import FaqAccordian from "../components/faqAccordian";
import AdvisorycardSection from "../components/HTML/FinancialPlan/AdvisorySection";
import FinancialReport from "../components/HTML/FinancialPlan/FinancialReport";
import FPCardSection from "../components/HTML/FinancialPlan/FPCardSection";
import HeaderSection from "../components/HTML/FinancialPlan/HeaderSection";
// import FaqSection from "../components/HTML/FinancePlan/FaqSection";
import FaqSection from "../components/HTML/FinancialPlan/FaqSection";
// import StepsFinancialPlan from "../components/HTML/Insurance/StepsFinancialPlan";
import StepsFinancialPlan from "../components/HTML/FinancialPlan/StepsFinancialPlan";
import SecuritySection from "../components/HTML/SecuritySection";
import YoutubevideoSection from "../components/HTML/FinancialPlan/YoutubevideoSection";
import Fullpage from "../components/Layout/Fullpage";
import WealthContactForm from "../components/WealthManagementContactForm";
import backImg from '../components/Layout/Fullpage/assets/advisor-min.jpg'
import { servicesTypes } from "../components/WealthManagementContactForm/model";
import { useState } from "react";
import ThankyouSection from "../components/ThankyouSection";
import AppointmentBox from "../components/Pages/Calendly";

const FinancialPlan = () => {
  const [show, SetShow] = useState(false)
  return (
    <Fullpage>
      <HeaderSection />
      <FPCardSection />
      <AdvisorycardSection />
      <StepsFinancialPlan />
      <FinancialReport />
      <SecuritySection DynamicBgColor={'#FFFFFF'} />
      <YoutubevideoSection />
      <section style={{
        // backgroundColor : "#e8f8ff6b"
      }} id="GetStarted">
        <AppointmentBox eventCode={'Callback_mintyApp_8'} serviceName="Financial Planning"  eventUrl={"https://calendly.com/fintoo/15-min-consultation-call-financial-planning-advisory?hide_event_type_details=1"}/>
        {/* <WealthContactForm imgSrc={backImg} serviceType={servicesTypes.financialPlanning} onSuccess={() => SetShow(true)} /> */}
      </section>
        <FaqSection />
      {show && <ThankyouSection onClose={() => SetShow(false)} />}
    </Fullpage>
  );
};
export default FinancialPlan;