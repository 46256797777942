import React from 'react'
import Style from "./style.module.css"
function GlobalScorecard() {
    return (
        <div className={`${Style.GlobalScorecard}`}>
            <div className={`${Style.ScoreBox}`}>
                <div className={`${Style.counternumber}`} style={{display : "flex", alignItems : "center"}}>
                    <span>300</span>
                    <span>Cr+</span>
                </div>
                <div>AUM</div>
            </div>
            <div className={`${Style.ScoreBox}`}>
                <div className={`${Style.counternumber}`} style={{display : "flex", alignItems : "center"}}>
                    <span>10</span>
                    <span>k+</span>
                </div>
                <div>Portfolio</div>
            </div>
            <div className={`${Style.ScoreBox}`}>
                <div className={`${Style.counternumber}`} style={{display : "flex", alignItems : "center"}}>
                    <span>50</span>
                    <span>k+</span>
                </div>
                <div>Transaction</div>
            </div>
        </div>
    )
}

export default GlobalScorecard
