import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { getParentUserId,getItemLocal,apiCall,loginRedirectGuest } from "../../../common_utilities";
import { CHECK_SESSION,BASE_API_URL } from "../../../constants";
import Styles from "./NDA.module.css";
import commonEncode from '../../../commonEncode';
import FintooLoader from '../../../components/FintooLoader';

function NDA() {
  const [userNDA,setNDA]=useState('')
  const [isLoading,setIsLoading]=useState(false)
  useEffect(() => {
    document.body.classList.add("NDA-Page");
  }, []);
  useEffect(() => {
    getMemberList();

  }, []);
  const getMemberList = async () => {
    setIsLoading(true)
    try {
      let url = CHECK_SESSION;
      let data = { user_id: getParentUserId(), sky: getItemLocal("rmf"), is_direct: 0 };
      
      let session_data = await apiCall(url, data, true, false);
      console.log("session_data",session_data,url,data);
      if(session_data.error_code=="100"){
        let url = BASE_API_URL+"restapi/expertflow/expertnda/";
        console.log('abb',session_data.data['plan_id']);
        if(session_data.data['plan_id']){
          setIsLoading(false)

          if(session_data.data['plan_id']==29){
            var nda_payload={user_id:getParentUserId(),skip_accept_check:1}
           
            let nda_api_call = await apiCall(url, nda_payload, true, false);
           
            if(nda_api_call.error_code=="100"){
  
              setNDA(nda_api_call.data)
            }
          }
          else if (ession_data.data['plan_id']==31){
            var nda_payload={user_id:getParentUserId()}
            console.log('data',nda_payload);
            let nda_api_call = await apiCall(url, nda_payload, true, false);
           
            if(nda_api_call.error_code=="100"){
  
              setNDA(nda_api_call.data)
            }
          }
        }
        
        else{
          setIsLoading(false)

          var nda_payload={user_id:getParentUserId(),skip_accept_check:1}
          let nda_api_call = await apiCall(url, nda_payload, true, false);
        
          if(nda_api_call.error_code=="100"){

            setNDA(nda_api_call.data)
          }
        }
        
        
      }
      else{
        loginRedirectGuest()
      }

      


    } catch (e) {
      setIsLoading(false)

      console.log("err", e);
    }
  };
  return (
                                                                                                                                                        
    <div className={`${Styles.NDApage}`}>
      <FintooLoader isLoading={isLoading} />

      <section className="basic-details-section bg-white ng-scope">
        <div className="container-fluid">
          <div className={`${Styles.backgrounddiv}`}>
            <div className={`${Styles.bg} ${Styles.active}`} id="bg-about" />
            <div className="top-wrapper">
              <Link
               to={
                process.env.PUBLIC_URL +
                "/pricing/"
              }
                className={`${Styles.backarrow}`}
              >
                <img
                  ng-src="https://images.minty.co.in/static/userflow/img/icons/back-arrow.svg"
                  alt="Back Arrow"
                  src="https://images.minty.co.in/static/userflow/img/icons/back-arrow.svg"
                />
              </Link>
            </div>
          </div>

          <div className="row align-items-center  justify-content-center">
            <div className="col-md-12">
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <div className={`${Styles.ndacontent}`}>
                    <div
                      className={`${Styles.rContent}`}
                      id="rcontentt"
                      style={{ textAlign: "justify" ,maxWidth:"100%"}}
                    >

                    <div dangerouslySetInnerHTML={{ __html: userNDA }} />
                      
                  </div>
                </div>
              </div>
            </div>
         
          </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default NDA;
