import React, { useEffect, useRef } from 'react';
import style from "./style.module.css";
const WhyLoanssection = () => {

    return (
        <div className={`container ${style.WhyLoanssection}`}>
            <div className={`${style.Whyinvestequitytitle}`}>
                Choose the Right Loan for You Quick, Easy, and Hassle-Free!
            </div>
            <div className={`${style.FeatureView}`}>
                <div className={`${style.FeatureViewbox} ${style.FeatureViewboxperosnal}`}>
                    <div>
                        <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Personal_loan.svg'} alt="Personal Loan" />
                    </div>
                    <div className={`${style.loantype}`}>Personal Loan</div>
                    <div className={`${style.loandesc}`}>From home upgrades, and weddings, to unexpected expenses, get the cash you need quickly and easily. Apply now and avail instant personal loan online!
                    </div>
                    <a style={{ textDecoration: "none" }} href='#Book-Calendly'>
                        <button>Apply Now</button></a>
                </div>
                <div className={`${style.FeatureViewbox} ${style.FeatureViewboxProfessional}`}>
                    <div>
                        <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/professional_loan_black.svg'} alt="Professional loan" />
                    </div>
                    <div className={`${style.loantype}`}>Professional Loan</div>
                    <div className={`${style.loandesc}`}>Got big plans? Expanding or upgrading? Let FintooInvest fuel your business growth with our fast, flexible loans.</div>
                    <a style={{ textDecoration: "none" }} href='#Book-Calendly'> <button>Apply Now</button></a>
                </div>
                <div className={`${style.FeatureViewbox} ${style.FeatureViewboxBusiness}`}>
                    <div>
                        <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/business_loan.svg'} alt="business loan" />
                    </div>
                    <div className={`${style.loantype}`}>Business Loan</div>
                    <div className={`${style.loandesc}`}>Business expansion or starting anew? At Fintoo Invest, we offer flexible business loans tailored to your needs. Get the funds you need to grow and thrive!</div>
                    <a style={{ textDecoration: "none" }} href='#Book-Calendly'> <button>Apply Now</button></a>
                </div>
            </div>
        </div>
    );
};

export default WhyLoanssection;
