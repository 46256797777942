import moment from "moment";
import { useState, useEffect } from "react";

import DatePicker from "react-datepicker";
import "./style.css";

const FintooDatePicker = ({ removeStyle, Placeholder, ...options }) => {
  return (
    <div
      className={`${
        removeStyle ? "no-style-calendar" : "style-calendar"
      } fintoo-datepicker fn-date ${options.date ? "fn-selected" : ""}`}
    >
      {/* {minDate={new Date()}} */}
      <DatePicker
        onKeyDown={(e)=> e.preventDefault()}
        autoComplete="off"
        id="myConstantID"
        placeholderText={Placeholder}
        dropdownMode="select"
        {...options}
        calendarIcon={
          <img width={"30px"} src={require("./Images/calendar73.svg")} />
        }
      />
    </div>
  );
};
export default FintooDatePicker;
