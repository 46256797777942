import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Styles from "./Styles.module.css";
const active = {
  //   borderBottom: "3px solid #042b62",
  color: "#000",
  backgroundColor: "#ffff",
};
const bgcolor = {
  backgroundColor: "#fff",
  transition: ".2s ease-in-out",
};
const inactive = {};
const TabName = ["Comprehensive", "Rebalancing", "Triggers", "Locker"];

TabName.map((Tabs, index) => index + " = " + Tabs + " = " + TabName[index]);
const KeyDifferentiators = () => {
  const { pathname, hash } = useLocation();
  const [showTab, setShowTab] = useState("Comprehensive");
  useEffect(() => {
    if (hash == "#international") {
      setShowTab("usequity");
    } else if (hash == "#domestic") {
      setShowTab("equity");
    } else {
      setShowTab("Comprehensive");
    }
  }, [hash]);
  return (
    <>
      <div className="container">
        <h2 className="text-center">
          Key Differentiators That Make Our Advice More Effective
        </h2>
        <div className={`${Styles.TextLabel}`}>
          Combining the power of our in-house developed algorithmic financial
          planning platform with the extensive experience of our team of expert
          advisors, we deliver tangible and comprehensive wealth management
          solutions that are at par with your financial goals.
        </div>
        <div className={`${Styles.tabSection}`}>
          <div
            className={`${Styles.tabData}`}
            style={showTab == "Comprehensive" ? bgcolor : inactive}
          >
            <div className={`${Styles.links}`}>
              <div
                onMouseOver={() => setShowTab("Comprehensive")}
                className={`${Styles.tabName} ${showTab ? "ActiveTab" : ""}`}
              >
                <div style={showTab == "Comprehensive" ? active : inactive}>
                  Comprehensive & Personalised
                </div>
              </div>
              <div
                onMouseOver={() => setShowTab("Rebalancing")}
                className={`${Styles.tabName}`}
              >
                <div style={showTab == "Rebalancing" ? active : inactive}>
                  Rebalancing And Restructuring
                </div>
              </div>
              <div
                onMouseOver={() => setShowTab("Triggers")}
                className={`${Styles.tabName}`}
              >
                <div style={showTab == "Triggers" ? active : inactive}>
                  What If Analysis & Triggers
                </div>
              </div>
              <div
                onMouseOver={() => setShowTab("Locker")}
                className={`${Styles.tabName}`}
              >
                <div style={showTab == "Locker" ? active : inactive}>
                Fintooinvest Locker
                </div>
              </div>
            </div>
            <div className={`${Styles.tabSection}`}>
              {showTab == "Comprehensive" && (
                <>
                  <p>
                    Keeping your income, expenses, assets, liabilities, risk
                    profile, and goals in mind, we develop a 360-degree
                    financial plan that covers every aspect of financial
                    planning like money management, investment portfolio
                    analysis, contingency planning, and pre-and-post-retirement
                    cash flow projections. This empowers you with the
                    information, necessary to make informed and beneficial
                    financial decisions.
                  </p>
                </>
              )}
              {showTab == "Rebalancing" && (
                <>
                  <p>
                    Just like your dreams have no boundaries, we too believe in
                    going beyond boundaries and giving you a solution that
                    offers more than just the creation of static documents. By
                    keeping pace with the ever-changing economic landscape, we
                    offer dynamic and personalised advice through a range of
                    rebalancing and restructuring options that keep your
                    investments on track to achieve your financial goals.
                  </p>
                </>
              )}
              {showTab == "Triggers" && (
                <>
                  <p>
                    Various ‘What If’ analysis tools and triggers to keep you
                    updated on goals and investments.
                  </p>
                </>
              )}
              {showTab == "Locker" && (
                <>
                  <p>
                    Keep all your financial and tax-related documents in a
                    single secure location for easy access.
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default KeyDifferentiators;
