import { useState } from "react";
import FaqAccordianContent from "./FaqAccordianContent";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import style from "./style.module.css";

function FaqAccordian() {
    const [activeIndex, SetActiveIndex] = useState(0);

    const updateAccordionIndex = (idx) => {
        if (activeIndex === idx) {
            return SetActiveIndex(() => null);
        } else {
            return SetActiveIndex(() => idx);
        }
    };

    const isActive = (idx) => activeIndex === idx;

    return (
        <>
            <div className="container" >
                <div className={`${style.faqtitle}`}>Frequently Asked Questions</div>
                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]} `}
                        onClick={() => updateAccordionIndex(0)}
                        style={isActive(0) ? { color: "#042b62" } : { color: "black" }}
                    >
                        {" "}
                        <span>What Are Domestic Equity Funds?</span>{" "}
                        {isActive(0) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(0)}>
                        Domestic Equity Funds are mutual funds that invest primarily in stocks of companies based within the investor's home country. These funds aim to benefit from the growth and potential of the local economy by focusing on companies listed on domestic stock exchanges.
                    </FaqAccordianContent>
                </div>
                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]}`}
                        onClick={() => updateAccordionIndex(1)}
                        style={isActive(1) ? { color: "#042b62" } : { color: "black" }}
                    >
                        <span>How Do Domestic Equity Funds Compare to Other Types of Equity Funds?</span>{" "}
                        {isActive(1) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(1)}>
                        Domestic Equity Funds invest in companies within the investor's home country, whereas International Equity Funds target companies outside the home country. Additionally, Global Equity Funds invest in both domestic and international stocks. Domestic funds offer exposure to local market dynamics and opportunities, while international and global funds provide diversification across various economies.
                    </FaqAccordianContent>
                </div>
                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]}`}
                        onClick={() => updateAccordionIndex(2)}
                        style={isActive(2) ? { color: "#042b62" } : { color: "black" }}
                    >
                        <span>
                            What Are the Key Benefits of Investing in Domestic Equity Funds?
                        </span>{" "}
                        {isActive(2) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(2)}>
                        Investing in Domestic Equity Funds offers several advantages:
                        <br />
                      
                        Local Market Exposure- Capitalize on economic growth and opportunities within your own country.
                        <br />
                        Familiarity with the Market- Invest in industries and companies you understand well.
                        <br />
                        Regulatory Oversight- Benefit from a familiar regulatory framework that provides investor protection.

                    </FaqAccordianContent>
                </div>  <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]}`}
                        onClick={() => updateAccordionIndex(4)}
                        style={isActive(4) ? { color: "#042b62" } : { color: "black" }}
                    >
                        <span>
                            What Risks Should I Be Aware of When Investing in Domestic Equity Funds?
                        </span>{" "}
                        {isActive(4) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(4)}>
                        Market Volatility: Stock prices can fluctuate, affecting your investment returns.
                        <br />
                        Economic Dependence: The fund's performance is tied to the health of the domestic economy.
                        <br />
                        Sector-Specific Risks: Concentration in particular sectors may lead to increased risk based on sector performance.
                    </FaqAccordianContent>
                </div>

                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]}`}
                        onClick={() => updateAccordionIndex(4)}
                        style={isActive(4) ? { color: "#042b62" } : { color: "black" }}
                    >
                        <span>
                            How Can I Select the Right Domestic Equity Fund for My Investment Needs?

                        </span>{" "}
                        {isActive(4) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(4)}>
                        To choose a suitable Domestic Equity Fund:<br />
                        Assess Risk Tolerance- Determine your comfort level with market volatility.<br />
                        Review Performance- Analyze the fund’s historical returns and performance metrics.<br />
                        Understand Fund Objectives- Ensure the fund’s goals align with your personal investment objectives.<br />
                        Evaluate Fees- Compare management fees and other charges to ensure they are reasonable for the fund’s offerings.

                    </FaqAccordianContent>
                </div>
            </div>
        </>
    );
}

export default FaqAccordian;
