import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Dashboard from "./Dashboard";

import NetworthGoals from "./NetworthGoals";

import {
  getUserId,
  apiCall,
  getItemLocal,
  loginRedirectGuest,
  getParentUserId,
} from "../../../common_utilities";
import {
  CHECK_SESSION,
  ADVISORY_RENEWPAYMENT_API_URL,
  ADVISORY_GET_PLANOFACTION_API_URL,
} from "../../../constants";
import commonEncode from "../../../commonEncode";
import PlanofAction from "./PlanofAction";
import MainLayout from "../../../components/Layout/MainLayout";
import PortfolioBalance from "../../../components/PortfolioBalance";


const CommonDashboard = () => {
  const [page, setPage] = useState("dashboard");
  const [dashboardpage, setDashboardPage] = useState(-1);
  const [plansubcat, setPlanSubCat] = useState(0);
  const [plantype, setPlanType] = useState(0);
  const [renewpopup, setRenewPopup] = useState(0);
  const [subscriptionenddate, setSubscriptionEndDate] = useState("");
  const [mutualfunddata, setMutualFundData] = useState([]);
  const [lifeinsurancerecomm, setLifeInsuranceRecomm] = useState(0);
  const [lifeinsurance, setLifeInsurance] = useState(0);
  const [totalmfsum, setTotalMFSum] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [sessionData, setSessionData] = useState({});
  const showPage = useSelector((state) => state.page);

  useEffect(() => {
    if (showPage) {
      //
      setPage(showPage);
    }
  }, [showPage]);

  const userid = getParentUserId();
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (!userid) {
      // loginRedirectGuest();
    }

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('success');
    const myCibilScoreParam = urlParams.get('iscibilscore');

    if (myParam == 1) {
      setIsOpen(true);
    }

    if (myCibilScoreParam == 1) {
      if (urlParams.get('cibilscore')) {
        setModalData(prev => ({ ...prev, cibilscore: Number(urlParams.get('cibilscore')) }));
        // setIsFetch(true);
        // setTab("tab2");
        // setSelectedOption("Fetch Loan");
      }
    }
  }, []);

  useEffect(() => {
    getSessiondata();
    getplanofactiondata();
  }, []);

  useEffect(() => {
    if (document.querySelector(".plan-of-action-cls") == null) return;
    if (dashboardpage > 1 && plantype == 6) {
      document.querySelector(".plan-of-action-cls").style.display = "block";
    } else {
      document.querySelector(".plan-of-action-cls").style.display = "none";
    }
  }, [dashboardpage, plantype]);

  const getSessiondata = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: userid, sky: getItemLocal("rmf"), is_direct: 0 };
      let session_data = await apiCall(url, data, true, true);
      if ((session_data.error_code = "100" && session_data.data != "")) {
        setSessionData(session_data["data"]);

        if (
          session_data.data.fp_lifecycle_status == "" ||
          !session_data.data.fp_lifecycle_status
        ) {
          var lifecycledata = 0;
        } else {
          var lifecycledata = session_data.data.fp_lifecycle_status;
        }
        setPlanSubCat(session_data.data.fp_plan_sub_cat);
        //console.log("nodnd",lifecycledata,session_data.data.fp_plan_type)
        setDashboardPage(lifecycledata);
        setPlanType(session_data.data.fp_plan_type);
        let api_data = {
          fp_log_id: session_data["data"]["fp_log_id"],
          user_id: session_data["data"]["id"],
        };
        var payload_data = commonEncode.encrypt(JSON.stringify(api_data));
        var renew_data = await apiCall(
          ADVISORY_RENEWPAYMENT_API_URL,
          payload_data,
          false,
          false
        );
        var res = JSON.parse(commonEncode.decrypt(renew_data));
        if ((res.error_code = "100" && res.data != "")) {
          setRenewPopup(res.data["show_popup"]);
          setSubscriptionEndDate(res.data["subscription_end_date"]);
        } else {
          setRenewPopup(0);
          setSubscriptionEndDate("");
        }
      } else {
        setDashboardPage(0);
        setRenewPopup(0);
        setPlanType(0);
        setPlanSubCat(0);
        setSubscriptionEndDate("");
        // loginRedirectGuest()
      }
    } catch (e) { }
  };

  const getplanofactiondata = async () => {
    try {
      setIsLoading(true);
      let url = CHECK_SESSION;
      let data = { user_id: getParentUserId(), sky: getItemLocal("rmf"), is_direct: 0 };
      let session_data = await apiCall(url, data, true, false);

      let api_data = {
        fp_log_id: session_data["data"]["fp_log_id"],
        user_id: session_data["data"]["id"],
        fp_user_id: session_data["data"]["fp_user_id"],
      };
      var payload_data = commonEncode.encrypt(JSON.stringify(api_data));
      var planofaction_data = await apiCall(
        ADVISORY_GET_PLANOFACTION_API_URL,
        payload_data,
        false,
        false
      );
      var res = JSON.parse(commonEncode.decrypt(planofaction_data));
      if (res.error_code == "100") {
        setIsLoading(false);
        setMutualFundData(res.data["mutual_funds"][0]);
        setLifeInsurance(res.data["lifeinsurance"]);
        setLifeInsuranceRecomm(res.data["life_insurance_recomm"]);
        setTotalMFSum({
          total_pvsum: res.data["total_pvsum"],
          total_pmtsum: res.data["total_pmtsum"],
        });
      } else {
        setIsLoading(false);
        setMutualFundData([]);
        setLifeInsurance([]);
        setLifeInsuranceRecomm(0);
        setTotalMFSum({});
      }
    } catch (e) { }
  };
  //
  //console.log("ss----->", dashboardpage, plansubcat, page, plantype);
  //console.log('ee----->', (dashboardpage > 1 || plantype == 6));
  return (
    <MainLayout>
      <div style={{ minHeight: "110vh" }}>
        {dashboardpage > -1 && (
          <>
            <div
              style={{
                display:
                  page == "dashboard" &&
                    (dashboardpage === 0 || dashboardpage === 1) &&
                    plansubcat != 7
                    ? "block"
                    : "none",
              }}
            >
              <Dashboard
                lifecyclestatus={dashboardpage}
                renewpopup={renewpopup}
                subscriptionenddate={subscriptionenddate}
                onChangePage={() => {
                  setPage(v);
                }}
              />
            </div>

            <div
              className="CoachmarkIssue"
              style={{
                display:
                  page == "dashboard" &&
                    !(
                      (dashboardpage === 0 || dashboardpage === 1) &&
                      plansubcat != 7
                    )
                    ? "block"
                    : "none",
              }}
            >
              {Object.keys(sessionData).length > 0 && (
                <NetworthGoals
                  session={sessionData}
                  renewpopup={renewpopup}
                  lifecyclestatus={dashboardpage}
                  subscriptionenddate={subscriptionenddate}
                  onChangePage={() => {
                    setPage(v);
                  }}
                />
              )}
            </div>

            <div
              style={{
                display:
                  dashboardpage > 1 && plantype == 6 && page === "planofaction"
                    ? "block"
                    : "none",
              }}
            >
              <PlanofAction
                mutualfunddata={mutualfunddata}
                totalmfsum={totalmfsum}
                lifeinsurancerecomm={lifeinsurancerecomm}
                lifeinsurance={lifeinsurance}
                isLoading={isLoading}
                onChangePage={() => {
                  setPage(v);
                }}
              />

              <PortfolioBalance open={isOpen} setIsOpen={setIsOpen} modalData={modalData} isDashboard={true} />

            </div>
          </>
        )}
      </div>
    </MainLayout>
  );
};
export default CommonDashboard;
