import React, { useEffect, useRef, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import ReactDatePicker from "../../../components/HTML/ReactDatePicker/ReactDatePicker";
import FintooRadio2 from "../../../components/FintooRadio2";
import Switch from "react-switch";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import GoalsDropdown from "../../../components/GoalsDropdown/GoalDropdown";
import CustomStyles from "../../../components/CustomStyles.js";

function Realestate(props) {
  const setAssetsDetails = props.setAssetsDetails;
  const assetsDetails = props.assetsDetails;
  const familyData = props.familyData;
  const goalData = props.goalData;
  const setDate = props.setDate;
  const rentalincomeData = props.rentalincomeData;
  const addForm = props.addForm;
  const updateForm = props.updateForm;
  const addAssetsSubmit = props.addAssetsSubmit;
  const cancelAssetForm = props.cancelAssetForm;
  const updateAssetsSubmit = props.updateAssetsSubmit;
  const assetForMember = props.assetForMember;
  const session = props.session;
  const setGoalSelected = props.setGoalSelected;
  const closeModal = props.closeModal;
  const selectGoals = props.selectGoals;
  const selectedGoals = props.selectedGoals;
  const selectedGoalIdArray = props.selectedGoalIdArray;
  const selectedGoalsId = props.selectedGoalsId;
  const setPriorityArray = props.setPriorityArray;
  const selectedPriorityArray = props.selectedPriorityArray;
  const setAutoMatedGoal = props.setAutoMatedGoal;
  const isAutoMatedGoal = props.isAutoMatedGoal;
  const setGoalLink = props.setGoalLink;
  const isGoalSelected = props.isGoalSelected;
  const setSelectedGoals = props.setSelectedGoals;
  const setSelectedGoalsId = props.setSelectedGoalsId;
  const setSelectedPriorityArray = props.setSelectedPriorityArray;
  const unchangedgoaldata = props.unchangedgoaldata;
  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: {
        pincode: {
          // name the rule
          message: "Please enter valid :attribute",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^[1-9][0-9]{5}$/i);
          },
        },
      },
    })
  );
  const [, forceUpdate] = useState();
  const [, setForceUpdate] = useState(0);
  const assetEditId = props.assetEditId;

  const handleRealEstateSubmit = async (e) => {
    e.preventDefault();

    var isFormValid = simpleValidator.current.allValid();
    simpleValidator.current.showMessages();
    setForceUpdate((v) => ++v);

    if (isFormValid) {
      // setIsLoading(true);
      addAssetsSubmit(e);
      setGoalSelected(false);

      setSelectedGoalsId(false);
      setSelectedPriorityArray([]);
      setAutoMatedGoal(true);
      setSelectedGoals("Automated Linkage");
      simpleValidator.current.hideMessages();
      setForceUpdate((v) => ++v);
    }
  };

  const handleRealEstateUpdate = async (e) => {
    e.preventDefault();
    var isFormValid = simpleValidator.current.allValid();
    simpleValidator.current.showMessages();
    setForceUpdate((v) => ++v);

    if (isFormValid) {
      // setIsLoading(true);
      updateAssetsSubmit(e);
      setGoalSelected(false);

      setSelectedGoalsId(false);
      setSelectedPriorityArray([]);
      setAutoMatedGoal(true);
      setSelectedGoals("Automated Linkage");
      simpleValidator.current.hideMessages();
      setForceUpdate((v) => ++v);
    }
  };

  const handleRealEstateCancel = async (e) => {
    e.preventDefault();
    cancelAssetForm(e);
    simpleValidator.current.hideMessages();
    setForceUpdate((v) => ++v);
  };

  useEffect(() => {
    simpleValidator.current.hideMessages();
    simpleValidator.current.purgeFields();

    if (assetEditId) {
      if (selectedGoals == "Automated Linkage") {
        setAutoMatedGoal(true);
        setSelectedGoals("Automated Linkage");
      } else {
        setAutoMatedGoal(false);
      }
    } else {
      setAutoMatedGoal(true);
      setSelectedGoals("Automated Linkage");
    }
    if (session && !assetEditId) {
      setSelectedGoalsId(false);
      setSelectedPriorityArray([]);
      setGoalSelected(false);
      props.getfpgoalsdata(session.data.fp_log_id);
    }
    setForceUpdate((v) => ++v);
  }, [assetsDetails?.asset_sub_category_id]);

  return (
    <div>
      {(assetsDetails.asset_sub_category_id == 53 ||
        assetsDetails.asset_sub_category_id == 55 ||
        assetsDetails.asset_sub_category_id == 52 ||
        assetsDetails.asset_sub_category_id == 56 ||
        assetsDetails.asset_sub_category_id == 51 ||
        assetsDetails.asset_sub_category_id == 59) && (
          <form noValidate="novalidate" name="goldassetform">
            <div className="row  d-flex align-items-center">
              <div className="col-md-5 custom-input">
                <div className={`form-group ${assetsDetails.asset_name ? "inputData" : null} `} style={{paddingTop : "19px"}}>
                  <input
                    type="text"
                    name="asset_name"
                    maxLength={35}
                    value={assetsDetails.asset_name}
                    onChange={(e) => {
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_name: e.target.value,
                      });
                    }}
                    required
                    autoComplete="off"
                  />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label for="name">Name of Asset*</label>
                </div>
                {simpleValidator.current.message(
                  "Asset Name",
                  assetsDetails.asset_name,
                  "required|alpha_space|min:3|max:60",
                  {
                    messages: {
                      alpha_space: "Alphabets are allowed only.",
                      required: "Please enter asset name",
                      max: "Asset name must be between 3-35 characters.",
                      min: "Asset name must be between 3-35 characters.",
                    },
                  }
                )}
              </div>
              <div className="col-md-5">
                <div className="material mt-md-0 mt-3">
                  <Form.Label>Who is This Investment For?*</Form.Label>
                  {familyData && (
                    <Select
                      classNamePrefix="sortSelect"
                      isSearchable={false}
                      styles={CustomStyles}
                      options={familyData}
                      onChange={(e) =>
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_member_id: e.value,
                        })
                      }
                      value={familyData.filter(
                        (v) => v.value == assetsDetails.asset_member_id
                      )}
                      isDisabled={
                        assetsDetails.asset_type &&
                        assetsDetails.asset_type != "none"
                      }
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="row d-flex align-items-center">
              <div className="col-md-5 ">
                <div className="material mb-3 mt-md-0 mt-3 form-floating">
                  <Form.Label className="mb-0">Date of Purchase</Form.Label>
                  <div
                    className="dt-conbx"
                    style={{
                      borderBottom: "1px solid #dadada",
                     paddingTop : "15px"
                    }}
                  >
                    <ReactDatePicker
                      select_date={moment(
                        assetsDetails.asset_purchase_date,
                        "DD/MM/YYYY"
                      ).toDate()}
                      setDate={(date) => {
                        setDate(date, "assetPurchaseDate");
                      }}
                      minDate={""}
                      maxDate={moment().toDate()}
                      className="pt-4"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-5  custom-input">
                <div className={`form-group 
                ${ assetsDetails.asset_purchase_amount == 0 ?
                   "inputData": 
                    assetsDetails.asset_purchase_amount  ? "inputData" : null} 
                  `}
                   style={{paddingTop : "19px"}}>
                  <input
                    type="text"
                    name="asset_purchase_amount"
                    maxLength={35}
                    value={assetsDetails.asset_purchase_amount}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/\D/g, ''); 
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_purchase_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                      });
                    }}
                    autoComplete="off"
                  />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label for="name">Purchase Amount (₹)</label>
                </div>
              </div>
            </div>

            <div className="row py-md-2">
              <div className="col-md-5 custom-input">
                <div className={`form-group ${assetsDetails.asset_amount ? "inputData" : null} `}>
                  <input
                    type="text"
                    name="asset_amount"
                    maxLength={35}
                    value={assetsDetails.asset_amount}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/\D/g, ''); 
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_amount: numericValue.slice(0, 11), 
                      });
                    }}
                    autoComplete="off"
                  />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label for="name">Current Market Value*</label>
                </div>
                {simpleValidator.current.message(
                  "Current Balance",
                  assetsDetails.asset_amount,
                  "required",
                  { messages: { required: "Please add asset value" } }
                )}
              </div>
              <div className="col-md-5 mt-md-0 mt-3 custom-input">
                <div className={`form-group  ${assetsDetails.asset_pin_code ? "inputData" : null} `}>
                  <span>
                    <input
                      type="text"
                      name="asset_amount"
                      maxLength={35}
                      value={assetsDetails.asset_pin_code}
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(/\D/g, ''); 
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_pin_code: numericValue.slice(0, 6),
                        });
                      }}
                      autoComplete="off"
                    />
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label for="name">Pincode*</label>
                  </span>
                  <span className="info-hover-box">
                    <span className="icon">
                      <img
                        alt="More information"
                        src="https://static.fintoo.in/static/assets/img/more_information.svg"
                      />
                    </span>
                    <span className="msg">Ex: 400097</span>
                  </span>
                </div>
                {simpleValidator.current.message(
                  "pincode",
                  assetsDetails.asset_pin_code,
                  "required|pincode",
                  { messages: { required: "Please enter pincode" } }
                )}
              </div>
            </div>
            <div className="row py-md-2 mt-1">
              {assetsDetails.asset_sub_category_id != "55" && (
                <div className="col-md-5 mt-md-0 mt-3">
                  <div className="dark-label">
                    <Form.Label>Residential premises type?*</Form.Label>
                    <div className="d-flex pt-4 " style={{ clear: "both" }}>
                      <FintooRadio2
                        checked={assetsDetails.asset_isrented == "0"}
                        onClick={(v) =>
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_isrented: "0",
                          })
                        }
                        title="Rented"
                      />
                      <FintooRadio2
                        checked={assetsDetails.asset_isrented == "1"}
                        onClick={(v) =>
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_isrented: "1",
                          })
                        }
                        title="Self Occupied"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-5 ">
                <div className="dark-label">
                  <Form.Label>Mortgage or Freehold?*</Form.Label>
                  <div className="d-flex  pt-4 " style={{ clear: "both" }}>
                    <FintooRadio2
                      checked={assetsDetails.asset_ismortgage == "0"}
                      onClick={(v) =>
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_ismortgage: "0",
                        })
                      }
                      title="Free Hold"
                    />
                    <FintooRadio2
                      checked={assetsDetails.asset_ismortgage == "1"}
                      onClick={(v) =>
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_ismortgage: "1",
                        })
                      }
                      title="Mortgage"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row py-md-2">
              {assetsDetails.asset_isrented == "0" &&
                rentalincomeData.length > 0 && (
                  <div className="col-md-5 ">
                    <Form.Label>Rental Income*</Form.Label>
                    <Select
                      classNamePrefix="sortSelect"
                      isSearchable={false}
                      styles={CustomStyles}
                      options={rentalincomeData}
                      onChange={(e) =>
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_rental_income: e.value,
                        })
                      }
                      value={rentalincomeData.filter(
                        (v) => v.value == assetsDetails.asset_rental_income
                      )}
                    />
                    {simpleValidator.current.message(
                      "select",
                      assetsDetails.asset_rental_income,
                      "required",
                      { messages: { required: "Please add rental income" } }
                    )}
                  </div>
                )}
              {assetsDetails.asset_isrented == "0" && (
                <div className="col-md-5 ">
                  <div className="dark-label">
                    <Form.Label>City Type?*</Form.Label>
                    <div className="d-flex pt-4" style={{ clear: "both" }}>
                      <FintooRadio2
                        checked={assetsDetails.asset_citytype == "0"}
                        onClick={(v) =>
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_citytype: "0",
                          })
                        }
                        title="Tier 1"
                      />
                      <FintooRadio2
                        checked={assetsDetails.asset_citytype == "1"}
                        onClick={(v) =>
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_citytype: "1",
                          })
                        }
                        title="Tier 2"
                      />
                      <FintooRadio2
                        checked={assetsDetails.asset_citytype == "2"}
                        onClick={(v) =>
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_citytype: "2",
                          })
                        }
                        title="Tier 3"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div style={{marginTop : `${assetsDetails.asset_isrented == "0" ? "1rem" : "0"}`}} className="row py-md-2">
              <div className="col-md-8">
                <div className="d-md-flex">
                  <Form.Label className=" ">
                    Consider This Asset In Automated Linkage*
                  </Form.Label>
                  <span className="info-hover-left-box ms-md-4">
                    <span Name="icon">
                      <img
                        alt="More information"
                        src="https://static.fintoo.in/static/assets/img/more_information.svg"
                      />
                    </span>
                    <span className="msg">
                      Select a goal below to map this investment with a goal of
                      your choice. otherwise, fintooinvest will link it automatically
                      with your high priority goal. In case, you do not wish to
                      utilize this investment for any goal, select "NO".
                    </span>
                  </span>
                  <div className="d-flex ms-md-4">
                    <div>No</div>
                    <Switch
                      onChange={(v) =>
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_islinkable: v,
                        })
                      }
                      checked={assetsDetails.asset_islinkable}
                      activeBoxShadow="0 0 2px 3px #424242"
                      className="react-switch px-2"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                      onColor="#424242"
                      offColor="#424242"
                    />
                    <div>Yes</div>
                  </div>
                </div>
              </div>
            </div>

            {assetsDetails.asset_isrented == "0" && (
            <div className="row py-md-2">
              <div className="col-md-8 mt-md-0 mt-3">
                <div className="d-md-flex">
                  <Form.Label className=" ">
                    Consider This Asset In Asset Allocation*
                  </Form.Label>
                  <span className="info-hover-left-box ps-md-3 ms-2">
                    <span className="icon">
                      <img
                        alt="More information"
                        src="https://static.fintoo.in/static/assets/img/more_information.svg"
                      />
                    </span>
                    <span className="msg">
                      Select a goal below to map this investment with a goal of
                      your choice. otherwise, fintooinvest will link it automatically
                      with your high priority goal. In case, you do not wish to
                      utilize this investment for any goal, select "NO".
                    </span>
                  </span>
                  <div className="d-flex ms-md-4">
                    <div>No</div>
                    <Switch
                      onChange={(v) => {
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_isallocation: v,
                        });
                      }}
                      checked={assetsDetails.asset_isallocation}
                      className="react-switch px-2"
                      activeBoxShadow="0 0 2px 3px #424242"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                      onColor="#424242"
                      offColor="#424242"
                    />
                    <div>Yes</div>
                  </div>
                </div>
              </div>
            </div>
           )}

            {assetsDetails.asset_islinkable == true && (
              <>
                <div className="row py-md-2">
                  <div className="col-md-8 mt-md-2 mt-3">
                    <div className="d-md-flex">
                      <Form.Label className="link_asset_style">
                        Link This Investment Asset to Goal
                      </Form.Label>{" "}
                      <span
                        className="ms-md-4 info-hover-left-box float-right"
                        style={{
                          position: "relative !important",
                        }}
                      >
                        <span className="icon">
                          <img
                            alt="More information"
                            src="https://static.fintoo.in/static/assets/img/more_information.svg"
                          />
                        </span>
                        <span className="msg">
                          You can only assign goals which are prior to the end
                          date of the SIP, if any
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-md-2 mt-3">
                    <div className="material">
                      <div
                        className="m-0 btn-sm default-btn gradient-btn save-btn"
                        onClick={() => setGoalSelected(true)}
                      >
                        Select Goals
                      </div>
                      <br></br>
                      <br></br>

                      {selectedGoals ? (
                        <div className="d-flex" style={{ textAlign: "left!important" }}>
                          <div style={{whiteSpace :"nowrap"}}>
                            <b>Selected Goals : </b>
                          </div>
                          <div className="ms-1">{selectedGoals}</div>
                        </div>
                      ) : (
                        ""
                      )}
                      {isGoalSelected ? (
                        <GoalsDropdown
                          setGoalSelected={setGoalSelected}
                          goals={goalData}
                          unchangedgoaldata={unchangedgoaldata}
                          closeModal={closeModal}
                          selectGoals={selectGoals}
                          selectedGoals={selectedGoals}
                          selectedGoalIdArray={selectedGoalIdArray}
                          selectedGoalsId={selectedGoalsId}
                          setPriorityArray={setPriorityArray}
                          selectedPriorityArray={selectedPriorityArray}
                          setAutoMatedGoal={setAutoMatedGoal}
                          isAutoMatedGoal={isAutoMatedGoal}
                          setGoalLink={setGoalLink}
                          type={"Asset"}
                          isGoalFilter={assetsDetails.asset_isrecurring == "1" ? "1" : "0"}
                          isAssetRecurring={assetsDetails.asset_isrecurring == "1" ? "1" : "0"}
                        ></GoalsDropdown>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="row py-2">
              <div className=" text-center">
                <div>
                  <div className="btn-container">
                    <div className="d-flex justify-content-center">
                      <a
                        href={process.env.PUBLIC_URL + "/datagathering/goals"}
                      >
                        <div className="previous-btn form-arrow d-flex align-items-center">
                          <FaArrowLeft />
                          <span className="hover-text">&nbsp;Previous</span>
                        </div>
                      </a>

                      {props.addForm && (
                        <button
                          onClick={(e) => handleRealEstateSubmit(e)}
                          className="default-btn gradient-btn save-btn"
                        >
                          Save & Add More
                        </button>
                      )}
                      {props.updateForm && (
                        <div>
                          <button
                            onClick={(e) => handleRealEstateCancel(e)}
                            className="default-btn gradient-btn save-btn"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={(e) => handleRealEstateUpdate(e)}
                            className="default-btn gradient-btn save-btn"
                          >
                            Update
                          </button>
                        </div>
                      )}
                      <div
                        className="next-btn form-arrow d-flex align-items-center"
                        onClick={() => props.setTab("tab2")}
                      >
                        <span className="hover-text" style={{ maxWidth: 100 }}>
                          Continue&nbsp;
                        </span>
                        <FaArrowRight />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
    </div>
  );
}

export default Realestate;
