import { useState, useEffect } from "react";
import Footer from "../components/MainComponents/Footer";
import { ReactComponent as Logo } from "../Assets/Images/logo.svg";
import MainLayout from "../components/Layout/MainLayout";
import { apiCall, getItemLocal, getParentUserId } from "../common_utilities";
import {
  ADVISORY_ADDTOCART_API_URL,
  ADVISORY_GET_PRICINGDETAILS_API_URL,
  ADVISORY_GET_TAXDETAILS_API_URL,
  BASE_API_URL,
  CHECK_SESSION,
  STATIC_URL,
} from "../constants";
import PricingPopup from "./PricingPopup";
// import Modal from "react-responsive-modal";
import { Modal } from "react-bootstrap";
import FintooLoader from "../components/FintooLoader";
import * as constClass from "../constants";
import commonEncode from "../commonEncode";
import { Buffer } from "buffer";


const PricingPage = () => {
  const [tabNo, setTabNo] = useState(1);
  const [sessionData, setSessionData] = useState([]);
  const [pricingData, setPricingData] = useState([]);
  const [reqplandict, setReqPlanDict] = useState({});
  const [taxData, setTaxsData] = useState([]);
  const [open, setOpen] = useState(false);
  const onCloseModal = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.body.classList.add("main-layout");
  }, []);

  useEffect(() => {
    if (location.href.includes("#tax_plan")) {
      setTabNo(2);
    } else {
      setTabNo(1);
    }
  }, []);
  useEffect(() => {
    getSessionData();
    getPricingDetails();
    getTaxDetails();
  }, []);

  const userid = getParentUserId();
  const getSessionData = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: getParentUserId(), sky: getItemLocal("rmf"), is_direct: 0 };
      let session_data = await apiCall(url, data, true, false);
      setSessionData(session_data["data"]);
    } catch {
      setSessionData([]);
    }
  };
  const getPricingDetails = async () => {
    try {
      setIsLoading(true);
      let url = ADVISORY_GET_PRICINGDETAILS_API_URL;
      let pricing_data = await apiCall(url, "", false, false);
      if (pricing_data["error_code"] == "100") {
        setIsLoading(false);
        setPricingData(pricing_data["data"]["plan_details"]["plandetails"]);
      }
    } catch {
      setIsLoading(true);
      setPricingData({});
    }
  };
  const getTaxDetails = async () => {
    try {
      setIsLoading(true);
      let url = ADVISORY_GET_TAXDETAILS_API_URL;
      let tax_data = await apiCall(url, "", false, false);
      if (tax_data["error_code"] == "100") {
        setIsLoading(false);
        setTaxsData(tax_data["data"]["tax_plan_details"]["taxplandetails"]);
      }
    } catch {
      setIsLoading(true);
      setTaxsData({});
    }
  };
  const loginRedirect = () => {
    localStorage.removeItem("userid");
    let t = window.location.href;
    if (window.location.pathname == "/") {
      t = t + "web/commondashboard";
    }
    if(t=="https://stg.minty.co.in/web/pricing/"){
      var redirectURL =
      window.location.origin +
      process.env.PUBLIC_URL +
      "/checkredirect?redirect=" +
      encodeURI(process.env.PUBLIC_URL +"/commondashboard/");
    }
    else{
      var redirectURL =
      window.location.origin +
      process.env.PUBLIC_URL +
      "/checkredirect?redirect=" +
      encodeURI(t);
    }
    
    var goTo =
      constClass.LOGIN_PAGE +
      "?src=" +
      Buffer.from(commonEncode.encrypt("dmf")).toString("base64") +
      "&redirect_uri=" +
      Buffer.from(commonEncode.encrypt(redirectURL)).toString("base64");
    // let redirectToThis = window.location.href;
    // let checkAuth = window.location.href.split("auth");
    // if (checkAuth.length > 1) {
    //   redirectToThis = checkAuth[0].substring(0, checkAuth[0].length - 1).replace(/\/+$/, '');
    // }
    // localStorage.setItem("redirectToThis", redirectToThis);
    window.location = goTo;
    // return;
  }; 
  function addtocart(monthly_amount, total_amount, frq, plan_sub_cat, plan_id) {
    var amount = 0;
    if (total_amount.isquaterly == 0 && total_amount.total != "custom") {
      amount = parseInt(total_amount.total);
    } else {
      amount = total_amount.Q1;
    }
    setReqPlanDict({
      amount: amount,
      frq: frq,
      plan_sub_cat: plan_sub_cat,
      plan_id: plan_id,
    });

    if (
      sessionData &&
      sessionData.fp_log_id &&
      (plan_id == 29 || plan_id == 31) &&
      sessionData.fp_plan_sub_cat == plan_sub_cat
    ) {
      window.location = process.env.PUBLIC_URL +"/commondashboard/";
    } else if (
      sessionData &&
      sessionData.fp_log_id &&
      (plan_id == 29 || plan_id == 31) &&
      sessionData.fp_plan_sub_cat != plan_sub_cat
    ) {
      if (
        (sessionData["plan_payment_status"] == "1" ||
          sessionData["plan_payment_status"] == "3" ||
          sessionData["plan_payment_status"] == "6" ||
          sessionData["plan_payment_status"] == "7") &&
        sessionData.fp_plan_sub_cat
      ) {
        if (sessionData.fp_plan_sub_cat > plan_sub_cat) {
          {
            setOpen(true);
          }
        } else {
          expertDetails(plan_id, plan_sub_cat, amount, frq);
        }
      } else {
        expertDetails(plan_id, plan_sub_cat, amount, frq);
      }
    } else if (plan_sub_cat != 7 && !sessionData) {
      // window.location.href = BASE_API_URL + "login/";
      loginRedirect()

    } else {
      if (plan_id == 29 || plan_id == 31) {
        expertDetails(plan_id, plan_sub_cat, amount, frq);
      }
    }
  }

  const expertDetails = async (plan_id, plan_sub_cat, amount, frq) => {
    if (plan_id == 29) {
      let cartdatatosend = {
        user_id: sessionData && sessionData && sessionData.id,
        plan_id: plan_id,
        plan_sub_cat_id: plan_sub_cat,
        amount: amount,
        subscription_freq: frq,
      };
      setIsLoading(true);
      let url = ADVISORY_ADDTOCART_API_URL;
      let cart_data = await apiCall(url, cartdatatosend, true, false);
      if (cart_data.error_code == "100") {
        setIsLoading(false);
        window.location.href = BASE_API_URL + "userflow/payment/";
      }
    } else if (plan_id == 31) {
      setIsLoading(true);
      window.location = process.env.PUBLIC_URL + "/expert";
    }
  };

  const addTaxCart = async (plan_id) => {
    window.location =
      "http://www.erokda.in/tax/itr-filing.php?pid=" + "" + plan_id;
  };
  return (
    <MainLayout>
      <div className="container-fluid white-bg">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10 w-100">
            <div className="s-head text-center mb-80">
              <h2 className="upperText">
                AI-Based Personalized Financial Planning Solutions Trusted By
                1,25,000+ Investors.
              </h2>
              <p
                className="mt-2 BottomText"
                style={{
                  color: "gray",
                }}
              >
                Fintoo's AI-Based comprehensive financial planning solution is a
                perfect combination of advanced technology and the extensive
                experience of financial planning experts. As each client is
                unique, we develop a unique and customised plan for each of our
                clients.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="pric-tables price-table">
              <div className="PricingBox">
                {pricingData &&
                  pricingData.map((x) => (
                    <div className="">
                      <div
                        className={`item sm-mb50 ${
                          x.category_name == "Assisted Advisory"
                            ? "item-active"
                            : "item-inactive"
                        }`}
                      >
                        <div className="type Price-type text-center mb-15">
                          {x.category_name == "Assisted Advisory" ? (
                            <div style={{ fontWeight: "bold" }}>
                              ASSISTED ADVISORY
                            </div>
                          ) : (
                            <h4>{x.category_name.toUpperCase()}</h4>
                          )}
                        </div>
                        <div className="amount text-center mb-40">
                          {x.category_name == "Robo Advisory" && (
                            <h4>₹ {x.monthly_amount}</h4>
                          )}
                          {x.category_name == "Assisted Advisory" && (
                            <div className="Customtype">{x.monthly_amount}</div>
                          )}
                        </div>
                        
                        {x.category_name == "Assisted Advisory" ? (
                          <div className="text-center custombtmText text-gray p">
                            Get a completely customised financial plan according
                            to your unique requirement
                          </div>
                        )
                        :
                        <>
                          <div style={{
                            color : "gray",
                            padding :"0 3rem",
                            fontSize : "0.9rem"
                          }} className="text-center custombtmText text-gray p">
                          Half Yearly
                          </div>
                        </>
                        
                        }
                        <div className="feat">
                          <ul>
                            <li className="d-flex align-items-center">
                              <span>
                                {x.dedicated_wealth_manager == 0 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/cancel.png"
                                    }
                                    alt=""
                                  />
                                )}
                                {x.dedicated_wealth_manager == 1 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/check.png"
                                    }
                                    alt=""
                                  />
                                )}
                              </span>
                              <span
                                className={`ml-2 ${
                                  x.category_name == "Assisted Advisory"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                Dedicated Wealth Manager
                              </span>
                            </li>
                            <li className="d-flex align-items-md-center">
                              <span>
                                {x.comprehensive_fp == 0 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/cancel.png"
                                    }
                                    alt=""
                                  />
                                )}
                                {x.comprehensive_fp == 1 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/check.png"
                                    }
                                    alt=""
                                  />
                                )}
                              </span>
                              <span
                                className={`ml-2 ${
                                  x.category_name == "Assisted Advisory"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                Comprehensive Financial Planning
                              </span>
                            </li>
                            <li className="d-flex align-items-center">
                              <span>
                                {x.tax_advisory.data == 0 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/cancel.png"
                                    }
                                    alt=""
                                  />
                                )}
                                {x.tax_advisory.data == 1 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/check.png"
                                    }
                                    alt=""
                                  />
                                )}
                              </span>
                              <span
                                className={`ml-2 ${
                                  x.category_name == "Assisted Advisory"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                Tax Advisory
                              </span>
                            </li>
                            <li className="d-flex align-items-center">
                              <span>
                                {x.will_estate_planning == 0 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/cancel.png"
                                    }
                                    alt=""
                                  />
                                )}
                                {x.will_estate_planning == 1 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/check.png"
                                    }
                                    alt=""
                                  />
                                )}
                              </span>
                              <span
                                className={`ml-2 ${
                                  x.category_name == "Assisted Advisory"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                {" "}
                                Will & Estate Planning
                              </span>
                            </li>
                            <li className="d-flex align-items-center">
                              <span>
                                {x.risk_management.data == 0 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/cancel.png"
                                    }
                                    alt=""
                                  />
                                )}
                                {x.risk_management.data == 1 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/check.png"
                                    }
                                    alt=""
                                  />
                                )}
                              </span>
                              <span
                                className={`ml-2 ${
                                  x.category_name == "Assisted Advisory"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                Risk Management
                              </span>
                            </li>
                            <li className="d-flex align-items-center">
                              <span>
                                <img
                                  className="pe-2"
                                  style={{ width: "16px" }}
                                  src={
                                    process.env.REACT_APP_STATIC_URL +
                                    "media/Pricing/check.png"
                                  }
                                  alt=""
                                />
                              </span>
                              <span
                                className={`ml-2 ${
                                  x.category_name == "Assisted Advisory"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                Review Frequency{" "}
                                <span
                                  className={`ExtraTxt ${
                                    x.category_name == "Assisted Advisory"
                                      ? "TextWhite"
                                      : "text-secondary"
                                  }`}
                                >
                                  (
                                  {x.review_frequency == "3"
                                    ? "Quarterly"
                                    : "Monthly"}
                                  )
                                </span>
                              </span>
                            </li>
                            <li className="d-flex">
                              <span>
                                {x.private_wealth_managment.data == 0 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/cancel.png"
                                    }
                                    alt=""
                                  />
                                )}
                                {x.private_wealth_managment.data == 1 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/check.png"
                                    }
                                    alt=""
                                  />
                                )}
                              </span>
                              <span
                                className={`ml-2 ${
                                  x.category_name == "Assisted Advisory"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                Private Wealth Management{" "}
                                <span
                                  className={`ExtraTxt ${
                                    x.category_name == "Assisted Advisory"
                                      ? "TextWhite"
                                      : "text-secondary"
                                  }`}
                                >
                                  {" "}
                                  {x.private_wealth_managment.meta.web}{" "}
                                </span>
                                {/* <br /> */}
                                <div className="Extrainfo">
                                  (Direct MF, Domestic Equity, Intl Equity,
                                  Unlisted Equity, Bonds, Debt & Alternate)
                                </div>
                              </span>
                            </li>
                            <li className="d-flex ">
                              <span>
                                {x.rebalancing_portfolio.data == 0 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/cancel.png"
                                    }
                                    alt=""
                                  />
                                )}
                                {x.rebalancing_portfolio.data == 1 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/check.png"
                                    }
                                    alt=""
                                  />
                                )}
                              </span>
                              <span
                                className={`ml-2 ${
                                  x.category_name == "Assisted Advisory"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                Rebalancing of Portfolio
                                <span
                                  className={`ExtraTxt ${
                                    x.category_name == "Assisted Advisory"
                                      ? "TextWhite"
                                      : "text-secondary"
                                  }`}
                                >
                                  &nbsp;
                                  {x.rebalancing_portfolio.meta.web
                                    ? x.rebalancing_portfolio.meta.web
                                    : "(Advisory & Execution)"}
                                </span>
                              </span>
                            </li>
                            <li className="d-flex align-items-center">
                              <span>
                                {x.tax_filling.data == 0 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/cancel.png"
                                    }
                                    alt=""
                                  />
                                )}
                                {x.tax_filling.data == 1 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/check.png"
                                    }
                                    alt=""
                                  />
                                )}
                              </span>

                              <span
                                className={`ml-2 ${
                                  x.category_name == "Assisted Advisory"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                {" "}
                                Tax Filing &nbsp;
                                <span className="ExtraTxt">
                                  {x.tax_filling.meta.web}
                                </span>
                              </span>
                            </li>
                            <li className="d-flex align-items-center">
                              <span>
                                {x.chat_with_expert.data == 0 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/cancel.png"
                                    }
                                    alt=""
                                  />
                                )}
                                {x.chat_with_expert.data == 1 && (
                                  <img
                                    className="pe-2"
                                    style={{ width: "16px" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/Pricing/check.png"
                                    }
                                    alt=""
                                  />
                                )}
                              </span>
                              <span
                                className={`ml-2 ${
                                  x.category_name == "Assisted Advisory"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                Chat With Expert
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="BtnBox">
                          <button
                            onClick={() =>
                              addtocart(
                                x.monthly_amount,
                                x.amount,
                                x.payment_frequency,
                                x.id,
                                x.plan_id
                              )
                            }
                          >
                            {" "}
                            Get Started{" "}
                          </button>
                        </div>
                        <br></br>
                      </div>
                    </div>
                  ))}
                {/* <div className="">
                  <div className="item item-active sm-mb50">
                    <div className=" Price-type text-center mb-15">
                      <div style={{ fontWeight: "bold" }}>ASSISTED ADVISORY</div>
                    </div>
                    <div className="amount text-center">
                      <div className="Customtype">Custom</div>
                    </div>
                    <div className="text-center custombtmText text-gray p">
                      Get a completely customised financial plan according to your unique requirement
                    </div>
                    <div className="feat">
                      <ul>
                        <li className="d-flex align-items-center">
                          <span>
                            <img
                              className="pe-2"
                              style={{ width: "16px" }}
                              src={
                                process.env.REACT_APP_STATIC_URL +
                                "media/Pricing/check.png"
                              }
                              alt=""
                            />
                          </span>
                          <span className="ml-2 text-white">
                            Dedicated Wealth Manager
                          </span>
                        </li>
                        <li className="d-flex align-items-center">
                          <span>
                            <img
                              className="pe-2"
                              style={{ width: "16px" }}
                              src={
                                process.env.REACT_APP_STATIC_URL +
                                "media/Pricing/check.png"
                              }
                              alt=""
                            />
                          </span>
                          <span className="ml-2 text-white">
                            Comprehensive Financial Planning
                          </span>
                        </li>
                        <li className="d-flex align-items-center">
                          <span>
                            <img
                              className="pe-2"
                              style={{ width: "16px" }}
                              src={
                                process.env.REACT_APP_STATIC_URL +
                                "media/Pricing/check.png"
                              }
                              alt=""
                            />
                          </span>
                          <span className="ml-2 text-white">Tax Advisory</span>
                        </li>
                        <li className="d-flex align-items-center">
                          <span>
                            <img
                              className="pe-2"
                              style={{ width: "16px" }}
                              src={
                                process.env.REACT_APP_STATIC_URL +
                                "media/Pricing/check.png"
                              }
                              alt=""
                            />
                          </span>
                          <span className="ml-2 text-white">
                            {" "}
                            Will & Estate Planning
                          </span>
                        </li>
                        <li className="d-flex align-items-center">
                          <span>
                            <img
                              className="pe-2"
                              style={{ width: "16px" }}
                              src={
                                process.env.REACT_APP_STATIC_URL +
                                "media/Pricing/check.png"
                              }
                              alt=""
                            />
                          </span>
                          <span className="ml-2 text-white">
                            Risk Management
                          </span>
                        </li>
                        <li className="d-flex align-items-center">
                          <span>
                            <img
                              className="pe-2"
                              style={{ width: "16px" }}
                              src={
                                process.env.REACT_APP_STATIC_URL +
                                "media/Pricing/check.png"
                              }
                              alt=""
                            />
                          </span>
                          <span className="ml-2 text-white">
                            Review Frequency{" "}
                            <span className="ExtraTxt">( Monthly )</span>
                          </span>
                        </li>
                        <li className="d-flex align-items-center">
                          <span>
                            <img
                              className="pe-2"
                              style={{ width: "16px" }}
                              src={
                                process.env.REACT_APP_STATIC_URL +
                                "media/Pricing/check.png"
                              }
                              alt=""
                            />
                          </span>
                          <span className="ml-2 text-white">
                            Private Wealth Management
                            <br />
                            <span className="Extrainfo">
                              (Direct MF, Domestic Equity, Intl Equity, Unlisted
                              Equity, Bonds, Debt & Alternate)
                            </span>
                          </span>
                        </li>
                        <li className="d-flex align-items-center">
                          <span>
                            <img
                              className="pe-2"
                              style={{ width: "16px" }}
                              src={
                                process.env.REACT_APP_STATIC_URL +
                                "media/Pricing/check.png"
                              }
                              alt=""
                            />
                          </span>
                          <span className="ml-2 text-white">
                            Rebalancing of Portfolio <br />{" "}
                            <span className="ExtraTxt">
                              ( Adisory & Execution )
                            </span>
                          </span>
                        </li>
                        <li className="d-flex align-items-center">
                          <span>
                            <img
                              className="pe-2"
                              style={{ width: "16px" }}
                              src={
                                process.env.REACT_APP_STATIC_URL +
                                "media/Pricing/check.png"
                              }
                              alt=""
                            />
                          </span>
                          <span className="ml-2 text-white">
                            {" "}
                            Tax Filing{" "}
                            <span className="ExtraTxt">( Self & Spouse )</span>
                          </span>
                        </li>
                        <li className="d-flex align-items-center">
                          <span>
                            <img
                              className="pe-2"
                              style={{ width: "16px" }}
                              src={
                                process.env.REACT_APP_STATIC_URL +
                                "media/Pricing/check.png"
                              }
                              alt=""
                            />
                          </span>
                          <span className="ml-2 text-white">
                            Chat With Expert
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="BtnBox">
                      <button className="text-black"> Get Started </button>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal className="Modalpopup" show={open} onHide={onCloseModal}>
        <div className="text-center">
          <h2 className="HeaderText">Information !!</h2>
          <PricingPopup
            open={open}
            onClose={onCloseModal}
            sessionData={sessionData}
            reqplandict={reqplandict}
          />
        </div>
      </Modal>
      {/* <Footer />s */}
    </MainLayout>
  );
};
export default PricingPage;
