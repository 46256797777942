import React, { useEffect, useRef, useState, useCallback } from "react";
import Slider from "react-slick";
import styles from "./style.module.css";
import { FcGoogle } from "react-icons/fc";
import Avatar from "react-avatar";
import ApplyWhiteBg from "../../../components/ApplyWhiteBg";
export default function ClientReviews() {
    const settings = {
        className: "center",
        centerMode: true,
        centerPadding: "4rem",
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    useEffect(() => {
        document.body.classList.add("Client-Slider")
    }, []);
    return (
        <>
         <ApplyWhiteBg />
            <Slider {...settings} autoplay className={`${styles.SlideShow}`}>
                <div>
                    <div className={`ClientSlider ${styles.TestimonialCard}`}>
                        <div
                            className={`justify-content-between ${styles.TestimonialCardHeader}`}
                        >
                            <div className={`d-flex ${styles.CardProfile}`}>
                                <div className={`${styles.CardProfileImg}`}>
                                    <img src="https://www.financialhospital.in/img/agarwal.jpg" />
                                    {/* <Avatar
                                            googleId
                                            round={true}
                                            name="Vijay Rajagopal"
                                            size="40"
                                            textSizeRatio={1.75}
                                        /> */}
                                </div>
                                <div className={`ms-3 ${styles.profilecard}`}>
                                    <div className={`${styles.ProfileName}`}>
                                        Saurabh Agarwal
                                    </div>
                                    <div className={`${styles.ProfileDesignation}`}>
                                        Vice President - Reliance Industries
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.socialIcon}`}>
                                {/* <FcGoogle /> */}
                            </div>
                        </div>
                        <div className={`${styles.clientReviewTxt}`}>
                            <p>
                                "Financial Hospital is an efficient, reliable and trusted partner for me. They have been able to service a broad range of investment and tax planning needs while always remaining flexible, and most importantly lightning fast. A great experience! The flexibility and speed of response from the relationship manager, as well as the quality of the management is exceptional. Keep up the good work!"
                            </p>
                        </div>
                    </div>
                    <div className={`mt-1 ${styles.TestimonialCard}`}>
                        <div
                            className={`justify-content-between ${styles.TestimonialCardHeader}`}
                        >
                            <div className={`d-flex ${styles.CardProfile}`}>
                                <div className={`${styles.CardProfileImg}`}>
                                    <img src="https://www.financialhospital.in/img/sa.jpg" />
                                </div>
                                <div className={`ms-3 ${styles.profilecard}`}>
                                    <div className={`${styles.ProfileName}`}>
                                        Sanjay Kumar Jha
                                    </div>
                                    <div className={`${styles.ProfileDesignation}`}>
                                        Vice President (HR) - Mahindra First Choice Wheels
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.socialIcon}`}>
                                {/* <FcGoogle /> */}
                            </div>
                        </div>
                        <div className={`${styles.clientReviewTxt}`}>
                            <p>
                                "I had a privilege to associate with Financial Hospital in 2014 for handling my Finance & tax related issues, I must say that this was the good decision I made, I really appreciate the employees of Financial Hospital who helped me to manage it professionally and systematically. I appreciate their enthusiasm and passion towards work."
                            </p>
                        </div>
                    </div>
                </div>
                <div className={`${styles.Odd}`}>
                    <div className={`${styles.TestimonialCard}`}>
                        <div
                            className={`justify-content-between ${styles.TestimonialCardHeader}`}
                        >
                            <div className={`d-flex ${styles.CardProfile}`}>
                                <div className={`${styles.CardProfileImg}`}>
                                    <Avatar
                                        googleId
                                        round={true}
                                        name="Anand P."
                                        size="40"
                                        textSizeRatio={1.75}
                                    />
                                </div>
                                <div className={`ms-3 ${styles.profilecard}`}>
                                    <div className={`${styles.ProfileName}`}>Anand P. </div>
                                    <div className={`${styles.ProfileDesignation}`}>
                                        Johnson & Johnson
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.socialIcon}`}>
                                {/* <FcGoogle /> */}
                            </div>
                        </div>
                        <div className={`${styles.clientReviewTxt}`}>
                            <p>
                                "Financial hospital is a super-speciality facility rendering its services with a personal touch to its clients marketing it unique. The doctors are well trained in wealth care and personalized attention at your doorstep is a sure shot way of finding their way to your heart with good intention and keep you wealthy!"
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={`${styles.TestimonialCard}`}>
                        <div
                            className={`justify-content-between ${styles.TestimonialCardHeader}`}
                        >
                            <div className={`d-flex ${styles.CardProfile}`}>
                                <div className={`${styles.CardProfileImg}`}>
                                    <Avatar
                                        googleId
                                        round={true}
                                        name="Jitesh Pandey."
                                        size="40"
                                        textSizeRatio={1.75}
                                    />
                                </div>
                                <div className={`ms-3 ${styles.profilecard}`}>
                                    <div className={`${styles.ProfileName}`}>Jitesh Pandey. </div>
                                    <div className={`${styles.ProfileDesignation}`}>
                                        Director Air Tran Logestic Pvt. Ltd.
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.socialIcon}`}>
                                {/* <FcGoogle /> */}
                            </div>
                        </div>
                        <div className={`${styles.clientReviewTxt}`}>
                            <p>
                                "I transferred my accountancy services to FHand the transition was as smooth as they said it would be. I am impressed with the array of services they offer and the quality of the advice they give me. I regard FH as my business adviser. I would recommend FH to any business looking for a better service at a competitive price."
                            </p>
                        </div>
                    </div>
                    <div className={`mt-1 ${styles.TestimonialCard}`}>
                        <div
                            className={`justify-content-between ${styles.TestimonialCardHeader}`}
                        >
                            <div className={`d-flex ${styles.CardProfile}`}>
                                <div className={`${styles.CardProfileImg}`}>
                                    {/* <img src="https://gi-strapi.s3.ap-south-1.amazonaws.com/aditya_f9bee07a9d.jpg" /> */}
                                    <Avatar
                                        googleId
                                        round={true}
                                        name="Gautam Salaskar "
                                        size="40"
                                        textSizeRatio={1.75}
                                    />
                                </div>
                                <div className={`ms-3 ${styles.profilecard}`}>
                                    <div className={`${styles.ProfileName}`}>Gautam Salaskar </div>
                                    <div className={`${styles.ProfileDesignation}`}>
                                        Go Air
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.socialIcon}`}>
                                {/* <FcGoogle /> */}
                            </div>
                        </div>
                        <div className={`${styles.clientReviewTxt}`}>
                            <p>
                                "Financial Hospital lives up to its name.We normally go to hospital for one stop shop where all your worries are taken care of and I think they lived up completely to that all our financial worries are completely looked by them and it gives you confidence that you are in right place and you don't have to really worry about your investments.Quite happy with this."
                            </p>
                        </div>
                    </div>
                </div>
                <div className={`${styles.Odd}`}>
                    <div className={`${styles.TestimonialCard}`}>
                        <div
                            className={`justify-content-between ${styles.TestimonialCardHeader}`}
                        >
                            <div className={`d-flex ${styles.CardProfile}`}>
                                <div className={`${styles.CardProfileImg}`}>
                                    {/* <img src="https://gi-strapi.s3.ap-south-1.amazonaws.com/aditya_f9bee07a9d.jpg" /> */}
                                    <Avatar
                                        googleId
                                        round={true}
                                        name="Vikash Arora "
                                        size="40"
                                        textSizeRatio={1.75}
                                    />
                                </div>
                                <div className={`ms-3 ${styles.profilecard}`}>
                                    <div className={`${styles.ProfileName}`}>Vikash Arora </div>
                                    <div className={`${styles.ProfileDesignation}`}>
                                        Director Flowers n fruits
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.socialIcon}`}>
                                {/* <FcGoogle /> */}
                            </div>
                        </div>
                        <div className={`${styles.clientReviewTxt}`}>
                            <p>
                                "Financial Hospital has worked very closely with us over the last Five years. At the outset we ran as trategy day looking at how far we had come and where we were going. We had expanded rapidly and its easy to lose sight of the wood for the trees as we all just get on with doing the job."
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={`${styles.TestimonialCard}`}>
                        <div
                            className={`justify-content-between ${styles.TestimonialCardHeader}`}
                        >
                            <div className={`d-flex ${styles.CardProfile}`}>
                                <div className={`${styles.CardProfileImg}`}>
                                    <Avatar
                                        googleId
                                        round={true}
                                        name="Anu Khanchandani"
                                        size="40"
                                        textSizeRatio={1.75}
                                    />
                                </div>
                                <div className={`ms-3 ${styles.profilecard}`}>
                                    <div className={`${styles.ProfileName}`}>Anu Khanchandani </div>
                                    <div className={`${styles.ProfileDesignation}`}>
                                        Director - Infoheights Systems
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.socialIcon}`}>
                                {/* <FcGoogle /> */}
                            </div>
                        </div>
                        <div className={`${styles.clientReviewTxt}`}>
                            <p>
                                "Entrusting our personal finances to Financial Hospital has been one of the best decisions we have made.Their professionalism and domain knowledge clearly reflects in their performance. It is because of their knowledge of the financial market that we are sure that our investments are going to yield maximum returns in the years to come. We sincerely wish they will keep up the good work and would surely recommend their services to prospective clients who wish to outsource the burden of managing their finances to Financial Hospital"
                            </p>
                        </div>
                    </div>
                    <div className={`mt-1 ${styles.TestimonialCard}`}>
                        <div
                            className={`justify-content-between ${styles.TestimonialCardHeader}`}
                        >
                            <div className={`d-flex ${styles.CardProfile}`}>
                                <div className={`${styles.CardProfileImg}`}>
                                    {/* <img src="https://gi-strapi.s3.ap-south-1.amazonaws.com/aditya_f9bee07a9d.jpg" /> */}
                                    <Avatar
                                        googleId
                                        round={true}
                                        name="Haresh Shetha "
                                        size="40"
                                        textSizeRatio={1.75}
                                    />
                                </div>
                                <div className={`ms-3 ${styles.profilecard}`}>
                                    <div className={`${styles.ProfileName}`}>
                                        Haresh Shetha
                                    </div>
                                    <div className={`${styles.ProfileDesignation}`}>
                                        Director - Colors Concepts
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.socialIcon}`}>
                                {/* <FcGoogle /> */}
                            </div>
                        </div>
                        <div className={`${styles.clientReviewTxt}`}>
                            <p>
                                "Unlike the majority of other accountancy firms that i have worked with, FH is a lot more proactive by being prepared to offer a different level of service to local businesses. Initiatives seek the help organizations by introducing new ideas for growth and development through ongoing support. It is an approach that is not offered t h rough traditional accountancy."
                            </p>
                        </div>
                    </div>
                </div>
                <div className={`${styles.Odd}`}>
                    <div className={`${styles.TestimonialCard}`}>
                        <div
                            className={`justify-content-between ${styles.TestimonialCardHeader}`}
                        >
                            <div className={`d-flex ${styles.CardProfile}`}>
                                <div className={`${styles.CardProfileImg}`}>
                                    <Avatar
                                        googleId
                                        round={true}
                                        name="B. Anand "
                                        size="40"
                                        textSizeRatio={1.75}
                                    />
                                </div>
                                <div className={`ms-3 ${styles.profilecard}`}>
                                    <div className={`${styles.ProfileName}`}>
                                        B. Anand
                                    </div>
                                    <div className={`${styles.ProfileDesignation}`}>
                                        Director - Finance Future Group
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.socialIcon}`}>
                                {/* <FcGoogle /> */}
                            </div>
                        </div>
                        <div className={`${styles.clientReviewTxt}`}>
                            <p>
                                "Amidst a crowded jungle of financial advisors,came across a very unique institution called Financial Hospital (FH) which as the name suggests provides comprehensive solutions around financial care and wellness. Run by a very young and dynamic entrepreneur Manish Hingar, FH seamlessly blends contemporary financial solutions with a human touch. Backed by a very competent team of young talented and skilled counselors,within a short span of time Manish has developed a wide pool of customers ranging from leading corporates, senior company executives,professionals, businessmen and middle class citizens. What I personally admired, is their intense desire to constantly innovate in making their clients fiscal planning easier, effective and without surprises.This they have managed by using latest available technologies and on the human front by engaging with lots of integrity and consistently interacting with clients by using educative approach by holding seminars and other interactive models.I wish them the very best for a great journey ahead."
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={`${styles.TestimonialCard}`}>
                        <div
                            className={`justify-content-between ${styles.TestimonialCardHeader}`}
                        >
                            <div className={`d-flex ${styles.CardProfile}`}>
                                <div className={`${styles.CardProfileImg}`}>
                                    {/* <img src="https://gi-strapi.s3.ap-south-1.amazonaws.com/aditya_f9bee07a9d.jpg" /> */}
                                    <Avatar
                                        googleId
                                        round={true}
                                        name="Ajit Nair "
                                        size="40"
                                        textSizeRatio={1.75}
                                    />
                                </div>
                                <div className={`ms-3 ${styles.profilecard}`}>
                                    <div className={`${styles.ProfileName}`}>Ajit Nair </div>
                                    <div className={`${styles.ProfileDesignation}`}>
                                        Pepsico
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.socialIcon}`}>
                                {/* <FcGoogle /> */}
                            </div>
                        </div>
                        <div className={`${styles.clientReviewTxt}`}>
                            <p>
                                "Financial Hospital has stream lined my tax planning and has made my money work harder for me. Prompt service, sound advise and personalised service is what I have gained in this lifelong partnership. Thanks !"
                            </p>
                        </div>
                    </div>
                    <div className={`mt-1 ${styles.TestimonialCard}`}>
                        <div
                            className={`justify-content-between ${styles.TestimonialCardHeader}`}
                        >
                            <div className={`d-flex ${styles.CardProfile}`}>
                                <div className={`${styles.CardProfileImg}`}>
                                    {/* <img src="https://gi-strapi.s3.ap-south-1.amazonaws.com/aditya_f9bee07a9d.jpg" /> */}
                                    <Avatar
                                        googleId
                                        round={true}
                                        name="Sandeep Gupta "
                                        size="40"
                                        textSizeRatio={1.75}
                                    />
                                </div>
                                <div className={`ms-3 ${styles.profilecard}`}>
                                    <div className={`${styles.ProfileName}`}>Sandeep Gupta </div>
                                    <div className={`${styles.ProfileDesignation}`}>
                                        Reliance Industries
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.socialIcon}`}>
                                {/* <FcGoogle /> */}
                            </div>
                        </div>
                        <div className={`${styles.clientReviewTxt}`}>
                            <p>
                                "My opinion about FH : As we seek medical treatment from a physician.I feel Manish and his team help us improve our financial health through their strategic insights and meticulous planning. Hats of to FH for all the good work.... keep up the tempo."
                            </p>
                        </div>
                    </div>
                </div>
            </Slider>
        </>
    );
}
