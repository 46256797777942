import React from 'react';
import styles from './style.module.css';
import { Link } from 'react-router-dom';

const InvestmentButton = () => {
  return (
    <Link
      style={{ textDecoration: "none" }}
      to={process.env.PUBLIC_URL + "/mutual-fund/funds/all"}>
      <button className={styles.investNowButton}>INVEST NOW</button>
    </Link>
  );
};

export default InvestmentButton;
