import { useEffect, useState } from "react";
import PortfolioCategory from "./PortfolioCategory";
import style from "./style.module.css";
import ReactApexChart from "react-apexcharts";

const PortfolioValue = (props) => {
  const [categoryValues, setCategoryValues] = useState([]);

  const [donutData, setDonutData] = useState({
    series: [70, 30],
    options: {
      responsive: [
        {
          breakpoint: 500,
          options: {
            legend: {
              fontSize: "11px"
            }
          }
        }
      ],
      states: {
        active: {
          filter: {
            type: "none" /* none, lighten, darken */
          }
        }
      },
      legend: false,
      dataLabels: {
        enabled: false,
      },
      chart: {
        type: "donut"
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    }
  });

  useEffect(()=> {
    if(categoryValues.length > 0) {
      setDonutData({...donutData, series: categoryValues.map((v)=> parseFloat(v.title_percentage_value)), options: {...donutData.options, labels: categoryValues.map((v)=> v.title)}});
    }
  }, [categoryValues]);

  const onLoadInit = async () => {
    await props;
    GetCategoryData(props.data);
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      currency: "INR",
    }).format(value);

  const GetCategoryData = (data) => {
    let graph_data = [];
    Object.keys(data.graph_data).forEach((key) => {
      graph_data.push({
        title: key,
        title_percentage_value: parseFloat(
          (data.graph_data[key] / data.tcurr_value) * 100
        ).toFixed(2),
        title_invested_value: numberFormat(data.graph_data[key]),
      });
    });
    setCategoryValues(graph_data);
  };

  useEffect(() => {
    onLoadInit();
  }, []);

  console.log('categoryValues', categoryValues);

  return (
    <div>
      <div
      className={style.bepbox}
        style={{
          border: "1px solid #ececec",
          borderRadius: 10,
        }}
      >
        <div className={`${style.pbox}`}>
          <div className={`${style.pbox1}`}>
            <div>
              <div className={style.insidePo}>
                <p className={`mb-0 ${style.title}`}>Total Portfolio Value</p>
                <p className="mb-0">
                  <span className={style.symbol}>₹</span>
                  <span className={style.value}>
                    {props.data.tcurr_value
                      ? numberFormat(props.data.tcurr_value)
                      : 0}
                  </span>
                </p>
              </div>
            </div>
            <div className={style.diffDv}>
              <div className={style.insidePo}>
                <p className={`mb-0 ${style.title}`}>Investment Value</p>
                <p className="mb-0">
                  <span className={style.symbol}>₹</span>
                  <span className={style.value}>
                    {props.data.tinvested_value
                      ? numberFormat(props.data.tinvested_value)
                      : 0}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div>
          <ReactApexChart
            className="donutchartI74y"
            options={donutData.options}
            series={donutData.series}
            key={'heolo' + donutData.series.length}
            type="donut"
            height={100}
          />
          </div>
        </div>
      </div>

      <div className="py-4">
        <div className={style.grid08}>
          {categoryValues.length > 0 ? (
            categoryValues.map((v) => <PortfolioCategory data={v} />)
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default PortfolioValue;
