import React, { useEffect } from 'react'
import Herosection from '../../../components/LandingPages/MFlandingpage/Herosection';
import Whyinvest from '../../../components/LandingPages/MFlandingpage/Whyinvest';
import OurFeatures from '../../../components/LandingPages/MFlandingpage/OurFeatures';
import InvestmentCard from '../../../components/LandingPages/MFlandingpage/InvestmentCard';
import FaqAccordian from '../../../components/LandingPages/MFlandingpage/FaqAccordian';
import Mfcalculator from '../../../components/LandingPages/MFlandingpage/Mfcalculator';
import ClientTestimonial from '../../../components/HTML/ClientTestimonial';

const MFLandingPage = () => {
    useEffect(() => {
        document.body.classList.add("Bg-white")
        return () => {
            document.body.classList.remove("Bg-white");
        };
    }, []);
    return (
        <div>
            <Herosection />
            <Whyinvest />
            <OurFeatures />
            <InvestmentCard />
            <Mfcalculator />
            <ClientTestimonial />
            <FaqAccordian />
        </div>
    )
}

export default MFLandingPage
