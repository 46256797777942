import React, { useEffect, useState } from "react";
import {
  apiCall,
  fetchData,
  indianRupeeFormat,
  rsFilter,
} from "../../../../common_utilities";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import FintooLoader from "../../../FintooLoader";
import { ADVISORY_GET_CURRENT_INVESTMENT_RECOMMENDATION } from "../../../../constants";
import ReactStarRating from "react-star-ratings-component";
import PortfolioData from "./Portfolio";
import PerformanceData from "./Performace";
import HoldingsData from "./HoldingData";
import DebtDepositData from "./DebtDepositData";
import RealEstateData from "./RealEstateData";

function Portfolioanalysis(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [secondTab, setSecondTab] = useState("tab13");
  const session = props.session;
  const [PARData, setPARData] = useState([]);

  useEffect(() => {
    getcurrentinvestmentrecommendation();
  }, []);

  const getcurrentinvestmentrecommendation = async () => {
    if (localStorage.getItem('ParReportData'))
    {
      setIsLoading(false)
      
      const ParData = localStorage.getItem('ParReportData')
      setPARData(JSON.parse(ParData));
    }
    else{
      try {
        const payload = {
          url: ADVISORY_GET_CURRENT_INVESTMENT_RECOMMENDATION,
          data: {
            fp_log_id: session["fp_log_id"],
            user_id: session["id"],
            fp_user_id: session["fp_user_id"],
            is_direct: 0
          },
          method: "post",
        };
        const getcii_data = await fetchData(payload);
        if (getcii_data["error_code"] = "100") {
          setIsLoading(false)
          setPARData(getcii_data.data);
          localStorage.setItem('ParReportData', JSON.stringify(getcii_data.data));
        }
      }
      catch (e) {
        console.log("Error", e)
      }
    }
  };

  return (
    <>
      <FintooLoader isLoading={isLoading} />
      <div
        className="tabs innerTabs subTabWrapper "
      >
        <ul
          className="nav nav-buttons nav-secoandary d-md-inline-flex justify-center align-items-md-center m-auto"
          id="intro-appendix"
        >
          <li
            className={`tab-menu-item ${secondTab == "tab13" ? "active" : ""
              }`}
          >
            <a href onClick={() => setSecondTab("tab13")}>
              Portfolio
            </a>
          </li>
          <li
            className={`tab-menu-item ${secondTab == "tab14" ? "active" : ""
              }`}
          >
            <a href onClick={() => setSecondTab("tab14")}>
              Performance
            </a>
          </li>
          <li
            className={`tab-menu-item ${secondTab == "tab15" ? "active" : ""
              }`}
          >
            <a href onClick={() => setSecondTab("tab15")}>
              Holdings
            </a>
          </li>
          {Boolean(PARData?.ssy_recomm && !PARData?.ssy_recomm.ssy_isinvested) && <li
            className={`tab-menu-item ${secondTab == "tab16" ? "active" : ""
              }`}
          >
            <a href onClick={() => setSecondTab("tab16")}>
              Debt Deposit
            </a>
          </li>}
          <li
            className={`tab-menu-item ${secondTab == "tab17" ? "active" : ""
              }`}
          >
            <a href onClick={() => setSecondTab("tab17")}>
              Real Estate
            </a>
          </li>
        </ul>
        <div>

          <div
            className={secondTab == "tab13" ? "d-block" : "d-none"}
          >
            <PortfolioData
              ReactStarRating={ReactStarRating}
              PARData={PARData}
            />
            <div className="row py-2">
              <div className=" text-center">
                <div>
                  <div className="btn-container">
                    <div className="d-flex justify-content-center">
                      <div
                        className="previous-btn form-arrow d-flex align-items-center"
                        onClick={() => setTab("tab4")}
                      >
                        <FaArrowLeft />
                        <span className="hover-text">
                          &nbsp;Previous
                        </span>
                      </div>
                      <div
                        className="next-btn form-arrow d-flex align-items-center"
                        onClick={() => setSecondTab("tab14")}
                      >
                        <span
                          className="hover-text"
                          style={{ maxWidth: 100 }}
                        >
                          Next&nbsp;
                        </span>
                        <FaArrowRight />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={secondTab == "tab14" ? "d-block" : "d-none"}
          >
            <PerformanceData
              PARData={PARData}
            />
            <div className="row py-2">
              <div className=" text-center">
                <div>
                  <div className="btn-container">
                    <div className="d-flex justify-content-center">
                      <div
                        className="previous-btn form-arrow d-flex align-items-center"
                        onClick={() => setSecondTab("tab13")}
                      >
                        <FaArrowLeft />
                        <span className="hover-text">
                          &nbsp;Previous
                        </span>
                      </div>
                      <div
                        className="next-btn form-arrow d-flex align-items-center"
                        onClick={() => setSecondTab("tab15")}
                      >
                        <span
                          className="hover-text"
                          style={{ maxWidth: 100 }}
                        >
                          Next&nbsp;
                        </span>
                        <FaArrowRight />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={secondTab == "tab15" ? "d-block" : "d-none"}
          >
            <div className="" style={{}}>
              <HoldingsData
                PARData={PARData}
              />
              <div className="row py-2">
                <div className=" text-center">
                  <div>
                    <div className="btn-container">
                      <div className="d-flex justify-content-center">
                        <div
                          className="previous-btn form-arrow d-flex align-items-center"
                          onClick={() => setSecondTab("tab14")}
                        >
                          <FaArrowLeft />
                          <span className="hover-text">
                            &nbsp;Previous
                          </span>
                        </div>
                        <div
                          className="next-btn form-arrow d-flex align-items-center"
                          onClick={() => {
                            (Boolean(PARData?.ssy_recomm && !PARData?.ssy_recomm.ssy_isinvested)) ?
                            setSecondTab("tab16") 
                            :
                            setSecondTab("tab17")
                          }}
                        >
                          <span
                            className="hover-text"
                            style={{ maxWidth: 100 }}
                          >
                            Next&nbsp;
                          </span>
                          <FaArrowRight />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={secondTab == "tab16" ? "d-block" : "d-none"}
          >
            <div className="" style={{}}>
              <DebtDepositData
                PARData={PARData}
              />
              <div className="row py-2">
                <div className=" text-center">
                  <div>
                    <div className="btn-container">
                      <div className="d-flex justify-content-center">
                        <div
                          className="previous-btn form-arrow d-flex align-items-center"
                          onClick={() => setSecondTab("tab15")}
                        >
                          <FaArrowLeft />
                          <span className="hover-text">
                            &nbsp;Previous
                          </span>
                        </div>
                        <div
                          className="next-btn form-arrow d-flex align-items-center"
                          onClick={() => setSecondTab("tab17")}
                        >
                          <span
                            className="hover-text"
                            style={{ maxWidth: 100 }}
                          >
                            Next&nbsp;
                          </span>
                          <FaArrowRight />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={secondTab == "tab17" ? "d-block" : "d-none"}
          >
            <div className="" style={{}}>
              <RealEstateData
                PARData={PARData}
              />
              <div className="row py-2">
                <div className=" text-center">
                  <div>
                    <div className="btn-container">
                      <div className="d-flex justify-content-center">
                        <div
                          className="previous-btn form-arrow d-flex align-items-center"
                          onClick={() => setSecondTab("tab16")}
                        >
                          <FaArrowLeft />
                          <span className="hover-text">
                            &nbsp;Previous
                          </span>
                        </div>
                        <div
                          className="next-btn form-arrow d-flex align-items-center"
                          onClick={() => props.settab1("tab6")}
                        >
                          <span
                            className="hover-text"
                            style={{ maxWidth: 100 }}
                          >
                            Next&nbsp;
                          </span>
                          <FaArrowRight />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Portfolioanalysis;