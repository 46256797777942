import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./style.module.css";
import WhiteOverlay from "../HTML/WhiteOverlay";
import LogoImg from "./images/logo.png";
import { AiOutlineGlobal } from "react-icons/ai";
const UAEHeader = (props) => {
  const [show, setShow] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [header, setHeader] = useState("header1");
  const listenScrollEvent = (event) => {
    // console.log('uiii', new Date());
    if (window.scrollY > 60) {
      document
        .querySelector("." + styles.header1)
        .classList.add(styles.header2);
    } else {
      document
        .querySelector("." + styles.header1)
        .classList.remove(styles.header2);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <>
      <div className={`NDA-Space ${styles.fakeSpace} fakeSpace_rn_k9`}></div>
      <div
        // style={{
        //   backgroundColor: "transparent",
        //   padding: "1rem",
        // }}
        // className={header}
        className={styles.header1}
      >
        <WhiteOverlay show={isLoading} />
        <div className=" ">
          <div className={`container-fluid ${styles["UAE-in-container"]}`}>
            <div className={`w-100 ${styles["in-container"]}`}>
              <div className="row align-items-center">
                <div className="col-md-5 col-12">
                  <a className={`${styles.UAELogo}`} href="/">
                    <img
                      className={`${styles.logo}`}
                      alt="logo"
                      src={LogoImg}
                      // src={process.env.REACT_APP_STATIC_URL + "media/FintooLogoUAE.png"}
                      // src="https://static.fintoo.in/wealthmanagement/wp-content/uploads/2022/09/fintoo-logo-01-e1663135457467-2048x604.png"
                    />
                  </a>
                </div>
                <div className="col-md-7 col-12">
                  <ul className={styles["main-navigation"]}>
                    <div className={`me-4 me-md-0 ${styles.NriBtns}`}>
                      <Link
                        className={`text-decoration-none ${styles.Btns}`}
                        to={`${process.env.PUBLIC_URL}/itr-file`}
                      >
                        file itr
                      </Link>
                    </div>
                    <div className={`${styles.NriBtns}`}>
                      {process.env.REACT_APP_MODE == "live" ? (
                        <Link
                          className={`ms-md-4 text-decoration-none ${styles.Btns}`}
                          href="https://www.fintoo.in/web/contact"
                        >
                          contact us
                        </Link>
                      ) : (
                        <Link
                          className={`ms-md-4 text-decoration-none ${styles.Btns}`}
                          to={`${process.env.PUBLIC_URL}/contact-us`}
                        >
                          contact us
                        </Link>
                      )}
                    </div>
                    <li className={`pointer ${styles.regionselectordialog}`}>
                      <div className={styles["link-url"]}>
                        {/* <img
                        src={require("./images/global_white.png")}
                        alt=""
                        style={{ width: "27.5px" }}
                      /> */}
                        <AiOutlineGlobal className={`${styles.GlobalIcon}`} />
                        {/* Help Center */}
                      </div>
                      <div className={styles["submenu-container"]}>
                        <div
                          className={`${styles.submenu}, ${styles.GlobalMenu}`}
                        >
                          <div className={styles.GlobalLang}>
                            <div className={styles.GlobalText}>
                              Change Region{" "}
                            </div>
                          </div>
                          <div className={`${styles.GloballangMenu}`}>
                            <div className={`${styles.Country}`}>
                              <p>Asia Pacific</p>
                              <div>
                                <a
                                  className={`text-decoration-none ${styles.Region}`}
                                  href="https://www.fintoo.in"
                                >
                                  India(English)
                                </a>
                              </div>
                            </div>
                            <div className={`${styles.HRline}`}></div>
                            <div className={`${styles.Country}`}>
                              <p>Middle East</p>
                              <div>
                                <a
                                  className={`text-decoration-none ${styles.Region}`}
                                  href="https://www.fintoo.ae"
                                >
                                  UAE(English)
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UAEHeader;
