import FaqSection from "../components/HTML/IpoPage/FaqSection";
import WealthContactForm from "../components/WealthManagementContactForm/";
import backImg from "../components/Layout/Fullpage/assets/insurance1-contact-form-background.jpg";
import IpoFeaturedIn from "../components/HTML/IpoPage/IpoFeaturedInSection";
import IpoClientTestimonialSection from "../components/HTML/IpoPage/IpoClientTestimonialSection";
import IpoWhyTrustUsSection from "../components/HTML/IpoPage/WhyInvestIpoSection";
import IpoHeaderSection from "../components/HTML/IpoPage/IpoPageHeaderSection";
import Fullpage from "../components/Layout/Fullpage";
import { servicesTypes } from "../components/WealthManagementContactForm/model";
import { useState } from "react";
import ThankyouSection from "../components/ThankyouSection";
import AppointmentBox from "../components/Pages/Calendly";
import ClientTestimonial from "../components/HTML/ClientTestimonial";
function IpoPage() {
  const [show, SetShow] = useState(false);

  return (
    <Fullpage>
      <div style={{ backgroundColor: "white" }}>
        <IpoHeaderSection />
        <IpoWhyTrustUsSection />
        {/* <IpoClientTestimonialSection /> */}
        <ClientTestimonial />
        {/* <IpoFeaturedIn /> */}
        <AppointmentBox eventCode={'Callback_mintyApp_113'} serviceName="IPO"  eventUrl="https://calendly.com/fintoo/15-min-consultation-call-ipo?hide_event_type_details=1" />
        {/* <WealthContactForm imgSrc={backImg} serviceType={servicesTypes.IPO} onSuccess={() => SetShow(true)} /> */}
        {show && <ThankyouSection onClose={() => SetShow(false)} />}
        <FaqSection />
      </div>
    </Fullpage>
  );
}

export default IpoPage;
