import React from 'react'
import style from "./style.module.css"
import { Link } from 'react-router-dom';
function Herosection() {
    return (
        <div className={`container ${style.Herosection}`}>
            <div className={`row ${style.Herosectioncard}`}>
                <div className='col-12 col-md-6'>
                    <div className={`${style.title}`}>Turbocharge Your Future with Quick Funds! Fast, Easy Loans to Fuel Your Ambitions</div>
                    {/* <div className={`${style.subtitle}`}>Diversify and Strengthen Your Portfolio with Indian Equities</div> */}
                    <div className='mt-4'>
                        <a style={{ textDecoration: "none", }} href='#Book-Calendly'>
                            <button>Apply Now</button>
                        </a>
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <img style={{ width: "100%" }} src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Loans_.svg'} alt="Loan" />
                </div>
            </div>
        </div>
    )
}

export default Herosection;
