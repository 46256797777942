import React, { useState, useEffect } from 'react';
import Styles from "./style.module.css"
const Team = () => {

    return (
        <section className={`${Styles.Teampage}`}>
            <div className={`${Styles.primargBg}`}>
                <div className={`${Styles.aboutusText}`}>
                    Our Team
                </div>
            </div>
            <div className={`${Styles.ourTeam}`}>
                <div className={`${Styles.teamSection}`}>
                    <div className={`${Styles.teammemberProfile}`}>
                        <img src={"https://fintooinvest.in/assets/img/manish-sir2.jpg"} alt='CEO' />
                    </div>
                    <div className={`${Styles.teamRightSection}`}>
                        <div className={`${Styles.teammemberName}`}>
                            Mr. Manish Hingar
                        </div>
                        <div  className={`${Styles.designation}`}>(CEO)</div>
                        <div className='mt-2'>
                            <div>
                                As the Chief Belief Officer of Financial Hospital, Manish Hingar is responsible for running all the facets of the business. He is a Chartered Accountant by profession and an Entrepreneur carrying more than 15+ years of expertise in Direct Tax consultancy, Financial Planning, Tax Planning, Retirement planning and other investment instruments.
                            </div>
                            <div className='pt-3'>
                                His expertise is in providing a customized innovative solution with unmatched speed which provides a distinctive edge to the group’s capability. His vast experience and vision has enabled the company to establish itself as a respected financial services provider in the country.
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`mt-5 ${Styles.teamSection}`}>
                    <div className={`${Styles.teammemberProfile}`}>
                        <img src={"https://fintooinvest.in/assets/img/prahlad.jpg"} alt='Chairman' />
                    </div>
                    <div className={`${Styles.teamRightSection}`}>
                        <div className={`${Styles.teammemberName}`}>
                            Mr. Prahlad Rai Hingar
                        </div>
                        <div  className={`${Styles.designation}`}>(Chairman)</div>
                        <div className='mt-2'>
                            <div>
                                With more than 34 years of banking experience and having law degree, Mr. P.R. Hingar is currently the chairman of Financial Hospital and our guiding light. His vast banking experience and visionary approach gives strong foundation to the group.
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`mt-5 ${Styles.teamSection}`}>
                    <div className={`${Styles.teammemberProfile}`}>
                        <img src={"https://fintooinvest.in/assets/img/suchita.jpg"} alt='MD' />
                    </div>
                    <div className={`${Styles.teamRightSection}`}>
                        <div className={`${Styles.teammemberName}`}>
                            Suchita M. Hingar 
                        </div>
                        <div  className={`${Styles.designation}`}>(MD)</div>
                        <div className='mt-2'>
                            <div>
                                With strength in Finance and Human Resource, Suchita M. Hingar ensures Financial Hospital stays a step ahead. She assists in raising funds for group and giving it the financial direction and stability for a long term strategic growth. She takes care of overall group Human Resource and Finance
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`mt-5 ${Styles.teamSection}`}>
                    <div className={`${Styles.teammemberProfile}`}>
                        <img src={"https://fintooinvest.in/assets/img/nikhil.jpg"} alt='CA' />
                    </div>
                    <div className={`${Styles.teamRightSection}`}>
                        <div className={`${Styles.teammemberName}`}>
                            Nikhil Sharma
                        </div>
                        <div  className={`${Styles.designation}`}>(CA)</div>
                        <div className='mt-2'>
                            <div>
                                Nikhil Sharma, by profession is a Chartered Accountant. His ability to understand the business environment coupled with his sound financial and taxation advisory background has enabled him to give ethical and right advice to our investors on taxation matters. His vast audit experience provides a smooth and perfect internal control system in to the group
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`mt-5 ${Styles.teamSection}`}>
                    <div className={`${Styles.teammemberProfile}`}>
                        <img src={"https://fintooinvest.in/assets/img/anu.jpg"} alt='Principal Mentor' />
                    </div>
                    <div className={`${Styles.teamRightSection}`}>
                        <div className={`${Styles.teammemberName}`}>
                            Anu Khanchandani
                        </div>
                        <div  className={`${Styles.designation}`}>(Principal Mentor)</div>
                        <div className='mt-2'>
                            <div>
                                Performance focused software technology executive with around 2 decades of experience in India and abroad with companies like Syntel, Delta Airlines and Reuters, now executing in the profile of CTO at Financial Hospital. She believes in consistently driving highly strategic business initiatives to support corporate goals through effective use of technology services. Innovator in launching technical strategies, programs and services to boost sales performance, capture new revenue opportunities, and expand into new channels.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Team;
