import styles from "./style.module.css";
import {
  FaTwitter,
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import FintooLogo from './FintooLogo.png'
import { FaXTwitter } from "react-icons/fa6";
function UAEFooter() {
  let currentDate = new Date();
  let year = currentDate.getFullYear();
  return (
    <footer>
      <div className={`${styles.Footer}`}>
        <div className={`${styles.FooterSection}`}>
          <div className={`row ${styles.FooterSectionContent}`}>
            <div className={`col-md-3 col-12 text-left ${styles.FooterNRIwidget}`}>
              <div className={`${styles.FooterwidgetImg}`}>
                <img
                  width={200}
                  src={FintooLogo}
                />
              </div>
            </div>
            <div className={`col-md-9 col-12 ${styles.FooterNRIwidget}`}>
              <div className={`${styles.Footerwidgetcontact}`}>
                <a href="tel:+971545126647">+971 54 512 6647</a>
              </div>
              <div className={`${styles.Footerwidgetcontact}`}>
                <a href="mailto:support@fintoo.ae">support@fintoo.ae</a>
              </div>
              <div className={`${styles.FooterwidgetSocialNRI}`}>
                <a target="_blank"
                  className={`${styles.FooterwidgetSocialIcons}`}
                  href="https://twitter.com/fintoouae"
                >
                  <FaXTwitter />
                </a>

                <a target="_blank"
                  className={`${styles.FooterwidgetSocialIcons}`}
                  href="https://www.facebook.com/fintoouae"
                >
                  <FaFacebookF />
                </a>

                <a target="_blank"
                  className={`${styles.FooterwidgetSocialIcons}`}
                  href="https://www.linkedin.com/showcase/fintooae/"
                >
                  <FaLinkedin />
                </a>

                <a target="_blank"
                  className={`${styles.FooterwidgetSocialIcons}`}
                  href="https://www.instagram.com/fintoouae"
                >
                  <FaInstagram />
                </a>

                <a target="_blank"
                  className={`${styles.FooterwidgetSocialIcons}`}
                  href="https://www.youtube.com/channel/UC00AMcwwfUKrV-XD5n6hWyQ/videos"
                >
                  <FaYoutube />
                </a>
              </div>
            </div>
          </div>
          {/* <div className={`${styles.FooterSection}`}></div> */}
        </div>
        <div className={`mt-2 ${styles.FooterSectionText}`}>
          <div className={`${styles.FooterSectionPara}`}>
            <p style={{
              textAlign : "justify"
            }}>
              DISCLAIMER All investments are subject to market risks. Investors
              are advised to read all relevant information carefully before
              making any investment. The value of any investment may rise or
              fall as a result of market changes. Past performance is not
              indicative of future results. By accessing this website, you agree
              to be bound by the following terms of use of this site and use of
              any materials or services contained herein. This site is provided
              by Wealth Trust Advisors L.L.C-FZ, License Number 2306282.01
              (Brand name: Fintoo) for the purposes of information to its
              clients, financial literacy, and educational purposes only. The
              information provided on this website does not comprise an offer by
              Wealth Trust Advisors L.L.C-FZ to provide any product or service
              described herein. Products and services described herein are not
              available to all persons in all geographical locations. Wealth
              Trust Advisors L.L.C-FZ has used all reasonable care and skill in
              compiling the content of this website but does not guarantee the
              accuracy or completeness of any information on this website and
              does not accept liability for any errors or omissions. The
              information provided on this website should not be made the basis
              for any personal decisions. The information contained on this
              website does not form a substitute for professional advice. Wealth
              Trust Advisors L.L.C-FZ shall not be liable to any person for any
              loss or damage which may arise from the use of any of the
              information contained on this website. No liability whatsoever is
              accepted for any direct or consequential loss arising from the use
              of the information contained on this site. Wealth Trust Advisors
              L.L.C-FZ does not guarantee that any functions at this site will
              operate without interruptions or errors and accepts no
              responsibility for any such interruptions or errors. This website
              may also provide links to other websites operated by third
              parties. Wealth Trust Advisors L.L.C-FZ is not responsible for
              such websites and their compliance with all relevant laws and
              regulations. To the full extent permissible by law, Wealth Trust
              Advisors L.L.C-FZ disclaims all responsibility for any error,
              omission, or inaccuracy in such material.
            </p>
          </div>
          <div className={`text-center ${styles.FooterSectionCopyRightText}`}>
            Copyright © {year} Fintoo,. All rights reserved
          </div>
        </div>
      </div>
    </footer>
  );
}

export default UAEFooter;
