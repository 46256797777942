import { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import Fatcaimg from "../../../Assets/05_Fatca_details.svg";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import Back from "../../../Assets/left-arrow.png";
import Resident from "../../../Assets/04_fatca_resident.svg";
import NRIResident from "../../../Assets/NRI.svg";
import NROResident from "../../../Assets/nro.png";
import HoldingNatureImg from "../../../Assets/07_holding_nature_single.svg";
import HoldingNatureJoint from "../../../Assets/08_holding_nature_joint.svg";
import HoldingNatureSurvivor from "../../../Assets/09_holding_nature_anyone_survivor.svg";
import { SegmentedControl } from "segmented-control";
import FintooButton from "../../../HTML/FintooButton";
import FintooProfileBack from "../../../HTML/FintooProfileBack";
import * as constClass from "../../../../constants";
import axios from "axios";
import { DMF_BASE_URL, DMF_DATAGATHERING_API_URL, } from "../../../../constants";
import commonEncode from "../../../../commonEncode";
import {
  CheckSession,
  fetchEncryptData,
  getParentUserId,
  getUserId,
  memberId,
} from "../../../../common_utilities";
import { useDispatch, useSelector } from "react-redux";
import FintooInlineLoader from "../../../FintooInlineLoader";

function FatcaAll(props) {
  const user_id = memberId();
  const [userDetails, setUserDetails] = useState("");
  const [validated, setValidated] = useState(false);
  const [isPoliticallyExposed, setPoliticallyExposed] = useState("2");
  const [residential, setResidential] = useState("RES");
  const [updateSeg, setUpdateSeg] = useState(0);
  const [parentName, setParentName] = useState('')
  const [parentProfile,setParentProfile] = useState([]);
  const allUserData = localStorage.getItem("user");
  const [jointDropdown,setJointDropdown] = useState([])
  const userData = JSON.parse(allUserData);
  const dispatch = useDispatch();
  const showBack = useSelector((state) => state.isBackVisible);
  const [isLoading,setIsLoading] = useState(false)
  const [holdingNature, setHoldingNature] = useState('');
  const [holding, setHolding] = useState("");

  let navDynamicNext = "";
  let navDynamicPrev = "";

  const [options, setOptions] = useState([
    {
      label: "Yes",
      value: "1"
    },
    {
      label: "No",
      value: "2",
      default: true
    },
    {
      label: "Partially Exposed",
      value: "3"
    },
  ]);

  useEffect(()=> {
    const a = options;
    switch(Number(isPoliticallyExposed)) {
      case 1:
        options[0]['default'] = true;
        options[1]['default'] = undefined;
        options[2]['default'] = undefined;
        break;
      case 3:
        options[0]['default'] = undefined;
        options[1]['default'] = undefined;
        options[2]['default'] = true;
        break;
      default:
        options[0]['default'] = undefined;
        options[1]['default'] = true;
        options[2]['default'] = undefined;
    }
    setOptions([...a]);
  }, [isPoliticallyExposed]);

  console.log('opt99', options, isPoliticallyExposed);



  const options1 = [
    {
      label: "Yes",
      value: "1",
      default: residential == "NRO" || residential == "NRI" ? true : false,
    },
    { label: "No", value: "0", default: residential == "RES" ? true : false },
  ];

  const [value, setValue] = useState("");

  const [value1, setValue1] = useState("2");

  if (residential == "NRI" && value == "1") {
    navDynamicNext = "FatcaAdd";
    navDynamicPrev = "FatcaAll";
  }
  if (residential == "RES" && value == "0") {
    navDynamicNext = "NomineeDetails";
    navDynamicPrev = "FatcaAll";
  }

  const onLoadInIt = async () => {
    var data = { user_id: '' + getUserId(), is_direct: '' + constClass.IS_DIRECT};
    try {
      var data = commonEncode.encrypt(JSON.stringify(data));
      var config = {
        method: "post",
        url: constClass.DMF_GETUSERDETAILS_API_URL,
        data: data,
      };
      var res = await axios(config);
      var response = commonEncode.decrypt(res.data);

      setUserDetails(JSON.parse(response)["data"]);
    } catch (e) {
      //console.log("catch", e);
    }
  };

  const getNewUserDetails = async () => {
    var data = { user_id: getUserId() };
    try {
      var data = commonEncode.encrypt(JSON.stringify(data));
      var config = {
        method: "post",
        url: DMF_DATAGATHERING_API_URL,
        data: data,
      };
      var res = await axios(config);
      var response = commonEncode.decrypt(res.data);
      let response_obj = JSON.parse(response);
      let error_code = response_obj["error_code"];
      if (error_code == "100") {
        localStorage.removeItem("user");
        // localStorage.setItem("user", JSON.stringify(response_obj["data"]));
      }
    } catch (e) {
      //console.log("catch", e);
    }
  };

  const defaultValues = async () => {
    if (userDetails.residential_status != "") {
      if (userDetails.residential_status == 1) {
        setResidential("RES");
      }
      if (userDetails.residential_status == 2) {
        setResidential("NRI");
      }
    } else {
      setResidential("RES");
    }
    // console.log('natu888', userDetails, typeof parentProfile, parentProfile.length);
    if (userDetails.user_holding_nature != "") {
        if (userDetails.user_holding_nature == "single") {
          setHolding("single");
        }
        if (userDetails.user_holding_nature == "joint") {
          setHolding("joint");
        }
        if (userDetails.user_holding_nature == "anyone_survivor") {
          setHolding("any");
        }
      } else {
        setHolding("single");
      }
    

      if (userDetails.politically_exposed == "1") {
        setPoliticallyExposed("1");
      } else if (userDetails.politically_exposed == "3") {
        setPoliticallyExposed("3");
      } else {
        setPoliticallyExposed("2");
      }
  

    setUpdateSeg((v) => ++v);
  };

  useEffect(() => {
    if(typeof userDetails == 'string') return;
    defaultValues();
    getUserParent();
    if(typeof userDetails != 'string' && 'joint_survivor_user_id' in userDetails && userDetails.joint_survivor_user_id) {
      setHoldingNature(userDetails.joint_survivor_user_id);
    }
  }, [userDetails]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      ApiCall();
      // addfatca();
      // console.log("afterapicall")
    } else {
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: holdingNature ? "Please try again." : 'Please select member',
          type: "error",
          autoClose: 3000,
        },
      });
    }
    setValidated(true);
  };

  const getUserParent = async () => {
    try {
      setIsLoading(true)
      var config_joint = {
        method: "POST",
        url: constClass.DMF_JOINT_DROPDOWN_API_URL,
        data: { user_id:"" + '' + getParentUserId(), is_direct: '' + constClass.IS_DIRECT },
      };

      var respon = await fetchEncryptData(config_joint);
      setJointDropdown(respon.data)

      var config_user = {
        method: "POST",
        url: constClass.DMF_GETUSERDETAILS_API_URL,
        data: { user_id:"" + getParentUserId() ,is_direct:constClass.IS_DIRECT},
      };

      var resp = await fetchEncryptData(config_user);
      // console.log("res.data",resp.data)
      setParentName(resp.data)

      var config_profile = {
        method: "POST",
        url: constClass.DMF_GET_USER_PROFILE_STATE_URL,
        data: { pan:resp.data.pan, is_direct:constClass.IS_DIRECT},
      };

      var res = await fetchEncryptData(config_profile);
      setParentProfile(res.data)
      setIsLoading(false)
    } catch (e) {
      //console.log("catch", e);
      setIsLoading(false)
  }
  };
  
  const SegmentedChange = async () => {
    if (residential == "RES") {
      setUpdateSeg((v) => ++v);
    }
    if (residential == "NRI") {
      setUpdateSeg((v) => ++v);
    }
  };

  const ResiChange = async () => {
    if (value == "1") {
      setResidential("NRI");
    }
    if (value == "0") {
      setResidential("RES");
    }
  };

  useEffect(() => {
    ResiChange();
  }, [value]);

  const ApiCall = async () => {
    let url = constClass.DMF_UPDATEBASICDETAILS_API_URL;
    let resiInt = "";
    let joint_survivor_user_id = "";
    if (residential == "NRI") {
      resiInt = "2";
    }

    if (residential == "RES") {
      resiInt = "1";
    }
    var myHolding = holding;
    if (myHolding == "any") {
      myHolding = "anyone_survivor";
      // setHolding("anyone_survivor");
      
      joint_survivor_user_id = holdingNature;
    } else if(myHolding == "joint") {
      joint_survivor_user_id = holdingNature;
    }else {
      joint_survivor_user_id = "0";
    }

    let data_sent = JSON.stringify({
      user_id: getUserId(),
      residential_status: resiInt,
      tax_resident: value,
      holding_nature: myHolding,
      politically_exposed: value1,
      joint_survivor_user_id: joint_survivor_user_id,
      is_direct:constClass.IS_DIRECT,
    });

    console.log(data_sent,"politicalyy",value1)
    var config = {
      method: "post",
      url: url,
      data: commonEncode.encrypt(data_sent),
    };
    var res = await axios(config);
    var response = commonEncode.decrypt(res.data);
    let response_obj = JSON.parse(response);
    let error_code = response_obj["error_code"];
    if (error_code == "100") {
      addfatca();
      await addNri();
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "User details updated successfully.",
          type: "success",
          autoClose: 2000,
        },
      });
      await getNewUserDetails();
      setTimeout(() => {
        props.onNext(navDynamicNext);
      }, 3500);
    } else {
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: response_obj["message"],
          type: "error",
          autoClose: 2000,
        },
      });
    }
  };

  const addNri = async () => {
    try {
     
      console.log("log",userDetails.residential_status ,"kk", residential)
      if(userDetails.residential_status == 2 && userDetails.residential_status !="" ||userDetails.user_nri_city !="" || userDetails.user_nri_country !="" || userDetails.user_nri_pincode !="" || userDetails.user_nri_address !="")
      {
        if(residential == "NRI")
        {
          var data = {
                  user_nri_state: userDetails.residential_status,
                  user_nri_city: userDetails.user_nri_city,
                  user_nri_country: userDetails.user_nri_country,
                  user_nri_pincode: userDetails.user_nri_pincode,
                  user_nri_address: userDetails.user_nri_address,
                  user_id: user_id,
                  is_direct:constClass.IS_DIRECT
                };
        }
      }
      if(residential == "RES")
        {
          var data = {
              user_id: user_id,
              is_direct: constClass.IS_DIRECT,
            };
        }
     
      var config = {
        method: "post",
        url: constClass.DMF_ADD_USER_NRI_DETAILS_API_URL,
        data: commonEncode.encrypt(JSON.stringify(data)),
      };

      var res = await axios(config);
      var response = commonEncode.decrypt(res.data);
      var response_obj = JSON.parse(response);

      console.log("Add user nri details response -> ",response_obj);
    } catch (e) {
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: "Something went Wrong...", type: "error" },
      });
      setError(true);
    }
  }

  useEffect(() => {
    onLoadInIt();
    // CheckSession();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  function onChangeValue(event) {
    setResidential(event.target.value);
  }
  const [isActive, setActive] = useState("true");

  const handleToggle = () => {
    setActive(!isActive);
  };
  //

  function onChangeValue1(event) {
    
    setHolding(event.target.value);
  }
  const [isActive1, setActive1] = useState("true");

  const handleToggle2 = () => {
    setActive1(!isActive1);
  };

  const addfatca = async () => {
    try {
      console.log(value1,"Are you politically exposed?")
      var form_data_user = {
        user_id: getUserId(),
        fatca_politically_exposed: value1,
        fatca_tax_resident: value,
        fatca_resident_country: residential == "RES" ? "94" : "0",
        is_direct:constClass.IS_DIRECT
      };
      var data = commonEncode.encrypt(JSON.stringify(form_data_user));

      var config_list = {
        method: "post",
        url: constClass.DMF_ADD_FATCA_API_URL,
        data: data,
      };

      var res = await axios(config_list);
      var response = commonEncode.decrypt(res.data);
      let responseobj = JSON.parse(response);
    } catch (e) {
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: "Something went Wrong...", type: "error" },
      });
      setError(true);
    }
  };

  // console.log('h77', holding);

  return (
    <>
      <div className="row">
        <div className="ProfileImg col-12 col-md-6">
          <div>
            <img src={Fatcaimg} alt="Fatcaimg" />
          </div>
        </div>
        <div className="RightPanel col-12 col-md-6">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="rhl-inner">
              {showBack == true && (
                <FintooProfileBack
                  title="FATCA Details"
                  onClick={() => props.onPrevious()}
                />
              )}
              <div className="Fatca">
                <div className="profile-space profile-border">
                  <h5 className="ResiStatus">Residential Status</h5>
                  <p>Please select your residential status</p>
                  <br />
                  <div
                    className="d-flex text-center"
                    onChange={onChangeValue}
                    style={{ marginTop: "0rem" }}
                  >
                    <div
                      className={`w-33 rs-type-bx pointer ${
                        residential == "RES" ? "active" : ""
                      }`}
                    >
                      <div
                        className="rs-type-ck"
                        onClick={() => {
                          setResidential("RES");
                          SegmentedChange();
                        }}
                      >
                        <label>
                          <img
                            src={Resident}
                            style={{
                              width: "60px",
                            }}
                          />
                        </label>
                        <div className="ResidentType">
                          <span>RES-Resident</span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`w-33 rs-type-bx pointer ${
                        residential == "NRI" ? "active" : ""
                      }`}
                    >
                      <div
                        className="rs-type-ck"
                        onClick={() => {
                          setResidential("NRI");
                          SegmentedChange();
                        }}
                      >
                        <label>
                          <img
                            src={NRIResident}
                            className={
                              residential.toLowerCase() == "nri"
                                ? "ColorChangeDark"
                                : "ColorChange"
                            }
                            style={{
                              width: "60px",
                            }}
                          />
                        </label>
                        <div className="ResidentType">
                          <span style={{ whiteSpace: "nowrap" }}>
                            NRI – Non-Resident Indian
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="profile-space profile-border">
                  <h5 className="ResiStatus">
                    Tax Resident in any other country than India?
                  </h5>
                  <div>
                    <SegmentedControl
                      name="taxResident"
                      className="my-segment"
                      options={options1}
                      key={"sg-" + updateSeg}
                      style={{ width: "66.66%", color: "#042b62" }} // purple400
                      setValue={(newValue) => {
                        setValue(newValue);
                        if (newValue === "yes") {
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="profile-space profile-border">
                  <h5 className="ResiStatus">Account Holding Nature</h5>
                  <p>
                    {" "}
                    <b>Note</b> : The nature of the first account will by
                    default be Single.
                  </p>
                  <br />
                  <div
                    className="d-flex text-center"
                    
                    style={{ marginTop: "0rem" }}
                  >
                    <div
                      className={`w-33 rs-type-bx pointer ${
                        holding == "single" ? "active" : "single"
                      }`}
                      onClick={() => setHolding("single")}
                    >
                      <label htmlFor="Single">
                        <img
                          src={HoldingNatureImg}
                          style={{
                            width: "60px",
                          cursor : "pointer"
                          }}
                        />
                      </label>
                      <div className="ResidentType">
                        <span>Single</span>
                      </div>
                    </div>

                    <div
                      className={`w-33 rs-type-bx pointer  ${
                        holding == "joint" ? "active" : ""
                      }`}
                      onClick={() => setHolding((v)=> {
                        if((getParentUserId() != getUserId()) ) {
                            return "joint"
                          
                        } else {
                          return v;
                        }
                      })}
                    >
                      <label htmlFor="Joint">
                        <img
                          src={HoldingNatureJoint}
                          style={{
                            width: "60px",
                            cursor : "pointer"
                          }}
                        />
                      </label>
                      <div className="ResidentType">
                        <span>Joint</span>
                      </div>
                    </div>

                    <div
                      className={`w-33 rs-type-bx pointer  ${
                        holding == "any" ? "active" : ""
                      }`}
                      onClick={() => setHolding((v) => {
                        // console.log(v)
                        if((getParentUserId() != getUserId()) ) {
                            return "any"
                        } else {
                          return v;
                        }
                      })}
                    >
                      <label htmlFor="Anyone">
                        <img
                          src={HoldingNatureSurvivor}
                          style={{
                            width: "60px",
                            cursor : "pointer"
                          }}
                        />
                      </label>
                      <div className="ResidentType">
                        <span style={{ whiteSpace: "nowrap" }}>
                          Anyone or Survivor
                        </span>
                      </div>
                    </div>
                  </div>
                  {holding == "joint"  ||  holding == "any" ? (
                    <div className="d-md-flex justify-content-start">
                      <div className="w-50  mt-4 ms-auto pe-4">
                        <div className={`d-flex data-loader align-items-center ${isLoading ? 'justify-content-between' : 'justify-content-end'}`}>
                        <FintooInlineLoader isLoading={isLoading} />  

                        <Form.Select
                          aria-label="Default select example"
                          className="shadow-none"
                          style={{
                            borderRadius: "10px",
                            height: "3rem",
                            outline: "none",
                            marginLeft: "-1.5rem"
                          }}
                          required
                          value={holdingNature}
                          onChange={(e)=> {
                            setHoldingNature(e.target.value);
                          }}
                        >
                          <option value={''}>---Select---</option>
                          {jointDropdown.map((v) => (
                             <option value={v.user_id} >
                             {v.name}
                            </option>
                          ))}
                        </Form.Select>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="profile-space profile-border">
                  <h5 className="ResiStatus">Are you politically exposed?</h5>

                  <div className="">
                    <SegmentedControl
                      className="my-segment line-2-seg"
                      name="politicalStatus"
                      width={"100%"}
                      key={"sg-" + updateSeg}
                      options={options}
                      style={{ width: "100%", color: "#042b62" }} // purple400
                      setValue={(newValue) => {
                        setValue1(newValue);
                        if (newValue === "yes") {
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="profile-space">
                  <FintooButton
                    className="d-block ms-auto me-0"
                    title={"Next"}
                    type="submit"
                  />
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default FatcaAll;
