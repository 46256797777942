import { useEffect, useState } from "react";
import style from "./style.module.css";

const TableData = (props) => {
  return (
    <>
    <td className={style.table_data}>
    <div className="d-flex align-items-center">
      <div className={style.icon}>
        <img width={30} src={`${process.env.PUBLIC_URL}/static/media/companyicons/${props.data.amc_code}.png`} style={{borderRadius:"50%"}}/>
      </div>
      <a href={`${process.env.PUBLIC_URL}/mutual-fund/MutualFund/${props.data.slug}`} style={{textDecoration: "none"}}>
        <div className={style.boldText}>{props.data.scheme}</div>
      </a>
    </div>
    </td>
    <td className={style.boldText}>
    <span className={props.data.gain_loss > 0 ? style.greenText : style.redText}>{props.data.gain_loss}</span><span className={style.grayText}>({props.data.xirr_percentage}%)</span>
    </td>
    <td className={style.boldText}>₹ {props.data.curr_val}</td>
    </>
  );
};

const MaxGainerLooser = (props) => {
  const [activeButton, setActiveButton] = useState("fund");
  const [gainer, setGainer] = useState(true);
  const [data, setData] = useState({});
  
  useEffect(()=> {
    if (gainer) {
      setData(props.gainerData);
    } else {
      setData(props.loserData);
    }
  }, [gainer]);

  return (
    <>
      <div>
        <p className={style.boxtitle}>Max Gainer | Loser</p>
        <div className="d-flex align-items-center justify-content-between py-4">
          <div className="d-flex align-items-center">
            <div className={style.btn_con}>
              <p
                onClick={() => {
                  setActiveButton("fund");
                }}
                className={`mb-0 ${style.button} ${
                  activeButton == "fund" ? style.active : ""
                }`}
              >
                Mutual Fund
              </p>
            </div>
            <div className={style.btn_con}>
              <p
                onClick={() => {
                  setActiveButton("stocks");
                }}
                className={`mb-0 ${style.button} ${
                  activeButton == "stocks" ? style.active : ""
                }`}
              >
                Stocks
              </p>
            </div>
          </div>
          <div>
            <p className={`mb-0 ${style.button}`} onClick={()=> setGainer((v)=> !v)}>
              {gainer ? 'Gainer' : 'Loser'} &nbsp;&nbsp;<i class="fa-solid fa-filter"></i>
            </p>
          </div>
        </div>

        <table className={` table`}>
          <thead className={style.header}>
            <tr>
              <th>Scheme Name</th>
              <th>Price & Change %</th>
              <th>Curr Valuation</th>
            </tr>
          </thead>
          <tbody>
              {data.length > 0 && data.map((item)=> (
                <tr>
                  <TableData data={item}/>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default MaxGainerLooser;
