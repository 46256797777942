import { useState } from "react";
import FaqAccordianContent from "./FaqAccordianContent";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import style from "./style.module.css";

function FaqAccordian() {
    const [activeIndex, SetActiveIndex] = useState(0);

    const updateAccordionIndex = (idx) => {
        if (activeIndex === idx) {
            return SetActiveIndex(() => null);
        } else {
            return SetActiveIndex(() => idx);
        }
    };

    const isActive = (idx) => activeIndex === idx;

    return (
        <>
            <div className="container" >
                <div className={`${style.faqtitle}`}>Frequently Asked Questions</div>
                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]} `}
                        onClick={() => updateAccordionIndex(0)}
                        style={isActive(0) ? { color: "#042b62" } : { color: "black" }}
                    >
                        {" "}
                        <span>What is Term Insurance?</span>{" "}
                        {isActive(0) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(0)}>
                    Term insurance is a type of life insurance that provides coverage for a specific period or "term." If the policyholder passes away during this term, the insurer pays a death benefit to the designated beneficiaries. It's designed to offer financial protection at an affordable cost for a fixed period.
                    </FaqAccordianContent>
                </div>
                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]}`}
                        onClick={() => updateAccordionIndex(1)}
                        style={isActive(1) ? { color: "#042b62" } : { color: "black" }}
                    >
                        <span>Why Do I Need Term Insurance?</span>{" "}
                        {isActive(1) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(1)}>
                    Term insurance provides essential financial protection for your loved ones in the event of your untimely death. It ensures that your family receives a lump sum payment to cover expenses, debts, or future needs, offering peace of mind that they will be financially secure.
                    </FaqAccordianContent>
                </div>
                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]}`}
                        onClick={() => updateAccordionIndex(2)}
                        style={isActive(2) ? { color: "#042b62" } : { color: "black" }}
                    >
                        <span>
                        What Does Life Insurance Cover?
                        </span>{" "}
                        {isActive(2) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(2)}>
                    Life insurance offers a financial safety net to your family by paying a predetermined amount, known as the sum assured, in the event of your death. It helps cover living expenses, debt repayments, and future financial needs, ensuring your loved ones are supported even in your absence.
                    </FaqAccordianContent>
                </div>

                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]}`}
                        onClick={() => updateAccordionIndex(4)}
                        style={isActive(4) ? { color: "#042b62" } : { color: "black" }}
                    >
                        <span>
                        Why Should I Get Health Insurance Now?
                        </span>{" "}
                        {isActive(4) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(4)}>
                    Health insurance provides crucial financial protection against unexpected medical expenses. By getting coverage now, you ensure access to quality healthcare without the burden of high costs, safeguard your finances against rising medical bills, and gain peace of mind for you and your family.
                    </FaqAccordianContent>
                </div>
                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]}`}
                        onClick={() => updateAccordionIndex(5)}
                        style={isActive(5) ? { color: "#042b62" } : { color: "black" }}
                    >
                        <span>What is a Premium in Insurance?</span>{" "}
                        {isActive(5) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(5)}>
                    A premium is the amount you pay to an insurance company for coverage. It can be paid monthly, quarterly, or annually, depending on your policy. The premium amount is determined based on various factors, including coverage type, sum assured, and personal details.
                    </FaqAccordianContent>
                </div>
            </div>
        </>
    );
}

export default FaqAccordian;
