import { InlineWidget, useCalendlyEventListener } from "react-calendly";

const Calendar = ({ eventCode, url, serviceName, extraParams = {}  }) => {
  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: async (e) => {
      console.log("url---->", e.data.payload.invitee.uri);
      localStorage.setItem("eventURL", e.data.payload.invitee.uri);
      localStorage.setItem("eventType", eventCode);
      localStorage.setItem("serviceName", serviceName);
      localStorage.setItem('extraParams', JSON.stringify(extraParams));
    },
  });
  console.log('e----->', eventCode, url);
  return (
    <>
      <InlineWidget url={url} />
    </>
  );
};
export default Calendar;
