import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import { useLocation } from "react-router-dom";

const OurAchievements = () => {
  const timerStarted = useRef(false);
  const autoInc = useRef(0);
  const location = useLocation();
  const [pageurl, setPageurl] = useState(false);

  // console.log('nil 8384');
  useEffect(() => {
    console.log('nil', location);
    if (location.pathname.indexOf("/nri-desk-dubai") > -1) {
      setPageurl(true);
    } else {
      setPageurl(false);
    }
  }, [location]);
  useEffect(() => {
    // startAnimation();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (document.getElementById("oa-y-9") == null) return;

    if (
      scrollPosition >
        document.getElementById("oa-y-9").offsetTop -
          document.getElementById("oa-y-9").clientHeight &&
      timerStarted.current == false
    ) {
      timerStarted.current = true;

      if (document.getElementById("b1").innerHTML * 1 === 0) {
        startAnimation();
      }
    }
  };

  const startAnimation = () => {
    // var a = 0;
    const max = 1000;
    const min = 100;

    var b1 = document.getElementById("b1").getAttribute("max") * 1;
    var b2 = document.getElementById("b2").getAttribute("max") * 1;
    var b3 = document.getElementById("b3").getAttribute("max") * 1;
    var b4 = document.getElementById("b4").getAttribute("max") * 1;
    var b5 = document.getElementById("b5").getAttribute("max") * 1;
    var b6 = document.getElementById("b6").getAttribute("max") * 1;

    var timer = setInterval(() => {
      document.getElementById("b1").innerHTML = Math.round(
        (autoInc.current * (b1 / min)) / 10
      );
      document.getElementById("b2").innerHTML = Math.round(
        (autoInc.current * (b2 / min)) / 10
      );
      document.getElementById("b3").innerHTML = Math.round(
        (autoInc.current * (b3 / min)) / 10
      );
      document.getElementById("b4").innerHTML =
        "" +
        Math.round((autoInc.current * (b4 / min)) / 10).toLocaleString("en-IN");
      // Math.round((autoInc.current * (b5 / min)) / 10).toLocaleString("us-IN");
      document.getElementById("b5").innerHTML =
      "" +
      // Math.round((autoInc.current * (b4 / min)) / 10).toLocaleString("en-IN");
    Math.round((autoInc.current * (b5 / min)) / 10).toLocaleString("us-IN");

    document.getElementById("b6").innerHTML = Math.round(
      (autoInc.current * (b6 / min)) / 10
    );
    
      //
      autoInc.current = autoInc.current + 1;
      if (autoInc.current > max) {
        clearInterval(timer);
      }
    }, 1);
  };

  const stopTimer = () => {
    // timer_x.current = null;
    return clearInterval(timer_x.current);
  };

  console.log(pageurl, 'nil');


  return (
    <section className={`${styles.section} my-5`} id="oa-y-9">
      <div className={styles.overlay}></div>
      <div className={`${styles.container} container`}>
        <div className={`text-center ${styles.GlobalText}`}>
          Global Scorecard
        </div>
        <div style={{ padding: "0px 0px 50px 0px" }}>
          <div className={`${styles.cards} timer-dv`}>
            <div className={styles["card-item"]}>
              <div>
                <p className={styles.number}>
                  <span id="b1" max="8">
                    0
                  </span>
                </p>
                <p className={styles.label}>Years of Business</p>
              </div>
            </div>
            <div className={styles["card-item"]}>
              <div>
                <p className={styles.number}>
                  <span id="b2" max="250">
                    0
                  </span>
                  <span className={styles.smallfont}>+</span>
                </p>
                <p className={styles.label}>Corporate tie-ups</p>
              </div>
            </div>
            <div className={styles["card-item"]}>
             
                <div className={pageurl === true ?  'd-none' : ''}>
                  <p className={styles.number}>
                    <span id="b3" max="300">
                      0
                    </span>
                    <span className={styles.smallfont}>+ cr</span>
                  </p>
                  <p className={styles.label}>Assets Under Advisory(AUA)</p>
                </div>
                <div className={pageurl === true ?  '' : 'd-none'}>
                  <p className={styles.number}>
                    <span id="b6" max="3000">
                      0
                    </span>
                    <span className={styles.smallfont}>+ Million</span>
                  </p>
                  <p className={styles.label}>Assets Under Advisory(AUA)</p>
                </div>
             
            </div>
            <div className={styles["card-item"]}>
               <div className={pageurl === true ?  'd-none' : ''}>
                  <p className={styles.number}>
                    <span id="b4" max="140000">
                      0
                    </span>
                    <span className={styles.smallfont}>+</span>
                  </p>
                  <p className={styles.label}>Happy Clients</p>
                </div>
                <div className={pageurl === true ?  '' : 'd-none'}>
                  <p className={styles.number}>
                    <span id="b5" max="140000">
                      0
                    </span>
                    <span className={styles.smallfont}>+</span>
                  </p>
                  <p className={styles.label}>Happy Clients</p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurAchievements;
