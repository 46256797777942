import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BASE_API_URL,
} from "../../../constants";
import {
  apiCall,
  getUserId,
  successAlert,
} from "../../../common_utilities";
import CommonDashboardLayout from "../../../components/Layout/Commomdashboard";
import CardBox from "../../../components/CommonDashboard/CardBox";
import Goals from "../../../components/CommonDashboard/Goals";
import commonEncode from "../../../commonEncode";
import Investsection from "./Investsection/index";
import TotalGoals from "../../../components/CommonDashboard/TotalGoals";
import FintooInlineLoader from "../../../components/FintooInlineLoader";

const triggerDefaulyValues = [
  { 'report_frequency': 'Monthly' },
  { 'portfolio_percentage': '5%', 'portfolio_movement': 'Both', 'portfolio_Mode': 'Both' },
  { 'mutual_fund_percentage': '5%', 'mutual_fund_movement': 'Both', 'mutual_fund_Mode': 'Both' },
  { 'stock_percent': '5%', 'stock_movement': 'Both', 'stock_Mode': 'Both' },
  { 'buzz_mutual_fund_frequency': 'Monthly', 'buzz_mutual_fund_Mode': 'Both' },
  { 'goal_frequency': '1 Month' }
]
let url = BASE_API_URL + "managetrigger/";
let payload = {
  "user_id": getUserId(),
  "tag": "all",
  "data": triggerDefaulyValues
}
let default_data = apiCall(url, payload, false, false);
console.log(default_data)


const Dashboard = ({ lifecyclestatus, renewpopup, subscriptionenddate }) => {
  const [selectedGoalTab, setSelectedGoalTab] = useState(5);
  const [goalCountData, setGoalCountData] = useState([]);
  const [goalData, setGoalData] = useState([]);
  const [isMemberSelected, setIsMemberSelected] = useState(false);
  const [isDataLoaded, setDataLoadFlag] = useState(false);
  const [memberID, setMemberID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [planId, setPlanId] = useState('')
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const value = params.get('isreportgenerated');
  useEffect(() => {
    if (value == 1) {
      successAlert("Your Goal based planning report has been generated and shared with your designated wealth manager. We will get in touch with you shortly and help you understand the report.");
    }
  }, [value]);
  return (
    <CommonDashboardLayout>

      {/* <p style={{ height: "2rem" }}></p> */}
      <div
        style={{
          marginTop: "1rem",
        }}
      >
        <div className="Section">
          <CardBox lifecyclestatus={lifecyclestatus} renewpopup={renewpopup} subscriptionenddate={subscriptionenddate} />
        </div>
        <div className="Section">
          <Investsection />
        </div>
        <div className="Section">
          <Goals lifecyclestatus={lifecyclestatus} renewpopup={renewpopup} />
        </div>
        <p style={{ height: "2rem" }}></p>
      </div>
    </CommonDashboardLayout>
  );
};

export default Dashboard;
