import BoldSectionHeader from "./BoldSectionHeader.js";
import Testimonial from "./Testimonial.js";
import ClientReviews from "./ClientReviews.js";
import styles from "./style.module.css";
import BorderDivider from "./BorderDivider.js";
const ClientTestimonial = () => {
  return (
    <section className={`${styles.investsection} py-5`}>
      <div className="text-center">
        <span className={`${styles["section-title"]}`}>
          Wall of <span className={`${styles.DiffText}`}>Trust</span> @ FintooInvest
        </span>
        <BorderDivider />
      </div>
      <div>
        <ClientReviews />
      </div>
    </section>
  );
};
export default ClientTestimonial;
