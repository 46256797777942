import { useState } from "react";
import FaqAccordianContent from "./FaqAccordianContent";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import style from "./style.module.css";

function FaqAccordian() {
    const [activeIndex, SetActiveIndex] = useState(0);

    const updateAccordionIndex = (idx) => {
        if (activeIndex === idx) {
            return SetActiveIndex(() => null);
        } else {
            return SetActiveIndex(() => idx);
        }
    };

    const isActive = (idx) => activeIndex === idx;

    return (
        <>
            <div className="container" >
                <div className={`${style.faqtitle}`}>Frequently Asked Questions</div>
                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]} `}
                        onClick={() => updateAccordionIndex(0)}
                        style={isActive(0) ? { color: "#042b62" } : { color: "black" }}
                    >
                        {" "}
                        <span>What are international equities?</span>{" "}
                        {isActive(0) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(0)}>
                        International equities are shares of companies traded on foreign stock exchanges. Investing in international equities allows you to diversify your portfolio and gain exposure to global markets.
                    </FaqAccordianContent>
                </div>
                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]}`}
                        onClick={() => updateAccordionIndex(1)}
                        style={isActive(1) ? { color: "#042b62" } : { color: "black" }}
                    >
                        <span>How can I invest in U.S. stocks from India?</span>{" "}
                        {isActive(1) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(1)}>
                        You can invest in U.S. stocks from India through global brokerage accounts, international mutual funds, or ETFs. Platforms like FintooInvest provide access to U.S. stock markets and allow you to buy shares of American companies.
                    </FaqAccordianContent>
                </div>
                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]}`}
                        onClick={() => updateAccordionIndex(2)}
                        style={isActive(2) ? { color: "#042b62" } : { color: "black" }}
                    >
                        <span>
                            What are the benefits of investing in international stocks?
                        </span>{" "}
                        {isActive(2) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(2)}>
                        Investing in international stocks offers diversification, access to high-growth markets, and potential risk reduction by spreading investments across various economic regions and industries.
                    </FaqAccordianContent>
                </div>

                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]}`}
                        onClick={() => updateAccordionIndex(4)}
                        style={isActive(4) ? { color: "#042b62" } : { color: "black" }}
                    >
                        <span>
                            Are there risks associated with investing in foreign stocks?
                        </span>{" "}
                        {isActive(4) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(4)}>
                        Yes, investing in foreign stocks involves risks such as currency fluctuations, geopolitical instability, and different regulatory environments. It's crucial to research and understand these risks before investing.
                    </FaqAccordianContent>
                </div>
                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]}`}
                        onClick={() => updateAccordionIndex(5)}
                        style={isActive(5) ? { color: "#042b62" } : { color: "black" }}
                    >
                        <span>Can I invest in international stocks through mutual funds or ETFs?</span>{" "}
                        {isActive(5) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(5)}>
                        Yes, you can invest in international stocks through mutual funds or ETFs that focus on global or regional markets. These funds provide a convenient way to gain exposure to a diverse range of international companies.
                    </FaqAccordianContent>
                </div>
            </div>
        </>
    );
}

export default FaqAccordian;
