import React from 'react'
import style from "./style.module.css"

const toolsData = [
    {
        id: 1,
        imgSrc: process.env.PUBLIC_URL + '/static/media/LandingPages/New/HLV.svg',
        title: 'Human Life Value Calculator (HLV)',
        desc: 'Deploy, manage and debug applications with end-to-end ownership without experiencing bottlenecks or battling Kubernetes hurdles.'
    },
    {
        id: 2,
        imgSrc: process.env.PUBLIC_URL + '/static/media/LandingPages/New/your_life.svg',
        title: 'Protect Your Life',
        desc: 'Deploy, manage and debug applications with  end-to-end ownership without experiencing  bottlenecks or battling Kubernetes hurdles.'
    },
    {
        id: 3,
        imgSrc: process.env.PUBLIC_URL + '/static/media/LandingPages/New/Save_life_cancer.svg',
        title: 'Save Yourself from Cancer',
        desc: 'Identify availability issues, misconfigurations, lack of resources, K8s anomalies and failed deploys. feedback and health checks.'
    },
    {
        id: 4,
        imgSrc: process.env.PUBLIC_URL + '/static/media/LandingPages/New/health_insurance_cover.svg',
        title: 'Health Insurance Calculator',
        desc: 'Identify availability issues, misconfigurations, lack of resources, K8s anomalies and failed deploys. feedback and health checks.'
    },
    {
        id: 5,
        imgSrc: process.env.PUBLIC_URL + '/static/media/LandingPages/New/SIP.svg',
        title: 'SIP Calculator',
        desc: 'Identify availability issues, misconfigurations, lack of resources, K8s anomalies and failed deploys. feedback and health checks.'
    },
    {
        id: 6,
        imgSrc: process.env.PUBLIC_URL + '/static/media/LandingPages/New/Lumpsum.svg',
        title: 'Lumpsum Calculator',
        desc: 'Identify availability issues, misconfigurations, lack of resources, K8s anomalies and failed deploys. feedback and health checks.'
    },
    {
        id: 7,
        imgSrc: process.env.PUBLIC_URL + '/static/media/LandingPages/New/Global_SIP_Calculator.svg',
        title: 'Goal SIP Calculator',
        desc: 'Identify availability issues, misconfigurations, lack of resources, K8s anomalies and failed deploys. feedback and health checks.'
    },

];

const Toolsbox = ({ onToolClick, selectedTool }) => {
    return (
        <div>
            <div className={style.toolsandcalculatortxt}>Tools & Calculator</div>
            <div className={style.toolBoxSection}>
            {toolsData.map((tool) => (
                    <div
                        key={tool.id}
                        className={`${style.toolBox} ${selectedTool === tool.id ? style.selected : ''}`} 
                        onClick={() => onToolClick(tool.id, tool.title)} 
                    >
                        <div>
                            <img src={tool.imgSrc} alt={tool.title} />
                        </div>
                        <div className={style.toolstype}>{tool.title}</div>
                        <div className={style.toolsDesc}>{tool.desc}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Toolsbox
