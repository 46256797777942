const INITIAL_STATE = {
  progressValue: 0,
  progressTitle: null,
  hideSideBar: false,
  loggedIn: false,
  cartCount: 0,
  toastMessage: {},
  memberInfo: {},
  memberChanged: "",
  isBackVisible: true,
  page: "dashboard",
  selectedRM: {},
  addBankStepCount: 0,
  investDashboardTabActiveTab: "mf",
  resetDragzone: false,
  dubaiLive: false,
  hideMainFooter: false,
  downloadLinkHolding: {},
  skipDetails: 0,
  reportDownload: 1,
  reloadAssumption: 0,
  dgSidebarData: {
    kyc_waiting: true,
    kyc_verify: "0",
    percentage: "0",
    profileData: {}
  },
};

const fintooReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_PROGRESS":
      return Object.assign({}, state, {
        progressValue: action.payload,
      });
    case "SET_PROGRESS_NAME":
      return Object.assign({}, state, {
        progressTitle: action.payload,
      });
    case "HIDE_SHOW_SIDEBAR":
      return Object.assign({}, state, {
        hideSideBar: action.payload,
      });
    case "LOGGIN_LOGOUT":
      return Object.assign({}, state, {
        loggedIn: action.payload,
      });
    case "UPDATE_CART_COUNT":
      return Object.assign({}, state, {
        cartCount: action.payload,
      });
    // case "CHANGE_MEMBER":
    //   return Object.assign({}, state, {
    //     memberInfo: action.payload,
    //   });
    case "CHANGE_MEMBER":
      return Object.assign({}, state, {
        memberChanged: action.payload,
      });
    case "RENDER_TOAST":
      return Object.assign({}, state, {
        toastMessage: action.payload,
      });
    case "PROFILE_BACK":
      return Object.assign({}, state, {
        isBackVisible: action.payload,
      });
    case "CHANGE_COMMONDASHBOARD":
      return Object.assign({}, state, {
        page: action.payload,
      });
    case "RM_DETAILS":
      console.log("rm89", action.payload);
      return Object.assign({}, state, {
        selectedRM: action.payload,
      });
    case "BANK_ADD_COUNT":
      // console.log('rm89', action.payload);
      return Object.assign({}, state, {
        addBankStepCount: state.addBankStepCount + 1,
      });

    case "NOMINEE BACK":
      return Object.assign({}, state, {
        nominee_back: state.nominee_back,
      });
    case "INVEST_DASHBOARD_CHANGE_TAB":
      return Object.assign({}, state, {
        investDashboardTabActiveTab: action.payload,
      });
    case "RESET_DRAGZONE":
      return Object.assign({}, state, {
        resetDragzone: action.payload,
      });
    case "DUBAI_LIVE":
      return Object.assign({}, state, {
        dubaiLive: action.payload,
      });
    case "HIDE_FOOTER":
      return Object.assign({}, state, {
        hideMainFooter: action.payload,
      });
    case "SKIP_DETAILS":
      return Object.assign({}, state, {
        skipDetails: action.payload,
      });
    case "LINK_HOLDING_EVENT":
      return Object.assign({}, state, {
        downloadLinkHolding: action.payload,
      });
    case "RELOAD_SIDEBAR":
      return Object.assign({}, state, {
        reloadAdvisorySideBar: action.payload,
      });
    case "RELOAD_ASSUMPTION":
      return Object.assign({}, state, {
        reloadAssumption: action.payload,
      });
    case "DG_SIDEBAR_DATA":
      return Object.assign({}, state, {
        dgSidebarData: action.payload,
      });
    case "REPORT_DOWNLOAD_STATUS":
      return Object.assign({}, state, {
        reportDownload: action.payload,
      });
    default:
      return state;
  }
};

export default fintooReducer;
