import React from 'react'
import Style from "./style.module.css";
import Arrow from "./Curve_arrow.svg"
import { Link } from 'react-router-dom';
function InvestmentCard() {
    return (
        <div className={`${Style.InvestmentCard}`}>
            <div className={`${Style.title}`}>Already have Investments ?</div>
            <div className={`${Style.subtitle}`}>Import them and manage on FintooInvest effortlessly!</div>
            <div className={`${Style.investbtnView}`}>
                <Link style={{ textDecoration: "none" }} to={`${process.env.PUBLIC_URL}/login`}>
                    <button>FETCH NOW</button></Link>
                <img src={Arrow} alt="Invest Here" />
                {/* <img  src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/MF_Dashboard.svg'} alt="Invest Here" /> */}
            </div>
        </div>
    )
}

export default InvestmentCard