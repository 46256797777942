import WealthContactForm from "../components/WealthManagementContactForm/";
import backImg from "../components/Layout/Fullpage/assets/insurance1-contact-form-background.jpg";
import SecuritySection from "../components/HTML/SecuritySection";
import BondInvestmentHeaderSection from "../components/HTML/BondInvestment/BondInvestmentHeader";
import WhyChooseBondInvestmentSection from "../components/HTML/BondInvestment/WhyChooseBondInvestmentSection";
import BondinvestmentVideoSection from "../components/HTML/BondInvestment/BondInvestmentVideoSection";
import { servicesTypes } from "../components/WealthManagementContactForm/model";
import Fullpage from "../components/Layout/Fullpage";
import ThankyouSection from "../components/ThankyouSection";
import { useState } from "react";
import AppointmentBox from "../components/Pages/Calendly";
import FaqSection from '../components/HTML/BondInvestment/FaqSection'
function BondInvestment() {
  const [show, SetShow] = useState(false);

  return (
    <Fullpage>
      <div style={{ backgroundColor: "white" }}>
        <BondInvestmentHeaderSection />
        <WhyChooseBondInvestmentSection />
        <BondinvestmentVideoSection />
        <SecuritySection />
        {/* <FaqSection /> */}
        <section
          style={{
            
          }}
          id="bondInvestmentContact"
        >
          <AppointmentBox eventCode={'Callback_mintyApp_33'} serviceName="Bonds" eventUrl="https://calendly.com/fintoo/15-min-consultation-call-bond-investment?hide_event_type_details=1" />
          {/* <WealthContactForm imgSrc={backImg} serviceType={servicesTypes.bondInvestment} onSuccess={() => SetShow(true)} /> */}
        </section>
        <FaqSection />
        {show && <ThankyouSection onClose={() => SetShow(false)} />}
      </div>
    </Fullpage>
  );
}

export default BondInvestment;
