import React from 'react';
import styles from './style.module.css';
import CustomSlider from './CustominsuranceSlider';

const SliderInput = ({ label, min, max, value, unit, onChange, formatValue, Rupee, onBlur }) => {
    return (
        <div className={styles.sliderContainer}>
            <div className={styles.labelRow}>
                <span>{label}</span>
                <div className={styles.inputWithUnit}>
                    {
                        unit && <span style={{ display: Rupee ? "inline" : "none", color: "#042b62" }} className={styles.unitLabel}>{unit}</span>
                    }
                    <input
                        type="number"
                        className={styles.inputNumber}
                        value={value}
                        min={min}
                        max={max}
                        onChange={(e) => onChange(e.target.value)}
                        onBlur={onBlur}
                    />

                    {
                        formatValue && unit && <span style={{ display: Rupee == false ? "inline" : "none", color: "#042b62" }} className={styles.unitLabel}>{unit}</span>
                    }
                </div>
            </div>

            <CustomSlider
                min={min}
                max={max}
                value={value}
                onChange={onChange}
            />

            <div className={styles.rangeLabels}>
                <span>{formatValue ? formatValue(min) : min}</span>
                <span>{formatValue ? formatValue(max) : max}</span>
            </div>
        </div>
    );
};

export default SliderInput;
