import React, { useEffect, useState } from "react";
import TotalGoals from "./TotalGoals.js";
import { ADVISORY_GET_GOALS_API_URL, CHECK_SESSION, GET_MEMBER_LIST } from "../../constants";
import { apiCall, getItemLocal, getParentUserId, getUserId } from "../../common_utilities";
import commonEncode from "../../commonEncode";
import FintooInlineLoader from "../FintooInlineLoader";

const Goals = ({lifecyclestatus,renewpopup}) => {
  const [selectedGoalTab, setSelectedGoalTab] = useState(5);
  const [goalCountData, setGoalCountData] = useState([]);
  const [goalData, setGoalData] = useState([]);
  const [isMemberSelected, setIsMemberSelected] = useState(false);
  const [memberID, setMemberID] = useState("");
  const [planId,setPlanId]=useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoaded, setDataLoadFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  useEffect(() => {
    getMemberList();
  }, []);

  const getMemberList = async () => { 
    try {
      let url = CHECK_SESSION;
      let data = { user_id: getParentUserId(), sky : getItemLocal("rmf"), is_direct: 0 };

      let session_data = await apiCall(url, data, true, false);
      setPlanId(session_data['data']['plan_id'])
      
      data = {
        user_id: getParentUserId(),
        is_direct: "0",
      };
      let member_id = null;
      let member_data = await apiCall(GET_MEMBER_LIST, data, true, false);
      if (member_data.error_code == "100") {
        member_data.data.forEach((v) => {
          if (v.id == getUserId()) {
            let fp_user_id = v.fp_user_id;
            
            setMemberID(fp_user_id);
            member_id = fp_user_id
            setIsMemberSelected(true);
          }
          
        });
      }

      let member_selected = "member_id";
      let member = "";
      if(member_id == null){
        member_id = "";
      }
      if (getItemLocal("family")) {
        member_selected = "all"
        member = "all"
      }
      else {
        member = member_id.toString()
      }

      try {
        let cache_member_goals = JSON.parse(localStorage.getItem('memberGoals'));

        if (cache_member_goals == null || (Object.keys(cache_member_goals).length == 0)) {
          // if first time page load or all members data changed
          getGoalsAPI(session_data, member_selected, member_id)
        } else {
          setIsLoading(true);

          // check if member data exist
          if (member in cache_member_goals) {
            let goalss = cache_member_goals[member];
            // if not null goals
            if (goalss) {
              setGoalCountData(goalss['goalsCount']);
              setGoalData(goalss["data"]);
              setIsLoading(false);
              setDataLoadFlag(true);
              setSelectedGoalTab(5);
            }
            else {
              // if no goals
              getGoalsAPI(session, member_selected, member_id)

            }
          }
          else {
            // if first time member clicked
            // getGoalsAPI(session, member_selected, member_id).then(function(res_goals){
            //   // let member_goals = {
            //   //   member: {'data': res_goals['data'], 'goalsCount': res_goals['count_data']}
            //   // }
            //   cache_member_goals[member] = {'data': res_goals['data'], 'goalsCount': res_goals['count_data']};

            //   localStorage.setItem('memberGoals', JSON.stringify(cache_member_goals))
            // });        
            getGoalsAPI(session, member_selected, member_id)

          }

        }

      } catch (e) {
        console.log("err", e);
      }
    } catch (e) {
      console.log("err", e);
    }
  };

  const getGoalsAPI = async (session_data, member_selected, member_id) => {
    let url = ADVISORY_GET_GOALS_API_URL;
    let api_data = {
      user_id: session_data["data"]["id"],
      fp_log_id: session_data["data"]["fp_log_id"],
      filter_type: member_selected,
      fp_user_id: member_id,
      parent_fp_user_id: session_data["data"]["fp_user_id"],
      is_direct: 0
    };
    var payload_data = commonEncode.encrypt(JSON.stringify(api_data));
    setIsLoading(true);

    let res = await apiCall(url, payload_data, false, false);
    let decoded_res = JSON.parse(commonEncode.decrypt(res));

    if (decoded_res["error_code"] == "100") {
      setSelectedGoalTab(5);
      setDataLoadFlag(true);
      setIsLoading(false);
      setGoalData(decoded_res['data']);
      setGoalCountData(decoded_res["count_data"]);

      let member = "";
      if (member_selected == "all") {
        member = "all"
      } else {
        member = member_id.toString()
      }

      let cache_member_goals = JSON.parse(localStorage.getItem('memberGoals'));

      if (cache_member_goals != null) {
        cache_member_goals[member] = { 'data': decoded_res['data'], 'goalsCount': decoded_res['count_data'] }
        localStorage.setItem('memberGoals', JSON.stringify(cache_member_goals))
      } else {
        let member_goals = {
          [member]: { 'data': decoded_res['data'], 'goalsCount': decoded_res['count_data'] }
        }
        localStorage.setItem('memberGoals', JSON.stringify(member_goals))
      }

    } else {
      setDataLoadFlag(true);
      setIsLoading(false);
      setGoalCountData([]);
      setGoalData([]);
    }
  }

  const getGoals = async () => {
    try {
      setIsLoading(true);
      setGoalData([]);
      setGoalCountData([]);
      let url = CHECK_SESSION;
      let data = { user_id: getParentUserId(), sky : getItemLocal("rmf"), is_direct: 0 };

      let session_data = await apiCall(url, data, true, false);

      let member_id = session_data["data"]["fp_user_id"];
      if (memberChanged) {
        member_id = memberID;
      } 
      let member_selected = memberChanged ? "member_id" : "all";
      let api_data = {
        user_id: session_data["data"]["id"],
        fp_log_id: session_data["data"]["fp_log_id"],
        filter_type: "member_id",
        fp_user_id: member_id,
        parent_fp_user_id: session_data["data"]["fp_user_id"],
        is_direct: 0
      };
      var payload_data = commonEncode.encrypt(JSON.stringify(api_data));

      var res = await apiCall(
        ADVISORY_GET_GOALS_API_URL,
        payload_data,
        false,
        false
      );
      let decoded_res = JSON.parse(commonEncode.decrypt(res));
      if (decoded_res) {
        setDataLoadFlag(true);
        setIsLoading(false);

        // if(res['error_code']=="100"){
        setGoalCountData(decoded_res["count_data"]);
        setGoalData(decoded_res["data"]);
        setSelectedGoalTab(5);
        // }
      } else {
        setIsLoading(false);

        setGoalCountData([]);
        setGoalData([]);
      }
    } catch (e) {
      setError(true);
    }
  };

  return (
    <div className="d-block">
      <div style={{
        padding: "1rem 0",
        border: "1px solid #D8D8D8",
        borderRadius: "11px",
        margin: "1rem"
      }}>
        <p className="GoalText default-grey">Goals</p>
        <div className="GoalTabs me-2">
          <div className="">
            <div className="insideTabBoxd row w-100">
              <div className="col-md-7 d-flex justify-content-between overflow-auto">
                <div
                  onClick={() => setSelectedGoalTab(5)}
                  className={`pointer ${selectedGoalTab == 5 ? "active" : ""
                    }`}
                >
                  <p>
                    Total Goals{" "}
                    <span className="TotalGoal">
                      {goalCountData ? goalCountData.total : 0}
                    </span>{" "}
                  </p>
                </div>
                <span className="borderRight"></span>
                <div
                  onClick={() => setSelectedGoalTab(6)}
                  className={`pointer ${selectedGoalTab == 6 ? "active" : ""
                    }`}
                >
                  <p>
                    Achieved{" "}
                    <span className="TotalGoal">
                      {goalCountData ? goalCountData.achieved_goal_data : 0}
                    </span>
                  </p>
                </div>
                <span className="borderRight"></span>
                <div
                  onClick={() => setSelectedGoalTab(7)}
                  className={`pointer ${selectedGoalTab == 7 ? "active" : ""
                    }`}
                >
                  <p>
                    Pending{" "}
                    <span className="TotalGoal">
                      {goalCountData ? goalCountData.pending_goal_data : 0}
                    </span>
                  </p>
                </div>
                <span className="borderRight"></span>
                <div
                  onClick={() => setSelectedGoalTab(8)}
                  className={`pointer lastTab ${selectedGoalTab == 8 ? "active" : ""
                    }`}
                >
                  <p>
                    Upcoming{" "}
                    <span className="TotalGoal">
                      {goalCountData ? goalCountData.upcoming_goal_data : 0}
                    </span><br />
                    <label className="bottomText">3 months</label>
                  </p>
                  {/* <div>2 months</div> */}
                </div>
              </div>

            </div>
          </div>
        </div>
        {goalCountData.total == 0 && (
          <div>
            <div className="finvestGoal">
              <div className="texttitle">Add & Manage your Goals</div>
              <div className="textDesc">In-depth Individual goals analysis and auto linkage with right assets</div>
              {renewpopup === 2 ? (
                <a onClick={onOpenModal}>
                  {" "}
                  <button className=" AddGoalBtn custom-background-color custom-border-color">Add Goal</button>{" "}
                </a>
              ) : (
                <a className="AddGoalBtn" href={process.env.PUBLIC_URL + "/datagathering/goals/"}>
                  Add Goal
                  <div style={{ borderBottom: "1px solid #fff" }}></div>
                </a>
              )}
            </div>
          </div>
        )}

        <FintooInlineLoader isLoading={isLoading} />

        <div className="ms-0 mb-5">
          {selectedGoalTab == 5 && (
            <>
              <TotalGoals
                goals={goalData}
                goal_type={"all"}
                member_id={memberID}
                member_selected={isMemberSelected}
                is_data={isDataLoaded}
                renewpopup={renewpopup}
                onOpenModal={onOpenModal}
                plan_id={planId}
                lifecyclestatus={lifecyclestatus}
              />
            </>
          )}
          {selectedGoalTab == 6 && (
            <>
              {/* <p>Achieved </p> */}
              <TotalGoals
                goals={goalData}
                goal_type={"achieved"}
                member_id={memberID}
                member_selected={isMemberSelected}
                is_data={isDataLoaded}
                renewpopup={renewpopup}
                onOpenModal={onOpenModal}
                plan_id={planId}
                lifecyclestatus={lifecyclestatus}
              />
            </>
          )}
          {selectedGoalTab == 7 && (
            <>
              {/* <p>Pending </p> */}
              <TotalGoals
                goals={goalData}
                goal_type={"pending"}
                member_id={memberID}
                member_selected={isMemberSelected}
                is_data={isDataLoaded}
                renewpopup={renewpopup}
                onOpenModal={onOpenModal}
                plan_id={planId}
                lifecyclestatus={lifecyclestatus}
              />
            </>
          )}
          {selectedGoalTab == 8 && (
            <>
              {/* <p>Upcoming </p> */}
              <TotalGoals
                goals={goalData}
                goal_type={"upcoming"}
                member_id={memberID}
                member_selected={isMemberSelected}
                is_data={isDataLoaded}
                renewpopup={renewpopup}
                onOpenModal={onOpenModal}
                plan_id={planId}
                lifecyclestatus={lifecyclestatus}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Goals