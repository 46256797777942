import BoldSectionHeader from "../../BoldSectionHeader";
import Testimonial from "../../Testimonial";
import ClientReviews from "../ClientReviews";
import styles from "./style.module.css";
import BorderDivider from "../../BorderDivider";
const ClientTestimonial = () => {
  return (
    <section className={`${styles.investsection} py-5`}>
      <div className="text-center">
        <span className={`${styles["section-title"]}`}>
          Wall of <span className={`${styles.DiffText}`}>Trust</span> @ Fintooinvest{" "}
        </span>
        <BorderDivider />
      </div>
      <div>
        <ClientReviews />
      </div>
    </section>
  );
};
export default ClientTestimonial;
