import React, { useEffect, useRef } from 'react';
import style from "./style.module.css";
import userfriendlyui from "./user_friendly_ui.svg";
import invest from "./invest.svg";
import performace from "./performace.svg";
import secure_shield from "./secure_shield.svg";
import support from "./support.svg";
import analytics from "./analytics.svg";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const FeatureCard = ({ icon, title, description, cardRef, style }) => {
    return (
        <div ref={cardRef} className={`${style.FeatureCard}`}>
            <div>
                <img src={icon} alt={title} />
            </div>
            <div>
                <div className={`${style.FeatureCardTitle}`}>{title}</div>
                <div className={`${style.FeatureCardDes}`}>{description}</div>
            </div>
        </div>
    );
};

const OurFeatures = () => {
    const cardRefs = useRef([]);

    const features = [
        {
            icon: userfriendlyui,
            title: 'Intuitive Interface',
            description: 'Navigate easily with our intuitive and user-friendly platform.'
        },
        {
            icon: invest,
            title: 'Invest In The Best',
            description: 'Invest in premier funds vetted by experts like Morningstar and CRISIL.'
        },
        {
            icon: performace,
            title: 'Live Performance Insights',
            description: 'Stay updated with real-time tracking and comprehensive performance reports.'
        },
        {
            icon: secure_shield,
            title: 'Top-Notch Security',
            description: 'Invest confidently in our safe and reliable platform.'
        },
        {
            icon: support,
            title: 'Excellent Support',
            description: 'Benefit from our expert support team, ensuring a hassle-free investment experience.'
        },
        // {
        //     icon: analytics,
        //     title: 'Analytics Business',
        //     description: 'Many desktop publishing packages and web page editors now use for them.'
        // }
    ];

    useEffect(() => {
        cardRefs.current = cardRefs.current.slice(0, features.length);

        cardRefs.current.forEach((card, index) => {
            gsap.fromTo(card, 
                {
                    opacity: 0,
                    y: 100
                }, 
                {
                    opacity: 1,
                    y: 0,
                    zIndex: index + 1,
                    duration: 1,
                    scrollTrigger: {
                        trigger: card,
                        start: "top bottom-=150",
                        end: "top center", 
                        scrub: 1,
                        toggleActions: "play none none reverse"
                    }
                }
            );
        });
    }, [features]);

    return (
        <div className={`container ${style.OurFeatureSection}`}>
            <div className={`${style.OurFeaturestitle}`}>
                Our Key Features
            </div>
            <div className={`${style.FeatureView}`}>
                {features.map((feature, index) => (
                    <FeatureCard
                        key={index}
                        icon={feature.icon}
                        title={feature.title}
                        description={feature.description}
                        cardRef={el => cardRefs.current[index] = el}
                        style={style}
                    />
                ))}
            </div>
            
        </div>
    );
};

export default OurFeatures;
