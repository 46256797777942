import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BackBtn from "../../Assets/left-arrow.png";
import NetBanking from "../../Assets/Cart_blue_payment_mode_01.svg";
import UPI from "../../Assets/Cart_blue_payment_mode_02.svg";
import NFET from "../../Assets/Cart_blue_payment_mode_04.svg";
import Mandate from "../../Assets/Cart_blue_payment_mode_03.svg";
import Link from "../../MainComponents/Link";
import PaymentLayout from "../../Layout/PaymentLayout";
import CartAmt from "./CartAmt";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import {
  DMF_GETBANKDETAILS_API_URL,
  IS_DIRECT,
  DMF_MANDATELIST_API_URL,
} from "../../../constants";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import {
  CheckSession,
  getItemLocal,
  getUserId,
  fetchEncryptData,
  getDownActivityStateFromLS,
  isFamilySelected,
} from "../../../common_utilities";
import TwoFactorOtpModal from "./TwoFactorOTP";
import commonEncode from "../../../commonEncode";
import ApplyWhiteBg from "../../ApplyWhiteBg";
import axios from "axios";
import Swal from "sweetalert2";

export default function MyCartPaymentmode() {
  const userid = getUserId();
  const cartAmt = getItemLocal("cart_amt");
  const bankid = getItemLocal("selbankid");

  const [bankDetail, setBankDetail] = useState([]);
  const [prev, setPrev] = useState("");
  const [dis, setDis] = useState("");
  const [mandateList, setMandatelist] = useState("");
  const [openModalByName, setOpenModalByName] = useState("twoFA");
  const navigate = useNavigate();

  useEffect(() => {
    // CheckSession();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    onLoad();
  }, []);

  React.useEffect(function () {
    onLoadInIt();
  }, []);

  const onLoadInIt = async () => {
    try {
      if (isFamilySelected()) {
        window.location = process.env.PUBLIC_URL + "/mutual-fund/mycart";
        return;
      }
      var payload = {
        data: {
          user_id: userid,
          bank_id: bankid,
          is_direct: IS_DIRECT,
        },
        method: "post",
        url: DMF_GETBANKDETAILS_API_URL,
      };
      var res = await fetchEncryptData(payload);
      setBankDetail(res.data);
    } catch (e) {
      console.error(e);
    }
  };
  console.log("bankdetail", bankDetail.length);

  const conditionalNavigation = () => {
    let x = localStorage.getItem("cart_data");
    let y = commonEncode.decrypt(x);
    let types = JSON.parse(y).map((v) => v.cart_purchase_type);
    types = [...new Set(types)]; //1 lumpsum 2 sip
    Object.keys(types).forEach(function (key) {
      if (types[key] == 1) {
        setDis("paymentgatewayoption disabled");
      } else {
        setDis("paymentgatewayoption");
      }
    });

    if (types.length > 1) {
      setPrev("/mutual-fund/MyCart");
    } else {
      if (types[0] == 2) {
        setPrev("/mutual-fund/MyCart");
      } else {
        setPrev("/mutual-fund/MyCart");
      }
    }
  };

  const onLoad = async () => {
    try {
      var payload = {
        data: { bank_id: bankid, is_direct: IS_DIRECT },
        method: "post",
        url: DMF_MANDATELIST_API_URL,
      };
      //
      var res = await fetchEncryptData(payload);
      var mandate = res.data;
      setMandatelist(mandate);

      //
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    conditionalNavigation();
    setOpenModalByName("twoFA");
  }, []);

  const checkActivityDown = (type) => {
    const a = getDownActivityStateFromLS(type);
    // console.log("a---->", a);
    console.log("a--->", type, Boolean(Number(a?.active) ?? 0));
    return Boolean(Number(a?.active) ?? 0);
  };

  return (
    <PaymentLayout>
      <ApplyWhiteBg />
      <div className="Transaction">
        <Container>
          <span></span>
          <div className="row">
            <div className="MainPanel d-flex">
              <div className="">
                <p>
                  <span className="Rupeees">
                    <Link to={prev}>
                      <img className="BackBtn" src={BackBtn} alt="BackBtn" />
                    </Link>
                  </span>
                </p>
              </div>
              <div className="text-label align-items-center text-center  w-75 ">
                <h4 style={{ textAlign: "center" }}>Select payment mode</h4>
                <p className="PaymentText" style={{ textAlign: "center" }}>
                  Choose how you'd like to pay for your purchase
                </p>
              </div>
            </div>
            {bankDetail.length > 0 &&
              bankDetail.map((v, i) => (
                <div key={"bank-details-" + i}>
                  <div className="CartSelectSIP ">
                    <div className="PaymentLayout">
                      <Container>
                        <div className="payment-box">
                          <Row>
                            <div className="col-12 col-md-12 col-lg-7 ">
                              {v.bank_bse_code == "" ||
                              v.bank_bse_code == "" ||
                              v.bank_bse_mode == null ||
                              v.bank_bse_mode == null ? (
                                <></>
                              ) : (
                                <div className="paymentgatewayoption mt-0">
                                  <Container>
                                    <Row>
                                      <Col xs={3} lg={2}>
                                        <div className="PayImg">
                                          <img
                                            src={NetBanking}
                                            alt="NetBanking"
                                          />
                                        </div>
                                      </Col>
                                      <Col>
                                        <h4 className="PaymentType">
                                          NetBanking
                                        </h4>
                                      </Col>
                                      <Col>
                                        {checkActivityDown("net-banking") ? (
                                          <span
                                            onClick={() => {
                                              const a =
                                                getDownActivityStateFromLS(
                                                  "net-banking"
                                                );
                                              Swal.fire(a?.value);
                                            }}
                                            className="next-process pointer"
                                          >
                                            <MdOutlineArrowForwardIos
                                              style={{ color: "#000" }}
                                              className="NextArrow"
                                            />
                                          </span>
                                        ) : (
                                          <Link
                                            to="/mutual-fund/NetBanking"
                                            className="next-process"
                                          >
                                            <MdOutlineArrowForwardIos
                                              style={{ color: "#000" }}
                                              className="NextArrow"
                                            />
                                          </Link>
                                        )}
                                      </Col>
                                    </Row>
                                  </Container>
                                </div>
                              )}
                              {v.upi_allow == "1" && (
                                <div className="paymentgatewayoption UPIOption">
                                  <Container>
                                    <Row>
                                      <Col xs={3} lg={2}>
                                        <div className="PayImg">
                                          <img
                                            style={{ width: "50px" }}
                                            className="UPI"
                                            src={UPI}
                                            alt="UPI"
                                          />
                                        </div>
                                      </Col>
                                      <Col>
                                        <h4 className="PaymentType UPIData">
                                          UPI
                                        </h4>
                                      </Col>
                                      <Col>
                                        {checkActivityDown("upi") ? (
                                          <span
                                            onClick={() => {
                                              const a =
                                                getDownActivityStateFromLS(
                                                  "upi"
                                                );
                                              Swal.fire(a?.value);
                                            }}
                                            className="next-process pointer"
                                          >
                                            <MdOutlineArrowForwardIos
                                              style={{ color: "#000" }}
                                              className="NextArrow"
                                            />
                                          </span>
                                        ) : (
                                          <Link
                                            to="/mutual-fund/CartUPI"
                                            className="next-process"
                                          >
                                            <MdOutlineArrowForwardIos
                                              style={{ color: "#000" }}
                                              className="NextArrow"
                                            />
                                          </Link>
                                        )}
                                      </Col>
                                      {/* <div style={{ color: "red", paddingTop: "6px", fontSize : "14px" }}>
                                        The UPI service is currently unavailable due to low success rate from BSE.
                                        </div> */}
                                    </Row>
                                  </Container>
                                </div>
                              )}
{/* 
                              {v.emandate_allow == "1" &&
                                mandateList.length > 0 &&
                                mandateList.some(
                                  (x) => x.mandate_status == "2"
                                ) && (
                                  <div className={dis}>
                                    <Container>
                                      <Row>
                                        <Col xs={3} lg={2}>
                                          <div className="PayImg">
                                            <img
                                              // style={{ width: "80px" }}
                                              className=""
                                              src={Mandate}
                                              alt="Mandate"
                                            />
                                          </div>
                                        </Col>
                                        <Col>
                                          <h4 className="PaymentType">
                                            Mandate
                                          </h4>
                                        </Col>
                                        <Col>
                                          {checkActivityDown("mandate") ? (
                                            <span
                                              onClick={() => {
                                                const a =
                                                  getDownActivityStateFromLS(
                                                    "mandate"
                                                  );
                                                Swal.fire(a?.value);
                                              }}
                                              className="next-process pointer"
                                            >
                                              <MdOutlineArrowForwardIos
                                                style={{ color: "#000" }}
                                                className="NextArrow"
                                              />
                                            </span>
                                          ) : (
                                            <Link
                                              to="/mutual-fund/Mandate"
                                              className="next-process"
                                            >
                                              <MdOutlineArrowForwardIos
                                                style={{ color: "#000" }}
                                                className="NextArrow"
                                              />
                                            </Link>
                                          )}
                                        </Col>
                                      </Row>
                                    </Container>
                                  </div>
                                )} */}

                              {v.neft_allow == "1" && (
                                <div className="paymentgatewayoption">
                                  <Container>
                                    <Row>
                                      <Col xs={3} lg={2}>
                                        <div className="PayImg">
                                          <img src={NFET} alt="NFET" />
                                        </div>
                                      </Col>
                                      <Col>
                                        <h4 className="PaymentType">
                                          NEFT/RTGS
                                        </h4>
                                      </Col>
                                      <Col>
                                        {checkActivityDown("neft") ? (
                                          <span
                                            onClick={() => {
                                              const a =
                                                getDownActivityStateFromLS(
                                                  "neft"
                                                );
                                              Swal.fire(a?.value);
                                            }}
                                            className="next-process pointer"
                                          >
                                            <MdOutlineArrowForwardIos
                                              style={{ color: "#000" }}
                                              className="NextArrow"
                                            />
                                          </span>
                                        ) : (
                                          <Link
                                            to="/mutual-fund/NeftRtgs"
                                            className="next-process"
                                          >
                                            <MdOutlineArrowForwardIos
                                              style={{ color: "#000" }}
                                              className="NextArrow"
                                            />
                                          </Link>
                                        )}
                                      </Col>
                                    </Row>
                                  </Container>
                                </div>
                              )}
                            </div>

                            <div className="col-5 col-lg-4  DesktopView">
                              <CartAmt cartAmt={cartAmt} />
                            </div>
                            {/* <Col xs={12} lg={5} className="MobileView">
                                <div className="mb-float-button">
                                  <Link to="/mutual-fund/NeftRtgs">
                                    {" "}
                                    <button> Rs. {cartAmt} Pay</button>
                                  </Link>
                                </div>
                              </Col> */}
                          </Row>
                        </div>
                      </Container>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </Container>
      </div>
      <Modal
        style={{ paddingTop: "90px" }}
        backdrop="static"
        show={openModalByName == "twoFA"}
        className="white-modal fn-redeem-modal"
        // centered
        onHide={() => {
          setOpenModalByName("");
        }}
      >
        <TwoFactorOtpModal
          onBack={() =>
            navigate(-1)
          }
          onSubmit={() => {
            setOpenModalByName("");
          }}
        />
      </Modal>
    </PaymentLayout>
  );
}
