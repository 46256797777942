import { useEffect } from "react";

const Logout = () => {
  useEffect(() => {
    localStorage.clear();
    window.location = '/mutualfunds/';
  }, []);

  return (
    <>
      <div style={{ height: "100vh" }}></div>
    </>
  );
};
export default Logout;
