import React from 'react'
import style from "./style.module.css"
import { Link } from 'react-router-dom';
function Herosection() {
    return (
        <div className={`container ${style.Herosection}`}>
            <div className={`row ${style.Herosectioncard}`}>
                <div className='col-12 col-md-6'>
                    <div className={`${style.title}`}>Boost Your Portfolio with Indian Equities <br /> Ride the high waves of Indian Markets!</div>
                    {/* <div className={`${style.subtitle}`}>Diversify and Strengthen Your Portfolio with Indian Equities</div> */}
                    <div className='mt-4'>
                    <Link style={{ textDecoration: "none" }} to={`${process.env.PUBLIC_URL}/login`}>
                        <button>Invest Now</button>
                    </Link>
                    </div>
                    
                </div>
                <div className='col-12 col-md-6'>
                    <img style={{ width: "100%" }} src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Domestic_Equity.svg'} alt="Domestic Equity" />
                </div>
            </div>
        </div>
    )
}

export default Herosection;
