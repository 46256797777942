import React, { useRef, useEffect, useState } from "react";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";

const MobileInput = ({ value = "", onChange, onValidation, onBlur }) => {
  const utilsScriptUrl =
    "https://cdn.jsdelivr.net/npm/intl-tel-input@18.0.3/build/js/utils.js";
  const inputRef = useRef(null);
  const itiRef = useRef(null);

  const options = {
    allowDropdown: true,
    autoHideDialCode: true,
    initialCountry: "IN",
    separateDialCode: true,
    nationalMode: true,
    hadInitialPlaceholder: true,
    utilsScript: utilsScriptUrl,
    customPlaceholder: function (
      selectedCountryPlaceholder,
      selectedCountryData
    ) {
      return "e.g. " + selectedCountryPlaceholder;
    },
  };

  useEffect(() => {
    const input = document.querySelector("#phone");
    if (!input) return;
    itiRef.current = intlTelInput(input, { ...options });
    itiRef.current.setCountry(options.initialCountry);
  }, []);

  useEffect(() => {
    // console.log('rty347 v23', value, itiRef.current.isValidNumber());
    // const regex = /^[6-9]\d*$/;
    // if(regex.test(value)) {
    //   onValidation(false);
    // } else {
    //   onValidation(itiRef.current.isValidNumber());
    // }
    onValidation(itiRef.current.isValidNumber());
  }, [value]);
  const handleInput = (e) => {
    let inputValue = e.target.value;
    let onlyDigits = inputValue.replace(/\D/g, "");
    if (/^[6-9]/.test(onlyDigits)) {
      onlyDigits = onlyDigits.replace(/^0+/, ''); 
    } else {
      onlyDigits = ''
    }
    onChange(onlyDigits);
    onValidation(itiRef.current.isValidNumber() && /^[6-9]\d{9}$/.test(onlyDigits));
  };
  return (
    <input
      id="phone"
      style={{ width: "100%" }}
      type="phone"
      containerClassName="intl-tel-input"
      inputClassName="form-control"
      value={value}
      tabIndex="3"
      maxLength={15}
      name="mobileNo"
      ref={inputRef}
      placeholder="Mobile Number*"
      onBlur={onBlur}
      onInput={handleInput}
    />
  );
};

export default MobileInput;
