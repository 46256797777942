import React, { useState } from "react";
import commonEncode from "../../commonEncode";
import { apiCall, getItemLocal, getUserId } from "../../common_utilities";
import Poppimg from "../Assets/Dashboard/PopupImg.jpeg";
import {
  ADVISORY_CART_DATA_RENEW_API_URL,
  ADVISORY_UPDATE_AUTH_DATA_API_URL,
  BASE_API_URL,
  CHECK_SESSION,
  REACT_APP_PRICING_URL
} from "../../constants";

const RenewPopupTextbox = (props) => {
  const [error, setError] = useState(false);
  const userid = getUserId();
  const renew = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: userid, sky:getItemLocal("rmf"), is_direct: 0 };
      let session_data = await apiCall(url, data, true, false);
      let api_data = {
        user_id: session_data["data"]["id"],
        fp_log_id: session_data["data"]["fp_log_id"],
      };
      var payload_data = commonEncode.encrypt(JSON.stringify(api_data));
      var cart_data = await apiCall(
        ADVISORY_CART_DATA_RENEW_API_URL,
        payload_data,
        false,
        false
      );
      var res = JSON.parse(commonEncode.decrypt(cart_data));
      if (res["error_code"] == "100") {
        var updatesession_data = { plan_payment_status: 6 };
        var res = await apiCall(
          ADVISORY_UPDATE_AUTH_DATA_API_URL,
          updatesession_data,
          false,
          false
        );
        if (session_data["data"]["fp_plan_sub_cat"] == 2) {
          window.location.href = process.env.PUBLIC_URL + "/userflow/payment/";
        } else {
          window.location.href = process.env.REACT_APP_PRICING_URL + "/pricing/";
        }
      }
    } catch (e) {
      setError(true);
    }
  };
  return (
    <>
      {props.showpopup === true && (
        <div className="ExpirePopup">
        <div className="d-md-flex align-items-center">
          <div className="p-2 contentText">
            Your Financial Planning Subscription has expired. We request you to
            re-activate your subscription to continue your financial planning
            with Fintooinvest.
          </div>
          <div className="text-center">
            <button className="pointer text-sm-center RenewBtn" onClick={renew}>
              <b>Renew now</b>
            </button>
          </div>
        </div>
        </div>
      )}
    </>
  );
};

export default RenewPopupTextbox;
