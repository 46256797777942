import React, { useEffect } from 'react';
import Herosection from '../../../components/LandingPages/Loans/Herosection';
import Whydomesticeuityinvest from '../../../components/LandingPages/Loans/Whyinvest';
import Ourfeaturesection from '../../../components/LandingPages/Loans/Our_Features';
import FaqAccordian from '../../../components/LandingPages/Loans/FaqAccordian'; 
import LoanForm from '../../../components/LandingPages/Loans/Loanform';
import ClientTestimonial from '../../../components/HTML/ClientTestimonial';

function Loans() {
  useEffect(() => {
    document.body.classList.add("Bg-white")
    return () => {
      document.body.classList.remove("Bg-white");
    };
  }, []);

  return (
    <div>
      <Herosection />
      <Whydomesticeuityinvest />
      <Ourfeaturesection />
      {/* <BookCalendly title="Ready to Invest in India's Future?" titleDes="Join countless investors who trust us for their Indian equity investments.Open a Demat and a Trading account and Start investing! It’s free!" logo={true}/> */}
     <div  style={{marginTop : "5rem"}}>
      <LoanForm/>
     </div>
     <ClientTestimonial />
      <p style={{height : "3rem"}}></p>
      <FaqAccordian />
     
    </div>
  );
}

export default Loans;
