import React, { useRef, useState } from "react";
import Styles from "./Style.module.css";
import "./style.css";
import { FaStar } from "react-icons/fa";
import Carousel from "react-slick";
import { useSelector } from "react-redux";
function ExpertRmDetails() {
  const selectedRM = useSelector((state) => state.selectedRM);
  const [show, setShow] = useState(false);
  const rmDetailRef = useRef();
  const breakPoints = [
    { width: 1, itemsToShow: 8 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 2 },
  ];
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    // centerMode: true,
    slidesToScroll: 1,
  };

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (rmDetailRef.current && !rmDetailRef.current.contains(event.target)) {
        closePopup();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closePopup = () => {
    document.querySelector('.outerRmBox-udim4').classList.remove('active');
    document.querySelector('.outerRmBox-udim4').classList.remove('mobile');
    document.body.classList.remove('overflow-hidden');
  }


  console.log("rm---99999----", selectedRM);
  return (
    <div className={`${Styles.outerRmBox} outerRmBox-udim4`}>
      <div className={`${Styles.RMPersonalData} RM-Data-ildfX`} ref={rmDetailRef}>
        <div className={`d-block d-md-none ${Styles.imgclose}`}>
          <div onClick={()=> {
            closePopup();
          }}>
            <img src={require('./images/cancel_1.png')} />
          </div>
        </div>
        {Object.keys(selectedRM).length > 0 && (
          <>
            {show ? (
              <div
                id="calendly"
                className={`${Styles.calendly}`}
                style={{ display: "none" }}
              >
                <div className="text-center">
                  <div>
                    <p className={`${Styles.BooKLabel}`}>
                      Book Appointment with Expert
                    </p>
                  </div>
                  <div>
                    <p className={`${Styles.bookIntro}`}>
                      Book an introductory call with our financial Advisor to
                      know more about our offerings and advice.
                    </p>
                  </div>
                </div>
                <div
                  className="calendly-inline-widget"
                  data-url="https://calendly.com/fintoo/15-minutes-consultation-expert?embed_domain=minty.co.in&embed_type=Inline&hide_event_type_details=1&hide_gdpr_banner=1"
                  style={{ position: "relative", height: 565 }}
                  data-processed="true"
                >
                  <div className="calendly-spinner">
                    <div className="calendly-bounce1" />
                    <div className="calendly-bounce2" />
                    <div className="calendly-bounce3" />
                  </div>
                  <iframe
                    src="https://calendly.com/fintoo/15-minutes-consultation-expert?embed_domain=minty.co.in&embed_type=Inline&hide_event_type_details=1&hide_gdpr_banner=1&name=&email=&a3=+91&a4=Palak Mundhra&name=&email=&a3=+91&a4=Rushabh Kothari&name=&email=&a3=+91&a4=Maitri Panchal&name=&email=&a3=+91&a4=Rushabh Kothari&name=&email=&a3=+91&a4=Rajdeep Javeri&name=&email=&a3=+91&a4=Maitri Panchal&name=&email=&a3=+91&a4=Rushabh Kothari&name=&email=&a3=+91&a4=Kiran Vaswani&name=&email=&a3=+91&a4=Rajdeep Javeri&name=&email=&a3=+91&a4=Rushabh Kothari&name=&email=&a3=+91&a4=Yash Jain&name=&email=&a3=+91&a4=Yash Jain&name=&email=&a3=+91&a4=Rushabh Kothari&name=&email=&a3=+91&a4=Rushabh Kothari&name=&email=&a3=+91&a4=Rushabh Kothari&name=&email=&a3=+91&a4=Yash Jain&name=&email=&a3=+91&a4=Yash Jain&name=&email=&a3=+91&a4=Rushabh Kothari&name=&email=&a3=+91&a4=Rushabh Kothari&name=&email=&a3=+91&a4=Rushabh Kothari&name=&email=&a3=+91&a4=Yash Jain&name=&email=&a3=+91&a4=Rushabh Kothari&name=&email=&a3=+91&a4=Rushabh Kothari&name=&email=&a3=+91&a4=Rushabh Kothari&name=&email=&a3=+91&a4=Yash Jain&name=&email=&a3=+91&a4=Yash Jain&name=&email=&a3=+91&a4=Rushabh Kothari&name=&email=&a3=+91&a4=Rushabh Kothari&name=&email=&a3=+91&a4=Rushabh Kothari&name=&email=&a3=+91&a4=Rushabh Kothari&name=&email=&a3=+91&a4=Rushabh Kothari&name=&email=&a3=+91&a4=Rushabh Kothari"
                    width="100%"
                    height="100%"
                    frameBorder={0}
                  />
                </div>
              </div>
            ) : (
              <>
                <div id="RMDATA1" className={`${Styles.RmSpace} `}>
                  
                    <div className="RMProfile text-center">
                      <div className={`${Styles.RmBox}`}>
                        <img
                          src={
                            "https://images.minty.co.in/static/" +
                            selectedRM.imagepath
                          }
                        />
                      </div>
                      <div className={`mt-2 ${Styles.RMName}`}>
                        {selectedRM.emp_name}
                      </div>
                      <div className={`${Styles.RmQualification}`}>
                        <span className="ng-binding">
                          {selectedRM.emp_position}
                        </span>{" "}
                        <span style={{ fontWeight: 100 }}>|</span>{" "}
                        <span className={`${Styles.Education}`}>
                          {selectedRM.emp_qualification}
                        </span>
                      </div>
                    </div>
                    <div className={`d-flex mt-2 ${Styles.Rmcareer}`}>
                      <div className={`${Styles.BorderRight}`}>
                        <div className={`${Styles.textLabel}`}>Experience</div>
                        <div className={`${Styles.ValueLabel}`}>
                          {selectedRM.emp_experience}{" "}
                          <span style={{}}>Years</span>
                        </div>
                      </div>
                      <div className={`ps-4 pe-4 ${Styles.BorderRight}`}>
                        <div className={`${Styles.textLabel}`}>Location</div>
                        <div className={`${Styles.ValueLabel}`}>
                          {selectedRM.emp_location}
                        </div>
                      </div>
                      <div className="ps-3">
                        <div className={`${Styles.textLabel}`}>Rating</div>
                        <div className={`${Styles.rating}`}>
                          {/* ngIf: expert_details.rating == 5 */}
                          <div>
                            <span
                              className={`fa fa-star`}
                              style={{
                                color: `${
                                  selectedRM.rating - 1 < 0 ? "gray" : "orange"
                                }`,
                              }}
                            />
                            <span
                              className={`fa fa-star`}
                              style={{
                                color: `${
                                  selectedRM.rating - 2 < 0 ? "gray" : "orange"
                                }`,
                              }}
                            />
                            <span
                              className={`fa fa-star`}
                              style={{
                                color: `${
                                  selectedRM.rating - 3 < 0 ? "gray" : "orange"
                                }`,
                              }}
                            />
                            <span
                              className={`fa fa-star`}
                              style={{
                                color: `${
                                  selectedRM.rating - 4 < 0 ? "gray" : "orange"
                                }`,
                              }}
                            />
                            <span
                              className={`fa fa-star`}
                              style={{
                                color: `${
                                  selectedRM.rating - 5 < 0 ? "gray" : "orange"
                                }`,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`text-center ${Styles.aboutRm}`}
                      style={{ borderBottom: "0.3px solid rgb(227, 227, 227)" }}
                    >
                      <p className={`${Styles.labelText}`}>About Me</p>
                      <p className={`${Styles.Textinfo}`}>
                        {selectedRM.emp_description}
                      </p>
                    </div>

                    <div className={`${Styles.FeedBackCustomer}`}>
                      <p className={`text-center ${Styles.labelText}`}>
                        Customer Feedback
                      </p>
                      {selectedRM.reviews != null && (<Carousel {...settings}>
                        {JSON.parse(selectedRM.reviews).slice(0,3).map((x) => (
                          <div
                            key={`rv-${Math.random()}`}
                            className={`${Styles.item}`}
                          >
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                                <div>
                                  <img
                                    width={40}
                                    src="https://www.fintoo.in/static/userflow/img/profile-picture.svg"
                                    alt=""
                                  />
                                </div>
                                <div className={`ms-1 ${Styles.customerDetails}`}>
                                  <div className={`${Styles.customerName}`}>
                                    {x.user_name}
                                  </div>
                                  <div className={`${Styles.customerrating}`}>
                                    <div>
                                      {Array.from({ length: x.rating }).map(
                                        () => (
                                          <span className={`${Styles.checked}`}>
                                            <FaStar />
                                          </span>
                                        )
                                      )}
                                      {Array.from({ length: 5 - x.rating }).map(
                                        () => (
                                          <span className={`${Styles.Uchecked}`}>
                                            <FaStar />
                                          </span>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div>
                              <p className={`mt-2 ${Styles.FeedbackMsg}`}>
                              {x.review}
                              </p>
                            </div>
                          </div>
                        ))}
                      </Carousel>)}
                      {selectedRM.reviews == null && (<div className={` mt-3 ${Styles.reviewslider}`}>
                        <div>
                          <p className={`mt-2 ${Styles.Textinfo}`}>
                            No Review Available
                          </p>
                        </div>
                      </div>)}
                    </div>
                    <div className={`text-center ${Styles.bookAppointment}`}>
                      <button
                        onClick={() => setShow(!show)}
                        className={`${Styles.AppointmentDate}`}
                      >
                        Book Appointment
                      </button>
                    </div>
                  
                </div>
              </>
            )}
            <div
              className={`${Styles.ThanksSection}`}
              style={{ display: "none" }}
            >
              <div className="text-center mt-4">
                <div>
                  <img
                    style={{ width: 40 }}
                    src="https://static.fintoo.in/static/userflow/img/icons/check.png"
                    alt=""
                  />
                </div>
                <div className={`${Styles.thankyoumsg}`}>
                  Thank You For Choosing Fintooinvest!
                </div>
                <div className={`mt-3 ${Styles.ThanksParagraphs}`}>
                  <div>
                    We have received your request, and we are extremely
                    delighted that out of all the available options, you trusted
                    us to assist you at every step on your journey towards
                    financial planning and achieving your goals.
                  </div>
                  <div className="mt-3">
                    Now, let’s head over to your inbox , find the mail with the
                    step-by-step instructions to complete your registration
                    process, set up your account, and ultimately, start your
                    journey towards financial freedom.
                  </div>
                  <div className="mt-3">
                    In case you have any questions or need more information,
                    contact us at online@fintooinvest.in or +91 96998 00600.
                  </div>
                </div>
                <div className={`${Styles.teamFintoo}`}>
                  <p className="pt-4">TEAM FINTOOINVEST</p>
                </div>
                <div className={`text-center ${Styles.thanksContinue}`}>
                  <button className={`${Styles.Thankscontinue}`}>
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ExpertRmDetails;
