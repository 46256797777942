import { useEffect, useRef } from "react";
import { removeMemberId, removeUserId } from "../../common_utilities";

const AutoLogout = () => {
  
  const myTimer = useRef();
  useEffect(() => {
    if (Boolean(localStorage.getItem("rmf")) && Boolean(localStorage.getItem('lastVisit'))) {
      let d = new Date();
      // console.log("now login --=--==--=>", d);
      if (d.valueOf() > Number(localStorage.getItem("lastVisit"))) {
        redirectNow();
        return;
      }
    }
    checkStorage();
    autoExpire();
    document.addEventListener("click", setStorage);
    document.addEventListener("mouseover", setStorage);
  }, []);

  const checkStorage = () => {
    setStorage();
  };

  const setStorage = () => {
    const d = new Date();
    d.setTime(d.getTime() + Number(process.env.REACT_APP_AUTO_LOGOUT) * 60 * 1000);
    localStorage.setItem("lastVisit", d.valueOf());
  };

  const autoExpire = () => {
    myTimer.current = setInterval(() => {
      
      if (Boolean(localStorage.getItem("rmf")) && Boolean(localStorage.getItem('lastVisit'))) {
        let d = new Date();
        // console.log("now login --=--==--=>", d);
        if (d.valueOf() > Number(localStorage.getItem("lastVisit"))) {
          clearInterval(myTimer.current);
          myTimer.current = null;
          redirectNow();
        }
      }
    }, 5000);
  };

  const redirectNow = () => {
    console.log("now expired -------------------------->");
    removeMemberId();
    removeUserId();
    localStorage.removeItem("rmf");
    const _w = window.location.href;
    localStorage.setItem("redirectToThis", _w);
    console.log('now redirect to', window.location.href);
    window.location.href = process.env.PUBLIC_URL + "/session-expired";
  };

  return <></>;
};

export default AutoLogout;
