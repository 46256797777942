import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Rupee from "../../Assets/Rupee.png";
import security from "../../Assets/security.png";
import BackBtn from "../../Assets/left-arrow.png";
import Link from "../../MainComponents/Link";
import Investmentfundbox from "./Investmentfundbox";
import MainLayout from "../../Layout/MainLayout";
import FintooButton from "../../HTML/FintooButton";

import uuid from "react-uuid";
import {
  DMF_BASE_URL,
  GET_CART_DETAILS,
  DMF_DATAGATHERING_API_URL,
  IS_DIRECT,
  DMF_NOMINEESTATUS,
} from "../../../constants";
import commonEncode from "../../../commonEncode";
import axios from "axios";
import {
  CheckSession,
  apiCall,
  indianRupeeFormat,
  loginRedirectGuest,
  getSumOfProperty,
  setItemLocal,
  getItemLocal,
  getUserId,
  getCurrentUserDetails,
  getProfilePercentage,
  fetchMembers
} from "../../../common_utilities";

import WhiteOverlay from "../../HTML/WhiteOverlay";
import { useSelector, useDispatch } from "react-redux";
import SweetAlert from "sweetalert-react";
import FintooInlineLoader from "../../FintooInlineLoader";
import { useNavigate } from "react-router-dom";
import IncompleteRegistration from "../../IncompleteRegistration";
import { Modal as ReactModal } from "react-responsive-modal";


const AddFund = () => { };
const CloseFund = () => { };
const cartFunds = [["sip"], ["sip", "lumpsum"]];
export default function MyCart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cartList, setCartList] = useState(cartFunds);
  const [cartData, setCartData] = useState([]);
  const [error, setError] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [cartValue, setCartValue] = useState(0);
  const [goingToDelete, setGoingToDelete] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [mainData, setMainData] = useState({});
  const [statusData, setStatusData] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [isProfileIncomplete, setIsProfileIncomplete] = useState(false);
  const [status, setnomineestatus] = useState("");
  const [showPanel, setShowPanel] = useState("");
  const [profilepercent, setprofilepercent] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => { setIsOpen(true); };
  const closeModal = () => { setIsOpen(false); };
  const [erroronproceed, setErrorOnProceed] = useState("Please select a member from the dropdown to proceed.");
  const membersRef = useRef({});

  const isDisabled = true;

  const userid = getUserId();

  const disable = () => {
    return isDisabled
  }

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (!userid) {
      loginRedirectGuest();
    } else {
      onLoadInIt();
      fetchUsersData();
      nomineestatus();
    }
  }, [status]);

  const onLoadInIt = async () => {
    try {

      setShowLoader(true);

      if (getItemLocal("family")) {
        var new_array = [];
        var new_data = getItemLocal("member");
        new_data.forEach((element) => {
          new_array.push(element.id);
        });
      }
      var user = getUserId();
      if (user == null) return;

      var memberData = await fetchMembers();
      console.log('memberData', memberData);
      var newMemberObj = {};
      memberData.forEach((v) => {
        newMemberObj[v.id] = v.NAME ?? v.fdmf_email ?? "";
      });
      membersRef.current = newMemberObj;
      console.log('memberData 78', newMemberObj);

      var data = commonEncode.encrypt(
        JSON.stringify({
          user_id: getItemLocal("family") ? new_array : getUserId(),
          is_direct: IS_DIRECT
        })
      );

      var config = {
        method: "post",
        url: GET_CART_DETAILS,
        data: data,
      };
      var res = await axios(config);
      var response = commonEncode.decrypt(res.data);
      const cartdStr = JSON.parse(response)["data"];
      console.log("cartdStr", cartdStr)
      let cartSum = getSumOfProperty(cartdStr, "cart_amount");
      console.log("cartSum", cartSum)
      setCartValue(cartSum);

      setItemLocal("cart_data", cartdStr);
      console.log('cartdStr', cartdStr);
      setItemLocal("cart_amt", cartSum);

      dispatch({ type: "UPDATE_CART_COUNT", payload: cartdStr.length });

      var newA = [];
      cartdStr.map(v => ({ ...v, memberName: membersRef.current[v.user_id] })).forEach((v) => {
        var index = newA.findIndex(
          (x) => v.cart_scheme_code == x.cart_scheme_code && v.user_id == x.user_id
        );
        if (index < 0) {
          newA.push(v);
          v.funds = [];
          v.funds.push(v);
        } else {
          newA[index].funds.push(v);
        }
      });

      // crtData.push(newA);
      setCartData([...newA]);
      setShowLoader(false);
    } catch (e) {
      setShowLoader(false);
      // 
      setError(true);
    }
  };

  const reloadPage = () => {
    onLoadInIt();
  };

  const onRemoveScheme = async () => {
    setWaiting(true);
    setOpenConfirm(false);
    try {
      var payload = {
        cart_id: "" + goingToDelete.cart_id,
        user_id: "" + getUserId(),
      };
      var encodedData = commonEncode.encrypt(JSON.stringify(payload));
      var response = await axios.post(
        DMF_BASE_URL + "api/cart/delete",
        encodedData
      );
      var d = JSON.parse(commonEncode.decrypt(response.data));
      if (d.error_code * 1 === 100) {
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: d.message, type: "success" },
        });
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: d.message, type: "error" },
        });
      }
      setWaiting(false);
      onLoadInIt();
    } catch (e) {
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: "Something went wrong", type: "error" },
      });
      setWaiting(false);
    }
  };
  const onAddScheme = (v, keyid) => {
    var mainArray = cartData;
    var mainIndex = mainArray.findIndex(
      (x) => x.cart_scheme_code == v.cart_scheme_code
    );
    mainArray[mainIndex].funds.push({
      cart_scheme_code: mainArray[mainIndex].cart_scheme_code,
      cart_purchase_type: keyid + "",
      cart_id: "temp_" + uuid(),
    });
    setCartData([...mainArray]);
  };

  const nomineestatus = async () => {
    try {
      var res = await apiCall(DMF_NOMINEESTATUS, {
        user_id: userid,
        is_direct: IS_DIRECT,
      });
      setnomineestatus(res.message)
      console.log(res.message, "data")

    } catch (e) {
      console.error(e);
    }

  };

  const fetchUsersData = async () => {
    try {
      var res = await apiCall(DMF_DATAGATHERING_API_URL, {
        user_id: "" + userid,
        is_direct: IS_DIRECT,
      });
      setItemLocal('main', res.data);

      console.log(res.data, "data")

      // if (!res.data.pan) {
      //   window.location.href =
      //     process.env.PUBLIC_URL + "/mutual-fund/Profile";
      // }
    } catch (e) {
      console.error(e);
    }
  };

  const handleClick = async () => {
    try {
      const r = await getCurrentUserDetails();

      const p = await getProfilePercentage(r);
      setprofilepercent(p)
      console.log("test", p)
      console.log("statsus", status)
      if (p == 100 && status != "N") {
        navigate(process.env.PUBLIC_URL + "/mutual-fund/MyCartSelectBank");
      } else {
        setIsProfileIncomplete(true);
      }
    } catch (e) {
      dispatch({ type: "RENDER_TOAST", payload: { message: e.toString(), type: 'error' } });
    }
  };

  return (
    <MainLayout>
      <div className="Transaction" style={{ background: "none" }}>
        <WhiteOverlay show={waiting} />


        <IncompleteRegistration
          open={isProfileIncomplete}
          onCloseModal={() => {
            setIsProfileIncomplete(false);
          }}
          handleSubmit={() => {
            console.log(profilepercent == 100, "percent////")
            if (profilepercent != 100) {
              console.log("100")
              navigate(process.env.PUBLIC_URL + "/mutual-fund/profile")
            }
            if (status === "N") {
              console.log("!=100")
              navigate(process.env.PUBLIC_URL + "/mutual-fund/profile/dashboard/nominee")
            }
            // {status === "N" && profilepercent == 100 ? navigate(process.env.PUBLIC_URL + "/mutual-fund/profile/dashboard/nominee"):navigate(process.env.PUBLIC_URL + "/mutual-fund/profile")}
            // navigate(process.env.PUBLIC_URL + "/mutual-fund/profile");
            // navigate(process.env.PUBLIC_URL + "/mutual-fund/profile?s=NomineeDetails");
          }}
        />

        {/* <SweetAlert
          show={openConfirm}
          title="Delete fund"
          text="Are you sure?"
          onConfirm={() => {
            onRemoveScheme();
          }}
          onCancel={() => {
            setOpenConfirm(false);
          }}
          showCancelButton
        /> */}

        <ReactModal classNames={{
          modal: 'ModalpopupXs',
        }} open={openConfirm} showCloseIcon={false} center animationDuration={0} closeOnOverlayClick={false} large>
          <div className="text-center">
            <h3 className="HeaderText">Delete Confirmation</h3>
            <div className="">
              <div className="p-2">
                <p className="PopupContent" style={{ fontSize: '1.3rem', fontWeight: 'normal', }}>
                  Are you sure you want to remove funds from the cart ?</p>
              </div>
              <div className="ButtonBx aadharPopUpFooter" style={{ display: 'flex', justifyContent: 'center' }}>
                <button className="outlineBtn" onClick={() => {
                  onRemoveScheme()
                }}>
                  Yes
                </button>
                <button className="ReNew" onClick={() => {
                  setOpenConfirm(false)
                }}>
                  No
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        < ReactModal
          classNames={{
            modal: 'ModalpopupContentWidth',
          }} open={isOpen} showCloseIcon={false} center animationDuration={0} closeOnOverlayClick={false} large
        >
          <div className="text-center">
            <h3 className="HeaderText">Attention !</h3>
            <div className="">
              <div className="PopupImg" style={{ width: '40%', margin: '0 auto' }}>
                <img style={{ width: '100%' }}
                  src={process.env.PUBLIC_URL + "/static/media/RMF/SelectingTeam.svg"} />
              </div>
              <div className="p-2">
                <p className="PopupContent" style={{ fontSize: '1.3rem', fontWeight: 'normal', padding: '0 1rem', width: '90%', margin: '0 auto' }}>
                  Please select member from the dropdown to proceed.</p>
              </div>
              <div className="ButtonBx aadharPopUpFooter" style={{ display: 'flex', justifyContent: 'center' }}>
                <button className="ReNew" onClick={() => {
                  closeModal();
                }}>
                  Continue
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        <Container>
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-12 col-lg-8  ">
              <div className="MainPanel Cart">
                <div className="">
                  <div>
                    <span className="Rupeees">
                      <Link to="/mutual-fund/funds/all">
                        <img
                          className="BackBtn"
                          src={BackBtn}
                          alt=""
                          srcSet=""
                        />
                      </Link>
                    </span>{" "}
                  </div>
                </div>
                <div className="TransDetails mt-4">
                  <div className="AllTrans">
                    <h4 className="tranText pb-2">All Transactions</h4>
                    {/* <div className="GreenLine"></div> */}
                  </div>
                </div>
              </div>
              <div className="TwoSpace">
                <div className="InvestFund">
                  <p>Investment Funds</p>
                  {/* <p>Please note: If your SIP date is within the next 5 days, your SIP will start from the next month on the chosen date.</p> */}
                </div>
                <div>
                  <Link
                    style={{ textDecoration: "none", color: " #fff" }}
                    to={"/mutual-fund/funds/all"}
                    className="AddFund"
                  >
                    Add Funds
                  </Link>
                </div>
              </div>
              <FintooInlineLoader isLoading={showLoader} />
              <div>
                {cartData.length > 0 &&
                  cartData.map((v, i) => (
                    <>
                      <Investmentfundbox
                        key={v.cart_scheme_code + v.funds.length}
                        onAdd={(a, b) => onAddScheme(a, b)}
                        onRemove={(a) => {
                          setGoingToDelete({ ...a });
                          setOpenConfirm(true);
                        }}
                        onProceed={() => {
                          if (getItemLocal("family")) {
                            openModal();
                          } else {
                            handleClick();
                          }
                        }}
                        schemeType={v}
                        reloadPage={() => reloadPage()}
                      />
                    </>
                  ))}
                {cartData.length == 0 && (
                  <div className="row">
                    <div className="item-continer-bx">
                      <p className="mb-0 text-center">Cart is empty!</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="CartAmtBox">
                <div className="CartSummary">
                  <div className="CartSummaryDeatils">
                    <div className="CartHeading">
                      <div>Cart Summary</div>
                    </div>
                    <div className="HRLine"></div>
                    <div className="CartCenter">
                      <p className="AmtPayText">Amount payable now</p>
                      <p
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <span className="Cart_Amt">
                          {indianRupeeFormat(cartValue * 1, 0)}
                        </span>
                      </p>
                      <div className="SecureShield">
                      <img src={process.env.REACT_APP_STATIC_URL + "media/DMF/security.png"} alt="" />
                      </div>
                      <p className="Safe">100% SAFE AND SECURE</p>
                    </div>
                    {getItemLocal("family") ?
                      <div className="CartBtn">

                        {cartValue * 1 == 0 ? (
                          <p className="zero-para mb-0">PROCEED TO PAY</p>
                        ) : (
                          // <Link to="/direct-mutual-fund/MyCartSelectBank/">
                          //   PROCEED TO PAY
                          // </Link>
                          <FintooButton
                            onClick={(disable, openModal)}
                            title="PROCEED TO PAY"
                          />
                        )}
                      </div>
                      : <div className="CartBtn">
                        {cartValue * 1 == 0 ? (
                          <p className="zero-para mb-0">PROCEED TO PAY</p>
                        ) : (
                          // <Link to="/direct-mutual-fund/MyCartSelectBank/">
                          //   PROCEED TO PAY
                          // </Link>
                          <FintooButton
                            onClick={handleClick}
                            title="PROCEED TO PAY"
                          />
                        )}
                      </div>}<div>
                      {/* <div onClick={openModal}>Click here to open the modal</div> */}

                      {/* <Modal
                        backdrop="static"
                        size="lg"
                        centered
                        show={isOpen}
                        className="profile-popups sign-popup"
                        onHide={() => {
                          closeModal(false);
                        }}
                      >
                        <Modal.Body><div className="modal-body-box">
                          <center><h5><b>{erroronproceed}</b></h5></center>
                          <>
                            <div className="pt-3 pb-3 ">
                            </div>
                            <div className="pb-3 pt-3">
                              <FintooButton
                                onClick={() => {
                                  closeModal();
                                }}
                                title={"Continue"}
                              />
                            </div>
                          </>
                        </div>
                        </Modal.Body>
                      </Modal> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="CartAmtBox">
                <div className="CartSummary">
                  <div className="CartHeading">
                    <div>Note</div>
                  </div>
                  <div className="HRLine"></div>
                  <div className="CartSummaryDeatils">
                    <div className="text-center" style={{ padding: "2rem .5rem", fontWeight: "500", fontSize: ".9rem" }}>
                      <div>
                        A minimum 5-day gap between the current date and the SIP start date is required for initiating SIP using a pre-approved mandate.
                      </div>
                      <div className="mt-3">
                        The mandate pay option will be disabled if there is any Lumpsum amount in the cart.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <br />
        <br />
        <br />
      </div>
    </MainLayout>
  );
}
