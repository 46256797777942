
import styles from "./style.module.css";
import { getUserId } from "../../../../common_utilities";
import { useEffect, useState } from "react";
import Calendar from "./Calendar.js";
import UserVerification from "./UserVerification";
import ThankyouSection from "./ThankyouSection.js";
import { useDispatch, useSelector } from "react-redux";
import commonEncode from "../../../../commonEncode.js";

function AppointmentBox({ eventCode, eventUrl, serviceName, planId, extraParams, title, titleDes, logo }) {

  const loggedIn = useSelector((state) => state.loggedIn);

  const [currAppointmentView, setCurrAppointmentView] = useState('VERIFICATION');
  const [show, SetShow] = useState(false);

  const dispatch = useDispatch();

  const handleClose = () => {
    SetShow(false);
    Boolean(loggedIn) == false ? setCurrAppointmentView('VERIFICATION') : setCurrAppointmentView('CALENDLY');

    dispatch({
      type: "SET_LEAD_DATA", payload: {
        fullname: "",
        mobile: "",
        email: ""
      }
    });
  }

  const handleLoggedInCase = () => {
    setTimeout(() => {
      // assignUserData()
    }, 2000)
  }

  const assignUserData = () => {
    const userid = getUserId();

    let member = JSON.parse(commonEncode.decrypt(localStorage.getItem("member")));
    let users = JSON.parse(commonEncode.decrypt(localStorage.getItem("allMemberUser")));

    const membertUserData = member.filter(data => data.id == userid)[0];
    const currentUserData = users.filter(data => data.id == userid)[0]

    console.log('currentUserDatacurrentUserData', currentUserData)

    dispatch({
      type: "SET_LEAD_DATA", payload: {
        fullname: membertUserData?.name,
        mobile: currentUserData?.mobile,
        email: currentUserData?.email
      }
    });

    setCurrAppointmentView('CALENDLY');
  }

  useEffect(() => {
    Boolean(loggedIn) == false ? setCurrAppointmentView('VERIFICATION') : handleLoggedInCase();
  }, [loggedIn])

  return (
    <>
      <section
        className={`${styles["appointment-section"]} container`}
      >
        <div className={` row ${styles["appointment-section-container"]}`}>
          <div className="col-md-6 col-12" style={{ display: "grid", placeContent: "center" }}>
            <div className={`${styles["appointment-section-container-title"]}`}>{title}</div>
            <div className={` ${styles["appointment-section-text"]}`}>
              {titleDes}
            </div>
            {
              logo === true && (
                <div className='mt-3'>
                  <span>Powered by</span>  <img width={150} src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/IIFL.svg'} alt="IIFL Finance" />
                </div>
              )
            }
          </div>
          <div className="col-md-6 col-12">

            {
              currAppointmentView === 'VERIFICATION' && <UserVerification setCurrAppointmentView={setCurrAppointmentView} />
            }
            {
              currAppointmentView === 'CALENDLY' && <div className={`${styles["appointment-section-iframe"]}`}>
                <div
                  className="calendly-inline-widget"
                  style={{
                    width: "100%",
                  }}
                >
                  <Calendar extraParams={extraParams} eventCode={eventCode} url={eventUrl} serviceName={serviceName} planId={planId} SetShow={SetShow} />
                </div>
              </div>
            }
          </div>
        </div>
        <br />
        {show && <ThankyouSection onClose={() => handleClose()} />}
      </section>
    </>
  );
}

export default AppointmentBox;
