import React, { useEffect, useState } from "react";
import ExpertHeader from "../../components/Expert/ExpertHeader/ExpertHeader";
import Styles from "./Style.module.css";
import ApplyWhiteBg from "../../components/ApplyWhiteBg";
import Expertrm from "../../components/Expert/ExpertRm/Expertrm";
import ExpertFilter from "../../components/Expert/ExpertFilter/ExpertFilter";
import ExpertRmDetails from "../../components/Expert/ExpertRmDetails/ExpertRmDetails";
function Expert() {
  useEffect(() => {
    document.body.classList.add("Expert-bg");
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({});
  const handleChange = (v) => {
    setSearchTerm(v);
  }
  const handleFilter = (v) => {
    // console.log('po', v);
    setFilters({...v});
  }
  console.log('nil 8', searchTerm);
  return (
    <>
      <ApplyWhiteBg />      
        <div className={`${Styles.ExpertSection}`}>
          <ExpertHeader handleChange={(v)=> handleChange(v)} searchTerm={searchTerm} />
          <section className={` ${Styles.MainSection}`}>
            <ExpertFilter onFilterChange={(v)=> handleFilter(v)} />
            <Expertrm searchTerm={searchTerm} filters={filters}/>
            <ExpertRmDetails />
          </section>
        </div>      
    </>
  );
}

export default Expert;
