import FaqSection from "../components/HTML/TaxPlanning/FaqSection";
import SecuritySection from "../components/HTML/SecuritySection";
import TaxPlanningHeaderSection from "../components/HTML/TaxPlanning/TaxPlanningHeaderSection";
import TaxPlanningVideoSection from "../components/HTML/TaxPlanning/TaxPlanningVideoSection";
import WhyTaxPlanningSection from "../components/HTML/TaxPlanning/WhyTaxPlanningSection";
import WealthContactForm from "../components/WealthManagementContactForm/";
import backImg from "../components/Layout/Fullpage/assets/tax-planning-contact-bg.jpg";
import Fullpage from "../components/Layout/Fullpage";
import { servicesTypes } from "../components/WealthManagementContactForm/model"
import ThankyouSection from "../components/ThankyouSection";
import { useState } from "react";
import AppointmentBox from "../components/Pages/Calendly";
import WidgetSection from "../components/HTML/TaxPlanning/WidgetSection";
import TPCardSection from "../components/HTML/TaxPlanning/TPCardSection";
function TaxPlanning() {
  const [show, SetShow] = useState(false)

  return (
    <Fullpage>
      <div style={{ backgroundColor: "white" }}>
        <TaxPlanningHeaderSection />
        <TPCardSection />
        {/* <WhyTaxPlanningSection /> */}
        <WidgetSection />
        {/* <TaxPlanningVideoSection /> */}
        <SecuritySection />
        <section style={{
          
        }} id="GetStarted">
        <AppointmentBox eventCode={'Callback_mintyApp_9'} serviceName="Tax Planning" eventUrl="https://calendly.com/fintoo/15-min-consultation-call-tax-planning-website?hide_event_type_details=1" />
          {/* <WealthContactForm imgSrc={backImg} serviceType={servicesTypes.taxPlanning} onSuccess={() => SetShow(true)} /> */}
        </section>
        <FaqSection />
        {show && <ThankyouSection onClose={() => SetShow(false)} />}
      </div>
    </Fullpage>
  );

}

export default TaxPlanning;
