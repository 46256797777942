import React, { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import axios from "axios";

import Styles from "./Accordion.module.css";
import './style.css';

export default function TriggerAccordion({title, children, ...props},) {
  const [show, setShow] = useState(false);
  return (
    <div>
    <div  className={`${Styles.Accordian_Box}`}>
      <div className={`${Styles.acc_section} ${show ? 'Accordion_acc_section_active' : ''}`}>
        <div
          className={`${Styles['title-container']} Accordion_title-container`}
          onClick={() => setShow(!show)}
        >
          <div className={Styles.section_title}>{title}</div>
          <div>
            <label style={{ cursor: "pointer" }} onClick={() => setShow(!show)}>
              {show ? <BsChevronUp /> : <BsChevronDown />}
            </label>
          </div>
        </div>
        <>
          {show && <div className={`${Styles['acc-body']} Accordion_acc-body`}>
            <div className={Styles['noti-icon']}><img src={require('./Images/noti.png')} /></div>
            {children}
          </div>}
        </>
      </div>
    </div>
    </div>
  );
}
