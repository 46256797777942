import { default as RCSlider } from "rc-slider";
import { useState } from "react";
import "./style.css";
const Slider = ({min, max, value, step = 1, onChange}) => {
  // const [sliderValue, setSliderValue] = useState(props.defaultValue);
  return (
    <div
      className={`py-3 gs-slider fintoo-input-slider `}
      data-min={min}
      data-max={max}
    >
      <RCSlider
        // key={"slider-" + props.defaultValue}
        min={min}
        max={max}
        value={value}
        // defaultValue={props.defaultValue}
        step={step}
        onChange={onChange}
        handleStyle={{
          borderColor: "#424242",
          backgroundColor: "#ffffff",
        }}
        railStyle={{
          backgroundColor: "#424242",
        }}
        trackStyle={{
          backgroundColor: "#424242",
        }}
        
      />
    </div>
  );
};
export default Slider;
