import React from 'react'
import style from "./style.module.css"
const Ourfeaturesection = () => {
    return (
        <div className='container'>
            <div className={style.Ourfeature_section}>
                <div className={style.ourfeaturesone}>
                    <div>

                        <sup><img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/topview.svg'} alt="Our Features" /></sup>
                        Our
                    </div>
                    <div>Features</div>
                </div>
                <div className={`${style.FeatureCard}`}>
                    <div className='d-flex d-md-block justify-content-center'>
                        <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Real_time_Data.svg'} alt="Real time Data" />
                    </div>
                    <div>
                        <div className={`${style.featuretitle}`}>Real-Time Data</div>
                        <div className={`${style.featureDesc}`}>Get live market updates and stock prices.</div>
                    </div>
                </div>
                <div className={`${style.FeatureCard}`}>
                    <div className='d-flex d-md-block justify-content-center'>
                        <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Customizable_Portfolio.svg'} alt="Customizable Portfolio" />
                    </div>
                    <div>
                        <div className={`${style.featuretitle}`}>Customizable Portfolio</div>
                        <div className={`${style.featureDesc}`}>Tailor and manage your portfolio to meet your investment goals.</div>
                    </div>
                </div>
                <div className={`${style.FeatureCard}`}>
                    <div className='d-flex d-md-block justify-content-center'>
                        <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Secure_Platform.svg'} alt="Secure Platform" />
                    </div>
                    <div>
                        <div className={`${style.featuretitle}`}>Secure Platform</div>
                        <div className={`${style.featureDesc}`}>Invest confidently with our robust security measures.</div>
                    </div>
                </div>
                <div className={`${style.FeatureCard}`}>
                    <div className='d-flex d-md-block justify-content-center'>
                        <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Excellent_Support.svg'} alt="Excellent Support" />
                    </div>
                    <div>
                        <div className={`${style.featuretitle}`}>Excellent Support</div>
                        <div className={`${style.featureDesc}`}>Rely on our dedicated team for a smooth investment experience.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ourfeaturesection