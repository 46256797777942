import React, { useState, useEffect } from "react";
import Styles from "./style.module.css"
import Select from 'react-select'
import * as constClass from "../../../../constants";
import CustomStyles from "../../../../components/CustomStyles";
import { CHECK_SESSION } from "../../../../constants";
import { apiCall, getItemLocal, restApiCall, getParentUserId, createCookie, setUserId } from "../../../../common_utilities";
import socket, { onMessageHandler } from "../../BankCashbalance/socket";
import commonEncode from "../../../../commonEncode";
const BasicDetails = (props) => {

    const [selectedMember, setSelectedMember] = useState({});
    const [ecasData, setEcasData] = useState([]);
    const [restHeaders, setRestHeaders] = useState({});
    const [allMembers, setAllMembers] = useState([]);
    const [panEditable, setPanEditable] = useState(false);
    const [errors, setErrors] = useState({});
    const [sendDisabled, setSendDisabled] = useState(true);
    const [isPanRegistered, setIsPanRegistered] = useState(false);
    const { v4: uuidv4 } = require("uuid");
    const [handleId, setHandleId] = useState("");
    const[userId,setUserId] = useState("");


    useEffect(() => {
        fetchSmallcase();
    }, []);

    useEffect(() => {
        handleChange();
    }, [allMembers]);

    const findMobileErrors = () => {
        const newErrors = {};
        let regex = /^[6789]\d{9}$/;
        if (!selectedMember.mobile || selectedMember.mobile === "")
            newErrors.userMobile = "Please enter mobile number";
        else if (selectedMember.mobile.length !== 10)
            newErrors.userMobile = "Please enter valid mobile number";
        else if (!regex.test(selectedMember.mobile))
            newErrors.userMobile = "Please enter valid mobile number";
        else if (
            selectedMember.mobile ||
            regex.test(selectedMember.mobile) ||
            selectedMember.mobile.length == 10
        )
            newErrors.userMobile = "";
        return newErrors;
    };

    const findPANErrors = () => {
        const newErrors = {};
        let regex = /^[A-Za-z]{3}[HPhp]{1}[A-Za-z]{1}\d{4}[A-ZHPa-zhp]{1}$/;
        if (!selectedMember.pan || selectedMember.pan === "") {
            newErrors.userPan = "Please enter PAN number";
        } else if (selectedMember.pan.length !== 10) {
            newErrors.userPan = "Please enter valid PAN number";
        } else if (!regex.test(selectedMember.pan)) {
            newErrors.userPan = "Please enter valid PAN number";
        } else if (
            selectedMember.pan ||
            regex.test(selectedMember.pan) ||
            selectedMember.pan.length == 10
        ) {
            newErrors.userPan = "";
        }
        return newErrors;
    };

    useEffect(() => {
        const mobileErrors = findMobileErrors();
        const panErrors = findPANErrors();
        if (
            !panEditable &&
            selectedMember.pan != "" &&
            selectedMember.pan != null
        ) {
            if (Object.keys(panErrors).length > 0) {
                setErrors((v) => ({ ...v, ...panErrors }));
            }
        }
        if (selectedMember.mobile != "" && selectedMember.mobile != null) {
            if (Object.keys(mobileErrors).length > 0) {
                setErrors((v) => ({ ...v, ...mobileErrors }));
            }
        }
    }, [selectedMember.pan, selectedMember.mobile]);

    const checksession = async () => {
        let url = CHECK_SESSION;
        let data = { user_id: getParentUserId(), sky: getItemLocal("rmf"), is_direct: 0 };
        return await apiCall(url, data, true, false);
    };

    const fetchSmallcase = async () => {
        try {
            let sessionData = await checksession();
            let reqdata = {
                fp_user_id: sessionData['data']['fp_user_id'],
                fp_log_id: sessionData['data']['fp_log_id'], //test2
                user_id:sessionData['data']['id'], //test
                is_direct:0
            };
            setUserId(sessionData['data']['id'])
            let checkData = await restApiCall(
                constClass.ADVISORY_FETCH_MEMBER_DATA,
                reqdata,
                ""
            );

            if (checkData.error_code == "100") {
                const a = checkData.data
                const b = checkData.valid_members
                const valid_member = a.filter(data => !b.includes(data.user_id))
                setEcasData(valid_member);
            if (valid_member.length > 0) {
                const all = valid_member.map((v) => ({
                    name: v.first_name + " " + v.last_name,
                    id: v.id,
                    user_id:v.user_id,
                    pan: v.PAN,
                    mobile: v.alternate_mobile,
                    label: v.first_name + " " + v.last_name,
                    value: v.id,
                    fp_log_id:v.fp_log_id,
                    retirement_date:v.retirement_date
                }));
                setAllMembers([...all]);
            }
        }
        } catch (e) {
            console.log(e);
        }
    };

    const handleChange = async (e) => {
        try {
            if (Boolean(e) == false) {
                let member = allMembers;
                setSelectedMember({ ...member[0] });
                setPanEditable(
                    member[0].pan !== null && member[0].pan !== "" ? true : false
                );
                setSendDisabled(false);
            } else {
                setSelectedMember({ ...e });
                setPanEditable(e.pan !== null && e.pan !== "" ? true : false);
                setErrors({});
                setSendDisabled(false);
            }
        } catch (e) { }
    };

    const handleMobileChange = (e) => {
        setSelectedMember({ ...selectedMember, mobile: e.target.value });
        if (e.target.value.length == 10) {
            findMobileErrors();
        }
    };

    const handlePANChange = (e) => {
        setSelectedMember({ ...selectedMember, pan: e.target.value });
        if (e.target.value.length == 10) {
            findPANErrors();
        }
    };

    // const checkIfPanExists = async () => {
    //     let sessionData = await checksession();
    //     let reqData = {
    //         pan: selectedMember.pan,
    //         fp_user_id: selectedMember.id,
    //         fp_log_id: sessionData['data']['fp_log_id'],
    //     };
    //     let checkPan = await restApiCall(
    //         constClass.DMF_CHECKIFPANEXISTS_API_URL,
    //         reqData,
    //         restHeaders
    //     );
    //     if (checkPan.error_code == "100") return true;
    //     else if (checkPan.error_code == "101") return message;
    //     return "Something went wrong!";
    // };

    const checkenterpanexists = async () => {
        let sessionData = await checksession();
        if (selectedMember.pan != "" && selectedMember.pan != null) {
            let url =
                constClass.ADVISORY_CHECK_PAN_EXISTS +
                "?uid=" +
                btoa("00" + sessionData['data']['id']) +
                "&pan=" +
                selectedMember.pan;
            let checkpan = await apiCall(url, "", false, false);
            if (checkpan)
            {
             setIsPanRegistered(true)
            }
            return checkpan;
        }
    };

    const sendOTP = async () => {
        try {
            const mobileErrors = findMobileErrors();
            const panErrors = findPANErrors();
            if (!panEditable && selectedMember.pan != "" && selectedMember.pan != null ) {
                let checkenterPan = await checkenterpanexists();
                if (checkenterPan != true) {
                    panErrors.userPan = checkenterPan;
                }
            }
            if (
                (Object.keys(mobileErrors).length > 0 || Object.keys(panErrors).length > 0) && (mobileErrors.userMobile !== "" || panErrors.userPan !== "")) {
                setErrors({ ...mobileErrors, ...panErrors });
                return;
            }
            // let checkPan = await checkIfPanExists();

            if (mobileErrors['userMobile'].trim() == '' && panErrors['userPan'].trim() === '') {
                props.onProceedClick();
                let mobileNo = selectedMember.mobile;
                try {
                    const rid = uuidv4();
                    const ts = new Date().toISOString();

                    const loginPayload = {
                        header: {
                            rid: rid,
                            ts: ts,
                            channelId: "finsense",
                        },
                        body: {
                            userId: "channel@fintoo",
                            password: "85a333fb49044c7e91611a0d962ff8ba",
                        },
                    };

                    const url =
                        "https://fintoo.fiulive.finfactor.co.in/finsense/API/V2/User/Login";
                    const options = {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(loginPayload),
                    };

                    const loginResponse = await fetch(url, options);
                    if (loginResponse.status === 200) {
                        const responseData = await loginResponse.json();
                        const token = responseData.body.token;
                        commonEncode.encrypt(createCookie("token", commonEncode.encrypt(JSON.stringify(token, 60))));

                        let consent_url = "https://fintoo.fiulive.finfactor.co.in/finsense/API/V2/ConsentRequestPlus";
                        let redirect_url = `https://stg.minty.co.in/money_managment/FatchDataFromAccount/?mob_no=${mobileNo}`;

                        const headers = {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            Authorization: "Bearer " + token,
                        };
                        const payload = {
                            header: {
                                ts: ts,
                                channelId: "finsense",
                                rid: rid,
                            },
                            body: {
                                custId: mobileNo + "@finvu",
                                consentDescription: "Apply for loan",
                                templateName: "BANK_STATEMENT_PERIODIC",
                                userSessionId: "sessionid123",
                                redirectUrl: redirect_url,
                                fip: [""],
                                ConsentDetails: {},
                            },
                        };

                        const response = await fetch(
                            "https://fintoo.fiulive.finfactor.co.in/finsense/API/V2/ConsentRequestPlus",
                            {
                                method: "POST",
                                headers: headers,
                                body: JSON.stringify(payload),
                            }
                        );

                        if (response.status === 200) {
                            const responseData = await response.json();
                            setHandleId(responseData.body.ConsentHandle);
                            const socketCreation = () => {
                                // Send OTP payload
                                const send_otp_payload = {
                                    header: {
                                        mid: "fcd2c56e-9414-11e8-9eb6-529269fb1459",
                                        ts: new Date().toISOString().replace("Z", "+00:00"),
                                        sid: "",
                                        dup: "false",
                                        type: "urn:finvu:in:app:req.loginOtp.01",
                                    },
                                    payload: {
                                        username: selectedMember.mobile + "@finvu",
                                        mobileNum: selectedMember.mobile,
                                        handleId: responseData.body.ConsentHandle,
                                    },

                                };
                                // props.setDummy(send_otp_payload['payload'])                              
                                props.setDummy({...send_otp_payload['payload'],tokenid:token,pan:selectedMember.pan,id:selectedMember.id,consentid:responseData.body.ConsentHandle,user_id:userId,fp_log_id:selectedMember.fp_log_id,retirement_date:selectedMember.retirement_date,name:selectedMember.name,user_specific_id :selectedMember.user_id })
                                socket.send(JSON.stringify(send_otp_payload));
                                if (send_otp_payload) {
                                    const tokenMessage = {
                                        header: {},
                                        payload: {
                                            token: token,
                                            handleId: responseData.body.ConsentHandle,
                                        },
                                    };
                                    socket.send(JSON.stringify(tokenMessage));
                                }
                            };
                            socketCreation();
                        }
                    } else {
                        console.error("Request failed with status code:", loginResponse.status);
                    }
                } catch (error) {
                    console.error("An error occurred:", error.message);
                }
            }
            //   if (checkPan != true) {
            //     toastr.options.positionClass = "toast-bottom-left";
            //     toastr.error(checkPan);
            //     return;
            //   }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <div className={` DeamtBasicDetailsSection ${Styles.BasicDetailsSection}`}>
                <div className="">
                    <div className={`${Styles.title}`}>Name</div>
                    <div className="mt-2">
                        <Select classNamePrefix="sortSelect"
                            placeholder="Select Member"
                            isSearchable={false}
                            styles={CustomStyles}
                            options={allMembers}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                            value={allMembers.filter(
                                (v) => v.id == selectedMember.id
                            )}
                        />
                    </div>
                </div>
                <div className="mt-4 ">
                    <div className={`${Styles.title}`}>PAN Number</div>
                    <div className="mt-2">
                        <input className={`${Styles.inputField}`}  placeholder="Enter PAN Number" type="text"
                            value={selectedMember.pan ?? ""}
                            onChange={(e) => handlePANChange(e)}
                            readOnly={panEditable}
                        />
                        {errors.userPan && (
                            <p className="error">{errors.userPan}</p>
                        )}
                    </div>
                </div>
                <div className="mt-4">
                    <div className={`${Styles.title}`}>Mobile Number</div>
                    <div className="mt-2">
                        <input className={`${Styles.inputField}`}
                            type="number"
                           
                            placeholder="(+91) Enter 10 Digit Mobile Number"
                            value={selectedMember.mobile ?? ""}
                            onChange={(e) => handleMobileChange(e)}
                        />
                        {errors.userMobile && (
                            <p className="error">{errors.userMobile}</p>
                        )}
                    </div>
                </div>
                <div className="mt-4">
                    <div className="ButtonBx d-flex justify-content-center">
                        <button className="Cancel" onClick={() => {
                            props.onClose();
                        }}>
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="Unlink ms-md-0 ms-2"
                            disabled={sendDisabled}
                            onClick={() => {
                              sendOTP()
                            //   props.onProceedClick();
                            } }
                        >
                            Proceed
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
export default BasicDetails;
