import InternationalEquityAdvantagesSection from "../components/HTML/InternationalEquity/AdvantagesSection";
import InternationalEquityHeaderSection from "../components/HTML/InternationalEquity/InternatinoalEquityHeaderSection";
import InternationalEquityContactForm from "../components/HTML/InternationalEquity/InternationalEquityContactSection";
import WhyInvestSection from "../components/HTML/InternationalEquity/WhyInvestSection";
import FaqSection from "../components/HTML/InternationalEquity/FaqSection";
import Fullpage from "../components/Layout/Fullpage";
import { servicesTypes } from "../components/WealthManagementContactForm/model";
import { useEffect, useState } from "react";
import ThankyouSection from "../components/ThankyouSection";
import AppointmentBox from "../components/Pages/Calendly";
function InternationalEquity() {
  const [show, SetShow] = useState(false);

  useEffect(() => {}, []);

  return (
    <Fullpage>
      <div style={{ backgroundColor: "white" }}>
        <InternationalEquityHeaderSection />
      
        {/* <InternationalEquityContactForm serviceType={servicesTypes.internationalEquity} onSuccess={() => SetShow(true)} /> */}
       
          <InternationalEquityAdvantagesSection />
        
        <section
          style={{
            backgroundColor: "#e8f6fc",
          }}
        >
        <WhyInvestSection />
        </section>

        {show && <ThankyouSection onClose={() => SetShow(false)} />}
        <AppointmentBox eventCode={'Callback_mintyApp_90'} serviceName="Direct International Equity" eventUrl="https://calendly.com/fintoo/15-min-consultation-call-international-equity?hide_event_type_details=1" />
        <FaqSection />
      </div>
    </Fullpage>
  );
}

export default InternationalEquity;
