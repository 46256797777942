import React, { useRef, useState } from 'react'
import style from "./style.module.css";
import Insurancecalculator from "../Insurance/insurancecalculator"
import Toolsbox from './Toolsbox';
const ToolsandCalculator = () => {
  const [selectedTitle, setSelectedTitle] = useState("Human Life Value Calculator");
  const [selectedTool, setSelectedTool] = useState(1); 
  const calculatorRef = useRef(null);
  const handleToolClick = (id,title) => {
    setSelectedTitle(title);
    setSelectedTool(id);
    scrollToCalculator();
  };
  const scrollToCalculator = () => {
    calculatorRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div className='container'>
      <Toolsbox selectedTool={selectedTool} onToolClick={handleToolClick}/>
      <div ref={calculatorRef}>
      <Insurancecalculator title={selectedTitle}/>
      </div>
    </div>
  )
}

export default ToolsandCalculator