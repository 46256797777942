import FaqSection from "../components/HTML/Insurance/FaqSection";
import HeaderSection from "../components/HTML/Insurance/HeaderSection";
import HowWeWorkSection from "../components/HTML/Insurance/HowWeWorkSection";
import WhyInsuranceSection from "../components/HTML/Insurance/WhyInsuranceSection";
import WidgetSection from "../components/HTML/Insurance/WidgetSection";
import WealthContactForm from "../components/WealthManagementContactForm/";
import backImg from "../components/Layout/Fullpage/assets/insurance1-contact-form-background.jpg";
import InsuranceClientTestimonialSection from "../components/HTML/Insurance/InsuranceClientTestimonialSection";
import SecuritySection from "../components/HTML/SecuritySection";
import Fullpage from "../components/Layout/Fullpage";
import { servicesTypes } from "../components/WealthManagementContactForm/model";
import ThankyouSection from "../components/ThankyouSection";
import { useState } from "react";
import AppointmentBox from "../components/Pages/Calendly";
import ClientTestimonial from "../components/HTML/ClientTestimonial";
function Insurance1() {
  const [show, SetShow] = useState(false);

  return (
    <Fullpage>
      <div style={{ backgroundColor: "white" }}>
        <HeaderSection />
        <WhyInsuranceSection />
        {/* <WidgetSection /> */}
        <HowWeWorkSection />
        <SecuritySection DynamicBgColor={"#E8F6FC"} />
        <ClientTestimonial />
        {/* <InsuranceClientTestimonialSection /> */}
        <section
          style={{
            // 
          }}
          id="Book"
        >
           <AppointmentBox eventCode={'Callback_mintyApp_12'} serviceName="Insurance"  eventUrl="https://calendly.com/fintoo/15-min-consultation-call-risk-management?hide_event_type_details=1" />
          {/* <WealthContactForm imgSrc={backImg} serviceType={servicesTypes.insurance} onSuccess={() => SetShow(true)} /> */}
        </section>
          <FaqSection />
        {show && <ThankyouSection onClose={() => SetShow(false)} />}
      </div>
    </Fullpage>
  );
}

export default Insurance1;
