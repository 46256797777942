import React from 'react'
import Style from "./style.module.css";
const Security = () => {
    return (
        <div className={` ${Style.Security_Section}`}>
            <div className='container'>
                <div className={`${Style.whyinvesttitle}`}>Invest with Complete Security</div>
                <div className={`${Style.investCard}`}>
                    <div>
                        <div className='mb-3'>
                            <img style={{borderRadius : "14px"}} src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Data_security.svg'} alt=' Data Security' />
                        </div>
                        <div className={`${Style.whyinvestkeypoint}`}> Data Security</div>
                        <div className={`${Style.whyinvestkeypointdec}`}>Safeguard your investments with robust security measures.</div>
                    </div>
                    <div>
                        <div className='mb-3'>
                            <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Cloud_Infrastructure.svg'} alt='Cloud Infrastructure' />
                        </div>
                        <div className={`${Style.whyinvestkeypoint}`}> Cloud Infrastructure</div>
                        <div className={`${Style.whyinvestkeypointdec}`}>Leverage the reliability of advanced, secure cloud systems.</div>
                    </div>
                    <div>
                        <div className='mb-3'>
                            <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Authorised_Access.svg'} alt=' Authorized Transactions' />
                        </div>
                        <div className={`${Style.whyinvestkeypoint}`}>Authorized Transactions</div>
                        <div className={`${Style.whyinvestkeypointdec}`}>Easily buy or sell your mutual fund shares.</div>
                    </div>
                    <div>
                        <div className='mb-3'>
                            <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Security_Audited.svg'} alt=' Security Audited' />
                        </div>
                        <div className={`${Style.whyinvestkeypoint}`}> Regular Audits</div>
                        <div className={`${Style.whyinvestkeypointdec}`}>Ensure your investments are protected through frequent security audits.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Security