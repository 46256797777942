import FaqSection from "../components/HTML/ITRFile/FaqSection";
import ITRFileHeaderSection from "../components/HTML/ITRFile/ITRFileHeaderSection";
import Fullpage from "../components/Layout/Fullpage";
import ThankyouSection from "../components/ThankyouSection";
import { useState } from "react";
import AppointmentBox from "../components/Pages/Calendly";
import ITRVideoCardSection from "../components/HTML/ITRFile/ITRVideoCardSection";
import ITRCardSection from "../components/HTML/ITRFile/ITRCardSection";
import ClientTestimonial from "../components/HTML/ClientTestimonial";
function ITRFile() {
  const [show, SetShow] = useState(false)

  return (
    <Fullpage>
      <div style={{ backgroundColor: "white" }}>
        <ITRFileHeaderSection />
        {/* <ITRCardSection /> */}
        <ITRVideoCardSection />
       <ClientTestimonial />
       <FaqSection />
      </div>
    </Fullpage>
  );

}

export default ITRFile;
