import React, { forwardRef } from 'react';
import Style from './style.module.css';
import { Link } from 'react-router-dom';

const CardBox = forwardRef(({ bgColor, textColor, title, description, imageSrc, altText, arrowImg, spanColor, link, unlink }, ref) => {
    return (
        <div ref={ref} style={{ background: bgColor }} className={`${Style.cardBox}`}>
            <Link  style={{ textDecoration: "none", cursor : unlink ? "normal" : "pointer" }} to={link}>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className={`${Style.textSection}`}>
                        <div style={{ color: textColor }} className={`${Style.cardboxtitle}`}>{title}</div>
                        <div style={{ color: textColor }} className={`${Style.cardboxdes}`}>{description}</div>
                        <div className={`${Style.learnMore}`}>
                            {
                                unlink ? <>
                                    <button className={Style.commingSoinbtn}>Comming Soon..</button>
                                </> : <Link style={{ textDecoration: "none" }} to={link}>
                                    <img className='me-2' width={30} src={arrowImg} alt='Next Arrow' />
                                    <span style={{ color: spanColor }}>Learn more</span>
                                </Link>
                            }

                        </div>
                    </div>
                    <div className='d-md-block d-none'>
                        <img className={`${Style.cardBoximg}`} src={imageSrc} alt={altText} />
                    </div>
                </div>
            </Link>
        </div>
    );
});

export default CardBox;
