import React from "react";
import DirectMFHeaderSection from "../components/HTML/DirectMF/DirectMFHeaderSection";
import DirectMFTestimonialSection from "../components/HTML/DirectMF/DirectMFTestimonials";
import ExpertAdvisorySection from "../components/HTML/DirectMF/ExpertAdvisorySection";
import FeaturesSection from "../components/HTML/DirectMF/FeaturesSection";
import LifeChoiceSection from "../components/HTML/DirectMF/LifeChoiceSection";
import StatsSection from "../components/HTML/DirectMF/StatsSection";
import FaqSection from "../components/HTML/DirectMF/FaqSection";
import Fullpage from "../components/Layout/Fullpage";
import WealthContactForm from "../components/WealthManagementContactForm";
import backImg from "../components/Layout/Fullpage/assets/insurance1-contact-form-background.jpg";
import { servicesTypes } from "../components/WealthManagementContactForm/model";
import ThankyouSection from "../components/ThankyouSection";
import { useState } from "react";
import Main from "./DMF/Main";
import AppointmentBox from "../components/Pages/Calendly";
import ClientTestimonial from "../components/HTML/ClientTestimonial";

function DirectMF() {
  const [show, SetShow] = useState(false);
  React.useEffect(()=> {
    document.body.classList.add('MainDivRemove');

  }, []);
  return (
    <Fullpage>
      <div style={{ backgroundColor: "white", overflow : "hidden" }}>
        <DirectMFHeaderSection />
        {/* <div>
          <Main isDmf={false} />
        </div> */}
        {/* <LifeChoiceSection /> */}
        <div className="mb-md-5">
        <FeaturesSection />
        </div>
        {/* <ExpertAdvisorySection /> */}
        <StatsSection />
        <ClientTestimonial />
        {/* <DirectMFTestimonialSection /> */}
        <AppointmentBox eventCode={'Callback_mintyApp_25'} serviceName="Mutual Fund" eventUrl="https://calendly.com/fintoo/15-min-consultation-call-mutual-fund?hide_event_type_details=1" />
        {/* <WealthContactForm
          imgSrc={backImg}
          serviceType={servicesTypes.DMF}
          onSuccess={() => SetShow(true)}
        /> */}
        <FaqSection />
        {show && <ThankyouSection onClose={() => SetShow(false)} />}
      </div>
    </Fullpage>
  );
}

export default DirectMF;
