import React, { useEffect } from 'react';
import Herosection from '../../../components/LandingPages/International_Equity/Herosection';;
import Ourfeaturesection from '../../../components/LandingPages/International_Equity/Our_Features';
import FaqAccordian from '../../../components/LandingPages/International_Equity/FaqAccordian';
import BookCalendly from '../../../components/LandingPages/International_Equity/Calendly';
import WhydoInternationalinvestsection from '../../../components/LandingPages/International_Equity/Whyinvest';
import ClientTestimonial from '../../../components/HTML/ClientTestimonial';

function International_Equity() {
  useEffect(() => {
    document.body.classList.add("Bg-white")
    return () => {
      document.body.classList.remove("Bg-white");
    };
  }, []);

  return (
    <div>
      <Herosection />
      <WhydoInternationalinvestsection />
      <Ourfeaturesection />
      <p style={{height : "3rem"}}></p>
      <BookCalendly title="Ready to Expand Your Investment Horizons?" titleDes="Join thousands of satisfied investors and start growing your wealth globally today" logo={false}/>
      <ClientTestimonial />
      <p style={{height : "3rem"}}></p>
      <FaqAccordian />
      
    </div>
  );
}

export default International_Equity;
