import React, { useState } from 'react';
import style from './style.module.css';
import InvestmentTypeSwitcher from './InvestmentTypeSwitcher.js';
import SliderInput from './SliderInput';
import InvestmentSummary from './InvestmentSummary';
import InvestmentButton from './InvestmentButton';

const Mfcalculator = () => {
    const [monthlyInvestment, setMonthlyInvestment] = useState(10000);
    const [period, setPeriod] = useState(10);
    const [growthRate, setGrowthRate] = useState(10);
    const investedValue = monthlyInvestment * period * 12;
    const estimatedReturns = investedValue * (growthRate / 100);
    const totalValue = investedValue + estimatedReturns;
    const [selectedOption, setSelectedOption] = useState('sip');

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };
    const handleMonthlyInvestmentChange = (newValue) => {
        const parsedValue = Number(newValue);
        if (!isNaN(parsedValue) && parsedValue <= 1000000) {
            setMonthlyInvestment(parsedValue);
        }
    }
    const handleMonthlyInvestmentBlur = () => {
        const clampedValue = Math.max(500, Math.min(1000000, monthlyInvestment));
        setMonthlyInvestment(clampedValue);
    };

    const handlePeriodChange = (newValue) => {
        const parsedValue = Number(newValue);
        if (!isNaN(parsedValue) && parsedValue <= 50) {
            setPeriod(parsedValue);
        }
    };

    const handlePeriodBlur = () => {
        // Apply constraints when user finishes typing
        const clampedValue = Math.max(0.5, Math.min(15, period));
        setPeriod(clampedValue);
    };

    const handleGrowthRateChange = (newValue) => {
        const parsedValue = Number(newValue);
        if (!isNaN(parsedValue) && parsedValue <= 30) {
            setGrowthRate(parsedValue);
        }
    };

    const handleGrowthRateBlur = () => {
        // Apply constraints when user finishes typing
        const clampedValue = Math.max(1, Math.min(30, growthRate));
        setGrowthRate(clampedValue);
    };

    const formatPeriod = (value) => {
        if (value < 1) {
            const months = Math.round(value * 12);
            return `${months} ${months > 1 ? 'Months' : 'Month'}`;
        } else {
            const years = Math.floor(value);
            return `${years} ${years > 1 ? 'Years' : 'Year'}`;
        }
    };
    const formatInvestment = (value) => `₹ ${value}`;
    const formatGrowthRate = (value) => `${value} %`;

    return (
        <div className={`container ${style.Mfcalculator}`}>
            <div className={style.Mfcalculatortitle}>Calculator</div>
            <div className={`row ${style.calculatorContainer}`}>

                <div className={`col-md-5 col-12 ${style.leftPanel}`}>
                    <InvestmentTypeSwitcher selectedOption={selectedOption} handleOptionChange={handleOptionChange} />


                    <SliderInput
                        label={selectedOption === 'sip' ? "Monthly Investment" : "Total Investment"}
                        min={500}
                        max={1000000}
                        value={monthlyInvestment}
                        onChange={handleMonthlyInvestmentChange}
                        formatValue={formatInvestment}
                        unit="₹"
                        Rupee={true}
                        onBlur={handleMonthlyInvestmentBlur}
                    />

                    <SliderInput
                        label="For A Period Of"
                        min={0.5}
                        max={50}
                        step={0.5}
                        value={period}
                        onChange={handlePeriodChange}
                        formatValue={formatPeriod}
                        unit={period < 1 ? 'Years' : 'Years'}
                        Rupee={false}
                        onBlur={handlePeriodBlur}
                    />

                    <SliderInput
                        label={selectedOption === 'sip' ? "Growth Rate" : "Expected rate of return"}
                        min={1}
                        max={30}
                        value={growthRate}
                        onChange={handleGrowthRateChange}
                        formatValue={formatGrowthRate}
                        unit="%"
                        Rupee={false}
                        onBlur={handleGrowthRateBlur}
                    />
                </div>
                <div className={`col-2 ${style.Divider}`}></div>
                <div className={`col-md-5 col-12 ${style.rightPanel}`}>
                    <InvestmentSummary
                        investedValue={investedValue.toFixed(2)}
                        estimatedReturns={estimatedReturns.toFixed(2)}
                        totalValue={totalValue.toFixed(2)}
                    />
                    <div className="mt-4">
                        <InvestmentButton />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mfcalculator;
