import React, { useState } from 'react';
import radioOn from './radio_button_on.svg';
import radioOff from './radio_button_off.svg';

const InvestmentTypeSwitcher = ({selectedOption, handleOptionChange}) => {
  

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '2rem' }}>
      <label onClick={() => handleOptionChange('sip')} style={{ display: 'flex', alignItems: 'center', marginRight: '2rem', cursor: 'pointer' }}>
        <img
          src={selectedOption === 'sip' ? radioOn : radioOff}
          alt="radio button"
          style={{ width: '20px', height: '20px', marginRight: '0.5rem' }}
        />
        SIP
      </label>
      <label onClick={() => handleOptionChange('lumpsum')} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <img
          src={selectedOption === 'lumpsum' ? radioOn : radioOff}
          alt="radio button"
          style={{ width: '20px', height: '20px', marginRight: '0.5rem' }}
        />
        Lumpsum
      </label>
    </div>
  );
};

export default InvestmentTypeSwitcher;
