import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import CardBox from './FeatureCard';
import Style from './style.module.css';

gsap.registerPlugin(ScrollTrigger);

const FeatureCard = () => {
    const cardRef = useRef([]);
    useEffect(() => {
        cardRef.current.forEach((card, index) => {
            gsap.fromTo(
                card,
                {
                    opacity: 0,
                    x: index % 2 === 0 ? -200 : 200,
                    y: 50
                },
                {
                    opacity: 1,
                    x: 0,
                    y: 0,
                    duration: 1.5,
                    ease: 'power2.out',
                    scrollTrigger: {
                        trigger: card,
                        start: 'top 80%',
                        end: 'bottom 20%',
                        toggleActions: 'play none none reverse',
                        scrub: true,
                        invalidateOnRefresh: true,
                    },
                }
            );
        });
    }, []);

    return (
        <div className={`container ${Style.internationalequityFeaturesSection}`}>
            <div className={Style.FeaturesSectionTxt}>Our Features</div>
            <div className={Style.CardView}>
                <CardBox
                    ref={(el) => (cardRef.current[0] = el)}
                    link={`${process.env.PUBLIC_URL}/mutual-fund-page`}
                    bgColor=""
                    textColor="#042b62"
                    title="Smooth onboarding experience"
                    description="Getting started is simple and straightforward. Enjoy a hassle-free onboarding process."
                    imageSrc={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Realtionship.svg'}
                    altText="Smooth onboarding experience"
                    spanColor="#042b62"
                />
                <CardBox
                    ref={(el) => (cardRef.current[1] = el)}
                    bgColor=""
                    // textColor="#fff"
                    // spanColor="#fff"
                    title="No minimum Investment"
                    description="Start investing with any amount, so you can grow at your own pace."
                    imageSrc={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Investment.svg'}
                    altText="No minimum Investment"
                />
                <CardBox
                    ref={(el) => (cardRef.current[2] = el)}
                    bgColor=""
                    // textColor="#fff"
                    title="Fractional Shares"
                    description="Stock is high priced? Buy just a fraction of a stock! Yes, that’s possible."
                    imageSrc={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Fractional_Shares.svg'}
                    altText="Fractional Shares"
                    // spanColor="#fff"
                />
                <CardBox
                    ref={(el) => (cardRef.current[3] = el)}
                    bgColor=""
                    textColor="#042b62"
                    title="Thematic Investments"
                    description="Interested in specific sectors? Explore tailored investment opportunities across diverse themes and sectors."
                    imageSrc={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Invest_.svg'}
                    altText="Thematic Investments"
                    spanColor="#042b62"
                />
            </div>
        </div>
    );
};

export default FeatureCard;
