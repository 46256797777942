import React, { useState, useEffect } from "react";
import styles from "./style.module.css";

import { Link } from "react-router-dom";
import Logo from "../images/logo.svg";
import Confirm from "../images/Confirm.png";
import commonEncode from "../../../commonEncode";
import AppointmentBox from "../../../components/Pages/Calendly/ITRindex";
import ApplyWhiteBg from "../../../components/ApplyWhiteBg";
import HideFooter from "../../../components/HideFooter";
import HideHeader from "../../../components/HideHeader";
import { getUserId, loginRedirectGuest } from "../../../common_utilities";
function Appointment() {
  const [value, setvalue] = useState("");
  const [url, setUrl] = useState("");

  useEffect(() => {

    console.log('nil78', getUserId());
    if(getUserId() == null) {
      loginRedirectGuest();
      return;
    }
    document.body.classList.add("bg-color");
    setvalue(JSON.parse(commonEncode.decrypt(localStorage.getItem("pid"))));
    return () => {
      document.body.classList.remove("bg-color");
    };
  }, []);


  useEffect(() => {
    if(getUserId() == null) return;
    if (value.plan_id == 39) {
      setUrl(
        "https://calendly.com/d/yvb-km2-hc5/60min-consultation-call-individual-with-salary-itr?hide_event_type_details=1&hide_gdpr_banner=1"
      );
    } else if (value.plan_id == 40) {
      setUrl(
        "https://calendly.com/d/y6q-scq-44k/60min-consultation-call-individual-with-capital-gain?hide_event_type_details=1&hide_gdpr_banner=1"
      );
    } else if (value.plan_id == 41) {
      setUrl(
        "https://calendly.com/d/yt6-c3m-zs3/60min-consultation-call-resident-nri-itr?hide_event_type_details=1&hide_gdpr_banner=1"
      );
    } else if (value.plan_id == 42) {
      setUrl(
        "https://calendly.com/d/y7c-kzw-8h3/60min-consultation-call-presumptive-income-tax-itr?hide_event_type_details=1&hide_gdpr_banner=1"
      );
    } else {
      setUrl(" ");
    }
  }, [value]);

  console.log(value.plan_id, "value.plan_id");

  return (
    <>
      <HideFooter />
      <HideHeader />
      <ApplyWhiteBg />

      <div className={`${styles.PlanSubscription}`}>
        <section className={`${styles.Appointment_section}`}>
          <div className="container-fluid">
            <div className="row ">
              <div className="col-md-6 col-12">
                <div className={`${styles.Appointment_section_block}`}>
                  <div className="d-flex justify-content-center">
                    <img src={Logo} />
                  </div>
                  <h2 className={`text-center ${styles.page_header}`}>
                    Schedule Appointment
                  </h2>
                  <p className={`text-center ${styles.page_subTxt}`}>
                    Tax Filing | Individuals with Capital Gains
                  </p>
                </div>

                <div className={` ${styles.AppointmentFrame}`}>
                  <div className="">
                    <AppointmentBox
                      eventCode={"ITR_2023"}
                      serviceName="itr-filing"
                      eventUrl={url}
                      extraParams={{
                        tagval: "itr_filing_2023",
                        service: "91",
                        utm_source: "26",
                        rm_id: 96,
                        skip_mail: "1",
                        comment: "ITR filing 2023",
                        tags: "itr_filing_2023",
                        itr: "itr",
                        plan_name: value.plan_name,
                      }}
                    />
                  </div>
                </div>
                <div className={`d-none ${styles.AppointmentConfirmFrame}`}>
                  <div className={` ${styles.Confirmed}`}>
                    <div className={`${styles.confirmBox}`}>
                      <div className="d-flex justify-content-center">
                        <img src={Confirm} />
                      </div>
                      <div className="mt-5">
                        <p> Confirmed </p>
                      </div>
                      <div className="mt-4">You are Scheduled with Fintooinvest.</div>
                      <div className="mt-4">Redirecting....</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`col-6 h100 ${styles.login_illustration}`}></div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Appointment;
