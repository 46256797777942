import React from 'react'
import Style from "./style.module.css";
const Whyinvest = () => {
    return (
        <div className={` ${Style.Whyinvestsection}`}>
            <div className='container'>
                <div className={`${Style.whyinvesttitle}`}>Why Invest in Mutual Funds?</div>
                <div className={`${Style.investCard}`}>
                    <div>
                        <div className='mb-3'>
                            <img style={{borderRadius : "14px"}} src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Diversification.svg'} alt='Diversification' />
                        </div>
                        <div className={`${Style.whyinvestkeypoint}`}>Diversification</div>
                        <div className={`${Style.whyinvestkeypointdec}`}>Distribute your risk across multiple assets</div>
                    </div>
                    <div>
                        <div className='mb-3'>
                            <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Professional_Management.svg'} alt='Professional Management' />
                        </div>
                        <div className={`${Style.whyinvestkeypoint}`}>Professional Management</div>
                        <div className={`${Style.whyinvestkeypointdec}`}>Benefit from the expertise of certified fund managers</div>
                    </div>
                    <div>
                        <div className='mb-3'>
                            <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Liquidity.svg'} alt='Liquidity' />
                        </div>
                        <div className={`${Style.whyinvestkeypoint}`}>Liquidity</div>
                        <div className={`${Style.whyinvestkeypointdec}`}>Buy or sell your mutual fund shares effortlessly</div>
                    </div>
                    <div>
                        <div className='mb-3'>
                            <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Accessibility.svg'} alt='Accessibility' />
                        </div>
                        <div className={`${Style.whyinvestkeypoint}`}>Accessibility</div>
                        <div className={`${Style.whyinvestkeypointdec}`}>Start with small amounts and invest regularly</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whyinvest