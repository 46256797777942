import React, { useState, useEffect } from 'react';
import Styles from "./style.module.css"
const PrivacyPolicy = () => {

    return (
        <section className={`${Styles.PrivacyPolicypage}`}>
            <div className={`${Styles.primargBg}`}>
                <div className={`${Styles.aboutusText}`}>
                    Privacy and Security Policy
                </div>
            </div>
            <div className='container'>
                <div className={`${Styles.privacyContent}`}>
                    <div className={`${Styles.PrivacyPolicytitle}`}>Overview</div>
                    <div className={`${Styles.PrivacyPolicyDec}`}>
                        <div>‘Mihika Financial Services Private Limited (hereinafter referred to as “MFS” or “We” or “Us” or “Our”) is a limited liability partnership that provides financial services (“Services”) to customers (“User(s)”, “You”, “Your”) who visit the website www.fintooinvest.com (“Website”). MFS is committed to protecting your privacy. The purpose of this notice is to supply you with the required information at the time of providing us with your personal data. This Privacy Notice explains how MFS collects and uses User’s personal data and their rights and privileges in this regard.</div>
                        <div className='pt-3'>
                            Users are urged to read this Privacy Notice carefully. By visiting the Website or using any of our services, User indicates their confirmation and consent to our use personal information as set out in this Privacy Notice.
                        </div>
                    </div>
                </div>
                <div className={`${Styles.privacyContent}`}>
                    <div className={`${Styles.PrivacyPolicytitle}`}>Collection of Data</div>
                    <div className={`${Styles.PrivacyPolicyDec}`}>
                        <div>MFS will collect personal data specifically and will be controlled by towards the use that User may have instructed for availing the Services.</div>
                        <div className='pt-3'>
                            The said data is collected when User(s):
                            <ol type='a'>
                                <li>Registers on the Website(s), apply to use any of Services, become our client, or contact us in person, by telephone, by email or by post;</li>
                                <li>Provides feedback or complete a contact form on the Website(s).</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.privacyContent}`}>
                    <div className={`${Styles.PrivacyPolicytitle}`}>Nature of Data</div>
                    <div className={`${Styles.PrivacyPolicyDec}`}>
                        <div className=''>
                            The data is collected depending on the kind of Services availed and may include:
                            <ol type='a'>
                                <li>Contact details, such as name, job title, postal address, including home address, where Users have provided this, business address, telephone number, mobile phone number, fax number and email address;</li>
                                <li>Date of birth, place of birth, employment status and tax identification number (i.e. National Insurance Number);</li>
                                <li>Personal identification details, PAN and utility bills;</li>
                                <li>Details of the services requested from MFS such as assets and insurance details, income slab, profession, family details, tax residency, nationality, gender, marital status;</li>
                                <li>In some circumstances, personal data may be collected from a third party source such as User’s organisation, other organisations with whom they have dealings, government agencies, a credit reporting agency;</li>
                                <li>Employment status, income and source of wealth;</li>
                                <li>IP address and browser-generated data when visiting the Website;</li>
                            </ol>
                        </div>
                        <div>
                            User agrees that data provided to MFS is entirely voluntarily and User may choose not to consent or to provide personal data. However, there are circumstances in which MFS cannot take action without certain of your personal data either to provide the Services or details if sought for by regulatory agencies.
                        </div>
                    </div>
                </div>
                <div className={`${Styles.privacyContent}`}>
                    <div className={`${Styles.PrivacyPolicytitle}`}>Use of Data</div>
                    <div className={`${Styles.PrivacyPolicyDec}`}>
                        <div className=''>
                            MFS uses the data to
                            <ol type='a'>
                                <li>Process User’s application to avail Services and for MFS to provide requested Services;</li>
                                <li>Comply with MFS’s obligations arising from any contracts entered into between User and MFS and to provide User with the information, products and services that User requests from MFS;</li>
                                <li>Help protect User information and prevent unauthorised access to it;</li>
                                <li>Deal with any queries, complaints or problems reported by User;</li>
                                <li>Generate statistics relating to Website use by customers, including utility or popularity of certain features or services. However, MFS will not use personally identifiable information for these purposes;</li>
                                <li>If required to do so by law and to the extent necessary for the proper operation of MFS’s systems, to protect MFS and its customers, or for the enforcement of any agreement between User and MFS;</li>
                                <li>To notify User of changes to our Services; and</li>
                                <li>Help improve the Services provided.</li>
                            </ol>
                        </div>
                        <div>
                            Data may also be used to provide User with information about other services offered that are similar to those that User may have applied for or enquired about (in the past) or to provide updates and information about MFS through newsletters or emails. In such cases MFS will obtain the User’s consent in MFS’s reasonable judgement assessed that there is some benefit or value added if we communicate about it.
                        </div>
                    </div>
                </div>
                <div className={`${Styles.privacyContent}`}>
                    <div className={`${Styles.PrivacyPolicytitle}`}>Data Protection</div>
                    <div className={`${Styles.PrivacyPolicyDec}`}>
                        <ol type="a">
                            <li> MFS shall at all times take appropriate security measures (including physical, electronic and procedural measures) to help protect the confidentiality, integrity and availability of Users personal information from unauthorised access and disclosure.</li>
                            <li> The data collected may be used for the purposes of providing the Service, share it with entities that are actively engaged with or within MFS, or that become part of MFS, its brokers, dealers, IT providers, services providers and agents;</li>
                            <li> MFS’s appointed auditors, accountants, lawyers and other professional advisers (e.g. compliance consultants), to the extent that they require access to the information in order to help MFS provide the Services;</li>
                            <li> In rare cases and where required, meet applicable law, the order of a Court or market rules and codes of practice applicable to the circumstances at the time;</li>
                            <li> To investigate or prevent fraud or activities believed to be illegal or otherwise in breach of applicable law;</li>
                            <li> If User provides personal data to us about someone else (such as one of your directors or employees, or someone with whom you have business dealings) User must ensure that it is entitled to disclose that personal data to us and that, without our taking any further steps, MFS may collect, use and disclose that personal data as described in this Privacy Policy. In particular, User must ensure that the individual concerned is aware of the various matters detailed in this Privacy Policy, as those matters relate to that individual, including MFS’s identity, how to contact us, our purposes of collection, our personal data disclosure practices (including disclosure to overseas recipients), the individual's right to obtain access to the personal data and make complaints about the handling of the personal data, and the consequences if the personal data is not provided (such as our inability to provide services).</li>
                            <li> The User should ensure that details provided by third party service providers who are associated with Us have adequate privacy parameters and that the terms provided by such third party service providers terms are complied with.</li>
                        </ol>
                    </div>
                </div>
                <div className={`${Styles.privacyContent}`}>
                    <div className={`${Styles.PrivacyPolicytitle}`}>Website Infrastructure</div>
                    <div className={`${Styles.PrivacyPolicyDec}`}>
                        <div>In order to provide you secure, reliable and continuous services the Website is hosted on a virtual private cloud on Amazon Web Services. These services can withstand increasing traffic and provide a platform that provides best-in-class security.</div>
                        <div className='pt-3'>Communication lines employed by the Website, upstream and downstream, are routed through servers protected by 256bit encryption HTTPS protocol. Certain cyber attacks and malignancies are prevented owing to this technology.</div>
                        <div className='pt-3'>We employ best-in-class anti-virus, anti-malware, systems that eliminate unauthorised intrusion protocols, have deployed file status and integrity mapping.</div>
                    </div>
                </div>
                <div className={`${Styles.privacyContent}`}>
                    <div className={`${Styles.PrivacyPolicytitle}`}>Data Security</div>
                    <div className={`${Styles.PrivacyPolicyDec}`}>
                        <div>Users data are stored in encrypted format and all applications are checked for data and security check in the course of transactions. All data handled by internal teams are transmitted on a need to know basis with appropriate classification.</div>
                        <div className='pt-3'>We have adequate damage rescue and recovery protocols in place to ensure quick recovery and restoration from any untoward incidents or events.</div>
                        <div className='pt-3'>Users on our Website are prompted one-time password wherever applicable for completing transactions and actions.
                        </div>
                    </div>
                </div>
                <div className={`${Styles.privacyContent}`}>
                    <div className={`${Styles.PrivacyPolicytitle}`}>Data retention</div>
                    <div className={`${Styles.PrivacyPolicyDec}`}>
                        <ol type="a">
                            <li> MFS is committed to only keeping User personal data for as long as MFS needs it in order to fulfil the relevant purpose (s) it was collected for, as set out in this Privacy Policy, and for as long as we are required or permitted to keep it by law.</li>
                            <li> MFS retains copies of User contracts in order to enable MFS to deal with any legal issues and the information provided for identification verification checks, for up to three (3) years after termination or expiry of our contract with you. We retain details of complaints for up to three (3) years from the date of receipt.</li>
                        </ol>
                    </div>
                </div>
                <div className={`${Styles.privacyContent}`}>
                    <div className={`${Styles.PrivacyPolicytitle}`}>User rights on Data provided to MFS</div>
                    <div className={`${Styles.PrivacyPolicyDec}`}>
                        <ol type="a">
                            <li> User shall have the right to request a copy of their personal data, which is held with MFS, to have any inaccurate personal data corrected and to object to or restrict our using said personal data. User may report  if it has a concern about our handling of your personal data.</li>
                            <li> When a request is made by User through email, MFS will endeavour to provide you with these details without delay and at the latest within one month of receipt.</li>
                            <li> When MFS receives a subject access request we will provide a copy of the information held free of charge. MFS may charge a reasonable fee to comply with requests for further copies of the same information.</li>
                            <li> The User also has the following rights (unless exemptions apply), which can be exercised by contacting us using the details provided below:
                                <ul style={{ listStyleType: "disc", marginLeft: "1.3rem", marginTop: ".7rem" }}>
                                    <li>To ask us not to process your personal data for marketing purposes;</li>
                                    <li>To prevent any processing of personal data that is causing or is likely to cause unwarranted and substantial damage or distress to you or another individual;</li>
                                    <li>To request the rectification or completion of personal data which are inaccurate or incomplete;</li>
                                    <li>To request its erasure under certain circumstances;</li>
                                    <li>To be informed about any use of your personal data to make automated decisions about you, and to obtain meaningful information about the logic involved, as well as the significance and the envisaged consequences of this processing; and</li>
                                    <li>If you would like to send a report as mentioned above, User can contact MFS at <a href="mailto:support@fintooinvest.in" target="_blank">:support@fintooinvest.in</a></li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
                <div className={`${Styles.privacyContent}`}>
                    <div className={`${Styles.PrivacyPolicytitle}`}>Update of Terms</div>
                    <div className={`${Styles.PrivacyPolicyDec}`}>
                        <div>MFS shall from time to time review these terms and hereby reserves the right to change or modify, any or all of them including, changing of the extent and scope of the Services and/or include any other category, service, facility or feature within the term ‘Service’, at the sole discretion of the Website. Any such change(s) shall be effective immediately upon the relevant webpage going ‘live’. User is urged to determine when these Terms were last revised by referring to ‘LAST UPDATED’ link in these Terms.</div>
                        <div className='pt-3'>
                            E-mail : <a href="mailto:support@fintooinvest.in" target="_blank">support@fintooinvest.in</a>
                        </div>
                        <div className='pt-3'>
                        Address: SHOP NO. 3, EMP. 48 ,D-Wing Halley Towers THAKUR VILLAGE, KANDIVALI-EAST MUMBAI MH 400101
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default PrivacyPolicy;
