import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";
import Footer from "../HTML/Footer";
import Logo from "../../Assets/Images/logo.svg";
import { useEffect } from "react";
import { GrFormClose } from "react-icons/gr";
import { fetchData } from "../../common_utilities";

function ThankyouSection({ onClose }) {
  const navigate = useNavigate();

  

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);
  return (
    <>
      
      <section className={`${styles["Thankyou-Section"]}`}>
        <div className={`${styles.ThanksBox}`}>
          <div
            className={`${styles.closeIcon}`}
            onClick={() => (onClose ? onClose() : navigate(-1))}
          >
            <GrFormClose />
          </div>
          <div className={`${styles.Logo}`}>
            <img
              src={
                process.env.REACT_APP_STATIC_URL + "media/logo.svg"
              }
            />
          </div>
          <div className={`${styles.ThankyouText}`}>Thank you</div>
          <div className={`${styles.ThanksubText}`}>
            We have successfully received your details.
          </div>
          <div className={`${styles.ThankMsg}`}>
            Our Expert will get in touch with you shortly.
          </div>
        </div>
      </section>
    </>
  );
}

export default ThankyouSection;
