import styles from "./style.module.css";
import {
  FaTwitter,
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
function Footer() {
  let currentDate = new Date();
  let year = currentDate.getFullYear();
  const hideMainFooter = useSelector((state) => state.hideMainFooter);

  // console.log('hideMainFooter', hideMainFooter);

  return (
    <>
      {hideMainFooter == false && (
        <footer id="FooterView">
          <div className={`${styles.Footer + " main-footer"}`}>
            <div className={`${styles.FooterSection}`}>
              <div className={`row ${styles.FooterSectionContent}`}>
                <div className={` col-12 ${styles.FooterSectionContent}`}>
                  <div className={`row ${styles.FooterSectionLinks}`}>
                    <div className={`col-md-3 col-12 ${styles.Footerwidget}`}>
                      <div className={`${styles.FooterwidgetImg}`}>

                        <img
                          width={200}
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/footer/fintooinvestlogo.svg"
                          }
                        />
                      </div>
                      <div className={`${styles.Footerwidgetcontact}`}>
                        <a href="tel:+919699800600">+91-9699 800 600</a>
                      </div>
                      <div className={`${styles.Footerwidgetcontact}`}>
                        <a href="mailto:online@fintooinvest.in">online@fintooinvest.in</a>
                      </div>
                      <div className={`${styles.FooterwidgetSocial}`}>
                        <a
                          className={`${styles.FooterwidgetSocialIcons}`}
                          href="https://twitter.com/FintooApp"
                        >
                          <FaTwitter />
                        </a>

                        <a
                          className={`${styles.FooterwidgetSocialIcons}`}
                          href="https://www.facebook.com/fintooapp/"
                        >
                          <FaFacebookF />
                        </a>

                        <a
                          className={`${styles.FooterwidgetSocialIcons}`}
                          href="https://www.linkedin.com/company/1769616/"
                        >
                          <FaLinkedin />
                        </a>

                        <a
                          className={`${styles.FooterwidgetSocialIcons}`}
                          href="https://www.instagram.com/fintoo.app/"
                        >
                          <FaInstagram />
                        </a>

                        <a
                          className={`${styles.FooterwidgetSocialIcons}`}
                          href="https://www.youtube.com/channel/UC00AMcwwfUKrV-XD5n6hWyQ/videos"
                        >
                          <FaYoutube />
                        </a>
                      </div>
                      <div className={`${styles.footernavigation}`}>
                        <a
                          target="_blank"
                          style={{
                            paddingTop: ".5rem !important",
                            padding: 0,
                          }}
                          href="https://play.google.com/store/apps/details?id=distribution.finh.admin.userportfolio.com.portfolioo"
                        >
                          <img
                            alt="Download Fintoo app on play store"
                            className=" ls-is-cached lazyloaded"
                            src={
                              process.env.REACT_APP_STATIC_URL +
                              "media/footer/minty-android-app.png"
                            }
                          />
                        </a>

                        <a
                          target="_blank"
                          style={{
                            paddingTop: ".5rem !important",
                            padding: 0,
                          }}
                          href="https://apps.apple.com/in/app/fintoo/id1550264388"
                          className="ms-2"
                        >
                          <img
                            alt="Download Fintoo app on app store"
                            className=" ls-is-cached lazyloaded"
                            src={
                              process.env.REACT_APP_STATIC_URL +
                              "media/footer/minty-app-store.png"
                            }
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col ms-md-0 ms-4">
                      <h2 className={`row ${styles.footerheading}`}>
                        Product & Services
                      </h2>
                      <ul className={`row ${styles.footernavigation}`}>
                        <li>
                          <a
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                            href={process.env.PUBLIC_URL + "/mutual-fund-page"}
                          >
                            Buy Mutual Funds
                          </a>
                        </li>
                        {/* <li>
                          <a
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                            href="https://www.fintooinvest.in/pickandinvest#pickandinvest"
                          >
                            Goal Based Investing
                          </a>
                        </li> */}
                        {/* <li>
                          <a
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                            href={
                              "https://www.fintooinvest.in/govt-security-bonds"
                            }
                          >
                            Govt. Security Bonds
                          </a>
                        </li> */}
                        <li>
                          <a
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                            href={process.env.PUBLIC_URL + "/mutual-fund/funds/nfo"}
                          >
                            NFO
                          </a>
                        </li>
                        <li>
                          <a
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                            href={process.env.PUBLIC_URL + "/domestic-equity-page"}
                          >
                            Domestic Equity
                          </a>
                        </li>
                        <li>
                          <a
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                            href={process.env.PUBLIC_URL + "/international-equity-page"}
                          >
                            International Equity
                          </a>
                        </li>
                        {/* <li>
                          <a
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                            href={
                              "https://www.fintooinvest.in/ipo"
                            }
                          >
                            IPO
                          </a>
                        </li> */}
                        <li>
                          <a
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                            href={process.env.PUBLIC_URL + "/insurance-page"}
                          >
                            Buy Insurance
                          </a>
                        </li>
                        <li>
                          <a
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                            href={process.env.PUBLIC_URL + "/tools-and-calculator"}
                          >
                            Tools & Calculators
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col">
                      <h2 className={`row ${styles.footerheading}`}>
                        Company
                      </h2>
                      <ul className={`row ${styles.footernavigation}`}>
                        <li>
                          <Link to={`${process.env.PUBLIC_URL}/about-us`}
                          >
                            About Us
                          </Link>
                        </li>
                        <li>
                          <a

                            target="_self"
                            href={`${process.env.PUBLIC_URL}/contact`}
                          >
                            Contact Us
                          </a>
                        </li>
                        <li>
                          <a
                            target="_self"
                            href={process.env.PUBLIC_URL + "/privacy-policy"}
                          >
                            Privacy Policy
                          </a>
                        </li>
                        <li>
                          <a
                            target="_self"
                            href={process.env.PUBLIC_URL + "/terms-conditions"}
                          >
                            Terms & Conditions
                          </a>
                        </li>
                        <li>
                          <a
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                            href={process.env.PUBLIC_URL + "/faq"}
                          >
                            FAQ’s
                          </a>
                        </li>
                        <li>
                          <a
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                            href={
                              "https://www.fintoo.in/blog/how-safe-is-it-to-invest-in-mutual-fund-online/"
                            }
                          >
                            Security
                          </a>
                        </li>

                      </ul>
                    </div>
                    <div className="col">
                      <h2 className={`row ${styles.footerheading}`}>Why Us?</h2>
                      <ul className={`row ${styles.footernavigation}`}>
                        <li>
                          <a
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                            href={process.env.PUBLIC_URL + "/overview"}
                          >
                            Overview
                          </a>
                        </li>
                        <li className="d-none">
                          <a
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                            href={process.env.PUBLIC_URL + "/our-team"}
                          >
                            Our Team
                          </a>
                        </li>
                        <li>
                          <a
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                            href={process.env.PUBLIC_URL + "/client-speak"}
                          >
                            Client Speak
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col">
                      <h2 className={`row ${styles.footerheading}`}>Others</h2>
                      <ul className={`row ${styles.footernavigation}`}>
                        <li>
                          <a
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                            href={process.env.PUBLIC_URL + "/grievance-mechanism"}
                          >
                            Grievance Mechanism
                          </a>
                        </li>
                        <li className="">
                          <a
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                            href={process.env.PUBLIC_URL + "/complaints-status"}
                          >
                            Complaints Status
                          </a>
                        </li>
                        {/* <li className="">
                          <a
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                            href={process.env.PUBLIC_URL + "/investor-charter"}
                          >
                            Investor Charter
                          </a>
                        </li>
                        <li className="">
                          <a
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                            href={process.env.PUBLIC_URL + "/compliance-audit-status"}
                          >
                            Compliance Audit Status
                          </a>
                        </li> */}

                      </ul>
                    </div>

                  </div>
                </div>
              </div>
              <div className={`${styles.FooterSection}`}></div>
            </div>
            <div className={`${styles.FooterSectionText}`}>
              <div className={`${styles.FooterSectionPara}`}>
                <p>
                  All investments involve risk and the past performance of a security, or financial product does not guarantee future results or returns. Keep in mind that while diversification may help spread risk it does not assure a profit, or protect against loss, in a down market. There is always the potential of losing money when you invest in securities, or other financial products. Investors should consider their investment objectives and risks carefully before investing. Investors should be aware that system response, execution price, speed, liquidity, market data, and account access times are affected by many factors, including market volatility, size and type of order, market conditions, system performance, and other factors.
                </p>
                <p>
                  All information placed on fintooinvest.in website is for informational purposes only and does not constitute as an offer to sell or buy a security. Further, any information on the website is not intended as investment advice. Certain hyperlinks or referenced websites on the Site, if any, are for your convenience and forward you to third parties’ websites, which generally are recognized by their top level domain name. Any descriptions of, references to, or links to other products, publications or services does not constitute an endorsement, authorization, sponsorship by or affiliation with Mihika Financial Services Private Limited with respect to any linked site or its sponsor, unless expressly stated by Mihika Financial Services Private Limited. Any such information, products or sites have not necessarily been reviewed by Mihika Financial Services Private Limited and are provided or maintained by third parties over whom Mihika Financial Services Pvt. Ltd. exercise no control. Mihika Financial Services Pvt. Ltd. expressly disclaim any responsibility for the content, the accuracy of the information, and/or quality of products or services provided by or advertised on these third-party sites.
                </p>
                <p>
                  Mihika Financial Services Private Limited role here is limited to a Referrer. Mihika Financial Services Private Limited will introduce the customer with the platform provider Stockal post which clients will be directly sharing their details to third party stock broker Drive Wealth. Once customers have been referred, they are solely responsible for any and all orders placed by them, and understand that all orders are unsolicited and based on their own investment decisions. Mihika Financial Services Pvt. Ltd. and any of its employees, agents, principals, or representatives DO NOT: provide recommendations of any security, transaction, or order; provide investment advice; produce or provide research to any user regarding any security, transaction, or order; handle funds or securities related to securities orders or effect the clearance or settlement of a user’s trades done through fintooinvest.in solution provided by Stockal Inc. All processes including KYC will be executed by Stockal directly in case of investments in international security market with client and Mihika Financial Services Private Limited will not incur any personal financial liability.
                </p>
                <p>
                  Mihika Financial Services Private Limited role here is limited to a Referrer. Once customers have been referred, they are solely responsible for any and all orders placed by them, and understand that all orders are unsolicited and based on their own investment decisions. Mihika Financial Services Private Limited and any of its employees, agents, principals, or representatives DO NOT: provide recommendations of any security, transaction, or order; provide investment advice; produce or provide research to any user regarding any security, transaction, or order; handle funds or securities related to securities orders or effect the clearance or settlement of a user’s trades done through fintooinvest.in solution provided by IIFL Securities Limited. All processes including KYC will be executed by IIFL Securities Limited directly in case of investments in domestic security market with client and Mihika Financial Services Private Limited will not incur any personal financial liability.
                </p>
                <p>
                  Investment in securities market are subject to market risks, read all the related details carefully before investing. Mutual fund investments are subject to market risks, read all scheme related documents carefully. Past performance is not indicative of future returns. Please consider your specific investment requirements before choosing a fund, or before making portfolio. Mihika Financial Services Private Limited (AMFI-registered Mutual Fund Distributor, having ARN Code - 21209 register w.e.f 30th July 2004 and valid till 25th july 2026) [Platform Partner : BSE (Member code-10096)] [GST Registration No: 27AADCM8314R2ZU] makes no warranties or representations, express or implied, on products and services offered through the platform (fintooinvest.in) referred as "website". It accepts no liability for any damages or losses, however, caused in connection with the use of related services. Terms and Conditions are applicable.
                </p>
                <p>
                  Mihika Financial Services Private Limited is a financial services intermediary and is engaged as a distributor of financial products & services in strategic distribution partnerships. Customers need to check products & features before investing since the contours of the product rates may change from time to time. Mihika Financial Services Private Limited is not liable for any loss or damage of any kind arising out of investments in these products. Investments in Equity, Currency, Futures & Options are subject to market risk. Clients should read the Risk Disclosure Document issued by SEBI & relevant exchanges & the T&C on fintooinvest.in before investing. Equity SIP is not an approved product of Exchange and any dispute related to this will not be dealt at Exchange platform.
                </p>
                <p>
                  All logos and trademarks belong to their respective legal owners.
                </p>
              </div>
              <div
                className={`text-center ${styles.FooterSectionCopyRightText}`}
              >
                Copyright © {year} Mihika Financial Services Private Limited. All rights reserved.
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
}

export default Footer;
