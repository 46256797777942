import React, { useEffect, useState } from 'react';
import style from './style.module.css';
import SavingAccountExploreSection from './SavingAccountExploreSection';
const Investsection = () => {
    return (
        <div className={`${style.ScheduleAndAccountContainer}`}>
            <div className={`Section ${style.AccountInfoSectionContainer}`} style={{ margin: '0', width: '100%'}}>
                    <SavingAccountExploreSection />
            </div>
        </div>

    );
};

export default Investsection;
