import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Success from "../../Assets/payment_success.png";
import MainLayout from "../../Layout/MainLayout";
import { useSearchParams } from "react-router-dom";
import GuestLayout from "../../Layout/GuestLayout";
import { loginRedirectGuest } from "../../../common_utilities";
const SessionExpired = () => {
  

  return (
    <GuestLayout className="container-sm">
      <div className="PaymentSuccess">
        <div className="Res-modal ">
          
          <div className="mt-4 justify-center align-content-center">
            <h5 className="text-center">Session Expired</h5>
            <p
              className="text-center"
              style={{
                color: "#a0a0a0",
                fontSize: "1em",
                // padding: "2rem",
              }}
            >
              Your session has expired due to inactivity. Please log in again to continue.
            </p>
          </div>
          <div className="ErrorBtn">
            {/* <button
              className="shadow-none outline-none continue-btn "
              //   onClick={handleClose}
            >
              Download Receipt
            </button> */}
            <button
              className="shadow-none outline-none continue-btn w-30"
              onClick={()=> {
                loginRedirectGuest();
              }}
            >
              <a>Login</a>
            </button>
          </div>
        </div>
      </div>
    </GuestLayout>
  );
};

export default SessionExpired;
