import { useState } from "react";
import FaqAccordianContent from "./FaqAccordionContent";
import { AiOutlinePlus } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import style from "./style.module.css";
import ApplyWhiteBg from "../ApplyWhiteBg";

function FaqAccordian() {
  const [activeIndex, SetActiveIndex] = useState(0);

  const updateAccordionIndex = (idx) => {
    if (activeIndex === idx) {
      return SetActiveIndex(() => null);
    } else {
      return SetActiveIndex(() => idx);
    }
  };

  const isActive = (idx) => activeIndex === idx;

  return (
    <>
    <ApplyWhiteBg />
      <div className="container">
        <div className={`${style.faqData}`}>
          FAQ's
        </div>

        <div>
          <div>
            <center>
                <img style={{
                  width : "400px"
                }} src="https://www.fintooinvest.in/assets/img/faq-content.svg" alt="faq"/>
            </center>
          </div>
          <div className="accordian-item">
            <div
              className={`${style["accordian-header"]} `}
              onClick={() => updateAccordionIndex(0)}
              style={isActive(0) ? { color: "#042b62" } : { color: "black" }}
            >
              {" "}
              <span>What is FintooInvest?</span>{" "}
              {isActive(0) ? <BsArrowRight /> : <AiOutlinePlus />}{" "}
            </div>
            <FaqAccordianContent activeIndex={isActive(0)}>
              <div>
                <p>
                  FintooInvest is an online platform providing easy mutual fund transactions to investors. In addition, this platform also caters to other exceptional services such as:

                </p>
                <ul className="innerAccord ms-3">
                  <li>- Customization of investment portfolio</li>
                  <li>- Real time portfolio review</li>
                  <li>- Investment reminders</li>
                  <li>- Management of family investments</li>
                </ul>
              </div>
            </FaqAccordianContent>
          </div>
          <div className="accordian-item">
            <div
              className={`${style["accordian-header"]}`}
              onClick={() => updateAccordionIndex(1)}
              style={isActive(1) ? { color: "#042b62" } : { color: "black" }}
            >
              <span>What makes FintooInvest different from other Investment platforms?</span>{" "}
              {isActive(1) ? <BsArrowRight /> : <AiOutlinePlus />}{" "}
            </div>
            <FaqAccordianContent activeIndex={isActive(1)}>
              <div>
                <p>
                  The innovative ideas employed here makes FintooInvest a unique platform. Investors can add family members to their respective investment portfolios and keep up-to-date with every relevant investment. In addition, FintooInvest automatically syncs across all devices after every transaction allowing you to track your investment in real-time. But that’s not all! Unlike other platforms, FintooInvest intelligently segregates the investments following their scheme and asset allocation, thus organizing and managing all your investments in one place.
                </p>
              </div>
            </FaqAccordianContent>
          </div>
       
          <div className="accordian-item">
            <div
              className={`${style["accordian-header"]}`}
              onClick={() => updateAccordionIndex(3)}
              style={isActive(3) ? { color: "#042b62" } : { color: "black" }}
            >
              <span>
                How safe and secure are my data and investment with FintooInvest?
              </span>{" "}
              {isActive(3) ? <BsArrowRight /> : <AiOutlinePlus />}{" "}
            </div>
            <FaqAccordianContent activeIndex={isActive(3)}>
              <div>
                <p>
                  All our data and web services are hosted with electronic security and the network communications are secured with 256 bit encryption. We only store user’s non-personal information via secured methods like Internet Protocol (IP) and Cookies. We use Cookies to track the information flow and for further data based improvements. However, it doesn't contain any kind of personally identifiable information of the users. Similarly, the IP address assigned to each user’s computer is also dynamic and used to diagnose problems related to server.
                </p>
              </div>
            </FaqAccordianContent>
          </div>
          <div className="accordian-item">
            <div
              className={`${style["accordian-header"]}`}
              onClick={() => updateAccordionIndex(4)}
              style={isActive(4) ? { color: "#042b62" } : { color: "black" }}
            >
              <span>Is FintooInvest authorised by SEBI?</span>{" "}
              {isActive(4) ? <BsArrowRight /> : <AiOutlinePlus />}{" "}
            </div>
            <FaqAccordianContent activeIndex={isActive(4)}>
              <div>
                <p>
                  Yes! It is authorized by Security  Exchange Borad of India (SEBI).
                </p>
              </div>
            </FaqAccordianContent>
          </div>
          <div className="accordian-item">
            <div
              className={`${style["accordian-header"]}`}
              onClick={() => updateAccordionIndex(5)}
              style={isActive(5) ? { color: "#042b62" } : { color: "black" }}
            >
              <span>Will I be charged for investing via FintooInvest?</span>{" "}
              {isActive(5) ? <BsArrowRight /> : <AiOutlinePlus />}{" "}
            </div>
            <FaqAccordianContent activeIndex={isActive(5)}>
              <div>
                <p>
                  No! There aren't any charges applicable.
                </p>
              </div>
            </FaqAccordianContent>
          </div>
          <div className="accordian-item">
            <div
              className={`${style["accordian-header"]}`}
              onClick={() => updateAccordionIndex(6)}
              style={isActive(6) ? { color: "#042b62" } : { color: "black" }}
            >
              <span>Do I need a demat account to Invest?</span>{" "}
              {isActive(6) ? <BsArrowRight /> : <AiOutlinePlus />}{" "}
            </div>
            <FaqAccordianContent activeIndex={isActive(6)}>
              <div>
                <p>
                  Demat account is not required. FintooInvest caters to the investment needs in a digitized format.
                </p>
              </div>
            </FaqAccordianContent>
          </div>
          <div className="accordian-item">
            <div
              className={`${style["accordian-header"]}`}
              onClick={() => updateAccordionIndex(7)}
              style={isActive(7) ? { color: "#042b62" } : { color: "black" }}
            >
              <span>What is the difference between Mutual Fund and SIP?</span>{" "}
              {isActive(7) ? <BsArrowRight /> : <AiOutlinePlus />}{" "}
            </div>
            <FaqAccordianContent activeIndex={isActive(7)}>
              <div>
                <p>
                  Mutual Funds and SIPs are both popular and well known terminologies. Mutual funds is the avenue to invest and SIP is a method used to invest. As they serve the same purpose of investment they are often used interchangeably leading to confusion. Here is an example for clarity.                                                                                                                                 Say a person X has Rs. 1,00,000 available with him and is seeking an investment. He chooses mutual fund as an investment avenue over any other, whereby his money would grow with the interest rates (depending on market situation), in the selected scheme. Further, if he wants to follow a monthly pattern to invest, he can invest a smaller amount instead of that lump sum, say as minimum as Rs. 500 or above (depending upon his requirements of the goal). This systematic investment as a plan works out well for investing money in mutual funds.
                </p>
              </div>
            </FaqAccordianContent>
          </div>
          <div className="accordian-item">
            <div
              className={`${style["accordian-header"]}`}
              onClick={() => updateAccordionIndex(8)}
              style={isActive(8) ? { color: "#042b62" } : { color: "black" }}
            >
              <span>Is PAN mandatory for investment?</span>{" "}
              {isActive(8) ? <BsArrowRight /> : <AiOutlinePlus />}{" "}
            </div>
            <FaqAccordianContent activeIndex={isActive(8)}>
              <div>
                <p>
                  Having PAN is mandatory to start an investment.                </p>
              </div>
            </FaqAccordianContent>
          </div>
          <div className="accordian-item">
            <div
              className={`${style["accordian-header"]}`}
              onClick={() => updateAccordionIndex(9)}
              style={isActive(9) ? { color: "#042b62" } : { color: "black" }}
            >
              <span>What is KYC?</span>{" "}
              {isActive(9) ? <BsArrowRight /> : <AiOutlinePlus />}{" "}
            </div>
            <FaqAccordianContent activeIndex={isActive(9)}>
              <div>
                <p>KYC is an acronym referred to "KNOW YOUR CUSTOMERS", commonly used for customer identification procedure, as per the rules ascertained by SEBI. For Financial Institutions and Financial Intermediaries, in order to "know their clients" SEBI has set a particular format that needs to be fulfilled by the customers. KYC is a one-time-activity, and once complied with, investors can invest in any AMC.
                </p>
                <p><span style={{fontWeight : "bold"}}>KYC registration procedure:</span></p>
                <ol className="innerAccordordered">
                  <li> Download KYC form from any of the AMC's website or from transfer agent's website. Investors can also visit the 'Download' section of www.cvlindia.com.</li>
                  <li> Take a print out of the KYC application for fresh KYC.</li>
                  <li> Fill the form as per the instructions. Keep the self-attested copies of the following documents handy and originals as a mandate for verification.</li>
                  <ul className="innerAccord">
                    <li>Proof of Identity</li>
                    <li>Proof of Address</li>
                    <li>PAN Card</li>
                    <li>Photograph</li>
                  </ul>
                  <li> Submit the dully filled form to either AMC with which you are going to invest.</li>
                  <li> There are no fees for KYC registration.</li>
                </ol>
              </div>
            </FaqAccordianContent>
          </div>
          <div className="accordian-item">
            <div
              className={`${style["accordian-header"]}`}
              onClick={() => updateAccordionIndex(10)}
              style={isActive(10) ? { color: "#042b62" } : { color: "black" }}
            >
              <span>Is transaction confirmation in Mutual Fund via FintooInvest instant?</span>{" "}
              {isActive(10) ? <BsArrowRight /> : <AiOutlinePlus />}{" "}
            </div>
            <FaqAccordianContent activeIndex={isActive(10)}>
              <div>
                <p>
                  Yes! investors do not have to wait for the results. They can see all the transactions of their respective investments on a real-time basis.</p>
              </div>
            </FaqAccordianContent>
          </div>
          <div className="accordian-item">
            <div
              className={`${style["accordian-header"]}`}
              onClick={() => updateAccordionIndex(11)}
              style={isActive(11) ? { color: "#042b62" } : { color: "black" }}
            >
              <span>How can I track my portfolio/investments?</span>{" "}
              {isActive(11) ? <BsArrowRight /> : <AiOutlinePlus />}{" "}
            </div>
            <FaqAccordianContent activeIndex={isActive(11)}>
              <div>
                <p>
                Investors can track their investments on FintooInvest easily as it organizes portfolio based on asset and scheme allocation in one place.
                        </p>
              </div>
            </FaqAccordianContent>
          </div>
        </div>
      </div>
    </>
  );
}

export default FaqAccordian;
