import React, {useState} from "react";
import FaqSection from "../components/HTML/RetirementPlanning/FaqSection";
import FinancialReport from "../components/HTML/RetirementPlanning/FinancialReport";
import HeaderSection from "../components/HTML/RetirementPlanning/HeaderSection";
import HowWeWorkSection from "../components/HTML/RetirementPlanning/StepsFinancialPlan";
import YoutubevideoSection from "../components/HTML/RetirementPlanning/YoutubevideoSection";
import SecuritySection from "../components/HTML/SecuritySection";
import Fullpage from "../components/Layout/Fullpage";
import WealthContactForm from "../components/WealthManagementContactForm";
import backImg from "../components/Layout/Fullpage/assets/advisor-min.jpg";
import { servicesTypes } from "../components/WealthManagementContactForm/model";
import AppointmentBox from "../components/Pages/Calendly";
const RetirementPlanning = () => {
  const [show, SetShow] = useState(false)
  return (
    <Fullpage>
      <HeaderSection />
      <HowWeWorkSection />
      <FinancialReport />
      <YoutubevideoSection />
      <SecuritySection />
      <section style={{
        
      }} id="GetStarted">
       <AppointmentBox eventCode={'Callback_mintyApp_10'} serviceName="Retirement Planning"  eventUrl={"https://calendly.com/fintoo/15-minutes-consultation-call-retirement-planning?hide_event_type_details=1"} />
        {/* <WealthContactForm imgSrc={backImg} serviceType={servicesTypes.retirementPlanning} onSuccess={() => SetShow(true)}/> */}
      </section>
        <FaqSection />
    </Fullpage>
  );
};
export default RetirementPlanning;
