import { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import pmc from "../../../components/Layout/Commomdashboard/commonDashboard.module.css";
import {
  FaUserAlt,
  FaLongArrowAltUp,
  FaCalendarAlt,
  FaChevronRight,
  FaPrint,
  FaDownload,
} from "react-icons/fa";
import { TbUpload } from "react-icons/tb";
import { MdOutlineEmail } from "react-icons/md";
import { BsFillPrinterFill } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import iciciLogo from "../../../Assets/Images/01_icici.png";
import { ReactComponent as PiggybankIcon } from "../../../Assets/Images/piggybank.svg";
import { ReactComponent as DownloadIcon } from "../../../Assets/Images/download-reports.svg";
import { ReactComponent as DownArrow } from "../../../Assets/Images/down-arr-98.svg";
import ICICI from "../../../Assets/Images/CommonDashboard/ICICIBank.png";
import HDFC from "../../../Assets/Images/CommonDashboard/hdfc.png";
import TATA from "../../../Assets/Images/CommonDashboard/tata-icon.png";
import Bag from "../../../Assets/Images/CommonDashboard/suitcase.png";
import Trade from "../../../Assets/Images/CommonDashboard/Trade.svg";

import Blog1 from "../../../Assets/Images/CommonDashboard/01_blog.png";
import Blog2 from "../../../Assets/Images/CommonDashboard/02_blog.png";
import { Link, useNavigate } from "react-router-dom";
import CommonDashboardLayout from "../../../components/Layout/Commomdashboard";
import Carousel from "react-elastic-carousel";
import Item from "./Item";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import CardBox from "../../../components/CommonDashboard/CardBox";
import DashboardSlider from "../../../components/CommonDashboard/DashboardSlider";
import NewsBox from "../../../components/CommonDashboard/NewsBox";
import Profile from "../../../components/CommonDashboard/Profile";
import TopPicks from "../../../components/TopPicks";
import Style from "./style.module.css";
import "./style.css";
import InvestmentCategories from "../../../components/InvestmentCategories";
import AmcSlider from "../../../components/AmcSlider";
import PortfolioValue from "../../../components/PortfolioValue";
import *  as constClass from "../../../constants";
import {
  apiCall,
  getUserId,
  loginRedirectGuest,
} from "../../../common_utilities";
import ProfilePercentage from "../../../components/ProfilePercentage";
import NiftySensex from "../../../components/CommonDashboard/NiftySensex";
import MakeYourTaxPlan from "../../../components/MakeYourTaxPlan";
import BlogBoxSection from "./BlogBoxSection";
import MaxGainerLooser from "../../../components/MaxGainerLooser";
import { useDispatch, useSelector } from "react-redux";
import { fetchEncryptData } from "../../../common_utilities";
import FintooLoader from "../../../components/FintooLoader";

const progressBarValues = [
  {
    title: "Equity",
    value: 20,
    color: "#fd9745",
  },
  {
    title: "Debt",
    value: 10,
    color: "#fe5a01",
  },
  {
    title: "Real Estate",
    value: 14,
    color: "#e3e3e3",
  },
  {
    title: "Liquid",
    value: 18,
    color: "#3598db",
  },
  {
    title: "Alternate",
    value: 10,
    color: "#16a086",
  },
  {
    title: "Gold",
    value: 24,
    color: "#2dcc70",
  },
  {
    title: "Other",
    value: 4,
    color: "#ffcc00",
  },
];
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 4 },
  { width: 1200, itemsToShow: 4 },
];

const SidebarLinks = ({ title, description, link }) => {
  return (
    <>
      {link != undefined ? (
        <a href={`${process.env.PUBLIC_URL}/${link}`} style={{textDecoration: "none"}}>
          <div className={`${Style.linkbox} d-flex p-4 my-4`}>
            <div className={Style.AddImg}>
              <img src={Bag} />
            </div>
            <div className={Style.newsData}>
              <div className={Style.Newstitle}>{title}</div>
              <span className={Style.NewsBottomLine}>{description}</span>
            </div>
          </div>
        </a>
      ) : 
        <div className={`${Style.linkbox} d-flex p-4 my-4`}>
          <div className={Style.AddImg}>
            <img src={Bag} />
          </div>
          <div className={Style.newsData}>
            <div className={Style.Newstitle}>{title}</div>
            <span className={Style.NewsBottomLine}>{description}</span>
          </div>
        </div>
      }
    </>
  );
};

const Investment = (props) => {

  const rdxSelectedTab = useSelector((state)=> state.investDashboardTabActiveTab);
  const dispatch = useDispatch();
  const [returnsType, setReturnsType] = useState("xirr");
  const [selectedTab, setSelectedTab] = useState(1);
  const [dashboardData, setDashboardData] = useState({});
  const [amcData, setAmcData] = useState({});
  const [topPicks, setTopPicks] = useState({});
  const [pan, setPan] = useState("");
  const [mainData, setMainData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [gainerData, setGainerData] = useState([]);
  const [loserData, setLoserData] = useState([]);
  const [hasPf, setHasPf] = useState("");
  const navigate = useNavigate();

  const getDashboardData = async () => {
    setIsLoading(true);
    let url = constClass.DMF_GET_DASHBOARD_DATA;
    let data = {};
    var res = await apiCall(url, data);
    setIsLoading(false);
    if (res.error_code == "100") {
      setAmcData(res.data.top_amc_data);
      setTopPicks(res.data.top_fund_data);
    }
  };
  
  const fetchFundsData = async () => {
    try {
      setIsLoading(true);
      var payload = {
        url: constClass.DMF_GETUSERDETAILS_API_URL,
        method: "post",
        data: { user_id: '' + getUserId(), is_direct: '' + IS_DIRECT },
      };

      var res = await fetchEncryptData(payload);
      if (Boolean(res.data.pan) == false) {
        throw "PAN not found";
      }
      
      var payload = {
        url: constClass.GET_PORTFOLIO_SUMMARY_URL,
        data: {
          pan: res.data.pan,
          is_direct: "1",
        },
        method: "post",
      };
      var res = await fetchEncryptData(payload);
      if (res.error_code == "100") {
        setMainData(res.data);
        // GraphData(res.data);
        gainerLoserData(res.data.fund_details);
        setHasPf(true);
      } else if (res.error_code == "103") {
        setHasPf(false);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const gainerLoserData = (val) => {
    var myarray = val;
    var numDescending = myarray.sort((a, b) => b.gain_loss - a.gain_loss);
    var data = { ...val, numDescending };
    var numAscending = myarray.sort((a,b) => a.gain_loss - b.gain_loss);
    var data2 = { ...val, numAscending};
    let myObjectGainer = data;
    if(Object.keys(myObjectGainer).length < 6){
      delete myObjectGainer.numDescending;
    }
    const gainerValues = Object.values(myObjectGainer).slice(0, 6);
    let myObjectLoser = data2;
    if(Object.keys(myObjectLoser).length < 6){
      delete myObjectLoser.numAscending;
    }
    const loserValues = Object.values(myObjectLoser).slice(0,6);
    setGainerData(gainerValues);
    setLoserData(loserValues);
  };

  useEffect(() => {
    if (getUserId()) {
      getDashboardData();
      fetchFundsData();
    } else {
      loginRedirectGuest();
    }
  }, []);

  // useEffect(()=>{
  //   fetchFundsData();
  // }, [!isLoading])

  useEffect(()=> {
    if(selectedTab == 1) {
      dispatch({
        type: "INVEST_DASHBOARD_CHANGE_TAB",
        payload: 'mf',
      });
    } else {
      dispatch({
        type: "INVEST_DASHBOARD_CHANGE_TAB",
        payload: 'stocks',
      });
    }
  }, [selectedTab]);

  return (
    <>
      <FintooLoader isLoading={isLoading} />
        {!isLoading  && ( 
          <CommonDashboardLayout>
          <div className="px-0 px-md-4">
            {/* <p style={{ height: "2rem" }}></p> */}
            <div className="row ">
              <div className="col-12">
                <div className={`row `}>
                  <div className="col-12 col-md-5">
                    <div className="pe-md-2">
                      <div className="py-4">
                        <ProfilePercentage />
                      </div>
                      {hasPf && (
                        <div className="py-4">
                          <PortfolioValue data={mainData} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className="ps-2">
                      {/* <NiftySensex /> */}
                      {hasPf && (
                        <MaxGainerLooser gainerData={gainerData} loserData={loserData}/>
                      )}
                    </div>
                  </div>
                </div>
              </div>
    
              <div className="col-12 col-md-8">
                <div className={Style.insideTabBoxd}>
                  <div className="d-flex pt-3">
                    <div
                      onClick={() => setSelectedTab(1)}
                      className={`pointer ${Style.tabBx} ${
                        selectedTab == 1 ? Style.active : ""
                      }`}
                    >
                      <div
                        className={`mb-0 ${Style.tabText} d-flex align-items-center`}
                      >
                        <div className="pe-1">
                          <img src={require("./Images/mficon83.png")} width={25} />
                        </div>
                        <div>Mutual Funds</div>
                      </div>
                    </div>
                    <div
                      onClick={() => setSelectedTab(2)}
                      className={`pointer ${Style.tabBx} ${
                        selectedTab == 2 ? Style.active : ""
                      }`}
                    >
                      <div
                        className={`mb-0 ${Style.tabText} d-flex align-items-center`}
                      >
                        <div className="pe-1">
                          <img src={require("./Images/stocks63.png")} width={25} />
                        </div>
                        <div>Stocks</div>
                      </div>
                    </div>
                  </div>
                </div>
                {Boolean(topPicks.length) && (
                  <div className={Style.MFData}>
                  <div className="d-flex justify-content-between">
                    <div className={Style.Datatext}>
                      <p>Top Picks</p>
                    </div>
                    <div>
                      <button onClick={() => {
                          navigate(
                            process.env.PUBLIC_URL + "/mutual-fund/funds/recommended"
                          );
                        }}
                      className={Style.button}>Explore All</button>
                    </div>
                  </div>
                  <TopPicks data={topPicks}/>
                </div>
                )}
                <div className={Style.MFData}>
                  <div
                    className="d-flex justify-content-between"
                    style={{
                      borderBottom: "1px solid #eeee",
                    }}
                  >
                    <div className={Style.Datatext}>
                      <p>Category</p>
                    </div>
                    <div>
                      <button onClick={() => {
                          navigate(
                            process.env.PUBLIC_URL + "/mutual-fund/funds/all"
                          );
                        }}
                      className={Style.button}>Explore All</button>
                    </div>
                  </div>
                  <InvestmentCategories />
                </div>
    
                <div className={Style.MFData}>
                  <div
                    className="d-flex justify-content-between"
                    style={{
                      borderBottom: "1px solid #eeee",
                    }}
                  >
                    <div className={Style.Datatext}>
                      <p>AMC</p>
                    </div>
                    <div>
                      <button
                        className={Style.button}
                        onClick={() => {
                          navigate(
                            process.env.PUBLIC_URL + "/mutual-fund/funds/all"
                          );
                        }}
                      >
                        Explore All
                      </button>
                    </div>
                  </div>
                  <AmcSlider data={amcData} />
                </div>
                <div className="py-3 px-3 px-md-0">
                  <MakeYourTaxPlan />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <SidebarLinks
                  title={"Fintooinvest model portfolios"}
                  // description={"Lorem ipsum sit amet, consectetur."}
                />
                {/* <SidebarLinks
                  title={"Track all your investments"}
                  description={"Lorem ipsum sit amet, consectetur."}
                  link = {"mutual-fund/portfolio/link-your-holdings"}
                /> */}
    
                <div className="py-3">
                  <BlogBoxSection />
                </div>
              </div>
            </div>
          </div>
        </CommonDashboardLayout>
        )}
    </>
  );
};

export default Investment;
