import styles from "./style.module.css";
function TaxPlanningHeaderSection() {
  return (
    <>
      <section className={`${styles["header-section"]}`}>
        <div className={`${styles["header-section-background-overlay"]}`}></div>
        <div className={`${styles["header-section-container"]}`}>
          <div className={`${styles["header-section-content"]}`}>
            <h2 className={`${styles["header-section-title"]}`}>
              Minimise Your Taxes, <br />
              Maximise Your Savings.
            </h2>
            <br />
            <a href="#GetStarted" className="text-decoration-none">
              <button
                className={`${styles["header-section-content-btn"]} ${styles["animatedBouncInUp"]} ${styles["bounceInUp"]}`}
              >
                GET STARTED
              </button>
            </a>
          </div>
          <div className={`${styles["header-section-image"]}`}>
            <div
              className={`${styles["header-section-image-container"]} ${styles["animated"]} ${styles["animatedFadeInUp"]} ${styles["fadeInUp"]}  `}
            >
                <img src={process.env.REACT_APP_STATIC_URL + 'media/wp/TaxPlan/taxPlanningSectionImg.png'} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TaxPlanningHeaderSection;
