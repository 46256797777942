import { useState, useEffect, useRef } from "react";
import "react-responsive-modal/styles.css";
import { Link, useNavigate } from "react-router-dom";
import Profile_1 from "../../../Assets/06_banking_app.svg";
import BankConfirm from "../../../Assets/ReturnonEquity.png";
import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Container, Row, Col } from "react-bootstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import "../Fatca/style.css";
import Back from "../../../Assets/left-arrow.png";
import { Modal, Button } from "react-bootstrap";
import BankChequeUpload from "../Aadhar/BankChequeUpload";
import Information from "../../../Assets/information.png";
import FintooProfileBack from "../../../HTML/FintooProfileBack";
import FintooButton from "../../../HTML/FintooButton";
import UploadIcon from "../../../../Assets/Images/file-upload.png";
import "react-image-crop/dist/ReactCrop.css";
import axios from "axios";
import {
  DMF_BASE_URL,
  DMF_UPLOADCANCELCHECK_API_URL,
  DMF_DELETEBANK_API_URL,
  DMF_GETBANKDETAILS_API_URL,
  DOCS_READ_FILE,
  DMF_GETUSERBANKS_API_URL,
  IS_DIRECT,
} from "../../../../constants";
import commonEncode from "../../../../commonEncode";
import { ToastContainer, toast } from "react-toastify";
import {
  CheckSession,
  fetchData,
  fetchEncryptData,
  getUserId,
  memberId,
} from "../../../../common_utilities";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { canvasPreview } from "../../../FintooCrop/canvasPreview";

import { MdDelete } from "react-icons/md";

import ReactCrop from "../../../HTML/ReactCrop";

const UploadInput = styled("input")({
  display: "none",
});
const aspect = 16 / 9;
const scale = 1;

function BankCheque(props) {
  const user_id = getUserId();
  // const bank_id = localStorage.getItem("DGBankId");
  const [bankId, setBankId] = useState("");

  const [upload, uploadImage] = useState([]);
  const imgRef = useRef(null);
  const inputFileUpload = useRef(null);
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();
  const [crop, setCrop] = useState({
    unit: "%", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });
  const [imgSrc, setImgSrc] = useState("");
  const [completedCrop, setCompletedCrop] = useState(null);
  const [preview, setPreview] = useState(false);
  const previewCanvasRef = useRef(null);
  const [rotateValue, setRotateValue] = useState(0);
  const showBack = useSelector((state) => state.isBackVisible);
  const [chequeName, setChequeName] = useState("");

  const chequeNameRef = useRef();
  const [oldPhoto, setOldPhoto] = useState({});

  useEffect(() => {
    // CheckSession();
    getbankdetails();
    // loadDoc();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    if (completedCrop != null) {
      canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
    }
  }, [completedCrop]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };
  const [showModal, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const navigate = () => {
    navigate("/ConfirmBank");
  };

  const loadDoc = async () => {
    // await getbankdetails();
    var payload = {
      data: {
        file_name: chequeNameRef.current,
        user_id: getUserId(),
      },
      url: DOCS_READ_FILE,
      method: "post",
    };

    var response = await fetchData(payload);
    setOldPhoto({ ...response.data });
  };

  const getbankdetails = async () => {
    var config = {
      method: "post",
      url: DMF_GETUSERBANKS_API_URL,
      data: { user_id, is_direct: IS_DIRECT },
    };

    let res = await fetchEncryptData(config);
    console.log('res98', res, res.data[0].bank_id);

    setBankId('' + res.data[0].bank_id);
    var data = { user_id: user_id, bank_id: '' + res.data[0].bank_id, is_direct: IS_DIRECT };
    try {
      // var data = commonEncode.encrypt(JSON.stringify(data));
      let config = {
        method: "post",
        url: DMF_GETBANKDETAILS_API_URL,
        data: data,
      };
      let res = await fetchEncryptData(config);
      console.log('res98-89-', res);
      // var response = commonEncode.decrypt(res.data);
      // let x = JSON.parse(response);
      chequeNameRef.current = res.data[0].bank_cancel_cheque;
      loadDoc();
    } catch (e) {
      console.log("CATCH -- ", e);
    }
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (
        e.target.files[0].type == "image/jpeg" ||
        e.target.files[0].type == "image/png"
      ) {
        if (e.target.files[0].size <= 2097152) {
          setCrop(undefined);
          const reader = new FileReader();
          reader.addEventListener("load", () =>
            setImgSrc(reader.result.toString() || "")
          );
          reader.readAsDataURL(e.target.files[0]);
        } else {
          dispatch({
            type: "RENDER_TOAST",
            payload: {
              message: "Files up to 2MB accepted.",
              type: "error",
              autoClose: 3000,
            },
          });
          onRemoveUploaded();
        }
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "Invalid file extension being uploaded.",
            type: "error",
            autoClose: 3000,
          },
        });
        onRemoveUploaded();
      }
    }
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          // You don't need to pass a complete crop into
          // makeAspectCrop or centerCrop.
          unit: "%",
          width: 90,
        },
        aspect,
        width,
        height
      ),
      width,
      height
    );

    setCrop(crop);
  };

  const deleteBank = async () => {
    let data = {};

    data["bank_user_id"] = user_id;
    data["bank_id"] = bank_id;

    let post_data = commonEncode.encrypt(JSON.stringify(data));

    var res = await axios({
      url: DMF_DELETEBANK_API_URL,
      method: "POST",
      data: post_data,
    });
    let response = commonEncode.decrypt(res.data);

    let responseobj = JSON.parse(response);

    let error_code = responseobj.error_code;
    if (error_code == "100") {
      localStorage.removeItem("bankDetails");
      localStorage.removeItem("sendData");
      props.onPrevious("Bank");
    }
  };

  const convertBase = async (blob) => {
    let file = new File([blob], "fileName.jpg", { type: "image/jpeg" });

    let formdata = new FormData();
    formdata.append("cheque_file", file);
    formdata.append("bank_user_id", commonEncode.encrypt(user_id));
    formdata.append("bank_id", commonEncode.encrypt(bankId));
    var res = await axios({
      url: DMF_UPLOADCANCELCHECK_API_URL,
      method: "POST",
      data: formdata,
    });
    let response = commonEncode.decrypt(res.data);

    let responseobj = JSON.parse(response);

    let error_code = responseobj.error_code;
    if (error_code == "102") {
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: responseobj.message,
          type: "error",
          autoClose: 3500,
        },
      });

      // onRemoveUploaded();
    } else if (error_code == "100") {
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: responseobj.message,
          type: "success",
          autoClose: 3500,
        },
      });
      props.onNext();
    }
  };

  const convertBlob = () => {
    fetch(imgSrc)
      .then((res) => res.blob())
      .then(async (blob) => {
        convertBase(blob);
      });
  };

  const onRemoveUploaded = () => {
    setImgSrc("");
    setPreview(false);
  };

  return (
    <>
      <ToastContainer limit={1} />
      <Row className="reverse">
        <div className="ProfileImg col-12 col-md-6 ">
          <div>
            <img src={Profile_1} alt="" />
          </div>
        </div>
        <div className=" RightPanel col-12 col-md-6">
          <div className="rhl-inner">
            {showBack == true && (
              <FintooProfileBack
                title="Upload Bank Details"
                onClick={() => {
                  props.onPrevious("Bank");
                }}
              />
            )}

            <p className="">
              Upload a clear photograph or a scanned copy of your cheque leaf in
              JPEG, JPG or PNG format.
            </p>
            <div className="VerifyDetails">
              <label className="LabelName form-label">Upload Cheque Leaf</label>

              <div className="">
                <Col className=" ">
                  <div
                    style={{ display: "file_url" in oldPhoto ? "" : "none" }}
                  >
                    <div className="whitebg-rounded img-preview-n fileContainer">
                      <span
                        onClick={() => {
                          setOldPhoto({});
                        }}
                      >
                        <MdDelete />
                      </span>
                      <img
                        src={oldPhoto.file_url}
                        style={{
                          objectFit: "contain",
                          maxHeight: "270px",
                          maxWidth: "90%",
                        }}
                      />
                    </div>
                    <div className="pt-4">
                      <FintooButton
                        className={`d-block me-0 ms-auto`}
                        onClick={() => props.onNext()}
                        title={"Next"}
                      />
                    </div>
                  </div>

                  <div
                    style={{ display: "file_url" in oldPhoto ? "none" : "" }}
                  >
                    <div
                      style={{
                        display: imgSrc.trim() == "" ? "block" : "none",
                      }}
                    >
                      <UploadInput
                        onChange={(e) => onSelectFile(e)}
                        accept="image/*"
                        id="inputFileUpload"
                        ref={inputFileUpload}
                        type="file"
                        name="fileUploadHere"
                      />
                      <div className="dlc-bx-upload px-0 px-md-5">
                        <div className="dlc-bx">
                          <div className="pnf-img-bx" role="button">
                            <label htmlFor="inputFileUpload" role="button">
                              <img
                              src={
                          process.env
                            .REACT_APP_STATIC_URL +
                          "media/DMF/file-upload.svg"
                        }
                              />
                              <p>Upload</p>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`${
                        Boolean(preview) == false && imgSrc.trim() != ""
                          ? ""
                          : "fakeHide"
                      }`}
                    >
                      <div className="new-bg-white">
                        <ReactCrop
                          onDelete={() => {
                            setImgSrc("");
                            inputFileUpload.current.value = "";
                          }}
                          image={imgSrc.trim()}
                          onCrop={(v) => {
                            setImgSrc(v);
                            setPreview(true);
                            // convertBlob();
                          }}
                        />
                      </div>
                    </div>

                    <div
                      style={{ display: Boolean(preview) ? "block" : "none" }}
                    >
                      <div className="fileContainer d-flex justify-content-center align-items-center img-preview-n">
                        <span onClick={() => onRemoveUploaded()}>
                          <MdDelete />
                        </span>
                        <img
                          src={imgSrc}
                          style={{
                            // border: '1px solid black',
                            objectFit: "contain",
                            maxHeight: "270px",
                            maxWidth: "90%",
                          }}
                        />
                      </div>
                      <div>
                        {/* abcd
                      efgg */}
                        <FintooButton
                          className={`d-block me-0 ms-auto`}
                          onClick={() => {
                            convertBlob();
                            // props.onNext();
                          }}
                          title={"Next"}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
            <br />
          </div>
        </div>
      </Row>
    </>
  );
}

export default BankCheque;
