import React, { useEffect, useRef, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import ReactDatePicker from "../../../components/HTML/ReactDatePicker/ReactDatePicker";
import FintooRadio2 from "../../../components/FintooRadio2";
import Switch from "react-switch";
import moment from "moment";
import DgRoundedButton from "../../../components/HTML/DgRoundedButton";
import DgDragDrop from "../../../components/HTML/DgDragDrop";
import SimpleReactValidator from "simple-react-validator";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft, FaArrowRight, FaHubspot } from "react-icons/fa";
import DgDragDrop2 from "../../../components/HTML/DgDragDrop/DgDragDrop2";
import { Modal } from "react-bootstrap";
import LinkYourHoldingsDG from "./LinkYourHoldingsDG";
import * as toastr from "toastr";
import "toastr/build/toastr.css";
import { useDispatch } from "react-redux";
import FintooLoader from "../../../components/FintooLoader";
import GoalsDropdown from "../../../components/GoalsDropdown/GoalDropdown";
import Styles from "../AssetsLibDG/NSDL_CSDL/style.module.css";
import CustomStyles from "../../../components/CustomStyles.js";
import {
  ADVISORY_ADD_ASSETS_API,
  ADVISORY_ADD_BANK_DATA,
  ADVISORY_DELETE_BANK_DATA,
  ADVISORY_GET_BANK_DATA,
  ADVISORY_UPDATE_ASSETS_API,
  ADVISORY_UPDATE_RETIREMENT_DATE_API_URL,
  CHECK_SESSION,
} from "../../../constants";
import commonEncode from "../../../commonEncode";
import {
  apiCall,
  createCookie,
  fetchData,
  fetchEncryptData,
  getItemLocal,
  getParentUserId,
  getUserId,
  restApiCall,
} from "../../../common_utilities";
import * as constClass from "../../../constants";
import Bankbalance from "../BankCashbalance/Bankbalance.module.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Bankselect from "../BankCashbalance/Bankselect";
import socket, { onMessageHandler } from "../BankCashbalance/socket";
import BankAccountSelection from "../BankCashbalance/BankAccountSelection";
import { Buffer } from "buffer";
import Uniquepannotfoundmodal from "./Uniquepannotfoundmodal";

// const socket = new WebSocket("wss://webvwlive.finvu.in/consentapi");
const options = [
  { value: "Self", label: " Andrew Doe (Self)" },
  { value: " Alex_Doe", label: " Alex Doe" },
  { value: " Samanta_Doe", label: "Samanta Doe" },
  { value: "  Grey_Doe", label: "Grey Doe" },
];

const Liquid = (props) => {
  const [selectedButton, setSelectedButton] = useState(" ");
  const [selectedExtraOption, setSelectedExtraOption] = useState(" ");
  const setAssetsDetails = props.setAssetsDetails;
  const [show, setShow] = useState(false);
  const [stepCount, setStepCount] = useState(0);
  const [mobileOtp, setMobileOtp] = useState("");
  // const [seconds, setSeconds] = useState(60);
  const [isActive, setIsActive] = useState(true);
  const [pannumbers, setPanNumbers] = useState([]);
  const [familyecas, setFamilyEcas] = useState([]);
  const [memberdataid, setMemberDataId] = useState({})
  const [showuniqueUANModal, setShowuniqueUANModal] = useState(false);
  const assetsDetails = props.assetsDetails;
  const familyData = props.familyData;
  const goalData = props.goalData;
  const setDate = props.setDate;
  const schemedata = props.schemedata;
  const addForm = props.addForm;
  const updateForm = props.updateForm;
  const addAssetsSubmit = props.addAssetsSubmit;
  const cancelAssetForm = props.cancelAssetForm;
  const updateAssetsSubmit = props.updateAssetsSubmit;
  const assetForMember = props.assetForMember;
  const liquidfunds = props.liquidfunds;
  const session = props.session;
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [, setForceUpdate] = useState(0);
  const dispatch = useDispatch();
  const [retirementDate, setRetirementDate] = useState("");
  const [lifeExpectancyDate, setLifeExpectancyDate] = useState("");
  const [openModalByName, setOpenModalByName] = useState("");
  const handleShow = () => setShow(true);
  const setGoalSelected = props.setGoalSelected;
  const closeModal = props.closeModal;
  const selectGoals = props.selectGoals;
  const selectedGoals = props.selectedGoals;
  const selectedGoalIdArray = props.selectedGoalIdArray;
  const selectedGoalsId = props.selectedGoalsId;
  const setPriorityArray = props.setPriorityArray;
  const selectedPriorityArray = props.selectedPriorityArray;
  const setAutoMatedGoal = props.setAutoMatedGoal;
  const isAutoMatedGoal = props.isAutoMatedGoal;
  const setGoalLink = props.setGoalLink;
  const isGoalSelected = props.isGoalSelected;
  const setSelectedGoals = props.setSelectedGoals;
  const setSelectedGoalsId = props.setSelectedGoalsId;
  const setSelectedPriorityArray = props.setSelectedPriorityArray;
  const unchangedgoaldata = props.unchangedgoaldata;
  const assetEditId = props.assetEditId;
  const [mobileNumber, setMobileNumber] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [termsError, setTermsError] = useState("");
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [userId, setUserId] = useState("");
  const [assetId, setAssetId] = useState("");
  const liquidfundsData = liquidfunds.map((index, value) => {
    return {
      label: index.fund_name,
      value: index.current_nav,
    };
  });

  const handleLiquidFundSelection = (selectedOption) => {
    setAssetsDetails({
      ...assetsDetails,
      asset_name: selectedOption.label, // Set the asset_name using the selected fund name
      asset_current_unit_price: parseFloat(selectedOption.value).toFixed(2),
    });
  };

  const handleLiquidSubmit = async (e) => {
    e.preventDefault();

    var isFormValid = simpleValidator.current.allValid();
    simpleValidator.current.showMessages();
    setForceUpdate((v) => ++v);

    if (isFormValid) {
      // setIsLoading(true);
      addAssetsSubmit(e);
      simpleValidator.current.hideMessages();
      setForceUpdate((v) => ++v);
    }
  };

  const handleLiquidUpdate = async (e) => {
    e.preventDefault();
    var isFormValid = simpleValidator.current.allValid();
    simpleValidator.current.showMessages();
    setForceUpdate((v) => ++v);

    if (isFormValid) {
      // setIsLoading(true);
      updateAssetsSubmit(e);
      simpleValidator.current.hideMessages();
      setForceUpdate((v) => ++v);
      setGoalSelected(false);
      setSelectedGoalsId(false);
      setSelectedPriorityArray([]);
      setAutoMatedGoal(true);
      setSelectedGoals("Automated Linkage");
    }
  };

  const handleLiquidCancel = async (e) => {
    e.preventDefault();
    cancelAssetForm(e);
    simpleValidator.current.hideMessages();
    setForceUpdate((v) => ++v);
  };

  useEffect(() => {
    simpleValidator.current.hideMessages();
    simpleValidator.current.purgeFields();

    if (assetEditId) {
      if (selectedGoals == "Automated Linkage") {
        setAutoMatedGoal(true);
        setSelectedGoals("Automated Linkage");
      } else {
        setAutoMatedGoal(false);
      }
    } else {
      setAutoMatedGoal(true);
      setSelectedGoals("Automated Linkage");
    }
    if (session && !assetEditId) {
      setSelectedGoalsId(false);
      setSelectedPriorityArray([]);
      setGoalSelected(false);
      props.getfpgoalsdata(session.data.fp_log_id);
    }
    setForceUpdate((v) => ++v);
  }, [
    assetsDetails?.asset_sub_category_id,
    assetsDetails?.asset_isrecurring,
    selectedButton,
  ]);

  // Upload Document

  const [docPassword, setDocPassword] = useState("");
  const [dropFiles, setdropFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const ecashUploadDocument = async () => {
    try {
      var form = new FormData();
      form.append("ecash_type", "CAMS");
      form.append("ecash_passkey", docPassword);
      form.append("fp_user_id", parseInt(session["data"]["fp_user_id"]));
      form.append("fp_log_id", parseInt(session["data"]["fp_log_id"]));
      form.append("doc_user_id", parseInt(session["data"]["id"]));
      form.append("is_direct", 0);


      for (let i = 0; i < dropFiles.length; i++) {
        form.append(`file[${i}]`, dropFiles[i], dropFiles[i].name);
      }
      setIsLoading(true);
      var ecash_upload = await apiCall(
        ADVISORY_UPLOAD_ECASH_DOCUMENT,
        form,
        false,
        false
      );
      if (ecash_upload["error_code"] === "100") {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.success("Document uploaded successfully");
        getUnassignedAsset();
        // setIsLoading(false);
        setdropFiles([]);
        setDocPassword("");
        setForceUpdate((v) => ++v);
        dispatch({ type: "RESET_DRAGZONE", payload: true });
        setIsLoading(false);
      } else if (ecash_upload["error_code"] == "102") {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error(ecash_upload["data"]);
        setdropFiles([]);
        setDocPassword("");
        setForceUpdate((v) => ++v);
        dispatch({ type: "RESET_DRAGZONE", payload: true });
        setIsLoading(false);
      } else {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Something went wrong");
        // var retire_data = {
        //   fp_log_id: session["data"]["fp_log_id"],
        //   fp_user_id: session["data"]["id"],
        // };
        // var payload_retire_data = commonEncode.encrypt(
        //   JSON.stringify(retire_data)
        // );
        // var config_ret = await apiCall(
        //   ADVISORY_UPDATE_RETIREMENT_DATE_API_URL,
        //   payload_retire_data
        // );
        // var res_ret = JSON.parse(commonEncode.decrypt(config_ret));
        // if (res_ret.error_code == "100") {
        //   var retirement_date = moment(res_ret["data"][0]["dob"]).add(
        //     res_ret["data"][0]["retirement_age"],
        //     "y"
        //   );
        //   var life_expectancy_date = moment(res_ret["data"][0]["dob"]).add(
        //     res_ret["data"][0]["life_expectancy"],
        //     "y"
        //   );
        //   setRetirementDate(retirement_date);
        //   setLifeExpectancyDate(life_expectancy_date);
        // }
        var retirement_date = moment(session["data"]["user_details"]['dob'])
          .add(session["data"]["user_details"]['retirement_age'], "y")
          .format("MM/DD/YYYY");
        var life_expectancy_date = moment(session["data"]["user_details"]['dob'])
          .add(session["data"]["user_details"]['life_expectancy'], "y")
          .format("MM/DD/YYYY");

        setRetirementDate(retirement_date);
        setLifeExpectancyDate(life_expectancy_date);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const getUnassignedAsset = async () => {
    try {
      var payload = { "user_id": session["data"]["id"], "fp_log_id": session["data"]["fp_log_id"], "fp_user_id": session["data"]["fp_user_id"], "is_direct": 0 }
      var unassigned_asset = await apiCall(
        constClass.ADVISORY_GET_UNASSIGNED_ASSET,
        payload,
        false,
        false
      );
      if (unassigned_asset["error_code"] == "100") {
        setPanNumbers(unassigned_asset.data)
        if (unassigned_asset.data != 'false') {
          setShowuniqueUANModal(true)
        }
        var response_pan = unassigned_asset.data
        var temp_member_id = 0
        var familyDetails = await apiCall(
          constClass.BASE_API_URL + "restapi/getfpfamilydata/" +
          "?parent_user_id=" +
          Buffer.from(commonEncode.encrypt((session["data"]["id"]).toString())).toString("base64") + '&fp_log_id=' + Buffer.from(commonEncode.encrypt((session["data"]["fp_log_id"]).toString())).toString("base64") +
          "&web=1&is_direct=0",
        )
        if (familyDetails.data != undefined) {
          setFamilyEcas(familyDetails.data)

          let url = constClass.ADVISORY_GET_FP_USER_DATA + '?user_id=' + btoa(commonEncode.encrypt((session["data"]["id"]).toString())) + '&fp_log_id=' + btoa(commonEncode.encrypt((session["data"]["fp_log_id"]).toString())) + '&fp_user_id=' + btoa(commonEncode.encrypt((session["data"]["fp_user_id"]).toString())) + "&web=1&is_direct=0";

          let fpUserData = await apiCall(url, "", true, false);
          if (fpUserData.data.length > 0) {
            if (familyDetails.data.length > 0 && familyDetails.data != undefined) {
              temp_member_id = familyDetails.data[0].id
            }
            else {
              setFamilyEcas([])
            }
          }
          else {
            setFamilyEcas([])
          }
        }
        var item = {}
        for (var pan = 0; pan < unassigned_asset.data.length; pan++) {
          item["familydata_ecas_" + response_pan[pan].asset_pan] = temp_member_id.toString();
        }
        setMemberDataId(item)
      }
    }
    catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    getRetirementData();
    if (assetsDetails.asset_sub_category_id == 61) {
      setSelectedButton("Link Your Bank Statement");
    }
  }, []);

  const getRetirementData = async () => {
    try {
      // var retire_data = {
      //   fp_log_id: session["data"]["fp_log_id"],
      //   fp_user_id: session["data"]["id"],
      // };
      // var payload_retire_data = commonEncode.encrypt(
      //   JSON.stringify(retire_data)
      // );
      // var config_ret = await apiCall(
      //   ADVISORY_UPDATE_RETIREMENT_DATE_API_URL,
      //   payload_retire_data,
      //   false,
      //   false
      // );
      // var res_ret = JSON.parse(commonEncode.decrypt(config_ret));
      // if (res_ret.error_code == "100") {
      //   var retirement_date = moment(res_ret["data"][0]["dob"])
      //     .add(res_ret["data"][0]["retirement_age"], "y")
      //     .format("MM/DD/YYYY");
      //   var life_expectancy_date = moment(res_ret["data"][0]["dob"])
      //     .add(res_ret["data"][0]["life_expectancy"], "y")
      //     .format("MM/DD/YYYY");
      //   setRetirementDate(retirement_date);
      //   setLifeExpectancyDate(life_expectancy_date);
      // }
      var retirement_date = moment(session["data"]["user_details"]['dob'])
        .add(session["data"]["user_details"]['retirement_age'], "y")
        .format("MM/DD/YYYY");
      var life_expectancy_date = moment(session["data"]["user_details"]['dob'])
        .add(session["data"]["user_details"]['life_expectancy'], "y")
        .format("MM/DD/YYYY");

      setRetirementDate(retirement_date);
      setLifeExpectancyDate(life_expectancy_date);
    } catch {
      (e) => { };
    }
  };

  const handleFilesSelected = (files) => {
    const dropFiles = Array.from(files).slice(0, 1);
    setdropFiles(dropFiles);
  };

  const handleUploadSubmit = async (e) => {
    e.preventDefault();
    var isFormValid = simpleValidator.current.allValid();
    simpleValidator.current.showMessages();
    setForceUpdate((v) => ++v);

    if (isFormValid) {
      // setIsLoading(true);
      ecashUploadDocument();
      simpleValidator.current.hideMessages();
      setForceUpdate((v) => ++v);
      dispatch({ type: "RESET_DRAGZONE", payload: true });
    }
  };

  const Input = (props) => {
    const { autoComplete = props.autoComplete } = props.selectProps;
    return <components.Input {...props} autoComplete={autoComplete} />;
  };

  const [bankBalanceData, setBankBalanceData] = useState([]);

  const getbankdata = async () => {
    try {
      const payload = {
        url: ADVISORY_GET_BANK_DATA,
        data: {
          user_id: props.session.data.id,
        },
        method: "post",
      };
      const r = await fetchData(payload);
      if (r.error_code != "404") {
        setBankBalanceData(r);
      }

      localStorage.setItem(
        "getbankdata",
        commonEncode.encrypt(JSON.stringify(r))
      );
    } catch (e) {
      console.log("Error", e);
    }
  };

  const [selectedMember, setSelectedMember] = useState("");
  const [allMembers, setAllMembers] = useState([]);

  // Account Aggregator

  const sessionData = useRef("");
  const navigate = useNavigate();

  useEffect(() => {
    const checksession = async () => {
      let url = CHECK_SESSION;
      let data = { user_id: getUserId(), sky: getItemLocal("rmf"), is_direct: 0 };
      let session_data = await apiCall(url, data, true, false);

      if (session_data["error_code"] == "100") {
        sessionData.current = session_data;
        getmemberdata();
        getbankdata();
      }
    };
    checksession();
  }, []);

  const handleChange = async (e) => {
    try {
      if (Boolean(e) == false) {
        let member = allMembers;
        setSelectedMember({ ...member[0] });
      } else {
        setSelectedMember({ ...e });
        // setErrors({});
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  useEffect(() => {
    handleChange();
  }, [allMembers]);

  useEffect(() => {
    setOpenModalByName("");
    setSelectedButton("");
    setSelectedExtraOption("");
  }, [props.assetEditId]);
  // Member Data for Account Aggregator

  const [memberData, setMemberData] = useState([]);
  const [errors, setErrors] = useState("");
  const [mobileError, setMobileError] = useState("");

  const checksession = async () => {
    let url = CHECK_SESSION;
    let data = { user_id: getParentUserId(), sky: getItemLocal("rmf"), is_direct: 0 };
    return await apiCall(url, data, true, false);
  };

  const getmemberdata = async () => {
    try {
      let sessionData = await checksession();
      let reqdata = {
        fp_user_id: sessionData["data"]["fp_user_id"],
        fp_log_id: sessionData["data"]["fp_log_id"],
        user_id: sessionData["data"]["id"],
        is_direct: 0,
      };
      let checkData = await restApiCall(
        constClass.ADVISORY_FETCH_MEMBER_DATA,
        reqdata,
        ""
      );
      if (checkData.error_code == "100") {
        setMemberData(checkData);
        const all = checkData.data.map((v) => ({
          name: v.first_name + " " + v.last_name,
          id: v.id,
          mobile: v.alternate_mobile,
          label: v.first_name + " " + v.last_name,
          value: v.id,
        }));
        setAllMembers([...all]);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  useEffect(() => {
    const mobileErrors = findMobileErrors();

    if (selectedMember.mobile != "" && selectedMember.mobile != null) {
      if (Object.keys(mobileErrors).length > 0) {
        setErrors((v) => ({ ...v, ...mobileErrors }));
      }
    }
  }, [selectedMember.mobile]);

  const handleCheckboxClick = (e) => {
    setIsTermsChecked(e.target.checked);
    if (!isTermsChecked) {
      setTermsError("");
    } else {
      setTermsError("Please accept the Terms & Conditions.");
    }
  };

  const handleLinkAccountClick = () => {
    let mobileError = "";
    let termsError = "";

    const mobileErrors = findMobileErrors();

    if (mobileErrors.userMobile) {
      mobileError = mobileErrors.userMobile;
    }

    if (!isTermsChecked) {
      termsError = "Please accept the Terms & Conditions.";
    }

    if (mobileError || termsError) {
      setTermsError(termsError);
      setMobileError(mobileError);
    } else {
      setTermsError("");
      setMobileError("");
      localStorage.setItem("userdetails", selectedMember.id);
      localStorage.setItem("username", selectedMember.label);
      commonEncode.encrypt(createCookie("user", selectedMember.mobile, 15));

      navigate(
        `${process.env.PUBLIC_URL}/datagathering/assets-liabilities/bank-list/`
      );
    }
  };

  useEffect(() => {
    setTermsError("");
    setMobileError("");
    setIsTermsChecked(false);
  }, [selectedMember]);

  const findMobileErrors = () => {
    const newErrors = {};
    let regex = /^[789]\d{9}$/;

    if (!selectedMember.mobile || selectedMember.mobile.trim() === "") {
      newErrors.userMobile = "Please enter mobile number!";
    } else if (selectedMember.mobile.length !== 10) {
      newErrors.userMobile = "Please enter a valid 10-digit mobile number!";
    } else if (!regex.test(selectedMember.mobile)) {
      newErrors.userMobile = "Please enter a valid mobile number!";
      // } else if (isMobileNumberLinked(selectedMember.mobile)) {
      //   newErrors.userMobile = "Mobile number is already linked.";
    } else {
      newErrors.userMobile = "";
    }

    return newErrors;
  };

  const isMobileNumberLinked = (mobile) => {
    return bankBalanceData.some((obj) => obj.mobile_number === mobile);
  };

  const handleMobileChange = (e) => {
    const newMobile = e.target.value.slice(0, 10);
    setSelectedMember({ ...selectedMember, mobile: newMobile });

    if (newMobile.length !== 10) {
      setMobileError("Please enter a 10-digit mobile number");
      return;
    }

    const mobileErrors = findMobileErrors();

    setMobileError(mobileErrors.userMobile);

    // if (isMobileNumberLinked(newMobile)) {
    //   setMobileError("Mobile number is already linked.");
    // }
  };

  const handleDelete = async (id, asset_id) => {
    let payload = {
      url: ADVISORY_DELETE_BANK_DATA,
      data: {
        user_id: id.toString(),
        asset_id: asset_id,
      },
      method: "post",
    };

    const r = await fetchData(payload);
    if (r["error_code"] == "0") {
      props.deleteAssetData(asset_id);
      getbankdata();
    }
  };

  const [sessionId, setUserSessionId] = useState("");
  const { v4: uuidv4 } = require("uuid");

  const handleRefresh = async (
    user_id,
    consent_id,
    consent_handle,
    daterange_from,
    daterange_to,
    cust_id
  ) => {
    setIsLoading(true);
    const rid = uuidv4();
    const ts = new Date().toISOString();
    const loginPayload = {
      header: {
        rid: rid,
        ts: ts,
        channelId: "finsense",
      },
      body: {
        userId: "channel@fintoo",
        password: "85a333fb49044c7e91611a0d962ff8ba",
      },
    };

    const url =
      "https://fintoo.fiulive.finfactor.co.in/finsense/API/V2/User/Login";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginPayload),
    };

    const loginResponse = await fetch(url, options);

    if (loginResponse.status === 200) {
      const responseData = await loginResponse.json();
      const token = responseData.body.token;
      try {
        let fir_request_payload = {
          header: {
            rid: rid,
            ts: ts,
            channelId: "finsense",
          },
          body: {
            custId: cust_id,
            consentId: consent_id,
            consentHandleId: consent_handle,
            dateTimeRangeFrom: daterange_from,
            dateTimeRangeTo: daterange_to,
          },
        };

        const requestBody = JSON.stringify(fir_request_payload);

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: requestBody,
        };

        fetch(
          "https://fintoo.fiulive.finfactor.co.in/finsense/API/V2/FIRequest",
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.body && data.body.sessionId) {
              setUserSessionId(data.body.sessionId);
              FIStatus(
                data.body.sessionId,
                cust_id,
                consent_handle,
                consent_id,
                token,
                daterange_from,
                daterange_to
              );
            } else {
              setIsLoading(false);
              toastr.options.positionClass = "toast-bottom-left";
              toastr.error("Could not fetch session id from the bank");
            }
          })
          .catch((error) => {
            console.log("Error:", error);
          });
      } catch (e) {
        console.log("Error", e);
      }
    }
  };

  const FIStatus = async (
    sId,
    custID,
    ConsentHandle,
    consentID,
    token,
    daterange_from,
    daterange_to,
    retryCount = 0
  ) => {
    let sessionId = sId;

    try {
      const customHeaders = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const payload = {
        url: `https://fintoo.fiulive.finfactor.co.in/finsense/API/V2/FIStatus/${consentID}/${sessionId}/${ConsentHandle}/${custID}`,
        headers: customHeaders,
        method: "get",
      };

      const r = await fetchData(payload);

      if (r.body.fiRequestStatus === "READY") {
        FIPfmDataReport(
          sId,
          token,
          custID,
          consentID,
          ConsentHandle,
          daterange_from,
          daterange_to
        );
      } else {
        if (retryCount < 5) {
          setTimeout(
            () =>
              FIStatus(
                sId,
                custID,
                ConsentHandle,
                consentID,
                token,
                daterange_from,
                daterange_to,
                retryCount + 1
              ),
            15000
          );
        } else {
          toastr.options.positionClass = "toast-bottom-left";
          toastr.error(
            "Financial Information (FI) Status is PENDING / REJECTED"
          );
          setIsLoading(false);
          // navigate(
          //   `${process.env.PUBLIC_URL}/datagathering/assets-liabilities/`
          // );
        }
      }
    } catch (error) {
      console.error("OTP error", error);
    }
  };

  const FIPfmDataReport = async (
    sID,
    token,
    custID,
    consentID,
    ConsentHandle,
    daterange_from,
    daterange_to
  ) => {
    try {
      let customHeaders = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      };

      let payload = {
        url: `https://fintoo.fiulive.finfactor.co.in/finsense/API/V2/Consent/${consentID}`,
        headers: customHeaders,
        method: "get",
      };

      const r = await fetchData(payload);
      if (r.body.status == "ACTIVE") {
        let linkref = r.body.ConsentDetail.Accounts["0"].linkRefNumber;

        try {
          let customHeaders = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          };

          let payload = {
            url: `https://fintoo.fiulive.finfactor.co.in/finsense/API/V2/FIPfmDataReport/${ConsentHandle}/${sID}/${linkref}`,
            headers: customHeaders,
            method: "get",
          };

          const userData = await fetchData(payload);
          let balancePayload = {
            bankName: userData.body.statementAccounts["0"].bank,
            memberName: userData.body.customerInfo.name,
            mobileNumber: userData.body.customerInfo.mobile,
          };

          toastr.options.positionClass = "toast-bottom-left";
          toastr.success("Data updated successfully");
          setIsLoading(false);

          // setUserBalancePayload(balancePayload);
          // setOpenModalByName("ApprovedConsent");

          try {
            let assetPayload = {
              id: assetId,
              Created_By: 0,
              Updated_By: 0,
              asset_amount:
                userData?.body.statementAccounts["0"].currentBalance,
              asset_abreturn: "0",
              annual_growth_rate: "10",
              asset_broker_id: 0,
              asset_category_id: 40,
              asset_citytype: "0",
              asset_current_unit_price: "",
              asset_currency: false,
              asset_ecas_type: "broker",
              asset_epf_ismanual: "1",
              asset_folio_number: null,
              asset_footnote: null,
              asset_frequency: "1",
              asset_goal_link_id: 0,
              asset_goalname: null,
              asset_gold_karat: 0,
              asset_isActive: "1",
              asset_ismortgage: "0",
              asset_isperpetual: "3",
              asset_isallocation: false,
              asset_iselss: "1",
              asset_islinkable: true,
              asset_isrecurring: false,
              asset_isrented: "1",
              asset_maturity_amt: 0,
              asset_maturity_date: null,
              asset_member_id: 0,
              asset_mf_end_date: null,
              asset_name: "Bank Balance",
              asset_pan: null,
              asset_payout_type: "1",
              asset_pin_code: "",
              asset_purchase_amount: "",
              asset_purchase_date: null,
              asset_rental_amount: "",
              asset_rental_income: null,
              asset_ror: "0",
              asset_sub_category_id: 61,
              asset_unique_code: "",
              asset_units: "",
              categorydetail: "Bank Balance",
              created_datetime: moment().format("DD/MM/YYYY"),
              employee_contribution: "",
              employer_contribution: "",
              fp_log_id: session["data"]["fp_log_id"],
              installment_ispaid: "1",
              membername1: "",
              stock_mf: null,
              stock_name: null,
              subcategorydetail: "",
              totalinvestedvalue: "",
              totalpurchasevalue: "",
              totalmaturtiyamount: "",
              updated_datetime: moment().format("DD/MM/YYYY"),
              user_id: session["data"]["id"],
              scheme_equityshare: {},
              is_direct: 0,
            };

            const r = await apiCall(
              ADVISORY_UPDATE_ASSETS_API,
              assetPayload,
              true,
              false
            );

            if (r["error_code"] == "100") {
              try {
                let bankBalancePayload = {
                  url: ADVISORY_ADD_BANK_DATA,
                  data: {
                    bank_name: userData?.body?.statementAccounts["0"].bank,
                    member_name: userData?.body?.customerInfo.name,
                    mobile_number: custID.replace(/[^0-9]/g, ""),
                    user_id: "" + session["data"]["id"],
                    fp_log_id: "" + session["data"]["fp_log_id"],
                    consent_id: consentID,
                    consent_handle: ConsentHandle,
                    cust_id: custID,
                    daterange_from: daterange_from,
                    daterange_to: daterange_to,
                    updated_datetime: new Date().toISOString(),
                  },
                  method: "post",
                };

                const r = await fetchData(bankBalancePayload);

                if (r["error_code"] == "100") {
                  console.log("Error", r);
                }
              } catch (e) {
                console.log("Error"), e;
              }
            }
          } catch (e) {
            console.log("Error", e);
          }
        } catch (e) {
          console.log("Error", e);
        }
      } else {
        setIsLoading(false);
        navigate(`${process.env.PUBLIC_URL}/datagathering/assets-liabilities/`);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  useEffect(() => {
    if (window.location.href.includes("openLinkYourStatement=1")) {
      setOpenModalByName("Link_Account_Bank");
    }
  }, []);

  // console.log("openModalByName778", openModalByName);

  const handleCloseModal = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const openLinkYourStatement = urlParams.get("openLinkYourStatement");

    if (openLinkYourStatement === "1") {
      window.location.href =
        "https://stg.minty.co.in/datagathering/assets-liabilities";
    } else {
      setOpenModalByName(null);
    }
  };

  const handleClose = (type) => {
    if (type == "yes") {
      handleDelete(userId, assetId);
    } else {
      setShow(false);
    }
  };

  const showuniqueUANModalclose = () => {
    setShowuniqueUANModal(false);
  }

  return (
    <div>
      <FintooLoader isLoading={isLoading} />

      {(!props.assetEditId || props.assetEditId == "") &&
        assetsDetails.asset_sub_category_id == 61 && (
          <>
            <div className="d-md-flex d-grid place-items-center place-content-center justify-content-center">
              <DgRoundedButton
                active={selectedButton == "Link Your Bank Statement"}
                onClick={() => {
                  setSelectedButton("Link Your Bank Statement");
                  setSelectedExtraOption("Link Your Bank Statement");
                  setOpenModalByName("Link_Account_Bank");
                }}
                title="Link Your Bank Statement"
              />
              <DgRoundedButton
                active={selectedButton == "Manual Entry"}
                onClick={() => {
                  setSelectedButton("Manual Entry");
                  setSelectedExtraOption("Manual Entry");
                  setAssetsDetails({
                    ...assetsDetails,
                    asset_name: "Bank Balance",
                  });
                }}
                title="Enter Manually"
              />
            </div>
            {assetsDetails.asset_sub_category_id === 61 &&
              selectedButton === "Link Your Bank Statement" && (
                <div className="mt-md-4">
                  <div className="table-responsive rTabl">
                    <table className="bgStyleTable">
                      <tbody>
                        <tr>
                          <th>Linked Bank</th>
                          <th>Member Name</th>
                          <th>Mobile Number</th>
                          <th>Connected On</th>
                          <th>Last Updated</th>
                          <th>Action</th>
                        </tr>
                        {bankBalanceData && bankBalanceData.length > 0 ? (
                          bankBalanceData.map((item) => (
                            <tr key={item.id}>
                              <td>{item.bank_name}</td>
                              <td>{item.member_name}</td>
                              <td>{item.mobile_number}</td>
                              <td>
                                {moment(item.created_datetime).format("LL")}
                              </td>
                              <td>
                                {moment(item.updated_datetime).format("LL")}
                              </td>
                              <td>
                                <div className="d-flex">
                                  <div>
                                    <img
                                      onClick={() => {
                                        handleShow();
                                        setUserId(item.user_id);
                                        setAssetId(item.asset_id);
                                      }}
                                      style={{ cursor: "pointer" }}
                                      src={
                                        process.env.REACT_APP_STATIC_URL +
                                        "media/DG/Delete.svg"
                                      }
                                      alt="Delete"
                                    />
                                  </div>
                                  <div className="ps-4">
                                    {/* <div>
                                      <img
                                        onClick={() => {
                                          handleRefresh(
                                            item.user_id,
                                            item.consent_id,
                                            item.consent_handle,
                                            item.daterange_from,
                                            item.daterange_to,
                                            item.cust_id
                                          );
                                          setAssetId(item.asset_id);
                                        }}
                                        style={{ cursor: "pointer" }}
                                        src={
                                          process.env.REACT_APP_STATIC_URL +
                                          "media/DG/Refresh.svg"
                                        }
                                        alt="Refresh"
                                      />
                                    </div> */}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" style={{ textAlign: "center" }}>
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
          </>
        )}
      {assetsDetails.asset_sub_category_id === 60 && (
        <form noValidate="novalidate" name="goldassetform">
          <div className="row d-flex align-items-center mt-2">
            <div className="col-md-5 custom-input">
              <div
                className={`form-group ${assetsDetails.asset_name ? "inputData" : null
                  } `}
                style={{ paddingTop: "19px" }}
              >
                <input
                  type="text"
                  name="asset_name"
                  value={assetsDetails.asset_name}
                  onChange={(e) => {
                    setAssetsDetails({
                      ...assetsDetails,
                      asset_name: e.target.value,
                    });
                  }}
                  onBlur={() => {
                    simpleValidator.current.showMessageFor("Asset Name");
                    forceUpdate(1);
                  }}
                  disabled={
                    assetsDetails.asset_type &&
                    assetsDetails.asset_type != "none"
                  }
                  autoComplete="off"
                />
                <span class="highlight"></span>
                <span class="bar"></span>
                <label for="name">Name of Asset*</label>
              </div>
              {simpleValidator.current.message(
                "Asset Name",
                assetsDetails.asset_name,
                "required|alpha_space|min:3|max:60",
                {
                  messages: {
                    alpha_space: "Alphabets are allowed only.",
                    required: "Please enter asset name",
                    max: "Asset name must be between 3-35 characters.",
                    min: "Asset name must be between 3-35 characters.",
                  },
                }
              )}
            </div>
            <div className="col-md-5 mt-md-0 mt-3">
              <div className="material" style={{ marginTop: "2px" }}>
                <Form.Label>Name of holder*</Form.Label>
                {familyData && (
                  <Select
                    classNamePrefix="sortSelect"
                    isSearchable={false}
                    styles={CustomStyles}
                    options={familyData}
                    onChange={(e) =>
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_member_id: e.value,
                      })
                    }
                    value={familyData.filter(
                      (v) => v.value == assetsDetails.asset_member_id
                    )}
                    isDisabled={
                      assetsDetails.asset_type &&
                      assetsDetails.asset_type != "none"
                    }
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row py-md-2">
            {assetsDetails.asset_sub_category_id != 63 && (
              <div className="col-md-5 mt-md-0 mt-3 custom-input">
                <div
                  className={`form-group ${assetsDetails.asset_amount ? "inputData" : null
                    } `}
                >
                  <input
                    type="number"
                    name="asset_amount"
                    value={assetsDetails.asset_amount}
                    onChange={(e) => {
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                      });
                    }}
                    autoComplete="off"
                  />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label for="name">Current Balance*</label>
                </div>
                {simpleValidator.current.message(
                  "Current Balance",
                  assetsDetails.asset_amount,
                  "required",
                  { messages: { required: "Please add asset value" } }
                )}
              </div>
            )}
          </div>
          <div className="row mt-2">
            <div className="col-md-8 ">
              <div className="d-md-flex">
                <Form.Label className=" ">
                  Consider This Asset In Automated Linkage*
                </Form.Label>
                <span className="info-hover-left-box ms-md-4">
                  <span Name="icon">
                    <img
                      alt="More information"
                      src="https://static.fintoo.in/static/assets/img/more_information.svg"
                    />
                  </span>
                  <span className="msg">Ex: 400097</span>
                </span>
                <div className="d-flex ms-md-4">
                  <div>No</div>
                  <Switch
                    onChange={(v) =>
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_islinkable: v,
                      })
                    }
                    checked={assetsDetails.asset_islinkable}
                    className="react-switch px-2"
                    activeBoxShadow="0 0 2px 3px #424242"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={20}
                    width={40}
                    onColor="#042b62"
                   offColor="#d8dae5"
                  />
                  <div>Yes</div>
                </div>
              </div>
            </div>
            {assetsDetails.asset_islinkable == true && (
              <>
                <div className="row py-md-2">
                  <div className="col-md-8 mt-md-2 mt-3">
                    <div className="d-md-flex">
                      <Form.Label className="link_asset_style">
                        Link This Investment Asset to Goal
                      </Form.Label>{" "}
                      <span
                        className="ms-md-4 info-hover-left-box float-right"
                        style={{
                          position: "relative !important",
                        }}
                      >
                        <span className="icon">
                          <img
                            alt="More information"
                            src="https://static.fintoo.in/static/assets/img/more_information.svg"
                          />
                        </span>
                        <span className="msg">
                          You can only assign goals which are prior to the end
                          date of the SIP, if any
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-md-0">
                  <div className="material   w-100">
                    <div
                      className="m-0 btn-sm default-btn gradient-btn save-btn"
                      onClick={() => setGoalSelected(true)}
                    >
                      Select Goals
                    </div>
                    <br></br>
                    <br></br>

                    {selectedGoals ? (
                      <div
                        className="d-flex"
                        style={{ textAlign: "left!important" }}
                      >
                        <div>
                          <b>Selected Goals : </b>
                        </div>
                        <div className="ms-1">{selectedGoals}</div>
                      </div>
                    ) : (
                      ""
                    )}
                    {isGoalSelected ? (
                      <GoalsDropdown
                        setGoalSelected={setGoalSelected}
                        goals={goalData}
                        unchangedgoaldata={unchangedgoaldata}
                        closeModal={closeModal}
                        selectGoals={selectGoals}
                        selectedGoals={selectedGoals}
                        selectedGoalIdArray={selectedGoalIdArray}
                        selectedGoalsId={selectedGoalsId}
                        setPriorityArray={setPriorityArray}
                        selectedPriorityArray={selectedPriorityArray}
                        setAutoMatedGoal={setAutoMatedGoal}
                        isAutoMatedGoal={isAutoMatedGoal}
                        setGoalLink={setGoalLink}
                        type={"Asset"}
                        asset_maturity_date={assetsDetails?.asset_mf_end_date}
                        isGoalFilter={
                          assetsDetails.asset_isrecurring == "1" ? "1" : "0"
                        }
                        isAssetRecurring={
                          assetsDetails.asset_isrecurring == "1" ? "1" : "0"
                        }
                      ></GoalsDropdown>
                    ) : (
                      ""
                    )}
                    <div className=""></div>
                  </div>
                </div>
              </>
            )}
            <div className="row py-2">
              <div className=" text-center">
                <div>
                  <div className="btn-container">
                    <div className="d-flex justify-content-center">
                      <a href={process.env.PUBLIC_URL + "/datagathering/goals"}>
                        <div className="previous-btn form-arrow d-flex align-items-center">
                          <FaArrowLeft />
                          <span className="hover-text">&nbsp;Previous</span>
                        </div>
                      </a>

                      {addForm && (
                        <button
                          onClick={(e) => handleLiquidSubmit(e)}
                          className="default-btn gradient-btn save-btn"
                        >
                          Save & Add More
                        </button>
                      )}
                      {updateForm && (
                        <div>
                          <button
                            onClick={(e) => handleLiquidCancel(e)}
                            className="default-btn gradient-btn save-btn"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={(e) => handleLiquidUpdate(e)}
                            className="default-btn gradient-btn save-btn"
                          >
                            Update
                          </button>
                        </div>
                      )}

                      <div
                        className="next-btn form-arrow d-flex align-items-center"
                        onClick={() => props.setTab("tab2")}
                      >
                        <span className="hover-text" style={{ maxWidth: 100 }}>
                          Continue&nbsp;
                        </span>
                        <FaArrowRight />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}

      {/* {assetsDetails.asset_sub_category_id === 61 &&
        selectedExtraOption === "Link Your Bank Statement" && (
          <>
            <div className="d-flex justify-content-center">
              <DgRoundedButton
                active={selectedButton === "Link Account"}
                onClick={() => {
                  setSelectedButton("Link Account");
                  setSelectedExtraOption("Link Account");
                  setOpenModalByName("Link_Account_Bank");
                }}
                title="Link Account"
              />
            </div>
            <div className="mt-md-4">
              <div className="table-responsive rTabl">
                <table className="bgStyleTable">
                  <tbody>
                    <tr>
                      <th>Linked Bank</th>
                      <th>Member Name</th>
                      <th>Mobile Number</th>
                      <th>Connected On</th>
                      <th>Last Updated</th>
                      <th>Action</th>
                    </tr>
                    {bankBalanceData && bankBalanceData.length > 0 ? (
                      bankBalanceData.map((item) => (
                        <tr key={item.id}>
                          <td>{item.bank_name}</td>
                          <td>{item.member_name}</td>
                          <td>{item.mobile_number}</td>
                          <td>{moment(item.created_datetime).format("LL")}</td>
                          <td>{moment(item.updated_datetime).format("LL")}</td>
                          <td>
                            <div className="d-flex">
                              <div>
                                <img
                                  onClick={() => {
                                    handleShow();
                                    setUserId(item.user_id);
                                    setAssetId(item.asset_id);
                                  }}
                                  style={{ cursor: "pointer" }}
                                  src={
                                    process.env.REACT_APP_STATIC_URL +
                                    "media/DG/Delete.svg"
                                  }
                                  alt="Delete"
                                />
                              </div>
                              <div className="ps-4">
                                <div>
                                  <img
                                    onClick={() => {
                                      handleRefresh(
                                        item.user_id,
                                        item.consent_id,
                                        item.consent_handle,
                                        item.daterange_from,
                                        item.daterange_to,
                                        item.cust_id
                                      );
                                      setAssetId(item.asset_id);
                                    }}
                                    style={{ cursor: "pointer" }}
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/DG/Refresh.svg"
                                    }
                                    alt="Refresh"
                                  />
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )} */}
      {assetsDetails.asset_sub_category_id == 61 &&
        selectedExtraOption == "Manual Entry" && (
          <form noValidate="novalidate" name="goldassetform">
            <div className="row d-flex align-items-center mt-2">
              <div className="col-md-5 custom-input">
                <div
                  className={`form-group ${assetsDetails.asset_name ? "inputData" : null
                    } `}
                  style={{ paddingTop: "19px" }}
                >
                  <input
                    type="text"
                    name="asset_name"
                    value={assetsDetails.asset_name}
                    onChange={(e) => {
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_name: e.target.value,
                      });
                    }}
                    onBlur={() => {
                      simpleValidator.current.showMessageFor("Asset Name");
                      forceUpdate(1);
                    }}
                    disabled={
                      assetsDetails.asset_type &&
                      assetsDetails.asset_type != "none"
                    }
                    autoComplete="off"
                  />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label for="name">Name of Asset*</label>
                </div>
                {simpleValidator.current.message(
                  "Asset Name",
                  assetsDetails.asset_name,
                  "required|alpha_space|min:3|max:60",
                  {
                    messages: {
                      alpha_space: "Alphabets are allowed only.",
                      required: "Please enter asset name",
                      max: "Asset name must be between 3-35 characters.",
                      min: "Asset name must be between 3-35 characters.",
                    },
                  }
                )}
              </div>
              <div className="col-md-5 mt-md-0 mt-3">
                <div className="material" style={{ marginTop: "2px" }}>
                  <Form.Label>Name of holder*</Form.Label>
                  {familyData && (
                    <Select
                      classNamePrefix="sortSelect"
                      isSearchable={false}
                      styles={CustomStyles}
                      options={familyData}
                      onChange={(e) =>
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_member_id: e.value,
                        })
                      }
                      value={familyData.filter(
                        (v) => v.value == assetsDetails.asset_member_id
                      )}
                      isDisabled={
                        assetsDetails.asset_type &&
                        assetsDetails.asset_type != "none"
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row py-md-2">
              {assetsDetails.asset_sub_category_id != 63 && (
                <div className="col-md-5 mt-md-0 mt-3 custom-input">
                  <div
                    className={`form-group ${assetsDetails.asset_amount ? "inputData" : null
                      } `}
                  >
                    <input
                      type="number"
                      name="asset_amount"
                      value={assetsDetails.asset_amount}
                      onChange={(e) => {
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                        });
                      }}
                      autoComplete="off"
                    />
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label for="name">Current Balance*</label>
                  </div>
                  {simpleValidator.current.message(
                    "Current Balance",
                    assetsDetails.asset_amount,
                    "required",
                    { messages: { required: "Please add asset value" } }
                  )}
                </div>
              )}
            </div>
            <div className="row mt-2">
              <div className="col-md-8 ">
                <div className="d-md-flex">
                  <Form.Label className=" ">
                    Consider This Asset In Automated Linkage*
                  </Form.Label>
                  <span className="info-hover-left-box ms-md-4">
                    <span Name="icon">
                      <img
                        alt="More information"
                        src="https://static.fintoo.in/static/assets/img/more_information.svg"
                      />
                    </span>
                    <span className="msg">Ex: 400097</span>
                  </span>
                  <div className="d-flex ms-md-4">
                    <div>No</div>
                    <Switch
                      onChange={(v) =>
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_islinkable: v,
                        })
                      }
                      checked={assetsDetails.asset_islinkable}
                      className="react-switch px-2"
                      activeBoxShadow="0 0 2px 3px #424242"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                      onColor="#042b62"
                     offColor="#d8dae5"
                    />
                    <div>Yes</div>
                  </div>
                </div>
              </div>
              {assetsDetails.asset_islinkable == true && (
                <>
                  <div className="row py-md-2">
                    <div className="col-md-8 mt-md-2 mt-3">
                      <div className="d-md-flex">
                        <Form.Label className="link_asset_style">
                          Link This Investment Asset to Goal
                        </Form.Label>{" "}
                        <span
                          className="ms-md-4 info-hover-left-box float-right"
                          style={{
                            position: "relative !important",
                          }}
                        >
                          <span className="icon">
                            <img
                              alt="More information"
                              src="https://static.fintoo.in/static/assets/img/more_information.svg"
                            />
                          </span>
                          <span className="msg">
                            You can only assign goals which are prior to the end
                            date of the SIP, if any
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-md-0">
                    <div className="material   w-100">
                      <div
                        className="m-0 btn-sm default-btn gradient-btn save-btn"
                        onClick={() => setGoalSelected(true)}
                      >
                        Select Goals
                      </div>
                      <br></br>
                      <br></br>

                      {selectedGoals ? (
                        <div
                          className="d-flex"
                          style={{ textAlign: "left!important" }}
                        >
                          <div>
                            <b>Selected Goals : </b>
                          </div>
                          <div className="ms-1">{selectedGoals}</div>
                        </div>
                      ) : (
                        ""
                      )}
                      {isGoalSelected ? (
                        <GoalsDropdown
                          setGoalSelected={setGoalSelected}
                          goals={goalData}
                          unchangedgoaldata={unchangedgoaldata}
                          closeModal={closeModal}
                          selectGoals={selectGoals}
                          selectedGoals={selectedGoals}
                          selectedGoalIdArray={selectedGoalIdArray}
                          selectedGoalsId={selectedGoalsId}
                          setPriorityArray={setPriorityArray}
                          selectedPriorityArray={selectedPriorityArray}
                          setAutoMatedGoal={setAutoMatedGoal}
                          isAutoMatedGoal={isAutoMatedGoal}
                          setGoalLink={setGoalLink}
                          type={"Asset"}
                          asset_maturity_date={assetsDetails?.asset_mf_end_date}
                          isGoalFilter={
                            assetsDetails.asset_isrecurring == "1" ? "1" : "0"
                          }
                          isAssetRecurring={
                            assetsDetails.asset_isrecurring == "1" ? "1" : "0"
                          }
                        ></GoalsDropdown>
                      ) : (
                        ""
                      )}
                      <div className=""></div>
                    </div>
                  </div>
                </>
              )}
              <div className="row py-2">
                <div className=" text-center">
                  <div>
                    <div className="btn-container">
                      <div className="d-flex justify-content-center">
                        <a
                          href={process.env.PUBLIC_URL + "/datagathering/goals"}
                        >
                          <div className="previous-btn form-arrow d-flex align-items-center">
                            <FaArrowLeft />
                            <span className="hover-text">&nbsp;Previous</span>
                          </div>
                        </a>

                        {addForm && (
                          <button
                            onClick={(e) => handleLiquidSubmit(e)}
                            className="default-btn gradient-btn save-btn"
                          >
                            Save & Add More
                          </button>
                        )}
                        {updateForm && (
                          <div>
                            <button
                              onClick={(e) => handleLiquidCancel(e)}
                              className="default-btn gradient-btn save-btn"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={(e) => handleLiquidUpdate(e)}
                              className="default-btn gradient-btn save-btn"
                            >
                              Update
                            </button>
                          </div>
                        )}

                        <div
                          className="next-btn form-arrow d-flex align-items-center"
                          onClick={() => props.setTab("tab2")}
                        >
                          <span
                            className="hover-text"
                            style={{ maxWidth: 100 }}
                          >
                            Continue&nbsp;
                          </span>
                          <FaArrowRight />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      {props.assetEditId && assetsDetails.asset_sub_category_id == 61 && (
        <div>
          {assetsDetails.asset_sub_category_id == 61 && (
            <form noValidate="novalidate" name="goldassetform">
              <div className="row d-flex align-items-center mt-2">
                <div className="col-md-5 custom-input">
                  <div
                    className={`form-group ${assetsDetails.asset_name ? "inputData" : null
                      } `}
                    style={{ paddingTop: "19px" }}
                  >
                    <input
                      type="text"
                      name="asset_name"
                      value={assetsDetails.asset_name}
                      onChange={(e) => {
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_name: e.target.value,
                        });
                      }}
                      onBlur={() => {
                        simpleValidator.current.showMessageFor("Asset Name");
                        forceUpdate(1);
                      }}
                      disabled={assetsDetails.asset_ecas_type == "broker"}
                      // disabled={
                      //   assetsDetails.asset_type &&
                      //   assetsDetails.asset_type != "none"
                      // }
                      autoComplete="off"
                    />
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label for="name">Name of Asset*</label>
                  </div>
                  {simpleValidator.current.message(
                    "Asset Name",
                    assetsDetails.asset_name,
                    "required|min:3|max:60",
                    {
                      messages: {
                        // alpha_space: "Alphabets are allowed only.",
                        required: "Please enter asset name",
                        max: "Asset name must be between 3-35 characters.",
                        min: "Asset name must be between 3-35 characters.",
                      },
                    }
                  )}
                </div>
                <div className="col-md-5 mt-md-0 mt-3 custom-input">
                  <div className="material" style={{ marginTop: "2px" }}>
                    <Form.Label>Name of holder*</Form.Label>
                    {familyData && (
                      <Select
                        classNamePrefix="sortSelect"
                        isSearchable={false}
                        styles={CustomStyles}
                        options={familyData}
                        onChange={(e) =>
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_member_id: e.value,
                          })
                        }
                        value={familyData.filter(
                          (v) => v.value == assetsDetails.asset_member_id
                        )}
                        isDisabled={assetsDetails.asset_ecas_type == "broker"}
                      // isDisabled={
                      //   assetsDetails.asset_type &&
                      //   assetsDetails.asset_type != "none"
                      // }
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="row py-md-2">
                {assetsDetails.asset_sub_category_id != 63 && (
                  <div className="col-md-5 mt-md-0 custom-input">
                    <div
                      className={`form-group ${assetsDetails.asset_amount ? "inputData" : null
                        } `}
                    >
                      <input
                        type="number"
                        name="asset_amount"
                        value={assetsDetails.asset_amount}
                        onChange={(e) => {
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                          });
                        }}
                        disabled={assetsDetails.asset_ecas_type == "broker"}
                        autoComplete="off"
                      />
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label for="name">Current Balance*</label>
                    </div>
                    {simpleValidator.current.message(
                      "Current Balance",
                      assetsDetails.asset_amount,
                      "required",
                      { messages: { required: "Please add asset value" } }
                    )}
                  </div>
                )}
              </div>
              <div className="row mt-2">
                <div className="col-md-8">
                  <div className="d-md-flex">
                    <Form.Label className=" ">
                      Consider This Asset In Automated Linkage*
                    </Form.Label>
                    <span className="info-hover-left-box ms-md-4">
                      <span Name="icon">
                        <img
                          alt="More information"
                          src="https://static.fintoo.in/static/assets/img/more_information.svg"
                        />
                      </span>
                      <span className="msg">Ex: 400097</span>
                    </span>
                    <div className="d-flex ms-md-4">
                      <div>No</div>
                      <Switch
                        onChange={(v) =>
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_islinkable: v,
                          })
                        }
                        checked={assetsDetails.asset_islinkable}
                        className="react-switch px-2"
                        activeBoxShadow="0 0 2px 3px #424242"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={20}
                        width={40}
                        onColor="#042b62"
                       offColor="#d8dae5"
                      />
                      <div>Yes</div>
                    </div>
                  </div>
                </div>
                {assetsDetails.asset_islinkable == true && (
                  <>
                    <div className="row py-md-2">
                      <div className="col-md-8 mt-md-2 mt-3">
                        <div className="d-md-flex">
                          <Form.Label className="link_asset_style">
                            Link This Investment Asset to Goal
                          </Form.Label>{" "}
                          <span
                            className="ms-md-4 info-hover-left-box float-right"
                            style={{
                              position: "relative !important",
                            }}
                          >
                            <span className="icon">
                              <img
                                alt="More information"
                                src="https://static.fintoo.in/static/assets/img/more_information.svg"
                              />
                            </span>
                            <span className="msg">
                              You can only assign goals which are prior to the
                              end date of the asset
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-md-0">
                      <div className="material   w-100">
                        <div
                          className="m-0 btn-sm default-btn gradient-btn save-btn"
                          onClick={() => setGoalSelected(true)}
                        >
                          Select Goals
                        </div>
                        <br></br>
                        <br></br>

                        {selectedGoals ? (
                          <div
                            className="d-flex"
                            style={{ textAlign: "left!important" }}
                          >
                            <div>
                              <b>Selected Goals : </b>
                            </div>
                            <div className="ms-1">{selectedGoals}</div>
                          </div>
                        ) : (
                          ""
                        )}
                        {isGoalSelected ? (
                          <GoalsDropdown
                            setGoalSelected={setGoalSelected}
                            goals={goalData}
                            unchangedgoaldata={unchangedgoaldata}
                            closeModal={closeModal}
                            selectGoals={selectGoals}
                            selectedGoals={selectedGoals}
                            selectedGoalIdArray={selectedGoalIdArray}
                            selectedGoalsId={selectedGoalsId}
                            setPriorityArray={setPriorityArray}
                            selectedPriorityArray={selectedPriorityArray}
                            setAutoMatedGoal={setAutoMatedGoal}
                            isAutoMatedGoal={isAutoMatedGoal}
                            setGoalLink={setGoalLink}
                            type={"Asset"}
                            asset_maturity_date={
                              assetsDetails?.asset_mf_end_date
                            }
                            isGoalFilter={
                              assetsDetails.asset_isrecurring == "1" ? "1" : "0"
                            }
                            isAssetRecurring={
                              assetsDetails.asset_isrecurring == "1" ? "1" : "0"
                            }
                          ></GoalsDropdown>
                        ) : (
                          ""
                        )}
                        <div className=""></div>
                      </div>
                    </div>
                  </>
                )}
                <div className="row py-2">
                  <div className=" text-center">
                    <div>
                      <div className="btn-container">
                        <div className="d-flex justify-content-center">
                          <Link
                            to={process.env.PUBLIC_URL + "/datagathering/goals"}
                          >
                            <div className="previous-btn form-arrow d-flex align-items-center">
                              <FaArrowLeft />
                              <span className="hover-text">&nbsp;Previous</span>
                            </div>
                          </Link>

                          {addForm && (
                            <button
                              onClick={(e) => handleLiquidSubmit(e)}
                              className="default-btn gradient-btn save-btn"
                            >
                              Save & Add More
                            </button>
                          )}
                          {updateForm && (
                            <div>
                              <button
                                onClick={(e) => handleLiquidCancel(e)}
                                className="default-btn gradient-btn save-btn"
                              >
                                Cancel
                              </button>
                              <button
                                onClick={(e) => handleLiquidUpdate(e)}
                                className="default-btn gradient-btn save-btn"
                              >
                                Update
                              </button>
                            </div>
                          )}
                          <div
                            className="next-btn form-arrow d-flex align-items-center"
                            onClick={() => props.setTab("tab2")}
                          >
                            <span
                              className="hover-text"
                              style={{ maxWidth: 100 }}
                            >
                              Continue&nbsp;
                            </span>
                            <FaArrowRight />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      )}
      {assetsDetails.asset_sub_category_id == 63 && (
        <form noValidate="novalidate" name="goldassetform">
          <div className="row d-flex align-items-center">
            <div className="col-md-5 custom-input">
              <div
                className={`form-group  ${assetsDetails.asset_name ? "inputData" : null
                  }`}
                style={{ paddingTop: "17px" }}
              >
                <input
                  type="text"
                  name="asset_current_unit_price"
                  maxLength={35}
                  value={assetsDetails.asset_name}
                  onChange={(e) => {
                    setAssetsDetails({
                      ...assetsDetails,
                      asset_name: e.target.value,
                    });
                  }}
                  onBlur={() => {
                    simpleValidator.current.showMessageFor("Asset Name");
                    forceUpdate(1);
                  }}
                  disabled={
                    assetsDetails.asset_type &&
                    assetsDetails.asset_type != "none"
                  }
                  autoComplete="off"
                />
                <span class="highlight"></span>
                <span class="bar"></span>
                <label for="name">Name of Asset*</label>
              </div>
              {simpleValidator.current.message(
                "Asset Name",
                assetsDetails.asset_name,
                "required|alpha_space|min:3|max:60",
                {
                  messages: {
                    alpha_space: "Alphabets are allowed only.",
                    required: "Please enter asset name",
                    max: "Asset name must be between 3-35 characters.",
                    min: "Asset name must be between 3-35 characters.",
                  },
                }
              )}
            </div>
            <div className="col-md-5 mt-md-0 mt-3">
              <div className="material">
                <Form.Label>Name of holder*</Form.Label>
                {familyData && (
                  <Select
                    classNamePrefix="sortSelect"
                    isSearchable={false}
                    styles={CustomStyles}
                    options={familyData}
                    onChange={(e) =>
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_member_id: e.value,
                      })
                    }
                    value={familyData.filter(
                      (v) => v.value == assetsDetails.asset_member_id
                    )}
                    isDisabled={
                      assetsDetails.asset_type &&
                      assetsDetails.asset_type != "none"
                    }
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row py-md-2 d-flex align-items-center">
            <div className="col-md-5 mt-md-0 mt-4">
              <div className="material">
                <Form.Label>Date of Purchase*</Form.Label>
                <div
                  className="dt-conbx"
                  style={{
                    borderBottom: "1px solid #dadada",
                    paddingTop: "0px",
                  }}
                >
                  <ReactDatePicker
                    select_date={moment(
                      assetsDetails.asset_purchase_date,
                      "DD/MM/YYYY"
                    ).toDate()}
                    setDate={(date) => {
                      setDate(date, "assetPurchaseDate");
                    }}
                    minDate={""}
                    maxDate={moment().toDate()}
                    className="pt-2"
                  />
                </div>
              </div>
              {simpleValidator.current.message(
                "Date of Purchase",
                assetsDetails.asset_purchase_date,
                "required",
                { messages: { required: "Please enter date of purchase " } }
              )}
            </div>
            <div className="col-md-5 custom-input">
              <div
                className={`form-group w-100 ${assetsDetails.asset_amount ? "inputData" : null
                  }`}
                style={{ paddingTop: "13px" }}
              >
                <input
                  type="number"
                  name="insurance_maturity_amount"
                  value={assetsDetails.asset_amount}
                  onChange={(e) => {
                    setAssetsDetails({
                      ...assetsDetails,
                      asset_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                    });
                  }}
                  autoComplete="off"
                />
                <span class="highlight"></span>
                <span class="bar"></span>
                <label for="name">Direct Value*</label>
              </div>
              {simpleValidator.current.message(
                "Direct Value",
                assetsDetails.asset_amount,
                "required",
                { messages: { required: "Please enter asset direct value" } }
              )}
            </div>
            <div className="row py-md-2 mt-2">
              <div className="col-md-8">
                <div className="d-md-flex">
                  <Form.Label className=" ">
                    Consider This Asset In Automated Linkage*
                  </Form.Label>
                  <span className="info-hover-left-box ms-md-4">
                    <span Name="icon">
                      <img
                        alt="More information"
                        src="https://static.fintoo.in/static/assets/img/more_information.svg"
                      />
                    </span>
                    <span className="msg">Ex: 400097</span>
                  </span>
                  <div className="d-flex ms-md-4">
                    <div>No</div>
                    <Switch
                      onChange={(v) =>
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_islinkable: v,
                        })
                      }
                      checked={assetsDetails.asset_islinkable}
                      className="react-switch px-2"
                      activeBoxShadow="0 0 2px 3px #424242"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                      onColor="#042b62"
                     offColor="#d8dae5"
                    />
                    <div>Yes</div>
                  </div>
                </div>
              </div>
            </div>
            {assetsDetails.asset_islinkable == true && (
              <>
                <div className="row py-md-2">
                  <div className="col-md-8 mt-md-2 mt-3">
                    <div className="d-md-flex">
                      <Form.Label className="link_asset_style">
                        Link This Investment Asset to Goal
                      </Form.Label>
                      <span
                        className="ms-md-4 ms-2 info-hover-left-box float-right"
                        style={{
                          position: "relative !important",
                        }}
                      >
                        <span className="icon">
                          <img
                            alt="More information"
                            src="https://static.fintoo.in/static/assets/img/more_information.svg"
                          />
                        </span>
                        <span className="msg">
                          You can only assign goals which are prior to the end
                          date of the SIP, if any
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-md-0">
                  <div className="material   w-100">
                    <div
                      className="m-0 btn-sm default-btn gradient-btn save-btn"
                      onClick={() => setGoalSelected(true)}
                    >
                      Select Goals
                    </div>
                    <br></br>
                    <br></br>

                    {selectedGoals ? (
                      <div
                        className="d-flex"
                        style={{ textAlign: "left!important" }}
                      >
                        <div>
                          <b>Selected Goals : </b>
                        </div>
                        <div className="ms-1">{selectedGoals}</div>
                      </div>
                    ) : (
                      ""
                    )}
                    {isGoalSelected ? (
                      <GoalsDropdown
                        setGoalSelected={setGoalSelected}
                        goals={goalData}
                        unchangedgoaldata={unchangedgoaldata}
                        closeModal={closeModal}
                        selectGoals={selectGoals}
                        selectedGoals={selectedGoals}
                        selectedGoalIdArray={selectedGoalIdArray}
                        selectedGoalsId={selectedGoalsId}
                        setPriorityArray={setPriorityArray}
                        selectedPriorityArray={selectedPriorityArray}
                        setAutoMatedGoal={setAutoMatedGoal}
                        isAutoMatedGoal={isAutoMatedGoal}
                        setGoalLink={setGoalLink}
                        type={"Asset"}
                        asset_maturity_date={assetsDetails?.asset_mf_end_date}
                      ></GoalsDropdown>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="row py-2">
              <div className=" text-center">
                <div>
                  <div className="btn-container">
                    <div className="d-flex justify-content-center">
                      <a href={process.env.PUBLIC_URL + "/datagathering/goals"}>
                        <div className="previous-btn form-arrow d-flex align-items-center">
                          <FaArrowLeft />
                          <span className="hover-text">&nbsp;Previous</span>
                        </div>
                      </a>

                      {addForm && (
                        <button
                          onClick={(e) => handleLiquidSubmit(e)}
                          className="default-btn gradient-btn save-btn"
                        >
                          Save & Add More
                        </button>
                      )}
                      {updateForm && (
                        <div>
                          <button
                            onClick={(e) => handleLiquidCancel(e)}
                            className="default-btn gradient-btn save-btn"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={(e) => handleLiquidUpdate(e)}
                            className="default-btn gradient-btn save-btn"
                          >
                            Update
                          </button>
                        </div>
                      )}

                      <div
                        className="next-btn form-arrow d-flex align-items-center"
                        onClick={() => props.setTab("tab2")}
                      >
                        <span className="hover-text" style={{ maxWidth: 100 }}>
                          Continue&nbsp;
                        </span>
                        <FaArrowRight />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      {(!props.assetEditId || props.assetEditId == "") && (
        <div>
          {assetsDetails.asset_sub_category_id == 62 && (
            <div>
              <div className="d-md-flex justify-content-center">
                <DgRoundedButton
                  active={selectedButton == "Upload Statement"}
                  onClick={() => {
                    setSelectedButton("Upload Statement");
                    setSelectedExtraOption("Upload Statement");
                  }}
                  title="Upload Statement"
                />
                <DgRoundedButton
                  active={selectedButton == "Manual Entry"}
                  onClick={() => {
                    setSelectedButton("Manual Entry");
                    setSelectedExtraOption("Manual Entry");
                    setAssetsDetails({
                      ...assetsDetails,
                      asset_name: " ",
                    });
                  }}
                  title="Manual Entry"
                />
                <DgRoundedButton
                  active={selectedButton == "Link your Holdings"}
                  onClick={() => {
                    setSelectedButton("Link your Holdings");
                    setSelectedExtraOption("Link your Holdings");
                  }}
                  title="Link your Holdings"
                />
              </div>
            </div>
          )}
          {assetsDetails.asset_sub_category_id == 62 &&
            selectedExtraOption == "Upload Statement" && (
              <form
                encType="multipart/form-data"
                method="post"
                action="http://localhost/fileupload.php"
              >
                <FintooLoader isLoading={isLoading} />
                <div className="col-md-10" value="CAMS">
                  <h4>Cams / Karvy</h4>
                  <ol>
                    <li>
                      Visit{" "}
                      <a
                        style={{ color: "green" }}
                        target="_new"
                        href="https://www.camsonline.com/Investors/Statements/Consolidated-Account-Statement"
                      >
                        <u>CAMS</u>
                      </a>{" "}
                      website.
                    </li>
                    <li>
                      Go to Statements &gt;&gt; View More then select{" "}
                      <b>CAS - CAMS+KFintech</b>
                    </li>
                    <li>
                      Choose 'Statement Type' as Detailed{" "}
                      <b>(Includes transaction listing)</b>
                    </li>
                    <li>
                      Choose 'Period' as <b>Specific Period</b> of your choice
                    </li>
                    <li>
                      Select <b>“01-01-1990” in “From Date”</b>
                    </li>
                    <li>
                      Choose 'Folio Listing' as{" "}
                      <b>without Zero Balance Folios</b>
                    </li>
                    <li>
                      Enter your <b>Email and choose password</b>/key for your
                      CAMS statement
                    </li>
                    <li>
                      You will get your <b>CAMS statement</b> on your email
                      within 10 minutes
                    </li>
                  </ol>
                </div>
                <div className="col-md-10">
                  <p>
                    <b>Note</b>: CAMS PDF can be uploaded one time a day ( Per
                    PAN )
                  </p>
                  <p>
                    The statement uploaded i.e. CAMS doesn't fetch the existing
                    SIP's, Post statement upload do edit the existing SIP's in
                    the funds fetched in the system.
                  </p>
                </div>
                <div>
                  <DgDragDrop2
                    className="iconupload"
                    value={dropFiles}
                    onFilesSelected={handleFilesSelected}
                  />
                  {simpleValidator.current.message(
                    "Password",
                    dropFiles,
                    "required",
                    {
                      messages: {
                        required:
                          "Please select atleast one document to upload",
                      },
                    }
                  )}
                </div>
                <div className="col-md-12 custom-input mt-5">
                  <div
                    className={`form-group ${docPassword ? "inputData" : null
                      } `}
                  >
                    <input
                      type="password"
                      name="Password"
                      value={docPassword}
                      onChange={(e) => {
                        setDocPassword(e.target.value);
                      }}
                      required
                      autoComplete="off"
                    />
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label for="name">Password*</label>
                  </div>
                  {simpleValidator.current.message(
                    "Password",
                    docPassword,
                    "required",
                    { messages: { required: "Please enter the password" } }
                  )}
                </div>
                <div className="row py-2">
                  <div className=" text-center">
                    <div>
                      <div className="btn-container">
                        <div className="d-flex justify-content-center">
                          <a
                            href={
                              process.env.PUBLIC_URL + "/datagathering/goals"
                            }
                          >
                            <div className="previous-btn form-arrow d-flex align-items-center">
                              <FaArrowLeft />
                              <span className="hover-text">&nbsp;Previous</span>
                            </div>
                          </a>

                          {props.addForm && (
                            <button
                              onClick={(e) => handleUploadSubmit(e)}
                              className="default-btn gradient-btn save-btn"
                            >
                              Save & Add More
                            </button>
                          )}
                          <Link
                            to={
                              process.env.PUBLIC_URL +
                              "/datagathering/insurance"
                            }
                          >
                            <div className="next-btn form-arrow d-flex align-items-center">
                              <span
                                className="hover-text"
                                style={{ maxWidth: 100 }}
                              >
                                Continue&nbsp;
                              </span>
                              <FaArrowRight />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          {assetsDetails.asset_sub_category_id == 62 &&
            selectedExtraOption == "Manual Entry" && (
              <form
                noValidate="novalidate"
                name="goldassetform"
                className="mt-2"
              >
                <div className="row py-md-2">
                  <div className="col-md-5">
                    <div className="material">
                      <Form.Label>
                        Start Typing To Search For Your Liquid Funds*
                      </Form.Label>
                      <Select
                        classNamePrefix="sortSelect"
                        isSearchable={true}
                        styles={CustomStyles}
                        onChange={(v) =>
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_unique_code: v.isin,
                            asset_name: v.fund_name,
                            asset_current_unit_price: v.current_nav,
                          })
                        }
                        value={schemedata.filter(
                          (v) => v.fund_name == assetsDetails.asset_name
                        )}
                        options={schemedata}
                        getOptionLabel={(schemedata) => schemedata.fund_name}
                      />
                    </div>

                    {simpleValidator.current.message(
                      "Select",
                      assetsDetails.asset_name,
                      "required",
                      {
                        messages: { required: "Please select one liquid fund" },
                      }
                    )}
                  </div>
                  <div className="col-md-5 mt-md-0 mt-4">
                    <div
                      className="material "
                      style={{
                        paddingTop: "5px",
                      }}
                    >
                      <Form.Label>Who Is This Investment For*</Form.Label>
                      {familyData && (
                        <Select
                          classNamePrefix="sortSelect"
                          isSearchable={false}
                          styles={CustomStyles}
                          options={familyData}
                          onChange={(e) =>
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_member_id: e.value,
                            })
                          }
                          value={familyData.filter(
                            (v) => v.value == assetsDetails.asset_member_id
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="row py-md-2 mt-3">
                  <div className="col-md-8">
                    <div className="d-md-flex">
                      <Form.Label className=" ">
                        Is The Equity One Time Or Recurring?*
                      </Form.Label>
                      <div className="d-flex ms-md-4">
                        <div>One Time</div>
                        <Switch
                          onChange={(v) => {
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_isrecurring: v,
                            });
                          }}
                          checked={assetsDetails.asset_isrecurring}
                          className="react-switch px-2"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={40}
                          onColor="#042b62"
                         offColor="#d8dae5"
                        />
                        <div>Recurring</div>
                      </div>
                    </div>
                  </div>
                </div>

                {assetsDetails.asset_isrecurring == true && (
                  <>
                    <div className="row d-flex align-items-center py-md-2 ">
                      <div className="col-md-5 mt-md-0 mt-4">
                        <div className="material">
                          <Form.Label>SIP Start Date</Form.Label>
                          <div
                            className="dt-conbx"
                            style={{
                              borderBottom: "1px solid #dadada",
                              // paddingTop: "-2px",
                            }}
                          >
                            <ReactDatePicker
                              select_date={moment(
                                assetsDetails.asset_purchase_date,
                                "DD/MM/YYYY"
                              ).toDate()}
                              setDate={(date) => {
                                setDate(date, "assetPurchaseDate");
                              }}
                              minDate={""}
                              maxDate={moment().toDate()}
                              className="pt-4"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 mt-md-0 mt-3 custom-input">
                        <div
                          className={`form-group w-100 ${assetsDetails.asset_amount ? "inputData" : null
                            }`}
                          style={{ paddingTop: "14px" }}
                        >
                          <input
                            type="text"
                            name="insurance_maturity_amount"
                            value={assetsDetails.asset_amount}
                            onChange={(e) => {
                              setAssetsDetails({
                                ...assetsDetails,
                                asset_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                              });
                            }}
                            autoComplete="off"
                          />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label for="name">SIP Amount (₹)*</label>
                        </div>
                        {simpleValidator.current.message(
                          "SIP Amount",
                          assetsDetails.asset_amount,
                          "required",
                          {
                            messages: { required: "Please enter SIP Amount" },
                          }
                        )}
                      </div>
                    </div>
                    <div className="row py-md-2">
                      <div className="col-md-5 mt-md-0 mt-3 custom-input">
                        <div
                          className={`form-group mt-1 w-100 ${assetsDetails.asset_purchase_amount
                            ? "inputData"
                            : null
                            }`}
                        >
                          <input
                            type="text"
                            name="asset_purchase_amount"
                            value={assetsDetails.asset_purchase_amount}
                            onChange={(e) => {
                              setAssetsDetails({
                                ...assetsDetails,
                                asset_purchase_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                              });
                            }}
                            autoComplete="off"
                          />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label for="name">Total Invested Amount (₹)*</label>
                        </div>
                        {simpleValidator.current.message(
                          "Asset Purchase Amount",
                          assetsDetails.asset_purchase_amount,
                          "required",
                          {
                            messages: {
                              required: "Please enter asset purchase amount",
                            },
                          }
                        )}
                      </div>
                      <div className="col-md-5 mt-md-0 mt-3 custom-input">
                        <div
                          className={`form-group mt-1 w-100 ${assetsDetails.asset_units ? "inputData" : null
                            }`}
                        >
                          <input
                            type="number"
                            name="asset_purchase_amount"
                            value={assetsDetails.asset_units}
                            onChange={(e) => {
                              setAssetsDetails({
                                ...assetsDetails,
                                asset_units: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                              });
                            }}
                            autoComplete="off"
                          />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label for="name">No. Of Units*</label>
                        </div>
                        {simpleValidator.current.message(
                          "Asset Units",
                          assetsDetails.asset_units,
                          "required",
                          { messages: { required: "Please enter asset units" } }
                        )}
                      </div>
                    </div>
                    <div className="row py-md-2">
                      <div className="col-md-5 mt-md-0 mt-3 custom-input">
                        <div
                          className={`form-group mt-1 w-100 ${assetsDetails.asset_current_unit_price
                            ? "inputData"
                            : null
                            }`}
                        >
                          <input
                            type="text"
                            name="asset_current_unit_price"
                            value={assetsDetails.asset_current_unit_price}
                            onChange={(e) => {
                              setAssetsDetails({
                                ...assetsDetails,
                                asset_current_unit_price: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                              });
                            }}
                            autoComplete="off"
                          />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label for="name">Current Price (₹)*</label>
                        </div>

                        {simpleValidator.current.message(
                          "Asset Current Price",
                          assetsDetails.asset_current_unit_price,
                          "required",
                          {
                            messages: {
                              required: "Please enter asset current gold price",
                            },
                          }
                        )}
                      </div>
                      <div className="col-md-5 mt-md-0 mt-3 ">
                        <div className=" d-flex justify-content-between flex-grow-1 custom-input">
                          <div
                            className={`form-group mt-1 w-100 ${assetsDetails.totalinvestedvalue
                              ? "inputData"
                              : null
                              }`}
                          >
                            <span>
                              <input
                                type="text"
                                name="asset_current_unit_price"
                                value={assetsDetails.totalinvestedvalue}
                                onChange={(e) => {
                                  setAssetsDetails({
                                    ...assetsDetails,
                                    totalinvestedvalue: e.target.value,
                                  });
                                }}
                                readOnly
                                autoComplete="off"
                              />
                              <span class="highlight"></span>
                              <span class="bar"></span>
                              <label for="name">Current value (₹)</label>
                            </span>
                            <span
                              className="info-hover-box float-right"
                              style={{
                                position: "relative !important",
                              }}
                            >
                              <span className="icon">
                                <img
                                  alt="More information"
                                  src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                />
                              </span>
                              <span className="msg">
                                Auto calculated by SIP amount and current NAV.
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-md-2 mt-2">
                      <div className="col-md-5 ">
                        <div className="material">
                          <Form.Label>SIP End Date*</Form.Label>
                          <div
                            className="dt-conbx"
                            style={{
                              borderBottom: "1px solid #dadada",
                              // paddingTop: "19px",
                            }}
                          >
                            <ReactDatePicker
                              select_date={moment(
                                assetsDetails.asset_mf_end_date,
                                "DD/MM/YYYY"
                              ).toDate()}
                              setDate={(date) => {
                                setDate(date, "assetendDate");
                              }}
                              minDate={moment().toDate()}
                              maxDate={""}
                              className="pt-4"
                            />
                          </div>
                          {simpleValidator.current.message(
                            "SIP End Date*",
                            assetsDetails.asset_mf_end_date,
                            "required",
                            {
                              messages: {
                                required: "Please add SIP end date ",
                              },
                            }
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-9">
                        <div className="">
                          <div
                            className="d-md-flex pt-2"
                            style={{ clear: "both" }}
                          >
                            <FintooRadio2
                              checked={assetsDetails.asset_isperpetual == "3"}
                              onClick={() => {
                                setAssetsDetails({
                                  ...assetsDetails,
                                  asset_isperpetual: "3",
                                  asset_mf_end_date: moment(retirementDate)
                                    .add(retirementDate, "y")
                                    .format("DD/MM/YYYY"),
                                });
                              }}
                              title="Upto Retirement Age"
                            />
                            <FintooRadio2
                              checked={assetsDetails.asset_isperpetual == "2"}
                              onClick={() => {
                                setAssetsDetails({
                                  ...assetsDetails,
                                  asset_isperpetual: "2",
                                  asset_mf_end_date: moment(lifeExpectancyDate)
                                    .add(lifeExpectancyDate, "y")
                                    .format("DD/MM/YYYY"),
                                });
                              }}
                              title="Upto Life Expectancy Age"
                            />
                            <FintooRadio2
                              checked={assetsDetails.asset_isperpetual == "1"}
                              onClick={() => {
                                setAssetsDetails({
                                  ...assetsDetails,
                                  asset_isperpetual: "1",
                                  asset_mf_end_date: moment(
                                    "2099-12-31",
                                    "YYYY-MM-DD"
                                  ).format("DD/MM/YYYY"),
                                });
                              }}
                              title="Perpetual"
                            />
                            <span className="info-hover-box">
                              <span className="icon">
                                <img
                                  alt="More information"
                                  src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                />
                              </span>
                              <span className="msg">
                                Perpetual SIPs refer to those with no tenure end
                                date. Most fund houses assume such SIPs to
                                continue till 2099 and it can be only linked to
                                goals after 2099. Advice to select specific end
                                date based on goals
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {assetsDetails.asset_isrecurring == false && (
                  <>
                    <div className="row py-md-2">
                      <div className="col-md-5 mt-md-1 mt-4">
                        <div className="material">
                          <Form.Label>Date of Purchase</Form.Label>
                          <div
                            className="dt-conbx"
                            style={{
                              borderBottom: "1px solid #dadada",
                              paddingTop: "0px",
                            }}
                          >
                            <ReactDatePicker
                              select_date={moment(
                                assetsDetails.asset_purchase_date,
                                "DD/MM/YYYY"
                              ).toDate()}
                              setDate={(date) => {
                                setDate(date, "assetPurchaseDate");
                              }}
                              minDate={""}
                              maxDate={moment().toDate()}
                              className="pt-2"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-5 custom-input">
                        <div
                          className={`form-group w-100 ${assetsDetails.asset_units ? "inputData" : null
                            }`}
                        >
                          <input
                            type="number"
                            name="asset_purchase_amount"
                            value={assetsDetails.asset_units}
                            onChange={(e) => {
                              setAssetsDetails({
                                ...assetsDetails,
                                asset_units: e.target.value,
                              });
                            }}
                            autoComplete="off"
                          />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label for="name">No. Of Units*</label>
                        </div>
                        <div>
                          {simpleValidator.current.message(
                            "Asset Units",
                            assetsDetails.asset_units,
                            "required",
                            {
                              messages: {
                                required: "Please enter asset units",
                              },
                            }
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row py-md-2">
                      <div className="col-md-5 custom-input">
                        <div
                          className={`form-group mt-1 w-100 ${assetsDetails.asset_purchase_amount
                            ? "inputData"
                            : null
                            }`}
                        >
                          <input
                            name="asset_purchase_amount"
                            type="number"
                            value={assetsDetails.asset_purchase_amount}
                            onChange={(e) => {
                              setAssetsDetails({
                                ...assetsDetails,
                                asset_purchase_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                              });
                            }}
                            autoComplete="off"
                          />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label for="name">Avg. buy Price (₹)*</label>
                        </div>
                        <div>
                          {simpleValidator.current.message(
                            "asset_purchase_amount",
                            assetsDetails.asset_purchase_amount,
                            "required",
                            {
                              messages: {
                                required: "Please enter asset purchase amount",
                              },
                            }
                          )}
                        </div>
                      </div>

                      <div className="col-md-5 custom-input">
                        <div
                          className={`form-group mt-1 w-100 ${assetsDetails.totalpurchasevalue
                            ? "inputData"
                            : null
                            }`}
                        >
                          <span>
                            <input
                              name="totalpurchasevalue"
                              type="number"
                              value={assetsDetails.totalpurchasevalue}
                              onChange={(e) => {
                                setAssetsDetails({
                                  ...assetsDetails,
                                  totalpurchasevalue: e.target.value,
                                });
                              }}
                              readOnly
                              autoComplete="off"
                            />
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label for="name">Invested Amount (₹)</label>
                          </span>
                          <span className="info-hover-box">
                            <span className="icon">
                              <img
                                alt="More information"
                                src="https://static.fintoo.in/static/assets/img/more_information.svg"
                              />
                            </span>
                            <span className="msg">
                              Auto Calculated by No Of Units and Avg. Buy Price
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row py-md-2">
                      <div className="col-md-5 custom-input">
                        <div
                          className={`form-group mt-1 w-100 ${assetsDetails.asset_current_unit_price
                            ? "inputData"
                            : null
                            }`}
                        >
                          <input
                            type="text"
                            name="asset_purchase_amount"
                            value={assetsDetails.asset_current_unit_price}
                            onChange={(e) => {
                              setAssetsDetails({
                                ...assetsDetails,
                                asset_current_unit_price: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                              });
                            }}
                            readOnly
                            autoComplete="off"
                          />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label for="name">Current Price (₹)*</label>
                        </div>
                        <div>
                          {simpleValidator.current.message(
                            "Asset Current Price",
                            assetsDetails.asset_current_unit_price,
                            "required",
                            {
                              messages: {
                                required: "Please enter asset current price",
                              },
                            }
                          )}
                        </div>
                      </div>
                      <div className="col-md-5 custom-input">
                        <div
                          className={`form-group mt-1 w-100 ${assetsDetails.totalinvestedvalue
                            ? "inputData"
                            : null
                            }`}
                        >
                          <span>
                            <input
                              type="number"
                              name="asset_purchase_amount"
                              value={assetsDetails.totalinvestedvalue}
                              onChange={(e) => {
                                setAssetsDetails({
                                  ...assetsDetails,
                                  totalinvestedvalue: e.target.value,
                                });
                              }}
                              readOnly
                              autoComplete="off"
                            />
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label for="name">Current Value (₹)</label>
                          </span>
                          <span className="info-hover-box">
                            <span className="icon">
                              <img
                                alt="More information"
                                src="https://static.fintoo.in/static/assets/img/more_information.svg"
                              />
                            </span>
                            <span className="msg">
                              Auto Calculated by No Of Units and Current Price
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="row py-md-2 mt-md-4 mt-3">
                  <div className="col-md-8">
                    <div className="d-md-flex">
                      <Form.Label className=" ">
                        Consider This Asset In Goal Linkage*
                      </Form.Label>
                      <span className="info-hover-left-box ms-md-4">
                        <span Name="icon">
                          <img
                            alt="More information"
                            src="https://static.fintoo.in/static/assets/img/more_information.svg"
                          />
                        </span>
                        <span className="msg">
                          Select a goal below to map this investment with a goal
                          of your choice. otherwise, fintooinvest will link it
                          automatically with your high priority goal. In case,
                          you do not wish to utilize this investment for any
                          goal, select "NO".
                        </span>
                      </span>
                      <div className="d-flex ms-md-4">
                        <div>No</div>
                        <Switch
                          onChange={(v) =>
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_islinkable: v,
                            })
                          }
                          checked={assetsDetails.asset_islinkable}
                          className="react-switch px-2"
                          activeBoxShadow="0 0 2px 3px #424242"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={40}
                          onColor="#042b62"
                         offColor="#d8dae5"
                        />
                        <div>Yes</div>
                      </div>
                    </div>
                  </div>
                </div>
                {assetsDetails.asset_islinkable == true && (
                  <>
                    <div className="row py-md-2">
                      <div className="col-md-8 mt-md-2 mt-3">
                        <div className="d-md-flex">
                          <Form.Label className="link_asset_style">
                            Link This Investment Asset to Goal
                          </Form.Label>{" "}
                          <span
                            className="ms-md-4 info-hover-left-box float-right"
                            style={{
                              position: "relative !important",
                            }}
                          >
                            <span className="icon">
                              <img
                                alt="More information"
                                src="https://static.fintoo.in/static/assets/img/more_information.svg"
                              />
                            </span>
                            <span className="msg">
                              You can only assign goals which are prior to the
                              end date of the SIP, if any
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mt-md-2 mt-3">
                        <div className="material">
                          <div
                            className="m-0 btn-sm default-btn gradient-btn save-btn"
                            onClick={() => setGoalSelected(true)}
                          >
                            Select Goals
                          </div>
                          <br></br>
                          <br></br>

                          {selectedGoals ? (
                            <div
                              className="d-flex"
                              style={{ textAlign: "left!important" }}
                            >
                              <div>
                                <b>Selected Goals : </b>
                              </div>
                              <div className="ms-1">{selectedGoals}</div>
                            </div>
                          ) : (
                            ""
                          )}
                          {isGoalSelected ? (
                            <GoalsDropdown
                              setGoalSelected={setGoalSelected}
                              goals={goalData}
                              unchangedgoaldata={unchangedgoaldata}
                              closeModal={closeModal}
                              selectGoals={selectGoals}
                              selectedGoals={selectedGoals}
                              selectedGoalIdArray={selectedGoalIdArray}
                              selectedGoalsId={selectedGoalsId}
                              setPriorityArray={setPriorityArray}
                              selectedPriorityArray={selectedPriorityArray}
                              setAutoMatedGoal={setAutoMatedGoal}
                              isAutoMatedGoal={isAutoMatedGoal}
                              setGoalLink={setGoalLink}
                              type={"Asset"}
                              asset_maturity_date={
                                assetsDetails?.asset_mf_end_date
                              }
                              isGoalFilter={
                                assetsDetails.asset_isrecurring == "1"
                                  ? "1"
                                  : "0"
                              }
                              isAssetRecurring={
                                assetsDetails.asset_isrecurring == "1"
                                  ? "1"
                                  : "0"
                              }
                            ></GoalsDropdown>
                          ) : (
                            ""
                          )}
                          <div className=""></div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="row py-2">
                  <div className=" text-center">
                    <div>
                      <div className="btn-container">
                        <div className="d-flex justify-content-center">
                          <a
                            href={
                              process.env.PUBLIC_URL + "/datagathering/goals"
                            }
                          >
                            <div className="previous-btn form-arrow d-flex align-items-center">
                              <FaArrowLeft />
                              <span className="hover-text">&nbsp;Previous</span>
                            </div>
                          </a>

                          {addForm && (
                            <button
                              onClick={(e) => handleLiquidSubmit(e)}
                              className="default-btn gradient-btn save-btn"
                            >
                              Save & Add More
                            </button>
                          )}
                          {updateForm && (
                            <div>
                              <button
                                onClick={(e) => handleLiquidCancel(e)}
                                className="default-btn gradient-btn save-btn"
                              >
                                Cancel
                              </button>
                              <button
                                onClick={(e) => handleLiquidUpdate(e)}
                                className="default-btn gradient-btn save-btn"
                              >
                                Update
                              </button>
                            </div>
                          )}

                          <div
                            className="next-btn form-arrow d-flex align-items-center"
                            onClick={() => props.setTab("tab2")}
                          >
                            <span
                              className="hover-text"
                              style={{ maxWidth: 100 }}
                            >
                              Continue&nbsp;
                            </span>
                            <FaArrowRight />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          {assetsDetails.asset_sub_category_id == 62 &&
            selectedExtraOption == "Link your Holdings" && (
              <LinkYourHoldingsDG
                CustomStyles={CustomStyles}
                session={session}
              />
            )}
        </div>
      )}
      {props.assetEditId && assetsDetails.asset_sub_category_id == 62 && (
        <div>
          <form noValidate="novalidate" name="goldassetform">
            <div className="row py-md-2">
              <div className="col-md-5">
                <div className="material">
                  <Form.Label>
                    Start Typing To Search For Your Liquid Funds*
                  </Form.Label>

                  {props.liquidfunds && props.liquidfunds.length > 0 && (
                    <Select
                      classNamePrefix="sortSelect"
                      isDisabled={
                        (assetsDetails?.asset_ecas_type ?? "").toLowerCase() ==
                        "ecas"
                      }
                      autoComplete="new-password"
                      isSearchable={true}
                      styles={CustomStyles}
                      options={liquidfundsData}
                      onChange={(e) => handleLiquidFundSelection(e)}
                      value={liquidfundsData.filter(
                        (v) => v.label == assetsDetails.asset_name
                      )}
                    />
                  )}
                </div>
                {simpleValidator.current.message(
                  "Select",
                  assetsDetails.asset_name,
                  "required",
                  { messages: { required: "Please select one liquid fund" } }
                )}
              </div>
              <div className="col-md-5 mt-md-0 mt-4">
                <div
                  className="material"
                  style={{
                    paddingTop: "5px",
                  }}
                >
                  <Form.Label>Who Is This Investment For*</Form.Label>
                  {familyData && (
                    <Select
                      classNamePrefix="sortSelect"
                      isDisabled={
                        (assetsDetails?.asset_ecas_type ?? "").toLowerCase() ==
                        "ecas"
                      }
                      isSearchable={false}
                      styles={CustomStyles}
                      options={familyData}
                      onChange={(e) =>
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_member_id: e.value,
                        })
                      }
                      value={familyData.filter(
                        (v) => v.value == assetsDetails.asset_member_id
                      )}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="row py-md-2 mt-4">
              <div className="col-md-8">
                <div className="d-md-flex">
                  <Form.Label className=" ">
                    Is The Equity One Time Or Recurring?*
                  </Form.Label>
                  <div className="d-flex ms-md-4">
                    <div>One Time</div>
                    <Switch
                      onChange={(v) => {
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_isrecurring: v,
                          asset_purchase_amount: " ",
                        });
                      }}
                      disabled={
                        (assetsDetails?.asset_ecas_type ?? "").toLowerCase() ==
                        "ecas"
                      }
                      checked={assetsDetails.asset_isrecurring}
                      className="react-switch px-2"
                      activeBoxShadow="0 0 2px 3px #424242"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                      onColor="#042b62"
                     offColor="#d8dae5"
                    />
                    <div>Recurring</div>
                  </div>
                </div>
              </div>
            </div>

            {assetsDetails.asset_isrecurring == true && (
              <>
                <div className="row d-flex align-items-center py-md-2">
                  <div className="col-md-5 mt-md-0 mt-4">
                    <div className="material">
                      <Form.Label>SIP Start Date</Form.Label>
                      <div
                        className="dt-conbx"
                        style={{
                          borderBottom: "1px solid #dadada",
                          paddingTop: "0px",
                        }}
                      >
                        <ReactDatePicker
                          select_date={moment(
                            assetsDetails.asset_purchase_date,
                            "DD/MM/YYYY"
                          ).toDate()}
                          readOnly={
                            (
                              assetsDetails?.asset_ecas_type ?? ""
                            ).toLowerCase() == "ecas"
                          }
                          setDate={(date) => {
                            setDate(date, "assetPurchaseDate");
                          }}
                          minDate={""}
                          maxDate={moment().toDate()}
                          className="pt-4"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 mt-md-0 mt-3">
                    <div className="material">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="SIP Amount (₹)*"
                        className="mb-3 material"
                      >
                        <Form.Control
                          type="Number"
                          readOnly={
                            (
                              assetsDetails?.asset_ecas_type ?? ""
                            ).toLowerCase() == "ecas"
                          }
                          placeholder="*"
                          className="shadow-none"
                          value={assetsDetails.asset_amount}
                          onChange={(e) => {
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                            });
                          }}
                        />
                      </FloatingLabel>

                      {simpleValidator.current.message(
                        "SIP Amount",
                        assetsDetails.asset_amount,
                        "required",
                        { messages: { required: "Please enter SIP Amount" } }
                      )}
                    </div>
                  </div>
                </div>
                <div className="row py-md-2">
                  <div className="col-md-5">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Total Invested Amount (₹)*"
                      className="mb-3 material"
                    >
                      <Form.Control
                        type="number"
                        placeholder="*"
                        readOnly={
                          (
                            assetsDetails?.asset_ecas_type ?? ""
                          ).toLowerCase() == "ecas"
                        }
                        className="shadow-none"
                        value={assetsDetails.asset_purchase_amount}
                        onChange={(e) => {
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_purchase_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                          });
                        }}
                      />
                    </FloatingLabel>
                    {simpleValidator.current.message(
                      "Asset Purchase Amount",
                      assetsDetails.asset_purchase_amount,
                      "required",
                      {
                        messages: {
                          required: "Please enter asset purchase amount",
                        },
                      }
                    )}
                  </div>
                  <div className="col-md-5">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="No. Of Units*"
                      className="mb-3 material"
                    >
                      <Form.Control
                        type="number"
                        readOnly={
                          (
                            assetsDetails?.asset_ecas_type ?? ""
                          ).toLowerCase() == "ecas"
                        }
                        placeholder="*"
                        className="shadow-none"
                        value={assetsDetails.asset_units}
                        onChange={(e) => {
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_units: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                          });
                        }}
                      />
                    </FloatingLabel>
                    {simpleValidator.current.message(
                      "Asset Units",
                      assetsDetails.asset_units,
                      "required",
                      { messages: { required: "Please enter asset units" } }
                    )}
                  </div>
                </div>
                <div className="row py-md-2">
                  <div className="col-md-5">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Current Price (₹)*"
                      className="mb-3 material"
                    >
                      <Form.Control
                        type="number"
                        placeholder="*"
                        className="shadow-none"
                        value={assetsDetails.asset_current_unit_price}
                        onChange={(e) => {
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_current_unit_price: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                          });
                        }}
                      />
                    </FloatingLabel>
                    {simpleValidator.current.message(
                      "Asset Current Price",
                      assetsDetails.asset_current_unit_price,
                      "required",
                      {
                        messages: {
                          required: "Please enter asset current gold price",
                        },
                      }
                    )}
                  </div>
                  <div className="col-md-5  ">
                    <div className=" d-flex justify-content-between flex-grow-1">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Current value (₹)"
                        className="mb-3 material d-flex"
                      >
                        <Form.Control
                          type="number"
                          placeholder="Current value (₹)"
                          value={assetsDetails.totalinvestedvalue}
                          onChange={(e) => {
                            setAssetsDetails({
                              ...assetsDetails,
                              totalinvestedvalue: e.target.value,
                            });
                          }}
                          readOnly
                        />
                        <span
                          className="info-hover-box float-right"
                          style={{
                            position: "relative !important",
                          }}
                        >
                          <span className="icon">
                            <img
                              alt="More information"
                              src="https://static.fintoo.in/static/assets/img/more_information.svg"
                            />
                          </span>
                          <span className="msg">
                            Auto calculated by SIP amount and current NAV.
                          </span>
                        </span>
                      </FloatingLabel>
                    </div>
                  </div>
                </div>
                <div className="row py-md-2">
                  <div className="col-md-5 ">
                    <div className="material">
                      <Form.Label>SIP End Date*</Form.Label>
                      <div
                        className="dt-conbx"
                        style={{
                          borderBottom: "1px solid #dadada",
                          // paddingTop: "19px",
                        }}
                      >
                        <ReactDatePicker
                          select_date={moment(
                            assetsDetails.asset_mf_end_date,
                            "DD/MM/YYYY"
                          ).toDate()}
                          readOnly={
                            (
                              assetsDetails?.asset_ecas_type ?? ""
                            ).toLowerCase() == "ecas"
                          }
                          setDate={(date) => {
                            setDate(date, "assetendDate");
                          }}
                          minDate={moment().toDate()}
                          maxDate={""}
                          className="pt-4"
                        />
                      </div>
                      {simpleValidator.current.message(
                        "SIP End Date*",
                        assetsDetails.asset_mf_end_date,
                        "required",
                        { messages: { required: "Please add SIP end date " } }
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-9">
                    <div className="">
                      <div className="d-flex pt-2" style={{ clear: "both" }}>
                        <FintooRadio2
                          checked={assetsDetails.asset_isperpetual == "3"}
                          readOnly={
                            (
                              assetsDetails?.asset_ecas_type ?? ""
                            ).toLowerCase() == "ecas"
                          }
                          onClick={() => {
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_isperpetual: "3",
                              asset_mf_end_date: moment(retirementDate)
                                .add(retirementDate, "y")
                                .format("DD/MM/YYYY"),
                            });
                          }}
                          title="Upto Retirement Age"
                        />
                        <FintooRadio2
                          checked={assetsDetails.asset_isperpetual == "2"}
                          readOnly={
                            (
                              assetsDetails?.asset_ecas_type ?? ""
                            ).toLowerCase() == "ecas"
                          }
                          onClick={() => {
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_isperpetual: "2",
                              asset_mf_end_date: moment(lifeExpectancyDate)
                                .add(lifeExpectancyDate, "y")
                                .format("DD/MM/YYYY"),
                            });
                          }}
                          title="Upto Life Expectancy Age"
                        />
                        <FintooRadio2
                          checked={assetsDetails.asset_isperpetual == "1"}
                          readOnly={
                            (
                              assetsDetails?.asset_ecas_type ?? ""
                            ).toLowerCase() == "ecas"
                          }
                          onClick={() => {
                            setAssetsDetails({
                              ...assetsDetails,
                              asset_isperpetual: "1",
                              asset_mf_end_date: moment(
                                "2099-12-31",
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY"),
                            });
                          }}
                          title="Perpetual"
                        />
                        <span className="info-hover-box">
                          <span className="icon">
                            <img
                              alt="More information"
                              src="https://static.fintoo.in/static/assets/img/more_information.svg"
                            />
                          </span>
                          <span className="msg">
                            Perpetual SIPs refer to those with no tenure end
                            date. Most fund houses assume such SIPs to continue
                            till 2099 and it can be only linked to goals after
                            2099. Advice to select specific end date based on
                            goals
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {assetsDetails.asset_isrecurring == false && (
              <>
                <div className="row py-md-2">
                  <div className="col-md-5 mt-md-0 mt-3 ">
                    <div className="material">
                      <Form.Label>Date of Purchase</Form.Label>
                      <div
                        className="dt-conbx"
                        style={{
                          borderBottom: "1px solid #dadada",
                          paddingTop: "0px",
                        }}
                      >
                        <ReactDatePicker
                          select_date={moment(
                            assetsDetails.asset_purchase_date,
                            "DD/MM/YYYY"
                          ).toDate()}
                          readOnly={
                            (
                              assetsDetails?.asset_ecas_type ?? ""
                            ).toLowerCase() == "ecas"
                          }
                          setDate={(date) => {
                            setDate(date, "assetPurchaseDate");
                          }}
                          minDate={""}
                          maxDate={moment().toDate()}
                          className="pt-2"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-5 ">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="No. of Units*"
                      className="mb-3 material"
                    >
                      <Form.Control
                        placeholder="No. of Units*"
                        className="shadow-none"
                        readOnly={
                          (
                            assetsDetails?.asset_ecas_type ?? ""
                          ).toLowerCase() == "ecas"
                        }
                        type="number"
                        value={assetsDetails.asset_units}
                        onChange={(e) => {
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_units: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                          });
                        }}
                      />
                    </FloatingLabel>
                    {simpleValidator.current.message(
                      "Asset Units",
                      assetsDetails.asset_units,
                      "required",
                      { messages: { required: "Please enter asset units" } }
                    )}
                  </div>
                </div>

                <div className="row py-md-2">
                  <div className="col-md-5 ">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Avg. buy Price (₹)*"
                      className="mb-3 material"
                    >
                      <Form.Control
                        placeholder="Avg. buy Price (₹)"
                        disabled={
                          (
                            assetsDetails?.asset_ecas_type ?? ""
                          ).toLowerCase() == "ecas"
                        }
                        className="shadow-none"
                        type="number"
                        name="asset_purchase_amount"
                        value={assetsDetails.asset_purchase_amount}
                        onChange={(e) => {
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_purchase_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                          });
                        }}
                      />
                    </FloatingLabel>
                    {simpleValidator.current.message(
                      "asset_purchase_amount",
                      assetsDetails.asset_purchase_amount,
                      "required",
                      {
                        messages: {
                          required: "Please enter asset purchase amount",
                        },
                      }
                    )}
                  </div>

                  <div className="col-md-5 ">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Invested Amount (₹)"
                      className="mb-3 material d-flex"
                    >
                      <Form.Control
                        placeholder="Invested Amount (₹)"
                        className="shadow-none"
                        type="number"
                        value={assetsDetails.totalpurchasevalue}
                        onChange={(e) => {
                          setAssetsDetails({
                            ...assetsDetails,
                            totalpurchasevalue: e.target.value,
                          });
                        }}
                        readOnly
                      />
                      <span className="info-hover-box">
                        <span className="icon">
                          <img
                            alt="More information"
                            src="https://static.fintoo.in/static/assets/img/more_information.svg"
                          />
                        </span>
                        <span className="msg">
                          Auto Calculated by No Of Units and Avg. Buy Price
                        </span>
                      </span>
                    </FloatingLabel>
                  </div>
                </div>

                <div className="row py-md-2">
                  <div className="col-md-5 ">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Current Price (₹)*"
                      className="mb-3 material"
                    >
                      <Form.Control
                        placeholder="Current Price (₹)*"
                        className="shadow-none"
                        type="number"
                        value={assetsDetails.asset_current_unit_price}
                        onChange={(e) => {
                          setAssetsDetails({
                            ...assetsDetails,
                            asset_current_unit_price: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                          });
                        }}
                        readOnly
                      />
                    </FloatingLabel>
                    {simpleValidator.current.message(
                      "Asset Current Price",
                      assetsDetails.asset_current_unit_price,
                      "required",
                      {
                        messages: {
                          required: "Please enter asset current price",
                        },
                      }
                    )}
                  </div>
                  <div className="col-md-5 ">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Current Value (₹)"
                      className="mb-3 material d-flex"
                    >
                      <Form.Control
                        placeholder="Current Value (₹)"
                        className="shadow-none"
                        type="number"
                        value={assetsDetails.totalinvestedvalue}
                        onChange={(e) => {
                          setAssetsDetails({
                            ...assetsDetails,
                            totalinvestedvalue: e.target.value,
                          });
                        }}
                        readOnly
                      />
                      <span className="info-hover-box">
                        <span className="icon">
                          <img
                            alt="More information"
                            src="https://static.fintoo.in/static/assets/img/more_information.svg"
                          />
                        </span>
                        <span className="msg">
                          Auto Calculated by No Of Units and Current Price
                        </span>
                      </span>
                    </FloatingLabel>
                  </div>
                </div>
              </>
            )}

            <div className="row py-md-2 mt-md-4">
              <div className="col-md-8">
                <div className="d-md-flex">
                  <Form.Label className=" ">
                    Consider This Asset In Goal Linkage*
                  </Form.Label>
                  <span className="info-hover-left-box ms-md-4">
                    <span Name="icon">
                      <img
                        alt="More information"
                        src="https://static.fintoo.in/static/assets/img/more_information.svg"
                      />
                    </span>
                    <span className="msg">
                      Select a goal below to map this investment with a goal of
                      your choice. otherwise, fintooinvest will link it automatically
                      with your high priority goal. In case, you do not wish to
                      utilize this investment for any goal, select "NO".
                    </span>
                  </span>
                  <div className="d-flex ms-md-4">
                    <div>No</div>
                    <Switch
                      onChange={(v) =>
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_islinkable: v,
                        })
                      }
                      checked={assetsDetails.asset_islinkable}
                      className="react-switch px-2"
                      activeBoxShadow="0 0 2px 3px #424242"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                      onColor="#042b62"
                     offColor="#d8dae5"
                    />
                    <div>Yes</div>
                  </div>
                </div>
              </div>
            </div>
            {assetsDetails.asset_islinkable == true && (
              <>
                <div className="row py-md-2">
                  <div className="col-md-8 mt-md-2 mt-3">
                    <div className="d-md-flex">
                      <Form.Label className="link_asset_style">
                        Link This Investment Asset to Goal
                      </Form.Label>{" "}
                      <span
                        className="ms-md-4 info-hover-left-box float-right"
                        style={{
                          position: "relative !important",
                        }}
                      >
                        <span className="icon">
                          <img
                            alt="More information"
                            src="https://static.fintoo.in/static/assets/img/more_information.svg"
                          />
                        </span>
                        <span className="msg">
                          You can only assign goals which are prior to the end
                          date of the asset
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-md-2 mt-5">
                    <div className="material">
                      <div
                        className="m-0 btn-sm default-btn gradient-btn save-btn"
                        onClick={() => setGoalSelected(true)}
                      >
                        Select Goals
                      </div>
                      <br></br>
                      <br></br>

                      {selectedGoals ? (
                        <div
                          className="d-flex"
                          style={{ textAlign: "left!important" }}
                        >
                          <div>
                            <b>Selected Goals : </b>
                          </div>
                          <div className="ms-1">{selectedGoals}</div>
                        </div>
                      ) : (
                        ""
                      )}
                      {isGoalSelected ? (
                        <GoalsDropdown
                          setGoalSelected={setGoalSelected}
                          goals={goalData}
                          unchangedgoaldata={unchangedgoaldata}
                          closeModal={closeModal}
                          selectGoals={selectGoals}
                          selectedGoals={selectedGoals}
                          selectedGoalIdArray={selectedGoalIdArray}
                          selectedGoalsId={selectedGoalsId}
                          setPriorityArray={setPriorityArray}
                          selectedPriorityArray={selectedPriorityArray}
                          setAutoMatedGoal={setAutoMatedGoal}
                          isAutoMatedGoal={isAutoMatedGoal}
                          setGoalLink={setGoalLink}
                          type={"Asset"}
                          asset_maturity_date={assetsDetails?.asset_mf_end_date}
                          isGoalFilter={
                            assetsDetails.asset_isrecurring == "1" ? "1" : "0"
                          }
                          isAssetRecurring={
                            assetsDetails.asset_isrecurring == "1" ? "1" : "0"
                          }
                        ></GoalsDropdown>
                      ) : (
                        ""
                      )}
                      <div className=""></div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="row py-2">
              <div className=" text-center">
                <div>
                  <div className="btn-container">
                    <div className="d-flex justify-content-center">
                      <Link
                        to={process.env.PUBLIC_URL + "/datagathering/insurance"}
                      >
                        <div
                          className="previous-btn form-arrow d-flex align-items-center"
                          onClick={() => setTab("tab1")}
                        >
                          <FaArrowLeft />
                          <span className="hover-text">&nbsp;Previous</span>
                        </div>
                      </Link>

                      {addForm && (
                        <button
                          onClick={(e) => handleLiquidSubmit(e)}
                          className="default-btn gradient-btn save-btn"
                        >
                          Save & Add More
                        </button>
                      )}
                      {updateForm && (
                        <div>
                          <button
                            onClick={(e) => handleLiquidCancel(e)}
                            className="default-btn gradient-btn save-btn"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={(e) => handleLiquidUpdate(e)}
                            className="default-btn gradient-btn save-btn"
                          >
                            Update
                          </button>
                        </div>
                      )}
                      <Link
                        to={process.env.PUBLIC_URL + "/datagathering/insurance"}
                      >
                        <div className="next-btn form-arrow d-flex align-items-center">
                          <span
                            className="hover-text"
                            style={{ maxWidth: 100 }}
                          >
                            Continue&nbsp;
                          </span>
                          <FaArrowRight />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}

      {/* For Link Account */}

      <Modal
        dialogClassName="Nsdlcsdl-modal-width"
        show={openModalByName == "Link_Account_Bank"}
        centered
        animationDuration={0}
      >
        <div className="" style={{ padding: "0 !important" }}>
          <div className="">
            <div className="RefreshModalpopup_Heading col-12 d-flex ">
              <div className={`${Styles.modal_Heading}`}>
                Link Your Bank Account
              </div>
              <div className={`${Styles.CloseBtnpopup}`}>
                <img
                  onClick={() => {
                    handleCloseModal();
                  }}
                  style={{ cursor: "pointer", right: 0 }}
                  src={process.env.REACT_APP_STATIC_URL + "media/DG/Close.svg"}
                  alt="Close"
                />
              </div>
            </div>
            <div className={`modalBody ${Styles.DematmodalBody}`}>
              <div
                className={`${Styles.LeftSection}  ${Styles.LiquidLeftSection}`}
              >
                <div>
                  To retrieve your bank balance, please enter your details:
                </div>
                <div className="mt-3">
                  <div className={`${Styles.title}`}>Member</div>
                  <div className="mt-2">
                    <Select
                      classNamePrefix="sortSelect"
                      placeholder="Select Member"
                      isSearchable={false}
                      styles={CustomStyles}
                      options={allMembers}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={allMembers?.filter(
                        (v) => v.id == selectedMember.id
                      )}
                    />
                  </div>
                  <div className="mt-3">
                    <div className={`${Styles.title}`}>Mobile Number</div>
                    <div className="mt-2">
                      <input
                        className={`${Styles.inputField}`}
                        type="number"
                        placeholder="Enter Your Mobile Number"
                        value={selectedMember.mobile ?? ""}
                        onChange={(e) => handleMobileChange(e)}
                      />
                      {errors.userMobile && (
                        <p className="error">{errors.userMobile}</p>
                      )}
                    </div>
                    <div className="mt-3">
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          name=""
                          tabIndex="1"
                          className={`custom-checkbox ${Styles.inputCheckBox}`}
                          id="terms"
                          checked={isTermsChecked}
                          onChange={(e) => {
                            handleCheckboxClick(e);
                          }}
                        />
                        <label
                          htmlFor="terms"
                          style={{
                            paddingTop: "2px",
                            fontSize: "15px",
                            cursor: "pointer",
                            paddingLeft: "8px",
                          }}
                        >
                          Accept &nbsp;
                          <a
                            className={`${Styles.LinkTerms}`}
                            style={{
                              fontWeight: "500",
                            }}
                            href="https://finvu.in/terms" // Replace with the URL you want to link to
                            target="_blank"
                          >
                            Terms & Conditions
                          </a>
                        </label>
                      </div>
                      <div className="error">{termsError}</div>
                    </div>
                  </div>
                  <div className="ButtonBx d-flex justify-content-center">
                    <button
                      type="button"
                      className="Unlink"
                      onClick={(e) => {
                        handleLinkAccountClick(e);
                      }}
                    >
                      Link Account
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={`d-md-block d-none ${Styles.RightSection} ${Styles.LiquidRightSection}`}
              >
                <center>
                  <img
                    src={
                      process.env.REACT_APP_STATIC_URL +
                      "media/DG/BankLayout.png"
                    }
                    alt="Bank"
                  />
                </center>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Consent Under Process */}
      <Modal
        centered
        backdrop="static"
        show={openModalByName == "ApprovedConsent"}
        onHide={() => {
          setOpenModalByName("ApprovedConsent");
        }}
      >
        <div className={`${Bankbalance.ApprovedConsent}`}>
          <div className={`text-center ${Bankbalance.modalHeader}`}>
            Consent Under Process
          </div>
          <div className={`${Bankbalance.ApprovedConsentData}`}>
            { }
            <div>
              <img
                src={
                  process.env.REACT_APP_STATIC_URL +
                  "media/DG/ConsentDenied.png"
                }
              />
            </div>
            <div className={`${Bankbalance.ApprovedConsentcontent}`}>
              It is taking longer than usual to fetch your data. We will notify
              you once your consent is approved by the bank.
            </div>
          </div>
          <div className={`mt-4 ${Bankbalance.alternateOption}`}>
            <button
              style={{ padding: "10px 64px" }}
              onClick={() => {
                setOpenModalByName("");
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>

      <Modal className="popupmodal" centered show={show} onHide={handleClose}>
        <Modal.Header className="ModalHead">
          <div className="text-center">Delete Confirmation</div>
        </Modal.Header>
        <div className=" p-5 d-grid place-items-center align-item-center">
          <div className=" HeaderModal">
            <div
              style={{
                fontSize: "1rem",
                textAlign: "center",
              }}
            >
              This will permanently erase the record and its associated
              information.
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center pb-5">
          <button
            onClick={() => {
              handleClose("yes");
            }}
            className="outline-btn m-2"
          >
            Yes
          </button>
          <button
            onClick={() => {
              handleClose("no");
            }}
            className="outline-btn m-2"
          >
            No
          </button>
        </div>
      </Modal>
      <Modal
        classNames={{
          modal: "RefreshModalpopup",
        }}
        show={showuniqueUANModal}
        showCloseIcon={false}
        onClose={() => () => { }}
        centered
        animationDuration={0}
      >
        <Uniquepannotfoundmodal showuniqueUANModalclose={showuniqueUANModalclose} pannumbers={pannumbers} familyecas={familyecas} familyData={familyData} memberdataid={memberdataid} session={session} />
      </Modal>
    </div>
  );
};

export default Liquid;
