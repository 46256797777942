import React from 'react';
import styles from './style.module.css';
import Chart from 'react-apexcharts';
import { indianRupeeFormat } from '../../../../common_utilities';
const InvestmentSummary = ({ investedValue, estimatedReturns, totalValue }) => {
    const chartOptions = {
        chart: {
            type: 'donut',
        },
        colors: ['#042b62', '#042b629c'],
        labels : ['Invested Value', 'Est Returns'],
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
            formatter: function (val, opts) {
                return opts.w.globals.labels[opts.seriesIndex] + ": ₹" + val;
            }
        },
        tooltip: {
            y: {
                formatter: (value) => `₹ ${value}`,
            },
        },
    };

    const chartSeries = [parseFloat(investedValue), parseFloat(estimatedReturns)];

    return (
        <div className={styles.summaryContainer}>
            <div>
                <h2>Total Value</h2>
                <h3 className={styles.totalValue}>₹ {`${indianRupeeFormat(totalValue)}`}</h3>
                {/* <p>Tax Included</p> */}
            </div>
            <div className={styles.donutContainer}>
                <Chart options={chartOptions} series={chartSeries} type="donut" width="280" />
            </div>
            <div className={styles.valueBreakdown}>
                <div>
                    <p> <span style={{backgroundColor : "#042b62"}}  className={styles.roundedCircle}></span> Invested Value</p>
                    <div className={`${styles.investedValue}`}> ₹ {
                        indianRupeeFormat(
                            investedValue)}</div>
                </div>
                <div>
                    <p> <span style={{backgroundColor : "#042b629c"}} className={styles.roundedCircle}></span> Est Returns</p>
                    <div className={`${styles.estimatedReturns}`}> ₹ {indianRupeeFormat(estimatedReturns)}</div>
                </div>
            </div>
        </div>
    );
};

export default InvestmentSummary;
