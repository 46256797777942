import styles from "./style.module.css";
import headerImg from "./assets/taxPlanningSectionImg.png";
import { IoMdVideocam } from 'react-icons/io';
import { FiCheckCircle } from 'react-icons/fi';
function ITRFileHeaderSection() {
  return (
    <>
      <section className={`${styles["header-section"]} `}>
        <div className={`${styles["header-section-background-overlay"]}`}></div>
        <div className={`${styles["header-section-container"]}`}>
          <div className={`${styles["header-section-content"]}`}>
            <h2 className={`${styles["header-section-title"]}`}>
            File ITR With The TaXpert!
            </h2>
            <br />
            <a href="#ITRVideoSection" className={`text-decoration-none ${styles.liveItrLink}`}>
                <span className={`${styles.liveItrLinkicon}`}><IoMdVideocam /> </span> <span className={`${styles.DiffText}`}>LIVE</span> ITR Filing with video call option
            </a>
            <div className={`${styles.ItrFiletxt}`}>
                <div className={`${styles.text}`}>
                  <span><FiCheckCircle/> </span> LIVE ITR Filing on your screen.
                </div>
                <div className={`${styles.text}`}>
                  <span><FiCheckCircle/> </span> Consult with our Chartered Accountant on tax planning and investments during the 60 mins LIVE video call.
                </div>
                <div className={`${styles.text}`}>
                  <span><FiCheckCircle/> </span> Accurate. Transparent. Realtime Assistance.
                </div>
                <div className={`${styles.text}`}>
                  <span><FiCheckCircle/> </span> ITR Filing is now easy and convenient.
                </div>
                <div className={`${styles.text}`}>
                  <span><FiCheckCircle/> </span> Choose The Plan According To Your Source Of Income, Residential Status, And Requirements.
                </div>
            </div>
            {/* <a href="#GetStarted" className="text-decoration-none">
              <button
                className={`${styles["header-section-content-btn"]} ${styles["animatedBouncInUp"]} ${styles["bounceInUp"]}`}
              >
                GET STARTED
              </button>
            </a> */}
          </div>
          <div className={`${styles["header-section-image"]}`}>
            <div
              className={`${styles["header-section-image-container"]} ${styles["animated"]} ${styles["animatedFadeInUp"]} ${styles["fadeInUp"]}  `}
            >
               <img className="img-fluid" src={process.env.REACT_APP_STATIC_URL + 'media/wp/TPSection.png'} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ITRFileHeaderSection;
