import React, { useState, useEffect, useRef } from "react";
import ExpertRmSlider from "react-elastic-carousel";
import Slider from "react-slick";
import Styles from "./Style.module.css";
import { FaStar } from "react-icons/fa";
import LeftArrow from "./Images/left_arrow.png";
import RightArrow from "./Images/right_arrow.png";
import { ADVISORY_GET_RM_LIST_API } from "../../../constants";
import { fetchData } from "../../../common_utilities";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
// import Img from /

const ExpertItem = (props) => (
  <div className={`d-flex ${Styles.RmBox}`} onClick={() => props.onClick()}>
    <div>
      <img src={"https://images.minty.co.in/static/" + props.data.imagepath} />
    </div>
    <div>
      <div className={`ms-2 ${Styles.rminfo}`}>
        <div className={`${Styles.rmName}`}>{props.data.emp_name}</div>
        <div style={{ color: "#858487" }} className={`${Styles.rmPersonal}`}>
          {" "}
          <span className={`${Styles.rmProfile}`}>
            {props.data.emp_position}
          </span>{" "}
          |{" "}
          <span className={`${Styles.rmQualification}`}>
            {props.data.emp_qualification}
          </span>
        </div>
        <div className="d-flex  mt-3">
          <div className={`${Styles.BorderRight}`}>
            <div className={`${Styles.textLabel}`}>Experience</div>
            <div className={`${Styles.ValueLabel}`}>
              {props.data.emp_experience}
              <span className="ps-1">Years</span>
            </div>
          </div>
          <div className={`ms-2 ${Styles.BorderRight}`}>
            <div className={`${Styles.textLabel}`}>Location</div>
            <div className={`${Styles.ValueLabel}`}>
              {props.data.emp_location}
            </div>
          </div>
          <div className="Rating ms-2">
            <div className={`${Styles.textLabel}`}>Rating</div>
            <div ng-if="rm.rating == 4" className="ng-scope">
              <span
                className={`fa fa-star`}
                style={{
                  color: `${props.data.rating - 1 < 0 ? "gray" : "orange"}`,
                }}
              />
              <span
                className={`fa fa-star`}
                style={{
                  color: `${props.data.rating - 2 < 0 ? "gray" : "orange"}`,
                }}
              />
              <span
                className={`fa fa-star`}
                style={{
                  color: `${props.data.rating - 3 < 0 ? "gray" : "orange"}`,
                }}
              />
              <span
                className={`fa fa-star`}
                style={{
                  color: `${props.data.rating - 4 < 0 ? "gray" : "orange"}`,
                }}
              />
              <span
                className={`fa fa-star`}
                style={{
                  color: `${props.data.rating - 5 < 0 ? "gray" : "orange"}`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
function Expertrm({ searchTerm, filters }) {
  const [expertRmData, setExpertRmData] = useState();
  const allDataRef = useRef([]);
  const [slide, setSlide] = useState(null);

  const selectedRM = useSelector((state) => state.selectedRM);
  const dispatch = useDispatch();
  const [activeRM, setActiveRM] = useState(0);

  const [currentRM, setCurrentRM] = useState({});

  const sliderRef = useRef(null);

  const openRMData = () => {
    document.querySelector(".RM-Data-ildfX").classList.add("active");
  };

  // =================================================================================

  useEffect(() => {
    getrmdetailslist();
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  useEffect(() => {
    if (slide == null) return;
    if (slide.length > 0) {
      document.querySelector(".outerRmBox-udim4").style.display = "block";

      console.log("nil 9", sliderRef.current);
      sliderRef.current.slickGoTo(0);
    } else {
      document.querySelector(".outerRmBox-udim4").style.display = "none";
    }
  }, [slide]);

  const getrmdetailslist = async () => {
    console.log("function call nil88");
    try {
      var payload = {
        url: ADVISORY_GET_RM_LIST_API,
        method: "get",
      };
      var d = await fetchData(payload);
      console.log("d----> nil88", d);
      //https://images.minty.co.in/static/assets/img/expert-images/expert_278.png
      allDataRef.current = d.data;
      cleanRmData(allDataRef.current);
    } catch (error) {
      console.log("function catch", error);
    }
  };
  const cleanRmData = (data) => {
    if (data.length > 0) {
      dispatch({ type: "RM_DETAILS", payload: { ...data[0] } });
      setActiveRM(data[0]["id"]);
    } else {
      dispatch({ type: "RM_DETAILS", payload: {} });
      setActiveRM(0);
    }
    const chunkSize = 8;
    var a = [];
    for (let i = 0; i < data.length; i += chunkSize) {
      a.push(data.slice(i, i + chunkSize));
    }
    setSlide([...a]);
  };

  // Custom Arrows
  const ArrowButtonPrevious = ({ imgSrc, imgAlt, onClick }) => {
    return (
      <button onClick={onClick} className={`${Styles.BackBtn}`}>
        <img src={imgSrc} alt={imgAlt} className={`${Styles.BackImg}`} />
      </button>
    );
  };

  const ArrowButtonNext = ({ imgSrc, imgAlt, onClick }) => {
    return (
      <button onClick={onClick} className={`${Styles.NextBtn}`}>
        <img src={imgSrc} alt={imgAlt} className={`${Styles.NextImg}`} />
      </button>
    );
  };

  const breakPoints = [
    { width: 1, itemsToShow: 8 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 2 },
  ];
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    // centerMode: true,
    slidesToScroll: 1,
    prevArrow: (
      <ArrowButtonPrevious imgSrc={LeftArrow} imgAlt="previous-button" />
    ),
    nextArrow: <ArrowButtonNext imgSrc={RightArrow} imgAlt="next-button" />,
  };

  useEffect(() => {
    var a = [];
    allDataRef.current.forEach((v) => {
      console.log("r--->", v);
      var valid = false;
      if (
        v.emp_position.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        v.emp_name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        v.emp_qualification.toLowerCase().indexOf(searchTerm.toLowerCase()) >
          -1 ||
        v.emp_experience
          .toString()
          .toLowerCase()
          .indexOf(searchTerm.toLowerCase()) > -1
      ) {
        valid = true;
      }
      // check location
      if (
        valid === true &&
        "location" in filters &&
        filters.location.length > 0
      ) {
        if (filters.location.indexOf(v.emp_location.toLowerCase()) > -1) {
          valid = true;
        } else {
          valid = false;
        }
      }
      // check rating
      if (valid === true && "rating" in filters && filters.rating.length > 0) {
        if (filters.rating.indexOf(1 * v.rating) > -1) {
          valid = true;
        } else {
          valid = false;
        }
      }
      // check experience
      if (
        valid == true &&
        "experience" in filters &&
        filters.experience.length > 0
      ) {
        var match = false;
        // check above 4
        if (filters.experience.indexOf("4+") > -1) {
          if (v.emp_experience > 4) {
            match = true;
          }
        }
        // check 2-4
        if (match == false && filters.experience.indexOf("2-4") > -1) {
          if (v.emp_experience > 1 && v.emp_experience < 5) {
            match = true;
          }
        }
        // check <2
        if (match == false && filters.experience.indexOf("<2") > -1) {
          if (v.emp_experience < 2) {
            match = true;
          }
        }
        valid = match;
      }

      if (valid === true) {
        a.push(v);
      }
    });
    // var a = allDataRef.current.filter(
    //   (v) =>
    //     v.emp_position.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
    //     v.emp_name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
    //     v.emp_qualification.toLowerCase().indexOf(searchTerm.toLowerCase()) >
    //       -1 ||
    //     v.emp_experience
    //       .toString()
    //       .toLowerCase()
    //       .indexOf(searchTerm.toLowerCase()) > -1
    // );
    cleanRmData(a);
  }, [searchTerm, filters]);

  const onRMClick = (v) => {
    console.log("rmid--->", v);
    var a = allDataRef.current.filter((x) => x.id == v)[0];
    // dispatch('RM_DETAILS', {...a});
    console.log("rmid9", { ...a });
    dispatch({ type: "RM_DETAILS", payload: { ...a } });
    setActiveRM(v);
  };

  const openBottomSheet = () => {
    document.querySelector(".outerRmBox-udim4").classList.add("active");
    document.querySelector(".outerRmBox-udim4").classList.add("mobile");
    document.body.classList.add("overflow-hidden");
  };

  return (
    <div
      className={`${Styles.exMainData} ${
        slide != null && slide.length == 0 ? Styles.exMainData_1 : ""
      }`}
    >
      <div
        class={`d-none d-md-block ${Styles.rmlist}`}
        style={{
          outline: "none !important",
        }}
      >
        {slide != null && slide.length > 0 && (
          <Slider
            {...settings}
            className={`${Styles.SlideRm}`}
            style={{
              outline: "none !important",
            }}
            ref={sliderRef}
          >
            {slide.map((v) => (
              <div
                className=""
                style={{
                  outline: "none !important",
                }}
              >
                <div
                  className="row  "
                  style={{
                    outline: "none !important",
                  }}
                >
                  {v.map((x) => (
                    <div
                      key={`desk-rm-${x.id}`}
                      className={`mb-4 col-md-6 ${
                        activeRM == x.id ? Styles.activeRM : ""
                      }`}
                      style={{
                        outline: "none !important",
                      }}
                    >
                      <ExpertItem data={x} onClick={() => onRMClick(x.id)} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Slider>
        )}
        {slide != null && slide.length == 0 && (
          <div>
            <p>
              No Wealth Advisor found matching this criteria. You might want to
              refine your search.
            </p>
          </div>
        )}
      </div>
      <div className="container">
        <div className="d-block d-md-none">
          <div className={`row ${Styles.MobileRMList}`}>
            {slide != null &&
              slide.map((v) =>
                v.map((x) => (
                  <div key={`mobile-rm-${x.id}`} className="col-12">
                    <div className="py-2">
                      <ExpertItem
                        data={x}
                        onClick={() => {
                          onRMClick(x.id);
                          openBottomSheet();
                        }}
                      />
                    </div>
                  </div>
                ))
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Expertrm;
