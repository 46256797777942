import React, { useEffect, useRef } from 'react';
import style from "./style.module.css";
import Hired_positives_client from "./Hired_positives_client.svg";
import Market_Knowledge from "./Market_Knowledge.svg";
import Diversification from "./Diversification.svg";
import Regulatory_Framework from "./Regulatory_Framework.svg";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const FeatureCard = ({ icon, title, description, cardRef, style }) => {
    return (
        <div ref={cardRef} className={`${style.FeatureCard}`}>
            <div>
                <img src={icon} alt={title} />
            </div>
            <div>
                <div className={`${style.FeatureCardTitle}`}>{title}</div>
                <div className={`${style.FeatureCardDes}`}>{description}</div>
            </div>
        </div>
    );
};

const Whydomesticeuityinvestsection = () => {
    const cardRefs = useRef([]);

    const features = [
        {
            icon: Hired_positives_client,
            title: 'Expansive Market',
            description: 'Ranks Among the Top Equity Markets Worldwide.'
        },
        {
            icon: Market_Knowledge,
            title: 'Growth Drivers',
            description: 'Boosted by demographics, digitalization, and infrastructure.'
        },
        {
            icon: Diversification,
            title: 'Sector Diversity',
            description: 'Broad range of sectors for strong returns and diversification.'
        },
        {
            icon: Regulatory_Framework,
            title: 'Better Governance',
            description: 'Improved transparency and corporate practices.'
        },
        {
            icon: Diversification,
            title: 'Rising Investment Interest',
            description: 'Strong inflows from both local and foreign investors.'
        },
    ];

    useEffect(() => {
        cardRefs.current = cardRefs.current.slice(0, features.length);

        cardRefs.current.forEach((card, index) => {
            gsap.fromTo(card, 
                {
                    opacity: 0,
                    y: 100
                }, 
                {
                    opacity: 1,
                    y: 0,
                    zIndex: index + 1,
                    duration: 1,
                    scrollTrigger: {
                        trigger: card,
                        start: "top bottom-=150",
                        end: "top center", 
                        scrub: 1,
                        toggleActions: "play none none reverse"
                    }
                }
            );
        });
    }, [features]);

    return (
        <div className={`container ${style.WhyinvestequitySection}`}>
            <div className={`${style.Whyinvestequitytitle}`}>
            Why Invest in the Indian Equities?
            </div>
            <div className={`${style.FeatureView}`}>
                {features.map((feature, index) => (
                    <FeatureCard
                        key={index}
                        icon={feature.icon}
                        title={feature.title}
                        description={feature.description}
                        cardRef={el => cardRefs.current[index] = el}
                        style={style}
                    />
                ))}
            </div>
            
        </div>
    );
};

export default Whydomesticeuityinvestsection;
