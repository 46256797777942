import AppointmentSection from "../components/HTML/NriTaxationLandingPage/AppointmentSection";
import EndToEndSolutionSection from "../components/HTML/NriTaxationLandingPage/EndToEndSolutionSection";
import NeedHelpSection from "../components/HTML/NriTaxationLandingPage/NeedHelpSection";
import NriTaxationFeaturedIn from "../components/HTML/NriTaxationLandingPage/NriTaxationFeaturedInSection";
import NriTaxationHeaderSection from "../components/HTML/NriTaxationLandingPage/NriTaxationLandingPageHeaderSection";
import NriTaxationTestimonialSection from "../components/HTML/NriTaxationLandingPage/NriTaxationTestimonialsSection";
import WhyTrustUsSection from "../components/HTML/NriTaxationLandingPage/WhyTrustUsSection";
import backImg from "../components/Layout/Fullpage/assets/nri-taxation-header-background.jpg";
import WealthContactForm from "../components/WealthManagementContactForm";
import Fullpage from "../components/Layout/Fullpage";
import { servicesTypes } from "../components/WealthManagementContactForm/model";
import ThankyouSection from "../components/ThankyouSection";
import { useState } from "react";
import ClientTestimonial from "../components/HTML/ClientTestimonial";
import FaqSection from "../components/HTML/NriTaxationLandingPage/FaqSection";
import AppointmentBox from "../components/Pages/Calendly";
function NriTaxitionLandingPage() {

  const [show, SetShow] = useState(false)

  return (
    <Fullpage>
      <div style={{ backgroundColor: "white" }}>
        <NriTaxationHeaderSection />
        <EndToEndSolutionSection />
        <WhyTrustUsSection />
        <ClientTestimonial />
        {/* <NriTaxationTestimonialSection /> */}
        <NeedHelpSection />
        {/* <NriTaxationFeaturedIn /> */}
        <section id="Appointment">
        <AppointmentBox eventCode={'Callback_mintyApp_36'} serviceName="NRI Taxation" eventUrl="https://calendly.com/fintoo/15-min-consultation-call-nri-taxation?hide_event_type_details=1" />
        </section>
        <FaqSection />
        {/* <WealthContactForm imgSrc={backImg} serviceType={servicesTypes.nriTaxation} onSuccess={() => SetShow(true)} />
        {show && <ThankyouSection onClose={() => SetShow(false)} />} */}
      </div>
    </Fullpage>
  );
}

export default NriTaxitionLandingPage;
