import React from 'react'
import style from "./style.module.css";
import Mfdashboard from "./MF_mobile.svg"
import { Link } from 'react-router-dom';
function Herosection() {
    return (
        <div className={`container ${style.MFHerosection}`}>
            <div className={`row ${style.MFHerosectioncard}`}>
                <div className='col-12 col-md-6'>
                    <div className={`${style.title}`}>Achieve Financial Freedom with Smart Mutual Fund Investments</div>
                    <div className={`${style.subtitle}`}>Secure Your Future with  <b style={{ color: "#dd7300" }}>Fintoo Invest</b> Proven Investment Expertise.</div>
                    <Link
                        style={{ textDecoration: "none" }}
                        target="_self"
                        onClick={() => {
                            setOpenMenu(false);
                        }}
                        to={process.env.PUBLIC_URL + "/mutual-fund/funds/all"}
                    >
                        <button>Invest Now</button>
                    </Link>
                </div>
                <div style={{ display: "grid", placeItems: "center" }} className='col-12 col-md-6'>

                    {/* <img style={{ width: "100%" }} src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/MF_Dashboard.svg'} alt="Mutual Fund Dashbaord" /> */}
                    <img src={Mfdashboard} alt="Mutual Fund Dashbaord" />
                </div>
            </div>
        </div>
    )
}

export default Herosection;
