import FaqSection from "../components/HTML/ITRFile/FaqSection";
import ITRFileHeaderSection from "../components/HTML/ITRFile/ITRFileHeaderSection";
import Fullpage from "../components/Layout/Fullpage";
import ThankyouSection from "../components/ThankyouSection";
import { useState } from "react";
import AppointmentBox from "../components/Pages/Calendly";
import ITRVideoCardSection from "../components/HTML/ITRFile/ITRVideoCardSection";
import ITRCardSection from "../components/HTML/ITRFile/ITRCardSection";
import ClientTestimonial from "../components/HTML/ClientTestimonial";
import HideHeader from "../components/HideHeader";
import HideFooter from "../components/HideFooter";
import ITRHowToFile from "../components/ITRHowToFile";

function ITRFileLanding() {
  const [show, SetShow] = useState(false);

  return (
    <Fullpage>
      <HideHeader />
      <HideFooter />
      <div style={{ backgroundColor: "white" }}>
        <div className="text-center pt-4">
          <img
            src={process.env.PUBLIC_URL + "/static/media/logo.svg"}
            alt="Fintoo logo"
          />
        </div>
        <ITRFileHeaderSection />
        {/* <ITRCardSection /> */}
        <ITRHowToFile />
        <ITRVideoCardSection />
        <ClientTestimonial />
        {/* <FaqSection /> */}
      </div>

      <footer className="new-itr-page-footer">
        <div className="container">
          <p className="elementor-heading-title elementor-size-default pt-4">
            Financial Hospital Advisor LLP (Brand Name - Fintooinvest.in, Fintoo app,
              Fintooinvest) makes no warranties or representations, express or implied,
            on products and services offered through the platform. It accepts no
            liability for any damages or losses, however, caused in connection
            with the use of, or on the reliance of its advisory or related
            services.
            <br />
            Past performance is not indicative of future returns. Please
            consider your specific investment requirements, risk tolerance,
            goal, time frame, risk and reward balance and the cost associated
            with the investment before choosing a fund, or designing a portfolio
            that suits your needs. Performance and returns of any investment
            portfolio can neither be predicted nor guaranteed. Investments made
            on advisory are subject to market risks, read all scheme related
            documents carefully. Marketing and distribution of various financial
            products such as Mutual Funds,Loans,Insurance,Bonds, Domestic Equity
            and International Equity are powered by Mihika Financial Services
            Private Limited (FintooInvest.in, FintooInvest app, FintooInvest).
          </p>

          <p className="elementor-heading-title elementor-size-default pt-4">
            © FINANCIAL HOSPITAL ADVISOR LLP [SEBI RIA Registration No:
            INA000015756] [Type of Registration: Non-Individual] [Validity of
            registration: February 17, 2021-Perpetual] [Address: Financial
            Hospital B-404, Kanakia Wall Street, Andheri - Kurla Rd, Hanuman
            Nagar, Andheri East, Mumbai, Maharashtra 400093] [ARN - 21209]
            [Platform Partner : BSE (Member code-10096)] [LLP - AAE-1913] [GST
            No : 27AAEFF2161P1Z6] [Principal Officer details : Principal
            Officer: Ms. Nidhi Manchanda(nidhi.manchanda@fintoo.in)]
            [Corresponding SEBI regional/local office: Plot No. C 4-A , G Block,
            Near Bank of India, Bandra Kurla Complex,Bandra East, Mumbai,
            Maharashtra 400051]
          </p>
          <p className="elementor-heading-title elementor-size-default py-4 text-center">
            Copyright © 2022 Fintooinvest,. All rights reserved
          </p>
        </div>
      </footer>
    </Fullpage>
  );
}

export default ITRFileLanding;
