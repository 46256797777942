import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Style from './style.module.css';
import { Link } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

const Tick = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M20.5 6.5522L8.48123 19.1558L3.5 14.1696L4.92866 12.7396L8.44762 16.262L19.0385 5.15576L20.5 6.5522Z" fill="black" />
            </svg>
        </>
    );
};

const USPSection = () => {
    const cardRef = useRef([]);

    useEffect(() => {
        cardRef.current.forEach((card, index) => {
            const direction = index % 2 === 0 ? -200 : 200;

            gsap.fromTo(
                card,
                { opacity: 0, x: direction, y: 50 },
                {
                    opacity: 1,
                    x: 0,
                    y: 0,
                    duration: 1.5,
                    ease: 'power2.out',
                    scrollTrigger: {
                        trigger: card,
                        start: 'top 80%',
                        end: 'bottom 20%',
                        toggleActions: 'play none none reverse',
                        scrub: true,
                        invalidateOnRefresh: true,
                    },
                }
            );
        });
    }, []);

    return (
        <div className={`container ${Style.USPSection}`}>
            <div className={`${Style.USPtitle}`}>Our platform USP</div>
            <div>
                <div ref={el => cardRef.current[0] = el} className={`row ${Style.USPCardbox}`}>
                    <div className={`col-md-5 ${Style.USPinfo}`}>
                        <div className={`${Style.USPcardtitle}`}>Manage Investments for You and Your Loved Ones</div>
                        <div className={`${Style.USPlist}`}>
                            <div className={`d-flex align-items-center ${Style.usplistview}`}>
                                <div><Tick /></div>
                                <div className='ps-2'>Sign up with a single account and effortlessly manage investments for your relatives and family members.</div>
                            </div>
                            <div className={`d-flex align-items-center ${Style.usplistview}`}>
                                <div><Tick /></div>
                                <div className='ps-2'>Enjoy the convenience of multiple accounts under one login, all in one place.</div>
                            </div>
                            <div className={`d-flex align-items-center ${Style.usplistview}`}>
                                <div><Tick /></div>
                                <div className='ps-2'>Advisors too can manage client investments in individual & family-wise portfolios.</div>
                            </div>
                        </div>
                        <div className={`${Style.USPBtn}`}>
                            <Link style={{ textDecoration: "none" }} to={`${process.env.PUBLIC_URL}/login`}>
                                <button>View Now</button>
                            </Link>
                        </div>
                    </div>
                    <div style={{ marginTop: '30rem' }} className={`col-md-7 ${Style.USPCardimg}`}>
                        <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Investments_discussion.svg'} alt="Investments" />
                    </div>
                </div>

                <div ref={el => cardRef.current[1] = el} className={`row ${Style.USPCardbox}`}>
                    <div className={`col-md-7 ${Style.USPCardimg2}`}>
                        <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Mutliple_Assets.svg'} alt="Assets" />
                    </div>
                    <div className={`col-md-5 ${Style.USPinfo}`}>
                        <div className={`${Style.USPcardtitle}`}>Manage and track multiple assets </div>
                        <div className={`${Style.USPlist}`}>
                            <div className={`d-flex align-items-center ${Style.usplistview}`}>
                                <div><Tick /></div>
                                <div className='ps-2'>Manage your investments in Mutual Funds, Fixed Deposits, Government Schemes, Real Estate, Insurance & many other asset classes.</div>
                            </div>
                            <div className={`d-flex align-items-center ${Style.usplistview}`}>
                                <div><Tick /></div>
                                <div className='ps-2'>Track Absolute Gain and Annualized Return (XIRR) for assets and portfolios.</div>
                            </div>
                        </div>
                        <div className={`${Style.USPBtn}`}>
                            <Link style={{ textDecoration: "none" }} to={`${process.env.PUBLIC_URL}/login`}>
                                <button>Check Now</button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div ref={el => cardRef.current[2] = el} className={`row ${Style.USPCardbox}`}>
                    <div className={`col-md-5 ${Style.USPinfo}`}>
                        <div className={`${Style.USPcardtitle}`}>Comprehensive Portfolio Reports</div>
                        <div className={`${Style.USPlist}`}>
                            <div className={`d-flex align-items-center ${Style.usplistview}`}>
                                <div><Tick /></div>
                                <div className='ps-2'>View and download summary and detailed portfolio holding reports.</div>
                            </div>
                            <div className={`d-flex align-items-center ${Style.usplistview}`}>
                                <div><Tick /></div>
                                <div className='ps-2'>Receive monthly report updates directly via email.</div>
                            </div>
                            <div className={`d-flex align-items-center ${Style.usplistview}`}>
                                <div><Tick /></div>
                                <div className='ps-2'>Easy access to consolidated and individual portfolio data.</div>
                            </div>
                        </div>
                        <div className={`${Style.USPBtn}`}>
                            <Link style={{ textDecoration: "none" }} to={`${process.env.PUBLIC_URL}/login`}>
                                <button>See Details</button>
                            </Link>
                        </div>
                    </div>
                    <div style={{ marginTop: '30rem' }} className={`col-md-7 ${Style.USPCardimg}`}>
                        <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Portfolio_Report.svg'} alt="Portfolio Report" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default USPSection;
