import AdvisorycardSection from "../components/HTML/InvestmentPlanning/AdvisorySection";
import HeaderSection from "../components/HTML/InvestmentPlanning/HeaderSection";
import FaqSection from "../components/HTML/InvestmentPlanning/FaqSection";
import SecuritySection from "../components/HTML/SecuritySection";
import YoutubevideoSection from "../components/HTML/InvestmentPlanning/YoutubevideoSection";
import Fullpage from "../components/Layout/Fullpage";
import WealthContactForm from "../components/WealthManagementContactForm";
import backImg from "../components/Layout/Fullpage/assets/advisor-min.jpg";
import StepsInvestmentPlan from "../components/HTML/InvestmentPlanning/StepsInvestmentPlan";
import { servicesTypes } from "../components/WealthManagementContactForm/model";
import { useState } from "react";
import ThankyouSection from "../components/ThankyouSection";
import AppointmentBox from "../components/Pages/Calendly";
import KeyDifferentiators from "../components/HTML/InvestmentPlanning/KeyDifferentiators";
import Equity_Offerings from "../components/HTML/InvestmentPlanning/Equity_Offerings";
import DebtInvestments from "../components/HTML/InvestmentPlanning/DebtInvestments";
import AlternateInvestments from "../components/HTML/InvestmentPlanning/AlternateInvestments";
const InvestPlanning = () => {
  const [show, SetShow] = useState(false);

  return (
    <Fullpage>
      <HeaderSection />
      <AdvisorycardSection />
      <section
        style={{
          backgroundColor: "#e8f6fc",
        }}
      >
        <KeyDifferentiators />
        {/* <StepsInvestmentPlan /> */}
      </section>
      <Equity_Offerings />

      {/* <section
        style={{
          backgroundColor: "#fff",
        }}
      >
        {/* <StepsInvestmentPlan /> */}
      {/* </section> */} 
      <section
        style={{
          backgroundColor: "#e8f6fc",
        }}
      >
        <DebtInvestments />
      </section>
      <AlternateInvestments />
      {/* <YoutubevideoSection /> */}
      <SecuritySection DynamicBgColor={"#e8f6fc"} />
      <section style={{}} id="GetStarted">
      <AppointmentBox eventCode={'Callback_mintyApp_57'} serviceName="Investment Planning" eventUrl="https://calendly.com/fintoo/15-min-consultation-call-investment-planning?hide_event_type_details=1" />
        {/* <WealthContactForm imgSrc={backImg} serviceType={servicesTypes.investmentPlanning} onSuccess={() => SetShow(true)} /> */}
      </section>
      <FaqSection />
      {show && <ThankyouSection onClose={() => SetShow(false)} />}
    </Fullpage>
  );
};
export default InvestPlanning;
