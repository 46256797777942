import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import {
  ADVISORY_GET_ASSETS_SUMMARY_API,
  ADVISORY_GET_LIABILITY_DATA,
  ADVISORY_GET_NETWORTHLIABILITES_API_URL,
  ADVISORY_NETWORTHFUNDFLOW_PROJECTION_API_URL,
  GET_MEMBER_LIST,
} from "../../../../constants";
import {
  fetchData,
  fetchEncryptData,
  getParentUserId,
  setBackgroundDivImage,
} from "../../../../common_utilities";
import Styles from "./Networth/Networth.module.css";
import customStyles from "../../../CustomStyles";
import AssetsLibTab from "./Networth/AssetLibTab";
import NetworthProjection from "./Networth/NetworthProjection";
import { Link } from "react-router-dom";
import Select from "react-select";
import FintooLoader from "../../../FintooLoader";
import AssetNwGraph from "./Networth/AssetNwGraph";
import LibNwGraph from "./Networth/LibNwGraph";
import { ScrollToTop } from "../../../../Pages/datagathering/ScrollToTop"
function Networth(props) {
  const [showProjection, setShowProjection] = useState(false);
  const [allMembers, setAllMembers] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    label: "All Member",
    value: 0,
    id: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [currentNetworth, setCurrentNetworth] = useState(0);
  const [assetValue, setAssetValue] = useState(0);
  const [liabilityValue, setLiabilityValue] = useState(0);
  const [assetData, setAssetData] = useState([]);
  const [liabilityData, setLiabilityData] = useState([]);
  const [networthProjection, setNetworthProjection] = useState([]);
  const [assetTotal, setAssetTotal] = useState(0);
  const [liabilityTotal, setLiabilityTotal] = useState(0);

  const asset_colors = {
    0: "#00bca4",
    1: "#aebc1d",
    2: "#005165",
    3: "#3d8b37",
    4: "#9b0061",
    5: "#7FFFD4",
    6: "#FF9655",
  };

  const liability_color = {
    0: "#588036",
    1: "#e1b624",
    2: "#042b62",
    3: "#f88221",
    4: "#f9411f",
    5: "#9400D3",
    6: "#4B0082",
    7: "#800000",
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    getCurrentNetworthDataByUser(selectedOption.fp_user_details_id);
    getAssetDetailsByUser(selectedOption.value);
    getLiabilityDetailsByUser(selectedOption.value);
  };

  const toggleProjection = () => {
    setShowProjection(!showProjection);
  };

  const formatToIndianRupee = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 2,
    }).format(amount);
  };

  const formatIndianRupee = (number) => {
    if (number >= 1e7) {
      return (number / 1e7).toFixed(1) + " Cr";
    } else if (number >= 1e5) {
      return (number / 1e5).toFixed(1) + " L";
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(1) + " K";
    } else {
      return number.toString();
    }
  };

  const session = props.session;

  useEffect(() => {
    setBackgroundDivImage();
    fetchMembers();
    getCurrentNetworthData();
    getAssetDetails();
    getLiabilityDetails();
    getNetworthProjection();
  }, []);

  const fetchMembers = async () => {
    try {
      const r = await fetchData({
        url: GET_MEMBER_LIST,
        data: {
          user_id: getParentUserId(),
          is_direct: "0",
        },
        method: "POST",
      });
      let all = r.data.map((v) => ({
        label: v.NAME ? v.NAME : v.fdmf_email,
        value: v.fp_user_id,
        id: v.id,
        fp_user_details_id: v.fp_user_details_id
      }));
      all.unshift({
        label: "All Member",
        value: 0,
        id: 0,
        fp_user_details_id: 0,
      });
      setAllMembers([...all]);
    } catch (error) {
      console.error(error);
    }
  };

  const getCurrentNetworthData = async () => {
    try {
      var payload = {
        method: "POST",
        url: ADVISORY_GET_NETWORTHLIABILITES_API_URL,
        data: {
          fp_log_id: session["fp_log_id"],
          user_id: session["id"],
          fp_user_id: session["fp_user_id"],
          filter_type: "all",
          is_direct: 0
        },
      };
      let response = await fetchEncryptData(payload);
      if (response.error_code == "100") {
        setCurrentNetworth(response["data"]["networth_sum"]);
        setAssetValue(response["data"]["asset_data"]);
        setLiabilityValue(response["data"]["liability_sum"]);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCurrentNetworthDataByUser = async (fp_user_id) => {
    try {
      let filter_type = "member_id";
      if (fp_user_id == 0) {
        filter_type = "all";
        fp_user_id = session["fp_user_id"];
      }
      var payload = {
        method: "POST",
        url: ADVISORY_GET_NETWORTHLIABILITES_API_URL,
        data: {
          fp_log_id: session["fp_log_id"],
          user_id: session["id"],
          fp_user_id: fp_user_id,
          filter_type: filter_type,
          is_direct: 0
        },
      };
      let response = await fetchEncryptData(payload);
      if (response.error_code == "100") {
        setCurrentNetworth(response["data"]["networth_sum"]);
        setAssetValue(response["data"]["asset_data"]);
        setLiabilityValue(response["data"]["liability_sum"]);
        setIsLoading(false);
      } else {
        setCurrentNetworth(0);
        setAssetValue(0);
        setLiabilityValue(0);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const processAssetData = (assetSumArray) => {
    let totalSum = 0;
    let assetData = {};

    Object.entries(assetSumArray).forEach(([key, value]) => {
      if (value["total"] !== 0) {
        const assetKeys = [
          "equity",
          "debt",
          "liquid",
          "alternate",
          "gold",
          "realEstate",
          "gold-physical",
        ];

        const assetKeyIndex = assetKeys.indexOf(key);
        if (assetKeyIndex !== -1) {
          if (key === "gold-physical" && "4" in assetData) {
            let goldData = assetData["4"];
            value.total += goldData.total;
            value.percentage += goldData.percentage;
          }
          assetData[assetKeyIndex.toString()] = value;
          totalSum += value.total;
        }
      }
    });

    return { assetData, totalSum };
  };

  const getAssetDetails = async () => {
    try {
      let payLoad = {
        method: "POST",
        url: ADVISORY_GET_ASSETS_SUMMARY_API,
        data: {
          fp_log_id: session["fp_log_id"],
          user_id: session["id"],
          fp_user_id: session["fp_user_id"],
          filter_type: "all",
          is_direct: 0
        },
      };

      let response = await fetchEncryptData(payLoad);

      if (response["error_code"] === "100") {
        let { assetData, totalSum } = processAssetData(
          response["data"]["assetSumArray"]
        );

        if (Object.keys(response["data"]["insurance_data"]).length > 0) {
          assetData["6"] = response["data"]["insurance_data"];
        }

        setAssetData(assetData);
        setAssetTotal(totalSum);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAssetDetailsByUser = async (fp_user_id) => {
    try {
      let filter_type = "member_id";
      if (fp_user_id === 0) {
        filter_type = "all";
        fp_user_id = session["fp_user_id"];
      }

      let payLoad = {
        method: "POST",
        url: ADVISORY_GET_ASSETS_SUMMARY_API,
        data: {
          fp_log_id: session["fp_log_id"],
          user_id: session["id"],
          fp_user_id: fp_user_id,
          filter_type: filter_type,
          is_direct: 0
        },
      };

      let response = await fetchEncryptData(payLoad);

      if (response["error_code"] === "100") {
        let { assetData, totalSum } = processAssetData(
          response["data"]["assetSumArray"]
        );

        if (Object.keys(response["data"]["insurance_data"]).length > 0) {
          assetData["6"] = response["data"]["insurance_data"];
        }

        setAssetData(assetData);
        setAssetTotal(totalSum);
      } else {
        setAssetData([]);
        setAssetTotal(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const processLiabilityData = (liabilityData) => {
    let liabilityGraphData = [];
    let total = 0;

    liabilityData
      .sort((a, b) => b.category_name - a.category_name)
      .forEach((liability) => {
        if (liability !== undefined) {
          const index = liabilityGraphData.findIndex(
            (item) => item.name === liability["category_name"]
          );
          if (index !== -1) {
            liabilityGraphData[index].y +=
              liability["liability_outstanding_amount"];
          } else {
            liabilityGraphData.push({
              name: liability["category_name"],
              y: liability["liability_outstanding_amount"],
            });
          }
          total += liability["liability_outstanding_amount"];
        }
      });

    return { liabilityGraphData, total };
  };

  const getLiabilityDetails = async () => {
    try {
      let payLoad = {
        method: "post",
        url: ADVISORY_GET_LIABILITY_DATA,
        data: {
          fp_log_id: session["fp_log_id"],
          user_id: session["id"],
          fp_user_id: session["fp_user_id"],
          is_direct: 0
        },
      };

      let response = await fetchEncryptData(payLoad);

      if (response["error_code"] === "100") {
        let { liabilityGraphData, total } = processLiabilityData(
          response["data"]["liabilitydata"]
        );

        setLiabilityData(liabilityGraphData);
        setLiabilityTotal(total);
      } else {
        setLiabilityData([]);
        setLiabilityTotal(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLiabilityDetailsByUser = async (fp_user_id) => {
    try {
      let filter_type = "member_id";
      if (fp_user_id === 0) {
        filter_type = "all";
        fp_user_id = session["fp_user_id"];
      }

      let payLoad = {
        method: "post",
        url: ADVISORY_GET_LIABILITY_DATA,
        data: {
          fp_log_id: session["fp_log_id"],
          user_id: session["id"],
          fp_user_id: fp_user_id,
          filter_type: filter_type,
          is_direct: 0
        },
      };

      let response = await fetchEncryptData(payLoad);

      if (response["error_code"] === "100") {
        let { liabilityGraphData, total } = processLiabilityData(
          response["data"]["liabilitydata"]
        );

        setLiabilityData(liabilityGraphData);
        setLiabilityTotal(total);
      } else {
        setLiabilityData([]);
        setLiabilityTotal(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getNetworthProjection = async () => {
    try {
      let payLoad = {
        method: "post",
        url: ADVISORY_NETWORTHFUNDFLOW_PROJECTION_API_URL,
        data: {
          fp_log_id: session["fp_log_id"],
          user_id: session["id"],
          fp_user_id: session["fp_user_id"],
          is_direct: 0
        },
      };

      let response = await fetchEncryptData(payLoad);

      if (response["error_code"] === "100") {
        setNetworthProjection(response["data"])
      } else {
        setNetworthProjection([])
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getpercentage = (ideal, current) => {
    if (current > 0) {
      var halfamt = (current * 50) / 100;
    } else {
      halfamt = 0;
    }

    if (ideal > 0) {
      var percentage = (halfamt / ideal) * 100;
    } else {
      var percentage = 99.9;
    }

    if (percentage > 98.5) {
      percentage = 98.5;
    }
    if (percentage < 6) {
      percentage = 6;
    }
    return percentage;
  };

  const getcolor = (ideal) => {
    if (ideal > 50) {
      return "#042b62";
    } else {
      return "red";
    }
  };

  return (
    <div>
      {/* <FintooLoader isLoading={isLoading} /> */}
      <div className="">
        {showProjection ? (
          <>
            <div>
              <NetworthProjection
                onBackClick={() => setShowProjection(false)}
                networthProjection={networthProjection}
              />
            </div>
          </>
        ) : (
          <>
            <div>
              <div className={`mt-5 ${Styles.NetworthAssLib}`}>
                <div className={`${Styles.netwBox}`}>
                  <div className={`${Styles.dd}`}>
                    <div className={`${Styles.selectBg}`}>
                      <div className="mb-0 mt-2" style={{ fontWeight: "bold" }}>
                        Select Family Member *
                      </div>
                      <Select
                        classNamePrefix="sortSelect"
                        isSearchable={true}
                        styles={customStyles}
                        options={allMembers}
                        value={selectedOption}
                        onChange={handleSelectChange}
                      />
                    </div>
                    <div
                      className={`d-md-flex justify-content-md-between d-grid ${Styles.CurrentNw}`}
                    >
                      <div>
                        <div>Current Networth</div>
                        <div style={{
                          color : currentNetworth < 0 ? "red" : ""
                        }} className={`${Styles.currnwamt}`}>
                          {formatToIndianRupee(currentNetworth)}
                        </div>
                      </div>
                      <div className={`${Styles.NwBtn}`}>
                        <button onClick={toggleProjection}>
                          View Projection
                        </button>
                      </div>
                    </div>
                    <div
                      className={`d-md-flex justify-content-md-between d-grid ${Styles.CurrentNw}`}
                    >
                      <div className={`${Styles.assetLibval}`}>
                        <div className="text-center">Assets</div>
                        <div className={`${Styles.amt}`}>
                          {formatToIndianRupee(assetValue)}
                        </div>
                      </div>
                      <div
                        className={`ms-md-5 mt-md-0 mt-3 ${Styles.assetLibval}`}
                      >
                        <div className="text-center">Liabilities</div>
                        <div className={`${Styles.amt}`}>
                          {formatToIndianRupee(liabilityValue)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={`ms-md-5`}>
                    <div className={`w-100 ${Styles.dd}`}>
                      {Object.keys(assetData).length > 0 ? (
                        <div className={`${Styles.AssetlibPie}`}>
                          <div className={`${Styles.label}`}>Assets</div>
                          <div className="d-md-flex align-items-center">
                            <div className={`${Styles.pieGraph}`}>
                              {" "}
                              {/* Make this div take the available space */}
                              <AssetNwGraph
                                containerId="assetChartContainer"
                                data={assetData}
                                total={formatIndianRupee(assetTotal)}
                              />
                            </div>
                            <div className={`${Styles.Assetlist}`}>
                              {Object.values(assetData).map((asset, index) => {
                                return (
                                  <div key={index}>
                                    <div className="d-flex">
                                      <div style={{
                                        width: ""
                                      }} className="d-flex">
                                        <div>
                                          <span
                                            className={`${Styles.colorCircle}`}
                                            style={{
                                              backgroundColor: asset_colors[index],
                                            }}
                                          ></span>
                                        </div>
                                        <div>
                                          {asset.name}
                                        </div>
                                      </div>
                                      <div className="ms-2" style={{ whiteSpace: "nowrap" }}>
                                        <span className={`${Styles.AssetLIbsVal}`}>
                                          {" "}
                                          : {formatIndianRupee(asset.total)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={`${Styles.AssetlibPie} mt-2`}>
                          {/* <div className={`${Styles.label}`}>Assets</div> */}
                          <div
                            style={{
                              padding: "3.3rem",
                            }}
                            className="text-center"
                          >
                            <div className="w-100" style={{ fontSize: "20px" }}>
                              No Assets
                            </div>
                          </div>
                        </div>
                      )}
                      {liabilityData.length > 0 ? (
                        <div className={`mt-2 ${Styles.AssetlibPie}`}>
                          <div className={`${Styles.label}`}>Liabilities</div>
                          <div className="d-md-flex align-items-center">
                            <div className={`${Styles.pieGraph}`}>
                              {" "}
                              {/* Make this div take the available space */}
                              <LibNwGraph
                                containerId="libChartContainer"
                                data={liabilityData}
                                total={formatIndianRupee(liabilityTotal)}
                              />
                            </div>
                            <div className={`mt-md-4 ${Styles.Liblist}`}>
                              {liabilityData.map((liability, index) => {
                                return (
                                  <div className="d-flex">
                                    <div style={{
                                      width: ""
                                    }} className="d-flex">
                                      <div>
                                        <span
                                          className={`${Styles.colorCircle}`}
                                          style={{
                                            backgroundColor: liability_color[index],
                                          }}
                                        ></span>

                                      </div>
                                      <div>
                                        {liability.name}
                                      </div>
                                    </div>
                                    <div className="ms-2" style={{ whiteSpace: "nowrap" }}>
                                      <span className={`${Styles.AssetLIbsVal}`}>
                                        {" "}
                                        :  {formatIndianRupee(liability.y)}
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={`${Styles.AssetlibPie} mt-2`}>
                          {/* <div className={`${Styles.label}`}>Liabilities</div> */}
                          <div
                            style={{
                              padding: "3.3rem",
                            }}
                            className="text-center"
                          >
                            <div className="w-100" style={{ fontSize: "20px" }}>
                              No Liabilities
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${Styles.AssetLibSection}`}>
                <AssetsLibTab />
              </div>
            </div>
          </>
        )}

        <div className="row py-2">
          <div className=" text-center">
            <div>
              <div className="btn-container">
                <div className="d-flex justify-content-center">
                  <Link to={process.env.PUBLIC_URL + "/report/profile"}>
                    <div
                      className="previous-btn form-arrow d-flex align-items-center"
                      onClick={() =>
                        {
                          ScrollToTop();
                          props.settab1("tab2")
                        }
                      }
                    >
                      <FaArrowLeft />
                      <span className="hover-text">&nbsp;Previous</span>
                    </div>
                  </Link>
                  <div
                    className="next-btn form-arrow d-flex align-items-center"
                    onClick={() => 
                      {
                        ScrollToTop();
                        props.settab1("tab4")
                      }
                    }
                  >
                    <span className="hover-text" style={{ maxWidth: 100 }}>
                      Next&nbsp;
                    </span>
                    <FaArrowRight />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Networth;
