
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import commonEncode from "../commonEncode";
import { apiCall } from "../common_utilities";
import FintooLoader from "../components/FintooLoader";
import { ADVISORY_CALLBACKSERVICES_API_URL } from "../constants";

const PricingPopup = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  let plan_types = {
    1: "Basic",
    2: "Basic Pro",
    3: "Classic",
    4: "Classic Plus",
    5: "Premium",
    6: "Elite",
    7: "Elite Prime",
  };
  const sendCallback = async () => {
    var utm_source = 26;
    var financialdata = {
      tagval: "minty_financial_planning",
      servicename: "financial-planning",
      plan_name: "Financial Plan",
      exp_tag: "fin_web_FP_exp",
      utm_source: utm_source,
    };

    var email = props.sessionData.email;
    var mobile = props.sessionData.mobile;
    var name = props.sessionData.user_details.name;
    var old_plan = "";
    var new_plan = "";
    if (plan_types[props.sessionData.fp_plan_sub_cat]) {
      old_plan =
        " from " + plan_types[props.sessionData.fp_plan_sub_cat].toString();
    }
    if (plan_types[props.reqplandict.plan_sub_cat]) {
      new_plan = plan_types[props.reqplandict.plan_sub_cat].toString();
    }
    var comment = "change plan request" + old_plan + " to " + new_plan;

    if (props.reqplandict.plan_id == 31) {
      var callbackdata = {
        fullname: name,
        mobile: mobile,
        email: email,
        tagval: "minty_financial_planning",
        servicename: financialdata.servicename,
        plan_name: "Financial Plan",
        tags: "fin_plan_switch_req",
        utm_source: financialdata.utm_source,
        rm_id: localStorage.getItem("rm_id"),
        skip_mail: "1",
        service: 98,
        comment: comment,
      };
    } else {
      var callbackdata = {
        fullname: name,
        mobile: mobile,
        email: email,
        tagval: "minty_financial_planning",
        servicename: financialdata.servicename,
        plan_name: "Financial Plan",
        tags: "fin_plan_switch_req",
        utm_source: financialdata.utm_source,
        skip_mail: "1",
        service: 98,
        comment: comment,
      };
    }

    if (callbackdata) {
      try {
        setIsLoading(true);
        // let callback = commonEncode.encrypt(JSON.stringify(callbackdata));
        let url = ADVISORY_CALLBACKSERVICES_API_URL;
        let response_data = await apiCall(url, callbackdata, false, false);
        // let res = JSON.parse(commonEncode.decrypt(response_data));
        if (response_data.data.error_code == "0") {
          setIsLoading(false);
          props.onClose();
          toast.success("Callback request sent successfully", {
            position: "bottom-left",
            autoClose: 2000,
          });
        } else {
          setIsLoading(false);
          props.onClose();
          toast.error("Something went wrong", {
            position: "bottom-left",
            autoClose: 2000,
          });
          
        }
      } catch {
        setIsLoading(false);
        props.onClose();
        toast.error("Something went wrong", {
          position: "bottom-left",
          autoClose: 2000,
        });
        
      }
    }
  };
  return (
    <div>
      <ToastContainer />
      <FintooLoader isLoading={isLoading} />
      <div className="p-2">
        <p className="PopupContent">
          Thank you for expressing your interest in our assisted advisory. If
          you wish to choose assisted advisory and receive a callback from our
          wealth manager, click on continue.
        </p>
        <div className="ButtonBx">
          <button className="ReNew" onClick={() => sendCallback()}>
            Continue
          </button>
          <p className="SkipBtn" onClick={props.onClose}>
            Cancel
          </p>
        </div>
      </div>
    </div>
  );
};

export default PricingPopup;
