import FaqSection from "../components/HTML/Stockadvisory/FaqSection";
import HeaderSection from "../components/HTML/Stockadvisory/HeaderSection";
import YoutubevideoSection from "../components/HTML/Stockadvisory/YoutubevideoSection";
import SecuritySection from "../components/HTML/SecuritySection";
import Fullpage from "../components/Layout/Fullpage";
import WealthContactForm from "../components/WealthManagementContactForm";
import backImg from "../components/Layout/Fullpage/assets/advisor-min.jpg";
import WidgetSection from "../components/HTML/Stockadvisory/WidgetSection";
import AdvisorycardSection from "../components/HTML/Stockadvisory/AdvisorySection";
import ReportsPage from "../components/HTML/Stockadvisory/ReportsPage";
import FeaturesSection from "../components/HTML/Stockadvisory/FeaturesSection";
import FeaturedIn from "../components/HTML/Stockadvisory/FeaturedIn";
import { servicesTypes } from "../components/WealthManagementContactForm/model";
import AppointmentBox from "../components/Pages/Calendly";
import AssistedUAESection from "../components/HTML/Stockadvisory/AssistedUAESection";
const Stockadvisory = () => {
  return (
    <Fullpage>
      <HeaderSection />
      <section style={{
        backgroundColor : "#e8f6fc"
      }}>
      <WidgetSection />
      </section>
      <AssistedUAESection />
      <AdvisorycardSection />
      <FeaturesSection />
      <ReportsPage />
      <YoutubevideoSection />
      <SecuritySection />
      {/* <FeaturedIn /> */}
      <section  style={{
        
      }} id="GETREPORTS">
         <AppointmentBox eventCode={'Callback_mintyApp_89'} serviceName="Direct Domestic Equity" eventUrl="https://calendly.com/fintoo/15-min-consultation-call-domestic-equity?hide_event_type_details=1" />
        {/* <WealthContactForm imgSrc={backImg} serviceType={servicesTypes.stockAdvisory}/> */}
      </section>
            <FaqSection />
    </Fullpage>
  );
};
export default Stockadvisory;
