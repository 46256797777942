import React from 'react'
import style from "./style.module.css"
import { Link } from 'react-router-dom';
function Herosection() {
    return (
        <div className={`container ${style.Herosection}`}>
            <div className={`row ${style.Herosectioncard}`}>
                <div className='col-12 col-md-6'>
                    <div className={`${style.title}`}>Life’s Unpredictable – Be Wise & Choose A Term Plan</div>
                    <div className={`${style.subtitle}`}>A secure financial future for your loved ones.<br />
                    Use our easy calculator to find the perfect coverage at a price that fits your budget.
                    </div>
                    <Link style={{ textDecoration: "none" }} to={`${process.env.PUBLIC_URL}/login`}>
                        <button>Get Covered</button>
                    </Link>
                </div>
                <div className='col-12 col-md-6'>
                    <img style={{ width: "100%" }} src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Insurance_hero_image.svg'} alt="Insurance" />
                </div>
            </div>
        </div>
    )
}

export default Herosection;
