import { useEffect, useState } from "react";

import {
  apiCall,
  getItemLocal,
  getParentUserId,
  loginRedirectGuest,
  removeMemberId,
  removeUserId,
} from "../../common_utilities";
import { CHECK_SESSION } from "../../constants";

const MainLayout = (props) => {
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    checkIfSessionExist();
  }, []);

  const checkIfSessionExist = async () => {
    try {
      
      if (getParentUserId()) {
        let url = CHECK_SESSION;
        let data = { user_id: getParentUserId(), sky: getItemLocal("rmf"), is_direct: 0 };
        let respData = await apiCall(url, data, true, false);
        if (respData["error_code"] != "100") {
          // loginRedirect();
          // // loginRedirectGuest();
          removeMemberId();
          removeUserId();
          localStorage.removeItem("rmf");
          localStorage.setItem("redirectToThis", window.location.href);
          window.location.href = process.env.PUBLIC_URL + "/session-expired";
        } else {
          setVerified(true);
        }
      } else {
        loginRedirectGuest();
      }
    } catch(err) {
      console.error('h88', err);
      // loginRedirectGuest();
    }
  };
  return (
    <>{verified ? props.children : <div style={{ minHeight: "80vh" }}></div>}</>
  );
};

export default MainLayout;
