import React from "react";
import Loader from "../Assets/Images/Rolling-1s-200px.svg";
import { memo } from "react";

const styles = {
    parentDiv: {
        textAlign: 'center'
    },
    loaderTxt: {
        paddingTop: '5px'
    }
};
const FintooInlineLoader = ({isLoading, hideText = true}) => {
    return (
        <>
            {isLoading  ? (<div style={styles.parentDiv}>
                <img src={Loader} style={{width: '30px'}}/>
                {hideText == false && <p style={styles.loaderTxt}>Loading...</p>}
            </div>) : <></>}
        </>
    );
}
export default memo(FintooInlineLoader);