import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import CardBox from './FeatureCard';
import Style from './style.module.css';

gsap.registerPlugin(ScrollTrigger);

const FeatureCard = () => {
    const cardRef = useRef([]);
    useEffect(() => {
        cardRef.current.forEach((card, index) => {
            gsap.fromTo(
                card,
                { 
                    opacity: 0, 
                    x: index % 2 === 0 ? -200 : 200,
                    y: 50 
                }, 
                {
                    opacity: 1,
                    x: 0,
                    y: 0,
                    duration: 1.5,
                    ease: 'power2.out',
                    scrollTrigger: {
                        trigger: card,
                        start: 'top 80%',
                        end: 'bottom 20%',
                        toggleActions: 'play none none reverse',
                        scrub: true,
                        invalidateOnRefresh: true,
                    },
                }
            );
        });
    }, []);

    return (
        <div className={`container ${Style.FeaturesSection}`}>
            <div className={Style.FeaturesSectionTxt}>One Login. All Features</div>
            <div className={Style.CardView}>
                <CardBox
                    ref={(el) => (cardRef.current[0] = el)}
                    link={`${process.env.PUBLIC_URL}/mutual-fund-page`}
                    
                    bgColor=""
                    textColor="#042b62"
                    title="Mutual Funds"
                    description="Invest in Mutual Funds, across most popular AMCs and all their schemes, from wherever you want and whenever you want, and completely paperless!"
                    imageSrc={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Mutual_funds.svg'}
                    altText="Mutual Funds"
                    arrowImg={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Icon.svg'}
                    spanColor="#042b62"
                />
                <CardBox
                    ref={(el) => (cardRef.current[1] = el)}
                    link={`${process.env.PUBLIC_URL}/Loans`}
                    bgColor="#042b62"
                    textColor="#fff"
                    spanColor="#fff"
                    title="Loans"
                    description="No matter the occasion, be it your marriage or getting your first house, loans help you achieve your dreams."
                    imageSrc={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Loans.svg'}
                    altText="Loans"
                    arrowImg={process.env.PUBLIC_URL + '/static/media/LandingPages/New/white_arrow.svg'}
                />
                <CardBox
                    ref={(el) => (cardRef.current[2] = el)}
                    bgColor="#042b62"
                    textColor="#fff"
                    link={`${process.env.PUBLIC_URL}/insurance-page`}
                    title="Insurance"
                    description="Protect your future with comprehensive insurance plans covering health, life, and property for peace of mind and security."
                    imageSrc={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Insurance.svg'}
                    altText="Insurance"
                    arrowImg={process.env.PUBLIC_URL + '/static/media/LandingPages/New/white_arrow.svg'}
                    spanColor="#fff"
                />
                <CardBox
                    ref={(el) => (cardRef.current[3] = el)}
                    bgColor=""
                    unlink={true}
                    textColor="#042b62"
                    title="Government security Bond"
                    description="Invest in low-risk, government-backed bonds for stable returns and reliable income."
                    imageSrc={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Gov_security_bond.svg'}
                    altText="Government security Bond"
                    arrowImg={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Icon.svg'}
                    spanColor="#042b62"
                />
                <CardBox
                    ref={(el) => (cardRef.current[4] = el)}
                    link={`${process.env.PUBLIC_URL}/domestic-equity-page`}
                    bgColor=""
                    textColor="#042b62"
                    spanColor="#042b62"
                    title="Domestic equity"
                    description="Stocks are an important element in any well-diversified investment portfolio."
                    imageSrc={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Domestic_equity.svg'}
                    altText="Domestic equity"
                    arrowImg={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Icon.svg'}
                />
                <CardBox
                    ref={(el) => (cardRef.current[5] = el)}
                    link={`${process.env.PUBLIC_URL}/international-equity-page`}
                    bgColor="#042b62"
                    textColor="#fff"
                    title="International equity"
                    description="Boost returns by investing in top global companies like Google, Apple, etc. with international equities."
                    imageSrc={process.env.PUBLIC_URL + '/static/media/LandingPages/New/International_equity.svg'}
                    altText="International equity"
                    arrowImg={process.env.PUBLIC_URL + '/static/media/LandingPages/New/white_arrow.svg'}
                    spanColor="#fff"
                />
            </div>
        </div>
    );
};

export default FeatureCard;
