import { useState } from "react";
import FaqAccordianContent from "./FaqAccordianContent";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import style from "./style.module.css";

function FaqAccordian() {
    const [activeIndex, SetActiveIndex] = useState(0);

    const updateAccordionIndex = (idx) => {
        if (activeIndex === idx) {
            return SetActiveIndex(() => null);
        } else {
            return SetActiveIndex(() => idx);
        }
    };

    const isActive = (idx) => activeIndex === idx;

    return (
        <>
            <div className="container" >
                <div className={`${style.faqtitle}`}>Frequently Asked Questions</div>
                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]} `}
                        onClick={() => updateAccordionIndex(0)}
                        style={isActive(0) ? { color: "#042b62" } : { color: "black" }}
                    >
                        {" "}
                        <span>How can I apply for an online personal loan with FintooInvest?</span>{" "}
                        {isActive(0) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(0)}>
                        Applying for an online personal loan with FintooInvest is simple and convenient. Just visit our website, fill out the application form with your personal and financial details, and submit the required documents. Our team will review your application, and if approved, funds will be disbursed quickly to your account.
                    </FaqAccordianContent>
                </div>
                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]}`}
                        onClick={() => updateAccordionIndex(1)}
                        style={isActive(1) ? { color: "#042b62" } : { color: "black" }}
                    >
                        <span>What are the eligibility criteria for a business loan from FintooInvest?</span>{" "}
                        {isActive(1) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(1)}>
                        To qualify for a business loan with FintooInvest, you must be a registered business owner with a solid operational history. You should also have a good credit score and a stable revenue stream. Detailed financial documents and business plans may be required during the application process.
                    </FaqAccordianContent>
                </div>
                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]}`}
                        onClick={() => updateAccordionIndex(2)}
                        style={isActive(2) ? { color: "#042b62" } : { color: "black" }}
                    >
                        <span>
                            What are the interest rates for online personal loans at FintooInvest?
                        </span>{" "}
                        {isActive(2) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(2)}>
                        Our interest rates for online personal loans are competitive and vary based on your credit profile and the loan amount. You can get an exact rate after submitting your application, as we offer personalized rates tailored to your financial situation.
                    </FaqAccordianContent>
                </div>

                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]}`}
                        onClick={() => updateAccordionIndex(4)}
                        style={isActive(4) ? { color: "#042b62" } : { color: "black" }}
                    >
                        <span>
                            Can I use a business loan from FintooInvest to expand my business?
                        </span>{" "}
                        {isActive(4) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(4)}>
                        Absolutely! A business loan from FintooInvest will help you grow and expand your business. Whether you need funds for purchasing inventory, upgrading equipment or expanding your operations, our flexible business loans can provide the financial support you need.
                    </FaqAccordianContent>
                </div>
                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]}`}
                        onClick={() => updateAccordionIndex(5)}
                        style={isActive(5) ? { color: "#042b62" } : { color: "black" }}
                    >
                        <span>How does FintooInvest ensure the security of my information during the online personal loan application?</span>{" "}
                        {isActive(5) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(5)}>
                        FintooInvest takes the security of your personal and financial information seriously. Our online personal loan application process is encrypted with the latest security protocols, ensuring that your data is safe and secure throughout the process.
                    </FaqAccordianContent>
                </div>
            </div>
        </>
    );
}

export default FaqAccordian;
