import React, { useState, useEffect, useRef } from "react";
import "react-magic-slider-dots/dist/magic-dots.css";
import Styles from "./style.module.css";
import * as toastr from "toastr";
import socket, { onMessageHandler } from "../../BankCashbalance/socket";
import commonEncode from "../../../../commonEncode";
import { createCookie, fetchData, apiCall, getCookie, } from "../../../../common_utilities";
import { ADVISORY_NSDL_CDSL_DATA, ADVISORY_ADD_NSDL_CDSL_DATA, ADVISORY_GET_ASSETS_API } from "../../../../constants";
import ProgressBar from "./ProgressBar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
const AccoutDetails = (props) => {
  const apiAttemptRef = useRef(0);
  const [verified, setVerified] = useState(false);
  const [accountfound, setAccountfound] = useState(false);
  const [activeprogressBar, setActiveprogressBar] = useState(false);
  const [test, SetTest] = useState(true);
  const { v4: uuidv4 } = require("uuid");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const [consentStatus, setConsentStatus] = useState("");
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const [errorMessage, setErrorMessage] = useState("");
  const [RefNumber, setRefNumber] = useState("");
  const [activeInput, setActiveInput] = useState(0);
  const [visible, setVisible] = useState(false);
  const [accverified, setAccverified] = useState(false)
  const [tab, setTab] = useState("CSDL");
  const [progressValue, setProgressValue] = useState(0);
  const tempCheckRef = useRef(0);
  const [totalAmount, setTotalAmount] = useState("");

  const handleInput = (e, index) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 1) {
      otp[index] = value;
      setOTP([...otp]);
      if (value && index < 5) {
        inputRefs[index + 1].current.focus();
      }
    } else {
      otp[index] = "";
      setOTP([...otp]);
    }
    setActiveInput(index);
    setErrorMessage("");
  };

  const handleBackspace = (e, index) => {
    if (e.keyCode === 8 && !otp[index] && index > 0) {
      otp[index - 1] = "";
      setOTP([...otp]);
      inputRefs[index - 1].current.focus();
      setActiveInput(index - 1);
    }
  };
  const clearAllInputs = () => {
    const clearedOTP = ["", "", "", "", "", ""];
    setOTP(clearedOTP);
  };

  useEffect(() => {
    if (
      // props.cdslNsdlResponse.nsdl.length === 0 &&
      props.cdslNsdlResponse.cdsl.length === 0
    ) {
      setVerified(true);
    } else {
      setVerified(false);
    }
  }, []);




  const consentApproval = async () => {
    try {
      let consent_request_details_payload = {
        header: {
          mid: "fcd2c56e-9414-11e8-9eb6-529269fb1459",
          ts: new Date().toISOString().replace("Z", "+00:00"),
          sid: props.cdslNsdlResponse.sid,
          dup: "false",
          type: "urn:finvu:in:app:req.accountConsentRequest.01",
        },
        payload: {
          FIPDetails: [
            {
              FIP: {
                id: "CDSLFIP"
              },
              Accounts: filteredData
            },
          ],
          FIU: {
            id: "fiulive@fintoo",
          },
          ver: "1.1.2",
          consentHandleId: props.dummy["consentid"],
          handleStatus: "ACCEPT",
        },
      };

      socket.send(JSON.stringify(consent_request_details_payload));
    } catch (e) {
      console.log("Error Occured ===>>> ", e);
    }
  };
  const toggleVisibility = () => {
    setVisible((current) => !current);
  };
  useEffect(() => {
    socket.onmessage = function (event) {
      const data = onMessageHandler(event);
      setRefNumber(data.payload.RefNumber)

      if (data.payload.status == "SUCCESS" && data.header.type == "urn:finvu:in:app:res.consentRequestDetailsResponse.01") {
        commonEncode.encrypt(
          createCookie(
            "dateRange",
            commonEncode.encrypt(
              JSON.stringify(data.payload.DataDateTimeRange)
            ),
            60
          )
        );
      }

      if (data.payload.status == "SUCCESS" || data.payload.consentIntentId) {
        commonEncode.encrypt(
          createCookie(
            "consentId",
            commonEncode.encrypt(JSON.stringify(data.payload.consentIntentId)),
            60
          )
        );
        checkConsentStatus();
      } else if (data.payload.status == "SUCCESS") {
        consentApproval();
      } else {
        toastr.options.positionClass = "toast-bottom-left";
        // toastr.error("Could not fetch the consent");
        // navigate(`${process.env.PUBLIC_URL}/datagathering/assets-liabilities/`);
      }

      if (data.payload.status == "SUCCESS" && data.header.type == "urn:finvu:in:app:res.userLinkedAccount.01") {
        const mid = data["header"]["mid"]
        const LinkedAccounts = data["payload"]["LinkedAccounts"]
        if (data["header"]["mid"].indexOf("nsdl") == 0) {
          cdslTrackAccount(LinkedAccounts)
        }
        else if (data["header"]["mid"].indexOf("cdsl") == 0) {
          cdslTrackAccount(LinkedAccounts)
        }
      }

      // else{
      if (data.payload.status == "SUCCESS" && data.header.type == "urn:finvu:in:app:res.linking.01") {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.success("OTP sent successfully");
      }
      else if (data.header.type == "urn:finvu:in:app:res.linking.01" && data.payload.message.includes("You have already linked this account(s):")) {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.success(data.payload.message);
        SetTest(false);
        setAccverified(false);
        setVisible(false);
      }
      else if (data.payload.status == "FAILURE" && data.payload.message == "Bad Request") {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Please enter OTP");
        setVisible(true);
      }
      else if (data.payload.status == "FAILURE" && data.payload.message == "Token incorrect.") {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Invalid OTP");
        setAccverified(true)
      }
      else if (data.payload.status == "SUCCESS" && data.header.type == "urn:finvu:in:app:res.confirm-token.01") {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.success("OTP verified successfully");
        SetTest(false);
        setAccverified(false);
      }
    };
  }, []);


  const handleApprove = async () => {
    try {
      const rid = uuidv4();
      let handle_approve_payload = {
        header: {
          mid: rid,
          ts: new Date().toISOString().replace("Z", "+00:00"),
          sid: props.cdslNsdlResponse.sid,
          dup: "false",
          type: "urn:finvu:in:app:req.consentRequestDetails.01",
        },
        payload: {
          consentHandleId: props.dummy["consentid"],
          userId: props.dummy["mobileNum"] + "@finvu",
        },
      };

      socket.send(JSON.stringify(handle_approve_payload));
    } catch (e) {
      console.log("Error Occured ===>>> ", e);
    }
  };

  const [userSesssionId, setUserSessionId] = useState("");
  const FIRequest = async () => {
    const rid = uuidv4();
    const ts = new Date().toISOString();
    let consentHandle = props.dummy["consentid"];
    let consentId = JSON.parse(commonEncode.decrypt(getCookie("consentId")));
    let dateRange = JSON.parse(commonEncode.decrypt(getCookie("dateRange")));
    let token = JSON.parse(commonEncode.decrypt(getCookie("token")));

    try {
      let fir_request_payload = {
        header: {
          rid: rid,
          ts: ts,
          channelId: "finsense",
        },
        body: {
          custId: props.dummy["mobileNum"] + "@finvu",
          consentId: consentId,
          consentHandleId: consentHandle,
          dateTimeRangeFrom: dateRange.from,
          dateTimeRangeTo: dateRange.to,
        },
      };

      const requestBody = JSON.stringify(fir_request_payload);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: requestBody,
      };

      fetch(
        "https://fintoo.fiulive.finfactor.co.in/finsense/API/V2/FIRequest",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setUserSessionId(data.body.sessionId);
          FIStatus(data.body.sessionId);
        })
        .catch((error) => {
          console.log("OTP API Error:", error);
        });
    } catch (e) {
      console.log("Error Occured ===>>> ", e);
    }
  };

  const FIStatus = async (sId, retryCount = 0) => {
    let token = JSON.parse(commonEncode.decrypt(getCookie("token")));
    let ConsentHandle = props.dummy["consentid"];
    let custID = props.dummy["mobileNum"] + "@finvu";
    let consentID = JSON.parse(commonEncode.decrypt(getCookie("consentId")));
    let sessionId = sId;

    try {
      const customHeaders = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const payload = {
        url: `https://fintoo.fiulive.finfactor.co.in/finsense/API/V2/FIStatus/${consentID}/${sessionId}/${ConsentHandle}/${custID}`,
        headers: customHeaders,
        method: "get",
      };

      const r = await fetchData(payload);

      if (r.body.fiRequestStatus === "READY") {
        setProgressValue(25)
        FIPfmDataReport(sessionId);
      }
      else if (r.body.fiRequestStatus === "FAILED") {
        props.onClose();
        if(tempCheckRef.current == 0) {
          toastr.options.positionClass = "toast-bottom-left";
          toastr.error("Failed to Fetch your holdings");
          navigate(`${process.env.PUBLIC_URL}/datagathering/assets-liabilities/`);
          tempCheckRef.current = tempCheckRef.current + 1;
        }
      }
      else {
        if (retryCount < 5) {
          setTimeout(() => FIStatus(sId, retryCount + 1), 15000);
        } else {
          toastr.options.positionClass = "toast-bottom-left";
          toastr.error(
            "Financial Information (FI) Status is PENDING / REJECTED"
          );
          navigate(
            `${process.env.PUBLIC_URL}/datagathering/assets-liabilities/`
          );
        }
      }
    } catch (error) {
      console.error("OTP error", error);
      // Handle error here if needed
    }
  };

  const FIPfmDataReport = async (sID) => {
    let token = JSON.parse(commonEncode.decrypt(getCookie("token")));
    let bankAccounts = JSON.parse(
      commonEncode.decrypt(getCookie("filteredCDSLaccountdata"))
    );
    let ConsentHandle = props.dummy["consentid"];
    let dateRange = JSON.parse(commonEncode.decrypt(getCookie("dateRange")));
    let consentID = JSON.parse(commonEncode.decrypt(getCookie("consentId")));
    try {
      let customHeaders = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      };

      let payload = {
        url: `https://fintoo.fiulive.finfactor.co.in/finsense/API/V2/Consent/${consentID}`,
        headers: customHeaders,
        method: "get",
      };

      const r = await fetchData(payload);
      if (r.body.status == "ACTIVE") {
        setProgressValue(50)
        let linkref = r.body.ConsentDetail.Accounts["0"].linkRefNumber;
        let accounts = r.body.ConsentDetail.Accounts;
        try {
          let customHeaders = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          };

          let payload = {
            url: `https://fintoo.fiulive.finfactor.co.in/finsense/API/V2/FIDataFetch/${ConsentHandle}/${sID}`,
            headers: customHeaders,
            method: "get",
          };

          const userData = await fetchData(payload);
          const member_id = props.dummy['id']
          const pan = props.dummy['pan']
          const mobile = props.dummy['mobileNum']
          const user_id = props.dummy['user_id'];
          const user_specific_id = props.dummy['user_specific_id'];
          const fp_log_id = props.dummy['fp_log_id'];
          const retirement_date = props.dummy['retirement_date'];
          var userProfile = userData.body[0].fiObjects
          var userpanExist = userProfile.filter((v)=>
            v.Profile.Holders.Holder[0].pan == pan
          )
          if (userpanExist.length > 0) {
            var holdings = userpanExist.flatMap(v=>v.Summary.Investment.Holdings.Holding)
          }
          else{
            var holdings = []
          }
          // const modifiedData = holdings.map(item => {
          //   return {
          //     user_id: user_id,
          //     member_id: member_id,
          //     issuerName: item.issuerName,
          //     isin: item.isin,
          //     isinDescription: item.isinDescription,
          //     units: item.units,
          //     lastTradedPrice: item.lastTradedPrice,
          //     fp_log_id: fp_log_id,
          //     retirement_date: retirement_date,
          //     pan:pan,
          //     mobile: mobile
          //   };
          // });
          const modifiedData = [];
          var broker_ids_list = "";

          for(let i=0; i<userProfile.length; i++){
              var profile = userProfile[i].Profile;
              if(profile.Holders.Holder[0].pan == pan){
                  var dematId = "";
                  dematId = profile.Holders.Holder[0].dematId;
                  
                  var broker_id = dematId.slice(3, 8);
                  // console.log("brokerId: ", broker_id);

                  if(broker_ids_list.length == 0){
                    broker_ids_list = broker_id
                  }else{
                    broker_ids_list = broker_ids_list + "," + broker_id;
                  }

                  var holdings = userProfile[i].Summary.Investment.Holdings.Holding;

                  var holdings_temp = holdings.map(item => {
                      return {
                        user_id: user_id,
                        member_id: member_id,
                        issuerName: item.issuerName,
                        isin: item.isin,
                        isinDescription: item.isinDescription,
                        units: item.units,
                        brokerId: broker_id,
                        lastTradedPrice: item.lastTradedPrice,
                        fp_log_id: fp_log_id,
                        retirement_date: retirement_date,
                        pan: pan,
                        mobile: mobile,
                        is_direct:0
                      };
                  });

                  holdings_temp.forEach(element => {
                    modifiedData.push(element)
                  });
                 
              }

          }

          const totalValue = holdings.map(stock => {
            const units = parseFloat(stock.units);
            const lastTradedPrice = parseFloat(stock.lastTradedPrice);
            return units * lastTradedPrice;
          }).reduce((acc, value) => acc + value, 0).toFixed(2);
          commonEncode.encrypt(createCookie("totalAmount", totalValue, 60));
          const assetGetPayload = {
            "filter_id": "0",
            "user_id": user_id,
            "fp_log_id": fp_log_id,
            "is_direct": 0
          }
          try {

            if (apiAttemptRef.current == 0) {
              apiAttemptRef.current = apiAttemptRef + 1;
              const res = await apiCall(
                ADVISORY_NSDL_CDSL_DATA,
                modifiedData,
                true,
                false
              );
              if (res["error_code"] == "100") {
                setProgressValue(75)
                try {
                  let cdslnsdlpayload = {
                    url: ADVISORY_ADD_NSDL_CDSL_DATA,
                    data: {
                      user_specific_id:user_specific_id,
                      member_id: member_id,
                      user_id: "" + user_id,
                      pan:pan,
                      fp_log_id: "" + fp_log_id,
                      member_name: props.dummy["name"],
                      mobile_number: props.dummy["mobileNum"],
                      cust_id: props.dummy["mobileNum"] + "@finvu",
                      consent_id: consentID,
                      consent_handle: ConsentHandle,
                      daterange_from: dateRange.from,
                      daterange_to: dateRange.to,
                      holding_isactive: 1,
                      holding_data:userData,
                      broker_ids: broker_ids_list,
                      retirement_date: retirement_date,
                      data_belongs_to:0,
                      updated_datetime: new Date().toISOString().slice(0, 19).replace("T", " "),
                      created_datetime: new Date().toISOString().slice(0, 19).replace("T", " "),
                    },
                    method: "post",
                  };
                  const r = await fetchData(cdslnsdlpayload);
                  if (r["error_code"] == "100") {
                    setProgressValue(100)
                    const response = await apiCall(
                      ADVISORY_GET_ASSETS_API,
                      assetGetPayload,
                      true,
                      false
                    );
                    if (response["error_code"] == "100") {
                      dispatch({
                        type: "ASSETS_UPDATE",
                        payload: true,
                      });
                      dispatch({
                        type: "TRIGGER_EQUITY_HOLDING",
                        payload: true,
                      });
                      toastr.options.positionClass = "toast-bottom-left";
                      toastr.success("Data fetched successfully");
                      setTimeout(() => {
                        props.onProceedClick();
                      }, 3000);
                    }
                  }
                } catch (e) {
                  console.log("Error Occured ===>>> ", e);
                }
              }
              else {
                toastr.error("Failed to add your holdings");
                navigate(`${process.env.PUBLIC_URL}/datagathering/assets-liabilities/`);
              }
            }
          } catch (e) {
            console.log("Error Occured ===>>> ", e);
          }
        } catch (e) {
          console.log("Error Occured ===>>> ", e);
        }
      } else {
        navigate(`${process.env.PUBLIC_URL}/datagathering/assets-liabilities/`);
      }
    } catch (e) {
      console.log("Error Occured ===>>> ", e);
    }
  };


  const checkConsentStatus = async (retryCount = 0) => {
    let token = JSON.parse(commonEncode.decrypt(getCookie("token")));
    try {
      const customHeaders = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const payload = {
        url: `https://fintoo.fiulive.finfactor.co.in/finsense/API/V2/ConsentStatus/${props.dummy["consentid"]}/${props.dummy["mobileNum"] + "@finvu"}`,
        headers: customHeaders,
        method: "get",
      };

      const r = await fetchData(payload);
      if (r.body.consentStatus === "ACCEPTED") {
        setConsentStatus(r.body.consentStatus);
        FIRequest();
      } else {
        if (retryCount < 5) {
          setTimeout(() => checkConsentStatus(retryCount + 1), 15000);
        } else {
          if (r.body.consentStatus === "REJECTED") {
            toastr.options.positionClass = "toast-bottom-left";
            // toastr.error("Consent Status is Rejected");
            // navigate(
            //   `${process.env.PUBLIC_URL}/datagathering/assets-liabilities/`
            // );
          } else {
            toastr.options.positionClass = "toast-bottom-left";
            // toastr.error("Consent Status is Requested");
            // navigate(
            //   `${process.env.PUBLIC_URL}/datagathering/assets-liabilities/`
            // );
          }
        }
      }
    } catch (error) {
      console.error("OTP error", error);
      // Handle error here if needed
    }
  };







  const AccountverifyOTP = async (type, otp) => {
    const rid = type + "-" + uuidv4();
    try {
      const otpstring = otp.join("");
      const verifyOTPPayload = {
        header: {
          mid: rid,
          ts: new Date().toISOString().replace("Z", "+00:00"),
          sid: props.cdslNsdlResponse.sid,
          dup: false,
          type: "urn:finvu:in:app:req.confirm-token.01"
        },
        payload:
        {
          ver: "1.1.2",
          timestamp: new Date().toISOString().replace("Z", "+00:00"),
          txnid: uuidv4(),
          AccountsLinkingRefNumber: RefNumber,
          token: otpstring
        }
      }

      socket.send(JSON.stringify(verifyOTPPayload));
    } catch (error) {
      console.error(
        "An error occurred during OTP verification:",
        error.message
      );
    }
  };
 
  const handleTrackAccount = async (type) => {
    try {
      const rid = type + "-" + uuidv4();
      try {
        let link_account_payload = {
          header: {
            mid: rid,
            ts: new Date().toISOString().replace("Z", "+00:00"),
            sid: props.cdslNsdlResponse.sid,
            dup: false,
            type: "urn:finvu:in:app:req.linking.01",
          },
          payload: {
            ver: "1.1.2",
            timestamp: new Date().toISOString().replace("Z", "+00:00"),
            txnid: uuidv4(),
            FIPDetails: {
              fipId: type == "cdsl" ? "CDSLFIP" : "fip@nsdl",
              fipName:
                type == "cdsl"
                  ? "Central Depository Services Limited"
                  : "National Securities Depository Limited",
            },
            Customer: {
              id: props.dummy["mobileNum"] + "@finvu",
              Accounts:
                type == "cdsl"
                  ? props.cdslNsdlResponse.cdsl
                  : props.cdslNsdlResponse.nsdl,
            },
          },
        };
        socket.send(JSON.stringify(link_account_payload));
      } catch (e) {
        console.log("Error Occured ===>>> ", e);
      }
    } catch (e) {
      console.log("Error Occured ===>>> ", e);
    }

    // if (!verified) {
    //   setTimeout(() => {
    //     setAccountfound(true);

    //   }, 3000);
    // } else {
    //   // props.onProceedClick();
    // }
  };

  const trackAccount = async (type) => {
    try {
      const rid = type + "-" + uuidv4();
      try {
        let track_account_payload = {
          header: {
            mid: rid,
            ts: new Date().toISOString().replace("Z", "+00:00"),
            sid: props.cdslNsdlResponse.sid,
            dup: false,
            type: "urn:finvu:in:app:req.userLinkedAccount.01"
          },
          payload: {
            userId: props.dummy["mobileNum"] + "@finvu",
          }
        };
        socket.send(JSON.stringify(track_account_payload));
      } catch (e) {
        console.log("Error Occured ===>>> ", e);
      }
    } catch (e) {
      console.log("Error Occured ===>>> ", e);
    }
  };

  const cdslTrackAccount = async (data) => {
    try {
      const rid = uuidv4();
      try {
        const filteredData = data
          .filter(item => item.fipId === "CDSLFIP")
          .map(({ fipId, linkRefNumber, accType, accRefNumber, maskedAccNumber, FIType, fipName }) => ({
            fipId,
            linkRefNumber,
            accType,
            accRefNumber,
            maskedAccNumber,
            FIType,
            fipName
          }));
        commonEncode.encrypt(
          createCookie(
            "filteredCDSLaccountdata",
            commonEncode.encrypt(JSON.stringify(filteredData)),
            60
          )
        );
        let track_account_payload = {
          header: {
            mid: rid,
            ts: new Date().toISOString().replace("Z", "+00:00"),
            sid: props.cdslNsdlResponse.sid,
            dup: false,
            type: "urn:finvu:in:app:req.accountConsentRequest.01"
          },
          payload:
          {
            FIPDetails: [
              {
                FIP: {
                  id: "CDSLFIP"
                },
                Accounts: filteredData

              }
            ],
            FIU: {
              id: "fiulive@fintoo"
            },
            ver: "1.1.2",
            consentHandleId: props.dummy["consentid"],
            handleStatus: "ACCEPT"
          }
        };
        socket.send(JSON.stringify(track_account_payload));
      } catch (e) {
        console.log("Error Occured ===>>> ", e);
      }
    } catch (e) {
      console.log("Error Occured ===>>> ", e);
    }
  };

  return (
    <>
      {
        activeprogressBar ? (
          <ProgressBar progress={progressValue}/>
        ) : (
          <div
            className={` DeamtBasicDetailsSection ${Styles.BasicDetailsSection}`}
          >
            {verified ? (
              <>
                <div className="">
                  <div
                    className={`d-flex align-items-center ps-2 ${Styles.accountnotfoundDetailsbox}`}
                  >
                    <div>
                      <img
                        style={{ verticalAlign: "middle" }}
                        className=""
                        width={40}
                        src={
                          process.env.REACT_APP_STATIC_URL + "media/DG/Warning.svg"
                        }
                        alt="Warning"
                      />
                    </div>
                    <div className="ms-2">
                      <div className={`${Styles.notfoundtext}`}>
                        {/* Couldn't Find an Account */}
                        Couldn't Find an Account with CDSL Data
                      </div>
                      <div className={`${Styles.notfoundnumber}`}>
                        No account found with your mobile no.{" "}
                        <span style={{ textDecoration: "underline" }}>
                          {props.dummy["mobileNum"]}
                        </span>
                        .
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className={`${Styles.reasonTitle}`}>
                      This could be due to either
                    </div>
                    <div>
                      <div className="d-flex align-items-center">
                        <div>
                          {" "}
                          <img
                            style={{ verticalAlign: "middle" }}
                            className=""
                            width={15}
                            src={
                              process.env.REACT_APP_STATIC_URL +
                              "media/DG/Ellipse.svg"
                            }
                            alt="List"
                          />
                        </div>
                        <div className={`ms-3 ${Styles.Reasonlist}`}>
                          The Depository may be experiencing some technical
                          difficulties - please try again after some time.
                        </div>
                      </div>
                      <div className="d-flex mt-3 align-items-center">
                        <div>
                          {" "}
                          <img
                            style={{ verticalAlign: "middle" }}
                            className=""
                            width={15}
                            src={
                              process.env.REACT_APP_STATIC_URL +
                              "media/DG/Ellipse.svg"
                            }
                            alt="List"
                          />
                        </div>
                        <div className={`ms-3 ${Styles.Reasonlist}`}>
                          The Pan that you provide must not correspond to the
                          registered mobile number on the Demat.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div>
                <>
                  <div className={`${Styles.title}`}>We found your accounts</div>
                  <div className={`pt-2 ${Styles.stepsubTitle}`}>
                    Please note you can only track demat accounts actively used
                    for trading and still holding investments within the past year
                  </div>


                  <div className="">
                    <div style={{ display: `${tab === "CSDL" ? "block" : "none"}` }}>
                      {"cdsl" in props?.cdslNsdlResponse &&
                        props.cdslNsdlResponse.cdsl.length > 0 && (
                          <div className={`${Styles.accountDetailsbox}`}>
                            <>
                              <div className={`${Styles.servicesName}`}>
                                Central Depository Services LTD
                              </div>
                              {props.cdslNsdlResponse.cdsl.map((v) => (
                                <>
                                  <div className={`${Styles.AccountList} ${props.cdslNsdlResponse.cdsl.length > 4 ? Styles.listHeight : null}`}>
                                    <div className={`${Styles.accountSubdetails}`}>
                                      <div className="d-flex align-items-center">
                                        <div>
                                          <img
                                            style={{ verticalAlign: "middle" }}
                                            className=""
                                            width={20}
                                            src={
                                              process.env.REACT_APP_STATIC_URL +
                                              "media/DG/Check.svg"
                                            }
                                            alt="Check"
                                          />
                                        </div>
                                        <div
                                          className={`ms-2 pt-1 text-black ${Styles.dmatAccno}`}
                                        >
                                          Demat Account {v.maskedAccNumber}
                                        </div>
                                      </div>
                                      <div className={`pt-1 ${Styles.BrokerName}`}>
                                        {/* Broker Name */}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                              <div className={`${Styles.HRLine}`}></div>
                              <div >
                                {
                                  visible ? null : (
                                    <div className={test ? "d-block" : "d-none"}>
                                      <div className={accverified ? "d-none" : "d-block"}>
                                        <div className="ButtonBx w-auto d-flex justify-content-md-end justify-content-center">
                                          <button
                                            style={{ padding: "0.5rem 1rem" }}
                                            type="button"
                                            className="Unlink mt-3 "
                                            onClick={() => {
                                              toggleVisibility()
                                              handleTrackAccount("cdsl");
                                              
                                            }}
                                          >
                                            Verify Now
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }

                                {
                                  visible ? (
                                    <>
                                      <div className={`${Styles.OtpVerify}`}>
                                        <div className={`${Styles.title}`}>
                                          OTP Verification
                                        </div>
                                        <div className={`${Styles.sentotptext}`}>
                                          Enter the OTP sent to{" "}
                                          <span>+91 {props.dummy["mobileNum"]}</span>
                                        </div>
                                        <div className={`${Styles.OtpBox}`}>
                                          <div>
                                            <div>
                                              {Array.from({ length: 6 }).map(
                                                (_, index) => (
                                                  <input
                                                    key={index}
                                                    ref={inputRefs[index]}
                                                    type="text"
                                                    className={
                                                      otp[index] ? Styles.active : null
                                                    }
                                                    value={otp[index]}
                                                    onChange={(e) =>
                                                      handleInput(e, index)
                                                    }
                                                    onKeyDown={(e) =>
                                                      handleBackspace(e, index)
                                                    }
                                                    min={1}
                                                    max={1}
                                                  />
                                                )
                                              )}
                                            </div>
                                            <div className={`${Styles.Resentotptxt}`}>
                                              Didn’t you receive the OTP?{" "}
                                              <span
                                                onClick={() => {
                                                  handleTrackAccount("cdsl");
                                                  setVisible(true);
                                                }

                                                }
                                                style={{ cursor: 'pointer' }}
                                              >Resend OTP</span>{" "}
                                            </div>
                                          </div>
                                          <div className="ButtonBx w-auto">
                                            <button
                                              type="button"
                                              className="Unlink mt-md-0 mt-3"
                                              onClick={() => {
                                                AccountverifyOTP("cdsl", otp);
                                                setVisible(false);
                                              }}
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : null
                                }

                                {
                                  accverified ? (
                                    <div className={visible ? "d-none" : "d-block "}>

                                      <div className={`mt-3 d-flex justify-content-center align-items-center`}>
                                        <div className={`${Styles.notverifiedAccount}`}>
                                          <div>
                                            <img
                                              style={{ verticalAlign: "middle" }}
                                              className=""
                                              width={15}
                                              src={
                                                process.env.REACT_APP_STATIC_URL +
                                                "media/DG/Failed.svg"
                                              }
                                              alt="List"
                                            />
                                          </div>
                                          <div className="ms-2">
                                            Verification Failed
                                          </div>
                                        </div>
                                        <div className={`ms-3 pointer ${Styles.RetryBtn}`} onClick={() => {
                                          setVisible(true);
                                          setAccverified(null);
                                          handleTrackAccount("cdsl");
                                          clearAllInputs()
                                          // inputRefs(nu)
                                        }}>Retry</div>
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      <div className={test ? "d-none" : "d-block mt-3"}>
                                        <div className={`${Styles.verifiedAccount}`}>
                                          <div>
                                            <img
                                              style={{ verticalAlign: "middle" }}
                                              className=""
                                              width={15}
                                              src={
                                                process.env.REACT_APP_STATIC_URL +
                                                "media/DG/Verified.svg"
                                              }
                                              alt="List"
                                            />
                                          </div>
                                          <div className="ms-2">
                                            Account verified successful
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                }
                              </div>
                            </>
                          </div>
                        )}
                    </div>
                    <div style={{ display: `${tab === "NSDL" ? "block" : "none"}` }}>
                      {"nsdl" in props?.cdslNsdlResponse &&
                        props.cdslNsdlResponse.nsdl.length > 0 && (
                          <div className={`${Styles.accountDetailsbox}`}>
                            <>
                              <div className={`${Styles.servicesName}`}>
                                National Securities Depository LTD
                              </div>
                              {props.cdslNsdlResponse.nsdl.map((v) => (
                                <div className={`${Styles.accountSubdetails}`}>
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <img
                                        style={{ verticalAlign: "middle" }}
                                        className=""
                                        width={20}
                                        src={
                                          process.env.REACT_APP_STATIC_URL +
                                          "media/DG/Check.svg"
                                        }
                                        alt="Check"
                                      />
                                    </div>
                                    <div
                                      className={`ms-2 pt-1 ${Styles.dmatAccno}`}
                                    >
                                      Demat Account {v.maskedAccNumber}
                                    </div>
                                  </div>
                                  <div
                                    style={{ fontWeight: "bold" }}
                                    className={`pt-1 ${Styles.BrokerName}`}
                                  >
                                    {/* Broker Name */}
                                  </div>
                                </div>
                              ))}
                              <div className={`${Styles.HRLine}`}></div>
                              <div>
                                {
                                  visible ? null : (
                                    <>
                                      <div>
                                        <div className="ButtonBx w-auto d-flex justify-content-end">
                                          <button
                                            style={{ padding: "0.5rem 1rem" }}
                                            type="button"
                                            className="Unlink mt-3 "
                                            onClick={() => {
                                              toggleVisibility();
                                              handleTrackAccount("nsdl");
                                            }}
                                          >
                                            Verify Now
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  )
                                }

                                {
                                  visible && (
                                    <>
                                      <div className={`${Styles.OtpVerify}`}>
                                        <div className={`${Styles.title}`}>
                                          OTP Verification
                                        </div>
                                        <div className={`${Styles.sentotptext}`}>
                                          Enter the OTP sent to{" "}
                                          <span>+91 {props.dummy["mobileNum"]}</span>
                                        </div>
                                        <div className={`${Styles.OtpBox}`}>
                                          <div>
                                            <div>
                                              {Array.from({ length: 6 }).map(
                                                (_, index) => (
                                                  <input
                                                    key={index}
                                                    ref={inputRefs[index]}
                                                    type="text"
                                                    className={
                                                      otp[index] ? Styles.active : null
                                                    }
                                                    value={otp[index]}
                                                    onChange={(e) =>
                                                      handleInput(e, index)
                                                    }
                                                    onKeyDown={(e) =>
                                                      handleBackspace(e, index)
                                                    }
                                                    min={1}
                                                    max={1}
                                                  />
                                                )
                                              )}
                                            </div>
                                            <div className={`${Styles.Resentotptxt}`}>
                                              Didn’t you receive the OTP?{" "}
                                              <span
                                                onClick={() => handleTrackAccount("nsdl")}
                                                style={{ cursor: 'pointer' }}
                                              >Resend OTP</span>{" "}
                                            </div>
                                          </div>
                                          <div className="ButtonBx w-auto">
                                            <button
                                              type="button"
                                              className="Unlink mt-md-0 mt-3"
                                              onClick={() => {
                                                AccountverifyOTP("cdsl", otp);
                                                setVisible(null);
                                              }}
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                }
                                {/* Account Verified */}
                                <div className="mt-2">
                                  <div className={`${Styles.verifiedAccount}`}>
                                    <div>
                                      <img
                                        style={{ verticalAlign: "middle" }}
                                        className=""
                                        width={15}
                                        src={
                                          process.env.REACT_APP_STATIC_URL +
                                          "media/DG/Verified.svg"
                                        }
                                        alt="List"
                                      />
                                    </div>
                                    <div className="ms-2">
                                      Account verified successful
                                    </div>
                                  </div>
                                </div>
                                {/* Account not verified */}
                                <div style={{ display: visible ? "none" : "block" }} className={`mt-2 d-flex justify-content-center align-items-center`}>
                                  <div className={`${Styles.notverifiedAccount}`}>
                                    <div>
                                      <img
                                        style={{ verticalAlign: "middle" }}
                                        className=""
                                        width={15}
                                        src={
                                          process.env.REACT_APP_STATIC_URL +
                                          "media/DG/Failed.svg"
                                        }
                                        alt="List"
                                      />
                                    </div>
                                    <div className="ms-2">
                                      Verification Failed
                                    </div>
                                  </div>
                                  <div className={`${Styles.RetryBtn}`}>Retry</div>
                                </div>
                              </div>
                            </>
                          </div>
                        )}
                    </div>
                    {
                      props.cdslNsdlResponse.nsdl.length === 0 ||
                        props.cdslNsdlResponse.cdsl.length === 0 ? (
                        null
                      ) : (
                        <>
                          <div className="d-flex justify-content-center">
                            <div className="d-flex justify-content-between align-items-center mt-4">
                              <div
                                className={`${tab == "CSDL" ? Styles.Dotactive : Styles.Dots}`}
                                onClick={() => setTab("CSDL")}
                              >
                              </div>
                              <div
                                className={`text-center ${tab == "NSDL" ? Styles.Dotactive : Styles.Dots}`}
                                onClick={() => setTab("NSDL")}
                              >
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    }
                  </div>
                </>
              </div>
            )}

            <div className={`mt-4 ${Styles.accountfoundsection}`}>
              <div
                className={`ButtonBx justify-content-center   ${verified ? "d-md-flex " : "d-flex"
                  }`}
              >
                {accountfound ? null : (
                  <button
                    style={{marginLeft: `${verified ? "" : "0"}` }}
                    className={`Cancel ${Styles.Cancelpopup} ${verified ? Styles.Cancelbtnmobile : Styles.CancelBtndesktop
                      }`}
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    Cancel
                  </button>
                )}
                {verified ? (
                  <>
                    <button
                      type="button"
                      className={`Unlink ${Styles.UseDiffnumber}`}
                      onClick={() => {
                        props.onBackProceedClick();
                      }}
                    >
                      Use Another Number
                    </button>
                  </>
                ) : (
                  <>
                    {accountfound ? null : (
                      <button
                        type="button"
                        style={{
                          marginLeft: `${verified ? "" : "1rem"}`,
                          padding: `${verified ? "" : "0.6rem 2rem"}`,
                        }}
                        className={test ? Styles.disableBtn : "Unlink"}
                        onClick={() => {
                          // handleTrackAccount("nsdl");
                          // handleTrackAccount("cdsl");
                          trackAccount("cdsl")
                          handleApprove();
                          setActiveprogressBar(true);
                        }}
                      >
                        Track Account
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )
      }

    </>
  );
};
export default AccoutDetails;
