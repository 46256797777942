import React, { useEffect, useRef } from 'react';
import style from "./style.module.css";
const Whyinsurancesection = () => {

    return (
        <div className={`container ${style.Whyinsurancesection}`}>
            <div className={`${style.Whyinvestequitytitle}`}>
            Insurance Plans For Every Need
                <br />
                Explore Top-rated Plans From Fintoo- Invest

            </div>
            <div className={`${style.FeatureView}`}>
                <div className={`${style.FeatureViewbox} ${style.FeatureViewboxLife}`}>
                    <div>
                        <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/life_insurance.svg'} alt="Life Insurance" />
                    </div>
                    <div className={`${style.loantype}`}>Life Insurance</div>
                    <div className={`${style.loandesc}`}>Life insurance is a contract between you and an insurance company, offering financial protection for your family. In exchange for regular premium payments, the insurer provides a pre-agreed sum assured to your beneficiaries in case of an untoward incident. To ensure accurate coverage and quotes, disclose your health conditions fully and choose your preferred premium option. Use our insurance calculator to estimate your coverage and secure your family's future today.</div>
                    <a style={{ textDecoration: "none" }} href='#Book-Calendly'>
                        <button>Apply Now</button>
                    </a>
                </div>
                <div className={`${style.FeatureViewbox} ${style.FeatureViewboxHealth}`}>
                    <div>
                        <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Health_insurance_black.svg'} alt="Health Insurance" />
                    </div>
                    <div className={`${style.loantype}`}>Health Insurance</div>
                    <div className={`${style.loandesc}`}>Shield yourself and your loved ones from unexpected medical costs with our health insurance. Essential for everyone, our plans offer peace of mind and financial protection against rising healthcare expenses. Enjoy cashless treatments and flexible coverage options tailored to your needs. Stay secure and stress-free—get covered today!
                    </div>
                    <a style={{ textDecoration: "none" }} href='#Book-Calendly'>
                        <button>Apply Now</button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Whyinsurancesection;
