import style from './style.module.css';
import { ReactComponent as FintooNext } from './images/fintooNextIc.svg';

const PortfolioCategory = (props) => {
    return (<>
    <div className={`d-flex align-items-center ${style.gridItem}`}>
                <div className={`d-none d-md-block ${style.clsName}`}>
                    <img src={require('./images/test_82.png')} width={20} />
                </div>
                <div className='px-3'>
                    <p className={`mb-0 ${style.clsN}`}>{props.data ? props.data.title : ""}</p>
                    <p className={`mb-0 ${style.clsN1}`}>₹ {props.data ? props.data.title_invested_value : ""} <span>{props.data ? props.data.title_percentage_value : ""}%</span></p>
                </div>
                <a href={props.data.title == "Equity" || props.data.title == "Debt" ? `${process.env.PUBLIC_URL}/mutual-fund/portfolio/dashboard/` : ""}><div><FintooNext width={15} height={15} /></div></a>
            </div>
    </>);
}

export default PortfolioCategory;