import Styles from "./style.module.css";
import Logo from './UAEFintoo.png'
const VideoBox = () => {
  return (
    <div className={Styles.videobox}>
      {/* <div className="position-relative w-100">
        <div className="position-absolute w-100">
        <div className="w-100 text-center">
            <img
              src="https://static.fintoo.in/wealthmanagement/wp-content/uploads/2023/02/logo-1.png"
              width="182px"
              />
              </div>
              </div>
            </div> */}
      <div className={`${Styles.vBox}`}>
        <video
          className={`${Styles.VideoLayout}`}
          autoPlay={true}
          muted={true}
          playsInline={true}
          loop={true}
          src="https://www.fintoo.in/wealthmanagement/wp-content/uploads/2023/02/videoplayback.mp4"
        />
      </div>
      <div className={`${Styles.bgOverlay}`}></div>
      <div className="position-relative w-100">
        <div
          className={`position-absolute w-100 text-center ${Styles["land-txt"]}`}
        >
          <div>
            <div>
              <img
                width={300}
                src={Logo}
                // src="https://static.fintoo.in/wealthmanagement/wp-content/uploads/2022/09/fintoo-logo-01-e1663135457467-2048x604.png"
                alt="logo"
              />
            </div>
            <div>
              <h2
               
                className={`${Styles.TextHello}`}
              >
                Namaste Dubai..!
              </h2>
            </div>
          </div>
          <div>
          </div>
          <h1 className={`${Styles.SpaceReduce}`}>
            Your dreams have no boundaries. <br /> So why should your
            investments?
          </h1>
          <p>
            Open the doors to the world of endless possibilities with <br />{" "}
            customized wealth management solutions.
          </p>
          <div className="d-flex justify-content-center mt-md-4">
            <a href="#Howweguide">
              <div className={`${Styles.mouseicon}`}>
                <span></span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VideoBox;
