import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import Herosection from '../../../components/LandingPages/Domestic_Equity/Herosection';
import Whydomesticeuityinvest from '../../../components/LandingPages/Domestic_Equity/Whyinvest';
import Ourfeaturesection from '../../../components/LandingPages/Domestic_Equity/Our_Features';
import FaqAccordian from '../../../components/LandingPages/Domestic_Equity/FaqAccordian';
import Security from '../../../components/LandingPages/Domestic_Equity/Security';
import BookCalendly from '../../../components/LandingPages/Domestic_Equity/Calendly';
import ClientTestimonial from '../../../components/HTML/ClientTestimonial';

function Domestcic_Equity() {
  useEffect(() => {
    document.body.classList.add("Bg-white")
    return () => {
      document.body.classList.remove("Bg-white");
    };
  }, []);

  return (
    <div>
      <Herosection />
      <Whydomesticeuityinvest />
      <Ourfeaturesection />
      <BookCalendly title="Ready to Invest in India's Equity?" titleDes="Join the wave of investors tapping into India's promising equity market. Open your Demat and Trading account today—it’s free to get started!" logo={true}/>
      <Security />
      <ClientTestimonial />
      <p style={{height : "3rem"}}></p>
      <FaqAccordian />
      
    </div>
  );
}

export default Domestcic_Equity;
