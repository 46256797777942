import { useRef, useState } from "react";
import Styles from "./style.module.css";
import { getCookie } from "../../../../common_utilities";
const Completed = (props) => {
  const scrollToTop = () => {
    window.scroll({ top: 0 });
  };

  let amount = getCookie("totalAmount")
  
  return (
    <>
      <div
        className={` DeamtBasicDetailsSection ${Styles.BasicDetailsSection}`}
      >
        <div className="mt-5">
          <div>
            <center>
              <img className="ms-2"   src={process.env.REACT_APP_STATIC_URL + "media/DG/Completed.svg"} alt="Completed" />
            </center>
            <div className="text-center">
              <div className={`${Styles.Congratulationstxt}`}>Congratulations</div>
              <div className={`${Styles.Congratulationssubtxt}`}>
                You have successfully started tracking your Indian Stocks
                Portfolio With Fintooinvest
              </div>
              <div className="ButtonBx mt-0 d-md-flex justify-content-center">
                <button type="button" className="Unlink" onClick={() => {
                    scrollToTop();
                    props.onClose();
                    window.location.href = `${process.env.PUBLIC_URL}/datagathering/assets-liabilities?success=1&isstocks=1&stocksamount=${amount}`;
                  }}>
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Completed;
