import { useEffect, useState } from "react";
import Styles from "./style.module.css";
import { Row, Modal } from "react-bootstrap";
import NsdlcsdlInnersection from "./NsdlcsdlInnersection";
import Stepper from "./Stepper";
import BasicDetails from "./BasicDetails";
import Otpverification from "./Otpverification";
import AccoutDetails from "./AccoutDetails";
import Completed from "./Completed";
import MobileStepper from "./MobileStepper";
import { useDispatch, useSelector } from "react-redux";
const Nsdlcsdl = (props) => {
  const dispatch = useDispatch();
  // const triggerRefreshData = useSelector((state)=> state.triggerRefreshData);
  const [showcsdlnsdlModal, setshowcsdlnsdlModal] = useState(false);
  const [linknow, setLinknow] = useState(false);
  const [count, setCount] = useState(0);
  const [dummy, setDummy] = useState({
    username: "",
    mobileNum: "",
    handleId: ""
  })
  // const CloseModal = () => {
  //     setshowcsdlnsdlModal(false)
  // }
  // const CloseModal = () => {
  //     setshowcsdlnsdlModal(false)
  // }
  const [currentPopup, setCurrentPopup] = useState(null);
  const [tab, setTab] = useState("");
  const [actve, setActive] = useState(false);
  const [cdslNsdlResponse, setCdslNsdlResponse] = useState({});
  const onChangepopup = () => {
    setActive(true)
  }
  const handleProceedClick = () => {
    setCount(count + 1);
  };
  const handleBackProceedClick = () => {
    if (count === 2) {
      setCount(0);
    }
  };
  
  // useEffect(()=> {
  //   if(triggerRefreshData && Object.keys(triggerRefreshData).length > 0) {
  //     setActive(true);
  //     setCurrentPopup(0);
  //     dispatch({type: 'TRIGGER_REFRESH_HOLDING', payload: {}});
  //   }
  // }, [triggerRefreshData]);
  return (
    <>
      <div className={`mt-3 ${Styles.NsdlcsdlView}`}>
        <div>
          <div className={`${Styles.NsdlcsdlViewSection}`}>
            <div>
              <div className={`${Styles.NsdlcsdlViewtext}`}>
                Fetch your holdings{" "}
              </div>
              <div className={`${Styles.NsdlcsdlViewdsubtext}`}>
                Link Now its simple and faster then ever  <span onClick={() => {
                  setCurrentPopup(0);
                  setCount(0);
                  setTab("Demat");
                }} className={`${Styles.NsdlcsdlViewLink}`} >Know more</span>
              </div>

            </div>
            <div className="d-md-flex">
              <div
                className={`me-md-4 ${Styles.Holdingsoptview}`}
                onClick={() => {
                  // setshowcsdlnsdlModal(true)
                  setCurrentPopup(0);
                  setCount(0);
                  setTab("Demat");
                }}
              >
                <div className={`${Styles.leftsection}`}>
                  <div className={`${Styles.Holdingsoptviewtext}`}>
                    Link your Demat
                  </div>
                  <div className={`${Styles.PoweredBy}`}>
                    <span>Powered by</span>{" "}
                    <img
                      src={
                        process.env.REACT_APP_STATIC_URL + "media/DG/Finvu.png"
                      }
                      width={"50px"}
                    />
                  </div>
                </div>
                <div className={`${Styles.next}`}>
                  <img
                    src={process.env.REACT_APP_STATIC_URL + "media/DG/Next.svg"}
                    width={"40px"}
                  />
                </div>
              </div>
              {/* <div
                className={`${Styles.Holdingsoptview}`}
                onClick={() => {
                  // setshowcsdlnsdlModal(true)
                  setCurrentPopup(0);
                  setTab("Broker");
                }}
              >
                <div className={`${Styles.leftsection}`}>
                  <div className={`${Styles.Holdingsoptviewtext}`}>
                    Connect With Broker
                  </div>
                  <div className={`${Styles.PoweredBy}`}>
                    <span>Powered by</span>{" "}
                    <img
                      src={
                        process.env.REACT_APP_STATIC_URL +
                        "media/DG/Smallcase.png"
                      }
                      width={"60px"}
                    />
                  </div>
                </div>
                <div className={`${Styles.next}`}>
                  <img
                    src={process.env.REACT_APP_STATIC_URL + "media/DG/Next.svg"}
                    width={"40px"}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* All Modals */}
      <Modal
        dialogClassName="Nsdlcsdl-modal-width"
        show={currentPopup === 0}
        onClose={() => () => { setCurrentPopup(null); }}
        centered
        animationDuration={0}
      >
        {
          actve ? (
            <>
              <div className="" style={{ padding: "0 !important" }}>
                <div className="">
                  <div className="RefreshModalpopup_Heading col-12 d-flex ">
                    <div className={`${Styles.modal_Heading}`}>Link Your Demat</div>
                    <div className={`${Styles.CloseBtnpopup}`}>
                      <img
                        onClick={() => {
                          setCurrentPopup(null);
                          setActive(null)
                        }}
                        style={{ cursor: "pointer", right: 0 }}
                        src={process.env.REACT_APP_STATIC_URL + "media/DG/Close.svg"}
                        alt="Close"
                      />
                    </div>
                  </div>
                  <div className={`modalBody ${Styles.DematmodalBody}`}>
                    <div className={`${Styles.LeftSection}`}>
                      <div className="d-md-block d-none">
                        <Stepper
                          stepnumber="1"
                          text1={"Basic Details "}
                          text2={"Provide your account details"}
                          isActive={count >= 0}
                        />

                        <Stepper
                          stepnumber="2"
                          text1={"OTP Verification"}
                          text2={"Consent to fetch your documents"}
                          isActive={count >= 1}
                        />
                        <Stepper
                          stepnumber="3"
                          text1={"Account Details"}
                          text2={"Your demat related info"}
                          isActive={count >= 2}
                          isNumberMatched={props.isNumberMatched}
                          currentPopup={currentPopup}
                          cdslNsdlResponse={cdslNsdlResponse}
                        />
                        <Stepper
                          stepnumber="4"
                          text1={"Completed"}
                          text2={"Woah, we are here"}
                          isActive={count >= 3}
                        />
                      </div>
                      <div>
                        <div className={`d-flex d-md-none ${Styles.mobileStepper}`}>
                          <MobileStepper isActive={count >= 0} stepnumber="1" />
                          <MobileStepper isActive={count >= 1} stepnumber="2" />
                          <MobileStepper   cdslNsdlResponse={cdslNsdlResponse}
                            isNumberMatched={props.isNumberMatched} currentPopup={currentPopup} isActive={count >= 2} stepnumber="3" />
                          <MobileStepper isActive={count >= 3} stepnumber="4" />
                        </div>
                        <div className="d-md-none d-block pt-2">
                          {
                            count === 0 ? (
                              <div className={count === 0 ? "d-block" : "d-none"}>
                                <div className={`${Styles.stepTitle}`} style={{ color: "#042b62" }}>Basic Details</div>
                                <div className={`${Styles.stepsubTitle}`}>Provide your account details</div>
                              </div>
                            ) : null
                          }
                          {
                            count === 1 ? (
                              <div className={count === 1 ? "d-block" : "d-none"}>
                                <div className={`${Styles.stepTitle}`} style={{ color: "#042b62" }}>OTP Verification</div>
                                <div className={`${Styles.stepsubTitle}`}>Consent to fetch your documents</div>
                              </div>
                            ) : null
                          }
                          {
                            count === 2 ? (
                              <div className={count === 2 ? "d-block" : "d-none"}>
                                <div className={`${Styles.stepTitle}`} style={{ color: "#042b62" }}>Account Details</div>
                                <div className={`${Styles.stepsubTitle}`}>Your demat related info</div>
                              </div>
                            ) : null
                          }
                          {
                            count === 3 ? (
                              <div className={count === 3 ? "d-block" : "d-none"}>
                                <div className={`${Styles.stepTitle}`} style={{ color: "#042b62" }}>Completed</div>
                                <div className={`${Styles.stepsubTitle}`}>Provide your account details</div>
                              </div>
                            ) : null
                          }
                        </div>
                      </div>
                      <div className="d-md-block d-none">
                        <div className={`p-2 ${Styles.Modalbottombody}`}>
                          <div
                            style={{
                              justifyContent: "flex-start",
                            }}
                            className={`${Styles.thirdPartyView}`}
                          >
                            <div className="d-flex align-items-center">
                              <div className={`${Styles.poweredBy}`}>Powered by</div>{" "}
                              <img
                                className="ms-2"
                                width={60}
                                src={
                                  process.env.REACT_APP_STATIC_URL +
                                  "media/DG/Finvu.png"
                                }
                                alt="Close"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${Styles.RightSection}`}>
                      {count === 0 && (
                        <BasicDetails
                          onClose={() => {
                            setCurrentPopup(null);
                            setActive(null)
                          }}
                          onProceedClick={handleProceedClick} setDummy={setDummy} />
                      )}
                      {count === 1 && (
                        <Otpverification
                          onClose={() => {
                            setCurrentPopup(null);
                            setActive(null)
                          }}
                          onProceedClick={(response)=> {
                            handleProceedClick();
                            setCdslNsdlResponse({...response});
                          }} dummy={dummy} />
                      )}
                      {count === 2 && (
                        <AccoutDetails
                          onClose={() => {
                            setCurrentPopup(null);
                            setActive(null)
                          }}
                          onBackProceedClick={handleBackProceedClick}
                          onProceedClick={handleProceedClick} dummy={dummy}
                          cdslNsdlResponse={cdslNsdlResponse}
                        />
                      )}
                      {count === 3 && (
                        <Completed
                          onClose={() => {
                            setCurrentPopup(null);
                            setActive(null)
                          }}
                        />
                      )}
                    </div>
                    <div style={{borderTop : "1px solid #e6e6e6", margin : "0 1.2rem"}}  className={`p-3 mt-2 d-md-none d-block ${Styles.Modalbottombody}`}>
                          <div
                            style={{
                              justifyContent: "flex-end",
                            }}
                            className={`${Styles.thirdPartyView}`}
                          >
                            <div className="d-flex align-items-center">
                              <div className={`${Styles.poweredBy}`}>Powered by</div>{" "}
                              <img
                                className="ms-2"
                                width={60}
                                src={
                                  process.env.REACT_APP_STATIC_URL +
                                  "media/DG/Finvu.png"
                                }
                                alt="Close"
                              />
                            </div>
                          </div>
                        </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="" style={{ padding: "0 !important" }}>
                <div className="">
                  <div className="RefreshModalpopup_Heading col-12 d-flex ">
                    <div className={`${Styles.modal_Heading}`}>Fetch your Holdings</div>
                    <div className={`${Styles.CloseBtnpopup}`}>
                      <img
                        onClick={() => {
                          setCurrentPopup(null);
                          setActive(null)
                        }}
                        style={{ cursor: "pointer", right: 0 }}
                        src={process.env.REACT_APP_STATIC_URL + "media/DG/Close.svg"}
                        alt="Close"
                      />
                    </div>
                  </div>
                  <div className={`modalBody ${Styles.modalBody}`}>
                    <NsdlcsdlInnersection onChangepopup={() => { setActive(true) }} onClose={() => {
                      setCurrentPopup(null);
                    }} tab={tab} setTab={setTab} setNextPopup={() => setCurrentPopup(1)} />
                  </div>
                </div>
              </div>
            </>
          )
        }

      </Modal>
    </>
  );
};
export default Nsdlcsdl;
