import { Modal } from "react-bootstrap";
import FintooBackButton from "../HTML/FintooBackButton";
import FintooCheckbox from "../FintooCheckbox/FintooCheckbox";
import React from "react";
import { getItemLocal, indianRupeeFormat } from "../../common_utilities";
import Table from "react-bootstrap/Table";
import style from "./otpmodal.module.css";
import style2 from "../../Pages/DMF/Portfolio/style.module.css";
import moment from "moment";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

const StopSipSelectionModal = ({
  detailedMfPotfolio,
  setDetailedMfPotfolio,
}) => {
  const dispatch = useDispatch();

  const toggleTrnxCheckbox = (v) => {
    setDetailedMfPotfolio((prev) => ({
      ...prev,
      sip_stp_swp_data: {
        ...prev.sip_stp_swp_data,
        moreSIP: prev.sip_stp_swp_data.moreSIP.map((x) => ({
          ...x,
          checked:
            x.transaction_id === v.transaction_id
              ? !Boolean(x.checked)
              : x.checked,
        })),
      },
    }));
  };

  console.log("detailedMfPotfolio29", detailedMfPotfolio);

  return (
    <>
      <Table responsive className="trx-tbl8">
        <thead>
          <tr>
            <th>SIP Amount</th>
            <th>SIP Date</th>
            {/* <th>Start Date</th>
            <th>End Date</th> */}
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {(detailedMfPotfolio?.sip_stp_swp_data?.moreSIP ?? []).length == 0 && (
            <tr>
              <td colSpan={3}>
                <p className="text-center">No active SIP.</p>
              </td>
            </tr>
          )}
          {(detailedMfPotfolio?.sip_stp_swp_data?.moreSIP ?? []).map((v) => (
            <React.Fragment key={"k-" + v.transaction_id}>
              <tr>
                {/* {console.log('v.sip_start_date')} */}
                <td>
                  <div className="d-flex align-items-center">
                    <span
                      title={
                        v.cart_deleted == "N" ? "Active SIP" : "Inactive SIP"
                      }
                      className={
                        style2[
                          v.cart_deleted == "N"
                            ? "fund-tick-active"
                            : "fund-tick-inactive"
                        ]
                      }
                    ></span>
                    <span>&nbsp;{indianRupeeFormat(v.cart_amount)}</span>
                  </div>
                </td>
                <td>{moment(v.sip_start_date, "YYYY-MM-DD").format("Do")}</td>
                <td scope="row" data-label="">
                  <div className={`${style.SIPButtons}`}>
                    <div className="d-md-block d-none">
                      {getItemLocal("family") != 1 && v.cart_deleted == "N" ? (
                        <button
                          onClick={() => {
                            let date1 = moment(v.sip_start_date, "YYYY-MM-DD")
                              .month(moment().month())
                              .year(moment().year())
                              .startOf("day");
                            let date2 = date1.clone().add(1, "month");
                            let days1 = date1.diff(moment().startOf("day"), "days");
                            let days2 = date2.diff(moment().startOf("day"), "days");
                            // console.log('here====>', days1, days2, date1.format("LLL"), date2.format("LLL"));
                            if (
                              (days1 > 0 && days1 <= 9) ||
                              (days2 > 0 && days2 <= 9)
                            ) {
                              Swal.fire({
                                title: "",
                                text: "Your SIP installment is due within the next 9 days. Please try placing the cancel request later.",
                                icon: "error",
                              });
                            } else {
                              toggleTrnxCheckbox(v);
                            }
                          }}
                        >
                          Stop SIP
                        </button>
                      ) : (
                        <button className="disabled">Stop SIP</button>
                      )}
                    </div>
                    <div className="d-md-none d-block w-100">
                      <center>
                        <button onClick={() => {}}>Manage SIP</button>
                      </center>
                    </div>
                  </div>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </>
  );
};
export default StopSipSelectionModal;
