import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import Herosection from '../../../components/LandingPages/HomePage/Herosection';
import GlobalScorecard from '../../../components/LandingPages/HomePage/GlobalScorecard';
import FeaturesSection from '../../../components/LandingPages/HomePage/FeaturesSection';
import USPSection from '../../../components/LandingPages/HomePage/USPSection';
import ClientTestimonial from '../../../components/HTML/ClientTestimonial';

function Homepage() {
  useEffect(() => {
    document.body.classList.add("Bg-white")
    return () => {
      document.body.classList.remove("Bg-white");
    };
  }, []);

  return (
    <div>
      <Herosection />
      <GlobalScorecard />
      <FeaturesSection />
      <USPSection />
      <ClientTestimonial />
    </div>
  );
}

export default Homepage;
