import React, { memo } from "react";
import GaugeChart from "react-gauge-chart";

const RiskMeter = ({value}) => {

  console.log('v99', value, new Date());

  return (
    <GaugeChart
      animate={false}
      id="gauge-chart1"
      nrOfLevels={6}
      percent={value}
      hideText={false}
      textColor="transparent"
      needleBaseColor="#F35725"
      arcPadding={0.0}
      cornerRadius={0}
      arcWidth={0.29}
      formatTextValue={(value) => `${value}%`}
      needleColor="#000"
      colors={["#a9ba1d", "#fcdf56", "#f8c847", "#edac39", "#e0733c", "#c43f38"]}
      arcsLength={[0.075, 0.075, 0.075, 0.075, 0.075, 0.075]}
    />
  );
}

export default memo(RiskMeter);
