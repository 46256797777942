import React, { useState } from 'react';
import style from './style.module.css';
import SliderInput from './SliderInput';
import InvestmentSummary from './InvestmentSummary';
import InvestmentButton from './InvestmentButton';

const Insurancecalculator = ({title, description}) => {
    const [monthlyInvestment, setMonthlyInvestment] = useState(2000);
    const [period, setPeriod] = useState(0.5);
    const [growthRate, setGrowthRate] = useState(3);
    const [inflationRate, setInflationRate] = useState(2);
    const [outstandingLoanAmount, setOutstandingLoanAmount] = useState(50000);

    const investedValue = monthlyInvestment * period * 12;
    const estimatedReturns = investedValue * (growthRate / 100);
    const totalValue = investedValue + estimatedReturns;

    const handleMonthlyInvestmentChange = (newValue) => {
        setMonthlyInvestment(Number(newValue));
    };

    const handleMonthlyInvestmentBlur = () => {
        setMonthlyInvestment(Math.max(500, Math.min(10000, monthlyInvestment)));
    };

    const handlePeriodChange = (newValue) => {
        setPeriod(Number(newValue));
    };

    const handlePeriodBlur = () => {
        setPeriod(Math.max(0.5, Math.min(15, period)));
    };

    const handleGrowthRateChange = (newValue) => {
        setGrowthRate(Number(newValue));
    };

    const handleGrowthRateBlur = () => {
        setGrowthRate(Math.max(1, Math.min(30, growthRate)));
    };

    const handleInflationRateChange = (newValue) => {
        setInflationRate(Number(newValue));
    };

    const handleInflationRateBlur = () => {
        setInflationRate(Math.max(1, Math.min(30, inflationRate)));
    };

    const handleOutstandingLoanAmountChange = (newValue) => {
        setOutstandingLoanAmount(Number(newValue));
    };

    const handleOutstandingLoanAmountBlur = () => {
        setOutstandingLoanAmount(Math.max(1000, Math.min(1000000, outstandingLoanAmount)));
    };

    const formatPeriod = (value) => {
        if (value < 1) {
            const months = Math.round(value * 12);
            return `${months} ${months > 1 ? 'Months' : 'Month'}`;
        } else {
            const years = Math.floor(value);
            return `${years} ${years > 1 ? 'Years' : 'Year'}`;
        }
    };

    const formatInvestment = (value) => `₹ ${value}`;
    const formatGrowthRate = (value) => `${value} %`;
    const formatInflationRate = (value) => `${value} %`;

    return (
        <div className={`container ${style.Insurancecalculator}`}>
            <div className={style.Insurancecalculatortitle}>{title}</div>
            <div  className={style.Insurancecalculatordescription}>{description}</div>
            <div className={`row ${style.calculatorContainer}`}>
                <div className={`col-md-5 col-12 ${style.leftPanel}`}>
                    <SliderInput
                        label="Current Monthly Expense"
                        min={500}
                        max={10000}
                        value={monthlyInvestment}
                        onChange={handleMonthlyInvestmentChange}
                        formatValue={formatInvestment}
                        unit="₹"
                        onBlur={handleMonthlyInvestmentBlur}
                    />

                    <SliderInput
                        label="Current Age"
                        min={0.5}
                        max={15}
                        step={0.5}
                        value={period}
                        onChange={handlePeriodChange}
                        formatValue={formatPeriod}
                        unit={period < 1 ? 'Month' : 'Years'}
                        onBlur={handlePeriodBlur}
                    />

                    <SliderInput
                        label="Outstanding Loan Amount"
                        min={1000}
                        max={1000000}
                        value={outstandingLoanAmount}
                        onChange={handleOutstandingLoanAmountChange}
                        formatValue={formatInvestment}
                        unit="₹"
                        onBlur={handleOutstandingLoanAmountBlur}
                    />

                    <p>We have kept below few values constant throughout the working of the calculator. However, you can customize them as per your requirements.</p>

                    <SliderInput
                        label="Rate of Return (ROR)"
                        min={1}
                        max={30}
                        value={growthRate}
                        onChange={handleGrowthRateChange}
                        formatValue={formatGrowthRate}
                        unit="%"
                        onBlur={handleGrowthRateBlur}
                    />

                    <SliderInput
                        label="Inflation Rate"
                        min={1}
                        max={30}
                        value={inflationRate}
                        onChange={handleInflationRateChange}
                        formatValue={formatInflationRate}
                        unit="%"
                        onBlur={handleInflationRateBlur}
                    />
                </div>

                <div className={`col-2 ${style.Divider}`}></div>

                <div className={`col-md-5 col-12 ${style.rightPanel}`}>
                    <InvestmentSummary
                        totalLifeCoverNeeded={totalValue}
                        existingLifeCover={0}  // Update as needed or calculate based on additional inputs
                        netLifeCoverNeeded={totalValue - outstandingLoanAmount}
                    />
                    {/* <div className="mt-4">
                        <InvestmentButton />
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Insurancecalculator;
