import React, { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Trade from "../../Assets/Images/CommonDashboard/Trade.png";
import Medicalinsurance from "../../Assets/Images/CommonDashboard/02_Medical_insurance.png";
import DataGathering from "../../Assets/Images/CommonDashboard/01_data_gathering.png";
import FinancialPlan from "../../Assets/Images/CommonDashboard/02_build_financial_plan.png";
import {
  BASE_API_URL,
  CHECK_SESSION,
  ADVISORY_GETEQUIFAX_MEMBER_DATA_API,
  ADVISORY_GET_INSURANCE_COVER_API_URL,
  ADVISORY_GET_NETWORTHLIABILITES_API_URL,
  imagePath,
  ADVISORY_GET_SCORECARD_API_URL,
  DMF_DATAGATHERING_API_URL,
  IS_DIRECT,
} from "../../constants";
import commonEncode from "../../commonEncode";
import RenewPopup from "./RenewPopup";
import Button from "react-bootstrap/Button";
import Modal from "react-responsive-modal";
import {
  apiCall,
  getItemLocal,
  getUserId,
  getParentUserId,
  getFpUserDetailsId,
  restApiCall,
  fetchEncryptData,
  fetchUserData,
  getProfilePercentage,
  getFpLogId,
  fetchParentUserData
} from "../../common_utilities";
import { Link } from "react-router-dom";
import RenewPopupTextbox from "./RenewPopupTextbox";
import KYCTextbox from "./KYCTextbox.js";
import Slider from "react-slick";
import CreditReport from "./CreditScore/CreditReport";
import * as BootModal from "react-bootstrap";
import Fetchloan from "../../Pages/datagathering/AssetsLibDG/CIIBIL_Report/Fetchloan";
import Scorecard from "./Scorecard";
import { useDispatch } from "react-redux";

function CardBox({ lifecyclestatus, renewpopup, subscriptionenddate }) {

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [sessionData, setSessionData] = useState({});
  const [isFetched, setIsFetched] = useState(false);
  const [openModalByName, setOpenModalByName] = useState("");
  const [allMembers, setAllMembers] = useState([]);
  const [defaultSelectedMember, setDefaultSelectedMember] = useState([]);
  const [isPlan, setIsPlan] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [networtliabilitesdata, setNetwortLiabilitesData] = useState({});
  const [lifeinsuranceData, setLifeInsuranceCoverData] = useState("");
  const [basicDetailsCompleted, setBasicDetailsCompleted] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getFpLogId()
    getDataGatheringDetails();
    getEquifaxData();
    if (lifecyclestatus != 0) {
      getNetwortLiabilites();
      getLifeInsuranceCover();
    }
  }, []);

  const userid = getParentUserId();

  const getDataGatheringDetails = async () => {
    try {
      if (getUserId() == null) {
        loginRedirectGuest();
        return;
      }
      const userdata = await fetchParentUserData(true);
      const pan = userdata['pan']
      if (pan != '') {
        setBasicDetailsCompleted(true);
      } else {
        dispatch({ type: "SKIP_DETAILS", payload: 1 });
      }
      setApiSuccess(true);
    } catch (e) {
      console.error(e);
      setError(true);
      setApiSuccess(false)
    }
  }

  const getNetwortLiabilites = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: userid, sky: getItemLocal("rmf"), is_direct: IS_DIRECT };
      let session_data = await apiCall(url, data, true, false);

      // let member_id = props?.member_id ? props.member_id : getUserId();
      let member_id = getFpUserDetailsId();

      // let member_selected = props.member_selected ? "member_id" : "all";
      let member_selected;

      if (getItemLocal("family")) {
        member_selected = "all";
      } else {
        member_selected = "member_id";
      }

      let api_data = {
        fp_log_id: session_data["data"]["fp_log_id"],
        user_id: session_data["data"]["id"],
        fp_user_id: member_id,
        filter_type: member_selected,
        is_direct: IS_DIRECT
      };

      var payload_data = commonEncode.encrypt(JSON.stringify(api_data));
      var networtliabilites_data = await apiCall(
        ADVISORY_GET_NETWORTHLIABILITES_API_URL,
        payload_data,
        false,
        false
      );
      var res = JSON.parse(commonEncode.decrypt(networtliabilites_data));
      if ((res.error_code = "100" && res.data != "")) {
        setNetwortLiabilitesData(res.data);
      } else {
        setNetwortLiabilitesData({ "asset_data": 0, "asset_sum_formatted": "0", "liability_sum": 0, "liability_sum_formatted": "0", "networth_sum": 0, "networth_sum_formatted": "0" });
      }
    } catch (e) {
      setError(true);
    }
  };

  const CloseLoanModal = () => {
    setOpenModalByName(null);
    sliderRef.current.slickPlay();
  };

  const getLifeInsuranceCover = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: userid, sky: getItemLocal("rmf"), is_direct: IS_DIRECT };
      let session_data = await apiCall(url, data, true, false);

      let member_id = getFpUserDetailsId();

      let member_selected = "";

      if (getItemLocal("family")) {
        member_selected = "all";
      } else {
        member_selected = "member_id";
      }

      let api_data = {
        fp_log_id: session_data["data"]["fp_log_id"],
        user_id: session_data["data"]["id"],
        fp_user_id: member_id,
        filter_type: member_selected,
        is_direct: IS_DIRECT
      };
      var payload_data = commonEncode.encrypt(JSON.stringify(api_data));

      var res = await apiCall(
        ADVISORY_GET_INSURANCE_COVER_API_URL,
        payload_data,
        false,
        false
      );

      let decoded_res = JSON.parse(commonEncode.decrypt(res));
      if (decoded_res["error_code"] == "100") {
        setLifeInsuranceCoverData(decoded_res["data"]);
      } else {
        setLifeInsuranceCoverData({ "life_insurance_sum_assured": 0, "life_insurance_sum_assured_formatted": "0", "medical_insurance_sum_assured": 0, "medical_insurance_sum_assured_formatted": "0" });
      }
    } catch (e) {
      setError(true);
    }
  };

  const getEquifaxData = async () => {
    // var session_data = props.sessiondata 
    let url = CHECK_SESSION;
    let data = { user_id: getParentUserId(), sky: getItemLocal("rmf"), is_direct: IS_DIRECT };
    let session_data = await apiCall(url, data, true, false);
    setSessionData(session_data["data"]);

    try {
      var isPlanActive = true;
      // if(session_data.data['user_details'].fp_log_id == null){
      //   isPlanActive = false;
      // }
      // setIsPlan(isPlanActive);
      let reqdata = null;
      if (session_data.data['user_details'].fp_log_id == null) {
        reqdata = {
          user_id: session_data["data"].id,
          is_plan: "0",
        };
        setIsPlan(false);

      } else {
        reqdata = {
          user_id: session_data["data"].id,
          fp_log_id: session_data["data"].fp_log_id,
          is_direct: IS_DIRECT
        };
        setIsPlan(true);
      }

      let respData = await restApiCall(
        ADVISORY_GETEQUIFAX_MEMBER_DATA_API,
        reqdata,
        true,
        false
      );

      if (respData.error_code == "100") {
        // setEquifaxData(respData.data);
        let member_selected = "";

        if (getItemLocal("family")) {
          member_selected = "all";
        } else {
          member_selected = "member_id";
        }
        let member_id = getFpUserDetailsId();

        if (respData.valid_members.length > 0) {
          var valid_members = respData.valid_members;

          const all = respData.valid_members.map((v) => (
            v.relation_id == 1 ?
              {
                name: v.first_name + " " + v.last_name,
                id: v.id,
                dob: v.dob,
                pan: v.PAN,
                mobile: v.alternate_mobile,
                label: v.first_name + " " + v.last_name + " (Self)",
                value: v.id,
              } :
              {
                name: v.first_name + " " + v.last_name,
                id: v.id,
                dob: v.dob,
                pan: v.PAN,
                mobile: v.alternate_mobile,
                label: v.first_name + " " + v.last_name,
                value: v.id,
              }
          ))
          setAllMembers([...all]);

          for (let i = 0; i < valid_members.length; i++) {

            // check for Family option, show for self
            if ((member_selected == "all" && valid_members[i].relation_id == 1) ||
              (member_selected == "member_id" && valid_members[i].id == member_id)) {
              // let credit_score = valid_members[i].cibil_score;
              // setIsFetched(true);
              let mem = valid_members[i];

              if (mem.relation_id == 1) {
                setDefaultSelectedMember({
                  name: mem.first_name + " " + mem.last_name,
                  id: mem.id,
                  dob: mem.dob,
                  pan: mem.PAN,
                  mobile: mem.alternate_mobile,
                  label: mem.first_name + " " + mem.last_name + " (Self)",
                  value: mem.id,
                })
              }
              else {
                setDefaultSelectedMember({
                  name: mem.first_name + " " + mem.last_name,
                  id: mem.id,
                  dob: mem.dob,
                  pan: mem.PAN,
                  mobile: mem.alternate_mobile,
                  label: mem.first_name + " " + mem.last_name,
                  value: mem.id,
                })
              }

              break;
            }
          }
        }
        else {
          setAllMembers([]);
        }

        if (respData.data.length > 0) {
          let equifax_data = respData.data;

          for (let i = 0; i < equifax_data.length; i++) {

            // check for Family option, show for self
            if ((member_selected == "all" && equifax_data[i].relation_id == 1) ||
              (member_selected == "member_id" && equifax_data[i].member_id == member_id)) {
              let credit_score = equifax_data[i].cibil_score;
              setIsFetched(true);
              //   setEquifaxDetails({
              //     id: equifax_data[i].id,
              //     member_id: equifax_data[i].member_id,
              //     first_name: equifax_data[i].first_name,
              //     last_updated: equifax_data[i].Updated_datetime,
              //     can_refresh: equifax_data[i].canRefresh,
              //     refreshDate: equifax_data[i].refreshDate,
              //     cibil_score: equifax_data[i].cibil_score
              //  });
              break;
            }
          }
        }
      }
      else {
        setAllMembers([]);

      }
    } catch (e) {
      console.log(e);
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    autoplaySpeed: 8000,
    autoplay: isLoading ? false : true,
    fade: false,
    slidesToShow: 1,
    // slidesToScroll: 1,
    arrows: false,
    margin: 20,
    dotsClass: "slick-dots categories-slick-dots dashboard-slick-dots",
  };
  // const [forceRerender, setForceRerender] = useState(false);
  const sliderRef = useRef();
  // useEffect(() => {
  //   // Pause the slider initially
  //   if (sliderRef.current) {
  //     sliderRef.current.slickPause();
  //   }

  //   // Start the slider after a delay if not loading
  //   const timer = setTimeout(() => {
  //     if (!isLoading && sliderRef.current) {
  //       sliderRef.current.slickPlay();
  //     }
  //   }, 3000); // Adjust delay time as needed (e.g., 3000 milliseconds = 3 seconds)

  //   return () => {
  //     // Clear the timer if the component unmounts or isLoading changes
  //     clearTimeout(timer);

  //     // Pause the slider if it's still mounted
  //     if (sliderRef.current) {
  //       sliderRef.current.slickPause();
  //     }
  //   };
  // }, [isLoading]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      <div className="d-md-flex justify-content-md-between justify-content-md-center">
        {renewpopup == 2 ?
          <div className="RenewMsgbox mb-3">
            <RenewPopupTextbox showpopup={true} />
          </div>
          : ""}
        {/* {renewpopup != 2 &&
          <div className="RenewMsgbox mb-3">{<KYCTextbox />}</div>
        } */}

      </div>
      <div className="card-box ml-auto">
        <div className="row ms-md-1">
          <div className="col-md-4 col-lg-4 col-12">
            <Slider ref={sliderRef} {...settings}>
              <div className="cardBox GraphImg autoAdvisory">
                <div className="autoAdvisoryLabel">
                  {/* <div onClick={() => setOpenCalendar(true)}>
                  <img
                    src={imagePath + CalendarIcon}
                    width="20px"
                    alt="calendar"
                  />
                  <span className="TodayDate ms-2">
                    {`${date}`}
                    <sup>{addSuffix(`${date}`)}</sup>{" "}
                    {`${monthNames[month.getMonth()]}`} <span>'{`${year}`}</span>
                  </span>
                </div> */}
                  {renewpopup == 2 ? (
                    <>
                      <div className="me-4 mt-4 AssettotalValue">
                        <div className="TextLabel">Assets Value</div>
                        <div className={`d-flex align-items-center`}>
                          <div className={`valueLabel ${networtliabilitesdata && (networtliabilitesdata.asset_sum_formatted || networtliabilitesdata.asset_sum_formatted === 0) ? null : "shine"}`}>
                            ₹{" "}
                            <span >
                              <span className="bigBalue">
                                {networtliabilitesdata &&
                                  networtliabilitesdata.asset_sum_formatted}
                              </span>
                            </span>
                          </div>
                          <div className="ps-4">
                            <Link
                              to={
                                process.env.PUBLIC_URL +
                                "/mutual-fund/portfolio/dashboard/"
                              }
                            >
                              <img
                                width={20}
                                height={20}
                                className="pointer"
                                src={
                                  process.env.REACT_APP_STATIC_URL +
                                  "media/DG/NextImg.svg"
                                }
                              />
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="autoAdvisoryLabel mt-3 d-flex">
                        <div className="borderRight">
                          <a className="text-decoration-none text-black" href="#">
                            <div className="me-3">
                              <div className="TextLabel">Liabilities</div>
                              <div className={`d-flex align-items-center justify-content-between ${networtliabilitesdata && (networtliabilitesdata.liability_sum_formatted || networtliabilitesdata.liability_sum_formatted === 0) ? null : "shine"}`}>
                                <div className="valueLabel">
                                  ₹{" "}
                                  <span>
                                    {networtliabilitesdata &&
                                      networtliabilitesdata.liability_sum_formatted}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="ms-3">
                          <a className="text-decoration-none text-black" href="#">
                            <div className="TextLabel">Net Worth</div>
                            <div className={`d-flex align-items-center justify-content-between ${networtliabilitesdata && (networtliabilitesdata.networth_sum_formatted || networtliabilitesdata.networth_sum_formatted === 0) ? null : "shine"}`}>
                              <div className="valueLabel">
                                ₹{" "}
                                <span className="bigBalue">
                                  {networtliabilitesdata &&
                                    networtliabilitesdata.networth_sum_formatted}
                                </span>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </>
                  ) : (
                    lifecyclestatus == 0 ?
                      (
                        <>
                          <div className="me-4 pt-4  AssettotalValue">
                            <div className="TextLabel">Assets Value</div>
                            <div className={`d-flex align-items-center`}>
                              <div className={`valueLabel`}>
                                ₹{" "}
                                <span >
                                  <span className="bigBalue">0.0</span>
                                </span>
                              </div>
                              <div className="ps-4">
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/mutual-fund/portfolio/dashboard/"
                                  }
                                >
                                  <img
                                    width={20}
                                    height={20}
                                    className="pointer"
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/DG/NextImg.svg"
                                    }
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="autoAdvisoryLabel  mt-3 d-flex">
                            <div className=" ">
                              <a
                                className="text-decoration-none text-black"
                                href={
                                  lifecyclestatus != 0
                                    && process.env.PUBLIC_URL +
                                    "/datagathering/assets-liabilities"
                                    
                                }
                              >
                                <div className="me-3">
                                  <div className="TextLabel">
                                    Net Worth
                                  </div>
                                  <div className={`d-flex align-items-center justify-content-between`}>
                                    <div className="valueLabel">
                                      ₹{" "}
                                      <span className="bigBalue">0.0</span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div style={{ margin: "0 2rem" }} className="borderRight"></div>
                            <div className="ms-3">
                              <a
                                className="text-decoration-none text-black"
                                href={
                                  lifecyclestatus != 0
                                    && process.env.PUBLIC_URL +
                                    "/datagathering/assets-liabilities"
                                    
                                }
                              >
                                <div className="me-3">
                                  <div className="TextLabel">
                                    Liabilities
                                  </div>
                                  <div className={`d-flex align-items-center justify-content-between`}>
                                    <div className="valueLabel">
                                      ₹{" "}
                                      <span>0.0</span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>

                          </div>
                        </>
                      )
                      : (
                        <>
                          <div className="me-4 mt-4 AssettotalValue">
                            <div className="TextLabel">Assets Value</div>
                            <div className={`d-flex align-items-center`}>
                              <div className={`valueLabel ${networtliabilitesdata && (networtliabilitesdata.asset_sum_formatted || networtliabilitesdata.asset_sum_formatted === 0) ? null : "shine"}`}>
                                ₹{" "}
                                <span >
                                  <span className="bigBalue">
                                    {networtliabilitesdata &&
                                      networtliabilitesdata.asset_sum_formatted}
                                  </span>
                                </span>
                              </div>
                              <div className="ps-4">
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/mutual-fund/portfolio/dashboard/"
                                  }
                                >
                                  <img
                                    width={20}
                                    height={20}
                                    className="pointer"
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/DG/NextImg.svg"
                                    }
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="autoAdvisoryLabel mt-3 d-flex">
                            <div className="">
                              <a
                                className="text-decoration-none text-black"
                                href={
                                  lifecyclestatus != 0
                                    && process.env.PUBLIC_URL +
                                    "/datagathering/assets-liabilities"
                                    
                                }
                              >
                                <div className="me-3">
                                  <div className="TextLabel">
                                    {/* {!props.member_selected
                              ? "Family Net Worth"
                              : "Net Worth"} */}
                                    Net Worth
                                  </div>
                                  <div className={`d-flex align-items-center justify-content-between ${networtliabilitesdata && (networtliabilitesdata.networth_sum_formatted || networtliabilitesdata.networth_sum_formatted === 0) ? null : "shine"}`}>
                                    <div className="valueLabel">
                                      ₹{" "}
                                      <span className="bigBalue">
                                        {networtliabilitesdata &&
                                          networtliabilitesdata.networth_sum_formatted}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div className="borderRight" style={{ margin: "0 2rem" }}></div>
                            <div className="ms-3">
                              <a
                                className="text-decoration-none text-black"
                                href={
                                  lifecyclestatus != 0
                                    && process.env.PUBLIC_URL +
                                    "/datagathering/assets-liabilities"
                                    
                                }
                              >
                                <div className="me-3">
                                  <div className="TextLabel">
                                    {/* {!props?.member_selected
                                ? "Overall Liabilities"
                                : "Liabilities"} */}
                                    Liabilities
                                  </div>
                                  <div className={`d-flex align-items-center justify-content-between ${networtliabilitesdata && (networtliabilitesdata.liability_sum_formatted || networtliabilitesdata.liability_sum_formatted === 0) ? null : "shine"}`}>
                                    <div className="valueLabel">
                                      ₹{" "}
                                      <span>
                                        {networtliabilitesdata &&
                                          networtliabilitesdata.liability_sum_formatted}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>

                          </div>
                        </>
                      ))}
                </div>
              </div>
              {/* <div className="cardBox p-0 autoAdvisory mfReportbox" style={{ backgroundColor: "#EEF9FF", }}>
                <div style={{
                  borderRadius: "15px 137px 137px 15px",
                  background: "#fff",
                  width: "60%",
                  height: "100%",
                  padding: "1rem"
                }}>
                  <MFReport title={"MF SCREENING"} />
                </div>
              </div>
              <div className="cardBox p-0 autoAdvisory ParReportbox" style={{ backgroundColor: "#EEF9FF", }}>
                <div style={{
                  borderRadius: "15px 137px 137px 15px",
                  background: "#fff",
                  width: "60%",
                  height: "100%",
                  padding: "1rem"
                }}>
                  <MFReport title={"PORTFOLIO ANALYSIS"} />
                </div>
              </div> */}
            </Slider>
          </div>
          {lifecyclestatus == 0 ? (
            <>
              {/* <div className="cardBox ExpertPlan">
                <div className="d-flex">
                  <div className="mt-4 w-100">
                    <div className="expertplanLabel">
                      Build Your <br />{" "}
                      <span className="Bigtextlabel">Financial Plan</span>
                    </div>
                    <button className="text-uppercase investBtn">
                      <Link
                        to={`${process.env.PUBLIC_URL}/pricing`}
                        className="text-uppercase investBtn"
                      >
                        Start Now
                      </Link>
                    </button>
                  </div>
                </div>
              </div> */}
              <div className="col-md-4 col-lg-4 col-12 cardBox ScoreCardBox FPlan  autoAdvisory ">
                <div className="pt-4" style={{}}>
                  <div style={{
                    fontSize: "1.2rem",
                    fontWeight: '400'
                  }}>Begin Your </div>
                  <div style={{
                    fontSize: "1.5rem", fontWeight: "bold", textTransform: "uppercase"
                  }}>Goal-Based Planning Journey</div>
                </div>

                <div className="">

                  {/* {!basicDetailsCompleted && (
                    apiSuccess ? (<>
                      <button
                        className="custom-background-color custom-border-color pointer"
                        style={{ float: "left" }}
                      > <Link
                        // to={`${process.env.PUBLIC_URL}/mutual-fund/profile/dashboard`}
                        to={`${process.env.PUBLIC_URL}/userflow/expert-nda/`}
                        className="investBtn"
                      >
                          Start Now
                        </Link></button>
                    </>) : (
                      <button
                        className="custom-background-color custom-border-color pointer btn btn-secondary btn-lg"
                        disabled
                      >
                        <span className="investBtn">Start Now</span></button>
                    )
                  )} */}
                  {!basicDetailsCompleted ? (
                    apiSuccess ? (<>
                      <button
                        className="custom-background-color custom-border-color pointer"
                        style={{ float: "left" }}
                      > <Link
                        to={`${process.env.PUBLIC_URL}/userflow/expert-nda/`}
                        className="investBtn"
                      >
                          Start Now
                        </Link></button>
                    </>) : (
                      <button
                        className="custom-background-color custom-border-color pointer btn btn-secondary btn-lg"
                        disabled
                      >
                        <span className="investBtn">Start Now</span></button>
                    )
                  ) : (
                    <button
                      className="custom-background-color custom-border-color pointer"
                      style={{ float: "left" }}
                    > <Link
                      to={process.env.PUBLIC_URL + "/datagathering/about-you"}
                      className="investBtn"
                    >
                        Start Now
                      </Link></button>
                  )

                  }

                </div>
                <div style={{ marginTop: window.innerWidth <= 768 ? '-1rem' : '1.5rem', float: "right" }}>
                  <img
                    width={200}
                    src={
                      process.env.PUBLIC_URL + '/static/media/Person_1.svg'
                    }
                    alt="investment"
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {/* <div className="col-md-4 col-lg-4 col-12 ">
                <div className="bgimage">
                  <div className="text-center">
                    <div className="expertplanLabel">
                      Complete Your <br />{" "}
                      <span className="Bigtextlabel">Data Gathering</span>
                    </div>
                  </div>
                  <center>
                    <div>
                      <img src={"https://stg.minty.co.in/web/static/media/01_data_gathering.61ce1661a6235fddf2a6.png"} width={100} />
                    </div>
                  </center>
                  <di className="text-center">
                    {renewpopup === 2 ? (
                      <button
                        className="text-uppercase investBtn"
                        onClick={onOpenModal}
                      >
                        <a
                          // onClick={() => setRenewPopupShow(true)}
                          className="text-uppercase investBtn"
                        >
                          start now
                        </a>
                      </button>
                    ) : (
                      <button className="text-uppercase investBtn">
                        <a
                          href={process.env.PUBLIC_URL + "/datagathering/about-you"}
                          className="text-uppercase investBtn"
                        >
                          start now
                        </a>
                      </button>
                    )}
                  </di>
                </div>
              </div> */}
              <div className="col-md-4 col-lg-4 col-12 cardBox ScoreCardBox FPlan">
                <div className="pt-4" style={{}}>
                  <div style={{
                    fontSize: "1.2rem",
                    fontWeight: '400'
                  }}> Complete Your</div>
                  <div style={{
                    fontSize: "1.5rem", fontWeight: "bold", textTransform: "uppercase"
                  }}>Data Gathering</div>
                </div>
                <div className="">
                  {renewpopup === 2 ? (
                    <button className="pointer" onClick={onOpenModal}
                      style={{
                        padding: ".4rem 1.4rem",
                        backgroundColor: "#042b62",
                        border: "1px solid #042b62",
                        float: "left"
                      }}>
                      Start Now
                    </button>
                  ) : !basicDetailsCompleted && apiSuccess ?
                    (<>
                      <button className="pointer"
                        style={{
                          // padding: ".4rem 1.4rem",
                          backgroundColor: "#042b62",
                          border: "1px solid #042b62",
                          float: "left"
                        }}>
                        <Link
                          to={process.env.PUBLIC_URL + "/mutual-fund/profile/dashboard/"}
                        >
                          Start Now</Link>
                      </button>
                    </>)
                    : (<>
                      <button className="pointer"
                        style={{
                          // padding: ".4rem 1.4rem",
                          backgroundColor: "#042b62",
                          border: "1px solid #042b62",
                          float: "left"
                        }}>
                        <Link
                          to={process.env.PUBLIC_URL + "/datagathering/about-you"}
                        >
                          Start Now </Link>
                      </button>
                    </>)}
                </div>
              </div>


            </>
          )}
          <div className="col-md-4 col-lg-4 col-12">
            <div className="cardBox  autoAdvisory lifeInsurance">
              {/* <div onClick={() => setOpenCalendar(true)}>
                  <img
                    src={imagePath + CalendarIcon}
                    width="20px"
                    alt="calendar"
                  />
                  <span className="TodayDate ms-2">
                    {`${date}`}
                    <sup>{addSuffix(`${date}`)}</sup>{" "}
                    {`${monthNames[month.getMonth()]}`} <span>'{`${year}`}</span>
                  </span>
                </div> */}
              {renewpopup == 2 ? (
                <>
                  <div className="mt-4 autoAdvisoryLabel d-flex">
                    <div className="d-flex justify-content-end ms-4">
                      <a className="text-decoration-none text-black" href="#">
                        <div>
                          <div className="TextLabel">Medical Cover</div>
                          <div className={`d-flex align-items-center justify-content-between ${lifeinsuranceData && (lifeinsuranceData.medical_insurance_sum_assured_formatted || lifeinsuranceData.medical_insurance_sum_assured_formatted === 0) ? null : "shine"}`}>
                            <div className="valueLabel">
                              &#8377;{" "}
                              <span>
                                {lifeinsuranceData.medical_insurance_sum_assured_formatted}
                              </span>
                            </div>
                          </div>
                        </div>
                      </a>
                      {/* <div className="Imgbox">
                        <img className="" src={Medicalinsurance} width={130} />
                      </div> */}
                    </div>
                    <div className="borderRight"></div>
                    <div className="ms-3">
                      <a className="text-decoration-none text-black" href="#">
                        <div className="me-3">
                          <div className="TextLabel">
                            Life Insurance
                          </div>
                          <div className={`d-flex align-items-center justify-content-between ${lifeinsuranceData && (lifeinsuranceData.life_insurance_sum_assured_formatted || lifeinsuranceData.life_insurance_sum_assured_formatted === 0) ? null : "shine"}`}>
                            <div className="valueLabel">
                              &#8377;{" "}
                              <span className="bigBalue">
                                {lifeinsuranceData.life_insurance_sum_assured_formatted}
                              </span>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div style={{
                      float: "right",
                      marginTop: "1.7rem"

                    }}>
                      <img
                        width={250}
                        src={
                          process.env.REACT_APP_STATIC_URL +
                          "media/DG.svg"
                        }
                      />
                    </div>

                  </div>
                </>
              ) : (lifecyclestatus == 0 ? (
                <>
                  <div className="pt-4 autoAdvisoryLabel">
                    <div className="">
                      <a
                        className="text-decoration-none text-black"
                        href={
                          lifecyclestatus != 0
                            && process.env.PUBLIC_URL +
                            "/datagathering/insurance"
                            
                        }
                      >
                        <div className="me-3">
                          <div className="TextLabel">Life Insurance</div>
                          <div className={`d-flex align-items-center justify-content-between`}>
                            <div className="valueLabel">
                              &#8377;{" "}
                              <span className="bigBalue">0.0</span>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="">
                      <a
                        className="text-decoration-none text-black"
                        href={
                          lifecyclestatus != 0
                            && process.env.PUBLIC_URL +
                            "/datagathering/insurance"
                            
                        }
                      >
                        <div>
                          <div className="TextLabel">Medical Cover</div>
                          <div className={`d-flex align-items-center justify-content-between`}>
                            <div className="valueLabel">
                              &#8377;{" "}
                              <span>0.0</span>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div style={{
                      float: "right",
                      marginTop: "-4.1rem"

                    }}>
                      <img
                        width={200}
                        src={
                          process.env.REACT_APP_STATIC_URL +
                          "media/Mediclaim_Insurance.svg"
                        }
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="mt-4 autoAdvisoryLabel ">
                    <div className="">
                      <a
                        className="text-decoration-none text-black"
                        href={
                          lifecyclestatus != 0
                            && process.env.PUBLIC_URL +
                            "/datagathering/insurance"
                            
                        }
                      >
                        <div className="me-3">
                          <div className="TextLabel">Life Insurance</div>
                          <div className={`d-flex align-items-center justify-content-between ${lifeinsuranceData && (lifeinsuranceData.life_insurance_sum_assured_formatted || lifeinsuranceData.life_insurance_sum_assured_formatted === 0) ? null : "shine"}`}>
                            <div className="valueLabel">
                              &#8377;{" "}
                              <span className="bigBalue">
                                {lifeinsuranceData.life_insurance_sum_assured_formatted}
                              </span>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="d-flex mt-3">
                      <a
                        className="text-decoration-none text-black"
                        href={
                          lifecyclestatus != 0
                            && process.env.PUBLIC_URL +
                            "/datagathering/insurance"
                            
                        }
                      >
                        <div>
                          <div className="TextLabel">Medical Cover</div>
                          <div className={`d-flex align-items-center justify-content-between ${lifeinsuranceData && (lifeinsuranceData.medical_insurance_sum_assured_formatted || lifeinsuranceData.medical_insurance_sum_assured_formatted === 0) ? null : "shine"}`}>
                            <div className="valueLabel">
                              &#8377;{" "}
                              <span>
                                {lifeinsuranceData.medical_insurance_sum_assured_formatted}
                              </span>
                            </div>
                          </div>
                        </div>
                      </a>
                      {/* <div className="Imgbox">
                          <img className="" src={Medicalinsurance} width={130} />
                        </div> */}
                    </div>
                    <div style={{
                      float: "right",
                      marginTop: "-5.1rem"

                    }}>
                      <img
                        width={200}
                        src={
                          process.env.REACT_APP_STATIC_URL +
                          "media/Mediclaim_Insurance.svg"
                        }
                      />
                    </div>
                  </div>
                </>
              ))}
            </div>

          </div>
        </div>
      </div >
      <Modal
        className="Modalpopup"
        open={open}
        showCloseIcon={false}
        onClose={onCloseModal}
        center
      >
        <div className="text-center">
          <h2 className="HeaderText">Attention !!</h2>
          <RenewPopup open={open} onClose={onCloseModal} subscriptionenddate={subscriptionenddate} />
        </div>
      </Modal>

      <BootModal.Modal
        dialogClassName="Nsdlcsdl-modal-width"
        className="Modalpopup"
        show={openModalByName == "Fecth_your_Loan"}
        centered
        animationDuration={0}
      >
        <Fetchloan
          Closemodal={CloseLoanModal}
          session={sessionData}
          allMembers={allMembers}
          isCardBox={true}
          getEquifaxData={getEquifaxData}
          defaultSelectedMember={defaultSelectedMember}
          is_plan={isPlan}
        />
      </BootModal.Modal>
    </>
  );
}

export default CardBox;
