import { useEffect,useState,useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import * as constClass from "../constants";
import Form from "react-bootstrap/Form";
import axios from "axios";
import commonEncode from '../commonEncode';

import * as toastr from 'toastr'
import 'toastr/build/toastr.css';
import FintooLoader from '../components/FintooLoader';
import {setUserId,setItemLocal, fetchEncryptData, clearPortfolioStorage} from "../common_utilities";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";



const RegisterOTP = (props) => {

    const [otp,setOTP]=useState('')
    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [timerOn, setTimer] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [otpErrorMsg, setOtpErrorMsg] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch()
    useEffect(() => {
        document.body.classList.add('main-layout');
        clearPortfolioStorage();
    }, []);
    useEffect(()=>{
        timer(180);
    },[])
    
    const  timer=(remaining)=> {
        var m = Math.floor(remaining / 60);
        var s = remaining % 60;
        m = m < 10 ? '0' + m : m;
        s = s < 10 ? '0' + s : s;
        if(document.getElementById('timer') ==null){
            return false
        }else{
        document.getElementById('timer').innerHTML = m + ':' + s;
        }
        remaining -= 1;
        if (remaining >= 0 && timerOn) {
          setTimeout(function () {
            timer(remaining);
          }, 1000);
          document.getElementById("otp").style.display = "none";
          document.getElementById("timer").style.display = "block";
  
          return;
        }
        else {
          document.getElementById("otp").style.display = "block";
          document.getElementById("timer").style.display = "none";
        }
        if (!timerOn) {
          return;
        }
    }
    const transformEntry=(item, type) =>{
        switch (type) {
          case 'email':
            var parts = item.split("@"), len = parts[0].length;
            return item.replace(parts[0].slice(1, -1), "*".repeat(len - 2));
          case 'mobile':
            console.log('item',item[0]);
            return item[0] + "*".repeat(item.length - 4) + item.slice(-3);
          default:
            throw new Error("Undefined type: " + type);
        }
      }

     console.log('props',props.otp) 
    const getCookie=(cname)=> {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }
    const handleSubmit = (event) =>{

  

       
        event.preventDefault();
        event.stopPropagation();

        if (otp != props.otp){
            console.log('hello')
            setOtpErrorMsg((v)=> ("Entered OTP does not match"))
            return false;

        }
        else{
            console.log('hello')
            setOtpErrorMsg((v)=> (""))
        
        var isFormValid=simpleValidator.current.allValid();

        
        if (isFormValid) {
            registerUserFunction(props.mobileNo,props.email)
        }


    }
    }
    const getUTMSource=()=>{
        var url_string = window.location.href;
        var url = new URL(url_string);
        var utm_source = url.searchParams.get("utm_source");
        if (utm_source) {
            utm_source = utm_source
        } else {
            utm_source = 27
        }
        return utm_source
    }
    // const callbackFunction = async (mobileNo,email,firstName) => {

    //     setIsLoading(true)

    //     var is_expert = getCookie('is_expert');
    //     if(is_expert==''){
    //         var payload = {
    //             fullname: firstName,
    //             mobile: mobileNo,
    //             mailid: email,
    //             country_code:"91",
    //             tags:"fin_web_reg",
    //             utm_source:getUTMSource(),
    //             service:"98",
    //             skip_mail:"1"
    
    //         };
    //         console.log('payload',payload);
    //         var data = JSON.stringify(payload);

    //         try {
    //             var config = {
    //               method: "post",
    //               url: BASE_API_URL+'restapi/callback/',
    //               data: data,
    //             };
          
    //             var res = await axios(config);
                
                
    //             var response_obj = res.data
          
    //             let error_code = response_obj.error_code;
    //             console.log('erropr',error_code);
    //             if (error_code == "0") {
                    
                    
    //                 registerUserFunction(mobileNo,email,firstName)
                
                  
    //             } else {

    //                 toastr.options.positionClass = 'toast-bottom-left';
    //                 toastr.error(response_obj.data)
    //                 setIsLoading(false)
    //             //   dispatch({
    //             //     type: "RENDER_TOAST",
    //             //     payload: { message: response.data, type: "error" },
    //             //     autoClose: 3000,
    //             //   });
    //             }
    //           } catch (e) {
    //             console.log('e',e)
    //           }
    //     }
        
     
    // };
    const registerUserFunction = async (mobileNo,email,firstName) => {

        

        
            var payload = {
                
                user_mobile: mobileNo,
                user_email: email,
                tags:"reg_invshop"
    
            };
            var data = payload

            try {
                
                var config = {
                    method: "POST",
                    url: constClass.RMF_REGISTER_API_URL,
                    data:data,
                  };
                let res = await fetchEncryptData(config); 
                if(res){
                    if (Number(res.error_code) == 100) {
                        setUserId(res.data.user_id)
                        setItemLocal("rmf", res.rmf);
                        let urlParams = new URLSearchParams(window.location.search);
                        let src = urlParams.get("src");
                        let redirectUri = urlParams.get("redirectUri");
                        let urlkey = urlParams.get("urlkey");
                        setIsLoading(false)
                        // dispatch({
                        //     type: "RENDER_TOAST",
                        //     payload: {
                        //     message: "You have Registered Successfully!",
                        //     type: "success",
                        //     },
                        // });
                        // dispatch({
                        //     type: "LOGGIN_LOGOUT",
                        //     payload: true
                        // });
                        window.location = process.env.PUBLIC_URL +"/mutual-fund/portfolio/dashboard/";
                        // navigate(
                        //     process.env.PUBLIC_URL +"/mutual-fund/portfolio/dashboard/"
                        // );
                        
                 
                    }  else {
    
                        toastr.options.positionClass = 'toast-bottom-left';
                        toastr.error(res.message)
                        setIsLoading(false)
                
                    }
                }
            } catch (e) {
            console.log('e',e)
            }
        
     
    };
    return (
        <div>
            <FintooLoader isLoading={isLoading} />

            {/* <div className="login-header">
                <a target="_self" href="/" className="back-arrow">
                    <img
                        src="https://images.minty.co.in/static/userflow/img/icons/back-arrow.svg"
                        alt="Back Arrow"
                    />
                </a>
                <a target="_self" href="/" className="logo">
                    <img
                        src="https://images.minty.co.in/static/userflow/img/logo.svg"
                        alt="Fintoo logo"
                    />
                </a>
            </div> */}

            <section className="login-section">
                <div className="container-fluid">
                    <div className="row align-items-center ">
                        <div className="col-md-7">
                            <div className="login-block">
                                <div className="pt-3"></div>
                                <h2 className="page-header text-center">Enter OTP</h2>
                                <div>

                                    <div className="pt-3"></div>
                                    <div className="pt-3"></div>
                                    <Form onSubmit={handleSubmit}>
                                    <div className="row justify-content-center">
                                        <div className="col-md-8">
                                            <div className="input-code material input">
                                                <input maxLength="5" type="text" tabindex="1" placeholder="" value={otp} onChange={(e)=>{
                                                    setOTP(e.target.value)
                                                }} 
                                                onFocus={() => {
                                                    simpleValidator.current.showMessageFor('otp')
                                                }}
                                                className="default-input"
                                                />

                                            </div>
                                            <div className="text-center">
                                                <p>{simpleValidator.current.message('otp', otp, 'required|numeric|max:5|min:5',{message:{numeric:'Please enter OTP'}})}</p>
                                                <p className="error">{otpErrorMsg}</p>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="row form-row justify-content-center">
                                        <div className="col-md-8 text-center" id="otp" style={{ display: "block" }}>
                                            <a href="#" onClick={()=>{
                                                props.sendSMS(props.mobileNo,props.email);
                                                timer(180)
                                                }} style={{marginBottom: '15px'}} className="blue-link resend-link">
                                                Resend OTP
                                            </a>
                                        </div>
                                    </div>

                                    <div className="row justify-content-center text-center">
                                        <div class="col">
                                            <div class="btn-container">
                                                <input type="submit" name="login" value="Submit" class="default-btn d-block" data-loading-text="Loading..." onSubmit={handleSubmit} />
                                                <div id='timer'
                                                    style={{fontWeight: 'bold', fontSize: '20px', fontStyle: 'bold', color: '#042b62'}}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </Form>
                                    <div className="row form-row otp-msg">
                                        <div className="col">
                                            <div className="bottom-container">
                                                <p className="text-center pt-3">
                                                    We have sent an OTP to your -{" "}
                                                    <span id="sentnumber">{transformEntry(props.email,'email')} &amp; {transformEntry(props.mobileNo,'mobile')}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 registration-illustration h100"></div>
                    </div>
                </div>
            </section>

        </div>
    );
}
export default RegisterOTP;
