import { useEffect } from "react";

import GuestLayout from "../components/Layout/GuestLayout";

const GrievanceMechanism = () => {
  useEffect(() => {
    document.body.classList.add("main-layout");
  }, []);
  return (
    <GuestLayout>
      <section className="privacy-policy-section" style={{ padding: 30 }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Grievance Mechanism</h2>
            </div>
            <div className="col-md-12">
              <p>Client’s queries / complaints may arise due to lack of understanding or a deficiency of service experienced by clients. Deficiency of service may include lack of explanation, clarifications, understanding which escalates into shortfalls in the expected delivery standards, either due to inadequacy of facilities available or through the attitude of staff towards clients.</p>
              <ol>
                <li>Clients can seek clarification to their query and are further entitled to make a complaint in writing, orally or telephonically. An email may be sent to the Client Servicing Team on {" "}
                  <a
                    style={{ color: "royalblue", textDecoration: "underline" }}
                    href="mailto:info@fintoo.in"
                    target="_blank"
                  >
                    support@fintooinvest.in
                  </a>
                </li>
                <br />
                <li>
                A letter may also be written with their query/complaint and posted at the below mentioned address:
                 
                  <br />
                  <b> Mihika Financial Services Pvt Ltd</b> <br />
                  Office No. 403, B Wing, Kanakia Wall Street, <br />
                  Andheri - Kurla Rd, Mumbai, <br />
                  Maharashtra 400093
                  <br />
                </li>
                <br />
                <li>Clients can write to the distributor at {" "}
                  <a
                    style={{ color: "royalblue", textDecoration: "underline" }}
                    href="mailto:operations@fintoo.in"
                    target="_blank"
                  >
                    operation@fintooinvest.in
                  </a>{" "}
                  if the Investor does not receive a response within 10 business days of writing to the Client Servicing Team. The client can expect a reply within 10 business days of approaching the Distributor.
                </li>
                
              </ol>
            </div>
          </div>
        </div>
      </section>
      <div style={{ marginBottom: "5rem"}}></div>
    </GuestLayout>
  );
};
export default GrievanceMechanism;
