import HeaderSection from "../components/HTML/Notices/HeaderSection";
import WealthContactForm from "../components/WealthManagementContactForm/index";
import backImg from "../components/Layout/Fullpage/assets/Tax.jpg";
import FaqSection from "../components/HTML/Notices/FaqSection";
import Fullpage from "../components/Layout/Fullpage";
import FeaturedIn from "../components/HTML/VirtualItrHelpdesk/FeaturedIn";
import NeedHelpSection from "../components/HTML/Notices/NeedHelpSection";
import AppointmentSection from "../components/HTML/Notices/AppointmentSection";
import InsuranceClientTestimonialSection from "../components/HTML/Insurance/InsuranceClientTestimonialSection";
import OurProcess from "../components/HTML/Notices/OurProcess";
import WidgetSection from "../components/HTML/Notices/WidgetSection";
import NoticesType from "../components/HTML/Notices/NoticesType";
import TaxNotice from "../components/HTML/Notices/TaxNotice";
import { servicesTypes } from "../components/WealthManagementContactForm/model";
import { useState } from "react";
import ThankyouSection from "../components/ThankyouSection";
import ClientTestimonial from "../components/HTML/ClientTestimonial";
import AppointmentBox from "../components/Pages/Calendly";
function Notices() {
  const [show, SetShow] = useState(false)

  return (
    <Fullpage style={{ backgroundColor: "white" }}>
      <HeaderSection />
      <TaxNotice />
      <NoticesType />
      {/* <WidgetSection /> */}
      {/* <OurProcess /> */}
      <ClientTestimonial />
      {/* <InsuranceClientTestimonialSection /> */}
      <NeedHelpSection />
      {/* <FeaturedIn headerText={"Companies We Are Proud To Be Associated With."} /> */}
      <AppointmentBox eventCode={'Callback_mintyApp_35'} serviceName="Income Tax Notices" eventUrl={"https://calendly.com/fintoo/15-min-consultation-call-tax-notices?hide_event_type_details=1"}/>
      <FaqSection />
      {/* <section id="ContactUs">
        <WealthContactForm imgSrc={backImg} serviceType={servicesTypes.notices} onSuccess={() => SetShow(true)} />
      </section>
      {show && <ThankyouSection onClose={() => SetShow(false)} />} */}

    </Fullpage>
  );
}
export default Notices;
