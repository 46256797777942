import React, { useState, useEffect, useRef } from 'react';
import Styles from "./style.module.css";
import TeamFintoo from "./about_us.jpg"
const About = () => {
    const myClass = Styles.scrollanimation;
    const aniClass = Styles.animated;
    const animated = useRef(false);
    useEffect(() => {
        // startAnimation();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    const handleScroll = () => {
        const scrollPosition = window.scrollY;

        if (
            scrollPosition >
            document.getElementById("vision").offsetTop -
            document.getElementById("vision").clientHeight &&
            animated.current == false
        ) {
            animated.current = true;
            document
                .querySelectorAll("." + myClass)
                .forEach((v) => v.classList.add(aniClass));
        }
    };

    return (
        <section className={`${Styles.aboutuspage}`}>
            <div className={`${Styles.primargBg}`}>
                <div className={`${Styles.aboutusText}`}>
                    About Us
                </div>
            </div>
            <div className="mt-5">
                <div className={`${Styles.teamSection}`}>
                    <div className={`${Styles.teamImg}`}>
                    <img src={TeamFintoo} alt="team" />
                    </div>
                    <div className={`${Styles.aboutintrocontent}`}>
                        <div>
                            You are the architect of your own destiny, not everyone is a good builder. Here at Financial Hospital we build a solid foundation for you to reach your desired destiny of financial stability through proper foundation and execution of financial planning as well as financial goals. As ever, we would like to be a part of your success.
                        </div>
                        <div className="pt-2">
                            Since 2004, Financial Hospital has successfully serviced each and every client's from India or abroad. Be it Financial Planning, Tax advice or investment planning in equity, debt or alternate category; our team backed by strong research and latest economic trends, are always ready to serve our beloved investor's any queries or needs. Today, with the rich experience of our professional team comprise of CA's, MBA''s, CFP's and other technocrats, a thorough knowledge of the markets, strong leadership, innovative and focused research and having 7 office across India, Financial Hospital itself defines its value and success story.
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${Styles.teamBody}`} id='vision'>
                <div className={`${Styles.teamvisionmission} ${Styles.scrollanimation}`}>
                    <div className={`${Styles.teamvmsection}`}>
                        <div>
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/static/media/wp/FPPlan/stocks.svg"
                                }
                                alt="team_vision" />
                        </div>
                        <div className={Styles.teamvmsectionDesc}>
                            <h2>Vision</h2>
                            <div>
                                To influence our clients with the spirit of progress by continuously providing the means, strategies and products that will help them to enhance their net worth, protect their families security, and help them to create prosperity in all of their business and private financial endeavours.
                            </div>
                        </div>
                    </div>
                    <div className={`mt-5 ${Styles.teamvmsection}`}>
                        <div className={`ms-0 ${Styles.teamvmsectionDesc}`}>
                            <h2>Mission</h2>
                            <div>
                                Our mission is to optimise the long term performance of our clients financial assets. We achieve this by providing tailored advice with respect to the formulation, implementation and maintenance of long term financial strategies.
                            </div>
                            <br />
                            <div>
                                We combine this with an emphasis in the management of each client’s financial affairs. Whether it’s developing a financial planning, creating a customised executive benefit program for a business, implementing a need-based insurance strategy or preparing a detailed asset allocation analysis, our mission is simple; to provide innovative and practical financial program for our valued clients.
                            </div>
                        </div>
                        <div>
                            {/* <img src={"https://fintooinvest.in/assets/img/mission.svg"} alt="team_mission" /> */}
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/static/media/LandingPages/mission.png"
                                }
                                alt="team_mission" />
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};
export default About;
