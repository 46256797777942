import React, { forwardRef } from 'react';
import Style from './style.module.css';
import { Link } from 'react-router-dom';

const CardBox = forwardRef(({ bgColor, textColor, title, description, imageSrc, altText, arrowImg, spanColor, link }, ref) => {
    return (
        <div ref={ref} style={{ background: bgColor }} className={`${Style.cardBox}`}>
            <div className='d-flex justify-content-between align-items-center'>
            <div className='d-md-block d-none'>
                    <img className={`${Style.cardBoximg}`} src={imageSrc} alt={altText} />
                </div>
                <div className={`${Style.textSection}`}>
                    <div style={{ color: textColor }} className={`${Style.cardboxtitle}`}>{title}</div>
                    <div style={{ color: textColor }} className={`${Style.cardboxdes}`}>{description}</div>
                </div>
              
            </div>
        </div>
    );
});

export default CardBox;
