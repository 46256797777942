import { useState } from "react";
import FaqAccordianContent from "./FaqAccordianContent";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import style from "./style.module.css";

function FaqAccordian() {
    const [activeIndex, SetActiveIndex] = useState(0);

    const updateAccordionIndex = (idx) => {
        if (activeIndex === idx) {
            return SetActiveIndex(() => null);
        } else {
            return SetActiveIndex(() => idx);
        }
    };

    const isActive = (idx) => activeIndex === idx;

    return (
        <>
            <div className="container" >
                <div className={`${style.faqtitle}`}>Frequently Asked Questions</div>
                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]} `}
                        onClick={() => updateAccordionIndex(0)}
                        style={isActive(0) ? { color: "#042b62" } : { color: "black" }}
                    >
                        {" "}
                        <span>What are the different types of mutual funds in India?</span>{" "}
                        {isActive(0) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(0)}>
                        <div style={{ display: "grid", gap: "1rem" }}>
                            <div>
                                <span className={`${style.boldtxt}`}>Equity Mutual Funds :</span> Invest primarily in stocks and aim for long-term capital appreciation. Ideal for investors seeking high growth with a higher risk tolerance.
                            </div>
                            <div>
                                <span className={`${style.boldtxt}`}>Debt Mutual Funds :</span> Focus on fixed-income securities like bonds and government papers. Best for conservative investors looking for stable, predictable returns.
                            </div>
                            <div>
                                <span className={`${style.boldtxt}`}>Hybrid Mutual Funds :</span> Combine equity and debt investments to balance risk and return. Suitable for those seeking a mix of growth and stability.
                            </div>
                            <div>
                                <span className={`${style.boldtxt}`}>Index Funds :</span> Track specific market indices like the Nifty 50. They offer broad market exposure with lower management fees.
                            </div>
                            <div>
                                <span className={`${style.boldtxt}`}>Liquid Funds :</span> Invest in short-term money market instruments. They provide high liquidity and are perfect for parking funds temporarily.
                            </div>
                            <div>
                                <span className={`${style.boldtxt}`}>ELSS (Equity Linked Savings Schemes) :</span> Invest mainly in equities and offer tax benefits under Section 80C of the Income Tax Act, with a lock-in period of three years.
                            </div>
                        </div>
                    </FaqAccordianContent>
                </div>
                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]}`}
                        onClick={() => updateAccordionIndex(1)}
                        style={isActive(1) ? { color: "#042b62" } : { color: "black" }}
                    >
                        <span>How to invest in mutual funds in India?</span>{" "}
                        {isActive(1) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(1)}>
                        <div style={{ display: "grid", gap: "1rem" }}>
                            <div>
                                <span className={`${style.boldtxt}`}>Research :</span> Identify mutual funds that align with your financial goals and risk appetite.
                            </div>
                            <div>
                                <span className={`${style.boldtxt}`}>Complete KYC :</span> Submit Know Your Customer (KYC) documents such as identity and address proof, either online or through a mutual fund distributor.
                            </div>
                            <div>
                                <span className={`${style.boldtxt}`}>Choose Investment Mode :</span> Opt for a lump sum investment or Systematic Investment Plan (SIP) for regular contributions.
                            </div>
                            <div>
                                <span className={`${style.boldtxt}`}>Fill Application Form :</span> Provide your details, fund choices, and investment amount.
                            </div>
                            <div>
                                <span className={`${style.boldtxt}`}>Make Payment :</span> Transfer funds via net banking, cheque, or online payment methods.
                            </div>
                            <div>
                                <span className={`${style.boldtxt}`}>Receive Confirmation :</span> You’ll get a confirmation with your folio number and investment details.
                            </div>
                        </div>
                    </FaqAccordianContent>
                </div>
                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]}`}
                        onClick={() => updateAccordionIndex(2)}
                        style={isActive(2) ? { color: "#042b62" } : { color: "black" }}
                    >
                        <span>
                            What is the minimum investment required for mutual funds in India?
                        </span>{" "}
                        {isActive(2) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(2)}>
                        <div style={{ display: "grid", gap: "1rem" }}>
                            <div>
                                The minimum investment amount varies by fund type:
                            </div>
                            <div>
                                <span className={`${style.boldtxt}`}>Equity and Debt Funds :</span> Typically ₹500 to ₹5,000 for SIPs and ₹5,000 to ₹10,000 for lump sum investments.</div>
                            <div>
                                <span className={`${style.boldtxt}`}>ELSS Funds :</span>  Minimum investment is generally ₹500, with a mandatory lock-in period of three years.
                            </div>
                            <div>
                                <span className={`${style.boldtxt}`}>Liquid Funds :</span> Usually starts at ₹10,000.
                            </div>
                            <div style={{ color: "#042b62", fontWeight: "bold" }}>
                                Note: Check specific fund requirements for exact amounts.
                            </div>
                        </div>
                    </FaqAccordianContent>
                </div>

                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]}`}
                        onClick={() => updateAccordionIndex(4)}
                        style={isActive(4) ? { color: "#042b62" } : { color: "black" }}
                    >
                        <span>
                            What is the systematic withdrawal plan (SWP)?
                        </span>{" "}
                        {isActive(4) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(4)}>
                        <div style={{ display: "grid", gap: "1rem" }}>
                            <div>A Systematic Withdrawal Plan (SWP) allows you to withdraw a fixed amount from your mutual fund investment at regular intervals, such as monthly or quarterly. Key benefits include:
                            </div>
                            <div>
                                <span className={`${style.boldtxt}`}>Scheduled Withdrawals :</span> Set up regular withdrawals to meet ongoing financial needs.
                            </div>
                            <div>
                                <span className={`${style.boldtxt}`}>Flexibility :</span> Adjust the amount and frequency of withdrawals as needed.
                            </div>
                            <div>
                                <span className={`${style.boldtxt}`}>Tax Efficiency :</span> Withdrawals are subject to capital gains tax, but long-term investments may benefit from lower rates.
                            </div>
                            <div>SWPs are ideal for creating a steady income stream while maintaining investment growth potential.
                            </div>
                        </div>
                    </FaqAccordianContent>
                </div>
                <div className={`${style.accordianitem}`}>
                    <div
                        className={`${style["accordian-header"]}`}
                        onClick={() => updateAccordionIndex(5)}
                        style={isActive(5) ? { color: "#042b62" } : { color: "black" }}
                    >
                        <span>Can non-residents invest in Indian mutual funds?</span>{" "}
                        {isActive(5) ? <AiOutlineMinus /> : <AiOutlinePlus />}{" "}
                    </div>
                    <FaqAccordianContent activeIndex={isActive(5)}>
                        <div style={{ display: "grid", gap: "1rem" }}>
                            <div>Yes, NRIs can invest in mutual funds in India using just a PAN card and an NRE/NRO account. They must complete the KYC process again, even if they had previously done it as Indian residents. For investing in shares, NRIs will need an NRE/NRO account with a PIS (Portfolio Investment Scheme) bank, along with a Demat account.</div>
                            <div>To start investing in mutual funds, follow these steps:</div>
                            <ol>
                                <li className="pt-2"> <span className={`${style.boldtxt}`}>Consult a financial advisor (FintooInvest)</span></li>
                                <li className="pt-2"> <span className={`${style.boldtxt}`}>Complete KYC</span></li>
                                <li className="pt-2"> <span className={`${style.boldtxt}`}>Link Bank Account</span></li>
                                <li className="pt-2"> <span className={`${style.boldtxt}`}>Start Investing</span></li>
                            </ol>
                        </div>
                    </FaqAccordianContent>
                </div>
            </div>
        </>
    );
}

export default FaqAccordian;
