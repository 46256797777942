import React, { useEffect } from 'react';
import Herosection from '../../../components/LandingPages/Insurance/Herosection';
import Whydomesticeuityinvest from '../../../components/LandingPages/Insurance/Whyinvest';
import Ourfeaturesection from '../../../components/LandingPages/Insurance/Our_Features';
import FaqAccordian from '../../../components/LandingPages/Insurance/FaqAccordian';
import BookCalendly from '../../../components/LandingPages/Domestic_Equity/Calendly';
import Insurancecalculator from '../../../components/LandingPages/Insurance/insurancecalculator'
function InsurancePage() {
  useEffect(() => {
    document.body.classList.add("Bg-white")
    return () => {
      document.body.classList.remove("Bg-white");
    };
  }, []);

  return (
    <div>
      <Herosection />
      <Whydomesticeuityinvest />
      <Insurancecalculator title={"Human Life Value Calculator"} description={"Determine your ideal sum assured effortlessly with our Human Life Value Calculator. Customize key values to get a precise estimate of the financial protection your family needs."} />
      <div id="Book-Calendly">
        <div style={{
          color : "#042b62",
          fontSize : "2rem",
          fontWeight : "500"
        }} className='text-center'>Your Ideal Life Cover to Protect Your Loved Ones</div>
        <BookCalendly title="Ready To Secure Your Future??" titleDes="Get the Coverage You Need in Just a Few Easy Steps!" logo={false} />
      </div>
      <p style={{ height: "3rem" }}></p>
      <FaqAccordian />

    </div>
  );
}

export default InsurancePage;
