import React, { useEffect, useRef } from 'react';
import style from "./style.module.css";

const WhydoInternationalinvestsection = () => {

    return (
        <div className={`container ${style.WhyinvestInternationalSection}`}>
            <div className={`${style.WhyinvestInternationaltitle}`}>
                Why Choose International Equities ?
            </div>
            <div className={`row ${style.FeatureView}`}>
                <div className='col-md-6 col-12 d-flex justify-content-center'>
                    <div className={` ${style.bgImage}`}>
                        <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Growth.svg'} alt="Growth" />
                    </div>
                </div>
                <div className='col-md-6 col-12 mt-md-0 mt-3'>
                    <div className={`${style.investBox}`}>
                        <div className='d-flex align-items-center'>
                            <div className={`${style.numberBox}`}>
                                1
                            </div>
                            <div className={`ms-3 ${style.textBold}`}>High Growth Potential</div>
                        </div>
                        <div className={`${style.investboxDes}`}>Invest in rapidly growing economies and top companies with significant upside potential.</div>
                    </div>
                    <div className={`${style.investBox}`}>
                        <div className='d-flex align-items-center'>
                            <div className={`${style.numberBox}`}>2</div>
                            <div className={`ms-3 ${style.textBold}`}>Leverage Global Market Insights</div>
                        </div>
                        <div className={`${style.investboxDes}`}>Utilize expert knowledge from international markets to make informed investment choices.</div>
                    </div>
                    <div className={`${style.investBox}`}>
                        <div className='d-flex align-items-center'>
                            <div className={`${style.numberBox}`}>3</div>
                            <div className={`ms-3 ${style.textBold}`}>Achieve True Portfolio Diversification</div>
                        </div>
                        <div className={`${style.investboxDes}`}>Enhance your portfolio's stability by integrating both international and domestic assets.</div>
                    </div>
                    <div className={`${style.investBox}`}>
                        <div className='d-flex align-items-center'>
                            <div className={`${style.numberBox}`}>4</div>
                            <div className={`ms-3 ${style.textBold}`}>Regulatory Framework</div>
                        </div>
                        <div className={`${style.investboxDes}`}>Enjoy the security of robust regulations that safeguard your international investments.</div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default WhydoInternationalinvestsection;
