const { Link } = require("react-router-dom");
import { useEffect, useState } from "react";
import styles from "./style.module.css";
import { DMF_GET_USER_PROFILE_STATE_URL } from "../../constants";
import { fetchEncryptData, getProfilePercentage } from "../../common_utilities";
import { fetchUserData } from "../../common_utilities";
import Swal from "sweetalert2";
import nextImg from "./images/next.svg"

const ProfilePercentage = () => {
  const [percent, setPercent] = useState(100);
  const [userData, setUserData] = useState("");

  useEffect(() => {
    userProfileState();
  }, []);

  const userProfileState = async () => {
    try {
      const userdata = await fetchUserData(true);
      setUserData(userdata);
      const p = await getProfilePercentage(userdata);
      console.log("nil 77", p);
      if (p) {
        setPercent(p);
      } else {
        setPercent(0);
      }
    } catch (e) {
      console.log("natu err");
      console.error("natu err", e);
    }
  };

  return (
    <>
      {Number(percent) < 100 && (
        <Link
          to={process.env.PUBLIC_URL + "/mutual-fund/profile"}
          style={{ textDecoration: "none", color: "#000" }}
          onClick={(e) => {
            if (userData.is_minor === "Y" && userData.bse_reg == "Y") {
              e.preventDefault();
              Swal.fire({
                title: "Note!",
                html: "Your account verification is under process. We'll notify you by email once it's completed.",
                icon: "info",
              });
            }
          }}
        >
          <div
            className={`d-flex align-items-center my-profile-complete ${styles["my-profile-complete"]}`}
          >
            <div className={styles["mpc-1"]}>
              {userData.is_minor === "Y" && userData.bse_reg == "Y"
                ? "Authentication In Process"
                : "Complete Your Profile"}
            </div>
            <div className={` align-items-center ${styles["mpc-2"]}`}>
              {Number(percent) === 0 ? <p>&nbsp;&nbsp;</p> : <p>{percent}%</p>}
              <img
                style={{ width: "1.2rem", height: "1.2rem" }}
                src={nextImg}
              />
            </div>
          </div>
        </Link>
      )}
    </>
  );
};
export default ProfilePercentage;
