import React, { useEffect } from 'react'
import ToolsandCalculator from '../../../components/LandingPages/Tools_and_Calculator'

const Tools_and_Calculator = () => {
    useEffect(() => {
        document.body.classList.add("Bg-white")
        return () => {
          document.body.classList.remove("Bg-white");
        };
      }, []);
      
  return (
    <div>
        <ToolsandCalculator />
    </div>
  )
}

export default Tools_and_Calculator