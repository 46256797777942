import React, { useEffect, useRef, useState } from "react";
import Styles from "../../Pages/DMF/Portfolio/report.module.css";
import { Link } from "react-router-dom";
import {
  fetchEncryptData,
  getItemLocal,
  getParentUserId,
  getUserId,
} from "../../common_utilities";
import {
  DMF_SUMMARY_REPORT_URL,
  IS_DIRECT,
  GET_PORTFOLIO_SUMMARY_URL,
  DMF_GETUSERDETAILS_API_URL,
  SUPPORT_INVEST_EMAIL,
  DMF_BASE_URL,
  RMF_EMAIL_API_URL,
  DMF_DETAILED_REPORT_URL,
  GET_OTHER_INVESTMENTS,
  DMF_URL,
} from "../../constants";
import { useDispatch } from "react-redux";
import SweetAlert from "sweetalert-react";
import FintooLoader from "../FintooLoader";
import Modal from "react-responsive-modal";

function MutualFunds() {
  const repdata = {
    fileD: "",
    fileP: "",
    fileS: "",
  };

  const [file, setFile] = useState(repdata);
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [mainData, setMainData] = useState({});
  const [userIds, setUserIds] = useState([]);
  const [otherInvestment, setOtherInvestment] = useState({})
  const [openConfirm, setOpenConfirm] = useState(false);

  const [spinnerEmailLoader, setSpinnerEmailLoaderLoader] = useState(
    {
      spinner1: false,
      spinner2: false,
    }
  );
  const [spinnerLoader, setSpinnerLoader] = useState(
    {
      spinner1: false,
      spinner2: false,
    }
  );

  const btnDownloadRef = useRef({
    summaryDownload: null,
    detailedDownload: null
  });
  const dispatch = useDispatch();

  useEffect(() => {
    onLoadInit();
    familyArray();
  }, []);

  const familyArray = (typeOfArray) => {
    var new_array = [];
    var new_array_pans = [];
    var new_data = getItemLocal("member");
    new_data.forEach((element) => {
      if (element.id !== null) {
        new_array.push(element.id.toString());
      }
      // if (element.pan !== null) {
      //   new_array_pans.push(element.pan);
      // }
    });
    setUserIds(new_array.toString())
  };

  const onLoadInit = async () => {
    try {
      var payload = {
        url: DMF_GETUSERDETAILS_API_URL,
        method: "post",
        data: {
          user_id: getItemLocal("family")
            ? getParentUserId()
            : "" + getUserId(),
          is_direct: '' + IS_DIRECT,
        },
      };

      var res = await fetchEncryptData(payload);

      if (res.error_code * 1 === 103) {
        return setOpenConfirm(true);

      }
      setUserDetails(res.data);
      var payload = {
        url: GET_PORTFOLIO_SUMMARY_URL,
        data: {
          pan: res.data.pan,
          is_direct: IS_DIRECT,
        },
        method: "post",
      };
      var res = await fetchEncryptData(payload);
      setMainData(res);

      var payload = {
        url: GET_OTHER_INVESTMENTS,
        method: "post",
        data: {
          user_id: getItemLocal("family") ? getParentUserId() : "" + getUserId(),
          inv_type: "all",
          is_direct: IS_DIRECT,
        },
      };

      var res = await fetchEncryptData(payload);
      setOtherInvestment(res)
    } catch (e) {
      // console.error(e);
    }
  };

  useEffect(() => {
    if (file.fileS) {
      document.querySelector("#su-download").click();
      // setFileP("");
    }
  }, [file.fileS]);

  const summaryApi = async () => {
    var userId = userIds.split(',').filter(id => id.trim() !== '')
    const summaryRep = {
      user_id: getItemLocal("family") ? userId : [getUserId()],
      is_direct: IS_DIRECT,
      fund_registrar: "all",
    };
    var payload = {
      url: DMF_SUMMARY_REPORT_URL,
      method: "post",
      data: summaryRep,
    };

    var res = await fetchEncryptData(payload);
    return res;
  };

  const summaryData = async () => {
    try {
      if (mainData.error_code * 1 === 103 && otherInvestment.error_code * 1 === 103) {
        return dispatch({
          type: "RENDER_TOAST",
          payload: { message: "No Records Found", type: "error" },
        })
      } else if (mainData.error_code * 1 === 102) {
        return dispatch({
          type: "RENDER_TOAST",
          payload: { message: "Something went wrong..!", type: "error" },
        })
      } 
      // else if (getItemLocal("family")) {
      //   return dispatch({
      //     type: "RENDER_TOAST",
      //     payload: { message: "Please select a member from dropdown", type: "info" },
      //   });
      // }

      if (file.fileD) {
        document.querySelector("#su-download").click();
        return;
      }
      // setIsLoading(true);
      btnDownloadRef.current.summaryDownload.setAttribute("disabled", true);
      setSpinnerLoader((prev) => ({ ...prev, spinner1: true }));
      let res = await summaryApi();
      setSpinnerLoader((prev) => ({ ...prev, spinner1: false }));
      // setIsLoading(false);
      if (res.error_code == "100") {
        const response = await fetch(res.data);
        const blob = await response.blob();
        setFile((prev) => ({ ...prev, fileS: URL.createObjectURL(blob) }));
        btnDownloadRef.current.summaryDownload.removeAttribute("disabled");
      } else if (res.error_code == "103") {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "No records found!",
            type: "info",
          },
        });
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "Something went wrong...",
            type: "error",
          },
        });
      }
    } catch (e) {
      setSpinnerLoader((prev) => ({ ...prev, spinner1: false }));
      console.error(e);
    }
  };

  const fetchMailSummary = async () => {
    try {
      if (mainData.error_code * 1 === 103 && otherInvestment.error_code * 1 === 103) {
        return dispatch({
          type: "RENDER_TOAST",
          payload: { message: "No Records Found", type: "error" },
        })
      } else if (mainData.error_code * 1 === 102) {
        return dispatch({
          type: "RENDER_TOAST",
          payload: { message: "Something went wrong..!", type: "error" },
        })
      } 
      // else if (getItemLocal("family")) {
      //   return dispatch({
      //     type: "RENDER_TOAST",
      //     payload: { message: "Please select a member from dropdown", type: "info" },
      //   });
      // }

      // setIsLoading(true);
      setSpinnerEmailLoaderLoader((prev) => ({ ...prev, spinner1: true }));
      let res = await summaryApi();
      setSpinnerEmailLoaderLoader((prev) => ({ ...prev, spinner1: false }));

      if (res.data) {
        var urlmail = {
          userdata: {
            to: userDetails.email,
          },
          subject: "Fintooinvest Invest - Summary Report",
          template: "transactions_invest.html",
          contextvar: {
            name: userDetails.name,
            SUPPORT_EMAIL: SUPPORT_INVEST_EMAIL,
            report_link: res.data,
            base_url: DMF_BASE_URL,
          },
        };

        var config = {
          method: "post",
          url: RMF_EMAIL_API_URL,
          data: urlmail,
        };

        var resp = await fetchEncryptData(config);
        // setIsLoading(false);
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: resp.message, type: "success" },
        });
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "Email not sent, Something went wrong...",
            type: "error",
          },
        });
      }
    } catch {
      setSpinnerEmailLoaderLoader((prev) => ({ ...prev, spinner1: false }));
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: "Something went wrong", type: "error" },
      });
    }
  };

  useEffect(() => {
    if (file.fileD) {
      document.querySelector("#da-download").click();

    }
  }, [file.fileD]);
  const detailApi = async () => {
    var userId = userIds.split(',').filter(id => id.trim() !== '')
    const detailedRep = {
      user_id: getItemLocal("family") ? userId : [getUserId()],
      is_direct: IS_DIRECT,
      fund_registrar: "all",
    };
    let payload = {
      url: DMF_DETAILED_REPORT_URL,
      method: "post",
      data: detailedRep,
    };
    var res = await fetchEncryptData(payload);
    return res;
  };

  const detailedData = async () => {
    try {
      if (mainData.error_code * 1 === 103) {
        return dispatch({
          type: "RENDER_TOAST",
          payload: { message: "No mutual fund investment found", type: "success" },
        })
      } else if (mainData.error_code * 1 === 102) {
        return dispatch({
          type: "RENDER_TOAST",
          payload: { message: "Something went wrong..!", type: "error" },
        })
      }
      // else if (getItemLocal("family")) {
      //   return dispatch({
      //     type: "RENDER_TOAST",
      //     payload: { message: "Please select a member from dropdown", type: "info" },
      //   });
      // }

      if (file.fileD) {
        document.querySelector("#da-download").click();
        return;
      }
      btnDownloadRef.current.detailedDownload.setAttribute("disabled", true);
      // setIsLoading(true);
      setSpinnerLoader((prev) => ({ ...prev, spinner2: true }));
      let res = await detailApi();
      setSpinnerLoader((prev) => ({ ...prev, spinner2: false }));
      // setIsLoading(false);
      const response = await fetch(res.data);
      const blob = await response.blob();
      setFile((prev) => ({ ...prev, fileD: URL.createObjectURL(blob) }));
      btnDownloadRef.current.detailedDownload.removeAttribute("disabled");
    } catch (e) {
      setSpinnerLoader((prev) => ({ ...prev, spinner2: false }));
      console.error(e);
    }
  };

  const fetchMailDetailed = async () => {
    try {
      if (mainData.error_code * 1 === 103) {
        return dispatch({
          type: "RENDER_TOAST",
          payload: { message: "No mutual fund investment found", type: "success" },
        })
      } else if (mainData.error_code * 1 === 102) {
        return dispatch({
          type: "RENDER_TOAST",
          payload: { message: "Something went wrong..!", type: "error" },
        })
      }

      // setIsLoading(true);
      setSpinnerEmailLoaderLoader((prev) => ({ ...prev, spinner2: true }));
      let res = await detailApi();
      setSpinnerEmailLoaderLoader((prev) => ({ ...prev, spinner2: false }));
      if (res.data) {
        var urlmail = {
          userdata: {
            to: userDetails.email,
          },
          subject: "Fintooinvest Invest - Detailed Report",
          template: "transactions_invest.html",
          contextvar: {
            name: userDetails.name,
            SUPPORT_EMAIL: SUPPORT_INVEST_EMAIL,
            report_link: res.data,
            base_url: DMF_URL,
            is_direct: IS_DIRECT
          },
        };

        var config = {
          method: "post",
          url: RMF_EMAIL_API_URL,
          data: urlmail,
        };

        var resp = await fetchEncryptData(config);
        setIsLoading(false);
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: resp.message, type: "success" },
        });
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "Email not sent, Something went wrong...",
            type: "error",
          },
        });
      }
    } catch (e) {
      setSpinnerEmailLoaderLoader((prev) => ({ ...prev, spinner2: false }));
      console.error(e)
    }
  };

  useEffect(() => {
    if (file.fileP) {
      document.querySelector("#pa-download").click();
      // setFileP("");
    }
  }, [file.fileP]);

  const parApi = async () => {
    const parRep = {
      user_id: "" + getUserId(),
      pan: userDetails.pan,
    };
    var payload_par = {
      url: DMF_PAR_REPORT_URL,

      method: "POST",
      data: parRep,
    };
    var resp = await fetchEncryptData(payload_par);
    return resp;
  };

  const parData = async () => {
    try {
      if (mainData.error_code * 1 === 103) {
        return dispatch({
          type: "RENDER_TOAST",
          payload: { message: "No Record Found", type: "error" },
        })
      } else if (mainData.error_code * 1 === 102) {
        return dispatch({
          type: "RENDER_TOAST",
          payload: { message: "Something went wrong..!", type: "error" },
        })
      } 
      else if (getItemLocal("family")) {
        return dispatch({
          type: "RENDER_TOAST",
          payload: { message: "Please select a member from dropdown", type: "info" },
        });
      }
      if (file.fileP) {
        document.querySelector("#pa-download").click();
        return;
      }
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Please Wait...",
          type: "info",
        },
      });
      setIsLoading(true);
      btnDownloadRef.current.setAttribute("disabled", true);
      let resp = await parApi();
      setIsLoading(false);
      const response = await fetch(resp.pdf_report_link);
      const blob = await response.blob();
      // setFile.fileP(URL.createObjectURL(blob));
      setFile((prev) => ({ ...prev, fileP: URL.createObjectURL(blob) }));
      btnDownloadRef.current.removeAttribute("disabled");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <SweetAlert
        show={openConfirm}
        title="Please complete member's profile!"
        onConfirm={() => {
          setOpenConfirm(false);
          setTimeout(() => {
            window.location =
              process.env.PUBLIC_URL + "/mutual-fund/profile/dashboard";
          }, 100);
        }}
        confirmButtonText="Continue"
      />
      <FintooLoader isLoading={isLoading} />
      <div className="row">
        {/* <div className="col-12 pt-2">
          <FintooLoader
            isLoading={!Boolean(mainData?.error_code)}
            hideText={false}
          />
        </div> */}
        <div className="col-12 col-md-8">
          <div className={`${Styles.DataSection}`}>
            {/* <Link
              className="text-decoration-none text-black"
              to={`${process.env.PUBLIC_URL}/commondashboard/Report-details`}
            >
              <div className={`${Styles.ReportCard}`}>
                <div className="d-flex  align-items-center">
                  <div>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/static/media/RMF/Report/01_capital_gains_Loss_report.svg"
                      }
                      width={40}
                    />
                  </div>
                  <div className={`${Styles.ReportName}`}>
                    Capital Gains & Loss Report
                  </div>
                  <div>
                    <img
                      style={{
                        transform: "rotate(-90deg)",
                      }}
                      width={23}
                      height={23}
                      src={process.env.PUBLIC_URL + "/static/media/down.png"}
                    />
                  </div>
                </div>
              </div>
            </Link> */}

            <div className={`${Styles.ReportCard} `}>
              <div className="d-flex  align-items-center">
                <div>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/static/media/RMF/Report/02_holding_report.svg"
                    }
                    width={40}
                  />
                </div>
                <div className={`${Styles.ReportName}`}>
                  Summary Portfolio Holding Report
                </div>
              </div>
              {/* {!isLoading && ( */}
              <div className={`${Styles.Reportsshareoptions}`}>
                {
                  spinnerEmailLoader.spinner1 === true ? (
                    <div className={`ms-2 ${Styles.downloadSpinner}`}></div>
                  ) : (
                    <img
                      width={20}
                      height={20}
                      title="Share via Mail"
                      onClick={fetchMailSummary}
                      src={
                        process.env.PUBLIC_URL +
                        "/static/media/RMF/Report/email.svg"
                      }
                    />
                  )
                }
                {
                  spinnerLoader.spinner1 === true ? (
                    <div className={`ms-2 ${Styles.downloadSpinner}`}></div>
                  ) : (
                    <img
                      width={18}
                      height={18}
                      title="Download Detail Report"
                      className="ms-3"
                      ref={(ref) => btnDownloadRef.current.summaryDownload = ref}
                      onClick={summaryData}
                      src={
                        process.env.PUBLIC_URL +
                        "/static/media/RMF/Report/download.svg"
                      }
                    />
                  )
                }


                <div style={{ display: "none" }}>
                  <a
                    id="su-download"
                    href={file.fileS}
                    style={{
                      textDecoration: "none",
                    }}
                    download={"Summary_Report_Invest_" + getUserId()}
                  ></a>
                </div>
              </div>
              {/* )} */}
            </div>
            <div className={`${Styles.ReportCard} `}>
              <div className="d-flex justify-content-between  align-items-center">
                <div>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/static/media/RMF/Report/03_performance_report.svg"
                    }
                    width={40}
                  />
                </div>
                <div className={`${Styles.ReportName}`}>
                  Detailed Portfolio Holding Report
                </div>
                <div className={`${Styles.Reportsshareoptions}`}>
                  {
                    spinnerEmailLoader.spinner2 === true ? (
                      <div className={`ms-2 ${Styles.downloadSpinner}`}></div>
                    ) : (
                      <img
                        width={20}
                        height={20}
                        title="Share via Mail"
                        onClick={fetchMailDetailed}
                        src={
                          process.env.PUBLIC_URL +
                          "/static/media/RMF/Report/email.svg"
                        }
                      />
                    )
                  }
                  {
                    spinnerLoader.spinner2 === true ? (
                      <div className={`ms-2 ${Styles.downloadSpinner}`}></div>
                    ) : (
                      <img
                        width={18}
                        height={18}
                        title="Download Detail Report"
                        className="ms-3"
                        ref={(ref) => btnDownloadRef.current.detailedDownload = ref}
                        onClick={detailedData}
                        src={
                          process.env.PUBLIC_URL +
                          "/static/media/RMF/Report/download.svg"
                        }
                      />
                    )
                  }

                  <div style={{ display: "none" }}>
                    <a
                      id="da-download"
                      href={file.fileD}
                      style={{
                        textDecoration: "none",
                      }}
                      download={
                        getItemLocal("family")
                          ? "Portfolio_Detail_Report_Invest_" +
                          getParentUserId() +
                          "_Family"
                          : "Portfolio_Detail_Report_Invest_" + getUserId()
                      }
                    ></a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={`${Styles.ReportCard}`}>
              <div className="d-flex  align-items-center">
                <div>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/static/media/RMF/Report/4_Valuation_Report.svg"
                    }
                    width={40}
                  />
                </div>
                <div className={`${Styles.ReportName}`}>Performance Report</div>
                <div className={`${Styles.Reportsshareoptions}`}>
                  <img
                    width={20}
                    height={20}
                    title="Share via Mail"
                    src={
                      process.env.PUBLIC_URL +
                      "/static/media/RMF/Report/email.svg"
                    }
                  />
                  <img
                    width={18}
                    height={18}
                    title="Download Detail Report"
                    className="ms-3"
                    src={
                      process.env.PUBLIC_URL +
                      "/static/media/RMF/Report/download.svg"
                    }
                  />
                </div>
              </div>
            </div> */}
            {/* <div className={`${Styles.ReportCard}`}>
              <div className="d-flex  align-items-center">
                <div>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/static/media/RMF/Report/05_transaction_report.svg"
                    }
                    width={40}
                  />
                </div>
                <div className={`${Styles.ReportName}`}>Transaction Report</div>
                <div className={`${Styles.Reportsshareoptions}`}>
                  <img
                    width={20}
                    height={20}
                    title="Share via Mail"
                    src={
                      process.env.PUBLIC_URL +
                      "/static/media/RMF/Report/email.svg"
                    }
                  />
                  <img
                    width={18}
                    height={18}
                    title="Download Detail Report"
                    className="ms-3"
                    src={
                      process.env.PUBLIC_URL +
                      "/static/media/RMF/Report/download.svg"
                    }
                  />
                </div>
              </div>
            </div>
            <div className={`${Styles.ReportCard} `}>
              <div className="d-flex  align-items-center">
                <div>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/static/media/RMF/Report/06_ELSS_purchase.svg"
                    }
                    width={40}
                  />
                </div>
                <div className={`${Styles.ReportName}`}>
                  ELSS Purchase Report
                </div>
                <div className={`${Styles.Reportsshareoptions}`}>
                  <img
                    width={20}
                    height={20}
                    title="Share via Mail"
                    src={
                      process.env.PUBLIC_URL +
                      "/static/media/RMF/Report/email.svg"
                    }
                  />
                  <img
                    width={18}
                    height={18}
                    title="Download Detail Report"
                    className="ms-3"
                    src={
                      process.env.PUBLIC_URL +
                      "/static/media/RMF/Report/download.svg"
                    }
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default MutualFunds;
