import { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import pmc from "../../../components/Layout/Commomdashboard/commonDashboard.module.css";
import {
  FaUserAlt,
  FaLongArrowAltUp,
  FaCalendarAlt,
  FaChevronRight,
  FaPrint,
  FaDownload,
} from "react-icons/fa";
import { TbUpload } from "react-icons/tb";
import { MdOutlineEmail } from "react-icons/md";
import { BsFillPrinterFill } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import iciciLogo from "../../../Assets/Images/01_icici.png";
import { ReactComponent as PiggybankIcon } from "../../../Assets/Images/piggybank.svg";
import { ReactComponent as DownloadIcon } from "../../../Assets/Images/download-reports.svg";
import { ReactComponent as DownArrow } from "../../../Assets/Images/down-arr-98.svg";
import ICICI from "../../../Assets/Images/CommonDashboard/ICICIBank.png";
import HDFC from "../../../Assets/Images/CommonDashboard/hdfc.png";
import TATA from "../../../Assets/Images/CommonDashboard/tata-icon.png";
import Bag from "../../../Assets/Images/CommonDashboard/suitcase.png";
import Trade from "../../../Assets/Images/CommonDashboard/Trade.png";
import News1 from "../../../Assets/Images/CommonDashboard/News1.jpg";
import Blog from "../../../Assets/Images/CommonDashboard/blog.jpg";
import Blog1 from "../../../Assets/Images/CommonDashboard/01_blog.png";
import Blog2 from "../../../Assets/Images/CommonDashboard/02_blog.png";
import MutualFunds from "../../../Assets/Images/CommonDashboard/MutualFunds.png";
import IndEquity from "../../../Assets/Images/CommonDashboard/IndEquity.png";
import UsEquity from "../../../Assets/Images/CommonDashboard/UsEquity.png";
import Bonds from "../../../Assets/Images/CommonDashboard/Bonds.png";
import { Link, useNavigate } from "react-router-dom";
import {
  ADVISORY_GET_BLOG_API_URL,
  ADVISORY_GET_GOALS_API_URL,
  BLOG_URL,
  CHECK_SESSION,
  BASE_API_URL,
  GET_MEMBER_LIST,
} from "../../../constants";
import {
  apiCall,
  getItemLocal,
  getParentUserId,
  getUserId,
  loginRedirectGuest,
} from "../../../common_utilities";
import CommonDashboardLayout from "../../../components/Layout/Commomdashboard";
import Carousel from "react-elastic-carousel";
import Item from "./Item";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import CardBox from "../../../components/CommonDashboard/CardBox";
import DashboardSlider from "../../../components/CommonDashboard/DashboardSlider";
import NewsBox from "../../../components/CommonDashboard/NewsBox";
import Profile from "../../../components/CommonDashboard/Profile";
import InvestmentBox from "../../../components/CommonDashboard/InvestmentBox";
import CardBoxGoals from "../../../components/CommonDashboard/CardBoxGoals";
import TotalGoals from "../../../components/CommonDashboard/TotalGoals";
import FintooInlineLoader from "../../../components/FintooInlineLoader";
import BlogBoxSection from "./BlogBoxSection";
// import { getUserId, loginRedirectGuest } from "../../common_utilities";
import commonEncode from "../../../commonEncode";
import RenewPopup from "../../../components/CommonDashboard/RenewPopup";
import Modal from "react-responsive-modal";
import RenewPopupTextbox from "../../../components/CommonDashboard/RenewPopupTextbox";
import NiftySensex from "../../../components/CommonDashboard/NiftySensex";
import { useSelector } from "react-redux";
import BuyTaxPlan from "../../../components/BuyTaxPlan";
import NextImg from "../../../Assets/Images/CommonDashboard/BlogView.svg";
import Goals from "../../../components/CommonDashboard/Goals";

const progressBarValues = [
  {
    title: "Equity",
    value: 20,
    color: "#fd9745",
  },
  {
    title: "Debt",
    value: 10,
    color: "#fe5a01",
  },
  {
    title: "Real Estate",
    value: 14,
    color: "#e3e3e3",
  },
  {
    title: "Liquid",
    value: 18,
    color: "#3598db",
  },
  {
    title: "Alternate",
    value: 10,
    color: "#16a086",
  },
  {
    title: "Gold",
    value: 24,
    color: "#2dcc70",
  },
  {
    title: "Other",
    value: 4,
    color: "#ffcc00",
  },
];
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 4 },
  { width: 1200, itemsToShow: 4 },
];
const NetworthGoals = ({ renewpopup, subscriptionenddate }) => {
  const [returnsType, setReturnsType] = useState("xirr");
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedGoalTab, setSelectedGoalTab] = useState(5);
  const [goalCountData, setGoalCountData] = useState([]);
  const [goalData, setGoalData] = useState([]);
  const [error, setError] = useState(false);
  const [childData, setChildData] = useState("");
  // const [isMemberSelected, setMember] = useState(false);
  const [isMemberSelected, setIsMemberSelected] = useState(false);
  const [isDataLoaded, setDataLoadFlag] = useState(false);
  const [memberID, setMemberID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [planId, setPlanId] = useState('')
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const navigate = useNavigate();
  const interval = useRef(null);
  const timer = useRef(0);


  useEffect(() => {
    getMemberList();
  }, []);

  const startTimer = () => {
    // if idle for more than 10 mins logout
    if (timer.current >= 19) {
      clearInterval(interval.current)
      if (memberID) {
        window.location.href = "https://stg.fintooinvest.in/mutualfunds/logout"
      }
    }
    else {
      timer.current = timer.current + 1
    }
  }
  const incrementTimer = () => {
    interval.current = setInterval(() => {
      startTimer()
    }, 60000);
  }
  const resetTimer = () => {
    clearInterval(interval.current);
    timer.current = 0;
    incrementTimer();
  }

  const getMemberList = async () => {
    try {
      let url = CHECK_SESSION;
      data = { user_id: getParentUserId(), sky: getItemLocal("rmf"), is_direct: 0 };

      let session_data = await apiCall(url, data, true, false);
      setPlanId(session['data']['plan_id'])

      let data = {
        user_id: getParentUserId(),
        is_direct: "0",
      };
      let member_id = null;
      let member_data = await apiCall(GET_MEMBER_LIST, data, true, false);
      if (member_data.error_code == "100") {
        incrementTimer()
        member_data.data.forEach((v) => {
          if (v.id == getUserId()) {
            let fp_user_id = v.fp_user_id;

            setMemberID(fp_user_id);
            member_id = fp_user_id
            // setChildData(fp_user_id);
            setIsMemberSelected(true);
            // if(v.parent_user_id == 0) {
            //   setIsMemberSelected(false);
            // } else {
            //   setIsMemberSelected(true);
            // }
          }

        });
      }

      let member_selected = "member_id";
      let member = "";
      if (getItemLocal("family")) {
        member_selected = "all"
        member = "all"
      }
      else {
        member = member_id.toString()
      }

      try {
        let cache_member_goals = JSON.parse(localStorage.getItem('memberGoals'));

        if (cache_member_goals == null || (Object.keys(cache_member_goals).length == 0)) {
          // if first time page load or all members data changed
          getGoalsAPI(session, member_selected, member_id)
        } else {
          setIsLoading(true);

          // check if member data exist
          if (member in cache_member_goals) {
            let goalss = cache_member_goals[member];
            // if not null goals
            if (goalss) {
              setGoalCountData(goalss['goalsCount']);
              setGoalData(goalss["data"]);
              setIsLoading(false);
              setDataLoadFlag(true);
              setSelectedGoalTab(5);
            }
            else {
              // if no goals
              getGoalsAPI(session, member_selected, member_id)

            }
          }
          else {
            // if first time member clicked
            // getGoalsAPI(session, member_selected, member_id).then(function(res_goals){
            //   // let member_goals = {
            //   //   member: {'data': res_goals['data'], 'goalsCount': res_goals['count_data']}
            //   // }
            //   cache_member_goals[member] = {'data': res_goals['data'], 'goalsCount': res_goals['count_data']};

            //   localStorage.setItem('memberGoals', JSON.stringify(cache_member_goals))
            // });        
            getGoalsAPI(session, member_selected, member_id)

          }

        }

      } catch (e) {
        console.log("err", e);
      }
    } catch (e) {
      console.log("err", e);
    }
  };

  const getGoalsAPI = async (session_data, member_selected, member_id) => {
    let url = ADVISORY_GET_GOALS_API_URL;
    let api_data = {
      user_id: session_data["id"],
      fp_log_id: session_data["fp_log_id"],
      filter_type: member_selected,
      fp_user_id: member_id,
      parent_fp_user_id: session_data["fp_user_id"],
      is_direct: 0
    };
    var payload_data = commonEncode.encrypt(JSON.stringify(api_data));
    setIsLoading(true);

    let res = await apiCall(url, payload_data, false, false);
    let decoded_res = JSON.parse(commonEncode.decrypt(res));

    if (decoded_res["error_code"] == "100") {
      setSelectedGoalTab(5);
      setDataLoadFlag(true);
      setIsLoading(false);
      setGoalData(decoded_res['data']);
      setGoalCountData(decoded_res["count_data"]);

      let member = "";
      if (member_selected == "all") {
        member = "all"
      } else {
        member = member_id.toString()
      }

      let cache_member_goals = JSON.parse(localStorage.getItem('memberGoals'));

      if (cache_member_goals != null) {
        cache_member_goals[member] = { 'data': decoded_res['data'], 'goalsCount': decoded_res['count_data'] }
        localStorage.setItem('memberGoals', JSON.stringify(cache_member_goals))
      } else {
        let member_goals = {
          [member]: { 'data': decoded_res['data'], 'goalsCount': decoded_res['count_data'] }
        }
        localStorage.setItem('memberGoals', JSON.stringify(member_goals))
      }

    } else {
      setDataLoadFlag(true);
      setIsLoading(false);
      setGoalCountData([]);
      setGoalData([]);
    }
  }

  const getGoals = async () => {
    try {
      setIsLoading(true);
      setGoalData([]);
      setGoalCountData([]);
      // let memberList = await getMemberList();
      let url = CHECK_SESSION;
      let data = { user_id: getParentUserId(), sky: getItemLocal("rmf"), is_direct: 0 };

      let session_data = await apiCall(url, data, true, false);

      // let member_id = session_data["data"]["user_details"]["id"];
      let member_id = session_data["data"]["fp_user_id"];
      // if (memberChanged) {

      //   // setChildData("");
      // }
      if (memberChanged) {
        member_id = memberID;

        // setChildData("");
      }
      let member_selected = memberChanged ? "member_id" : "all";
      // setMemberID(member_id);

      let api_data = {
        user_id: session_data["data"]["id"],
        fp_log_id: session_data["data"]["fp_log_id"],
        filter_type: "member_id",
        fp_user_id: member_id,
        parent_fp_user_id: session_data["data"]["fp_user_id"],
        is_direct: 0
      };
      var payload_data = commonEncode.encrypt(JSON.stringify(api_data));

      var res = await apiCall(
        ADVISORY_GET_GOALS_API_URL,
        payload_data,
        false,
        false
      );
      let decoded_res = JSON.parse(commonEncode.decrypt(res));
      if (decoded_res) {
        setDataLoadFlag(true);
        setIsLoading(false);

        // if(res['error_code']=="100"){
        setGoalCountData(decoded_res["count_data"]);
        setGoalData(decoded_res["data"]);
        setSelectedGoalTab(5);
        // }
      } else {
        setIsLoading(false);

        setGoalCountData([]);
        setGoalData([]);
      }
    } catch (e) {
      setError(true);
    }
  };
  return (
    <CommonDashboardLayout>
      {/* <p style={{ height: "2rem" }}></p> */}
      <div
        style={{
          marginTop: ".3rem",
        }}
        onMouseEnter={() => {
          resetTimer();
        }}
        onMouseLeave={() => {
          resetTimer();
        }}
      >
        <div className="row">
          {/* <NiftySensex renewpopup={renewpopup} /> */}
        </div>
        <div className="Section">
          <CardBoxGoals
            renewpopup={renewpopup}
            subscriptionenddate={subscriptionenddate}
            member_id={memberID}
            member_selected={isMemberSelected}
          />
        </div>
        <div className="Section d-block">
                   <div className="ms-0 mb-5">
            <Goals renewpopup={renewpopup} />
            {/* {selectedGoalTab == 5 && (
              <>
                <TotalGoals
                  goals={goalData}
                  goal_type={"all"}
                  member_id={memberID}
                  member_selected={isMemberSelected}
                  is_data={isDataLoaded}
                  renewpopup={renewpopup}
                  onOpenModal={onOpenModal}
                  plan_id={planId}
                />
              </>
            )}
            {selectedGoalTab == 6 && (
              <>
                <TotalGoals
                  goals={goalData}
                  goal_type={"achieved"}
                  member_id={memberID}
                  member_selected={isMemberSelected}
                  is_data={isDataLoaded}
                  renewpopup={renewpopup}
                  onOpenModal={onOpenModal}
                  plan_id={planId}

                />
              </>
            )}
            {selectedGoalTab == 7 && (
              <>
                <TotalGoals
                  goals={goalData}
                  goal_type={"pending"}
                  member_id={memberID}
                  member_selected={isMemberSelected}
                  is_data={isDataLoaded}
                  renewpopup={renewpopup}
                  onOpenModal={onOpenModal}
                  plan_id={planId}

                />
              </>
            )}
            {selectedGoalTab == 8 && (
              <>
                <TotalGoals
                  goals={goalData}
                  goal_type={"upcoming"}
                  member_id={memberID}
                  member_selected={isMemberSelected}
                  is_data={isDataLoaded}
                  renewpopup={renewpopup}
                  onOpenModal={onOpenModal}
                  plan_id={planId}

                />
              </>
            )} */}
          </div>
          <p
            className="mt-md-5"
          // style={{
          //   height: "2rem",
          // }}
          ></p>
        </div>
        {/* <div className="row mt-5  Section">
          <div className="col-12 col-md-8 InvestmentType">
            <div className="insideTabBoxd">
              <div className="insideTabd">
                <div
                  onClick={() => setSelectedTab(1)}
                  className={`pointer d-flex pb-3 ${
                    selectedTab == 1 ? "active" : ""
                  }`}
                >
                  <img width={20} height={20} src={MutualFunds} />
                  <div className="ps-2">Mutual Funds </div>
                </div>
                <div
                  onClick={() => setSelectedTab(2)}
                  className={`pointer d-flex justify-content-center pb-3 ${
                    selectedTab == 2 ? "active" : ""
                  }`}
                >
                  <img width={20} height={20} src={IndEquity} />
                  <div className="ps-2">IND Equity </div>
                </div>
                <div
                  onClick={() => setSelectedTab(3)}
                  className={`pointer d-flex justify-content-center  pb-3 ${
                    selectedTab == 3 ? "active" : ""
                  }`}
                >
                  <img width={20} height={20} src={UsEquity} />
                  <div className="ps-2">US Equity </div>
                </div>
                <div
                  onClick={() => setSelectedTab(4)}
                  className={`pointer d-flex justify-content-center  pb-3 ${
                    selectedTab == 4 ? "active" : ""
                  }`}
                >
                  <img width={20} height={20} src={Bonds} />
                  <div className="ps-2 border-none">FD & Bonds </div>
                </div>
              </div>
            </div>
            <div className="MFData">
              <div className="d-flex justify-content-between">
                <div className="Datatext">
                  <p>Top Picks</p>
                </div>
                <div>
                  <button>Explore All</button>
                </div>
              </div>
              {selectedTab == 1 && (
                <>
                  <InvestmentBox />
                </>
              )}
              {selectedTab == 2 && (
                <>
                  <span>IND Equity</span>
                  <InvestmentBox />
                </>
              )}
              {selectedTab == 3 && (
                <>
                  <span>US Equity</span>
                  <InvestmentBox />
                </>
              )}
              {selectedTab == 4 && (
                <>
                  <span>FD & Bonds</span>
                  <InvestmentBox />
                </>
              )}
            </div>
          </div>
          <div className="col-md-4 mt-0 col-12 FintooBlog">
            <div className="d-flex">
              <p className="text-bold ms-5">Blog</p>
              <div className="mb-3 ms-3">
                <a href={BLOG_URL}>
                  <img className="pointer" src={NextImg} width={20} />
                </a>
              </div>
            </div>
            <div className="BlogBox NetworthBlog">
              <BlogBoxSection />
            </div>
          </div>
        </div> */}
        {/* <div className="Section row">
          <div className="col-md-6 col-12">
            <BuyTaxPlan />
          </div>
          <div className="PlanBox col-md-6 col-12 p-3">
            <div className=" BlogSectionBox">

              <div className="d-flex">
                <p className="text-bold" style={{ color: "#47494c" }}>Blog</p>
              </div>

              <div className="">
                <BlogBoxSection />
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <Modal
        className="Modalpopup"
        open={open}
        showCloseIcon={false}
        onClose={onCloseModal}
        center
      >
        <div className="text-center">
          <h2 className="HeaderText">Attention !!</h2>
          <RenewPopup
            open={open}
            onClose={onCloseModal}
            subscriptionenddate={subscriptionenddate}
          />
        </div>
      </Modal>
    </CommonDashboardLayout>
  );
};

export default NetworthGoals;
