import React, { useState, useEffect } from 'react';
import Styles from "./style.module.css";
import { CiMonitor } from "react-icons/ci";
import { FaCreativeCommonsZero, FaRegMoneyBillAlt } from "react-icons/fa";
import { GiChart } from "react-icons/gi";
import { GrShield } from "react-icons/gr";
import { AiOutlinePieChart, AiOutlineSelect } from "react-icons/ai";
import { MdFamilyRestroom } from "react-icons/md";
const Overview = () => {

    return (
        <section className={`${Styles.Teampage}`}>
            <div className={`${Styles.primargBg}`}>
                <div className={`${Styles.aboutusText}`}>
                    Overview
                </div>
            </div>
            <div className={`${Styles.OverViewSection}`}>
                <div className={`mb-3 ${Styles.overviewDesc}`}>
                    <div>
                        FintooInvest is a one-of-a-kind online platform that ensures you are in control of your investments. Diversified investments across different financial products can be tough to track regularly often leading to missed opportunities for growth. With FintooInvest, managing your investment portfolio has never been easier! This online platform combines all your investments in one place in an organised and systematic manner.
                    </div>
                </div>
                <div className={`${Styles.whyfintooinvest}`}>
                    <div className={`${Styles.title}`}>
                        Why Choose <span style={{
                            color : "#dd7300"
                        }}>FintooInvest</span>
                    </div>
                    <div className={`${Styles["features-4"]} `}>
                        <div className={`${Styles["feature-item"]} `}>
                            <div className={`${Styles["feature-item-icon"]}`}>
                                <CiMonitor />
                            </div>
                            <div className={`${Styles["feature-item-heading"]}`}>
                                {" "}
                                <span>Real-time tracking</span>{" "}
                            </div>
                            <div className={`${Styles["feature-item-content"]}`}>
                                {" "}
                                <span>
                                    Don't wait to see the result of a transaction on your investment portfolio! With FintooInvest, investors can monitor all their transactions in real-time.
                                </span>{" "}
                            </div>
                        </div>
                        <div className={`${Styles["feature-item"]} `}>
                            <div className={`${Styles["feature-item-icon"]}`}>
                                <FaRegMoneyBillAlt />
                            </div>
                            <div className={`${Styles["feature-item-heading"]}`}>
                                {" "}
                                <span>Organised investments</span>{" "}
                            </div>
                            <div className={`${Styles["feature-item-content"]}`}>
                                {" "}
                                <span>
                                    FintooInvest intelligently segregates all your investments systematically based on Asset and Scheme allocation.
                                </span>{" "}
                            </div>
                        </div>
                        <div className={`${Styles["feature-item"]} `}>
                            <div className={`${Styles["feature-item-icon"]}`}>
                                <FaCreativeCommonsZero />
                            </div>
                            <div className={`${Styles["feature-item-heading"]}`}>
                                {" "}
                                <span>No more paperwork</span>{" "}
                            </div>
                            <div className={`${Styles["feature-item-content"]}`}>
                                {" "}
                                <span>
                                    Invest in all listed mutual funds in complete paperless fashion. All the investments are available at a glance digitally instead of maintaining stacks of papers.
                                </span>{" "}
                            </div>
                        </div>
                        <div className={`${Styles["feature-item"]} `}>
                            <div className={`${Styles["feature-item-icon"]}`}>
                                <MdFamilyRestroom />
                            </div>
                            <div className={`${Styles["feature-item-heading"]}`}>
                                {" "}
                                <span>Family solutions</span>{" "}
                            </div>
                            <div className={`${Styles["feature-item-content"]}`}>
                                {" "}
                                <span>
                                    FintooInvest lets you connect your relevant family members with the relevant investments. Simply add your family members to your portfolio and keep them updated. This platform automatically syncs across all devices to keep everyone up-to-date after every transaction.
                                </span>{" "}
                            </div>
                        </div>
                    </div>
                    {/* <div className={`${Styles.contentBox}`}>
                        <div>
                            <img width={100} src={"https://www.fintooinvest.in/assets/img/whyfintoo-real-time-tracking.svg"} />
                        </div>
                        <div className={`${Styles.contentDesc}`}>
                            <div className={`${Styles.contenttitle}`}>Real-time tracking:</div>
                            <div className='pt-2'>Don't wait to see the result of a transaction on your investment portfolio! With FintooInvest, investors can monitor all their transactions in real-time.</div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBox}`}>
                        <div>
                            <img width={100} src={"https://www.fintooinvest.in/assets/img/whyfintoo-organised-investments.svg"} />
                        </div>
                        <div className={`${Styles.contentDesc}`}>
                            <div className={`${Styles.contenttitle}`}>Organised investments:</div>
                            <div className='pt-2'>FintooInvest intelligently segregates all your investments systematically based on Asset and Scheme allocation.</div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBox}`}>
                        <div>
                            <img width={100} src={"https://www.fintooinvest.in/assets/img/whyfintoo-nomore-paperwork.svg"} />
                        </div>
                        <div className={`${Styles.contentDesc}`}>
                            <div className={`${Styles.contenttitle}`}>No more paperwork:</div>
                            <div className='pt-2'>Invest in all listed mutual funds in complete paperless fashion. All the investments are available at a glance digitally instead of maintaining stacks of papers.</div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBox}`}>
                        <div>
                            <img width={100} src={"https://www.fintooinvest.in/assets/img/whyfintoo-family-solutions.svg"} />
                        </div>
                        <div className={`${Styles.contentDesc}`}>
                            <div className={`${Styles.contenttitle}`}>Family solutions:</div>
                            <div className='pt-2'>FintooInvest lets you connect your relevant family members with the relevant investments. Simply add your family members to your portfolio and keep them updated. This platform automatically syncs across all devices to keep everyone up-to-date after every transaction.</div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    );
};
export default Overview;
