import { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Trade from "../../Assets/Images/CommonDashboard/Trade.png";
import CalendarIcon from "../../Assets/Images/CommonDashboard/calendar-323.svg";
import Calendar from "react-calendar";

import Medicalinsurance from "../../Assets/Images/CommonDashboard/03_Medical_insurance.svg";
import moment from "moment";
import axios from "axios";
import {
  addSuffix,
  apiCall,
  getItemLocal,
  getParentUserId,
} from "../../common_utilities";
import {
  ADVISORY_GET_INSURANCE_COVER_API_URL,
  ADVISORY_GET_NETWORTHLIABILITES_API_URL,
  ADVISORY_RISK_APPETITE_API_URL,
  BASE_API_URL,
  IS_DIRECT,
} from "../../constants";
import commonEncode from "../../commonEncode";
import { ADVISORY_GET_SCORECARD_API_URL, CHECK_SESSION } from "../../constants";
import Scorecard from "./Scorecard";
import MeterBox from "./Scorecard";
import RenewPopup from "./RenewPopup";
import Modal from "react-responsive-modal";
import * as BootModal from "react-bootstrap";
import DownloadModal from "./DownloadModal";
import Slider from "react-slick";
import RenewPopupTextbox from "./RenewPopupTextbox";
import KYCTextbox from "./KYCTextbox";
import { Link } from "react-router-dom";
import KYCPopup from "./KYCPopup";

function CardBoxGoals(props) {
  const renewpopup = props.renewpopup;
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDates, setSelecteDates] = useState([]);
  const [error, setError] = useState(false);
  const [scorecardvalue, setScorecardValue] = useState(0);
  const [riskScore, setRiskScore] = useState(0);
  const [riskValue, setRiskValue] = useState("");
  const [ssessiondata, setSessionData] = useState({});
  const [kycDone, setKycDone] = useState(false);
  const [fpDone, setFPDone] = useState(false);
  const date = new Date().getUTCDate();
  const [allMembers, setAllMembers] = useState([]);
  const year = new Date().toLocaleDateString("en", { year: "2-digit" });
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = new Date();
  const [childData, setChildData] = useState("");
  const [memberID, setMemberID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMemberSelected, setMember] = useState(false);
  const [isDataLoaded, setDataLoadFlag] = useState(false);
  const [lifeinsuranceData, setLifeInsuranceCoverData] = useState("");
  const [networtliabilitesdata, setNetwortLiabilitesData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openModalByName, setOpenModalByName] = useState("");
  const [kycModal, setKycModal] = useState(false);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [GraphUrl, setGraphUrl] = useState("");
  const [paymentDone, setPaymentDone] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [defaultSelectedMember, setDefaultSelectedMember] = useState([]);
  const sliderRef = useRef();
  useEffect(() => {
    // getSummary();
    getRiskProfile();
    getLifeInsuranceCover();
    getNetwortLiabilites();
  }, [props.member_id, props.member_selected]);

  //console.log('props.member_id nil665', props.member_id, props.member_selected);

  const userid = getParentUserId();
  const getSummary = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: userid, sky: getItemLocal("rmf"), is_direct: 0 };
      let session_data = await apiCall(url, data, true, false);

      setSessionData(session_data["data"]);
      let api_data = {
        fp_log_id: session_data["data"]["fp_log_id"],
        user_id: session_data["data"]["id"],
        fp_user_id: session_data["data"]["fp_user_id"],
        is_direct: 0
      };

      var payload_data = commonEncode.encrypt(JSON.stringify(api_data));
      var scorecard_data = await apiCall(
        ADVISORY_GET_SCORECARD_API_URL,
        payload_data,
        false,
        false
      );
      var res = JSON.parse(commonEncode.decrypt(scorecard_data));
      if ((res.error_code = "100" && res.data != "")) {
        setScorecardValue(res.data.total_score);
      } else {
        setScorecardValue(0);
      }
    } catch (e) {
      setError(true);
    }
  };

  const getRiskProfile = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: userid, sky: getItemLocal("rmf"), is_direct: 0 };
      let session_data = await apiCall(url, data, true, false);
      let api_data = {
        fp_log_id: session_data["data"]["fp_log_id"],
        user_id: session_data["data"]["id"],
        is_direct: 0
      };

      var payload_data = commonEncode.encrypt(JSON.stringify(api_data));
      var risk_profile_data = await apiCall(
        ADVISORY_RISK_APPETITE_API_URL,
        payload_data,
        false,
        false
      );
      var response = JSON.parse(commonEncode.decrypt(risk_profile_data));
      if (response.error_code = "100") {
        let riskProfile = response["data"][0]["field2"];
        let riskIndicator = 0;
        if (riskProfile == "Highly Conservative") {
          riskIndicator = 7;
        } else if (riskProfile == "Conservative") {
          riskIndicator = 25;
        } else if (riskProfile == "Moderate") {
          riskIndicator = 44;
        } else if (riskProfile == "Aggressive") {
          riskIndicator = 62;
        } else if (riskProfile == "Highly Aggressive") {
          riskIndicator = 80;
        }
        setRiskScore(riskIndicator);
        setRiskValue(riskProfile);
      }
    } catch (e) {
      setError(true);
    }
  }

  const getNetwortLiabilites = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: userid, sky: getItemLocal("rmf"), is_direct: 0 };
      let session_data = await apiCall(url, data, true, false);
      let member_id = props.member_id;

      let member_selected = props.member_selected ? "member_id" : "all";

      let api_data = {
        fp_log_id: session_data["data"]["fp_log_id"],
        user_id: session_data["data"]["id"],
        fp_user_id: member_id,
        filter_type: member_selected,
        is_direct: IS_DIRECT
      };

      var payload_data = commonEncode.encrypt(JSON.stringify(api_data));
      var networtliabilites_data = await apiCall(
        ADVISORY_GET_NETWORTHLIABILITES_API_URL,
        payload_data,
        false,
        false
      );
      var res = JSON.parse(commonEncode.decrypt(networtliabilites_data));

      console.log("Payload Data:", payload_data); // Check if payload_data is correct
      console.log("API Response:", res); // Log the decrypted response

      if ((res.error_code = "100" && res.data != "")) {
        console.log("Networth Liabilities Data:", res.data);
        setNetwortLiabilitesData(res.data);
      } else {
        setNetwortLiabilitesData({});
      }
    } catch (e) {
      setError(true);
    }
  };
  const getLifeInsuranceCover = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: userid, sky: getItemLocal("rmf"), is_direct: 0 };
      let session_data = await apiCall(url, data, true, false);

      let member_id = props.member_id;

      let member_selected = props.member_selected ? "member_id" : "all";

      let api_data = {
        fp_log_id: session_data["data"]["fp_log_id"],
        user_id: session_data["data"]["id"],
        fp_user_id: member_id,
        filter_type: member_selected,
        is_direct: 0
      };
      var payload_data = commonEncode.encrypt(JSON.stringify(api_data));

      var res = await apiCall(
        ADVISORY_GET_INSURANCE_COVER_API_URL,
        payload_data,
        false,
        false
      );

      let decoded_res = JSON.parse(commonEncode.decrypt(res));

      console.log("decoded_res", decoded_res["data"])

      if (decoded_res["error_code"] == "100") {
        setDataLoadFlag(true);
        setIsLoading(false);
        setLifeInsuranceCoverData(decoded_res["data"]);
      } else if (decoded_res["error_code"] == "103") {
        setIsLoading(false);
        setLifeInsuranceCoverData({
          life_insurance_sum_assured_formatted: 0.0,
          medical_insurance_sum_assured_formatted: 0.0
        });
      } else {
        setDataLoadFlag(true);
        setIsLoading(false);
        setLifeInsuranceCoverData("");
      }

    } catch (e) {
      setError(true);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 8000,
    autoplay: true,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    margin: 20,
    centerPadding: "20",
    dotsClass: "slick-dots categories-slick-dots dashboard-slick-dots",
  };

  return (
    <>
      <div className="d-md-flex justify-content-md-between justify-content-md-center mb-4">
        {renewpopup == 2 ? (
          <div className="RenewMsgbox">
            <RenewPopupTextbox showpopup={true} />
          </div>
        ) : (
          ""
        )}
        {/* {renewpopup != 2 && <div className="RenewMsgbox">{<KYCTextbox />}</div>} */}
      </div>
      <div className="card-box GoalBox ml-auto" style={{ margin: "0 1rem" }}>
        <div className="row d-flex align-items-center">
          <div className="col-md-4 col-lg-4 col-12">
            <div className="cardBox GraphImg autoAdvisory" id="GraphImage"
              // style={{ backgroundImage: `url(${GraphUrl})` }}
            // style={{backgroundImage: 'url(${imagePath} + "https://stg.minty.co.in" + ${Graph})'}}
            >
              {/* <div className="d-flex align-items-center" onClick={() => setOpenCalendar(true)}>
              <img
                // src={
                //   process.env.REACT_APP_STATIC_URL +
                //   "media/DMF/calendar-323.png"
                // }
                src={imagePath + CalendarIcon}
                width="20px"
                alt="calendar"
              />
              <span className="TodayDate ms-2">
                {`${date}`}
                <sup>{addSuffix(`${date}`)}</sup>{" "}
                {`${monthNames[month.getMonth()]}`} <span>'{`${year}`}</span>
              </span>
            </div> */}
              {renewpopup == 2 ? (
                <>
                  <div className="me-4 mt-4 AssettotalValue">
                    <div className="TextLabel">
                      {/* {!props.member_selected
                      ? "Family Net Worth"
                      : "Net Worth"} */}
                      Assets Value
                    </div>
                    <div className={`d-flex align-items-center`}>
                      <div className={`valueLabel ${networtliabilitesdata && (networtliabilitesdata.asset_sum_formatted || networtliabilitesdata.asset_sum_formatted === 0) ? null : "shine"}`}>
                        ₹{" "}
                        <span >
                          <span className="bigBalue">
                            {networtliabilitesdata &&
                              networtliabilitesdata.asset_sum_formatted}
                          </span>
                        </span>
                      </div>
                      <div className="ps-4">
                        <Link to={process.env.PUBLIC_URL + "/mutual-fund/portfolio/dashboard/"}>
                          <img width={20} height={20} className="pointer" src={
                            process.env.REACT_APP_STATIC_URL +
                            "media/DG/NextImg.svg"
                          } />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="autoAdvisoryLabel mt-3 d-flex">
                    <div>
                      <a
                        className="text-decoration-none text-black"
                        onClick={onOpenModal}
                      >
                        <div className="">
                          <div className="TextLabel">
                            {!props.member_selected
                              ? "Family Net Worth"
                              : "Net Worth"}
                          </div>
                          <div className={`d-flex align-items-center justify-content-between ${networtliabilitesdata && (networtliabilitesdata.networth_sum_formatted || networtliabilitesdata.networth_sum_formatted === 0) ? null : "shine"}`}>
                            <div className="valueLabel">
                              ₹{" "}
                              <span className="bigBalue">
                                {networtliabilitesdata &&
                                  networtliabilitesdata.networth_sum_formatted}
                              </span>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="borderRight" style={{ margin: "0 2rem" }}></div>
                    <div className="">
                      <a
                        className="text-decoration-none text-black"
                        onClick={onOpenModal}
                      >
                        <div className="me-4">
                          <div className="TextLabel">
                            {!props.member_selected
                              ? "Overall Liabilities"
                              : "Liabilities"}
                          </div>
                          <div className={`d-flex align-items-center justify-content-between ${networtliabilitesdata && (networtliabilitesdata.liability_sum_formatted || networtliabilitesdata.liability_sum_formatted === 0) ? null : "shine"}`}>
                            <div className="valueLabel">
                              ₹{" "}
                              <span>
                                {networtliabilitesdata &&
                                  networtliabilitesdata.liability_sum_formatted}
                              </span>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>

                  </div>
                </>
              ) : (
                <>
                  <div className="me-4 mt-4 AssettotalValue border-0">
                    <div className="TextLabel">
                      {/* {!props.member_selected
                      ? "Family Net Worth"
                      : "Net Worth"} */}
                      Assets Value
                    </div>
                    <div className={`d-flex align-items-center`}>
                      <div className={`valueLabel ${networtliabilitesdata && (networtliabilitesdata.asset_sum_formatted || networtliabilitesdata.asset_sum_formatted === 0) ? null : "shine"}`}>
                        ₹{" "}
                        <span >
                          <span className="bigBalue">
                            {networtliabilitesdata &&
                              networtliabilitesdata.asset_sum_formatted}
                          </span>
                        </span>
                      </div>
                      <div className="ps-4">
                        <Link to={process.env.PUBLIC_URL + "/mutual-fund/portfolio/dashboard/"}>
                          <img width={20} height={20} className="pointer" src={
                            process.env.REACT_APP_STATIC_URL +
                            "media/DG/NextImg.svg"
                          } />
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="autoAdvisoryLabel mt-3 d-flex">
                    <div>
                      <a
                        className="text-decoration-none text-black"
                        href={
                          ssessiondata.plan_id === 29
                            ? process.env.PUBLIC_URL + "/report/assets-liabilities?tab=networth"
                            : process.env.PUBLIC_URL + "/datagathering/assets-liabilities"
                        }
                      >
                        <div className="">
                          <div className="TextLabel">
                            {!props.member_selected
                              ? "Family Net Worth"
                              : "Net Worth"}
                          </div>
                          <div className={`d-flex align-items-center justify-content-between ${networtliabilitesdata && (networtliabilitesdata.networth_sum_formatted || networtliabilitesdata.networth_sum_formatted === 0) ? null : "shine"}`}>
                            <div className="valueLabel">
                              ₹{" "}
                              <span className="bigBalue">
                                {networtliabilitesdata &&
                                  networtliabilitesdata.networth_sum_formatted}
                              </span>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div style={{ margin: "0 2rem" }} className="borderRight"></div>
                    <div >
                      <a
                        className="text-decoration-none text-black"
                        href={
                          ssessiondata.plan_id === 29
                            ? process.env.PUBLIC_URL + "/report/assets-liabilities?tab=liabilities"
                            : process.env.PUBLIC_URL + "/datagathering/assets-liabilities"
                        }
                      >
                        <div className="me-4">
                          <div className="TextLabel">
                            {!props.member_selected
                              ? "Overall Liabilities"
                              : "Liabilities"}
                          </div>
                          <div className={`d-flex align-items-center justify-content-between ${networtliabilitesdata && (networtliabilitesdata.liability_sum_formatted || networtliabilitesdata.liability_sum_formatted === 0) ? null : "shine"}`}>
                            <div className="valueLabel">
                              ₹{" "}
                              <span>
                                {networtliabilitesdata &&
                                  networtliabilitesdata.liability_sum_formatted}
                              </span>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>

                  </div>
                </>
              )}
            </div>
          </div>
          <div className="cardBox col-md-4 col-lg-4 col-12 ScoreCardBox mt-md-0 mt-5" id="scorecard">
            <div>Risk Profile</div>
            <div style={{
              fontSize: "1.6rem"
            }} className="text-bold">{riskValue}</div>
            <div style={{ margin: "1rem 0rem" }}>
              <Scorecard value={riskScore} width={170} />
            </div>
            <div className="d-flex justify-content-start">
              {(ssessiondata.plan_id === 29 ||
                (ssessiondata.plan_id === 31 &&
                  ssessiondata.plan_payment_status == "1")) && (
                  <>
                    {renewpopup === 2 ? (
                      <button onClick={onOpenModal} className="viewReport pointer custom-background-color custom-border-color">
                        View Report
                      </button>
                    ) : !kycDone || !fpDone ? (
                      <button
                        onClick={() => setKycModal(true)}
                        className="viewReport pointer custom-background-color custom-border-color"
                      >
                        View Report
                      </button>
                    ) : (
                      <button
                        onClick={() => setOpenModal(true)}
                        className="viewReport pointer custom-background-color custom-border-color"
                      >
                        View Report
                      </button>
                    )}
                  </>
                )}

              <button
                style={{
                  background: "none !important"
                }}
                className="pointer custom-background-color custom-border-color EditData"
              >
                {renewpopup === 2 ? (
                  <a
                    onClick={() => {
                      onOpenModal();
                    }}
                    className="pointer">
                    Edit Data
                  </a>
                ) : (
                  <a
                    href={process.env.PUBLIC_URL + "/datagathering/about-you"}
                    className="pointer"
                  >
                    Edit Data
                  </a>
                )}
              </button>
            </div>
          </div>

          <DownloadModal
            show={openModal}
            onHide={() => setOpenModal(false)}
            ssessiondata={ssessiondata}
          />

          <div className="col-md-4 col-lg-4 col-12">
            <div className="cardBox  autoAdvisory lifeInsurance">
              {/* <div className="d-flex align-items-center" onClick={() => setOpenCalendar(true)}>
                <img
                  src={imagePath + CalendarIcon}
                  width="20px"
                  alt="calendar"
                />
                <span className="TodayDate ms-2">
                  {`${date}`}
                  <sup>{addSuffix(`${date}`)}</sup>{" "}
                  {`${monthNames[month.getMonth()]}`} <span>'{`${year}`}</span>
                </span>
              </div> */}
              {renewpopup == 2 ? (
                <div className="autoAdvisoryLabel d-flex">
                  <div className="">
                    <a
                      className="text-decoration-none text-black"
                      onClick={onOpenModal}
                    >
                      <div className="me-2">
                        <diwwwv className="TextLabel">
                          {!props.member_selected
                            ? "Total Life Insurance"
                            : "Life Insurance"}
                        </diwwwv>
                        <div className={`d-flex align-items-center justify-content-between ${lifeinsuranceData && (lifeinsuranceData.life_insurance_sum_assured_formatted || lifeinsuranceData.life_insurance_sum_assured_formatted === 0) ? null : "shine"}`}>
                          <div className="valueLabel">
                            &#8377;{" "}
                            <span className="bigBalue">
                              {lifeinsuranceData.life_insurance_sum_assured_formatted}
                            </span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="borderRight" style={{ margin: "0 2rem" }}></div>
                  <div className="ms-4 d-flex justify-content-end">
                    <div>
                      <a
                        className="text-decoration-none text-black"
                        onClick={onOpenModal}
                      >
                        <div className="TextLabel">Medical Cover</div>
                        <div style={{ width: "50%" }} className={`d-flex align-items-center justify-content-between ${lifeinsuranceData && (lifeinsuranceData.medical_insurance_sum_assured_formatted || lifeinsuranceData.medical_insurance_sum_assured_formatted === 0) ? null : "shine"}`}>
                          <div className="valueLabel">
                            &#8377;{" "}
                            <span>
                              {lifeinsuranceData.medical_insurance_sum_assured_formatted}
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* <div className="Imgbox">
                 <img className="" src={Medicalinsurance} width={130} />
               </div> */}
                  </div>
                </div>
              ) : (
                <div className="mt-4 autoAdvisoryLabel">
                  <div>
                    <a
                      className="text-decoration-none text-black"
                      href={
                        ssessiondata.plan_id === 29
                          ? process.env.PUBLIC_URL + "/report/risk-management?tab=lifeinsurance"
                          : process.env.PUBLIC_URL + "/datagathering/insurance"
                      }
                    >
                      <div className="me-4">
                        <div className="TextLabel">
                          {!props.member_selected ? "Total Life Insurance" : "Life Insurance"}
                        </div>

                        <div
                          style={{ width: "50%" }}
                          className={`d-flex align-items-center justify-content-between ${lifeinsuranceData &&
                              (lifeinsuranceData.life_insurance_sum_assured_formatted ||
                                lifeinsuranceData.life_insurance_sum_assured_formatted === 0)
                              ? null
                              : "shine"
                            }`}
                        >
                          <div className="valueLabel">
                            &#8377;{" "}
                            <span className="bigBalue">
                              {lifeinsuranceData.life_insurance_sum_assured_formatted !== undefined
                                ? lifeinsuranceData.life_insurance_sum_assured_formatted
                                : 0.0}
                            </span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div>
                    <a
                      className="text-decoration-none text-black"
                      href={
                        ssessiondata.plan_id === 29
                          ? process.env.PUBLIC_URL + "/report/risk-management?tab=medicalinsurance"
                          : process.env.PUBLIC_URL + "/datagathering/insurance"
                      }
                    >
                      <div className="TextLabel">Medical Cover</div>
                      <div
                        style={{ width: "50%" }}
                        className={`d-flex align-items-center justify-content-between ${lifeinsuranceData &&
                            (lifeinsuranceData.medical_insurance_sum_assured_formatted ||
                              lifeinsuranceData.medical_insurance_sum_assured_formatted === 0)
                            ? null
                            : "shine"
                          }`}
                      >
                        <div className="valueLabel">
                          &#8377;{" "}
                          <span>
                            {lifeinsuranceData.medical_insurance_sum_assured_formatted !== undefined
                              ? lifeinsuranceData.medical_insurance_sum_assured_formatted
                              : 0.0}
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    style={{
                      float: "right",
                      marginTop: "-4.1rem"
                    }}
                  >
                    <img
                      width={200}
                      src={
                        process.env.REACT_APP_STATIC_URL + "media/Mediclaim_Insurance.svg"
                      }
                    />
                  </div>
                </div>

              )}
            </div>
          </div>
        </div>
      </div>


      <KYCPopup
        kycDone={kycDone}
        fpDone={fpDone}
        show={kycModal}
        sessiondata={ssessiondata}
        onHide={() => setKycModal(false)}
      />
      <Modal
        className="Modalpopup"
        open={open}
        showCloseIcon={false}
        onClose={onCloseModal}
        center
      >
        <div className="text-center">
          <h2 className="HeaderText">Attention !!</h2>
          <RenewPopup
            open={open}
            onClose={onCloseModal}
            subscriptionenddate={props.subscriptionenddate}
          />
        </div>
      </Modal>

      {/* <BootModal.Modal
        dialogClassName="Nsdlcsdl-modal-width"
        className="Modalpopup"
        show={openModalByName == "Fecth_your_Loan"}
        centered
        animationDuration={0}
      >
        <Fetchloan
          Closemodal={CloseLoanModal}
          session={ssessiondata}
          allMembers={allMembers}
          isCardBox={true}
          getEquifaxData={getEquifaxData}
          defaultSelectedMember={defaultSelectedMember}
          is_plan={true}
        />
      </BootModal.Modal> */}

      {/* <Reportmodal open={openModalByName == 'PAR_Report'} Closemodal={CloseparModal}
        forpar={true} />


      <MFReportModal open={openModalByName == 'MF_Screening'} setOpenModalByName={setOpenModalByName} /> */}

    </>
  );
}

export default CardBoxGoals;
