import styles from "./style.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { GrFormClose } from "react-icons/gr";

export function ThankyouSectionInline() {

    return (
        <>
            <section >
                <div className={`${styles.ThanksBox}`} style={{ width: '100%', margin: '0' }}>
                    <div className={`${styles.Logo}`}>
                        <img
                            src={
                                process.env.REACT_APP_STATIC_URL + "media/logo.svg"
                            }
                        />
                    </div>
                    <br />
                    <div className={`${styles.ThankyouTextInline}`} >Thank You for Registering with Fintoo.</div>
                    <div className={`${styles.ThanksubTextInkine}`} >
                        We appreciate you taking the time to connect with us.
                    </div>
                    <div className={`${styles.ThanksubTextInkine}`} style={{ fontWeight: '600', fontSize: '1.7rem' }}>
                        What’s Next?
                    </div>
                    <div className={`${styles.ThanksubTextInkine}`}>
                        Keep an eye on your inbox—we’ll send you a detailed response shortly to ensure you get the most out of our services.
                    </div>
                    <div className={`${styles.ThanksubTextInkine}`} style={{ fontWeight: '600' }}>
                        We’re excited to work with you and help you achieve your goals. In the meantime, feel free to explore our website and discover more about what we offer.
                    </div>
                    <br />
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <div style={{ padding: '0.5rem 1rem', fontSize: '1.5rem', fontWeight: '600', color: 'white', backgroundColor: '#54a8df', borderRadius: '7px' }}>
                            Explore Now
                        </div>
                    </Link>

                </div>
            </section>
        </>
    );
}

function ThankyouSection({ onClose }) {
    const navigate = useNavigate();



    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => (document.body.style.overflow = "unset");
    }, []);
    return (
        <>

            <section className={`${styles["Thankyou-Section"]}`}>
                <div className={`${styles.ThanksBox}`}>
                    <div
                        className={`${styles.closeIcon}`}
                        onClick={() => (onClose ? onClose() : navigate(-1))}
                    >
                        <GrFormClose />
                    </div>
                    <div className={`${styles.Logo}`}>
                        <img
                            alt="logo"
                            src={process.env.REACT_APP_STATIC_URL + 'media/fintooinvestlogo.svg'}

                        />
                    </div>
                    <div className={`${styles.ThankyouText}`}>Thank you</div>
                    <div className={`${styles.ThanksubText}`}>
                        We have successfully received your details.
                    </div>
                    <div className={`${styles.ThankMsg}`}>
                        Our Expert will get in touch with you shortly.
                    </div>
                </div>
            </section>
        </>
    );
}

export default ThankyouSection;
