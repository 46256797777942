import styles from "./style.module.css";

function IpoHeaderSection() {

    return (
        <>
            <section className={`${styles['header-section']} `}>
                <div className={`${styles['header-section-background-overlay']}`} ></div>
                <div className={`${styles['header-section-container']}`} >
                    <div className={`${styles['header-section-content']}`} >
                        <h2 className={`${styles['header-section-title']}`}>One platform to manage all your IPO-related activities.</h2>
                        {/* <h3 className={`${styles['section-sub-title']}`}>One platform to manage all your IPO related activities.</h3> */}
                        <br />
                        <a href="https://www.fintoodirect.in/ipo">
                            <button className={`${styles['header-section-content-btn']} ${styles['animatedBouncInUp']} ${styles['bounceInUp']}`} >EXPLORE</button>
                        </a>
                    </div>
                    <div className={`${styles['header-section-image']}`}>
                        <div className={`${styles['header-section-image-container']} ${styles['animated']} ${styles['animatedFadeInUp']} ${styles['fadeInUp']}  `} >
                        <img  src={process.env.REACT_APP_STATIC_URL + 'media/wp/IPO/ipoHeadingBg.png'} alt="" />
                            </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default IpoHeaderSection
