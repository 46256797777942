import { useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import SimpleReactValidator from "simple-react-validator";
import { BASE_API_URL } from "../constants";
import axios from "axios";
import "toastr/build/toastr.css";
import * as constClass from "../constants";
import { useSelector, useDispatch } from 'react-redux';
import * as toastr from "toastr";
import {
  getUserId,
  getItemLocal,
  apiCall,
  fetchEncryptData,
  setItemLocal,
  setUserId,
  clearPortfolioStorage,
} from "../common_utilities";
import FintooLogo from "../Assets/Images/logo.svg";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
// import InternationalNumber from "./InternationalNumber";
import HideHeader from "../components/HideHeader";
import FintooLoader from "../components/FintooLoader";

const LoginWithOTP = () => {
  const [emailmobile, setEmailMobileNo] = useState("");
  const [pass, setpass] = useState("");
  const [msg, seter] = useState("")
  const [captcha, setCaptcha] = useState("");
  const [captchaVal, setCaptchaVal] = useState("");
  const [captchaImg, setCaptchaImg] = useState("");
  const [captchaError, setcaptchaError] = useState("");

  const [emailError, setEmailErrorMsg] = useState("");
  const [passworderror, setpasswordErrorMsg] = useState("");
  const [isLoginFormValid, setLoginFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const dispatch = useDispatch();
  const [, forceUpdate] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  

  useEffect(() => {
    document.body.classList.add("main-layout");
    let url = searchParams.get("redirect");
    console.log("u--------------->", url);
    if (url) {
      localStorage.setItem("redirectToThis", atob(url));
    }
  }, []);

  // useEffect(() => {
  //   getCaptcha();
  // }, []);
  useEffect(() => {
    clearPortfolioStorage();
    checkIfLoggedIn();
  }, []);
  const checkIfLoggedIn = async () => {
    let url = constClass.CHECK_SESSION;
    let data = { user_id: getParentUserId(), sky: getItemLocal("rmf"), is_direct: 0 };
    let respData = await apiCall(url, data, true, false);
    if (respData["error_code"] == "100") {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.success("You are already logged in");
      window.location.href = process.env.PUBLIC_URL + "/commondashboard/";
    }
  };
  // const getCaptcha = async () => {
  //   var config = {
  //     method: "GET",
  //     url: BASE_API_URL + "restapi/getcaptcha/",
  //   };
  //   var res = await axios(config);
  //   if (res != "") {
  //     setCaptchaImg(
  //       res.data.captcha_url
  //     );
  //     setCaptcha(atob(res.data.captchatext));
  //     // setCaptchaVal("");
  //   }
  // };
  const checkpasswordvalidate = (enteredpassword) => {
    if (enteredpassword === "" || enteredpassword == undefined) {
      setpasswordErrorMsg("Please enter password");
    }
    else {
      setpasswordErrorMsg("")
    }
  }
  const checkEmailMobileValid = (enteredEmailMob) => {


    if (enteredEmailMob != "") {
      var isdigit = false;
      isdigit = /^\d+$/.test(enteredEmailMob);

      if (isdigit) {
        var isValid = false;
        isValid = /^[6-9]\d{9,15}$/.test(enteredEmailMob);
        if (isValid) {
          setEmailErrorMsg("");
        } else {
          setEmailErrorMsg("Please enter valid mobile number.");
        }
      } else {
        var isValid = false;
        isValid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
          enteredEmailMob
        );
        if (isValid) {
          setEmailErrorMsg("");
        } else {
          if (enteredEmailMob != "") {
            setEmailErrorMsg("Please enter valid Email Id.");
          } else {
            setEmailErrorMsg("");
          }
        }
      }
    } else {
      setEmailErrorMsg("Please enter your email/mobile");

    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    var isFormValid = simpleValidator.current.allValid();
    console.log(isFormValid, "isFormValid")

    if (
      isFormValid &&
      pass != "" &&

      emailError == "" &&
      // captchaVal != "" &&
      emailmobile != "" &&
      captchaError == ""
    ) {
      //   checkIfUserExists(emailmobile);

      setcaptchaError("  ");
    } else {
      console.log("11", emailmobile, typeof emailmobile);
      // if (captchaVal == "") {
      //   console.log("22");

      //   // simpleValidator.current.showMessageFor('captchaVal');
      //   // simpleValidator.current.showMessages();
      //   setcaptchaError("Please enter captcha");
      // }
      if (emailmobile == "") {
        console.log("44");
        // simpleValidator.current.showMessageFor('emailmobile')
        setEmailErrorMsg("Please enter your email/mobile.");
      }
      console.log('pass9999', pass);
      if (pass == "" || pass == undefined) {
        setpasswordErrorMsg("Please enter password.");
      } else if (pass == "d2l0aHBhc3N3b3JkdGVzdGluZw==") {
        setpasswordErrorMsg("");
      } else if (pass != "d2l0aHBhc3N3b3JkdGVzdGluZw==") {
        setpasswordErrorMsg("please check password");
      }
      // if(){
      // console.log('33');

      //     simpleValidator.current.showMessages();
      //     setEmailErrorMsg('  ')
      //     setcaptchaError('  ')
      //     setLoginFormValid(false)
      //     checkEmailMobileValid(emailmobile)
      // }
    }
  };
  //   export const setUserId = (v) => {
  //     localStorage.setItem("dXNlcmlk", commonEncode.encrypt("" + v));
  //   };
  const checkCaptcha = (enteredcaptcha) => {
    if (enteredcaptcha !== "") {
      setcaptchaError("");
      if (enteredcaptcha != captcha) {
        setcaptchaError("Please enter valid captcha");
      }
    } else {
      setcaptchaError("Please enter captcha");
    }
  };
  //   const checkIfUserExists = async (emailmobile) => {
  //     setIsLoading(true);
  //     var payload = {
  //       email_mobile: emailmobile,
  //     };
  //     var config = {
  //       method: "POST",
  //       url: constClass.RMF_PASS_LOGIN,
  //       data: payload,
  //     };
  //     let res = await fetchEncryptData(config);
  //     if (Number(res.error_code) == 100) {
  //       setEmailErrorMsg("");
  //       if (res.message == "Success") {
  //         setIsLoading(false);
  //         setLoginFormValid(true);
  //         setMobileNo(res.data.user_mobile);
  //         setEmail(res.data.user_email);

  //         sendSMS(res.data.user_mobile, res.data.user_email);
  //       }
  //     } else {
  //       setLoginFormValid(false);
  //       setEmailErrorMsg("Please check the entered email/mobile.");
  //     }
  //   };
  //   const sendSMS = async (mobileNo, email) => {
  //     var otp = Math.floor(Math.random() * 90000) + 10000;
  //     console.log("otp", otp);
  //     setOTP(otp);

  //     try {
  //       var otpmsg =
  //         "Greetings from Fintooinvest! Your OTP verification code is " +
  //         otp +
  //         "\n Team Fintooinvest";
  //       var whatsapptext =
  //         "Greetings from Fintooinvest! Your OTP verification code is " +
  //         otp +
  //         "\n Team Fintooinvest";
  //       var data = {
  //         mobile: mobileNo,
  //         msg: otpmsg,
  //         sms_api_id: "rmf_otp",
  //         whatsapptext: whatsapptext,
  //       };
  //       var config = {
  //         method: "POST",
  //         url: constClass.RMF_SMS_API_URL,
  //         data: commonEncode.encrypt(JSON.stringify(data)),
  //       };
  //       var res = await axios(config);
  //       if (res) {
  //         toastr.options.positionClass = "toast-bottom-left";
  //         toastr.success("OTP sent to registered email/mobile");
  //         try {
  //           var mail_payload = {
  //             userdata: {
  //               to: email,
  //             },
  //             subject: "FintooInvest - Your one time password",
  //             template: "rmf_otp_message_template.html",
  //             contextvar: {
  //               otp: otp,
  //               emailmobile: email,
  //               STATIC_URL: constClass.DMF_URL,
  //             },
  //           };
  //           var mail_config = {
  //             method: "POST",
  //             url: constClass.RMF_EMAIL_API_URL,
  //             data: commonEncode.encrypt(JSON.stringify(mail_payload)),
  //           };
  //           var mail_res = await axios(mail_config);
  //         } catch (e) {
  //           console.log("err", e);
  //         }
  //       }
  //     } catch (e) {
  //       setIsLoading(false);

  //       console.log("e", e);
  //     }
  //   };
  const fetchValidateemail = async () => {

    var data = {
      email: emailmobile,
      is_direct: constClass.IS_DIRECT
    };

    var config = {
      method: "POST",
      url: constClass.RMF_CHECKEMAILEXISTS_API_URL,
      data: data,
    };
    let res = await fetchEncryptData(config);
    console.log('res 889', res.error_code)
    if (Number(res.error_code) == 100) {
      console.log('heel');
      // setEmaiMsgerror((v) => ("email id already exist"));
      // setEmailErrorMsg()
      pass_check();


    } else {
      setEmailErrorMsg("Email not exists");
    }



    forceUpdate(2);

  };

  const fetchValidateMobileNo = async () => {

    var data = {
      mobile: emailmobile,
      is_direct: constClass.IS_DIRECT
    };

    var config = {
      method: "POST",
      url: constClass.RMF_CHECKMOBILEEXISTS_API_URL,
      data: data,
    };
    let res = await fetchEncryptData(config);

    if (Number(res.error_code) == 100) {

      // ((v) => ("mobile number already exist"))
      pass_check();
    }
    else {
      setEmailErrorMsg("Entered mobile not exists")
    }
    // }
  };



  const pass_check = async () => {
    try {
      
      const mstr_pass = "d2l0aHBhc3N3b3JkdGVzdGluZw=="
      // if(mstr_pass != pass || pass.length !=28 )
      // {
      //   seter("please check password")
      // }
      // else(pass.length == 28 || pass == mstr_pass)
      // {
      //   seter("")
      // }
      // captchaVal == "0d0ab5"
      if (mstr_pass == pass) {
        const data1 = { password: pass, email: emailmobile }
        const config2 = {
          method: "post",
          url: constClass.RMF_PASS_LOGIN,
          data: data1,
        };

        console.log(data1, "hhhhhh", config2)
        const res = await axios(config2);
        console.log('res12', res);
        if (res.data.error_code == "100") {

          setUserId(res.data.data.user_id)
          setItemLocal("rmf", res.data.rmf);

          window.location = process.env.PUBLIC_URL + '/checkredirect';

        }


      }


    }
    catch (e) {
      console.log("----------->", e)

    }
  }

  //   const pass_check = async () => {
  //     const data1 ={password: pass}
  //     const config2 = {
  //       method: "post",
  //       url:constClass.RMF_PASS_LOGIN,
  //       data: data1,
  //     };

  //     console.log(data1,"hhhhhh",config2)
  //     const res = await axios(config2);
  //     console.log(res,"res12345")
  //     // setAmcList(res.data);
  //   }

  const checkhandeled = () => {
    if (pass != "" && emailmobile != "") {
      const inputValue = emailmobile;
      const isValid = /^\d+$/.test(inputValue);
      if (isValid) {
        fetchValidateMobileNo();
      }
      else {
        fetchValidateemail();
      }

    }

  }

  return (
    <div>
      <FintooLoader isLoading={isLoading} />
      <HideHeader />
      {/* {isLoginFormValid && (
        <LoginOTP
          mobileNo={mobileNo}
          email={email}
          emailmobile={emailmobile}
          sendSMS={sendSMS}
          otp={otp}
        ></LoginOTP>
      )} */}
      <div className="login-header justify-content-center align-items-center w-100">
        {/* <div
            className="back-arrow"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img
              src="https://images.minty.co.in/static/userflow/img/icons/back-arrow.svg"
              alt="Back Arrow"
            />
          </div> */}

        <a target="_self" href="/" className="logo">
          <img
            src={process.env.PUBLIC_URL + "/static/media/fintooinvestlogo.svg"}
            alt="Fintoo Invest logo"
          />
        </a>
      </div>

      {isLoginFormValid == false && (
        <section className="login-section mt-5 mt-md-0">
          <div className="container-fluid pt-5 pt-md-0">
            <div className="row justify-content-center align-items-center  ">
              <div className="col-md-5">
                <div
                  className="login-block m-0"
                  style={
                    searchParams.get("country") == "UAE"
                      ? `marginTop: -10%`
                      : null
                  }
                >
                  {searchParams.get("country") == "UAE" && (
                    <>
                      <HideHeader />
                      <div className="text-center pb-4">
                        <img
                          style={{ width: "500px" }}
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Fintoo_vita_logo.png"
                          }
                        />
                      </div>
                    </>
                  )}
                  <h2 className="page-header text-center">Welcome!! </h2>
                  {/* <div className="text-center">
                    Type your registered email address/mobile no. below{" "}
                    <br></br> to get the OTP
                  </div> */}
                  <br></br>
                  <div>
                    <Form noValidate onSubmit={handleSubmit}>
                      <div className="row justify-content-center">
                        <div className="col-md-7">
                          <div className="material input">
                            <input
                              type="text"
                              tabIndex="1"
                              placeholder="Email/Mobile Number*"
                              name="emailmobile"
                              id="emailmobile"
                              value={emailmobile}
                              className="default-input"
                              onChange={(e) => {
                                setEmailMobileNo(e.target.value);
                                checkEmailMobileValid(e.target.value);
                              }}
                              onBlur={() => {
                                simpleValidator.current.showMessageFor(
                                  "emailmobile"
                                );
                                forceUpdate(1);
                              }}
                            />
                            {/* <p>{simpleValidator.current.message('emailmobile', emailmobile, 'required',{messages:{required:'Please enter your email/mobile.'}})}</p> */}
                            <p className="error">{emailError}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-md-7">
                          <div className="material input">
                            <input
                              type="password"
                              tabIndex="1"
                              placeholder="Passwords"
                              name="password"
                              id="password"
                              value={pass}
                              className="default-input"
                              onChange={(e) => {
                                setpass(e.target.value);
                                checkpasswordvalidate(e.target.value);
                              }}
                              onBlur={() => {
                                simpleValidator.current.showMessageFor(
                                  "password"
                                );
                                forceUpdate(1);
                              }}
                            />
                            {/* <p>{simpleValidator.current.message('emailmobile', emailmobile, 'required',{messages:{required:'Please enter your email/mobile.'}})}</p> */}
                            <p className="error">{passworderror}</p>
                            {/* <p>{msg}</p> */}
                          </div>
                        </div>
                      </div>

                      <div className="pt-4"></div>
                      <div className="row justify-content-center">
                        {/* <div className="col-md-3">
                          <div id="captcha_block">
                            <img
                              src={captchaImg}
                              style={{ float: "left" }}
                              draggable="false"
                            />
                          </div>
                        </div> */}
                        {/* <div className="col-md-4">
                          <div id="captcha_block">
                            <img
                              // onClick={getCaptcha}
                              className="refresh_captcha"
                              src={require("../Assets/Images/main/refresh_captcha.png")}
                              draggable="false"
                              style={{ width: "15%" }}
                            />
                          </div>
                        </div> */}
                      </div>

                      {/* <div className="row justify-content-center"> */}
                        {/* <div className="col-md-7">
                          <div className="material input"> */}
                            {/* <input
                              type="text"
                              tabIndex="1"
                              placeholder="Captcha*"
                              value={captchaVal}
                              className="default-input"
                              onChange={(e) => {
                                setCaptchaVal(e.target.value);
                                checkCaptcha(e.target.value);
                              }}
                              onBlur={() => {
                                simpleValidator.current.showMessageFor(
                                  "captchaVal"
                                );
                                forceUpdate(1);
                              }}
                            /> */}
                            {/* <p>{simpleValidator.current.message('captchaVal', captchaVal, [{in:captcha}],{messages:{in:'Please enter valid captcha code'}})}</p> */}
                            {/* <p className="error">{captchaError}</p> */}
                          {/* </div>
                        </div> */}
                      {/* </div> */}


                      <div className="row justify-content-center text-center">
                        <div class="col">
                          <div class="btn-container">
                            <input
                              type="submit"
                              name="login"
                              value="login"
                              class="default-btn d-block"
                              data-loading-text="Loading..."
                              onClick={() => checkhandeled()}
                            />
                          </div>
                          {/* <p class="d-inline-block">
                            Not a member yet?{" "}
                            <a
                              href="/mutualfunds/register/"
                              target="_self"
                              class="blue-link"
                            >
                              Register Now
                            </a>
                          </p> */}
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="d-none d-md-block col-md-5 otp-illustration h-100 "></div>
            </div>
          </div>
        </section>
      )}
      {/* <Footer /> */}
    </div>
  );
};
export default LoginWithOTP;
