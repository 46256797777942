import React, { useState } from "react";
import Modal from "react-responsive-modal";
import { IoClose } from "react-icons/io5";
import { indianRupeeFormat } from "../common_utilities";
import PortfolioBal from "./PortfolioBal.module.css";
import * as animationData from "./Successful.json";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
const PortfolioBalance = ({ open, modalData, setIsOpen, isDashboard}) => {
  // const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  console.log("open", open);

  const onCloseModal = () => {
    setIsOpen(false);

    if(isDashboard){
      navigate(`${process.env.PUBLIC_URL}/commondashboard/`);
    }else{
      navigate(`${process.env.PUBLIC_URL}/datagathering/assets-liabilities/`);
    } 
  };

  const PortfolioAmt = 84704;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    width: "200",
  };
  return (
    <>
      <Modal
        open={open}
        showCloseIcon={false}
        center
        classNames={{ modal: PortfolioBal["small-modal"] }}
      >
        <div className={`${PortfolioBal.PortfolioBalClose}`}>
          <IoClose
            onClick={() => {
              onCloseModal();
            }}
          />
        </div>
        <div className={`${PortfolioBal.PortfolioBalbody}`}>
          <div className={`${PortfolioBal.Verifiedimg}`}>
            <Lottie options={defaultOptions} />
          </div>
          {modalData?.amount && (
            <div>
              <div className={`${PortfolioBal.PortfolioBalbodytxt}`}>
                Congratulations! Your bank balance has been successfully
                fetched.
              </div>
              <div className={`${PortfolioBal.PortfoliotoatlBal}`}>
                {Boolean(modalData?.amount) &&
                  indianRupeeFormat(modalData?.amount)}
              </div>
            </div>
          )}
          {modalData?.epfamount && (
            <div>
              <div className={`${PortfolioBal.PortfolioBalbodytxt}`}>
                Success! Your Employee Provident Fund value has been fetched.
                Plan your future with confidence!
              </div>
              <div className={`${PortfolioBal.PortfoliotoatlBal}`}>
                {Boolean(modalData?.epfamount) &&
                  indianRupeeFormat(modalData?.epfamount)}
              </div>
            </div>
          )}
          {modalData?.stocksamount && (
            <div>
              <div className={`${PortfolioBal.PortfolioBalbodytxt}`}>
                Great news! Your stock portfolio has been successfully fetched.
              </div>
              <div className={`${PortfolioBal.PortfoliotoatlBal}`}>
                {Boolean(modalData?.stocksamount) &&
                  indianRupeeFormat(modalData?.stocksamount)}
              </div>
            </div>
          )}
         {modalData?.liabilityamount && (
            <div>
              <div className={`${PortfolioBal.PortfolioBalbodytxt}`}>
              Success! Your liabilities have been retrieved. Gain a clear understanding of your financial commitments
              </div>
              <div className={`${PortfolioBal.PortfoliotoatlBal}`}>
                {Boolean(modalData?.liabilityamount) &&
                  indianRupeeFormat(modalData?.liabilityamount)}
              </div>
            </div>
          )}
           {modalData?.cibilscore && (
            <div>
              <div className={`${PortfolioBal.PortfolioBalbodytxt}`}>
              Congratulations! Your CIBIL Score has been successfully retrieved. Stay credit-savvy!
              </div>
              <div className={`${PortfolioBal.PortfoliotoatlBal}`}>
                {/* {Boolean(modalData?.cibilscore) &&
                  indianRupeeFormat(modalData?.cibilscore)} */}
                  Score :  
                  {Boolean(modalData?.cibilscore) &&
                  " " + modalData?.cibilscore}
              </div>
            </div>
          )}
          <div>
            <button
              onClick={onCloseModal}
              className={`${PortfolioBal.ContinueBtn1}`}
            >
              Continue{" "}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default PortfolioBalance;