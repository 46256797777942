import React, { useEffect, useState, useRef } from "react";
import ProfileInsiderLayout from "../../../components/Layout/ProfileInsiderLayout";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Form, Modal } from "react-bootstrap";
import Verify from "../../../components/Assets/Dashboard/tick.png";
// import { useState } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import MobileOTP from "./MobileOTP";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import {
  DMF_BASE_URL,
  RMF_EMAIL_API_URL,
  RMF_SMS_API_URL,
  GET_MEMBER_LIST,
  IS_DIRECT,
  ADD_MEMBER_API_URL,
  GET_RELATION_API_URL,
} from "../../../constants";
import SimpleReactValidator from "simple-react-validator";
import {
  fetchData,
  fetchEncryptData,
  getParentUserId,
  getUserId,

  loginRedirectGuest,
  isValidEmail,
  memberId,
  setMemberId,
  fetchSms,
  fetchMail,
} from "../../../common_utilities";
import { useSearchParams } from "react-router-dom";
import SweetAlert from "sweetalert-react";

const options = [
  { value: "Mother", label: "Mother" },
  { value: "Father", label: "Father" },
  { value: "Other", label: "Other" },
];
const AddMembers = (props) => {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [searchParams] = useSearchParams();
  const [mobileNumber, setMobilenumber] = useState("");
  const handleChange = (e) => {
    // const value = e.target.value.replace(/\D/g, "");
    // setMobilenumber(value);
    const newMobilenumber = e.target.value.replace(/[^0-9\s]/g, "");
    setMobilenumber(newMobilenumber);
  };
  // const [mail, setMail] = useState("");

  const handleChangeMail = (e) => {
    // const value = e.target.value.replace(/\D/g, "");
    // setMobilenumber(value);
    setUserData((prevUserData) => ({ ...prevUserData, mail:e.target.value }));
    // setMail(e.target.value);
  };
  // const [relation, setRelation] = useState("");
  const [show, setShow] = useState(false);
  const [OTPSms, setOTPSms] = useState("");
  const [OTPEmail, setOTPSmsEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [mailVerify, setmailVerify] = useState(false);
  const mailCheck = () => setmailVerify(true);
  const onClick = () => setOpen(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showmbOTP, setShowMbOTP] = useState(false);
  const [showmobinputData, setShowmobinputData] = useState(false);
  const [showeidinputData, setShoweidinputData] = useState(false);

  const [showEmOTP, setShowEmOTP] = useState(false);
  const [generatedSmsOTP, setGeneratedSmsOTP] = useState("");
  const [generatedEmailOTP, setGeneratedEmailOTP] = useState("");
  const [error, setError] = useState({});
  const [name,setname] = useState("")


  const hiddenDivRef = useRef(null);

  const [errors, setErrors] = useState({});
  // const [mobile, setMobile] = useState("");
  // const [email, setEmail] = useState("");
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  const [userdata ,setUserData] = useState({mobile :"", relation:"",email:"",mail:"",name:"",user_name:""})

  useEffect(() => {
    setIsComponentLoaded(true);
  }, [userdata]);

  useEffect(() => {
    if (isComponentLoaded === false) return;
    validateMobile();
  }, [userdata.mobile]);

  const validateMobile = async () => {
    let err = 0;
    if (userdata.mobile === "") {
      err++;
      setErrors((v) => {
        return { ...v, mobile: "Mobile is required." };
      });
    } else if ("123450".indexOf(userdata.mobile[0]) > -1) {
      err++;
      setErrors((v) => {
        return { ...v, mobile: "Enter valid mobile number." };
      });
    } else if (userdata.mobile.length != 10) {
      err++;
      setErrors((v) => {
        return { ...v, mobile: "Enter valid 10 digit mobile number." };
      });
    } else {
      setErrors((v) => {
        return { ...v, mobile: undefined };
      });
    }
    if (err == 0) {
      checkDuplicateMobile();
    }
  };

  useEffect(() => {
    if(userdata.email !="") checkDuplicateEmail();
    if (isComponentLoaded === false) return;
    validateEmail();
  }, [userdata.email]);

  const validateEmail = () => {
    if (userdata.email === "") {
      setErrors((v) => {
        return { ...v, email: "Email is required." };
      });
    } else if (isValidEmail(userdata.email) == false) {
      setErrors((v) => {
        return { ...v, email: "Enter valid email id." };
      });
    } else {
      setErrors((v) => {
        return { ...v, email: undefined };
      });
    }
  };

  const checkDuplicateMobile = async () => {
    try {
      let data = {
        method: "post",
        url: DMF_BASE_URL + "api/user/validatemobile",
        data: {
          parent_user_id: getParentUserId(),
          user_id: getUserId(),
          mobile: userdata.mobile,
          is_direct: IS_DIRECT,
        },
      };
      let r = await fetchEncryptData(data);
      if (r.error_code == 102) {
        setErrors((prevState) => ({ ...prevState, mobile: <span>Number same as that of parent. <br />Please enter a new number.</span> }));
      } else if (r.error_code == 103) {
        setErrors((prevState) => ({ ...prevState, mobile: "Mobile number is already exist." }));
      }
    } catch (err) {
      console.log("err nil 76", err);
    }
  };

  const checkDuplicateEmail = async (str = "") => {
    if (Boolean(errors.email)) return;
    if (userdata.email.trim() == '') return;
    try {
      let data = {
        method: "post",
        url: DMF_BASE_URL + "api/user/validateemail",
        data: {
          parent_user_id: getParentUserId(),
          user_id: getUserId(),
          email: str || userdata.email,
          is_direct: IS_DIRECT,
        },
      };
      let r = await fetchEncryptData(data);

      if (r.error_code == 102) {
        setErrors((prevState) => ({ ...prevState, email: <span>Email ID same as that of parent member. <br />Please enter a new email ID.</span> }));
      } else if (r.error_code == 103) {
        setErrors((prevState) => ({ ...prevState, email: "Email  already exists" }));
      }
    } catch (err) {
      console.log("err nil 76", err);
    }
  };
  const [relations, setRelations] = useState([]);

  const ShowOTPData = () => setShowMbOTP(true);
  const ShowOTPDEmata = () => setShowEmOTP(true);
  const timer = useRef({ obj: null, counter: 0, default: 30 });
  const timer1 = useRef({ obj: null, counter: 0, default: 30 });
  const [count, setCount] = useState(timer.current.default);
  const [countEmail, setCountEmail] = useState(timer1.current.default);
  const [openConfirm, setOpenConfirm] = useState(false);
  // const [user_name,setusername] = useState("")

  useEffect(() => {
    timer.current.counter = timer.current.default;
    timer1.current.counter = timer1.current.default;
  }, []);

  const startTimer = () => {
    showHiddenDiv();
    clearInterval(timer.current.obj);
    timer.current.obj = setInterval(() => {
      //
      if (timer.current.counter > 0) {
        timer.current.counter = timer.current.counter - 1;
        setCount(timer.current.counter);
      } else {
        //
        clearInterval(timer.current.obj);
        timer.current.counter = timer.current.default;
      }
      // setOTPSms();
    }, 1000);
  };
  const startTimer1 = () => {
    showHiddenDiv();
    clearInterval(timer1.current.obj);
    timer1.current.obj = setInterval(() => {
      //
      if (timer1.current.counter > 0) {
        timer1.current.counter = timer1.current.counter - 1;
        setCountEmail(timer1.current.counter);
      } else {
        //
        clearInterval(timer1.current.obj);
        timer1.current.counter = timer1.current.default;
      }
    }, 1000);
  };

  useEffect(() => {
    var parent_user_id = getUserId();
    if (parent_user_id == null || parent_user_id == "") {
      loginRedirectGuest();
    }
    if (getUserId() != getParentUserId()) {
      if (searchParams.get("update")) {
        return;
      } else {
        window.location =
          process.env.PUBLIC_URL + "/mutual-fund/profile/dashboard";
      }
    }
  }, []);



  const fetchValidateemail = async () => {
    var parent_user_id = getUserId();
    var urldata = {
      parent_user_id: parent_user_id,
      email: userdata.mail,
      relation: userdata.relation,
      is_direct: IS_DIRECT,
    };

    var config = {
      method: "post",
      url: DMF_BASE_URL + "api/user/validateemail",
      data: urldata,
    };
    var res = await fetchEncryptData(config);
    var validateEmail = res;
    if (validateEmail.error_code == 102) {
      if (validateEmail.message != "User Email and UserId Match...!!") {
        setFakeEmailMsg("");
      } else if (getUserId() != getParentUserId()) {
        setDuplicateParentEmail("");
      }
      return;
    }
  };

  var parent_user_id = getUserId();

  const fetchAddMemberData = async () => {
    try {
      var formValid = simpleValidator.current.allValid();
      simpleValidator.current.showMessages();
      forceUpdate(1);
      if (formValid == false) return;
      if (getUserId() == getParentUserId()) {
        var urladdmemdata = {
          id: getParentUserId(),
          parent_user_id: getParentUserId(),
          email: userdata.email,
          mobile: userdata.mobile,
          relation: userdata.relation,
          is_direct: IS_DIRECT,

        };
      } else if (searchParams.get("update") == 1) {
        var urladdmemdata = {
          member_user_id: getUserId(),
          id: getParentUserId(),
          email: userdata.email,
          mobile: userdata.mobile,
          relation: userdata.relation,
          type: "update",
          is_direct: IS_DIRECT,

        };
      }

      var config = {
        method: "post",
        url: ADD_MEMBER_API_URL,
        data: urladdmemdata,
      };

      var response = await fetchEncryptData(config);
      getRelations();
      let error_code = response.error_code;
      localStorage.removeItem('family');
      if (error_code == "100") {
        let member_id = response.data.member_id;
        setMemberId(member_id);
        setOpenConfirm(true);
      } else if (error_code == "100" && searchParams.get("update") == 1) {
        let member_id = response.data.member_id;
        setMemberId(member_id);
        setOpenConfirm(true);
      }
    } catch (e) {

    }
  };

  const otpfunction = async function (v) {
    if (v === "sms") {
           let otp = Math.floor(Math.random() * 90000) + 10000;
            var urlsms = {
              mobile: userdata.mobile,
              otp:otp,
              is_direct :IS_DIRECT,
              key:"add_member",
              user_name :userdata.user_name??userdata.user_name
            };
           
            var response_sms = await fetchSms(urlsms);
            setUserData((prevUserData) => ({...prevUserData, name:response_sms['name']}))
            // setname(response_sms['name']);
            setGeneratedSmsOTP(otp);
    }
    if (v === "mail") {
      
            var response_mail = await fetchMail(userdata.email, userdata.name);
            setGeneratedEmailOTP(response_mail['otp'])
    }
}


  // const fetchSms = async () => {
  //   var sms_api_id = "fintoo_otp";
  //   var otp = Math.floor(Math.random() * 90000) + 10000;
  //   console.log("otp nil844", otp);
  //   setGeneratedSmsOTP(otp);


  //   var otpmsg =
  //     "Greetings from Fintooinvest! Your OTP verification code is " + otp + "\n Team Fintooinvest";
  //   var whatsapptext =
  //     "Greetings from Fintooinvest! Your OTP verification code is " + otp + "\n Team Fintooinvest";

  //   var urlsms = {
  //     mobile: mobile,
  //     msg: otpmsg,
  //     sms_api_id: "rmf_otp",
  //     whatsapptext: whatsapptext,
  //   };
  //   var config = {
  //     method: "post",
  //     url: RMF_SMS_API_URL,
  //     data: urlsms,
  //   };
  //   var res = await fetchEncryptData(config);
  //   var name = res.data
  //   setname(name)
  // };

  // const fetchMail = async () => {
  //   var otp = Math.floor(Math.random() * 90000) + 10000;
  //   setGeneratedEmailOTP(otp);
  //   var urlmail = {
  //     userdata: {
  //       to: email,
  //     },
  //     subject: "FintooInvest - Your one time password",
  //     template: "rmf_otp_message_template.html",
  //     contextvar: { otp: otp , emailmobile: name ? name: "User",},
  //   };
  //   var config = {
  //     method: "post",
  //     url: RMF_EMAIL_API_URL,
  //     data: urlmail,
  //   };
  //   var res = await fetchEncryptData(config);
  // };

  useEffect(() => {
    getRelations();
    fetchMembers();
  }, []);

  useEffect(() => {
    fetchValidateemail();
  }, [userdata.mail]);

  const getRelations = async () => {
    try {
      var config = {
        method: "post",
        url: GET_RELATION_API_URL,
      };
      var res = await axios(config);
      var responseRel = res.data;
      setRelations(responseRel.data);
    } catch (e) { }
  };

  const fetchMembers = async () => {
    try {
      const r = await fetchData({
        url: GET_MEMBER_LIST,
        data: {
          user_id: getParentUserId(),
          is_direct: IS_DIRECT,
        },
        method: "post",
      });
      handleParentdata(r);

      r.data.map(item => {
        if(item.parent_user_id == "0")
        {
          var u_name = item['NAME']
          var l_name = item['last_name']
          setUserData((prevUserData) => ({...prevUserData, user_name: u_name +' '+''+l_name}))
          // setusername(u_name +' '+''+l_name)
      
        }
        
      })


      const all = r.data.map((v) => ({
        id: v.id,
        email: v.email ? v.email : "",
        mobile: v.mobile ? v.mobile : "",
        relation: v.relation,
        parent_id: parent_user_id,
      }));
      // setSelectedMembers([...all]);

      if (all != "" || all != null) {
        const a = all.filter((v) => v.id == getUserId());
        if (typeof a == "object" && a.length) {
          setUserData((prevUserData) => ({ ...prevUserData, mobile: a[0].mobile > 0 ? a[0].mobile : "" }));
          // setMobile(a[0].mobile > 0 ? a[0].mobile : "");
          // setEmail(a[0].email);
          setUserData((prevUserData) => ({ ...prevUserData, email:a[0].email }));
          setUserData((prevUserData) => ({ ...prevUserData, relation:a[0].relation }));
          // setRelation(a[0].relation);
          if (a[0].email) {
            setTimeout(() => {
              checkDuplicateEmail(a[0].email);
            }, 1000);
          }
          // setdisabled(false);
        }
        if (searchParams.get("update") == null) {
          setUserData((prevUserData) => ({ ...prevUserData, mobile:"" }));
          // setMobile("");
          setUserData((prevUserData) => ({ ...prevUserData, email:"" }));
          // setEmail("");
          setUserData((prevUserData) => ({ ...prevUserData, relation:"" }));
          // setRelation("");
        }
      }
    } catch (e) { }
  };

  const handleParentdata = (Rdata) => {
    var getarray1 = Rdata.data.filter((obj) => {
      return obj.parent_user_id == 0;
    });
    // setParent(getarray1);
  };

  useEffect(() => {
    if (OTPEmail.length > 4 && generatedEmailOTP != OTPEmail) {
      var tempEerror = {};
      tempEerror.OTPEmail = "Please provide valid OTP.";
      setError({ ...tempEerror });
    } else if (OTPEmail.length > 4 && generatedEmailOTP == OTPEmail) {
      setShowEmOTP(false);
      setShoweidinputData(!showeidinputData);
    } else {
      setError({});
    }
  }, [OTPEmail]);

  useEffect(() => {
    if (OTPSms.length > 4 && generatedSmsOTP != OTPSms) {
      var tempEerror = {};
      tempEerror.OTPSms = "Please provide valid OTP.";
      setError({ ...tempEerror });
    } else if (OTPSms.length > 4 && generatedSmsOTP == OTPSms) {
      setShowMbOTP(false);
      setShowmobinputData(!showmobinputData);
      // setShowmobvrfyData(!showmobvrfyData);
    } else {
      setError({});
    }
  }, [OTPSms]);

  const showHiddenDiv = () => {
    hiddenDivRef.current.style.display = "block";
    setTimeout(() => {
      hiddenDivRef.current.style.display = "none";
    }, 1000);
  };


  return (
    <ProfileInsiderLayout>
      <SweetAlert
        show={openConfirm}
        title="Member Added Successfully!"
        text="Please complete member's profile."
        showCancelButton={true}
        onConfirm={() => {
          setOpenConfirm(false);
          setTimeout(() => {
            window.location =
              process.env.PUBLIC_URL + "/mutual-fund/profile";
          }, 100);
        }}
        onCancel={() => {
          setOpenConfirm(false);
          setTimeout(() => {
            window.location =
              process.env.PUBLIC_URL + "/mutual-fund/funds/all";
          }, 100);
        }}
        confirmButtonText="Continue"
        cancelButtonText="Later"
      />
      <ToastContainer limit={1} />
      <div
        ref={hiddenDivRef}
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          zIndex: 9999,
          top: 0,
          left: 0,
          display: "none",
        }}
      ></div>
      <>
        <div className="ProfileDashboard">
          <div className="ml-10 md:mt-14 mt-4 p-2 md:p-3 rounded-3xl">
            <div className="text-label-info">
              <Row>
                <Col xs={12} lg={12}>
                  <Row>
                    {searchParams.get("update") == 1 ? (
                      <Col>
                        <div className="col-12 col-lg-8">
                          <div className="text-nominee">
                            <p className="text-label">Update Members</p>
                            <span className="bank-label">
                              Update member to your Fintooinvest Account
                            </span>
                          </div>
                        </div>
                        <p className="Hrline mt-3"></p>
                      </Col>
                    ) : (
                      <Col>
                        <div className="col-12 col-lg-8">
                          <div className="text-nominee">
                            <p className="text-label">Add New Members</p>
                            <span className="bank-label">
                              Add member to your Fintooinvest Invest Account
                            </span>
                          </div>
                        </div>
                        <p className="Hrline mt-3"></p>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="add-member-field desktopview">
              <Row>
                <Col xs={12} lg={9}>
                  <Row>
                    <Col xs={6} lg={8}>
                      <Row>
                        <Col xs={12} lg={2}>
                          <label className="text-label">Mobile No.</label>
                        </Col>

                        <Col xs={12} lg={5}>
                          <input
                            maxLength={10}
                            type="text"
                            className="w-100"
                            value={userdata.mobile}
                            // onChange={handleChange}
                            onChange={(e) => {
                              setUserData((prevUserData) => ({ ...prevUserData, mobile: e.target.value.replace(/[^0-9]+/g, "") }));
                              // setMobile(e.target.value.replace(/[^0-9]+/g, ""));
                              setShowMbOTP(false);
                            }}
                            disabled ={showmobinputData}
                          />
                        </Col>

                        <Col>
                          {showmobinputData ? (
                            <>
                              <img
                                style={{
                                  width: "20px",
                                }}
                                src={
                                  process.env.REACT_APP_STATIC_URL +
                                  "media/DMF/tick.svg"
                                }
                              />
                            </>
                          ) : (
                            <>
                              <button
                                disabled={Boolean(
                                  userdata.mobile == "" || errors.mobile
                                )}
                                className="amt-Save mt-0 ml-2"
                                onClick={() => {
                                  ShowOTPData();
                                  startTimer();
                                  otpfunction('sms');
                                }}
                              >
                                Verify
                              </button>
                            </>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {Boolean(errors.mobile) && (
                    <div className="error">{errors.mobile}</div>
                  )}

                  <Row>
                    {showmbOTP ? (
                      <>
                        <Col className="mt-5">
                          <Row>
                            <Col xs={6} lg={2}>
                              <p className="Otp-text">
                                Verify Mobile Number with OTP
                              </p>
                            </Col>
                            <Col xs={6} lg={8}>
                              <div
                                id="otp"
                                className="inputs d-flex flex-row  mt-2 "
                              >
                                <OTPInput
                                  value={OTPSms}
                                  onChange={setOTPSms}
                                  // onChange={confirmdata()}
                                  autoFocus
                                  className="rounded rounded-otp"
                                  OTPLength={5}
                                  otpType="number"
                                  disabled={false}

                                // secure
                                />

                                {count == 0 && (
                                  <label
                                    className="Otp-resend-text mt-0 ml-2"
                                    onClick={() => {
                                      startTimer();
                                      otpfunction('sms');
                                      setOTPSms("");
                                    }}
                                  >
                                    Resend OTP
                                  </label>
                                )}
                                {count > 0 && (
                                  <label
                                    className="Otp-resend-text mt-0 ml-2"
                                    style={{
                                      cursor: "not-allowed",
                                    }}
                                  // onClick={() => startTimer()}
                                  >
                                    Resend OTP in &nbsp;
                                    <strong>
                                      {count > 120 ? count : +count} Sec.
                                    </strong>
                                  </label>
                                )}
                              </div>
                            </Col>
                            {"OTPSms" in error && (
                              <p className="error">{error.OTPSms}</p>
                            )}
                          </Row>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </Row>
                </Col>
              </Row>
              <div className="add-member-data-field email-data">
                <Row>
                  <Col xs={12} lg={9}>
                    <Row>
                      <Col xs={6} lg={8}>
                        <Row>
                          <Col xs={12} lg={2}>
                            <label className="text-label">Email ID</label>
                          </Col>
                          <Col xs={12} lg={5}>
                            <input
                              className="email w-100"
                              type="email"
                              maxLength={40}
                              autoComplete="off"
                              value={userdata.email}
                              onChange={(e) => {setShowEmOTP(false); setUserData((prevUserData) => ({ ...prevUserData, email:e.target.value}));}}
                              onBlur={() => checkDuplicateEmail()}
                              disabled ={showeidinputData}
                            />
                            {/* setEmail(e.target.value) */}
                            {/* <p><input className="mt-2 pt-3" style={{paddingTop : '10px'}} type="checkbox" name="" id="" /> <label style={{fontSize : "12px"}}>Lorem ipsum dolor sit amet consectetur.</label></p> */}
                          </Col>
                          <Col>
                            {showeidinputData ? (
                              <>
                                <img
                                  style={{
                                    width: "20px",
                                  }}
                                  src={
                                    process.env.REACT_APP_STATIC_URL +
                                    "media/DMF/tick.svg"
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <button
                                  disabled={Boolean(
                                    userdata.email == "" || errors.email
                                  )}
                                  className="amt-Save mt-0 ml-2"
                                  onClick={() => {
                                    ShowOTPDEmata();
                                    startTimer1();
                                    otpfunction('mail');
                                  }}
                                >
                                  Verify
                                </button>
                              </>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {Boolean(errors.email) && (
                      <div className="error">{errors.email}</div>
                    )}
                    <Row>
                      {showEmOTP ? (
                        <>
                          <Col className="mt-5">
                            <Row>
                              <Col xs={6} lg={2}>
                                <p className="Otp-text">
                                  Verify Email ID with OTP
                                </p>
                              </Col>
                              <Col xs={6} lg={8}>
                                <div
                                  id="otp"
                                  className="inputs d-flex flex-row  mt-2 "
                                >
                                  <OTPInput
                                    value={OTPEmail}
                                    onChange={setOTPSmsEmail}
                                    autoFocus
                                    className="rounded rounded-otp"
                                    OTPLength={5}
                                    otpType="number"
                                    disabled={false}
                                    
                                  // secure
                                  />
                                  {countEmail == 0 && (
                                    <label
                                      className="Otp-resend-text mt-0 ml-2"
                                      onClick={() => {
                                        startTimer1();
                                        setOTPSmsEmail("");
                                        otpfunction('mail');
                                        toast.success(
                                          "OTP has been sent successfully",
                                          {
                                            position:
                                              toast.POSITION.BOTTOM_LEFT,
                                            autoClose: 2000,
                                          }
                                        );
                                      }}
                                    >
                                      Resend OTP
                                    </label>
                                  )}
                                  {countEmail > 0 && (
                                    <label
                                      className="Otp-resend-text mt-0 ml-2"
                                      style={{
                                        cursor: "not-allowed",
                                      }}
                                    >
                                      Resend OTP in &nbsp;
                                      <strong>
                                        {countEmail > 120
                                          ? countEmail
                                          : +countEmail}{" "}
                                        Sec.
                                      </strong>
                                    </label>
                                  )}
                                </div>
                              </Col>
                              {"OTPEmail" in error && (
                                <p className="error">{error.OTPEmail}</p>
                              )}
                            </Row>
                          </Col>
                        </>
                      ) : null}
                    </Row>
                  </Col>
                </Row>
              </div>
              <div
                style={{
                  marginTop: "2em",
                }}
              ></div>
              <div className="Relational-data">
                <Row>
                  <Col xs={12} lg={9}>
                    <>
                      <Col xs={12} lg={12}>
                        <Row>
                          <Col xs={12} lg={4}>
                            <label className="text-label">
                              Relationship with Primary Holder
                            </label>
                          </Col>
                          <Col>
                            {/* <Form.Group controlId="formBasicSelect">
                              <Form.Select
                                style={{ width: "15rem" }}
                                value={val}
                                onChange={(e) => setVal(e.target.value)}
                              >
                                <option>Open this select menu</option>
                                <option value="1">Mother</option>
                                <option value="2">Father</option>
                                <option value="3">Other</option>
                              </Form.Select>
                            </Form.Group> */}
                            {/* <Form.Label>Who Is This Goal For?*</Form.Label> */}
                            <Form.Select
                              className="w-50"
                              aria-label="Default select example"
                              placeholder="Select"
                              value={userdata.relation}
                              onChange={(e) =>{setUserData((prevUserData) => ({ ...prevUserData, relation: e.target.value}));}}
                            >
                               <option>Select</option>
                              {relations.map((v) => (
                                <option value={v.relation_id}>
                                  {v.relation_name}
                                </option>
                                // <option value="1">2</option>
                              ))}
                            </Form.Select>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  </Col>
                </Row>
                {/* <button className="amt-Save" onClick={()=>cofirmdata()}>Confirm</button> */}

                {
                  <button
                    disabled={
                      !(showmobinputData && showeidinputData && (userdata.relation !== 'Select' && userdata.relation !==""))                    }
                    className="amt-Save"
                    onClick={() => fetchAddMemberData()}
                  >
                    Confirm 
                  </button>
                }
                {/* <button className="amt-Save" onClick={Error}>Confirm</button> */}
              </div>
            </div>

            <div className="mobile-view-add-member-field">
              <div className="row">
                <div className="col-12 d-flex">
                  <div className="col-8 add-member-data-field">
                    <div>
                      <label className="text-label">Mobile No.</label>
                    </div>
                    <div>
                      <input
                        className=""
                        maxLength={10}
                        type="number"
                        value={mobileNumber}
                        onChange={handleChange}
                        name=""
                        id=""
                      />
                    </div>
                  </div>
                  <div>
                    {open ? (
                      <img
                        className="verifyOTP"
                        style={{
                          width: "21px",
                          marginTop: "2em",
                        }}
                        src={Verify}
                        alt="Verify"
                      />
                    ) : (
                      <button
                        onClick={handleShow}
                        disabled={!mobileNumber}
                        className="amt-verify ml-2"
                      >
                        Verify
                      </button>
                    )}
                  </div>
                </div>
                <div className="col-12 d-flex">
                  <div className="col-8 add-member-data-field">
                    <div>
                      <label className="text-label">Email ID</label>
                    </div>
                    <div>
                      <input
                        className="email"
                        type="email"
                        name=""
                        value={userdata.mail}
                        onChange={handleChangeMail}
                        id=""
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div>
                    {mailVerify ? (
                      <img
                        className="verifyOTP"
                        style={{
                          width: "21px",
                          marginTop: "2em",
                        }}
                        src={
                          process.env.REACT_APP_STATIC_URL +
                          "media/DMF/tick.svg"
                        }
                        alt="Verify"
                      />
                    ) : (
                      <button
                        onClick={mailCheck}
                        disabled={!userdata.mail}
                        className="amt-verify ml-2"
                      >
                        Verify
                      </button>
                    )}
                    {/* <button className="amt-verify ml-2">Verfiy</button> */}
                  </div>
                </div>
                <div className="col-12">
                  <div className="col-12 add-member-data-field">
                    <div>
                      <label className="text-label">
                        Relationship with Primary Holder
                      </label>
                    </div>
                    <Form.Select
                      className="w-50"
                      aria-label="Default select example"
                      placeholder="Select.."
                    >
                      <option>Select..</option>
                      <option>1</option>
                      <option value="1">2</option>
                    </Form.Select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <MobileOTP />
        </Modal>
      </>
    </ProfileInsiderLayout>
  );
};

export default AddMembers;
