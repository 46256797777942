import React, { useState } from "react";
import styles from "./style.module.css";
import { ADVISORY_CALLBACKSERVICES_API_URL } from "../../../../constants";
import FintooLoader from "../../../FintooLoader";
import { toast, ToastContainer } from "react-toastify";
import { apiCall } from "../../../../common_utilities";
import ThankyouSection from "../../../ThankyouSection";
const LoanForm = () => {
    const initialFormData = {
        loanType: "personal",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        loanPartner: "",
    };

    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const [showThankYou, setShowThankYou] = useState(false);
  
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (errors[name]) {
            setErrors({ ...errors, [name]: "" });
        }
    };


    const validateForm = () => {
        const newErrors = {};
        if (!formData.firstName) {
            newErrors.firstName = "First Name is required";
        } else if (formData.firstName.length < 2 || formData.firstName.length > 50) {
            newErrors.firstName = "First Name must be between 2 and 50 characters";
        }

        if (!formData.lastName) {
            newErrors.lastName = "Last Name is required";
        } else if (formData.lastName.length < 2 || formData.lastName.length > 50) {
            newErrors.lastName = "Last Name must be between 2 and 50 characters";
        }
        if (!formData.email) {
            newErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Invalid Email format";
        }
        if (!formData.phoneNumber) {
            newErrors.phoneNumber = "Phone Number is required";
        } else if (!/^\+?\d{1,4}[\d\s]{7,15}$/.test(formData.phoneNumber)) {
            newErrors.phoneNumber = "Please enter a valid Phone Number";
        }
        if (!formData.loanPartner) {
            newErrors.loanPartner = "Please select a Loan Partner";
        }

        return newErrors;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setErrors({});
        const { firstName, lastName, email, phoneNumber, loanType, loanPartner } = formData;
        let tags = `${loanType}_${loanPartner.replace(/\s+/g, "_").toLowerCase()}`;

        const callbackdata = {
            fullname: `${firstName} ${lastName}`,
            email: email,
            tagval: "",
            servicename: "Loan",
            mobile: phoneNumber,
            tags: tags,
            utm_source: 26,
            rm_id: "96",
            skip_sms: "1",
            service: "85",
            status: "Entry",
        };

        try {
            setIsLoading(true);
            const url = ADVISORY_CALLBACKSERVICES_API_URL;
            const response_data = await apiCall(url, callbackdata, false, false);

            if (response_data.data.error_code === "0") {
                console.log("Toast should be displayed now");
                toast.success("Callback request sent successfully", {
                    position: "bottom-left",
                    autoClose: 2000,
                });
                setShowThankYou(true);

                setTimeout(() => {
                    setShowThankYou(false);
                }, 5000);
                setTimeout(() => {
                    setFormData(initialFormData);
                }, 2000); 
            } else {
                toast.success("Callback request sent successfully", {
                    position: "bottom-left",
                    autoClose: 2000,
                });
                setShowThankYou(true);

                setTimeout(() => {
                    setShowThankYou(false);
                }, 5000);
                setFormData(initialFormData);

            }
        } catch (error) {
            toast.error("Something went wrong", {
                position: "bottom-left",
                autoClose: 2000,
            });
            console.log("errorss", error)
            setFormData(initialFormData);
        } finally {
            setIsLoading(false);
        }
    };

    const uspList = ["Transparent Process", "Low Processing fees", "Easy Foreclosure", "No hidden charges" , "Dedicated Support Team"];
    const loanTypes = ["personal", "professional", "business"];
    const loanPartners = {
        personal: [
            { id: "Bajaj Finserv", logo: process.env.PUBLIC_URL + "/static/media/LandingPages/New/Bajaj-Finserv-Logo.svg", alt: "Bajaj Finserv" },
            { id: "Loantap", logo: process.env.PUBLIC_URL + "/static/media/LandingPages/New/loantaplogo.svg", alt: "Loantap" },
            { id: "TATA Capital", logo: process.env.PUBLIC_URL + "/static/media/LandingPages/New/tata_capital_Logo.svg", alt: "TATA Capital" },
        ],
        professional: [
            { id: "Bajaj Finserv", logo: process.env.PUBLIC_URL + "/static/media/LandingPages/New/Bajaj-Finserv-Logo.svg", alt: "Bajaj Finserv" },
            { id: "Loantap", logo: process.env.PUBLIC_URL + "/static/media/LandingPages/New/loantaplogo.svg", alt: "Loantap" },
        ],
        business: [
            { id: "Bajaj Finserv", logo: process.env.PUBLIC_URL + "/static/media/LandingPages/New/Bajaj-Finserv-Logo.svg", alt: "Bajaj Finserv" },
            { id: "TATA Capital", logo: process.env.PUBLIC_URL + "/static/media/LandingPages/New/tata_capital_Logo.svg", alt: "TATA Capital" },
        ],
    };


    return (
        <div>
            <ToastContainer />
            <FintooLoader isLoading={isLoading} />

            <div className={`container ${styles.Loanform}`}>
                <div className={styles.leftSection}>
                    <h2>Ready to Get Starteds?</h2>
                    <p>Apply now and take the first step toward achieving your goals</p>
                    <ul className={styles.uspList}>
                        {uspList.map((usp, index) => (
                            <li key={index} className={styles.uspItem}>
                                <span className={styles.iconWrapper}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M12 1.5C9.81276 1.50258 7.71584 2.3726 6.16923 3.91922C4.62261 5.46584 3.75259 7.56276 3.75001 9.75C3.74739 11.5374 4.33124 13.2763 5.41201 14.7C5.41201 14.7 5.63701 14.9963 5.67376 15.039L12 22.5L18.3293 15.0353C18.3623 14.9955 18.588 14.7 18.588 14.7L18.5888 14.6978C19.669 13.2747 20.2526 11.5366 20.25 9.75C20.2474 7.56276 19.3774 5.46584 17.8308 3.91922C16.2842 2.3726 14.1873 1.50258 12 1.5ZM12 12.75C11.4067 12.75 10.8266 12.5741 10.3333 12.2444C9.83995 11.9148 9.45543 11.4462 9.22837 10.8981C9.00131 10.3499 8.9419 9.74667 9.05765 9.16473C9.17341 8.58279 9.45913 8.04824 9.87869 7.62868C10.2982 7.20912 10.8328 6.9234 11.4147 6.80764C11.9967 6.69189 12.5999 6.7513 13.1481 6.97836C13.6962 7.20542 14.1648 7.58994 14.4944 8.08329C14.8241 8.57664 15 9.15666 15 9.75C14.999 10.5453 14.6826 11.3078 14.1202 11.8702C13.5578 12.4326 12.7954 12.749 12 12.75Z"
                                            fill="white"
                                        />
                                    </svg>
                                </span>
                                <span className={styles.uspText}>{usp}</span>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={styles.rightSection}>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.loantxt}>Select Loan Type?</div>
                        <div className={styles.loanType}>
                            {loanTypes.map((loanType) => (
                                <label key={loanType} className={styles.customRadio}>
                                    <input
                                        type="radio"
                                        name="loanType"
                                        value={loanType}
                                        checked={formData.loanType === loanType}
                                        onChange={handleInputChange}
                                    />
                                    <span className={styles.radioIcon}></span>
                                    <span className={styles.radioText}>{loanType.charAt(0).toUpperCase() + loanType.slice(1)} Loan</span>
                                </label>
                            ))}
                        </div>
                        <div className={styles.formGroup}>
                            <div className="custom-input">
                                <div style={{ height: "40px" }} className={`form-group ${formData.firstName ? "inputData" : null}`}>
                                    <input
                                        type="text"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                    />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label style={{ color: "#8D8D8D" }} htmlFor="name">First Name*</label>
                                </div>
                                {errors.firstName && <span>{errors.firstName}</span>}
                            </div>
                            <div className="custom-input">
                                <div style={{ height: "40px" }} className={`form-group ${formData.lastName ? "inputData" : null}`}>
                                    <input
                                        type="text"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                    />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label style={{ color: "#8D8D8D" }} htmlFor="name">Last Name*</label>
                                </div>
                                {errors.lastName && <span>{errors.lastName}</span>}
                            </div>
                            <div className="custom-input">
                                <div style={{ height: "40px" }} className={`form-group ${formData.email ? "inputData" : null}`}>
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label style={{ color: "#8D8D8D" }} htmlFor="email">Email*</label>
                                </div>
                                {errors.email && <span>{errors.email}</span>}
                            </div>
                            <div className="custom-input">
                                <div style={{ height: "40px" }} className={`form-group ${formData.phoneNumber ? "inputData" : null}`}>
                                    <input
                                        type="tel"
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleInputChange}
                                    />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label style={{ color: "#8D8D8D" }} htmlFor="phoneNumber">Phone Number*</label>
                                </div>
                                {errors.phoneNumber && <span>{errors.phoneNumber}</span>}
                            </div>
                        </div>

                        <h4>Select your Loan Partner</h4>
                        <div className={styles.loanPartners}>
                            {loanPartners[formData.loanType].map((partner) => (
                                <div
                                    key={partner.id}
                                    className={
                                        formData.loanPartner === partner.id
                                            ? styles.partnerSelected
                                            : styles.partner
                                    }
                                    onClick={() => setFormData({ ...formData, loanPartner: partner.id })}
                                >
                                    {partner.logo ? (
                                        <img src={partner.logo} alt={partner.alt} />
                                    ) : (
                                        <div className={styles.placeholder}></div>
                                    )}
                                </div>
                            ))}
                        </div>
                        {errors.loanPartner && <div style={{ padding: "10px 0", color: "red" }}>{errors.loanPartner}</div>}
                        <hr />
                        <div className="d-flex justify-content-center">
                            <button type="submit" className={styles.submitButton}>
                                Apply Now
                            </button>
                        </div>
                        <p className={styles.disclaimer}>
                            Clicking on "Apply for Loan" button will take you to third party website
                        </p>
                    </form>
                </div>
            </div>

            {showThankYou && <ThankyouSection />}
        </div>
    );
};

export default LoanForm;
