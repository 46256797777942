import React, { useEffect, useState } from "react";
import {
  FaUserAlt,
  FaLongArrowAltUp,
  FaCalendarAlt,
  FaChevronRight,
} from "react-icons/fa";
import CommonDSidebar from "./sidebar";
import pmc from "./commonDashboard.module.css";
import Table from "react-bootstrap/Table";
import AIChat from "../../../Assets/Images/CommonDashboard/AskFintoo.png";
import { ReactComponent as FintooNext } from "../../../Assets/Images/fintooNextIc.svg";
import MainHeader from "../../MainHeader";
import CloseIcon from "../../../components/Assets/Dashboard/close-button.png";
import Refresh from "../../../components/Assets/Dashboard/refresh.png";
import ApplyWhiteBg from "../../ApplyWhiteBg";
import { fetchUserData } from "../../../common_utilities";
// import Modal from "react-bootstrap/Modal";

const CommonDashboardLayout = (props) => {
  const [chatUrl, setChatUrl] = React.useState("");
  const [showResults, setShowResults] = React.useState(false);
  const onClick = () => setShowResults(true);
  const onClose = () => setShowResults(false);
  const refreshIframe = () => {
    var ifr = document.getElementsByName("ChatBot")[0];
    ifr.src = ifr.src;
  };

  useEffect(() => {
    document.body.classList.add("page-Commondashboard");
    document.body.classList.add("white-bg");
    return () => {
      document.body.classList.remove("white-bg");
      document.body.classList.remove("page-Commondashboard");
    };
  }, []);

  useEffect(() => {
    loadInit();
  }, []);
  const loadInit = async () => {
    const u = await fetchUserData(true);
    const a = `https://fintoo.hellotars.com/conv/z_dfOY/?name=${u.name}&email=${u.email}&mobile=${u.mobile}&country_code=${u.country_code}`;
    console.log("u------->", u, a);
    setChatUrl(a);
  };

  return (
    <div style={{ background: "#fff" }}>
      <ApplyWhiteBg />
      {/* <MainHeader /> */}
      <div className={pmc.wrapper}>
        <CommonDSidebar />
        <div className={pmc.contentWrapper}>
          
          <div id={pmc.content}>
            <div className={`container-fluid ${pmc.container}`}>
              {props.children}
            </div>
          </div>
          <div style={{ height: "1rem" }}></div>
        </div>
        {/* {showResults ? (
          <>
            <div
              className={pmc.chatButton}
              style={{
                display: "grid",
              }}
            >
              <div className={pmc.ChatBotRefresh}>
                <img onClick={refreshIframe} src={Refresh} />
              </div>
              <div className={pmc.ChatBotClose}>
                <img onClick={onClose} src={CloseIcon} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={pmc.ChatBot}>
              <img onClick={onClick} src={AIChat} />
            </div>
          </>
        )} */}
      </div>

      {/* {showResults ? (
        <>
          <div className={pmc.overlay}></div>
          <div className={pmc.chatBotframe}>
            {chatUrl ? (
              <iframe
                src={chatUrl}
                height="570px"
                width="100%"
                title="Iframe Example"
              ></iframe>
            ) : (
              <iframe
                src="https://fintoo.hellotars.com/conv/z_dfOY/?_nav"
                height="570px"
                width="100%"
                title="Iframe Example"
              ></iframe>
            )}
          </div>
          <div></div>
        </>
      ) : null} */}

      {/*
       */}
    </div>
  );
};

export default CommonDashboardLayout;
