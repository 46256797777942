import React, { useEffect, useState, useRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { FaArrowLeft, FaArrowRight, FaCommentsDollar } from "react-icons/fa";
import QuizeRadio from "../../components/HTML/QuizRadio";
import { GrEdit } from "react-icons/gr";
import { AiFillDelete } from "react-icons/ai";
import { BsPencilFill } from "react-icons/bs";
import { Row, Modal } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import moment from "moment";
import Switch from "react-switch";
import Select from "react-select";
import DatagatherLayout from "../../components/Layout/Datagather";
// import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import FintooRadio2 from "../../components/FintooRadio2";
import ReactDatePicker from "../../components/HTML/ReactDatePicker/ReactDatePicker";
import Slider from "../../components/HTML/Slider";
import { Link, useLocation } from "react-router-dom";
import { useOnHoverOutside } from "./useOnHoverOutside";
import {
  apiCall,
  getItemLocal,
  getParentUserId,
  loginRedirectGuest,
  setBackgroundDivImage, alphanumeric_custom,
  customspacing,
  special_start,
  getParentFpLogId,
  getFpLogId,
  setFplogid
} from "../../common_utilities";
import {
  ADVISORY_ADDGOAL_API_URL,
  ADVISORY_DELETEGOAL_API_URL,
  ADVISORY_EDITGOAL_API_URL,
  ADVISORY_GETDEFAULTROR_API_URL,
  ADVISORY_GETGOALSDATA_API_URL,
  CHECK_SESSION,
  GET_MEMBER_LIST,
  ADVISORY_UPDATE_RETIREMENT_DATE_API_URL,
  STATIC_URL,
  ADVISORY_CHECK_PROFILE_STATUS_API_URL,
  imagePath
} from "../../constants";
import * as toastr from "toastr";
import "toastr/build/toastr.css";
import DGstyles from "./DG.module.css";
import FintooLoader from "../../components/FintooLoader";
import CustomStyles from "../../components/CustomStyles.js";
import { useDispatch } from "react-redux";
import commonEncode from "../../commonEncode";
import { ScrollToTop } from "./ScrollToTop"
import FintooCheckbox from "../../components/FintooCheckbox/FintooSubCheckbox";
const Goals = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState("tab1");
  const [selectedOption, setSelectedOption] = useState("Education");
  const [sessiondata, setSessionData] = useState({});
  const [fplogid, setFpLogId] = useState("");
  const [goaldata, setGoalData] = useState([]);
  const [memberdata, setMemberData] = useState([]);
  const [familydata, setFamilyData] = useState([]);
  const [retirmentdate, setRetirmentDate] = useState("");
  const [showRadioButton, setshowRadioButton] = useState(true);
  const [lifeexpectancydate, setLifeExpentancyDate] = useState("");
  const [memberAge, setMemberAge] = useState(0);
  const [goalid, setGoalId] = useState(0);
  const [rordata, setRORData] = useState([]);
  const [goalname, setGoalName] = useState("");
  const [editflag, setEditFlag] = useState(false);
  const [goalcategorytype, setCategoryType] = useState("");
  const dropdownRef = useRef(null);
  const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false);
  const [showview, setShowView] = useState(true);
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [oldMember, setOldMember] = useState({});
  const [selectedgoalCategories, setSelectedgoalCategories] = useState([]);
  const [deletetogglegoal, setDeleteTogglegoal] = useState(false);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const removefixheader = document.querySelector('.removefixheader');
      const FixdgsubHeader = document.querySelector('.FixdgHeader');
      const scrollPosition = window.scrollY;
      if (removefixheader && FixdgsubHeader) {
        const removefixheaderRect = removefixheader.getBoundingClientRect();
        if (scrollPosition > 50) {
          setScroll(true)
          FixdgsubHeader.classList.add("DGsubheaderFix")
        } else {
          setScroll(false);
          FixdgsubHeader.classList.remove("DGsubheaderFix")

        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);

    };
  }, []);
  useEffect(() => {
    document.body.classList.add("dg-layout");
    document.getElementById("bg-goals").style.background =
      "url(" +
      imagePath +
      "https://static.fintoo.in/static/assets/img/bg/goals.svg)" +
      " no-repeat right top";

    setBackgroundDivImage();

    return () => {
      document.body.classList.remove("dg-layout");
      // document.body.classList.remove("rp-layout");
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setCurrentUrl(location.pathname);
    }, 100);
  }, [location]);
  const closeHoverMenu = () => {
    setMenuDropDownOpen(false);
  };
  useOnHoverOutside(dropdownRef, closeHoverMenu);
  const defaultGoalDetails = {
    goal_name: "Education",
    goal_category_type: "Education",
    goal_category_id: "1",
    goal_current_value: "",
    user_id: 0,
    fp_log_id: 0,
    goal_end_date: "",
    goal_for_member: 0,
    goal_frequency: "4",
    goal_inflation: 10,
    goal_interest_rate: 0,
    goal_isAfterRetirement: null,
    goal_isCritical: true,
    goal_isRecurring: false,
    goal_loan_amount: 0,
    goal_partial_loan: false,
    goal_priority: "1",
    goal_start_date: moment().format("DD/MM/YYYY"),
    goal_eduType: "1",
    goal_tenure: 0,
    member_age: memberAge,
    memberDOB: null,
    goal_footnote: "",
  };
  const [goaldetails, setGoalDetails] = useState(defaultGoalDetails);
  const defaultHandleError = {
    goalName: "",
    goalCurrentValue: "",
    goalEduType: "",
    goalStartDate: "",
    goalEndDate: "",
    goalFrequency: "",
  };
  const [handlerror, setHandleError] = useState(defaultHandleError);
  const userid = getParentUserId();
  const fpLogId = getParentFpLogId();
  const cntRef = useRef(null);
  const [show, setShow] = useState(false);
  const [counter, setCounter] = useState(0);
  const [editEducation, setEditEducation] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const scrollToGoalRef = () => {
    cntRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    document.body.classList.add("dg-layout");
    return () => {
      document.body.classList.remove("rp-layout");
    };
  }, []);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (!userid) {
      loginRedirectGuest();
    }
  }, []);
  useEffect(() => {
    // setIsLoading(true);
    getSessionData();
    getDefaultROR();
  }, []);

  const getDefaultROR = async () => {
    try {
      let url = ADVISORY_GETDEFAULTROR_API_URL;
      let ror_data = await apiCall(url, "", true, false);
      if (ror_data["error_code"] == "100") {
        setRORData(ror_data["data"]["ror_data"]["5"]);
      } else {
        setRORData([]);
      }
    } catch { }
  };
  const scrollToGoalForm = () => {
    const { offsetTop } = cntRef.current;
    cntRef.current.scrollIntoView({ behavior: 'smooth' });
    window.scroll({ top: offsetTop - 50 });
  };

  const handleLocalStorageGoalsData = (id = undefined, update = "0") => {
    let member_name = "";
    let member_id = "";

    if (id != undefined) {
      var details = getGoalDetailsById(id);
      member_name = details['member_name'];
      member_id = details['member_id'];
    }
    else {
      member_name = goaldetails["goalforname"];
      member_id = goaldetails["goal_for_member"];
    }
    let member_goals = JSON.parse(localStorage.getItem('memberGoals'));

    let member = ""
    if (member_name == "Family") {
      member = "all"
    }
    else {
      if (member_id != undefined) {
        member = member_id.toString();
      }
    }
    if (member_goals != null) {
      if (member in member_goals) {
        member_goals[member] = undefined
      }
      if (member != "all") {
        member_goals["all"] = undefined
      }
      if (update == "1") {
        let old_member = oldMember['member'];
        let old_member_id = oldMember['member_id'];
        if (old_member != "Family" && old_member_id.toString() != member && old_member_id in member_goals) {
          member_goals[old_member_id] = undefined
        }
      }
      localStorage.setItem('memberGoals', JSON.stringify(member_goals))
    }
  };

  const scrollToGoalList = () => {
    const { offsetTop } = cntRef.current;
    window.scroll({ top: offsetTop - 1500 });
  };
  const getMemberList = async () => {
    try {
      let data = {
        user_id: userid,
        is_direct: "0",
      };
      let member_data = await apiCall(GET_MEMBER_LIST, data, true, false);
      if (member_data.error_code == "100") {
        var member_array = [];
        var members = member_data["data"];
        setFamilyData(members);

        members.map((member, index) => {
          const memberId = member.fp_user_id || `temp-${index}`;
          if (member.parent_user_id == 0) {
            var goal_for_member = memberId;
            member_array.push({ value: memberId, label: "Self", retirement_age: member.retirement_age, dob: member.dob, life_expectancy: member.life_expectancy, isdependent: member.isdependent });
            setGoalDetails({
              ...goaldetails,
              goal_for_member: memberId,
            });
          } else {
            member_array.push({ value: memberId, label: member.NAME, retirement_age: member.retirement_age, dob: member.dob, life_expectancy: member.life_expectancy, isdependent: member.isdependent  });
          }
        });

        member_array.push({ value: 0, label: "Family", retirement_age: "0", dob: "0", life_expectancy: "0", isdependent: "1" });

        if (member_array.length > 0) {
          setMemberData(member_array);
        } else {
          setMemberData([]);
        }
        return member_array;
      } else {
        setMemberData([]);
      }
    } catch (error) {
      console.error("Error fetching member list:", error);
      setMemberData([]);
    }
  };


  const options = [
    {
      title: "Education",
      goal_category_type: "Education",
      image:
        imagePath +
        "http://static.fintoo.in/static/assets/img/goal/goal-education.svg",
      value: "1",
      goal_isCritical: true,
      goal_priority: "1",
      goal_frequency: "4",
      goal_inflation: rordata ? rordata["1"] : 0,
      goal_partial_loan: false,
    },
    {
      title: "Emergency",
      goal_category_type: "Emergency",
      image:
        imagePath +
        "http://static.fintoo.in/static/assets/img/goal/goal-emergency.svg",
      value: "6",
      goal_isCritical: true,
      goal_priority: "1",
      goal_frequency: "1",
      goal_inflation: rordata ? rordata["6"] : 0,
      goal_partial_loan: false,
    },
    {
      title: "Marriage",
      goal_category_type: "Marriage",
      image:
        imagePath +
        "http://static.fintoo.in/static/assets/img/goal/goal-marriage.svg",
      value: "2",
      goal_isCritical: false,
      goal_priority: "3",
      goal_frequency: "1",
      goal_inflation: rordata ? rordata["2"] : 0,
      goal_partial_loan: false,
    },
    {
      title: "Property",
      goal_category_type: "Property",
      image:
        imagePath +
        "http://static.fintoo.in/static/assets/img/goal/goal-property.svg",
      value: "4",
      goal_isCritical: false,
      goal_priority: "3",
      goal_frequency: "1",
      goal_inflation: rordata ? rordata["1"] : 0,
      goal_partial_loan: false,
    },
    {
      title: "Vacation",
      goal_category_type: "Vacation",
      image:
        imagePath +
        "http://static.fintoo.in/static/assets/img/goal/goal-vacation.svg",
      value: "5",
      goal_isCritical: false,
      goal_priority: "3",
      goal_frequency: "1",
      goal_inflation: rordata ? rordata["2"] : 0,
      goal_partial_loan: false,
    },
    {
      title: "Vehicle",
      goal_category_type: "Vehicle",
      image:
        imagePath +
        "http://static.fintoo.in/static/assets/img/goal/goal-vehicle.svg",
      value: "3",
      goal_isCritical: false,
      goal_priority: "4",
      goal_frequency: "1",
      goal_inflation: rordata ? rordata["2"] : 0,
      goal_partial_loan: false,
    },
    {
      title: "Others",
      goal_category_type: "Others",
      image:
        imagePath +
        "http://static.fintoo.in/static/assets/img/goal/goal-others.svg",
      value: "7",
      goal_isCritical: false,
      goal_priority: "3",
      goal_frequency: "1",
      goal_inflation: rordata ? rordata["6"] : 0,
      goal_partial_loan: false,
    },
  ];

  const sortOptionsPayment1 = [
    { value: "1", label: "Monthly" },
    { value: "2", label: "Quaterly" },
    { value: "3", label: "Half Yearly" },
    { value: "4", label: "Yearly" },
  ];
  const sortOptionsPayment2 = [
    { value: "1", label: "Half Yearly" },
    { value: "2", label: "Yearly" },
    { value: "3", label: "Once in 2 Year" },
    { value: "4", label: "Once in 3 Year" },
    { value: "5", label: "Once in 4 Year" },
    { value: "6", label: "Once in 5 Year" },
  ];

  let sortOptionsPayment = [];

  const sortOptionsEducation = [
    { value: "1", label: "  BSc / BCom / BA / BCA / BMS / BBA etc" },
    { value: "2", label: "Engineering" },
    { value: "3", label: "High School (Upto XIIth)" },
    { value: "4", label: "MSc / MCom / MA / MCA / MMS / MBA etc" },
    { value: "5", label: "School (Upto Xth)" },
    { value: "6", label: "Other" },
    // { value: "3", label: "Half Yearly" },
  ];
  const sortOptionsGoalPriority = [
    { value: "1", label: "Very High" },
    { value: "2", label: "High" },
    { value: "3", label: "Medium" },
    { value: "4", label: "Low" },
    { value: "5", label: "Very Low" },
    // { value: "3", label: "Half Yearly" },
  ];

  const frequencylist = {
    0: "One Time",
    1: "Monthly",
    2: "Quarterly",
    3: "Half Yearly",
    4: "Yearly",
    5: "Once In 2yr",
    6: "Once In 3yr",
    7: "Once In 4yr",
    8: "Once In 5yr",
  };
  const vacationfreqlist = {
    0: "One Time",
    1: "Half Yearly",
    2: "Yearly",
    3: "In 2 Year",
    4: "In 3 Year",
    5: "In 4 Year",
    6: "In 5 Year",
  };
  const priority_set = {
    1: "Very High",
    2: "High",
    3: "Medium",
    4: "Low",
    5: "Very Low",
  };
  // const CustomStyles = {
  //   option: (base, { data, isDisabled, isFocused, isSelected }) => {
  //     return {
  //       ...base,
  //       backgroundColor: isFocused ? "#ffff" : "#042b62",
  //       color: isFocused ? "#000" : "#fff",
  //       cursor: "pointer",
  //     };
  //   },
  //   menuList: (base) => ({
  //     ...base,
  //     height: "100px",
  //     overflowY: "scroll",
  //     scrollBehavior: "smooth",
  //     "::-webkit-scrollbar": {
  //       width: "4px",
  //       height: "0px",
  //     },
  //     "::-webkit-scrollbar-track": {
  //       background: "#fff",
  //     },
  //     "::-webkit-scrollbar-thumb": {
  //       background: "#042b62",
  //     },
  //     "::-webkit-scrollbar-thumb:hover": {
  //       background: "#555",
  //     },
  //   }),
  // };

  const getSessionData = async () => {
    try {
      let fp_log_id = await getFpLogId();
      setFplogid(fp_log_id)
    }
    catch {
      setFplogid("")
    }
    try {
      let url = CHECK_SESSION;
      let data = { user_id: userid, sky: getItemLocal("rmf"), is_direct: 0 };
      let session_data = await apiCall(url, data, true, false);
      if (session_data["error_code"] == "100") {
        // setIsLoading(false);
        await checkprofile(session_data);
        // let url = ADVISORY_UPDATE_RETIREMENT_DATE_API_URL;
        // let data = { fp_user_id: getParentUserId(), fp_log_id: getParentFpLogId() };
        // let res_ret = await apiCall(url, data, true, false);
        let memberList = await getMemberList();
        setSessionData(session_data["data"]);
        getGoalsList(fpLogId);
        setFpLogId(fpLogId);
        if (session_data["data"]["user_details"]) {
          var user_id = session_data["data"]["user_details"]["user_id"];
          var fp_log_id = session_data["data"]["user_details"]["fp_log_id"];
          var getUserdobyear = new Date(
            session_data["data"]["user_details"]["dob"]
          ).getFullYear();
          var getCurrentyear = new Date().getFullYear();
          var member_age = getCurrentyear - getUserdobyear;
          setGoalDetails({
            ...goaldetails,
            user_id: user_id,
            fp_log_id: fp_log_id,
            member_age: member_age,
            goal_for_member: memberList["0"].value,
          });
          setMemberAge(member_age);
          // setGoalDetails({...goaldetails,user_id:user_id,fp_log_id:fp_log_id})
          // var retirement_date = moment(res_ret['data'][0]['dob'])
          //   .add(res_ret['data'][0]['retirement_age'], "y")
          //   .format("DD/MM/YYYY");
          // var life_expectancy_date = moment(res_ret['data'][0]['dob'])
          //   .add(res_ret['data'][0]['life_expectancy'], "y")
          //   .format("DD/MM/YYYY");
          // setRetirmentDate(retirement_date);
          // setLifeExpentancyDate(life_expectancy_date);
          var retirement_date = moment(session_data["data"]["user_details"]['dob'])
            .add(session_data["data"]["user_details"]['retirement_age'], "y")
            .format("MM/DD/YYYY");
          var life_expectancy_date = moment(session_data["data"]["user_details"]['dob'])
            .add(session_data["data"]["user_details"]['life_expectancy'], "y")
            .format("MM/DD/YYYY");

          setRetirmentDate(retirement_date);
          setLifeExpentancyDate(life_expectancy_date);
        }
      } else {
        // setIsLoading(false);
        loginRedirectGuest();
      }
    } catch (error) {
      console.log(error);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something Went Wrong1");
    }
  };

  const checkprofile = async (sessionData) => {
    try {
      let api_data = {
        user_id: sessionData['data']["id"],
        fp_log_id: sessionData['data']['user_details']["fp_log_id"],
        web: 1,
        is_direct: 0
      };
      var payload_data = commonEncode.encrypt(JSON.stringify(api_data));
      var res = await apiCall(
        ADVISORY_CHECK_PROFILE_STATUS_API_URL,
        payload_data,
        false,
        false
      );
      let decoded_res = JSON.parse(commonEncode.decrypt(res));
      if (decoded_res['error_code'] == '100') {
        dispatch({ type: "UPDATE_PROFILE", payload: decoded_res['data'] });
        const profile_completed_mapping = {
          17: 117.496,
          50: 70.4973,
          67: 46.9982,
          83: 23.4991,
          100: 0
        };

        const profile_completed = decoded_res['data'][13]['profile_completed'] === 66 ? 67 : decoded_res['data'][13]['profile_completed'];
        const sectionIdsToCheck = [1, 3, 5, 6, 7, 8];
        const allConditionsMet = sectionIdsToCheck.every((sectionId) => {
          const matchingEntry = decoded_res["data"].find(
            (entry) => entry.section_id === sectionId
          );
          return matchingEntry && matchingEntry.total > 0;
        });

        const sectionIdsToCheckk = [1, 3];
        const allConditionsMett = sectionIdsToCheckk.every((sectionId) => {
          const matchingEntryy = decoded_res["data"].find(
            (entry) => entry.section_id === sectionId
          );
          return matchingEntryy && matchingEntryy.total > 0;
        });

        let newNumber;
        if (allConditionsMet) {
          newNumber = "1";
        } else {
          newNumber = "0";
        }

        let newNumberr;
        if (allConditionsMett) {
          newNumberr = "1";
        } else {
          newNumberr = "0";
        }
        const sectionTextMap = {
          1: "About You",
          3: "About You",
          5: "Goals",
          6: "Income & Expenses",
          7: "Income & Expenses",
          8: "Assets & Liabilities",
        };

        const filteredData = decoded_res["data"].filter((item) =>
          [1, 3, 5, 6, 7, 8].includes(item.section_id)
        );

        const sectionsWithTotalZeroTextArray = filteredData
          .filter((item) => item.total === 0)
          .map((item) => sectionTextMap[item.section_id]);

        const uniqueSectionsWithTotalZeroTextArray = [
          ...new Set(sectionsWithTotalZeroTextArray),
        ];
        const sectionsWithTotalZeroText =
          uniqueSectionsWithTotalZeroTextArray.join(", ");

        if (
          uniqueSectionsWithTotalZeroTextArray.includes("About You") &&
          uniqueSectionsWithTotalZeroTextArray.includes("Income & Expenses") &&
          uniqueSectionsWithTotalZeroTextArray.includes("Assets & Liabilities")
        ) {
          sessionStorage.setItem('showAboutYouToast', '1')
          window.location.href = process.env.PUBLIC_URL + "/datagathering/about-you"

        } else if (uniqueSectionsWithTotalZeroTextArray.includes("About You")) {
          sessionStorage.setItem('showAboutYouToast', '1')
          window.location.href = process.env.PUBLIC_URL + "/datagathering/about-you"

        }
      }
    } catch (e) {
      console.error("error---->", e);
    }
  };

  const getGoalDetailsById = (id) => {
    let goals = JSON.parse(localStorage.getItem('goalData'));
    // return member_id
    for (let i = 0; i < goals.length; i++) {
      if (goals[i]["id"] == id) {
        return { member_name: goals[i]["goalforname"], member_id: goals[i]["goal_for_member"] }
      }
    }
    return {}
  }

  const getGoalsList = async (fplogid) => {
    try {
      let url = ADVISORY_GETGOALSDATA_API_URL;
      let data = { user_id: userid, fp_log_id: fplogid, is_direct: 0 };
      let goal_data = await apiCall(url, data, true, false);
      localStorage.setItem('goalData', JSON.stringify(goal_data["data"]));

      if (goal_data["error_code"] == "100") {
        // setIsLoading(false);
        setIsDataLoading(false);
        goal_data["data"].map((data) => {
          if (data["goal_inflation"] != null) {
            data["goal_inflation"] = parseInt(data["goal_inflation"]);
          }
          if (data["goal_start_date"] != null) {

            var g_start_date = data["goal_start_date"];
            var today = new Date();
            if (g_start_date < today) {
              var tday = today.getDate();
              var tmonth = today.getMonth() + 1; // Adding 1 since months are zero-based
              var tyear = today.getFullYear();
              if (tday < 10) {
                tday = "0" + tday;
              }

              if (tmonth < 10) {
                tmonth = "0" + tmonth;
              }
              data["goalstartdate"] = tyear + "-" + tmonth + "-" + tday;
            } else {
              // data['goalenddate'] = data[];
            }
          }
        });
        if (goal_data["goal_category_id"] == "1") {
          setEditEducation(false);
        }
        setGoalData(goal_data["data"]);
      } else {
        setGoalData([]);
      }
    } catch {
      toastr.error("Something Went Wrong", {
        position: "bottom-left",
      });
    }
  };
  //

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    let valid = true;

    if (goaldetails.fp_log_id === 0 && goaldetails.user_id === 0) {
      const fp_log_id = sessiondata["fp_log_id"];
      const user_id = sessiondata["user_details"]["user_id"];
      setGoalDetails(prev => ({
        ...prev,
        user_id: user_id,
        fp_log_id: fp_log_id,
        is_direct: 0
      }));
    }

    // Goal Name Validation
    if (goaldetails.goal_name === "") {
      setHandleError(prev => ({
        ...prev,
        goalName: "Please enter goal name"
      }));
      valid = false;
    } else if (goaldetails.goal_name.length < 3 || goaldetails.goal_name.length > 35) {
      setHandleError(prev => ({
        ...prev,
        goalName: "Goal name must be between 3 and 35 characters"
      }));
      valid = false;
    } else {
      setHandleError(prev => ({
        ...prev,
        goalName: ""
      }));
    }

    // Goal Current Value Validation
    handleGoalValue(goaldetails.goal_current_value);
    if (handlerror.goalCurrentValue) {
      valid = false;
    }

    // Goal Edu Type Validation
    if (goaldetails.goal_eduType === "") {
      setHandleError(prev => ({
        ...prev,
        goalEduType: "Please select education type"
      }));
      valid = false;
    } else {
      setHandleError(prev => ({
        ...prev,
        goalEduType: ""
      }));
    }

    // Goal Start Date Validation
    if (goaldetails.goal_start_date === "") {
      setHandleError(prev => ({
        ...prev,
        goalStartDate: "Please select start date"
      }));
      valid = false;
    } else {
      setHandleError(prev => ({
        ...prev,
        goalStartDate: ""
      }));
    }

    // Goal End Date Validation
    if (goaldetails.goal_end_date === "" && goaldetails.goal_isRecurring) {
      setHandleError(prev => ({
        ...prev,
        goalEndDate: "Please select end date"
      }));
      valid = false;
    } else {
      setHandleError(prev => ({
        ...prev,
        goalEndDate: ""
      }));
    }

    // Goal Frequency Validation
    if (goaldetails.goal_frequency === "" || goaldetails.goal_frequency === "0") {
      setHandleError(prev => ({
        ...prev,
        goalFrequency: "Please select a valid frequency"
      }));
      valid = false;
    } else {
      setHandleError(prev => ({
        ...prev,
        goalFrequency: ""
      }));
    }

    // Convert inflation to integer if it's a string
    if (typeof goaldetails.goal_inflation === "string") {
      goaldetails.goal_inflation = parseInt(goaldetails.goal_inflation, 10);
    }

    // Conditional validation based on goal category
    if (goaldetails.goal_category_id === "1") {
      if (goaldetails.goal_isRecurring) {
        if (
          goaldetails.goal_name &&
          goaldetails.goal_name.length >= 3 &&
          goaldetails.goal_name.length <= 35 &&
          goaldetails.goal_current_value &&
          goaldetails.goal_current_value > 1 &&
          goaldetails.goal_eduType &&
          goaldetails.goal_start_date &&
          goaldetails.goal_end_date &&
          goaldetails.goal_frequency &&
          goaldetails.goal_frequency !== "0"
        ) {
          addGoal(e);
        } else {
          valid = false;
        }
      } else {
        if (
          goaldetails.goal_name &&
          goaldetails.goal_name.length >= 3 &&
          goaldetails.goal_name.length <= 35 &&
          goaldetails.goal_current_value &&
          goaldetails.goal_current_value > 1 &&
          goaldetails.goal_eduType &&
          goaldetails.goal_start_date
        ) {
          addGoal(e);
        } else {
          valid = false;
        }
      }
    } else {
      if (goaldetails.goal_isRecurring) {
        if (
          goaldetails.goal_name &&
          goaldetails.goal_name.length >= 3 &&
          goaldetails.goal_name.length <= 35 &&
          goaldetails.goal_current_value &&
          goaldetails.goal_current_value > 1 &&
          goaldetails.goal_start_date &&
          goaldetails.goal_end_date &&
          goaldetails.goal_frequency &&
          goaldetails.goal_frequency !== "0"
        ) {
          addGoal(e);
        } else {
          valid = false;
        }
      } else {
        if (
          goaldetails.goal_name &&
          goaldetails.goal_name.length >= 3 &&
          goaldetails.goal_name.length <= 35 &&
          goaldetails.goal_current_value &&
          goaldetails.goal_current_value > 1 &&
          goaldetails.goal_start_date
        ) {
          addGoal(e);
        } else {
          valid = false;
        }
      }
    }

    // if (!valid) {
    //     simpleValidator.current.showMessages();
    // }
  };

  const addGoal = async (e) => {
    e.preventDefault();
    try {
      let url = ADVISORY_ADDGOAL_API_URL;
      let payload = goaldetails;
      payload.user_id = getParentUserId();
      payload.fp_log_id = getParentFpLogId();
      payload.is_direct = 0;

      setIsLoading(true);
      setIsDataLoading(true)

      if (payload.goal_isRecurring == false && payload.goal_end_date == "") {
        payload.goal_end_date = payload.goal_start_date;
      }
      let addgoalData = await apiCall(url, payload, true, false);
      getGoalsList(fpLogId);

      if (addgoalData["error_code"] == "100") {
        var getUserdobyear = new Date(
          sessiondata["user_details"]["dob"]
        ).getFullYear();
        var getCurrentyear = new Date().getFullYear();
        var member_age = getCurrentyear - getUserdobyear;
        setIsDataLoading(true);
        scrollToGoalList();
        setSelectedOption(defaultGoalDetails.goal_category_type);
        getGoalsList(fpLogId);
        setHandleError({
          ...handlerror,
          goalName: "",
          goalEduType: "",
          goalFrequency: "",
          goalCurrentValue: "",
          goalStartDate: "",
          goalEndDate: "",
        });
        setIsLoading(false);
        // await new Promise((resolve, reject)=> setTimeout(resolve, 5000));
        setIsDataLoading(false);
        setEditFlag(false);
        var savetext = goaldetails.id ? " updated " : " saved ";
        var msg = goaldetails.goal_name ? " - " + goaldetails.goal_name : "";
        toastr.options.positionClass = "toast-bottom-left";
        toastr.success(
          "Data" + savetext + "successfully for " + goaldetails.goal_name + msg
        );
        setGoalDetails({
          ...defaultGoalDetails,
          user_id: sessiondata["user_details"]["user_id"],
          fp_log_id: fpLogId,
          member_age: member_age,
          goal_for_member: memberdata["0"].value,
        });
      } else {
        setIsLoading(false);
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  const editGoal = async (id) => {
    try {
      let url = ADVISORY_EDITGOAL_API_URL;
      let data = { id: id, is_direct: 0 };
      setIsLoading(true);

      let editgoalData = await apiCall(url, data, true, false);
      if (editgoalData["error_code"] == "100") {
        setIsLoading(false);
        var goalData = editgoalData["data"][0];
        if (goalData["goal_category_id"] == "1") {
          setEditEducation(false);
        }
        if (goalData["goal_eduType"] == "BSc / BCom / BA / BCA / BMS / BBA etc") {
          goalData["goal_eduType"] = "1"
        } else if (goalData["goal_eduType"] == "Engineering") {
          goalData["goal_eduType"] = "2"
        } else if (goalData["goal_eduType"] == "High School (Upto XIIth)") {
          goalData["goal_eduType"] = "3"
        } else if (goalData["goal_eduType"] == "MSc / MCom / MA / MCA / MMS / MBA etc") {
          goalData["goal_eduType"] = "4"
        } else if (goalData["goal_eduType"] == "School (Upto Xth)") {
          goalData["goal_eduType"] = "5"
        } else if (goalData["goal_eduType"] == "Other") {
          goalData["goal_eduType"] = "6"
        }
        if (goalData["goal_category_id"] != "1") {
          goalData["goal_eduType"] = ""
        }
        setGoalDetails(goalData);
        setOldMember({ "member": goalData.goalforname, "member_id": goalData.goal_for_member })
        scrollToGoalForm();
        setSelectedOption(goalData.goal_category_type);
        setHandleError({
          ...handlerror,
          goalName: "",
          goalEduType: "",
          goalFrequency: "",
          goalCurrentValue: "",
          goalStartDate: "",
          goalEndDate: "",
        });
        var msg = goalData.goal_name ? " - " + goalData.goal_name : "";
        toastr.options.positionClass = "toast-bottom-left";
        toastr.success(
          "You can now edit details for " + goalData.goal_category_type + msg
        );
      } else {
        setIsLoading(false);

        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Something went wrong");
      }
    } catch {
      setIsLoading(false);

      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  const deleteGoal = async () => {
    const filteredData = goaldata.filter(item => ['96', '124'].includes(item.goal_category_id));
    const ids = filteredData.map(item => item.id);
    const selected_ids = selectedgoalCategories.filter(id => !ids.includes(id));
    try {
      let url = ADVISORY_DELETEGOAL_API_URL;
      let data = {
        goal_id: selected_ids,
        user_id: sessiondata["user_details"]["user_id"],
        fp_log_id: sessiondata["user_details"]["fp_log_id"],
        is_direct: 0
      };
      let deletegoalData = await apiCall(url, data, true, false);
      if (deletegoalData["error_code"] == "100") {
        setShow(false);
        getGoalsList(sessiondata["user_details"]["fp_log_id"]);
        var msg = goalname ? " - " + goalname : "";
        toastr.options.positionClass = "toast-bottom-left";
        // toastr.success(goalcategorytype + msg + " Data Deleted Successfully");
        toastr.success(" Data Deleted Successfully");
        setGoalDetails(defaultGoalDetails);
        setGoalDetails({
          ...defaultGoalDetails,
          goal_for_member: memberdata["0"].value,
        });
        setSelectedgoalCategories([])
        setHandleError({
          ...handlerror,
          goalName: "",
          goalEduType: "",
          goalFrequency: "",
          goalCurrentValue: "",
          goalStartDate: "",
          goalEndDate: "",
        });
        setSelectedOption("Education");
        setEditFlag(false);
      } else {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Something went wrong");
      }
    } catch {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };
  const handleGoalName = (name) => {
    if (name == "") {
      setHandleError({ ...handlerror, goalName: "Please enter goal name" });
      scrollToGoalRef();
    } else {
      if (name.length < 3 || name.length > 35) {
        setHandleError({
          ...handlerror,
          goalName: "Name must be between 3-35 characters",
        });
        scrollToGoalRef();
      } else if (!special_start(name)) {
        setHandleError({
          ...handlerror,
          goalName: "Special characters not allowed",
        });
        scrollToGoalRef();
      } else if (!alphanumeric_custom(name)) {
        setHandleError({
          ...handlerror,
          goalName: "Special characters not allowed",
        });
        scrollToGoalRef();
      } else if (!customspacing(name)) {
        setHandleError({
          ...handlerror,
          goalName: "No white spaces are allowed at beginning/end",
        });
        scrollToGoalRef();
      } else {
        setHandleError({ ...handlerror, goalName: "" });
      }
    }
  };

  const handleGoalValue = (currentValue) => {
    if (currentValue === "") {
      setHandleError(prev => ({
        ...prev,
        goalCurrentValue: "Please enter goal current value"
      }));
      scrollToGoalRef();
    } else if (currentValue.length > 10) {
      setHandleError(prev => ({
        ...prev,
        goalCurrentValue: "Please enter less than 10 digits"
      }));
      scrollToGoalRef();
    } else if (!/^\d+$/.test(currentValue) || /^0\d+/.test(currentValue) || parseInt(currentValue, 10) < 1) {
      setHandleError(prev => ({
        ...prev,
        goalCurrentValue: "Please enter a value greater than or equal to 1"
      }));
      scrollToGoalRef();
    } else {
      setHandleError(prev => ({
        ...prev,
        goalCurrentValue: ""
      }));
    }
  };


  const handleEdu = (type) => {
    if (type == "") {
      handlerror.goalEduType = "Please select education type";
      scrollToGoalRef();
      setHandleError({
        ...handlerror,
        goalEduType: "Please select education type",
      });
      scrollToGoalRef();
    } else {
      setHandleError({ ...handlerror, goalEduType: "" });
    }
  };
  const handleStartDate = (startdate) => {
    if (startdate == "") {
      setHandleError({
        ...handlerror,
        goalStartDate: "Please select start date",
      });
      scrollToGoalRef();
    } else {
      setHandleError({ ...handlerror, goalStartDate: "" });
    }
  };
  const handleEndDate = (enddate) => {
    if (enddate == "") {
      setHandleError({ ...handlerror, goalEndDate: "Please select goal upto retirement/life expectancy age" });
      scrollToGoalRef();
    } else {
      setHandleError({ ...handlerror, goalEndDate: "" });
    }
  };

  const handleGoalFrq = (goalFrq) => {
    if (goalFrq == "") {
      setHandleError({
        ...handlerror,
        goalFrequency: "Please select goal frequency",
      });
      scrollToGoalRef();
    } else {
      setHandleError({ ...handlerror, goalFrequency: "" });
    }
  };

  // const handleDate = (isFrq) => {
  //   if (isFrq == true) {
  //     goaldetails.goal_isAfterRetirement = null;
  //     goaldetails.goal_start_date = moment().format("DD/MM/YYYY");
  //     goaldetails.goal_end_date = "";
  //   } else {
  //     goaldetails.goal_start_date = moment().format("DD/MM/YYYY");
  //     goaldetails.goal_end_date = moment().format("DD/MM/YYYY");
  //   }
  // };
  const handleIsRetirement = (goal_AfterRetirement) => {

    if (goal_AfterRetirement == "0") {
      var goal_end_date = moment(retirmentdate, "MM/DD/YYYY").format("DD/MM/YYYY");
      setGoalDetails({
        ...goaldetails,
        goal_end_date: goal_end_date,
        goal_isAfterRetirement: "0",
      });
    } else {
      var goal_end_date = moment(lifeexpectancydate, "MM/DD/YYYY").format("DD/MM/YYYY");
      setGoalDetails({
        ...goaldetails,
        goal_end_date: goal_end_date,
        goal_isAfterRetirement: "1",
      });
    }

  };

  const handlePatialLoan = (isPartialLoan) => {
    if (isPartialLoan == true) {
      goaldetails.goal_loan_amount = 0;
      goaldetails.goal_tenure = 1;
      goaldetails.goal_interest_rate = 0;
      // setGoalDetails({...goaldetails,goal_loan_amount:"22",goal_tenure:goal_tenure,goal_interest_rate:goal_interest_rate})
    }
  };

  const handleEdutype = () => {
    if (goaldetails.goal_eduType != "" && editEducation == true) {
      if (goaldetails.goal_eduType == "1" || goaldetails.goal_eduType == "2") {
        familydata.map((v) => {
          if (
            v.fp_user_id == goaldetails.goal_for_member &&
            goaldetails.goal_for_member != 0
          ) {
            var goalmemberDOB = v.dob;
            var dob = new Date(goalmemberDOB);
            var memberDOB = dob;
            var ageInMs = Date.now() - dob.getTime();
            var ageInDate = new Date(ageInMs);
            var age = Math.abs(ageInDate.getUTCFullYear() - 1970);
            if (age > 17) {
              var member_age = age;
            } else {
              var member_age = 17;
            }
            if (v.parent_user_id == 0 || v.isdependent == "0") {
              setshowRadioButton(true);
              var goal_startdate = moment().format("DD/MM/YYYY");
              var goal_enddate = moment(goal_startdate, "DD/MM/YYYY").format(
                "DD/MM/YYYY"
              );
              var goal_end_date = moment(goal_startdate, "DD/MM/YYYY")
                .add(3, "years")
                .format("DD/MM/YYYY");
              var goal_isRecurring = true;
              setGoalDetails({
                ...goaldetails,
                member_age: member_age,
                memberDOB: moment(memberDOB).format("DD/MM/YYYY"),
                goal_isRecurring: goal_isRecurring,
                goal_start_date: goal_startdate,
                goal_end_date: goal_end_date,
                goal_isAfterRetirement: null
              });
            } else {
              setshowRadioButton(false);
              if (age > 17) {
                var goal_startdate = moment().format("DD/MM/YYYY");
                var goal_enddate = moment(goal_startdate, "DD/MM/YYYY")
                  .add(3, "years")
                  .format("DD/MM/YYYY");
              } else {
                var member_dob = v.dob;
                var dob = new Date(member_dob);
                // var goal_startdate = dob;
                var goal_startdate = moment(dob, "DD/MM/YYYY")
                  .add(member_age, "years")
                  .format("DD/MM/YYYY");
                var goal_enddate = new Date(goal_startdate);
              }
              var goal_enddate = moment(goal_startdate, "DD/MM/YYYY")
                .add(3, "years")
                .format("DD/MM/YYYY");
              // goal_enddate.setFullYear(goal_startdate.getFullYear() + 3);
              var goal_isRecurring = true;
              setGoalDetails({
                ...goaldetails,
                goal_isRecurring: goal_isRecurring,
                member_age: member_age,
                memberDOB: moment(memberDOB).format("DD/MM/YYYY"),
                goal_start_date: goal_startdate,
                goal_end_date: goal_enddate,
              });
            }
          }
        });
      } else if (goaldetails.goal_eduType == "3") {
        familydata.map((v) => {
          if (
            v.fp_user_id == goaldetails.goal_for_member &&
            goaldetails.goal_for_member != 0
          ) {
            var goalmemberDOB = v.dob;
            var dob = new Date(goalmemberDOB);
            var memberDOB = dob;
            var ageInMs = Date.now() - dob.getTime();
            var ageInDate = new Date(ageInMs);
            var age = Math.abs(ageInDate.getUTCFullYear() - 1970);
            if (age > 16) {
              var member_age = age;
            } else {
              var member_age = 16;
            }
            if (v.parent_user_id == 0 || v.isdependent == "0") {
              setshowRadioButton(true);
              var goal_enddate = new Date();
              var goal_isRecurring = false;
              setGoalDetails({
                ...goaldetails,
                member_age: member_age,
                memberDOB: moment(memberDOB).format("DD/MM/YYYY"),
                goal_isRecurring: goal_isRecurring,
                goal_start_date: moment(goal_enddate).format("DD/MM/YYYY"),
                goal_isAfterRetirement: null,
                goal_end_date: null
              });
            } else {
              setshowRadioButton(false);
              if (age > 16) {
                var goal_enddate = new Date();
              } else {
                var member_dob = v.dob;
                var dob = new Date(member_dob);
                var goal_enddate = dob;
                var goal_enddate = moment(dob, "DD/MM/YYYY")
                  .add(member_age, "years")
                  .format("DD/MM/YYYY");
                // goal_enddate.setFullYear(
                //   dob.getFullYear() + member_age
                // );
              }
              var goal_isRecurring = false;
              setGoalDetails({
                ...goaldetails,
                member_age: member_age,
                memberDOB: moment(memberDOB).format("DD/MM/YYYY"),
                goal_isRecurring: goal_isRecurring,
                goal_start_date: goal_enddate,
                goal_end_date: moment().format("DD/MM/YYYY"),
              });
            }
          }
        });
      } else if (goaldetails.goal_eduType == "4") {
        familydata.map((v) => {
          if (
            v.fp_user_id == goaldetails.goal_for_member &&
            goaldetails.goal_for_member != 0
          ) {
            var goalmemberDOB = v.dob;
            var dob = new Date(goalmemberDOB);
            var memberDOB = dob;
            var ageInMs = Date.now() - dob.getTime();
            var ageInDate = new Date(ageInMs);
            var age = Math.abs(ageInDate.getUTCFullYear() - 1970);
            if (age > 21) {
              var member_age = age;
            } else {
              var member_age = 21;
            }
            if (v.parent_user_id == 0 || v.isdependent == "0") {
              setshowRadioButton(true);
              var goal_startdate = moment().format("DD/MM/YYYY");
              var goal_enddate = moment(goal_startdate, "DD/MM/YYYY")
                .add(1, "years")
                .format("DD/MM/YYYY");
              var goal_isRecurring = true;
              setGoalDetails({
                ...goaldetails,
                member_age: member_age,
                memberDOB: moment(memberDOB).format("DD/MM/YYYY"),
                goal_isRecurring: goal_isRecurring,
                goal_start_date: moment(goal_startdate, "DD/MM/YYYY").format(
                  "DD/MM/YYYY"
                ),
                goal_end_date: moment(goal_enddate, "DD/MM/YYYY").format(
                  "DD/MM/YYYY"
                ),
                goal_isAfterRetirement: null
              });
            } else {
              setshowRadioButton(false);
              if (age > 21) {
                var goal_startdate = moment().format("DD/MM/YYYY");
                var goal_enddate = moment(goal_startdate, "DD/MM/YYYY")
                  .add(1, "years")
                  .format("DD/MM/YYYY");
              } else {
                var member_dob = v.dob;
                var dob = new Date(member_dob);
                var goal_startdate = moment(dob, "DD/MM/YYYY")
                  .add(member_age, "years")
                  .format("DD/MM/YYYY");

                var goal_enddate = moment(goal_startdate, "DD/MM/YYYY")
                  .add(1, "years")
                  .format("DD/MM/YYYY");
              }
              var goal_isRecurring = true;
              setGoalDetails({
                ...goaldetails,
                member_age: member_age,
                memberDOB: moment(memberDOB).format("DD/MM/YYYY"),
                goal_isRecurring: goal_isRecurring,
                goal_start_date: goal_startdate,
                goal_end_date: goal_enddate,
              });
            }
          }
        });
      } else if (goaldetails.goal_eduType == "5") {
        familydata.map((v) => {
          if (
            v.fp_user_id == goaldetails.goal_for_member &&
            goaldetails.goal_for_member != 0
          ) {
            var goalmemberDOB = v.dob;
            var dob = new Date(goalmemberDOB);
            var memberDOB = dob;
            var ageInMs = Date.now() - dob.getTime();
            var ageInDate = new Date(ageInMs);
            var age = Math.abs(ageInDate.getUTCFullYear() - 1970);
            if (age > 14) {
              var member_age = age;
            } else {
              var member_age = 14;
            }
            if (v.parent_user_id == 0 || v.isdependent == "0") {
              setshowRadioButton(true);
              var goal_enddate = new Date();
              var goal_isRecurring = false;
              setGoalDetails({
                ...goaldetails,
                member_age: member_age,
                memberDOB: moment(memberDOB).format("DD/MM/YYYY"),
                goal_isRecurring: goal_isRecurring,
                goal_start_date: moment(goal_enddate).format("DD/MM/YYYY"),
                goal_end_date: null,
                goal_isAfterRetirement: null
              });
            } else {
              setshowRadioButton(false);
              if (age > 14) {
                var goal_enddate = moment().format("DD/MM/YYYY");
              } else {
                var member_dob = v.dob;
                var dob = new Date(member_dob);
                var goal_enddate = dob;
                var goal_enddate = moment(dob, "DD/MM/YYYY")
                  .add(member_age, "years")
                  .format("DD/MM/YYYY");
              }
              var goal_isRecurring = false;
              setGoalDetails({
                ...goaldetails,
                member_age: member_age,
                memberDOB: moment(memberDOB, "DD/MM/YYYY").toDate(),
                goal_isRecurring: goal_isRecurring,
                goal_start_date: goal_enddate,
                goal_end_date: moment().format("DD/MM/YYYY"),
              });
            }
          }
        });
      } else {
        familydata.map((v) => {
          if (
            v.fp_user_id == goaldetails.goal_for_member &&
            goaldetails.goal_for_member != 0
          ) {
            if (v.parent_user_id == 0 || v.isdependent == "0") {
              setshowRadioButton(true);
            }else{
              setshowRadioButton(false);
              
            }            
            var goalmemberDOB = v.dob;
            var dob = new Date(goalmemberDOB);
            var memberDOB = dob;
            var ageInMs = Date.now() - dob.getTime();
            var ageInDate = new Date(ageInMs);
            var age = Math.abs(ageInDate.getUTCFullYear() - 1970);
            var goal_enddate = moment().format("DD/MM/YYYY");
            var member_age = age;
            var goal_isRecurring = false;
            setGoalDetails({
              ...goaldetails,
              member_age: member_age,
              memberDOB: moment(memberDOB).format("DD/MM/YYYY"),
              goal_isRecurring: goal_isRecurring,
              goal_start_date: goal_enddate,
              goal_end_date: moment().format("DD/MM/YYYY"),
              goal_isAfterRetirement: null
            });
          }
        });
      }
    } else {
      setEditEducation(true);
      var memberdob = new Date();
      familydata.map((v) => {
        if (goaldetails.goal_for_member != 0) {
          if (v.fp_user_id == goaldetails.goal_for_member) {
            memberdob = new Date(v.dob);
          }
        } else {
          memberdob = sessiondata["user_details"]["dob"];
        }
      });
      var getmemberdobyear = new Date(memberdob).getFullYear();
      var getCurrentyear = new Date().getFullYear();

      var member_age = getCurrentyear - getmemberdobyear;
      if (!goaldetails.id) {
        setGoalDetails({
          ...goaldetails,
          member_age: member_age,
        });
      }
    }
    if (
      retirmentdate != goaldetails.goal_end_date ||
      lifeexpectancydate != goaldetails.goal_end_date
    ) {
      goaldetails.goal_isAfterRetirement = null;
    }
  };

  const setDate = (date, dateType) => {
    if (dateType == "startDate") {
      if (
        goaldetails.goal_category_id == "1" &&
        goaldetails.goal_eduType != ""
      ) {
        const updatedStartDate = moment(date, "DD/MM/YYYY");
        if (goaldetails.memberDOB) {
          var dateString = goaldetails.memberDOB;
          var dateParts = dateString.split("/");
          var day = parseInt(dateParts[0], 10);
          var month = parseInt(dateParts[1], 10) - 1; // Subtract 1 since months are 0-based (0 = January)
          var year = parseInt(dateParts[2], 10);
          var memberDOB = new Date(year, month, day);
          var ageDifMs = updatedStartDate - memberDOB.getTime();
        } else {
          if (goaldetails.goal_for_member != sessiondata["user_details"]["id"]) {
            var userDOB = familydata.find((family) => family.fp_user_id == goaldetails.goal_for_member)?.dob;
          } else {
            var userDOB = sessiondata["user_details"]["dob"];
          }
          userDOB = new Date(userDOB);
          var ageDifMs = updatedStartDate - userDOB.getTime();
        }
        var ageDate = new Date(ageDifMs);
        var age = ageDate.getUTCFullYear() - 1970;
        if (
          goaldetails.goal_eduType == "1" ||
          goaldetails.goal_eduType == "2"
        ) {
          const updatedEndDate = updatedStartDate.clone().add(3, "years");
          setGoalDetails({
            ...goaldetails,
            goal_start_date: updatedStartDate.format("DD/MM/YYYY"),
            goal_end_date: updatedEndDate.format("DD/MM/YYYY"),
            member_age: age,
          });
        } else if (goaldetails.goal_eduType == "4") {
          const updatedEndDate = updatedStartDate.clone().add(1, "years");
          setGoalDetails({
            ...goaldetails,
            goal_start_date: updatedStartDate.format("DD/MM/YYYY"),
            goal_end_date: updatedEndDate.format("DD/MM/YYYY"),
            member_age: age,
          });
        } else {
          setGoalDetails({
            ...goaldetails,
            goal_start_date: updatedStartDate.format("DD/MM/YYYY"),
            member_age: age,
          });
        }
      } else {
        setGoalDetails({
          ...goaldetails,
          goal_start_date: moment(date).format("DD/MM/YYYY"),
        });
      }
    } else {
      // goaldetails.goal_end_date = moment(date).format("DD/MM/YYYY");
      if (retirmentdate != date || lifeexpectancydate != date) {
        goaldetails.goal_isAfterRetirement = null;
      }
      setGoalDetails({
        ...goaldetails,
        goal_end_date: moment(date).format("DD/MM/YYYY"),
      });
    }
  };

  const cancelGoalForm = (e) => {
    e.preventDefault();
    setGoalDetails({
      ...defaultGoalDetails,
      user_id: sessiondata["user_details"]["user_id"],
      fp_log_id: sessiondata["user_details"]["fp_log_id"],
    });
    setSelectedOption(defaultGoalDetails.goal_category_type);
    setHandleError({ ...defaultHandleError });
    setEditFlag(false);
  };

  const sortByGoal = (type) => {
    const goalsData = [...goaldata].sort((a, b) => {
      if (a.goal_category_id === "96" || a.goal_category_id === "124") {
        return 1; // Move '96' and '124' to the end
      } else if (b.goal_category_id === "96" || b.goal_category_id === "124") {
        return -1; // Move '96' and '124' to the end
      } else if (type === "isCritical_sort") {
        return a[type] - b[type];
      } else if (type === "goal_priority") {
        return a[type] - b[type];
      } else if (type === "goalendadate") {
        return new Date(a["goalstartdate"]) - new Date(b["goalstartdate"]);
      }
      return 0;
    });
    setGoalData(goalsData);
    setMenuDropDownOpen(false);
  };

  // useEffect(() => {
  //   if (goaldetails.goal_for_member == "") {
  //     getMemberList();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (goaldetails.goal_isAfterRetirement != null) {
  //     handleIsRetirement();
  //   }
  // }, [goaldetails.goal_isAfterRetirement]);
  useEffect(() => {
    if (goaldetails.goal_category_id == '1') {
      handleEdutype();
    }
    // handleDate();
  }, [goaldetails.goal_eduType, goaldetails.goal_for_member]);

  if (
    goaldetails &&
    (goaldetails.goal_category_id == "7" ||
      goaldetails.goal_category_id == "5" ||
      goaldetails.goal_category_id == "3")
  ) {
    sortOptionsPayment = sortOptionsPayment2;
  } else {
    sortOptionsPayment = sortOptionsPayment1;
  }

  const [showPopup, setShowPopup] = useState(false);

  const handlePopupContinue = () => {
    setShowPopup(true);
  }

  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };

  const renderDatePicker = () => {
    return (
      <div className="dark-label">
        <Form.Label>Goal End Date*</Form.Label>
        <div className="dt-conbx" style={{ borderBottom: "1px solid #dadada" }}>

          <ReactDatePicker
            select_date={moment(goaldetails.goal_end_date, "DD/MM/YYYY")}
            setDate={(date) => {
              setDate(date, "endDate");
              handleEndDate(date);
            }}
            minDate={
              moment(goaldetails.goal_start_date, "DD/MM/YYYY").add(
                getMinDateValue(),
                'months'
              )
            }
            maxDate={""}
            className="pt-4"
          />
        </div>
        <div style={{ bottom: "-21px" }} className="error">{handlerror.goalEndDate}</div>
      </div>
    );
  };

  const getMinDateValue = () => {
    let frequencyMonths;
    if (
      goaldetails &&
      ["7", "5", "3"].includes(goaldetails.goal_category_id)
    ) {
      switch (goaldetails.goal_frequency) {
        case "6":
          frequencyMonths = 60;
          break;
        case "5":
          frequencyMonths = 48;
          break;
        case "4":
          frequencyMonths = 36;
          break;
        case "3":
          frequencyMonths = 24;
          break;
        case "2":
          frequencyMonths = 12;
          break;
        case "1":
        default:
          frequencyMonths = 6;
          break;
      }
    } else {
      switch (goaldetails.goal_frequency) {
        case "4":
          frequencyMonths = 12;
          break;
        case "3":
          frequencyMonths = 6;
          break;
        case "2":
          frequencyMonths = 3;
          break;
        case "1":
        default:
          frequencyMonths = 1;
          break;
      }
    }

    const minDate = moment(goaldetails.goal_start_date, "DD/MM/YYYY")
      .add(frequencyMonths, "months");

    const endDate = moment(goaldetails.goal_end_date, "DD/MM/YYYY").toDate();

    if (endDate < minDate) {
      setGoalDetails({
        ...goaldetails,
        goal_end_date: minDate.format("DD/MM/YYYY"),
      });
      handleEndDate(minDate);
    }
    return frequencyMonths;
  };

  return (
    <DatagatherLayout>
      <FintooLoader isLoading={isLoading} />

      <div className="">
        <div className="background-div">
          <div
            className={`bg ${currentUrl.indexOf("datagathering/goals") > -1 ? "active" : ""
              }`}
            id="bg-goals"
          ></div>
        </div>
        <div className="white-box">
          <div>
            <div className={` ${tab == "tab1" ? "d-block" : "d-none"}`}>
              <div className="row">
                <div className="col-md-10 col-12">
                  <div style={{
                    height: scroll ? "58px" : null,
                    top: scroll ? "0px" : null,
                    padding: scroll ? "0 2rem" : null,

                  }} className={`d-flex align-items-center  top-tab-menu justify-content-between  ${goaldata && goaldata.length > 2 && isDataLoading == false ? "FixdgHeader" : null}`}>
                    <div className="d-flex align-items-center">
                      {goaldata && goaldata.length > 2 && (
                        <FintooCheckbox
                          checked={selectedgoalCategories.length === goaldata.length}
                          onChange={() => {
                            if (selectedgoalCategories.length === goaldata.length) {
                              setSelectedgoalCategories([]);
                              setDeleteTogglegoal(false);
                            } else {
                              const allIds = goaldata.map(goal => goal.id);
                              setSelectedgoalCategories(allIds);
                              setDeleteTogglegoal(true);
                            }
                          }}
                        />
                      )}
                      <div
                        className="total-amt"
                        style={{
                          fontSize: "1.1rem",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "500",
                          }}
                        >
                          {" "}
                          ADDED GOALS
                        </span>
                      </div>
                    </div>
                    <div>
                      {
                        selectedgoalCategories.length > 0 &&
                        deletetogglegoal == true && (
                          <span
                            onClick={() => {
                              handleShow();
                              // setGoalId(i.id);
                              // setGoalName(i.goal_name);
                              // setCategoryType(i.goal_category_type);
                            }}
                            style={{
                              marginRight: "2rem",
                              cursor: "pointer"
                            }}
                            className="opt-options-2 pointer"
                          >
                            <MdDelete style={{ color: "#042b62", fontSize: "1.6rem" }} />
                          </span>
                        )
                      }
                      {
                        deletetogglegoal ? null :
                          <div className="sorting d-inline-block" ref={dropdownRef}>
                            <a
                              className="color-blue font-bold sort-by"
                              onMouseOver={() => setMenuDropDownOpen(true)}
                            >
                              Sort By{" "}
                              <img
                                alt=""
                                src="https://static.fintoo.in/static/assets/img/sort.svg"
                              />
                            </a>
                            {isMenuDropDownOpen && (
                              <ul className="sort-menu" id="goal-sort">
                                <li>
                                  <div onClick={() => sortByGoal("isCritical_sort")}>
                                    By Critical
                                  </div>
                                </li>
                                <li>
                                  <div onClick={() => sortByGoal("goal_priority")}>
                                    By Priority
                                  </div>
                                </li>
                                <li>
                                  <div onClick={() => sortByGoal("goalendadate")}>
                                    By Date
                                  </div>
                                </li>
                              </ul>
                            )}
                          </div>
                      }
                    </div>
                  </div>
                  <div className={goaldata == true ? "inner-box" : "inner-box"}>
                    {isDataLoading && (<div>
                      <div className=" inner-container mt-4 pt-4">
                        <div className="shine w-25 mb-1" style={{ height: '.7rem' }}></div>
                        <div className="shine w-100" style={{ height: '.7rem' }}></div>
                      </div>
                      <div className=" inner-container mt-4 pt-4">
                        <div className="shine w-25 mb-1" style={{ height: '.7rem' }}></div>
                        <div className="shine w-100" style={{ height: '.7rem' }}></div>
                      </div>
                    </div>)}
                    {goaldata &&
                      goaldata.map((i) => (
                        <div className="d-flex align-items-center">
                          {
                            i.goal_category_id == 96 || i.goal_category_id == 124 ?
                              null :
                              <FintooCheckbox
                                id={i.id}
                                checked={selectedgoalCategories.includes(i.id)}
                                title={i.title}
                                onChange={() => {
                                  setSelectedgoalCategories((prevSelected) => {
                                    if (prevSelected.includes(i.id)) {
                                      const updatedSelection = prevSelected.filter((id) => id !== i.id);
                                      setDeleteTogglegoal(updatedSelection.length > 0); // Check if any checkbox is still selected
                                      return updatedSelection;
                                    } else {
                                      setDeleteTogglegoal(true);
                                      return [...prevSelected, i.id];
                                    }
                                  });
                                }}
                              />
                          }
                          <div style={{
                            marginLeft: i.goal_category_id == 96 || i.goal_category_id == 124 ? "2rem" : "0"
                          }} key={i.id} className={`inner-container mt-4 w-100`}>
                            {
                              isDataLoading == false && (
                                <h4 className="">
                                  {i.goal_category_type}
                                  {i.goal_name == null || i.goal_name == ""
                                    ? ""
                                    : " - " + i.goal_name}
                                </h4>
                              )
                            }
                            {
                              isDataLoading == false && (
                                <div className="row ">
                                  {i.goal_priority != "96" && (
                                    <div className="col-md-4">
                                      <div className="display-style">
                                        <span>
                                          Value{" "}

                                          {i.goal_category_id != "5" &&
                                            i.goal_category_id != "7" &&
                                            i.goal_category_id != "3"
                                            ? "(" +
                                            frequencylist[parseInt(i.goal_frequency)] +
                                            ")"
                                            : "(" +
                                            vacationfreqlist[parseInt(i.goal_frequency)] +
                                            ")"}
                                        </span>
                                        <p className="">₹{i.goal_current_value}</p>
                                      </div>
                                    </div>
                                  )}
                                  <div className="col-md-2">
                                    <div className="display-style">

                                      <span>Year:</span>
                                      {i.goal_isRecurring == "1" ? (
                                        <p className="">
                                          {moment(i.goalstartdate).format("YYYY")}
                                        </p>
                                      ) : (
                                        !(
                                          i.goal_category_id == 96 ||
                                          i.goal_category_id == 124
                                        ) ?
                                          (
                                            <p className="">
                                              {moment(i.goalstartdate).format("YYYY")}
                                            </p>
                                          ) :
                                          (
                                            <p className="">
                                              {moment(i.goalendadate).format("YYYY")}
                                            </p>
                                          )
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="display-style">
                                      <span>Priority:</span>
                                      <p className="">
                                        {i.goal_priority != "96" &&
                                          priority_set[i.goal_priority] + " /"}
                                      </p>
                                      &nbsp;
                                      <p>{i.goal_isCritical && "Critical"}</p>
                                      <p>{!i.goal_isCritical && "Non-critical"}</p>
                                    </div>
                                  </div>
                                  {!(
                                    i.goal_category_id == 96 ||
                                    i.goal_category_id == 124
                                  ) && (
                                      <div className="col-md-2">
                                        <div className="opt-options float-md-right">
                                          <span>
                                            <BsPencilFill
                                              onClick={() => {
                                                editGoal(i.id), setEditFlag(true);
                                              }}
                                            />
                                          </span>

                                          {/* <span
                                          onClick={() => {
                                            handleShow();
                                            setGoalId(i.id);
                                            setGoalName(i.goal_name);
                                            setCategoryType(i.goal_category_type);
                                          }}
                                          className="opt-options-2"
                                        >
                                          <MdDelete />
                                        </span> */}
                                        </div>
                                      </div>
                                    )}
                                </div>
                              )
                            }
                            {i.goal_category_id == 96 && (
                              <div>
                                <br />
                                <p>
                                  Note : The retirement goal is added
                                  automatically as per the default goal, based on
                                  the retirement age provided in the about you
                                  section. As it is recommended by our financial
                                  experts, it is not editable.
                                </p>
                              </div>
                            )}
                            {i.goal_category_id == 124 && (
                              <div>
                                <br />
                                <p>
                                  Note : The Contingency Goal is added
                                  automatically as per default goal, based on the
                                  Liquidity Ratio. As it is recommended by our
                                  Financial Experts, It is not editable.
                                </p>
                              </div>
                            )}
                          </div>
                        </div>

                      ))}
                  </div>

                </div>
                <div className="removefixheader col-md-10 col-12">
                  <div className="accordion mt-4">
                    <div className="accordion-panel active">
                      <div className="accordion-header d-flex justify-content-between">
                        <h4 className="accordion-heading">
                          <img
                            className="accordian-img"
                            src={
                              imagePath +
                              "http://static.fintoo.in/static/assets/img/goal/goal-top.svg"
                            }
                            alt="Goal"
                          />
                          Goal Form
                        </h4>
                        <div
                          onClick={() => setShowView(!showview)}
                          className={`${DGstyles.HideSHowicon} hideShowIconCustom`}
                        >
                          {showview == true ? <>-</> : <>+</>}
                        </div>
                      </div>
                      {showview && (
                        <div
                          className={`accordion-content  family ${DGstyles.bgGoals}`}
                        >
                          <div className="row py-2">
                            <span>
                              <label className="">
                                Nature Of Goal : ({selectedOption})
                              </label>
                              {/* end ngIf: categorydetail!=''  */}
                            </span>
                            <div className="col-10">
                              <ul className="card-list">
                                {options.map((v, i) => (
                                  <React.Fragment key={i}>
                                    <li
                                      onClick={() => {
                                        setCounter((v) => v++);
                                        var getUserdobyear = new Date(
                                          sessiondata["user_details"]["dob"]
                                        ).getFullYear();
                                        var getCurrentyear =
                                          new Date().getFullYear();
                                        var member_age =
                                          getCurrentyear - getUserdobyear;
                                        setMemberAge(member_age);
                                        setHandleError({
                                          ...defaultHandleError,
                                        });
                                        setGoalDetails({
                                          ...defaultGoalDetails,
                                          goal_category_id: v.value,
                                          goal_name: v.title,
                                          goal_category_type:
                                            v.goal_category_type,
                                          user_id:
                                            sessiondata["user_details"][
                                            "user_id"
                                            ],
                                          fp_log_id:
                                            sessiondata["user_details"][
                                            "fp_log_id"
                                            ],
                                          goal_isCritical: v.goal_isCritical,
                                          goal_priority: v.goal_priority,
                                          goal_frequency: v.goal_frequency,
                                          goal_inflation: v.goal_inflation,
                                          goal_partial_loan:
                                            v.goal_partial_loan,
                                          member_age: member_age,
                                          goal_for_member:
                                            memberdata["0"].value,
                                        });
                                        setSelectedOption(v.goal_category_type);
                                        setEditFlag(false);
                                        setshowRadioButton(true);
                                        scrollToGoalRef();
                                      }}
                                      className={`li-options ${selectedOption == v.goal_category_type
                                        ? "active"
                                        : ""
                                        }`}
                                    >
                                      <input
                                        type="radio"
                                        id="radio"
                                        value={v.value}
                                        name="type"
                                        data-show=".recurring-group"
                                      />
                                      <label htmlFor="type-2">
                                        <img alt={v.title} src={v.image} />
                                        {v.title}
                                      </label>
                                    </li>
                                  </React.Fragment>
                                ))}
                              </ul>
                            </div>
                          </div>

                          <div ref={cntRef}>
                            <form
                              noValidate="novalidate"
                              className="floatinfo"
                              name="goldassetform"
                            >
                              <div className="row d-flex align-items-center py-2">
                                <div className="col-md-5 col-12 custom-input " style={{ paddingTop: "19px" }}>
                                  <div className={`form-group w-100 ${goaldetails.goal_name ? "inputData" : null}`} >
                                    <span>
                                      <input type="text" name="goal_name" value={goaldetails.goal_name}
                                        maxLength={35}
                                        id="goal_name"
                                        onChange={(e) => {
                                          setGoalDetails({
                                            ...goaldetails,
                                            goal_name: e.target.value,
                                          });
                                          handleGoalName(e.target.value);
                                        }}
                                        autoComplete="off" />
                                      <span class="highlight"></span>
                                      <span class="bar"></span>
                                      <label for="name">Goal Name*</label>
                                    </span>
                                    <span className="info-hover-box">
                                      <span className="icon">
                                        <img
                                          alt="More information"
                                          src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                        />
                                      </span>
                                      <span className="msg">
                                        Ex: Swati's higher education, Ajay's
                                        business investment or anything which
                                        you can relate to.
                                      </span>
                                    </span>
                                  </div>
                                  <div className="error">
                                    {handlerror.goalName}
                                  </div>
                                </div>
                                <div className="col-md-5 col-12 ">
                                  <div className="material">
                                    <Form.Label>
                                      Who Is This Goal For?*
                                    </Form.Label>
                                   
                                    <Select
                                      classNamePrefix="sortSelect"
                                      isSearchable={false}
                                      styles={CustomStyles}
                                      options={memberdata}
                                      value={memberdata.find((e) => e.value === goaldetails.goal_for_member)}
                                      onChange={(e) => {
                                        setGoalDetails({
                                          ...goaldetails,
                                          goal_for_member: e.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row d-flex align-items-center">
                                <div className={`col-md-5 col-12 custom-input`} style={{ paddingTop: "19px" }}>
                                  <div className={`form-group w-100 ${goaldetails.goal_current_value ? "inputData" : null}`} >
                                    <span>
                                      <input type="number" name="goal_current_value" value={goaldetails.goal_current_value}
                                        minLength={1}
                                        id="goal_current_value"
                                        maxLength={11}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          if (inputValue.length <= 9 && /^\d*$/.test(inputValue)) {
                                            setGoalDetails({
                                              ...goaldetails,
                                              goal_current_value: inputValue,
                                            });
                                            handleGoalValue(inputValue);
                                          }
                                        }}
                                        autoComplete="off" />
                                      <span class="highlight"></span>
                                      <span class="bar"></span>
                                      <label for="name">What is the present cost of the goal?(₹)*</label>
                                    </span>
                                    <span className="info-hover-box">
                                      <span className="icon">
                                        <img
                                          alt="More information"
                                          src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                        />
                                      </span>
                                      <span className="msg">
                                        If you were to spend for this goal today,
                                        how much amount is required in today's
                                        terms. Do not consider inflated value.
                                      </span>
                                    </span>
                                  </div>

                                  <div className="error">
                                    {handlerror.goalCurrentValue}
                                  </div>
                                </div>
                                {goaldetails &&
                                  goaldetails.goal_category_id == "1" ? (
                                  <div className="col-md-5 col-12 pt-1" style={{ position: "relative" }}>
                                    <div className="material">
                                      <Form.Label>
                                        Type Of Education*
                                      </Form.Label>
                                      <Select
                                        classNamePrefix="sortSelect"
                                        isSearchable={false}
                                        styles={CustomStyles}
                                        onChange={(v) => {
                                          setGoalDetails({
                                            ...goaldetails,
                                            goal_eduType: v.value,
                                            goal_isAfterRetirement: ""
                                          });
                                          handleEdu(v.value);
                                          // handleEdutype()
                                        }}
                                        value={sortOptionsEducation.filter(
                                          (v) =>
                                            v.value == goaldetails.goal_eduType
                                        )}
                                        options={sortOptionsEducation}
                                      />
                                    </div>
                                    <div style={{ position: "absolute", bottom: "-16px" }} className="error">
                                      {handlerror.goalEduType}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="col-md-5 col-12">
                                    <div className="material">
                                      <Form.Label>Goal Priority*</Form.Label>
                                      <Select
                                        classNamePrefix="sortSelect"
                                        isSearchable={false}
                                        styles={CustomStyles}
                                        onChange={(p) =>
                                          setGoalDetails({
                                            ...goaldetails,
                                            goal_priority: p.value,
                                          })
                                        }
                                        value={sortOptionsGoalPriority.filter(
                                          (v) =>
                                            v.value == goaldetails.goal_priority
                                        )}
                                        options={sortOptionsGoalPriority}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              {goaldetails &&
                                goaldetails.goal_category_id == "1" && (
                                  <div className="row mt-2 py-3">
                                    <div className="col-md-5 col-12  mt-2">
                                      <div className="material">
                                        <Form.Label>Goal Priority*</Form.Label>
                                        <Select
                                          classNamePrefix="sortSelect"
                                          isSearchable={false}
                                          styles={CustomStyles}
                                          onChange={(p) =>
                                            setGoalDetails({
                                              ...goaldetails,
                                              goal_priority: p.value,
                                            })
                                          }
                                          value={sortOptionsGoalPriority.filter(
                                            (v) =>
                                              v.value ==
                                              goaldetails.goal_priority
                                          )}
                                          options={sortOptionsGoalPriority}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              <div className="row py-3 mt-3">
                                <div className="col-md-5 col-12 ">
                                  <div className="material">
                                    <div className=" d-flex justify-content-between">
                                      <div>
                                        <Form.Label>
                                          Goal inflation rate(%)* :{" "}
                                          {Number(goaldetails.goal_inflation)}
                                        </Form.Label>
                                      </div>
                                      <div>
                                        <span
                                          className="info-hover-box"
                                          style={{
                                            top: "-22px",
                                          }}
                                        >
                                          <span className="icon">
                                            <img
                                              alt="More information"
                                              src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                            />
                                          </span>
                                          <span className="msg">
                                            Inflation is the rate of increase in
                                            prices over a given period. The rate
                                            of inflation mentioned here is
                                            assumed on the basis of the average
                                            inflation rate of the past 10 years.
                                            If you wish, you can also change it
                                            as per your requirement.
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className={`${goaldetails.goal_inflation < 2 &&
                                        "sl-hide-left"
                                        } ${goaldetails.goal_inflation > 18 &&
                                        "sl-hide-right"
                                        }`}
                                    >
                                      <Slider
                                        min={0}
                                        max={20}
                                        value={goaldetails.goal_inflation}
                                        onChange={(v) =>
                                          setGoalDetails({
                                            ...goaldetails,
                                            goal_inflation:
                                              Math.round(
                                                (parseFloat(v) +
                                                  Number.EPSILON) *
                                                100
                                              ) / 100,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row py-3">
                                <div className="col-md-8 col-12">
                                  <div className="d-md-flex d-grid">
                                    <Form.Label className="">
                                      Is This goal critical or not?*
                                    </Form.Label>
                                    <div className="d-flex ps-md-5">
                                      <div className="d-flex ms-md-5">
                                        Non-critical
                                      </div>
                                      <Switch
                                        onChange={(e) =>
                                          setGoalDetails({
                                            ...goaldetails,
                                            goal_isCritical: e,
                                          })
                                        }
                                        checked={goaldetails.goal_isCritical}
                                        className="react-switch px-2"
                                        activeBoxShadow="0 0 2px 3px #042b62"
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={20}
                                        width={40}
                                        onColor="#042b62"
                                       offColor="#d8dae5"
                                      />
                                      <div>Critical</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {goaldetails.goal_category_id != "6" && (
                                <div className="row py-3">
                                  <div className="col-md-8 col-12">
                                    <div className="d-md-flex d-grid">
                                      <Form.Label className=" ">
                                        Will The Goal Amount Be Funded By ?*
                                      </Form.Label>
                                      <div className="d-flex ps-md-5">
                                        <div>Self</div>
                                        <Switch
                                          onChange={(e) => {
                                            handlePatialLoan(e);
                                            setGoalDetails({
                                              ...goaldetails,
                                              goal_partial_loan: e,
                                            });
                                          }}
                                          checked={
                                            goaldetails.goal_partial_loan
                                          }
                                          className="react-switch px-2"
                                          activeBoxShadow="0 0 2px 3px #042b62"
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          height={20}
                                          width={40}
                                          onColor="#042b62"
                                         offColor="#d8dae5"
                                        />
                                        <div>Loan</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {goaldetails &&
                                goaldetails.goal_partial_loan == true &&
                                goaldetails.goal_category_id != "6" && (
                                  <>
                                    <div className="row py-3">
                                      <div className="col-md-5 col-12 ">
                                        <div className="material">
                                          <div className="d-flex justify-content-between">
                                            <div>
                                              <Form.Label>
                                                Loan Percentage(%)* :{" "}
                                                {Number(
                                                  goaldetails.goal_loan_amount
                                                )}
                                              </Form.Label>
                                            </div>
                                          </div>
                                          <div
                                            className={`${goaldetails.goal_loan_amount <
                                              5 && "sl-hide-left"
                                              } ${goaldetails.goal_loan_amount >
                                              95 && "sl-hide-right"
                                              }`}
                                          >
                                            <Slider
                                              min={0}
                                              max={100}
                                              value={
                                                goaldetails.goal_loan_amount
                                              }
                                              onChange={(v) =>
                                                setGoalDetails({
                                                  ...goaldetails,
                                                  goal_loan_amount:
                                                    Math.round(
                                                      (parseFloat(v) +
                                                        Number.EPSILON) *
                                                      100
                                                    ) / 100,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-5 col-12 ">
                                        <div className="material">
                                          <div className=" d-flex justify-content-between">
                                            <div>
                                              <Form.Label>
                                                Loan Tenure* :{" "}
                                                {goaldetails.goal_tenure}
                                              </Form.Label>
                                            </div>
                                          </div>
                                          <div
                                            className={`${goaldetails.goal_tenure < 3 &&
                                              "sl-hide-left"
                                              } ${goaldetails.goal_tenure > 27 &&
                                              "sl-hide-right"
                                              }`}
                                          >
                                            <Slider
                                              min={1}
                                              max={30}
                                              value={
                                                goaldetails.goal_tenure
                                              }
                                              onChange={(v) =>
                                                setGoalDetails({
                                                  ...goaldetails,
                                                  goal_tenure: v,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row py-3">
                                      <div className="col-md-5 col-12 ">
                                        <div className="material">
                                          <div className=" d-flex justify-content-between">
                                            <div>
                                              <Form.Label>
                                                Interest Rate(%)* :{" "}
                                                {parseFloat(goaldetails.goal_interest_rate, 1)}
                                              </Form.Label>
                                            </div>
                                          </div>
                                          <div
                                            className={`${goaldetails.goal_interest_rate <
                                              2 && "sl-hide-left"
                                              } ${goaldetails.goal_interest_rate >
                                              18 && "sl-hide-right"
                                              }`}
                                          >
                                            <Slider
                                              min={0}
                                              max={20}
                                              step={0.1}
                                              value={
                                                goaldetails.goal_interest_rate
                                              }
                                              onChange={(v) =>
                                                setGoalDetails({
                                                  ...goaldetails,
                                                  goal_interest_rate:
                                                    Math.round(
                                                      (parseFloat(v) +
                                                        Number.EPSILON) *
                                                      100
                                                    ) / 100,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              {goaldetails &&
                                goaldetails.goal_category_id != "2" && (
                                  <div className="row py-3">
                                    <div className="col-md-8 col-12">
                                      <div className="d-md-flex d-grid">
                                        <Form.Label className="">
                                          Is the goal one time or recurring?*
                                        </Form.Label>
                                        <div className="d-flex ps-md-4">
                                          <div>One Time</div>
                                          <Switch
                                            onChange={(v) => {
                                              {/* handleDate(v); */ }
                                              setGoalDetails({
                                                ...goaldetails,
                                                goal_isRecurring: v,
                                              });
                                            }}
                                            checked={
                                              goaldetails.goal_isRecurring
                                            }
                                            className="react-switch px-2"
                                            activeBoxShadow="0 0 2px 3px #042b62"
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={20}
                                            width={40}
                                            onColor="#042b62"
                                           offColor="#d8dae5"
                                          />
                                          <div>Recurring</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {goaldetails &&
                                goaldetails.goal_isRecurring == true && (
                                  <>
                                    <div className="row d-flex align-items-center py-2">
                                      <div className="col-md-5 col-12 ">
                                        <div
                                          className="dark-label"
                                          style={{
                                            marginTop: "0px",
                                          }}
                                        >
                                          <Form.Label>
                                            Goal Start Date*
                                          </Form.Label>
                                          <div
                                            className="dt-conbx"
                                            style={{
                                              borderBottom: "1px solid #dadada",
                                              // paddingTop: "1rem",
                                            }}
                                          >
                                            <ReactDatePicker
                                              select_date={moment(
                                                goaldetails.goal_start_date,
                                                "DD/MM/YYYY"
                                              ).toDate()}
                                              setDate={(date) => {
                                                setDate(date, "startDate");
                                                handleStartDate(date);
                                              }}
                                              minDate={moment().toDate()}
                                              maxDate={""}
                                              className="pt-4"
                                            />
                                          </div>
                                          <div className="error">
                                            {handlerror.goalStartDate}
                                          </div>
                                        </div>
                                      </div>
                                      {goaldetails &&
                                        goaldetails.goal_category_id == "1" && (
                                          <div className="col-md-5 col-12 custom-input">
                                            <div className={`form-group mt-2 w-100 ${(goaldetails.member_age || goaldetails.member_age === 0 || goaldetails.member_age === '0') ? "inputData" : ""}`} style={{ paddingTop: "15px" }}>
                                              <input type="text" name="member_age" id="member_age" value={goaldetails.member_age}
                                                onChange={(e) => {
                                                  const value = e.target.value;
                                                  const ageRegex = /^[1-9][0-9]*$/;
                                                  if (value === '' || ageRegex.test(value)) {
                                                    setGoalDetails({ ...goaldetails, member_age: value });
                                                  }
                                                }} required
                                                autoComplete="off" />
                                              <span class="highlight"></span>
                                              <span class="bar"></span>
                                              <label for="name">Age*</label>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                    <div className="row mt-1">
                                      <Form.Label>Upto*</Form.Label>
                                      <div className="col-md-5 col-12">
                                        {goaldetails.goal_frequency && (
                                          <>{renderDatePicker()}</>
                                        )}
                                      </div>
                                      {showRadioButton &&
                                        showRadioButton == true && (
                                          <>
                                            <div className="col-md-6 col-12 d-flex align-items-center">
                                              <div className="dark-label">
                                                <div
                                                  className="d-md-flex d-grid"
                                                  style={{ clear: "both" }}
                                                >
                                                  <FintooRadio2
                                                    checked={
                                                      goaldetails.goal_isAfterRetirement ==
                                                      "0"
                                                    }
                                                    onClick={(e) => {
                                                      setGoalDetails({
                                                        ...goaldetails,
                                                        goal_end_date: moment(retirmentdate)
                                                          .add(retirmentdate, "y")
                                                          .format("DD/MM/YYYY"),
                                                        goal_isAfterRetirement:
                                                          "0",
                                                      });
                                                      handleEndDate("0");
                                                      // handleIsRetirement(false);
                                                    }}
                                                    title="Upto Retirement Age"
                                                  />
                                                  <FintooRadio2
                                                    checked={
                                                      goaldetails.goal_isAfterRetirement ==
                                                      "1"
                                                    }
                                                    onClick={() => {
                                                      setGoalDetails({
                                                        ...goaldetails,
                                                        goal_end_date: moment(lifeexpectancydate)
                                                          .add(lifeexpectancydate, "y")
                                                          .format("DD/MM/YYYY"),
                                                        goal_isAfterRetirement:
                                                          "1",
                                                      });
                                                      handleEndDate("1");
                                                      // handleIsRetirement(true);
                                                    }}
                                                    title="Upto Life Expectancy Age"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                    </div>
                                    <div className="row py-3 mt-4">
                                      <div className="col-md-5 col-12 ">
                                        <div className="material">
                                          <Form.Label>Frequency*</Form.Label>
                                          <Select
                                            classNamePrefix="sortSelect"
                                            isSearchable={false}
                                            styles={CustomStyles}
                                            onChange={(v) => {
                                              setGoalDetails({
                                                ...goaldetails,
                                                goal_frequency: v.value,
                                              });
                                              handleGoalFrq(v.value);
                                            }}
                                            value={sortOptionsPayment.filter(
                                              (v) =>
                                                v.value ==
                                                goaldetails.goal_frequency
                                            )}
                                            options={sortOptionsPayment}
                                          />
                                          <div style={{ position: "relative" }} className="error">
                                            {handlerror.goalFrequency}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}

                              {goaldetails &&
                                goaldetails.goal_isRecurring == false && (
                                  <div className="row py-1 d-flex align-items-center">
                                    <div className="col-md-5 col-12 ">
                                      <div
                                        className="dark-label"

                                      >
                                        <Form.Label>Goal Date*</Form.Label>
                                        <div
                                          className="dt-conbx"
                                          style={{
                                            borderBottom: "1px solid #dadada",
                                            // paddingTop: "1rem",
                                          }}
                                        >
                                          <ReactDatePicker
                                            select_date={moment(
                                              goaldetails.goal_start_date,
                                              "DD/MM/YYYY"
                                            ).toDate()}
                                            setDate={(date) => {
                                              setDate(date, "startDate");
                                              handleStartDate(date);
                                            }}
                                            minDate={moment().toDate()}
                                            maxDate={""}
                                            className="pt-4"
                                          />
                                        </div>
                                        <div className="error">
                                          {handlerror.goalStartDate}
                                        </div>
                                      </div>
                                    </div>
                                    {goaldetails &&
                                      goaldetails.goal_category_id == "1" && (
                                        <div className="col-md-5 col-12 custom-input" style={{ paddingTop: "15px" }} >
                                          <div className={`form-group mt-2 w-100 ${(goaldetails.member_age || goaldetails.member_age === 0 || goaldetails.member_age === '0') ? "inputData" : ""}`}>
                                            <input type="text" name="member_age" id="member_age_s" value={goaldetails.member_age}
                                              onChange={(e) => {
                                                setGoalDetails({
                                                  ...goaldetails,
                                                  member_age: e.target.value,
                                                });
                                              }} required
                                              autoComplete="off" />
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label for="name">Age*</label>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                )}
                              <div className="row">
                                <div className="col-md-10 col-12 custom-input mt-2">
                                  <div className={`form-group mt-2 ${goaldetails.goal_footnote ? "inputData" : null}`}>
                                    <input type="text" name="S_Remarks" id="S_Remarks" value={goaldetails.goal_footnote}
                                      onChange={(e) => {
                                        setGoalDetails({
                                          ...goaldetails,
                                          goal_footnote: e.target.value,
                                        });
                                      }} autoComplete="off" />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label for="name">Remarks</label>
                                  </div>
                                </div>
                              </div>

                              <div className="row py-2">
                                <div className=" text-center">
                                  <div>
                                    <div className="btn-container">
                                      <div className="d-flex justify-content-center">
                                        <Link
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/datagathering/income-expenses"
                                          }
                                        >
                                          <div className="previous-btn form-arrow d-flex align-items-center">
                                            <FaArrowLeft />
                                            <span className="hover-text">
                                              &nbsp;Previous
                                            </span>
                                          </div>
                                        </Link>
                                        {editflag == true ? (
                                          <>
                                            <button
                                              className="default-btn gradient-btn save-btn"
                                              onClick={(e) => cancelGoalForm(e)}
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="default-btn gradient-btn save-btn"
                                              onClick={(e) => {
                                                handleSubmit(e);
                                                handleLocalStorageGoalsData(undefined, "1");
                                              }
                                              }
                                            >
                                              Update
                                            </button>
                                          </>
                                        ) : (
                                          <button
                                            className="default-btn gradient-btn save-btn"
                                            onClick={(e) => {
                                              handleSubmit(e);
                                              handleLocalStorageGoalsData(undefined, "0");
                                            }
                                            }
                                          >
                                            Save & Add More
                                          </button>
                                        )}

                                        {editflag == 1 ? (
                                          // Render the "Continue" button without <Link> when editflag is 1
                                          <div
                                            className="next-btn form-arrow d-flex align-items-center"
                                            onClick={() => setShowPopup(true)} // Show the popup when clicking
                                          >
                                            <span className="hover-text" style={{ maxWidth: 100 }}>
                                              Continue&nbsp;
                                            </span>
                                            <FaArrowRight />
                                          </div>
                                        ) : (
                                          <Link
                                            to={process.env.PUBLIC_URL + "/datagathering/assets-liabilities"}
                                          >
                                            <div className="next-btn form-arrow d-flex align-items-center">
                                              <span className="hover-text" style={{ maxWidth: 100 }}>
                                                Continue&nbsp;
                                              </span>
                                              <FaArrowRight />
                                            </div>
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal className="popupmodal" centered show={show} onHide={handleClose}>
        <Modal.Header className="ModalHead">
          <div className="text-center">Delete Confirmation</div>
        </Modal.Header>
        <div className=" p-5 d-grid place-items-center align-item-center">
          <div className=" HeaderModal">
            <div
              style={{
                fontSize: "1rem",
                textAlign: "center",
              }}
            >
              This will permanently erase the record and its associated
              information.
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center pb-5">
          <button onClick={() => { deleteGoal(); handleLocalStorageGoalsData(goalid, "0"); }} className="outline-btn m-2">
            Yes
          </button>
          <button onClick={handleClose} className="outline-btn m-2">
            No
          </button>
        </div>
      </Modal>
      <Modal className="popupmodal" centered show={showPopup} onHide={handlePopupClose}>
        <Modal.Header className="ModalHead">
          <div className="text-center">Information</div>
        </Modal.Header>
        <div className=" p-5 d-grid place-items-center align-item-center">
          <div className=" HeaderModal">
            <div
              style={{
                fontSize: "1rem",
                textAlign: "center",
              }}
            >
              It seems you have unsaved changes. Do you still want to continue to the next section?<br />We might not be able to save the changes if you go ahead.
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center pb-5">
          <button onClick={() => window.location.href = process.env.PUBLIC_URL + "/datagathering/assets-liabilities"} className="outline-btn m-2">
            Yes
          </button>
          <button onClick={handlePopupClose} className="outline-btn m-2">
            No
          </button>
        </div>
      </Modal>
      {/* <ToastContainer toastStyle={{ backgroundColor: "red" }}/> */}
    </DatagatherLayout>
  );
};
export default Goals;