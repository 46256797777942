import { useEffect } from "react";

import GuestLayout from "../components/Layout/GuestLayout";

const ComplaintsStatus = () => {
  useEffect(() => {
    document.body.classList.add("main-layout");
  }, []);
  const TableStyle = {
    color: "#000",

    // width : 100
  };
  return (
    <GuestLayout>
      <section  className="privacy-policy-section" style={{ paddingTop: 30 }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Complaints Status</h2>
              <p className="bold">Data for the month ending , June 2024</p>
              <table className="table ComplaintTable">
                <thead >
                  <tr>
                    <th>Sr. No</th>
                    <th>Received from</th>
                    <th>Pending at the end of last month</th>
                    <th>Received</th>
                    <th>Resolved*</th>
                    <th>Total Pending#</th>
                    <th>Pending complaints &gt; 3months</th>
                    <th>Average Resolution time (in days)^</th>
                  </tr>
                </thead>
                <tbody className="borderColor">
                  <tr>
                    <td>1</td>
                    <td>Directly from Investors</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  
                  <tr>
                    <td>2</td>
                    <td>Other Sources (if any)</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td />
                    <td>
                      <strong>Grand Total</strong>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
              <p>
                ^ Average Resolution time is the sum total of time taken to
                resolve each complaint in days, in the current month divided by
                total number of complaints resolved in the current month.
              </p>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Month</th>
                    <th>Carried forward from previous month</th>
                    <th>Received</th>
                    <th>Resolved*</th>
                    <th>Pending#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>April, 2023</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>May, 2023</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>June, 2023</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>July, 2023</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Aug , 2023</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Sep, 2023</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>Oct, 2023</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>Nov, 2023</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>Dec, 2023</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>Jan, 2024</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>Feb, 2024</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>March, 2024</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>April, 2024</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td>May, 2024</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td>June, 2024</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td />
                    <td>
                      <strong>Grand Total</strong>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
              <p>
                *Inclusive of complaints of previous months resolved in the
                current month.
              </p>
              <p>
                #Inclusive of complaints pending as on the last day of the
                month.
              </p>
              <div style={{ marginBottom: "5rem"}}></div>
              
            </div>
          </div>
        </div>
      </section>
    </GuestLayout>
  );
};
export default ComplaintsStatus;
