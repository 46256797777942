import React, { useEffect, useRef, useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { Link, NavLink, useLocation } from "react-router-dom";
import pmc from "./commonDashboard.module.css";
import FintooLogo from "../../../Assets/Images/F_logo.png";
import Advisory from "../../../Assets/Images/CommonDashboard/01_advisory.svg";
import PlanofAction from "../../../Assets/Images/CommonDashboard/Plan of action.png";
import Dashboard from "../../../Assets/Images/CommonDashboard/Dashboard.png";
// import Transaction from "../../../Assets/Images/CommonDashboard/04_transactions.png";
import Investment from "../../../Assets/Images/CommonDashboard/02_investment.svg";
import PersonalTax from "../../../Assets/Images/CommonDashboard/03_personal_tax.svg";
import Transaction from "../../../Assets/Images/CommonDashboard/04_transactions.png";
import Man from "../../../Assets/Images/CommonDashboard/profile_side.png";
import Profile from "../../../Assets/Images/CommonDashboard/profile-picture.svg";
import Menu from "../../../Assets/Images/CommonDashboard/menu.png";
import Call from "../../../Assets/Images/CommonDashboard/phonecall.png";
import Mail from "../../../Assets/Images/CommonDashboard/Mail.png";
import { useSelector, useDispatch } from "react-redux";
import {
  ADVISORY_GET_RM_DETAILS_API_URL,
  ADVISORY_RENEWPAYMENT_API_URL,
  BASE_API_URL,
  CHECK_SESSION,
  GET_RMDETAILS,
  STATIC_URL,
} from "../../../constants";
import {
  apiCall,
  fetchEncryptData,
  getCurrentUserDetails,
  getItemLocal,
  getParentUserId,
  getPublicMediaURL,
  getUserId,
  imagePath,
} from "../../../common_utilities";
import commonEncode from "../../../commonEncode";
import Calendly from "../../CommonDashboard/Calendly";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import { appendTo } from "cheerio/lib/api/manipulation";
const CommonDSidebar = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const showPage = useSelector((state) => state.page);
  // const url = window.location.pathname.split("/").pop();
  const mobileNavRef = useRef(null);
  const [rmdetails, setRmDetails] = useState({});
  const [rmdata, setRmdata] = useState('');
  const [calendlydata, setCalendlyData] = useState({
    name: "",
    email: "",
    mobile: "",
  });


  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleTrigger = () => setIsOpen(!isOpen);
  const [currentLocation, setCurrentLocation] = useState("");
  const [yopmailUser, setYopmalUser] = useState(false);
  const [plantype, setPlanType] = useState(0);
  const [dashboardpage, setDashboardPage] = useState(-1);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [url]);

  useEffect(() => {
    console.log("a--->", location);
    setCurrentLocation(location.pathname);
  }, [location]);

  useEffect(() => {

    Rmdetails();
    UserBaseView();
    // getSessiondata();
  }, []);

  const userid = getParentUserId();

  const getrmdetails = async () => {
    // alert(400)
    try {
      let url = CHECK_SESSION;
      let data = { user_id: userid, sky: getItemLocal("rmf"), is_direct: 0  };

      let session_data = await apiCall(url, data, true, false);
      if (session_data["data"]["user_details"]) {
        let calendly_detail = session_data["data"]["user_details"];



        setCalendlyData({
          name: calendly_detail["name"],
          email: calendly_detail["email"],
          mobile: calendly_detail["mobile"],
        });
      } else {
        let calendly_detail = session_data["data"];
        setCalendlyData({
          name: calendly_detail["first_name"],
          email: calendly_detail["email"],
          mobile: calendly_detail["mobile"],
        });
      }
      let rmId = "96";
      if ("rm_id" in session_data["data"]) {
        rmId = session_data["data"]["rm_id"];
      }
      let api_data = {
        rm_id: rmId,
      };
      var payload_data = commonEncode.encrypt(JSON.stringify(api_data));
      var rm_data = await apiCall(
        ADVISORY_GET_RM_DETAILS_API_URL,
        payload_data,
        false,
        false
      );
      var res = JSON.parse(commonEncode.decrypt(rm_data));
      if (res.error_code == "100" || res.data != "") {
        var rm_details = res.data[0];
        setRmDetails(rm_details);
      } else {
        setRmDetails({});
      }
    } catch { }
  };


  const Rmdetails = async () => {
    try {
      let rm = await getCurrentUserDetails();
      console.log(rm, "rmmmm")
      console.log(rm['rm_id'], "100")
      var data = { rm_id: rm['rm_id'] }
      // var data = commonEncode.encrypt(JSON.stringify(data));
      var config = {
        method: "post",
        url: GET_RMDETAILS,
        data: data,
      };
      var res = await fetchEncryptData(config);
      console.log(res.data, "bbbbbbb")
      // var res = JSON.parse(commonEncode.decrypt(res));
      console.log(res, "ll")
      console.log(res.data[0], 'kkk')
      if (res.error_code == "100") {
        setRmdata(res.data[0])
      }

    } catch (e) {
      console.log("e --> ", e);
    }
  }

  console.log(rmdata, "check123")
  const [currentUrl, setCurrentUrl] = useState("");
  React.useEffect(() => {
    console.log("acfv 123", location);
    setCurrentUrl(location.pathname);
  }, [location]);

  useEffect(() => {
    if (document.querySelector(".plan-of-action-cls") == null) return;
    if (dashboardpage > 1 && plantype == 6) {
      document.querySelectorAll(".plan-of-action-cls").forEach((v) => {
        v.style.display = "block";
      });
    } else {
      console.log("hello 65", dashboardpage > 1 && plantype == 6);

      document.querySelectorAll(".plan-of-action-cls").forEach((v) => {
        v.style.display = "none";
      });
    }
  }, [dashboardpage, plantype]);

  const getSessiondata = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: userid, sky: getItemLocal("rmf"), is_direct: 0  };
      let session_data = await apiCall(url, data, true, true);

      if ((session_data.error_code = "100" && session_data.data != "")) {
        if (
          session_data.data.fp_lifecycle_status == "" ||
          !session_data.data.fp_lifecycle_status
        ) {
          var lifecycledata = 0;
        } else {
          var lifecycledata = session_data.data.fp_lifecycle_status;
        }
        // setPlanSubCat(session_data.data.fp_plan_sub_cat);
        // console.log("nodnd", lifecycledata, session_data.data.fp_plan_type);
        setDashboardPage(lifecycledata);
        setPlanType(session_data.data.fp_plan_type);
        let api_data = {
          fp_log_id: session_data["data"]["fp_log_id"],
          user_id: session_data["data"]["id"],
        };
      } else {
        setDashboardPage(0);
        // setRenewPopup(0);
        setPlanType(0);
        // setPlanSubCat(0);
        // setSubscriptionEndDate("");
      }
    } catch (e) {
      // console.log("object1",e);
    }
  };

  const UserBaseView = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: userid, sky: getItemLocal("rmf"), is_direct: 0  };
      let session_data = await apiCall(url, data, true, true);
      if (
        session_data["data"]["email"].includes("@yopmail.com")
      ) {
        setYopmalUser(true)
      } else {
        setYopmalUser(false)
      }
    } catch (e) {
      console.error("Error:", e);
    }
  };

  const excludedUrls = [
    "/mutual-fund/portfolio/dashboard/",
    "/mutual-fund/portfolio/dashboard/transaction",
  ];

  const shouldRender = excludedUrls.includes(currentLocation);

  console.log("nil123", showPage, currentLocation, shouldRender);

  return (
    <>
      <div className={`${pmc.navbar} portfolio-navbar-sidebar`}>
        <div className={`${pmc.SidebarBox}`} ref={mobileNavRef}>
          <div className={`${pmc.menuitem} `}>
            {shouldRender   && (
              <div
                onClick={() => {
                  if (
                    !(
                      currentLocation == "/web/commondashboard" ||
                      currentLocation == "/web/commondashboard/"
                    )
                  ) {
                    navigate(`${process.env.PUBLIC_URL}/commondashboard`);
                  }
                  dispatch({
                    type: "CHANGE_COMMONDASHBOARD",
                    payload: "dashboard",
                  });
                }}
                className={`text-decoration-none d-flex menu-link-182 ${pmc["aaa"]
                  } ${pmc.menulink} ${showPage === "dashboard" &&
                    (currentLocation == "/web/commondashboard" ||
                      currentLocation == "/web/commondashboard/")
                    ? pmc.activeMenu
                    : "Inactive"
                  }`}
              >
                <div>
                  {console.log("shree", currentLocation)}
                  <img width={20} src={Advisory} />
                </div>
                <div className={pmc.menutext}>Dashboard</div>
              </div>
            )}
          </div>
          {
            yopmailUser &&(
          <div
            onClick={() => {
              if (!(currentLocation == '/mutualfunds/commondashboard' || currentLocation == '/mutualfunds/commondashboard/')) {
                navigate(`${process.env.PUBLIC_URL}/commondashboard`);
              }
              dispatch({
                type: "CHANGE_COMMONDASHBOARD",
                payload: "dashboard",
              });
            }}
            className={`text-decoration-none d-flex menu-link-182 ${pmc.menuitem}
                } ${pmc.menulink} ${showPage === "dashboard" &&
                (currentLocation == '/mutualfunds/commondashboard' || currentLocation == '/mutualfunds/commondashboard/')
                ? pmc.activeMenu
                : "Inactive"
              }`}
          >
            <div>
              <img width={20} src={
                 process.env.PUBLIC_URL + "/static/media/01_advisory.svg"
              } />
            </div>
            <div className={pmc.menutext}>Dashboard</div>
          </div>
            )
          }

          <div className={`${pmc.menuitem} plan-of-action-cls`}>
            <div
              onClick={() => {
                if (currentLocation.indexOf("/commondashboard") === -1) {
                  navigate(`${process.env.PUBLIC_URL}/commondashboard`);
                }
                dispatch({
                  type: "CHANGE_COMMONDASHBOARD",
                  payload: "planofaction",
                });
              }}
              className={` text-decoration-none d-flex menu-link-182  ${pmc.menulink
                } ${(currentLocation == "/web/commondashboard" ||
                  currentLocation == "/web/commondashboard/") &&
                  showPage === "planofaction"
                  ? `${pmc.activeMenu}`
                  : `${pmc.Inactive}`
                }`}
            >
              <div>
                <img width={20} src={PlanofAction} />
              </div>
              <div className={`${pmc.menutext} `}>Plan of Action</div>
            </div>
          </div>
          <div className={`${pmc.menuitem} `}>
            <NavLink
              className={({ isActive, isPending }) =>
                "text-decoration-none " +
                (isActive ? `${pmc.activeMenu}` : `${pmc.Inactive}`)
              }
              to={`${process.env.PUBLIC_URL}/mutual-fund/portfolio/dashboard/`}
            >
              <div
                // onClick={() => {
                //   dispatch({
                //     type: "CHANGE_COMMONDASHBOARD",
                //     payload: "dashboard",
                //   });
                // }}
                className={` text-decoration-none d-flex menu-link-182 `}
              >
                <div>
                  <img width={20} src={PersonalTax} />
                </div>
                <div className={`${pmc.menutext} `}>Portfolio</div>
              </div>
            </NavLink>
          </div>
          <div className={`${pmc.menuitem}`}>
            <NavLink
              className={({ isActive, isPending }) =>
                "text-decoration-none " +
                (isPending
                  ? pmc.pendingMenu
                  : isActive
                    ? pmc.activeMenu
                    : pmc.InactiveMenu)
              }
              to={`${process.env.PUBLIC_URL}/commondashboard/Report`}
            >
              <div
                // onClick={() => {
                //   dispatch({
                //     type: "CHANGE_COMMONDASHBOARD",
                //     payload: "dashboard",
                //   });
                // }}
                className={` text-decoration-none d-flex menu-link-182 `}
              >
                <div>
                  <img
                    src={getPublicMediaURL('static/media/04_transactions.svg')}
                    // src={imagePath + "https://stg.minty.co.in" + Transaction}
                    width={20}
                  />
                </div>
                <div
                  style={{
                    paddingTop: ".1rem",
                  }}
                  className={`${pmc.menutext + " sidebar-custom-color"} `}
                >
                  Report{" "}
                </div>
              </div>
            </NavLink>
          </div>
          <div className={`${pmc.menuitem}`}>
            <NavLink
              className={({ isActive, isPending }) =>
                "text-decoration-none " +
                (isPending
                  ? pmc.pendingMenu
                  : isActive
                    ? pmc.activeMenu
                    : pmc.InactiveMenu)
              }
              to={`${process.env.PUBLIC_URL}/mutual-fund/portfolio/dashboard/transaction`}
            >
              <div
                // onClick={() => {
                //   dispatch({
                //     type: "CHANGE_COMMONDASHBOARD",
                //     payload: "dashboard",
                //   });
                // }}
                className={` text-decoration-none d-flex menu-link-182 `}
              >
                <div>
                  <img width={20} src={
                     process.env.PUBLIC_URL + "/static/media/04_transactions.svg"
                  } />
                </div>
                <div className={`${pmc.menutext} `}>Transaction </div>
              </div>
            </NavLink>
          </div>

          {shouldRender && (
            <div className={`${pmc.menuitem} `}>
              <NavLink
                className={({ isActive }) =>
                  "text-decoration-none " +
                  (isActive ? `${pmc.activeMenu}` : `${pmc.Inactive}`)
                }
                to={`${process.env.PUBLIC_URL}/commondashboard/investment`}
              >
                <div
                  // onClick={() => {
                  //   dispatch({
                  //     type: "CHANGE_COMMONDASHBOARD",
                  //     payload: "dashboard",
                  //   });
                  // }}
                  className={` text-decoration-none d-flex menu-link-182 `}
                >
                  <div>
                    <img width={20} src={Investment} />
                  </div>
                  <div className={`${pmc.menutext} `}>Investment </div>
                </div>
              </NavLink>
            </div>
          )}
        </div>
      </div>
      <Calendly
        show={show}
        handleClose={() => setShow(false)}
        calendlydata={calendlydata}
        rmdetails={rmdetails}
      />
    </>
  );
};

export default CommonDSidebar;