import { useEffect } from "react";
import { fetchData } from "../common_utilities";

const CalendlyCallback = () => {

    const onLoad = async () => {
        try {
          const eventURL = localStorage.getItem("eventURL");
          const eventType = localStorage.getItem("eventType");
          const serviceName = localStorage.getItem("serviceName");
          const extraParams = localStorage.getItem("extraParams") == null ? {} : JSON.parse(localStorage.getItem("extraParams"));

        
          if (eventURL == null) return;
    
          if (eventType == null) {
            eventType = "Callback_mintyApp_8";
          }
          if (serviceName == null) {
            serviceName = "Financial Planning";
          }
          localStorage.removeItem("eventURL");
          localStorage.removeItem("eventType");
          localStorage.removeItem("serviceName");
          localStorage.removeItem("extraParams");
    
          const r = await fetchData({
            url: eventURL,
            method: "GET",
            headers: {
              Authorization:
                "Bearer eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjQ4MjExMjQ0LCJqdGkiOiJmMmM1YWIwOC01N2ZiLTQ0YzAtODNjYy1lM2QxZWZhZGY2YzMiLCJ1c2VyX3V1aWQiOiI0ODVhZTAyZC02ZGNiLTQ1MjktODdiYi01MGY2NDE3NGI4ZWYifQ.5bIIwHH3DTn1Vp7Oj6hZlLkVIbI1q7jxqFogGaGkb1g",
            },
          });
          
          console.log("r------->", r.resource);
    
          const mobileArr = r.resource.questions_and_answers.filter(
            (v) => v.question.toLowerCase().indexOf("mobile") > -1
          );
          if (mobileArr.length > 0) {
            console.log('mobileArr', mobileArr);
            var mobile = mobileArr[0]["answer"].replace(/[^0-9]/g, "");
            mobile = mobile.substring(mobile.length - 10, mobile.length);
            
            const payload = {
              method: "POST",
              url: process.env.REACT_APP_CONTACT_FORM,
              data: {
                fullname: r.resource.name,
                email: r.resource.email,
                tagval: "",
                servicename: serviceName,
                tags: eventType,
                utm_source: "26",
                mobile: "" + mobile,
                skip_sms: "",
                rm_id: "96",
                ...extraParams
              },
            };
            console.log('nil rt',payload);
            console.log("nil m--------->123");
            const r1 = await fetchData(payload);
            console.log("nil rr----->", r1);
          }
        } catch (e) {}
      };
    
      useEffect(() => {
        onLoad();
      }, []);

      return (<></>);
};
export default CalendlyCallback;