import React from 'react'
import style from "./style.module.css"
const Ourfeaturesection = () => {
    return (
        <div className='container'>
            <div className={style.Ourfeature_section}>
                <div className={style.ourfeaturesone}>
                    <div>

                        <sup><img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/topview.svg'} alt="Our Features" /></sup>
                        Our Features
                    </div>
                    <div style={{fontWeight:"500"}}>Why Choose us ?</div>
                </div>
                <div className={`${style.FeatureCard}`}>
                    <div className='d-flex d-md-block justify-content-center'>
                        <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Instant_Approval.svg'} alt="Instant Approval" />
                    </div>
                    <div>
                        <div className={`${style.featuretitle}`}>Instant Approval</div>
                        <div className={`${style.featureDesc}`}>Apply Online. Get Instant Disbursal in just 24-36 hours.</div>
                    </div>
                </div>
                <div className={`${style.FeatureCard}`}>
                    <div className='d-flex d-md-block justify-content-center'>
                        <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Repayment.svg'} alt="Flexible Repayment" />
                    </div>
                    <div className=''>
                        <div className={`${style.featuretitle}`}>Flexible Repayment</div>
                        <div className={`${style.featureDesc}`}>Interest Only EMI. No Prepayment charges. Free Part Payments.</div>
                    </div>
                </div>
                <div className={`${style.FeatureCard}`}>
                    <div className='d-flex d-md-block justify-content-center'>
                        <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Pocket_Friendly.svg'} alt="Secure Platform" />
                    </div>
                    <div>
                        <div className={`${style.featuretitle}`}>Pocket Friendly</div>
                        <div className={`${style.featureDesc}`}>Low interest rates. EMI Free Loans. Customized lower Installments.</div>
                    </div>
                </div>
                <div  className={`${style.FeatureCard}`}>
                    <div className='d-flex d-md-block justify-content-center'>
                        <img src={process.env.PUBLIC_URL + '/static/media/LandingPages/New/Documentation.svg'} alt="Excellent Support" />
                    </div>
                    <div>
                        <div className={`${style.featuretitle}`}>Minimal Documentation</div>
                        <div className={`${style.featureDesc}`}>Simple paperwork for a smoother application process.</div>
                    </div>
                </div>
            </div>
            <div id='Book-Calendly'></div>
        </div>
    )
}

export default Ourfeaturesection